import { Edit as EditIcon } from '@mui/icons-material';
import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { InfoCard, InfoCardRowDef } from '~components';
import { useAuthorize } from '~features/authentication';

import OrganisationsService from '../../services/organisationsService';

const organisationService = new OrganisationsService();

interface OrganisationDeviceCardProps {
	organisationId: string;
	onEdit?: () => void;
}

const OrganisationDeviceCard = ({ organisationId, ...props }: OrganisationDeviceCardProps) => {
	const { t } = useTranslation();
	const { isSuperAdmin } = useAuthorize();

	const { data, isLoading, error } = useSWR(
		organisationId != null ? [organisationService.basePath, organisationId] : null,
		([_, args]) => organisationService.getOrganisationById(args)
	);

	const rows: InfoCardRowDef[] | undefined = data && [
		{
			headerName: t('ui.label.devices'),
			value: data.features.includes('devices'),
			renderCell: (value: boolean) => (
				<Chip
					label={t(value ? 'ui.enabled' : 'ui.status.disabled')}
					color={value ? 'success' : 'error'}
				/>
			),
		},
	];

	return (
		<InfoCard
			title={t('ui.label.devices')}
			rows={rows ?? []}
			loading={isLoading}
			error={error != null}
			actions={
				isSuperAdmin() ?
					[
						{
							label: t('ui.edit'),
							icon: <EditIcon />,
							onClick: props.onEdit,
						},
					]
				:	undefined
			}
		/>
	);
};

export default OrganisationDeviceCard;

import { useEffect, useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, List, ListItem, Typography, IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import SelectPartnerOrganisation from './SelectPartnerOrganisation';
import { useStyles } from './style';
import {
	Wizard,
	SidepanelContainer,
	SidePanel,
	CustomExpansionPanel,
	LoadingBar,
	StyledButton,
} from '../../../../components';
import { useItemInstanceSelection } from '../../../../shared/hooks';
import {
	isEmptyArray,
	isArray,
	isUndefined,
	isObject,
	instanceName,
} from '../../../../shared/utility';
import * as actions from '../../../../store/actions';

interface AssignItemsToPartnerProps {
	onFetchItemInstancesList?(...args: unknown[]): unknown;
	itemInstances?: {
		data?: unknown[];
		loading?: boolean;
		error?: object | string;
	};
	onAssignItemsToPartner?(...args: unknown[]): unknown;
	assignedItemsToPartner?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const AssignItemsToPartner = (props: AssignItemsToPartnerProps) => {
	const {
		onFetchItemInstancesList,
		itemInstances,
		onAssignItemsToPartner,
		assignedItemsToPartner,
	} = props;
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();

	const itemSelection = useItemInstanceSelection(
		location.state && location.state.selectedItems ? location.state.selectedItems : [],
	);
	const [itemToFetchInstancesOf, setItemToFetchInstancesOf] = useState();
	const [fetchingInstancesForSummary, setFetchingInstancesForSummary] = useState(false);

	const [selectedPartners, setSelectedPartners] = useState([]);

	const { data: instancesData, loading: instancesLoading, error: instancesError } = itemInstances;
	const instancesReady = isArray(instancesData) && !instancesLoading && !instancesError;

	useEffect(() => {
		if (fetchingInstancesForSummary && instancesReady) {
			itemSelection.setSelection(
				itemSelection.selection.map((sItem) => ({
					...sItem,
					selectedInstances:
						sItem.item.id === itemToFetchInstancesOf ? instancesData : sItem.selectedInstances,
				})),
			);
			setFetchingInstancesForSummary(false);
		}
	}, [fetchingInstancesForSummary, instancesReady]);

	const [startedFinishWizard, setStartedFinishWizard] = useState(false);
	const [shouldSubmitSelection, setShouldSubmitSelection] = useState(false);
	const [submittingSelection, setSubmittingSelection] = useState(false);
	const [updatedPartnerCounter, setUpdatedPartnerCounter] = useState(0);

	const { data: assignedItems, error: assignedItemsError } = assignedItemsToPartner;
	const [initialAssignedItems, setInitialAssignedItems] = useState(false);

	const assignItemsDone =
		updatedPartnerCounter === selectedPartners.length && !isEmptyArray(selectedPartners);

	useEffect(() => {
		if (shouldSubmitSelection && !submittingSelection) {
			setSubmittingSelection(true);
			const itemsArray = itemSelection.selection.map((sItem) => ({
				itemId: sItem.item.id,
				instanceIds: sItem.selectedInstances.map((sInstance) => sInstance.id),
			}));
			selectedPartners.forEach((partner) => {
				onAssignItemsToPartner(partner.partnerOrganisation.id, { items: itemsArray });
			});
		}
	}, [shouldSubmitSelection, submittingSelection]);

	//Each time items are assigned to a specific partner
	useEffect(() => {
		if (assignedItems && submittingSelection) {
			if (initialAssignedItems) {
				setInitialAssignedItems(false);
			} else {
				//success notifications
				if (!isEmptyArray(assignedItems.accepted)) {
					enqueueSnackbar(
						`${t('views.assignItemsToPartner.messages.success.accepted.partOne')} ${assignedItems.accepted.length} ${t('views.assignItemsToPartner.messages.success.accepted.partTwo')} ${assignedItems.name}`,
						{ variant: 'success' },
					);
				}

				if (!isEmptyArray(assignedItems.rejected)) {
					enqueueSnackbar(
						`${t('views.assignItemsToPartner.messages.success.rejected')} ${assignedItems.name}`,
					);
				}
				//up counter
				setUpdatedPartnerCounter(updatedPartnerCounter + 1);
			}
		}
	}, [assignedItems, submittingSelection]);

	useEffect(() => {
		if (submittingSelection && assignedItemsError) {
			enqueueSnackbar(
				isObject(assignedItemsError) ? assignedItemsError.message : assignedItemsError,
				{ variant: 'error' },
			);
			setStartedFinishWizard(false);
		}
	}, [submittingSelection, assignedItemsError]);

	useEffect(() => {
		if (assignItemsDone) {
			navigate(-1);
		}
	}, [assignItemsDone]);

	const handleClickShowInstancesInSummary = (item) => {
		itemSelection.setSelection(
			itemSelection.selection.map((selectedItem) => ({
				...selectedItem,
				showInstances:
					item.item.id === selectedItem.item.id ?
						!selectedItem.showInstances
					:	selectedItem.showInstances,
			})),
		);

		if (item.selectedInstances.length === 0) {
			onFetchItemInstancesList(item.item.id);
			setItemToFetchInstancesOf(item.item.id);
			setFetchingInstancesForSummary(true);
		}
	};

	const handleClickRemovePartner = (partnerId) => {
		setSelectedPartners(
			selectedPartners.filter((sPartner) => sPartner.partnerOrganisation.id !== partnerId),
		);
	};

	const callback = (saved) => {
		const { selectedPartners: partnersToSave, finishedWizard } = saved;

		if (!isUndefined(partnersToSave)) {
			setSelectedPartners(partnersToSave);
		}

		if (finishedWizard && !startedFinishWizard) {
			setStartedFinishWizard(true);
			setShouldSubmitSelection(true);
		}
	};

	const assignItemSteps = [
		{
			name: 'Assign Items Step',
			content: SelectPartnerOrganisation,
			valid: !isEmptyArray(itemSelection.selection) && !isEmptyArray(selectedPartners),
			props: {
				selectedPartners,
			},
		},
	];

	/* * * * * * *
	 * SIDEPANEL *
	 * * * * * * */
	const sidepanel = (
		<SidepanelContainer>
			<SidePanel title={<Typography variant='h4'>{t('ui.summary')}</Typography>}>
				<CustomExpansionPanel title={t('ui.category.items')}>
					{!isEmptyArray(itemSelection.selection) ?
						<List className={classes.sidepanelList}>
							{itemSelection.selection.map((selectedItem) => (
								<ListItem className={classes.item} key={selectedItem.item.id}>
									<div className={classes.itemText}>
										<Typography variant='h5'>{selectedItem.item.name}</Typography>
										<Typography>
											{t('ui.label.category') + ': ' + selectedItem.item.categoryReference.name}
										</Typography>
										<StyledButton
											className={classes.inlineButton}
											endIcon={
												selectedItem.showInstances ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />
											}
											onClick={() => handleClickShowInstancesInSummary(selectedItem)}
											variant='inline-default'
										>
											{`${t('ui.button.inline.qty')}: ${selectedItem.selectedInstances.length > 0 ? selectedItem.selectedInstances.length : selectedItem.item.totalItemInstances}/${selectedItem.item.totalItemInstances}`}
										</StyledButton>
										{selectedItem.showInstances ?
											selectedItem.selectedInstances.length > 0 ?
												<List className={classes.instancesList}>
													{selectedItem.selectedInstances.map((sInstance) => (
														<ListItem
															className={classes.instance}
															key={`summary-item-${selectedItem.item.id}-instance-${sInstance.id}`}
														>
															<Typography>{instanceName(sInstance)}</Typography>
															<IconButton
																onClick={() =>
																	itemSelection.handleRemoveItemFromSidebar(
																		selectedItem.item,
																		sInstance,
																	)
																}
																size='small'
															>
																<CloseIcon />
															</IconButton>
														</ListItem>
													))}
												</List>
											:	Array(5)
													.fill({})
													.map((_, index) => (
														<ListItem
															key={`item-${selectedItem.item.id}-instance-loading-${index}`}
														>
															<LoadingBar />
														</ListItem>
													))

										:	null}
									</div>
									<IconButton
										onClick={() => itemSelection.handleRemoveItemFromSidebar(selectedItem.item)}
										size='small'
									>
										<CloseIcon />
									</IconButton>
								</ListItem>
							))}
						</List>
					:	<Typography>
							{t('views.assignItemsToUserGroup.sidebar.list.itemInstances.empty')}
						</Typography>
					}
				</CustomExpansionPanel>
				<Divider />
				<CustomExpansionPanel title={t('ui.category.partners')}>
					{!isEmptyArray(selectedPartners) ?
						<List className={classes.sidepanelList}>
							{selectedPartners.map((partner) => (
								<ListItem className={classes.item} key={partner.partnerOrganisation.id}>
									<div className={classes.itemText}>
										<Typography variant='h6'>{partner.partnerOrganisation.name}</Typography>
									</div>
									<IconButton
										onClick={() => handleClickRemovePartner(partner.partnerOrganisation.id)}
										size='small'
									>
										<CloseIcon />
									</IconButton>
								</ListItem>
							))}
						</List>
					:	<Typography>{t('views.assignItemsToPartner.sidebar.list.partners.empty')}</Typography>}
				</CustomExpansionPanel>
			</SidePanel>
		</SidepanelContainer>
	);

	/* * * * * *
	 * RENDER  *
	 * * * * * */
	return (
		<Wizard
			callback={callback}
			finishButtonLabel={t('views.assignItemsToUserGroup.button.finish')}
			loading={startedFinishWizard}
			sidebar={sidepanel}
			steps={assignItemSteps}
			title={t('views.assignItemsToPartner.page.title')}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		itemInstances: state.list.itemInstancesList,
		assignedItemsToPartner: state.details.assignedItemsToPartner,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchItemInstancesList: (itemId) => dispatch(actions.fetchItemInstancesList(itemId)),
		onAssignItemsToPartner: (partnerId, bodyData) =>
			dispatch(actions.assignItemsToPartner(partnerId, bodyData)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignItemsToPartner);

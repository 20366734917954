import { useEffect, useState, Fragment } from 'react';

import BlockIcon from '@mui/icons-material/Block';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import {
	Card,
	Box,
	CardContent,
	Divider,
	FormControl,
	Select,
	Switch,
	CircularProgress,
	MenuItem,
	Typography,
	Table,
	TableBody,
	TableRow,
	TableCell,
} from '@mui/material';
import { useAtomValue } from 'jotai';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { userInfoAtom } from '~atoms';
import { Tooltip } from '~components';
import { useAuthorize } from '~features/authentication';

import DeleteUserDialog from './DeleteUserDialog';
import { useStyles } from './style';
import VerifyEmailDialog from './VerifyEmailDialog';
import {
	ButtonWithAlertDialog,
	AlertDialog,
	StyledButton,
	Label,
} from '../../../../../../components';
import { usePrevious, useError } from '../../../../../../shared/hooks';
import { capitalizeFirstCharacter, isNull, isArray } from '../../../../../../shared/utility';
import * as actions from '../../../../../../store/actions';

interface OtherActionsProps {
	className?: string;
	onRemoveUserFromOrganisation?(...args: unknown[]): unknown;
	customer?: object;
	onDisableUser?(...args: unknown[]): unknown;
	onNfcServiceFlag?(...args: unknown[]): unknown;
	onRemoveNfcServiceFlag?(...args: unknown[]): unknown;
	onResetStateCondition?(...args: unknown[]): unknown;
	onFetchUserRoles?(...args: unknown[]): unknown;
	onUpdateUserRole?(...args: unknown[]): unknown;
	onFetchUsersUserRole?(...args: unknown[]): unknown;
	userRole?: string;
	updateRole?: {
		success?: boolean;
		loading?: boolean;
		error?: object | string;
	};
	nfcServiceFlag?: {
		success?: boolean;
		loading?: boolean;
		error?: object | string;
	};
	removeNfcServiceFlag?: {
		success?: boolean;
		loading?: boolean;
		error?: object | string;
	};
	disableUser?: {
		success?: boolean;
		loading?: boolean;
		error?: object | string;
	};
	removeUserFromOrganisation?: {
		success?: boolean;
		loading?: boolean;
		error?: object | string;
	};
	roles?: {
		data?: unknown[];
		loading?: boolean;
		error?: object | string;
	};
	hardDeleteUser?: {
		success?: boolean;
		loading?: boolean;
		error?: object | string;
	};
}

const OtherActions = (props: OtherActionsProps) => {
	const {
		onRemoveUserFromOrganisation,
		removeUserFromOrganisation,
		onFetchUserRoles,
		onUpdateUserRole,
		customer,
		onDisableUser,
		disableUser,
		updateRole,
		onFetchUsersUserRole,
		roles,
		userRole,
		onNfcServiceFlag,
		nfcServiceFlag,
		onRemoveNfcServiceFlag,
		removeNfcServiceFlag,
		onResetStateCondition,
		hardDeleteUser,
	} = props;
	const { t } = useTranslation();
	const { isSuperAdmin } = useAuthorize();
	const userInfo = useAtomValue(userInfoAtom);
	const userIsCurrentUser = userInfo.id == customer?.id;

	const classes = useStyles();

	const { enqueueSnackbar } = useSnackbar();

	const { data: userRolesData, loading: userRolesLoading, error: userRolesError } = roles;

	const userRolesReady = isArray(userRolesData) && !userRolesLoading && !userRolesError;

	const { success: updateSuccess, loading: updateLoading, error: updateError } = updateRole;
	const updateDone = updateSuccess && !updateLoading && !updateError;

	const userRoleErrorHandling = useError({
		value: updateRole,
		message: t('views.userDetails.message.success.updateUserRole'),
		variant: 'success',
	});

	const { success: disableSuccess, loading: disableLoading, error: disableError } = disableUser;
	const disableUserDone = disableSuccess && !disableLoading && !disableError;

	const {
		success: removeSuccess,
		loading: removeLoading,
		error: removeError,
	} = removeUserFromOrganisation;
	const removeUserDone = removeSuccess && !removeLoading && !removeError;

	const { success: hardDeleteUserSuccess } = hardDeleteUser;

	const {
		success: nfcServiceFlagSuccess,
		loading: nfcServiceFlagLoading,
		error: nfcServiceFlagError,
	} = nfcServiceFlag;

	const { loading: updateRoleLoading } = updateRole;

	const {
		success: removeNfcServiceFlagSuccess,
		loading: removeNfcServiceFlagLoading,
		error: removeNfcServiceFlagError,
	} = removeNfcServiceFlag;

	const loadingPreviousRole = usePrevious(updateRoleLoading);

	const [option, setOption] = useState({ name: userRole });

	const [openSelect, setOpenSelect] = useState(false);

	const [updateServiceFlag, setUpdateServiceFlag] = useState(
		customer?.serviceAccess.includes('nfc')
	);

	const [openDeleteUserDialog, setOpenDeleteUserDialog] = useState(false);

	const [openVerifyEmailDialog, setOpenVerifyEmailDialog] = useState(false);

	useEffect(() => {
		if (isNull(userRolesData)) {
			onFetchUserRoles();
		}
	}, []);

	useEffect(() => {
		if (updateSuccess) {
			onFetchUsersUserRole(customer.id);
		}
	}, [updateRole]);

	useEffect(() => {
		return () => {
			onResetStateCondition('hardDeleteUser', false);
		};
	}, []);

	useEffect(() => {
		if (nfcServiceFlagSuccess || removeNfcServiceFlagSuccess) {
			if (updateServiceFlag) {
				onResetStateCondition('nfcServiceFlag', false);
			} else {
				onResetStateCondition('removeNfcServiceFlag', false);
			}
			enqueueSnackbar(t(`views.userDetails.serviceFlag.success.message.${updateServiceFlag}`), {
				variant: 'success',
			});
		} else if (removeNfcServiceFlagError || nfcServiceFlagError) {
			enqueueSnackbar('Error', { variant: 'error' });
		}
	}, [nfcServiceFlagLoading, removeNfcServiceFlagLoading]);

	const handleCloseSelect = () => {
		setOpenSelect(false);
	};

	const handleOpenSelect = () => {
		setOpenSelect(true);
	};

	const [dialog, setDialog] = useState(false);

	useEffect(() => {
		if (userRoleErrorHandling.startAction && updateDone) {
			setDialog(false);
		}
	}, [userRoleErrorHandling.startAction, updateDone]);

	const updateOption = (name) => {
		const updatedOption = userRolesReady ? userRolesData.find((item) => item.name === name) : null;
		setOption(updatedOption);
	};

	const handleRolChange = (event) => {
		updateOption(event.target.value);
		setDialog(true);
	};

	const handleCancelDialog = () => {
		updateOption(userRole);
		setDialog(false);
	};
	const handleConfirmDialog = () => {
		onUpdateUserRole(customer.id, option.name);
		userRoleErrorHandling.setStartAction(true);
	};

	const removeUserMessage = useError({
		value: removeUserFromOrganisation,
		message: `${t('ui.successfully')} ${t('ui.removed')} ${customer.firstName} ${customer.lastName}`,
	});

	const disableUserMessage = useError({
		value: disableUser,
		message: `${t('ui.successfullyDisabled')} ${customer.firstName} ${customer.lastName}`,
	});

	useEffect(() => {
		if (loadingPreviousRole) {
			onFetchUsersUserRole(customer.id);
		}
	}, [loadingPreviousRole]);

	const handleUserTransfer = () => {
		if (isSuperAdmin()) {
			onDisableUser(customer.id);
			disableUserMessage.setStartAction(true);
		} else {
			onRemoveUserFromOrganisation(customer.id);
			removeUserMessage.setStartAction(true);
		}
	};

	const handleNfcServiceFlag = (event) => {
		setUpdateServiceFlag(event.target.checked);
		if (event.target.checked) {
			onNfcServiceFlag(customer.id);
		} else {
			onRemoveNfcServiceFlag(customer.id);
		}
	};

	const handleDeleteUser = () => setOpenDeleteUserDialog(true);

	const handleVerifyEmail = () => {
		setOpenVerifyEmailDialog(true);
	};

	const deleteUserDialogProps = {
		openDeleteUserDialog,
		setOpenDeleteUserDialog,
		userId: customer?.id,
	};

	const verifyEmailDialogProps = {
		openVerifyEmailDialog,
		setOpenVerifyEmailDialog,
		email: customer?.emailAddress,
	};

	const latestDeleteRequestState = customer?.latestDeleteRequestState;

	return (
		<Card className={classes.root}>
			<Box display='flex' justifyContent='space-between' pb={2} pl={3} pr={3} pt={2}>
				<Typography variant='h5'>
					{t('views.userDetails.summary.cardHeaders.otherActions')}
				</Typography>
				{isSuperAdmin() && (hardDeleteUserSuccess || latestDeleteRequestState) ?
					<Tooltip
						title={
							<Typography variant='body2'>
								{latestDeleteRequestState?.reason ?? t('ui.label.reequestProgress')}
							</Typography>
						}
					>
						<Box>
							<Label type={latestDeleteRequestState?.state === 'rejected' ? 'error' : 'warning'}>
								{latestDeleteRequestState?.state === 'rejected' ?
									t('ui.label.rejectedDeletion')
								:	t('ui.label.pendingDeletion')}
							</Label>
						</Box>
					</Tooltip>
				:	null}
			</Box>
			<Divider />
			<CardContent>
				{isSuperAdmin() && userRole === 'admin' ?
					<Table>
						<TableBody>
							<TableRow>
								<TableCell>{t('views.userDetails.summary.actions.serviceFlag')}</TableCell>
								<TableCell>
									{nfcServiceFlagLoading || removeNfcServiceFlagLoading ?
										<CircularProgress color='inherit' disableShrink size={19} />
									:	<Switch
											checked={updateServiceFlag}
											color='primary'
											onChange={handleNfcServiceFlag}
											size='small'
										/>
									}
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				:	null}
				<div className={classes.mainActions}>
					{userRole === 'devAdmin' || (userRole === 'superAdmin' && !isSuperAdmin()) ?
						<Card className={classes.roleCard}>
							<Typography className={classes.roleCardText}>
								{capitalizeFirstCharacter(userRole)}
							</Typography>
						</Card>
					: customer?.isAnonymous ?
						null
					:	<FormControl fullWidth={true} sx={{ paddingBottom: 1 }} variant='standard'>
							<Select
								disabled={userIsCurrentUser}
								onChange={handleRolChange}
								onClose={handleCloseSelect}
								onOpen={handleOpenSelect}
								open={openSelect}
								value={option.name}
								variant='outlined'
							>
								{userRolesReady ?
									userRolesData.map((item) => (
										<MenuItem key={item.id} value={item.name}>
											{capitalizeFirstCharacter(item.name)}
										</MenuItem>
									))
								:	null}
							</Select>
							<AlertDialog
								dialogDescription={t('views.userDetails.summary.changingUserRoleAlert.description')}
								dialogTitle={t('views.userDetails.summary.changingUserRoleAlert.title')}
								handleClose={handleCancelDialog}
								handleConfirm={handleConfirmDialog}
								loading={updateLoading && !updateDone}
								open={dialog}
							/>
						</FormControl>
					}
					{(
						userIsCurrentUser ||
						!isSuperAdmin() ||
						customer?.isAnonymous ||
						customer?.isEmailConfirmed
					) ?
						null
					:	<Box className={classes.emailVerification}>
							<StyledButton
								onClick={handleVerifyEmail}
								startIcon={<MarkEmailReadIcon />}
								variant='inline-default'
							>
								{t('views.usermanagement.users.button.default.verifyEmail')}
							</StyledButton>
							<Typography className={classes.notice} variant='body2'>
								{t('views.usermanagement.users.label.verification.description')}
							</Typography>
							{openVerifyEmailDialog ?
								<VerifyEmailDialog {...verifyEmailDialogProps} />
							:	null}
						</Box>}
					{isSuperAdmin() && !userIsCurrentUser ?
						<Fragment>
							<StyledButton
								disabled={
									userRole === 'admin' || userRole === 'superAdmin' || hardDeleteUserSuccess
								}
								onClick={handleDeleteUser}
								startIcon={<DeleteOutlineOutlinedIcon />}
								variant='inline-delete'
							>
								{t('views.userDetails.summary.deleteDialog.title')}
							</StyledButton>
							<Typography className={classes.notice} variant='body2'>
								{t('views.userDetails.summary.deleteDialog.description')}
							</Typography>
							{openDeleteUserDialog ?
								<DeleteUserDialog {...deleteUserDialogProps} />
							:	null}
						</Fragment>
					:	null}
					{userIsCurrentUser || customer?.isAnonymous ? null : (
						<>
							<ButtonWithAlertDialog
								actionDone={!isSuperAdmin() ? removeUserDone : disableUserDone}
								callback={handleUserTransfer}
								className={classes.deleteButton}
								color={!isSuperAdmin() ? 'secondary' : 'primary'}
								dialogDescription={
									!isSuperAdmin() ?
										`${customer.name}, ${t('views.userDetails.summary.actions.description')}`
									:	`${t('ui.disable.dialogDescription')} ${customer.name}`
								}
								dialogLoading={
									!isSuperAdmin() ?
										removeLoading && !removeUserDone
									:	disableLoading && !disableUserDone
								}
								dialogTitle={t(
									!isSuperAdmin() ? 'ui.remove' : 'views.userDetails.summary.disableDialog.title'
								)}
								disabled={userRole === 'admin' || userRole === 'superAdmin'}
								error={!isSuperAdmin() ? removeError : disableError}
								startIcon={
									!isSuperAdmin() ?
										<span className='material-icons'>person_remove</span>
									:	<BlockIcon />
								}
								textDialog={{
									notAllowed: userRole !== 'user',
									title: t('views.userDetails.summary.actions.isTextDialog.title'),
									description: t('views.userDetails.summary.actions.isTextDialog.description'),
								}}
								variant='inline-delete'
							>
								{t(
									!isSuperAdmin() ?
										'ui.button.inline.removefromorganisation'
									:	'view.usermanagement.userdetails.button.inline.disable'
								)}
							</ButtonWithAlertDialog>
							<Typography className={classes.notice} variant='body2'>
								{t(
									!isSuperAdmin() ?
										'views.userDetails.summary.description'
									:	'views.userDetails.summary.disable'
								)}
							</Typography>
						</>
					)}
				</div>
			</CardContent>
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		disableUser: state.condition.disableUser,
		removeUserFromOrganisation: state.condition.removeUserFromOrganisation,
		roles: state.list.userRoles,
		updateRole: state.condition.updateUserRole,
		nfcServiceFlag: state.condition.nfcServiceFlag,
		removeNfcServiceFlag: state.condition.removeNfcServiceFlag,
		hardDeleteUser: state.condition.hardDeleteUser,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onDisableUser: (id) => dispatch(actions.disableUser(id)),
		onRemoveUserFromOrganisation: (id) => dispatch(actions.removeUserFromOrganisation(id)),
		onFetchUsersUserRole: (id) => dispatch(actions.fetchUsersUserRole(id)),
		onFetchUserRoles: () => dispatch(actions.fetchUserRoles()),
		onUpdateUserRole: (userId, name) => dispatch(actions.updateUserRole(userId, name)),
		onNfcServiceFlag: (userId) => dispatch(actions.nfcServiceFlag(userId)),
		onRemoveNfcServiceFlag: (userId) => dispatch(actions.removeNfcServiceFlag(userId)),
		onResetStateCondition: (state, value) => dispatch(actions.resetStateCondition(state, value)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OtherActions);

import { SyntheticEvent, useRef, useState } from 'react';

import ReactCrop, { Crop, PercentCrop } from 'react-image-crop';

import './imageCropper.css';

export interface ImageCropperProps {
	src: string;
	aspect?: number;
	/**
	 * A callback when the crop changes, numbers are with respect to original image
	 * in pixels
	 * @param crop
	 * @returns
	 */
	onCropChange?: (crop: { x: number; y: number; width: number; height: number }) => void;

	minBlockSize?: number;
}

/**
 * A component to crop images and return the cropped image
 * in a onchange handler
 */
const ImageCropper = ({ src, aspect, onCropChange, minBlockSize }: ImageCropperProps) => {
	const srcRef = useRef(src);

	const [activeCrop, setActiveCrop] = useState<Crop>();
	const crop = useRef<Crop>();

	const [dimensions, setDimensions] = useState<{ width: number; height: number } | null>(null);

	/**
	 * Handle when the image has loaded
	 * @param e
	 */
	const handleImageLoad = (e: SyntheticEvent<HTMLImageElement>) => {
		const originalAspect = e.target.naturalWidth / e.target.naturalHeight;

		let crop: PercentCrop;
		if (!aspect) {
			crop = {
				unit: '%',
				width: 100,
				height: 100,
				x: 0,
				y: 0,
			};
		} else {
			// A ratio that specifies the ratio between the two aspects
			const aspectDifferenceRatio = aspect / originalAspect;

			crop = {
				unit: '%',
				width: aspectDifferenceRatio >= 1 ? 100 : 100 * aspectDifferenceRatio,
				height: aspectDifferenceRatio <= 1 ? 100 : 100 / aspectDifferenceRatio,
				x: aspectDifferenceRatio >= 1 ? 0 : (1 - aspectDifferenceRatio) * 50,
				y: aspectDifferenceRatio <= 1 ? 0 : (1 - 1 / aspectDifferenceRatio) * 50,
			};
		}

		setDimensions({
			width: e.target.naturalWidth,
			height: e.target.naturalHeight,
		});
		setActiveCrop(crop);

		handleComplete(crop);
	};

	const handleDragEnd = () => {
		if (activeCrop?.height != null && (activeCrop?.width <= 0 || activeCrop?.height <= 0)) {
			setActiveCrop(crop.current);
			return;
		}

		crop.current = activeCrop;
	};

	const handleComplete = async (percentageCrop: PercentCrop) => {
		if (percentageCrop.width <= 0 || percentageCrop.height <= 0 || !dimensions) {
			return;
		}

		const pixelCrop = {
			x: 0.01 * percentageCrop.x * dimensions.width,
			y: 0.01 * percentageCrop.y * dimensions.height,
			width: 0.01 * percentageCrop.width * dimensions.width,
			height: 0.01 * percentageCrop.height * dimensions.height,
		};
		onCropChange?.(pixelCrop);
	};

	return (
		<ReactCrop
			className='ReactCrop--no-animate'
			crop={activeCrop}
			onChange={(_, percentageCrop) => setActiveCrop(percentageCrop)}
			onDragEnd={handleDragEnd}
			onComplete={(_, percentageCrop) => handleComplete(percentageCrop)}
			ruleOfThirds
			aspect={aspect}
			style={{
				width: 'fit-content',
				height: 'fit-content',
			}}
		>
			<img
				onLoad={handleImageLoad}
				src={srcRef.current}
				draggable={false}
				style={{
					userSelect: 'none',
					objectFit: 'contain',
					minBlockSize: minBlockSize,
					writingMode:
						minBlockSize == null ? undefined
						: dimensions && dimensions.width / dimensions.height > 1 ? 'vertical-lr'
						: 'horizontal-tb',
				}}
			/>
		</ReactCrop>
	);
};

export default ImageCropper;

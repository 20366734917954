import { useEffect } from 'react';

import { Tabs, Tab, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';

import Header from './Header';
import { useStyles } from './style';
import Summary from './Summary';
import { Page } from '../../../../components';
import { isObject } from '../../../../shared/utility';
import * as actions from '../../../../store/actions';

interface PolicyDetailProps {
	policyDetails?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onFetchPolicy?(...args: unknown[]): unknown;
	onUpdateDetailsState?(...args: unknown[]): unknown;
	updatePolicy?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const PolicyDetail = (props: PolicyDetailProps) => {
	const { policyDetails, onFetchPolicy, updatePolicy, onUpdateDetailsState } = props;
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { id, tab } = useParams();
	const location = useLocation();

	const classes = useStyles();

	const {
		data: policyDetailsData,
		loading: policyDetailsLoading,
		error: policyDetailsError,
	} = policyDetails;
	const policyDetailsReady =
		isObject(policyDetailsData) && !policyDetailsLoading && !policyDetailsError;

	const { data: updatePolicyData } = updatePolicy;

	useEffect(() => {
		if (id && !policyDetailsLoading) {
			onFetchPolicy(id);
		}
	}, [id, onFetchPolicy]);

	useEffect(() => {
		if (isObject(updatePolicyData) && updatePolicyData.id === policyDetailsData.id) {
			onUpdateDetailsState('fetchPolicy', updatePolicyData);
		}
	}, [updatePolicyData, id]);

	const tabs = [{ value: 'summary', label: t('ui.summary') }];

	const handleTabsChange = (event, value) => {
		navigate(`../${value}`, { relative: 'path', state: { ...location.state } });
	};

	if (!tab) {
		return <Navigate to={'/policy-management/item-policies'} />;
	} else if (!tabs.find((t) => t.value === tab)) {
		return <Navigate to='/errors/error-404' />;
	}

	return (
		<Page className={classes.root} title={t('views.policyManagement.details.policyDetail.title')}>
			<Header loading={!policyDetailsReady} policy={policyDetailsData} />
			<Tabs
				className={classes.tabs}
				indicatorColor='primary'
				onChange={handleTabsChange}
				scrollButtons='auto'
				value={tab}
				variant='scrollable'
			>
				{tabs.map((tab) => (
					<Tab key={tab.value} label={tab.label} value={tab.value} />
				))}
			</Tabs>
			<Divider className={classes.divider} />
			<div className={classes.content}>
				{tab === 'summary' && <Summary loading={!policyDetailsReady} policy={policyDetailsData} />}
			</div>
		</Page>
	);
};

const mapStateToProps = (state) => {
	return {
		policyDetails: state.details.fetchPolicy,

		updatePolicy: state.details.updatePolicy,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchPolicy: (id) => dispatch(actions.fetchPolicy(id)),
		onUpdateDetailsState: (identifier, data) =>
			dispatch(actions.updateDetailsState(identifier, data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PolicyDetail);

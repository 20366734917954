import { useState } from 'react';

import {
	GpsFixed as GpsFixedIcon,
	ArrowDownward as ArrowDownwardIcon,
	CellTower as CellTowerIcon,
	ContentCopy as ContentCopyIcon,
	LocationOn as LocationOnIcon,
	LockOutlined as LockOutlinedIcon,
	RoomOutlined as RoomOutlinedIcon,
} from '@mui/icons-material';
import { Button, Chip, Link, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { InfoCard, InfoCardRowDef, Tooltip } from '~components';
import { useSnackbar } from '~hooks';
import { GeoCoordinate } from '~interfaces';
import { datetimeWithoutSecondsFormatter } from '~utils/dateUtils';

import { mapBatteryLevelEnum } from '../../../../shared/utility';
import DeviceTypeEnum from '../../enums/deviceTypeEnum';
import LockStateEnum from '../../enums/lockStateEnum';
import DeviceHeartbeatsService from '../../services/deviceHeartbeatsService';
import DevicesService from '../../services/devicesService';

const devicesService = new DevicesService();
const heartbeatsService = new DeviceHeartbeatsService();

const icons = {
	gpsFix: <GpsFixedIcon fontSize='small' />,
	gpsCopied: <ArrowDownwardIcon fontSize='small' />,
	cellTowerRequested: <CellTowerIcon fontSize='small' />,
	cellTowerCopied: <ContentCopyIcon fontSize='small' />,
	dockingLocation: <LocationOnIcon fontSize='small' />,
	none: '',
};

interface DeviceStatusCardProps {
	deviceId: string;
	deviceType: DeviceTypeEnum;
	isInternalItem?: boolean;
}

/**
 * A card to show the status/heartbeat of a device.
 */
const DeviceStatusCard = ({
	deviceId,
	isInternalItem = false,
	...props
}: DeviceStatusCardProps) => {
	const { t } = useTranslation();

	const { enqueueErrorSnackbar, enqueueAxiosErrorSnackbar } = useSnackbar();

	const [initialDeviceLocation, setInitialDeviceLocation] = useState(false);
	const [initialUnlockcode, setInitialUnlockcode] = useState(false);

	const isBoat = props.deviceType === DeviceTypeEnum.CpacBoat;
	const isBmw = props.deviceType === DeviceTypeEnum.Bmw;
	const isCar =
		props.deviceType === DeviceTypeEnum.VehicleTracker ||
		props.deviceType === DeviceTypeEnum.VehicleTrackerCan ||
		props.deviceType === DeviceTypeEnum.VehicleTrackerObd ||
		props.deviceType === DeviceTypeEnum.VehicleTrackerPro ||
		isBmw;

	const { data: deviceData } = useSWR([devicesService.basePath, deviceId], ([_, id]) =>
		devicesService.getDeviceById(id)
	);

	const {
		data: deviceLocationData,
		isLoading: isDeviceLocationLoading,
		isValidating: isDeviceLocationValidating,
		mutate: mutateLocation,
	} = useSWR(
		initialDeviceLocation ?
			[devicesService.basePath, deviceId, devicesService.locationSubPath]
		:	null,
		([_, id]) => devicesService.getDeviceLocation(id),
		{
			onSuccess: (data) => {
				if (data == null) {
					enqueueErrorSnackbar(t('ui.label.noLocationFound'));
				}
			},
			onError: (error) => enqueueAxiosErrorSnackbar(error, error.response?.data?.message),
		}
	);

	const { data: deviceUnlockCodeData, isLoading: isDeviceUnlockCodeLoading } = useSWR(
		initialUnlockcode ? [devicesService.basePath, deviceId, devicesService.unlockCodeSubPath] : null,
		([_, id]) => devicesService.getDeviceUnlockCode(id)
	);

	/**
	 * Notice: at the moment of writing the bmw responses will always have an
	 * empty body, because it is deprecated
	 */
	const { data: heartbeatData, isLoading: isHeartbeatLoading } = useSWR(
		[heartbeatsService.basePath, deviceId, heartbeatsService.latestHeartbeatSubPath],
		([_, id]) =>
			isBmw ? heartbeatsService.getBmwVehicleStatus(id) : heartbeatsService.getLatestHeartbeat(id)
	);

	const showLocation =
		!isInternalItem &&
		(deviceData?.communicationType.includes('lte') ||
			deviceData?.communicationType.includes('thread'));

	const handleGetLocationClick = () => {
		if (!initialDeviceLocation) {
			setInitialDeviceLocation(true);
		} else {
			mutateLocation();
		}
	};

	const allRows: InfoCardRowDef[] = [
		{
			headerName: t(isCar ? 'views.ticketsDetails.lastUpdated' : 'ui.label.lastOnline'),
			value: heartbeatData?.dateUpdated,
			renderCell: (value?: Date) =>
				value ? <Chip color='info' label={datetimeWithoutSecondsFormatter.format(value)} /> : '-',
		},
		{
			headerName: t('views.devices.heartbeatsList.deviceState'),
			value: heartbeatData?.state,
			valueFormatter: (value?: string) => (value ? t(`ui.label.device.state.${value}`) : '-'),
			hidden: isCar,
		},
		{
			headerName: t('ui.label.lockStatus'),
			value: heartbeatData?.lockState,
			valueFormatter: (value?: LockStateEnum) =>
				value ?
					t(value === LockStateEnum.Secured ? 'ui.label.lockedSecurely' : `ui.label.${value}`)
				:	'-',
		},
		{
			headerName: t('ui.label.ignitionStatus'),
			value: heartbeatData?.ignitionDisabled,
			valueFormatter: (value?: boolean) =>
				value != null ? t(value ? 'ui.disabled' : 'ui.enabled') : '-',
			hidden: !(isBoat || isCar),
		},
		{
			headerName: t('ui.label.immobiliserStatus'),
			value: heartbeatData?.immobiliserEnabled,
			valueFormatter: (value?: boolean) =>
				value != null ? t(value ? 'ui.enabled' : 'ui.disabled') : '-',
			hidden: isBmw || !(isBoat || isCar),
		},
		{
			headerName: t('ui.label.keyDetected'),
			value: heartbeatData?.keyDetected,
			// TODO: for obvious reasons change translation key
			valueFormatter: (value: boolean) => (value ? t('ui.true') : '-'),
			hidden: isBmw || !isCar,
		},
		{
			headerName: t('ui.label.doorStatus'),
			value:
				heartbeatData?.doorsState != null ?
					heartbeatData.doorsState.reduce((result, status) => result || status.open, false)
				:	undefined,
			renderCell: (value?: boolean) => {
				if (value === undefined) {
					return '-';
				}

				return value ?
						<Tooltip
							title={heartbeatData?.doorsState
								.filter((el) => el.open)
								.map((el, i) => (
									<Typography key={i}>{`${t(`ui.label.${el.location}`)}`}</Typography>
								))}
						>
							{t('ui.open')}
						</Tooltip>
					:	t('ui.openStatus.closed');
			},
			hidden: !isCar,
		},
		{
			headerName: t('ui.label.windowStatus'),
			value:
				heartbeatData?.windowsState != null ?
					heartbeatData?.windowsState?.reduce((result, status) => result || status.open, false)
				:	undefined,
			renderCell: (value?: boolean) => {
				if (value === undefined) {
					return '-';
				}

				return value ?
						<Tooltip
							title={heartbeatData?.windowsState
								?.filter((el) => el.open)
								.map((el, i) => (
									<Typography key={i}>{`${t(`ui.label.${el.location}`)}`}</Typography>
								))}
						>
							<Typography>{t('ui.open')}</Typography>
						</Tooltip>
					:	t('ui.openStatus.closed');
			},
			hidden: !isBmw,
		},
		{
			headerName: t('ui.label.chargingStatus'),
			value: heartbeatData?.batteryIsCharging,
			valueFormatter: (value: boolean) => (value ? t('ui.label.device.state.charging') : '-'),
			hidden: !(isCar || isBoat),
		},
		{
			headerName: t('ui.label.battery'),
			value:
				props.deviceType === DeviceTypeEnum.DoorLock ?
					heartbeatData?.deviceBatteryLevelEnum
				:	heartbeatData?.deviceBatteryLevel,
			valueFormatter: (value?: number | string) => {
				if (typeof value === 'string') {
					return mapBatteryLevelEnum(value);
				}

				return value ? `${value}%` : '-';
			},
			hidden: isBoat,
		},
		{
			headerName: t('ui.label.secondaryBattery'),
			value: heartbeatData?.externalBatteryLevel,
			hidden: props.deviceType !== DeviceTypeEnum.TrailerLock,
		},
		{
			headerName: t('ui.label.vehicleBattery'),
			value: heartbeatData?.vehicleBatteryLevel,
			valueFormatter: (value: number) => (value ? `${value}%` : '-'),
			hidden: !(isCar || isBoat),
		},
		{
			headerName: t('ui.label.fuelLevelBMW'),
			value: heartbeatData?.vehicleFuelVolume,
			valueFormatter: (value: number) => (value ? `${value} liter` : '-'),
			hidden: !(isCar || isBoat),
		},
		{
			headerName: t('ui.device.label.reachCombustion'),
			value: heartbeatData?.rangeCombustion,
			valueFormatter: (value: number) => value ?? '-',
			hidden: !(isBmw || isBoat),
		},
		{
			headerName: t('ui.device.label.reachElectric'),
			value: heartbeatData?.rangeElectric,
			valueFormatter: (value: number) => value ?? '-',
			hidden: !(isCar || isBoat),
		},
		{
			headerName: t('ui.device.label.reachTotal'),
			value: heartbeatData?.range,
			valueFormatter: (value: number) => value || '-',
			hidden: !(isCar || isBoat),
		},
		{
			headerName: t('ui.label.mileage'),
			value: heartbeatData?.mileage,
			hidden: !isCar,
		},
		{
			headerName: t('ui.label.temperature'),
			value: heartbeatData?.temperature,
			valueFormatter: (value?: number) => (value ? `${value}˚C` : '-'),
			hidden: isCar,
		},
		{
			headerName: t('ui.label.firmwareVersion'),
			value: heartbeatData?.firmwareVersion,
			hidden: isCar || isBoat,
		},
		{
			headerName: t('ui.label.location'),
			value: heartbeatData?.location ?? deviceLocationData,
			renderCell: (value?: GeoCoordinate) =>
				value ?
					<Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
						<Link
							rel='noreferrer noopener'
							target='_blank'
							href={`https://google.com/maps/place/${value.latitude},${value.longitude}`}
						>
							{`${value.latitude}, ${value.longitude}`}
						</Link>
						{heartbeatData != null &&
							heartbeatData?.locationType != 'none' &&
							!isCar &&
							!isBoat && (
								<Tooltip
									placement='bottom'
									title={t(`views.devices.heartbeatsList.icon.${heartbeatData?.locationType}`)}
								>
									{icons[heartbeatData.locationType]}
								</Tooltip>
							)}
					</Stack>
				:	<Button
						startIcon={<RoomOutlinedIcon />}
						onClick={handleGetLocationClick}
						loadingPosition='start'
						loading={isDeviceLocationLoading || isDeviceLocationValidating}
					>
						{t('ui.label.getGps')}
					</Button>,
			hidden: !(showLocation || props.deviceType === DeviceTypeEnum.DoorLock),
		},
		{
			headerName: t('ui.label.unlockCode'),
			value: deviceUnlockCodeData?.code,
			renderCell: (value?: number) =>
				value ?? (
					<Button
						startIcon={<LockOutlinedIcon />}
						onClick={() => setInitialUnlockcode(true)}
						loadingPosition='start'
						loading={isDeviceUnlockCodeLoading}
					>
						{t('ui.button.contained.getUnlockCode')}
					</Button>
				),
			hidden: !showLocation || !deviceData?.identificationType.includes('totp'), // || TODO: identificationTypes
		},
		{
			headerName: t('views.devices.details.deviceStatus.unlockCodeValid'),
			value: deviceUnlockCodeData?.adjustedEndDate,
			valueFormatter: (value?: Date) => datetimeWithoutSecondsFormatter.format(value),
			hidden: deviceUnlockCodeData == null,
		},
	];

	const otherLockRows: InfoCardRowDef[] = [
		{
			headerName: t('ui.label.lastOnline'),
			value: heartbeatData?.dateUpdated,
			renderCell: (value?: Date) =>
				value ? <Chip color='info' label={datetimeWithoutSecondsFormatter.format(value)} /> : '-',
		},
		{
			headerName: t('views.devices.heartbeatsList.deviceState'),
			value: heartbeatData?.state,
			valueFormatter: (value?: string) => (value ? t(`ui.label.device.state.${value}`) : '-'),
		},
		{
			headerName: t('ui.label.lockStatus'),
			value: heartbeatData?.lockState,
			valueFormatter: (value?: string) => (value ? t(`ui.label.${value}`) : '-'),
		},
		{
			headerName: t('ui.label.battery'),
			value:
				props.deviceType === DeviceTypeEnum.DoorLock ?
					heartbeatData?.deviceBatteryLevelEnum
				:	heartbeatData?.deviceBatteryLevel,
			valueFormatter: (value?: number | string) => {
				if (typeof value === 'string') {
					return mapBatteryLevelEnum(value);
				}

				return value ? `${value}%` : '-';
			},
			hidden: isBoat,
		},
		{
			headerName: t('ui.label.secondaryBattery'),
			value: heartbeatData?.externalBatteryLevel,
			hidden: props.deviceType !== DeviceTypeEnum.TrailerLock,
		},
		{
			headerName: t('ui.label.temperature'),
			value: heartbeatData?.temperature,
			valueFormatter: (value?: string) => `${value}˚C`,
		},
		{
			headerName: t('ui.label.firmwareVersion'),
			value: heartbeatData?.firmwareVersion,
		},
		{
			headerName: t('ui.label.location'),
			value: heartbeatData?.location ?? deviceLocationData,
			renderCell: (value?: GeoCoordinate) =>
				value ?
					<Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
						<Link
							rel='noreferrer noopener'
							target='_blank'
							href={`https://google.com/maps/place/${value.latitude},${value.longitude}`}
						>
							{`${value.latitude}, ${value.longitude}`}
						</Link>
						{heartbeatData != null && heartbeatData?.locationType != 'none' && (
							<Tooltip
								placement='bottom'
								title={t(`views.devices.heartbeatsList.icon.${heartbeatData?.locationType}`)}
							>
								{icons[heartbeatData.locationType]}
							</Tooltip>
						)}
					</Stack>
				:	<Button
						startIcon={<RoomOutlinedIcon />}
						onClick={handleGetLocationClick}
						loadingPosition='start'
						loading={isDeviceLocationLoading || isDeviceLocationValidating}
					>
						{t('ui.label.getGps')}
					</Button>,
			hidden: !showLocation,
		},
		{
			headerName: t('ui.label.unlockCode'),
			value: deviceUnlockCodeData?.code,
			renderCell: (value?: number) =>
				value ?? (
					<Button
						startIcon={<LockOutlinedIcon />}
						onClick={() => setInitialUnlockcode(true)}
						loadingPosition='start'
						loading={isDeviceUnlockCodeLoading}
					>
						{t('ui.button.contained.getUnlockCode')}
					</Button>
				),
			hidden: !showLocation || !deviceData?.identificationType.includes('totp'), // || TODO: identificationTypes
		},
		{
			headerName: t('views.devices.details.deviceStatus.unlockCodeValid'),
			value: deviceUnlockCodeData?.adjustedEndDate,
			valueFormatter: (value?: Date) => datetimeWithoutSecondsFormatter.format(value),
			hidden: deviceUnlockCodeData == null,
		},
	];

	const carRows: InfoCardRowDef[] = [
		{
			headerName: t('views.ticketsDetails.lastUpdated'),
			value: heartbeatData?.dateUpdated,
			renderCell: (value?: Date) =>
				value ? <Chip color='info' label={datetimeWithoutSecondsFormatter.format(value)} /> : '-',
		},
		{
			headerName: t('views.devices.heartbeatsList.deviceState'),
			value: heartbeatData?.state,
			valueFormatter: (value?: string) => (value ? t(`ui.label.device.state.${value}`) : '-'),
			hidden: !isBoat,
		},
		{
			headerName: t('ui.label.lockStatus'),
			value: heartbeatData?.lockState,
			valueFormatter: (value?: LockStateEnum) =>
				value ?
					t(value === LockStateEnum.Secured ? 'ui.label.lockedSecurely' : `ui.label.${value}`)
				:	'-',
		},
		{
			headerName: t('ui.label.ignitionStatus'),
			value: heartbeatData?.ignitionDisabled,
			valueFormatter: (value?: boolean) =>
				value != null ? t(value ? 'ui.disabled' : 'ui.enabled') : '-',
		},
		{
			headerName: t('ui.label.immobiliserStatus'),
			value: heartbeatData?.immobiliserEnabled,
			valueFormatter: (value?: boolean) =>
				value != null ? t(value ? 'ui.enabled' : 'ui.disabled') : '-',
			hidden: isBmw,
		},
		{
			headerName: t('ui.label.keyDetected'),
			value: heartbeatData?.keyDetected,
			// TODO: for obvious reasons change translation key
			valueFormatter: (value: boolean) => (value ? t('ui.true') : '-'),
			hidden: isBmw || isBoat,
		},
		{
			headerName: t('ui.label.doorStatus'),
			value:
				heartbeatData?.doorsState != null ?
					heartbeatData.doorsState.reduce((result, status) => result || status.open, false)
				:	undefined,
			renderCell: (value?: boolean) => {
				if (value === undefined) {
					return '-';
				}

				return value ?
						<Tooltip
							title={heartbeatData?.doorsState
								.filter((el) => el.open)
								.map((el, i) => (
									<Typography key={i}>{`${t(`ui.label.${el.location}`)}`}</Typography>
								))}
						>
							{t('ui.open')}
						</Tooltip>
					:	t('ui.openStatus.closed');
			},
			hidden: isBoat,
		},
		{
			headerName: t('ui.label.windowStatus'),
			value:
				heartbeatData?.windowsState?.reduce((result, status) => result || status.open, false) ??
				false,
			renderCell: (value: boolean) =>
				value ?
					<Tooltip
						title={heartbeatData?.windowsState
							?.filter((el) => el.open)
							.map((el, i) => <Typography key={i}>{`${t(`ui.label.${el.location}`)}`}</Typography>)}
					>
						<Typography>{t('ui.open')}</Typography>
					</Tooltip>
				:	t('ui.openStatus.closed'),
			hidden: !isBmw,
		},
		{
			headerName: t('ui.label.temperature'),
			value: heartbeatData?.temperature,
			valueFormatter: (value?: number) => (value ? `${value}˚C` : '-'),
		},
		{
			headerName: t('ui.label.chargingStatus'),
			value: heartbeatData?.batteryIsCharging,
			valueFormatter: (value: boolean) => (value ? t('ui.label.device.state.charging') : '-'),
		},
		{
			headerName: t('ui.label.battery'),
			value: heartbeatData?.deviceBatteryLevel,
			valueFormatter: (value: number) => (value ? `${value}%` : '-'),
			hidden: isBoat,
		},
		{
			headerName: t('ui.label.vehicleBattery'),
			value: heartbeatData?.vehicleBatteryLevel,
			valueFormatter: (value: number) => (value ? `${value}%` : '-'),
		},
		{
			headerName: t('ui.label.fuelLevelBMW'),
			value: heartbeatData?.vehicleFuelVolume,
			valueFormatter: (value: number) => (value ? `${value} liter` : '-'),
		},
		{
			// BWM
			headerName: t('ui.device.label.reachCombustion'),
			value: heartbeatData?.rangeCombustion,
			valueFormatter: (value: number) => value ?? '-',
			hidden: !(isBmw || isBoat),
		},
		{
			headerName: t('ui.device.label.reachElectric'),
			value: heartbeatData?.rangeElectric,
			valueFormatter: (value: number) => value ?? '-',
		},
		{
			headerName: t('ui.device.label.reachTotal'),
			value: heartbeatData?.range,
			valueFormatter: (value: number) => value || '-',
		},
		{
			headerName: t('ui.label.mileage'),
			value: heartbeatData?.mileage,
			hidden: isBoat,
		},
		{
			headerName: t('ui.label.location'),
			value: heartbeatData?.location,
			renderCell: (value?: GeoCoordinate) =>
				value ?
					<Link
						rel='noreferrer noopener'
						target='_blank'
						href={`https://google.com/maps/place/${value.latitude},${value.longitude}`}
					>
						{`${value.latitude}, ${value.longitude}`}
					</Link>
				:	'-',
		},
	];

	// const rows = isCar || isBoat ? carRows : otherLockRows;

	return (
		<InfoCard
			title={t('ui.label.deviceStatus')}
			rows={allRows}
			loading={isHeartbeatLoading}
			version='legacy'
		/>
	);
};

export default DeviceStatusCard;

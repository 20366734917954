import { useEffect, useState } from 'react';

import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import { Typography, Hidden, Box } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useStyles } from './style';
import { LoadingBar, StyledButton } from '../../../../../components';
import { isFullString } from '../../../../../shared/utility'; // import { useDetailPageBackButton } from '../../../shared/hooks';
import { useAuthorize } from '~features/authentication';

interface HeaderProps {
	className?: string;
	type?: 'personal' | 'expenses' | 'revenue';
	revenueData?: object;
	loading?: boolean;
	title?: string;
	month?: string;
	route?: string;
	date?: string;
	total?: string;
}

const Header = (props: HeaderProps) => {
	const { className, title, date, total } = props;
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { isSuperAdmin } = useAuthorize();

	const classes = useStyles();
	const [pathName, setPathName] = useState('');
	const [buttonName, setButtonName] = useState('');
	const [titleName, setTitleName] = useState('');

	useEffect(() => {
		if (isSuperAdmin()) {
			setPathName(`/financeManagement/revenue/${date}/${title}/organisations/revenue`);
			setButtonName(title);
			setTitleName(title);
		} else {
			setPathName('/financeManagement/revenue');
			setButtonName(t('nav.financialReports.revenue'));
			setTitleName(title);
		}
	}, [isSuperAdmin]);

	const handleBackButtonChange = () => navigate(pathName);

	return (
		<div className={clsx(classes.root, className)}>
			<Box>
				<Typography component='h2' gutterBottom variant='overline'>
					<Hidden only={['xs', 'sm']}>
						<StyledButton
							className={classes.backButton}
							onClick={handleBackButtonChange}
							startIcon={<KeyboardArrowLeftOutlinedIcon />}
							variant='inline-default'
						>
							{buttonName}
						</StyledButton>
					</Hidden>
				</Typography>
				<Typography component='h1' variant='h3'>
					{!isFullString(titleName) ?
						<LoadingBar />
					:	titleName}
				</Typography>
			</Box>
			<Box pr={2}>
				<Typography variant='h6'>
					{!isFullString(total) ?
						<LoadingBar />
					:	`${t('ui.label.finance.totaExclVat')}: ${total}`}
				</Typography>
			</Box>
		</div>
	);
};

export default Header;

import { StrictMode, Suspense } from 'react';

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider } from 'react-oidc-context';
import { Provider } from 'react-redux';
import { SWRConfig } from 'swr';

// Notice we import this by the full path path. Keep it like this.
// Importing from index initializes some components too soon
import LoadingFallback from '~components/fallbacks/loadingFallback';
import { oidcConfig } from '~features/authentication';
import { GenericLayout } from '~layouts';

import App from './app';
// import theme from './themes/theme';
import store from './store/store';
import theme from './theme';

// Load in libraries or extensions of it
import './lib/i18n';
import './lib/date';
import './lib/dayjs';

import './index.css';

if (import.meta.env.DEV) {
	import('./lib/viteErrorOverlay');

	if (import.meta.env.VITE_DEV_MOCK_API === 'true') {
		const { worker } = await import('./mocks/browser');
		await worker.start({ onUnhandledRequest: 'bypass' });
	}
}

// For deployment information
console.info(`Build date: ${import.meta.env.VITE_BUILD_DATETIME}`);
if (import.meta.env.VITE_BUILD_NUMBER) {
	console.info(`Build number: ${import.meta.env.VITE_BUILD_NUMBER}`);
}

// TODO: setting up the theme should be move to the app.tsx over time
const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<StrictMode>
		<HelmetProvider>
			<Suspense
				fallback={
					<GenericLayout>
						<LoadingFallback />
					</GenericLayout>
				}
			>
				<Provider store={store}>
					<AuthProvider {...oidcConfig}>
						<SWRConfig
							value={{
								refreshInterval: 60 * 60 * 1000,
								onError: (error) => console.error(error),
							}}
						>
							<StyledEngineProvider injectFirst>
								<ThemeProvider theme={theme}>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<App />
									</LocalizationProvider>
								</ThemeProvider>
							</StyledEngineProvider>
						</SWRConfig>
					</AuthProvider>
				</Provider>
			</Suspense>
		</HelmetProvider>
	</StrictMode>,
);

import { Tabs, Tab, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useLocation, useParams } from 'react-router-dom';
import useSWR from 'swr';

import { OrganisationsService } from '~features/organisations';
import SsoDomainsPage from '~pages/organisations/ssoDomainsPage';

import EmailDomains from './EmailDomains';
import Header from './Header';
import { useStyles } from './style';
import Summary from './Summary';
import { Page } from '../../../../components';
import BookingList from '../../../Bookings/BookingList';

const service = new OrganisationsService();

interface OrganisationDetailProps {
}

const OrganisationDetail = (props: OrganisationDetailProps) => {
	const location = useLocation();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const classes = useStyles();

	const { id, tab } = useParams();

	const { data, isLoading, error } = useSWR(
		id != null ? [service.basePath, id, 'unmapped'] : null,
		([_, arg]) => service.getOrganisationByIdUnmapped(arg)
	)

	const handleTabsChange = (event, value) => {
		navigate(`../${value}`, { relative: 'path', state: { ...location.state } });
	};

	const verified = data?.isVerified ?? false;

	const tabs =
		!verified ?
			[{ value: 'summary', label: t('ui.summary') }]
		:	[
				{ value: 'summary', label: t('ui.summary') },
				{ value: 'bookings', label: t('nav.category.bookings') },
				{ value: 'email-domains', label: t('views.emailDomains.title') },
				{ value: 'sso-domains', label: t('ssoDomains') },
			];

	if (!tabs.find((t) => t.value === tab)) {
		return <Navigate to={`/organisations/${id}/summary`} />;
	}

	return (
		<Page className={classes.root} title={t('views.organisationDetail.page.title')}>
			<Header
				data={data}
				loading={isLoading}
				name={data?.name ?? '-'}
			/>
			<Tabs
				className={classes.tabs}
				indicatorColor='primary'
				onChange={handleTabsChange}
				scrollButtons='auto'
				value={tab}
				variant='scrollable'
			>
				{tabs.map((tab) => (
					<Tab key={tab.value} label={tab.label} value={tab.value} />
				))}
			</Tabs>
			<Divider className={classes.divider} />
			{verified ?
				<div className={classes.content}>
					{tab === 'summary' && (
						<Summary
							organisationId={id}
							verified={verified}
						/>
					)}
					{tab === 'bookings' && verified && <BookingList organisationId={parseInt(id, 10)} />}
					{tab === 'email-domains' && <EmailDomains organisationId={id} />}
					{tab === 'sso-domains' && <SsoDomainsPage organisationId={id} />}
				</div>
			:	<div className={classes.content}>
					{tab === 'summary' && (
						<Summary
							organisationId={id}
						/>
					)}
				</div>
			}
		</Page>
	);
};

export default OrganisationDetail;

import { useEffect, useRef, useState } from 'react';

import { Box } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import useSWRMutation from 'swr/mutation';

import { ConfirmationDialog, FormDialog } from '~components';
import { useSnackbar } from '~hooks';

import selectedOrganisationAtom from '../atoms/selectedOrganisationAtom';
import SchedulesDataGrid from '../components/dataGrids/schedulesDataGrid';
import AccessSchedulesForm from '../forms/accessSchedulesForm/accessSchedulesForm';
import { SkcScheduleNew } from '../interfaces/skcSchedule';
import SkcSchedulesService from '../services/skcSchedulesService';

type UserAction =
	| {
			type: 'delete' | 'edit';
			label?: string;
			id: string;
	  }
	| {
			type: 'create';
			label?: never;
			id?: never;
	  };

const service = new SkcSchedulesService();

const SchedulesOverviewLayout = () => {
	const { t } = useTranslation();
	const { enqueueSuccessSnackbar, enqueueAxiosErrorSnackbar } = useSnackbar();
	const [userAction, setUserAction] = useState<UserAction | null>(null);
	const dataGridRef = useRef<{ mutate: () => void }>(null);
	const selectedOrganisation = useAtomValue(selectedOrganisationAtom);

	const { trigger: triggerCreate, isMutating: isCreateMutating } = useSWRMutation(
		service.basePath,
		(_, { arg }: { arg: SkcScheduleNew }) => service.createSchedule(arg),
		{
			onSuccess: () => {
				enqueueSuccessSnackbar(`${t('ui.successfullyAdded')} ${t('schedule').toLowerCase()}`);
				setUserAction(null);
				dataGridRef.current?.mutate();
			},
			onError: (error) => enqueueAxiosErrorSnackbar(error),
		}
	);

	const { trigger: triggerEdit, isMutating: isEditMutating } = useSWRMutation(
		userAction?.id ? [service.basePath, userAction.id] : null,
		([_, id], { arg }: { arg: SkcScheduleNew }) => service.updateSchedule(id, arg),
		{
			onSuccess: () => {
				enqueueSuccessSnackbar(`${t('ui.success.message.updated')} ${t('schedule').toLowerCase()}`);
				setUserAction(null);
				dataGridRef.current?.mutate();
			},
			onError: (error) => enqueueAxiosErrorSnackbar(error),
		}
	);

	const { trigger: triggerDelete, isMutating: isDeleteMutating } = useSWRMutation(
		userAction?.id ? [service.basePath, userAction.id] : null,
		([_, id]) => service.deleteSchedule(id),
		{
			onSuccess: () => {
				enqueueSuccessSnackbar(`${t('ui.successfullyDeleted')} ${t('schedule').toLowerCase()}`);
				setUserAction(null);
				dataGridRef.current?.mutate();
			},
			onError: (error) => enqueueAxiosErrorSnackbar(error),
		}
	);

	useEffect(() => {
		// Should be temporary
		if (selectedOrganisation) {
			service.organisation = selectedOrganisation;
		}
	}, [selectedOrganisation]);

	return (
		<Box
			sx={{
				height: 1,
			}}
		>
			<SchedulesDataGrid
				ref={dataGridRef}
				onEdit={(value) => setUserAction({ type: 'edit', ...value })}
				onDelete={(value) => setUserAction({ type: 'delete', ...value })}
				onCreate={() => setUserAction({ type: 'create' })}
			/>
			<FormDialog
				open={userAction?.type === 'edit' || userAction?.type === 'create'}
				title={t(userAction?.type === 'create' ? 'addResource' : 'editResource', {
					resource: t('schedule'),
				})}
				loading={isCreateMutating || isEditMutating}
				onClose={() => setUserAction(null)}
				maxWidth='xl'
			>
				<AccessSchedulesForm
					id={userAction?.id}
					onSubmit={async (data) => {
						if (userAction?.type === 'create') {
							await triggerCreate(data);
						} else if (userAction?.type === 'edit') {
							await triggerEdit(data);
						} else {
							console.error('Useraction not supported', userAction);
						}
					}}
				/>
			</FormDialog>
			<ConfirmationDialog
				loading={isDeleteMutating}
				open={userAction?.type === 'delete'}
				subTitle={t('thisWillDeleteResourceWithName', {
					resource: t('schedule'),
					name: userAction?.label ?? userAction?.id,
				})}
				onConfirm={async () => await triggerDelete()}
				onClose={() => setUserAction(null)}
			/>
		</Box>
	);
};

export default SchedulesOverviewLayout;

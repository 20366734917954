import path from 'path';

import { PeriodicityEnum } from '~enums';
import { Hub } from '~features/hub';
import { BaseReference, ListResults, PagedResults } from '~interfaces';
import { DateRange } from '~interfaces/dateRanges';
import { BasePagedParameters, StatisticsFilterParameters } from '~interfaces/requests';
import { AddressResponse, BaseReferenceResponse, PagedResponse } from '~interfaces/responses';
import { TopologyService } from '~services';

interface FinanceStat {
	timestamp: Date;
	revenue: number; // in euros?
}

interface ItemFinanceStat extends BaseReference {
	revenue: number;
	itemGroup: BaseReference;
	hub: Hub;
	bookingCount: number;
}

class FinanceStatisticsService extends TopologyService {
	public readonly path = 'graphs/revenues';

	constructor() {
		super('v1');
	}

	async getRevenues({
		...args
	}: {
		organisationId?: string;
		period: DateRange;
		periodicity?: PeriodicityEnum;
	} & StatisticsFilterParameters): Promise<ListResults<FinanceStat>> {
		const filters = FinanceStatisticsService.mapRequestFilterParameters(args);

		const { data } = await this._client.get<FinanceStatResponse>(this.path, {
			params: {
				...filters,
				organisationId: args.organisationId,
				dateAfter: args.period.start,
				dateBefore: args.period.end,
				step: args.periodicity,
			},
		});

		return {
			total: data.revenues,
			results: data.billings.map((el) => ({
				timestamp: new Date(el.dateTime),
				revenue: el.revenues,
			})),
		};
	}

	async getItemRevenues({
		page = 1,
		pageSize = 5,
		sortBy = 'id',
		descending = true,
		...args
	}: {
		period: DateRange;
		sortBy?: 'id' | 'item' | 'hub' | 'revenue' | 'bookingCount' | 'average';
		descending?: boolean;
	} & BasePagedParameters &
		StatisticsFilterParameters): Promise<PagedResults<ItemFinanceStat>> {
		const mappedSortBy = (() => {
			switch (sortBy) {
				case 'bookingCount':
					return 'bookings';
				case 'hub':
					return 'location';
				case 'item':
					return 'name';
				case 'revenue':
					return 'revenues';
				default:
					return sortBy;
			}
		})();
		const filters = FinanceStatisticsService.mapRequestFilterParameters(args);

		const { data } = await this._client.get<PagedResponse<ItemFinanceStatResponse>>(
			path.join(this.path, 'list'),
			{
				params: {
					...filters,
					organisationId: args.organisationId,
					pageNumber: page,
					pageSize: pageSize,
					dateAfter: args.period.start,
					dateBefore: args.period.end,
					sortBy: mappedSortBy,
					orderDescending: descending,
				},
			},
		);

		return this.mapPagedResponse(data, FinanceStatisticsService.fromResponse);
	}

	static fromResponse(data: ItemFinanceStatResponse): ItemFinanceStat {
		const { id, name, item, location, dateTime, bookings, revenues, ...rest } = data;

		return {
			...FinanceStatisticsService.fromBaseReferenceResponse({
				id: id,
				name: name ?? rest.boatNumber,
			}),
			bookingCount: bookings,
			revenue: revenues,
			itemGroup: FinanceStatisticsService.fromBaseReferenceResponse(item),
			hub: {
				id: location.id.toString(),
				label: location.name,
				address: {
					country: location.address.country,
					city: location.address.city,
					postalCode: location.address,
					street: location.address.street,
					number: location.address.number != null ? Number(location.address.number) : undefined
				}
			},
			...rest,
		};
	}
}

interface ItemFinanceStatResponse extends BaseReferenceResponse {
	bookings: number;
	revenues: number;
	item: BaseReferenceResponse;
	location: {
		address: AddressResponse;
	} & BaseReferenceResponse;
}

interface FinanceStatResponse {
	billings: {
		dateTime: string;
		revenues: number;
	}[];
	revenues: number;
}

export default FinanceStatisticsService;

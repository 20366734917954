enum PaymentMethodStatusEnum {
	Unavailable = 'unavailable',
	Started = 'started',
	Pending = 'pending',
	Authorized = 'authorized',
	Rejected = 'rejected',
	Disabled = 'disabled'
}

export default PaymentMethodStatusEnum;

import { useState, useEffect, useRef } from 'react';

import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { Box, Link, Avatar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { useAuthorize } from '~features/authentication';
import { useDebounce } from '~hooks';
import { getInitials } from '~utils/stringUtils';

import Header from './Header';
import MonthlyFinance from './MonthlyFinance';
import { useStyles } from './style';
import { Table, Label, SearchBar, GenericMoreButton, Page } from '../../../../components';
import { exportType } from '../../../../constantsOld';
import { localizeDateTime } from '../../../../shared/datetime';
import {
	isObject,
	isEmptyString,
	decimalBase,
	isFullString,
	isNull,
	isNumber,
	isFullArray,
	isEmptyArray,
	decimalAmount,
	getFinanceTableHeaders,
} from '../../../../shared/utility';
import * as actions from '../../../../store/actions';

interface OrganisationalBillingProps {
	onFetchBusinessExpenses?(...args: unknown[]): unknown;
	businessExpenses?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onFetchPersonalBillingPeriodByDate?(...args: unknown[]): unknown;
	onBillingReport?(...args: unknown[]): unknown;
	billingReport?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onPayBillingPeriod?(...args: unknown[]): unknown;
	payBillingPeriod?: object;
}

const OrganisationalBilling = (props: OrganisationalBillingProps) => {
	const {
		onFetchBusinessExpenses,
		businessExpenses,

		onBillingReport,
		billingReport,
		match,
	} = props;
	const { t } = useTranslation();
	const { isSuperAdmin } = useAuthorize();

	const classes = useStyles();

	const { date, title, id, name } = useParams();

	const [shouldDoInitialFetch, setShouldDoInitialFetch] = useState(true);
	const [showingInitialResults, setShowingInitialResults] = useState(true);
	const [download, setDownload] = useState(false);
	const [exportTypeLocal, setExportTypeLocal] = useState('csv');
	const [itemToDownload, setItemToDownload] = useState(null);
	const [searchValue, setSearchValue] = useState('');
	const [shouldSearch, setShouldSearch] = useState(false);
	const [isShowingSearchResults, setIsShowingSearchResults] = useState(false);

	const linkRef = useRef(null);
	const [monthlyTotal, setMonthlyTotal] = useState('');
	const [total, setTotal] = useState('');

	const { data: businessExpensesData, loading: businessExpensesLoading } = businessExpenses;

	const { data: billingReportData, loading: billingReportLoading } = billingReport;

	const shouldSearchOnDebounce = false;

	const filters = {
		...(!isEmptyString(searchValue) && { searchTerm: searchValue }),
		...(isFullString(id) && { id: id }),
	};

	useEffect(() => {
		if (shouldSearch || shouldDoInitialFetch) {
			if (shouldSearch && showingInitialResults) {
				setShowingInitialResults(false);
			}
			if (date && !businessExpensesLoading) {
				onFetchBusinessExpenses(date, filters);
			}
		}
		if (shouldSearch) {
			setShouldSearch(false);
		} else if (shouldDoInitialFetch) {
			setShouldDoInitialFetch(false);
		}
	}, [date, shouldSearch, shouldDoInitialFetch]);

	const handleSearchClick = () => {
		if (!isEmptyString(searchValue)) {
			setShouldSearch(true);
			setIsShowingSearchResults(true);
		}
		if (isEmptyString(searchValue) && isShowingSearchResults) {
			setShouldSearch(true);
			setIsShowingSearchResults(false);
		}
	};

	const handleResetSearch = () => {
		setSearchValue('');
		if (isShowingSearchResults === true) {
			setShouldSearch(true);
		}
		setIsShowingSearchResults(false);
	};

	const handleKeyUp = (e) => {
		switch (e.key) {
			case 'Enter':
				handleSearchClick();
				break;
			default:
				return;
		}
	};

	const events = {
		onClick: handleSearchClick,
		onChange: (e) => setSearchValue(e.target.value),
		onClear: handleResetSearch,
		onKeyUp: (e) => handleKeyUp(e),
	};

	const debouncedSearchValue = useDebounce(searchValue, 500);

	useEffect(() => {
		if (shouldSearchOnDebounce) {
			handleSearchClick();
		}
	}, [shouldSearchOnDebounce, debouncedSearchValue]);

	const handleClickDownloadReport = (billingItem, exportType) => {
		const organisationId = isSuperAdmin() ? parseInt(id) : null;
		onBillingReport(
			'expenses',
			date,
			billingItem.organisationReference.id,
			organisationId,
			exportType,
		);
		setDownload(true);
		setItemToDownload(billingItem);
	};

	useEffect(() => {
		if (!billingReportLoading && !isNull(billingReportData) && download) {
			const itemToDownloadLocal = `-${localizeDateTime(itemToDownload.end)}`;
			const instanceName = `${itemToDownload.organisationReference ? itemToDownload.organisationReference.name : itemToDownload.userReference.name}_${localizeDateTime(itemToDownload.start)}${itemToDownload.end === '0001-01-01T00:00:00' ? '' : itemToDownloadLocal}`;
			linkRef.current.href = URL.createObjectURL(billingReportData);
			linkRef.current.download = `${instanceName}.${exportTypeLocal}`;
			linkRef.current.click();
			setDownload(false);
		}
	}, [billingReportData, download]);

	useEffect(() => {
		if (isObject(businessExpensesData) && isNumber(monthlyTotal)) {
			setTotal(
				`${decimalBase(monthlyTotal + businessExpensesData.amount.subtotal, businessExpensesData.amount.currencySymbol)}`,
			);
		}
	}, [monthlyTotal, businessExpensesLoading]);

	const createMoreMenuItems = (billingItem) => [
		{
			icon: <InsertDriveFileOutlinedIcon />,
			text: t('ui.downloadReport.csv'),
			action: () => {
				handleClickDownloadReport(billingItem, exportType.CSV);
				setExportTypeLocal(exportType.CSV);
			},
		},
		{
			icon: <InsertDriveFileOutlinedIcon />,
			text: t('ui.downloadReport.xlsx'),
			action: () => {
				handleClickDownloadReport(billingItem, exportType.XLSX);
				setExportTypeLocal(exportType.XLSX);
			},
		},
	];

	const headers = getFinanceTableHeaders(true);

	const tableHeader = [{ name: 'partners', content: t('ui.category.partners') }, ...headers];

	const statusColor = {
		open: 'default',
		paid: 'success',
	};

	const statusText = {
		open: t('ui.open'),
		paid: t('ui.label.paid'),
	};

	const emptyBody =
		(
			isObject(businessExpensesData) &&
			!businessExpensesLoading &&
			isEmptyArray(businessExpensesData.billings)
		) ?
			Array(1)
				.fill(Array(tableHeader.length).fill())
				.map((arr) => arr.map(() => ({ content: '-' })))
		:	null;

	const loadingBody =
		!isObject(businessExpensesData) || businessExpensesLoading ?
			Array(3)
				.fill(Array(tableHeader.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })))
		:	null;

	const dataBody =
		(
			isObject(businessExpensesData) &&
			!businessExpensesLoading &&
			isFullArray(businessExpensesData.billings)
		) ?
			businessExpensesData.billings.map((expenses) => [
				{
					content: (
						<Box alignItems='center' display='flex' height='19px' width='250px'>
							{expenses.organisationReference.logo ?
								<Avatar src={expenses.organisationReference.logo} variant='square' />
							:	<Avatar variant='square'>{getInitials(expenses.organisationReference.name)}</Avatar>}
							<Box pl={1}>
								<Link
									className={classes.link}
									color='inherit'
									component={RouterLink}
									to={`/financeManagement/expenses/${expenses.start}/${title}/partner/${expenses.organisationReference.id}/${expenses.organisationReference.name}/expenses/bookings`}
									state={{
										organisationId: id,
									}}
								>
									{`${expenses.organisationReference.name}`}
								</Link>
							</Box>
						</Box>
					),
				},
				{ content: decimalAmount(expenses.amount.subtotal, expenses.amount) },
				{ content: decimalAmount(expenses.amount.discount, expenses.amount) },
				{
					content: decimalAmount(
						expenses.amount.subtotal - expenses.amount.discount,
						expenses.amount,
					),
				},
				{ content: decimalAmount(expenses.amount.priceVat, expenses.amount) },
				{ content: decimalAmount(expenses.amount.refund, expenses.amount) },
				{ content: decimalAmount(expenses.amount.totalPrice, expenses.amount) },
				{ content: decimalAmount(expenses.open, expenses.amount) },
				{
					content: (
						<Box alignItems='center' display='flex' justifyContent='space-between'>
							<Label type={statusColor[expenses.status]}>{statusText[expenses.status]}</Label>
							<GenericMoreButton menuItems={createMoreMenuItems(expenses)} />
						</Box>
					),
				},
			])
		:	null;

	const titleWithInformation =
		isObject(businessExpensesData) ?
			{
				header: t('ui.category.organisations'),
				sideHeader: decimalBase(
					businessExpensesData.amount.subtotal,
					businessExpensesData.amount.currencySymbol,
				),
			}
		:	null;

	return (
		<Page className={classes.root} title={t('ui.details')}>
			<Header date={date} id={id} name={name} title={title} total={total} />
			<Box pb={3} pt={3}>
				<MonthlyFinance
					businessExpensesData={businessExpensesData}
					date={date}
					id={id}
					loading={businessExpensesLoading}
					name={name}
					setMonthlyTotal={setMonthlyTotal}
					title={title}
				/>
			</Box>
			<a ref={linkRef} style={{ display: 'none' }} />
			<SearchBar
				hasSearchButton
				placeholder={t('views.usersAndOrganisations.organisations.searchPlaceholder')}
				searchEvents={events}
				searchValue={searchValue}
			/>
			<Table
				body={loadingBody || emptyBody || dataBody}
				cellStyle={classes.cellStyle}
				header={tableHeader}
				isNotPaginate={true}
				loading={businessExpensesLoading}
				noTitle={false}
				titleWithInformation={titleWithInformation}
				withoutPages={true}
			/>
		</Page>
	);
};

const mapStateToProps = (state) => {
	return {
		businessExpenses: state.details.businessExpenses,

		payBillingPeriod: state.condition.payBillingPeriod,
		billingReport: state.blob.billingReport,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchBusinessExpenses: (data, filters) =>
			dispatch(actions.fetchBusinessExpenses(data, filters)),
		onBillingReport: (type, dateTime, id, organisationId, exportType) =>
			dispatch(actions.billingReport(type, dateTime, id, organisationId, exportType)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationalBilling);

import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { PieChart, PieChartCenterLabel } from '~components';
import { colorsFull, colorsSlim } from '~themes/chartColors';

import DeviceStatisticsService from '../../services/deviceStatisticsService';

const service = new DeviceStatisticsService();

interface DeviceCountPieChartProps {
	organisationId?: string;
}

/**
 * A device pie chart for the total amount of devices
 * @param param0 
 * @returns 
 */
const DeviceCountPieChart = ({ organisationId }: DeviceCountPieChartProps) => {
	const { t } = useTranslation();

	const { data, isLoading } = useSWR(
		[service.basePath, { organisationId: organisationId }],
		([_, args]) => {
			if (isLoading) {
				service.abortCurrentRequest('parameter change');
			}

			return service.getCounts(args);
		},
	);

	data?.results.sort((a, b) => (b.total > a.total ? 1 : -1));

	return (
		<PieChart
			colors={data?.results != null && data.results.length > 3 ? colorsFull : colorsSlim}
			loading={isLoading}
			series={[
				{
					data:
						data?.results.map((el) => ({
							label: t(el.type === 'unlinked' ? 'unlinked' : `ui.label.${el.type}`),
							value: el.total,
						})) ?? [],
					outerRadius: '100%',
					innerRadius: '80%',
				},
			]}
		>
			<PieChartCenterLabel
				primary={t('totalResources', { resource: t('devices') })}
				secondary={data?.total}
			/>
		</PieChart>
	);
};

export default DeviceCountPieChart;

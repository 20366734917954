import { useEffect, useState } from 'react';

import { Modal, Card, CardContent, CardActions, Typography } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useDebounce } from '~hooks';

import { useStyles } from './style';
import { FormField, StyledButton } from '../../../../../../../components';
import { useWizardFormField, useError } from '../../../../../../../shared/hooks';
import {
	isEmptyString,
	isObject,
	isFullString,
	isUndefined,
} from '../../../../../../../shared/utility';
import * as actions from '../../../../../../../store/actions';

interface EditBillingAndAdminInfoProps {
	className?: string;
	organisation?: any;
	onClose?: (updated: boolean) => void;
	open?: boolean;
	checkedVATField?: {
		success?: boolean;
		loading?: boolean;
		error?: object | string;
	};
	checkedCOCField?: {
		success?: boolean;
		loading?: boolean;
		error?: object | string;
	};
	checkedIBANField?: {
		success?: boolean;
		loading?: boolean;
		error?: object | string;
	};
	checkedEnterpriseField?: {
		success?: boolean;
		loading?: boolean;
		error?: object | string;
	};
	onUpdateOrganisation(...args: unknown[]): unknown;
	onValidateOrganisationVATField(...args: unknown[]): unknown;
	onValidateOrganisationCOCField(...args: unknown[]): unknown;
	onValidateOrganisationIBANField(...args: unknown[]): unknown;
	onValidateOrganisationEnterpriseField(...args: unknown[]): unknown;
	updateOrganisation?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const EditBillingAndAdminInfo = ({
	open = false,
	onClose,
	organisation,
	className,

	onUpdateOrganisation,
	onValidateOrganisationCOCField,
	onValidateOrganisationIBANField,
	onValidateOrganisationVATField,
	onValidateOrganisationEnterpriseField,
	checkedVATField,
	checkedCOCField,
	checkedIBANField,
	checkedEnterpriseField,
	updateOrganisation,
}: EditBillingAndAdminInfoProps) => {
	const { t } = useTranslation();

	const classes = useStyles();

	const vatNumber = useWizardFormField(
		organisation.administration ? organisation.administration.vatNumber : '',
		{ minLength: 8, maxLength: 16 },
	);
	const [isUniqueVatNumber, setIsUniqueVatNumber] = useState(true);
	const debouncedVatValue = useDebounce(vatNumber.value, 300);

	const errorHandle = useError(
		isObject(updateOrganisation) ?
			{
				value: updateOrganisation,
				message: `${t('ui.successfully')} ${t('ui.updated')} ${organisation.name}`,
				variant: 'success',
			}
		:	null,
	);

	useEffect(() => {
		if (!isEmptyString(debouncedVatValue)) {
			onValidateOrganisationVATField(debouncedVatValue, organisation.id);
		}
	}, [debouncedVatValue]);

	const {
		success: checkedVATSuccess,
		loading: checkedVATLoading,
		error: checkedVATError,
	} = checkedVATField;

	useEffect(() => {
		if (!checkedVATLoading && !isObject(checkedVATError)) {
			setIsUniqueVatNumber(true);
		} else if (!checkedVATLoading) {
			if (checkedVATError.key === 'DuplicateVatNumber') {
				setIsUniqueVatNumber(false);
			}
		}
	}, [checkedVATSuccess, checkedVATLoading, checkedVATError]);

	const enterpriseNumber = useWizardFormField(
		organisation.administration ? organisation.administration.enterpriseNumber : '',
		{ minLength: 10, maxLength: 12, isEnterpriseNumber: true },
	);
	const [isUniqueEnterpriseNumber, setIsUniqueEnterpriseNumber] = useState(true);
	const debouncedEnterpriseValue = useDebounce(enterpriseNumber.value, 300);

	useEffect(() => {
		if (!checkedEnterpriseLoading && !isEmptyString(debouncedEnterpriseValue)) {
			onValidateOrganisationEnterpriseField(debouncedEnterpriseValue, organisation.id);
		}
	}, [debouncedEnterpriseValue]);

	const {
		success: checkedEnterpriseSuccess,
		loading: checkedEnterpriseLoading,
		error: checkedEnterpriseError,
	} = checkedEnterpriseField;

	useEffect(() => {
		if (!checkedEnterpriseLoading && !isObject(checkedEnterpriseError)) {
			setIsUniqueEnterpriseNumber(true);
		} else if (!checkedEnterpriseLoading) {
			if (checkedEnterpriseError.key === 'DuplicateEnterpriseNumber') {
				setIsUniqueEnterpriseNumber(false);
			}
		}
	}, [checkedEnterpriseSuccess, checkedEnterpriseLoading, checkedEnterpriseError]);

	const cocNumber = useWizardFormField(
		organisation.administration ? organisation.administration.chamberOfCommerceNumber : '',
		{ minLength: 8, maxLength: 16, isNumeric: true },
	);
	const [isUniqueCocNumber, setIsUniqueCocNumber] = useState(true);
	const debouncedCocValue = useDebounce(cocNumber.value, 300);

	useEffect(() => {
		if (!checkedCOCLoading && !isEmptyString(debouncedCocValue)) {
			onValidateOrganisationCOCField(debouncedCocValue, organisation.id);
		}
	}, [debouncedCocValue]);

	const {
		success: checkedCOCSuccess,
		loading: checkedCOCLoading,
		error: checkedCOCError,
	} = checkedCOCField;

	useEffect(() => {
		if (!checkedCOCLoading && !isObject(checkedCOCError)) {
			setIsUniqueCocNumber(true);
		} else if (!checkedCOCLoading) {
			if (checkedCOCError.key === 'DuplicateChamberOfCommerceNumber') {
				setIsUniqueCocNumber(false);
			}
		}
	}, [checkedCOCSuccess, checkedCOCLoading, checkedCOCError]);

	const ibanNumber = useWizardFormField(
		organisation.administration ? organisation.administration.ibanNumber : '',
		{ minLength: 10, maxLength: 32 },
	);
	const [isUniqueIbanNumber, setIsUniqueIbanNumber] = useState(true);
	const debouncedIbanValue = useDebounce(ibanNumber.value, 300);

	useEffect(() => {
		if (!checkedIBANLoading && !isEmptyString(debouncedIbanValue)) {
			onValidateOrganisationIBANField(debouncedIbanValue, organisation.id);
		}
	}, [debouncedIbanValue]);

	const {
		success: checkedIBANSuccess,
		loading: checkedIBANLoading,
		error: checkedIBANError,
	} = checkedIBANField;

	useEffect(() => {
		if (!checkedIBANLoading && !isObject(checkedIBANError)) {
			setIsUniqueIbanNumber(true);
		} else if (!checkedIBANLoading) {
			if (checkedIBANError.key === 'DuplicateIban') {
				setIsUniqueIbanNumber(false);
			}
		}
	}, [checkedIBANSuccess, checkedIBANLoading, checkedIBANError]);
	const email = useWizardFormField(
		organisation.invoiceContact ? organisation.invoiceContact.emailAddress : '',
		{ required: true, maxLength: 64, isEmail: true },
	);
	const [isUniqueEmail] = useState(true);

	const name = useWizardFormField(
		organisation.invoiceContact ? organisation.invoiceContact.name : '',
		{ required: true, maxLength: 25 },
	);
	const [isUniqueName] = useState(true);

	const validCocNumber = cocNumber.isValid && isUniqueCocNumber;
	const validIbanNumber = ibanNumber.isValid;
	const validVatNumber = vatNumber.isValid && isUniqueVatNumber;
	const validEnterpriseNumber = enterpriseNumber.isValid && isUniqueEnterpriseNumber;

	const formValid =
		email.isValid &&
		name.isValid &&
		validCocNumber &&
		validIbanNumber &&
		validVatNumber &&
		validEnterpriseNumber;

	const handleSave = () => {
		onUpdateOrganisation(organisation.id, {
			name: organisation.name,
			legalName: !isUndefined(organisation.legalName) ? organisation.legalName : organisation.name,
			description: organisation.description,
			phoneNumber: organisation.phoneNumber,
			isPublic: organisation.isPublic,
			showOnDashboard: organisation.showOnDashboard,
			email: organisation.emailAddress,
			...(organisation.address && { address: organisation.address }),
			...(organisation.contactPerson && {
				contactPerson: {
					email: organisation.contactPerson.emailAddress,
					firstName: organisation.contactPerson.firstName,
					lastName: organisation.contactPerson.lastName,
					phoneNumber: organisation.contactPerson.phoneNumber,
				},
			}),
			subscriptionId: organisation.subscription.id,
			...(organisation.invoiceContact && {
				invoiceContact: {
					email: email.value,
					name: name.value,
				},
			}),
			...{
				administration: {
					vatNumber: isFullString(vatNumber.value) ? vatNumber.value : null,
					chamberOfCommerceNumber: isFullString(cocNumber.value) ? cocNumber.value : null,
					ibanNumber: isFullString(ibanNumber.value) ? ibanNumber.value : null,
					enterpriseNumber: isFullString(enterpriseNumber.value) ? enterpriseNumber.value : null,
				},
			},
		});
		onClose?.(true);
		errorHandle.setStartAction(true);
	};

	const resetData = () => {
		vatNumber.resetToInitialValue();
		cocNumber.resetToInitialValue();
		ibanNumber.resetToInitialValue();
		email.resetToInitialValue();
		name.resetToInitialValue();
	};

	const handleClose = () => {
		resetData();
		onClose?.(false);
	};

	if (!open) {
		return null;
	}

	const editData = [
		{
			name: 'vatNumber',
			label: t('ui.label.vatNumber'),
			variable: vatNumber,
			placeholder: t('views.register.organisation.placeholder.vatNumber'),
			isExtraValid: isUniqueVatNumber,
			extraValidHelperText: t(
				'views.register.organisation.billingAndAdministration.error.vatAlreadyInUse',
			),
			maxLength: 15,
		},
		...(organisation.address && organisation.address.countryCode === 'BE' ?
			[
				{
					name: 'enterpriseNumber',
					label: t('ui.label.enterpriseNumber'),
					variable: enterpriseNumber,
					placeholder: t('views.register.organisation.placeholder.enterpriseNumber'),
					isExtraValid: isUniqueEnterpriseNumber,
					extraValidHelperText: t(
						'views.register.organisation.billingAndAdministration.error.enterpriseNumberAlreadyInUse',
					),
					maxLength: 12,
				},
			]
		:	[]),
		{
			name: 'cocNumber',
			label: t('ui.label.cocNumber'),
			variable: cocNumber,
			placeholder: t('views.register.organisation.placeholder.cocNumber'),
			isExtraValid: isUniqueCocNumber,
			extraValidHelperText: t(
				'views.register.organisation.billingAndAdministration.error.cocAlreadyInUse',
			),
			maxLength: 8,
		},
		{
			name: 'ibanNumber',
			label: t('ui.label.ibanNumber'),
			variable: ibanNumber,
			placeholder: t('views.register.organisation.placeholder.ibanNumber'),
			isExtraValid: isUniqueIbanNumber,
			extraValidHelperText: t(
				'views.register.organisation.billingAndAdministration.error.ibanAlreadyInUse',
			),
			maxLength: 22,
		},
		{
			name: 'email',
			label: t('ui.label.email'),
			variable: email,
			placeholder: t('ui.label.email'),
			isExtraValid: isUniqueEmail,
			extraValidHelperText: '',
			maxLength: 64,
			required: true,
		},
		{
			name: 'name',
			label: t('ui.label.name'),
			variable: name,
			placeholder: t('ui.label.name'),
			isExtraValid: isUniqueName,
			extraValidHelperText: '',
			maxLength: 25,
			required: true,
		},
	];

	return (
		<Modal onClose={handleClose} open={open}>
			<Card className={clsx(classes.root, className)}>
				<form>
					<CardContent className={classes.cardContent}>
						<Typography align='center' gutterBottom variant='h3'>
							{t('views.organisationDetail.summary.edit.titles.billingAndAdmin')}
						</Typography>
						<div className={classes.container}>
							{editData.map((edit) => (
								<div className={classes.formGroup} key={edit.name}>
									<FormField
										extraValidHelperText={edit.extraValidHelperText}
										hideCharacterCounter={!edit.variable.hasFocus}
										isExtraValid={edit.isExtraValid}
										label={edit.label}
										maxLength={edit.maxLength}
										name={edit.name}
										placeholder={edit.placeholder}
										required={edit.required}
										variable={edit.variable}
									/>
								</div>
							))}
						</div>
					</CardContent>
					<CardActions className={classes.actions}>
						<StyledButton onClick={handleClose} variant='contained-tertiary'>
							{t('ui.button.contained.close')}
						</StyledButton>
						<StyledButton
							disabled={!formValid || isObject(checkedIBANError)}
							onClick={handleSave}
							variant='contained-secondary'
						>
							{t('ui.button.contained.save')}
						</StyledButton>
					</CardActions>
				</form>
			</Card>
		</Modal>
	);
};

const mapStateToProps = (state) => {
	return {
		checkedVATField: state.condition.checkedOrganisationVATField,
		checkedCOCField: state.condition.checkedOrganisationCOCField,
		checkedIBANField: state.condition.checkedOrganisationIBANField,
		checkedEnterpriseField: state.condition.checkedOrganisationEnterpriseField,
		updateOrganisation: state.details.updateOrganisation,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onUpdateOrganisation: (organisationId, bodyData) =>
			dispatch(actions.updateOrganisation(organisationId, bodyData)),
		onValidateOrganisationVATField: (value, organisationId) =>
			dispatch(actions.validateOrganisationVATField(value, organisationId)),
		onValidateOrganisationCOCField: (value, organisationId) =>
			dispatch(actions.validateOrganisationCOCField(value, organisationId)),
		onValidateOrganisationIBANField: (value, organisationId) =>
			dispatch(actions.validateOrganisationIBANField(value, organisationId)),
		onValidateOrganisationEnterpriseField: (value, organisationId) =>
			dispatch(actions.validateOrganisationEnterpriseField(value, organisationId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditBillingAndAdminInfo);

import { Grid } from '@mui/material';
import clsx from 'clsx';

import { DeviceStatusCard } from '~features/devices';

import DeviceInfo from './DeviceInfo/DeviceInfo';
import DeviceSettings from './DeviceSettings/DeviceSettings';
import InstanceInfo from './InstanceInfo/InstanceInfo';
import KeyTag from './KeyTag/KeyTag';
import LocationInfo from './LocationInfo/LocationInfo';
import { useStyles } from './style';
import TankCard from './TankCard/TankCard';
import { isEmptyObject, isNull } from '../../../../../shared/utility';
import SummaryLoading from '../../../../OrganisationManagement/Details/OrganisationDetails/Summary/SummaryLoading/SummaryLoading';

interface SummaryProps {
	className?: string;
	instanceData?: object;
	instanceLoading?: boolean;
	instanceId?: number;
	isInternalUser?: boolean;
	isSuperAdmin?: boolean;
	access?: boolean;
}

const Summary = (props: SummaryProps) => {
	const {
		className,
		instanceLoading,
		instanceData,
		instanceId,
		isInternalUser,
		isSuperAdmin,
		access,
	} = props;

	const classes = useStyles();

	const gridProps = { item: true, xs: 12, md: 6, lg: 6, xl: 4 };

	const isVehicle =
		!isEmptyObject(instanceData) &&
		[
			'vehicleTrackerObd',
			'vehicleTrackerPro',
			'bmwCar',
			'vehicleTrackerCan',
			'vehicleTracker',
		].includes(instanceData.deviceType);

	const isDeviceMileagePresent =
		!isEmptyObject(instanceData) && instanceData.deviceStatus?.mileage != null;

	return !instanceLoading && !isEmptyObject(instanceData) && instanceId === instanceData.id ?
			<>
				<Grid className={clsx(classes.root, className)} container spacing={3}>
					<Grid {...gridProps}>
						<InstanceInfo
							instanceData={instanceData}
							isDeviceMileagePresent={isDeviceMileagePresent}
						/>
					</Grid>
					<Grid {...gridProps}>
						<LocationInfo instanceData={instanceData} />
					</Grid>
					{instanceData.deviceStatus != null &&
						<Grid {...gridProps}>
							<DeviceStatusCard deviceId={instanceData.deviceId} deviceType={instanceData?.deviceType} />
						</Grid>
					}
					{instanceData.device != null && (
						<Grid {...gridProps}>
							<DeviceInfo instanceData={instanceData} />
						</Grid>
					)}
					{access &&
						(instanceData.deviceType === 'vehicleTrackerCan' ||
							instanceData.deviceType === 'cpacBoat') && (
							<Grid {...gridProps}>
								<DeviceSettings
									instanceData={instanceData}
									isBoat={instanceData.deviceType === 'cpacBoat'}
								/>
							</Grid>
						)}
					{instanceData?.categoryReference.type === 'cars' && isInternalUser && (
						<Grid {...gridProps}>
							<TankCard instanceData={instanceData} />
						</Grid>
					)}
					{instanceData?.categoryReference.type === 'cars' &&
						isInternalUser &&
						(instanceData?.keyTag || isSuperAdmin) && (
							<Grid {...gridProps}>
								<KeyTag instanceData={instanceData} isSuperAdmin={isSuperAdmin} />
							</Grid>
						)}
				</Grid>
			</>
		:	<SummaryLoading />;
};

export default Summary;

import { useState, useEffect } from 'react';

import { Wrapper } from '@googlemaps/react-wrapper';
import { Box, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useDebounce } from '~hooks';

import { ActionDialog, AutocompleteInput, InteractiveMap } from '../../../../../../components';
import { isObject, isFullString, handleHubReference } from '../../../../../../shared/utility';
import * as actions from '../../../../../../store/actions';
const render = (status) => {
	return <h1>{status}</h1>;
};

interface AddressEditProps {
	open?: boolean;
	onClose?(...args: unknown[]): unknown;
	onFetchPlacesAutocomplete?(...args: unknown[]): unknown;
	onFetchPlace?(...args: unknown[]): unknown;
	onUpdateLocation?(...args: unknown[]): unknown;
	onResetState?(...args: unknown[]): unknown;
	placesAutocomplete?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	place?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	updateLocation?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const AddressEdit = ({
	open = false,
	onClose,
	location,

	place,
	placesAutocomplete,
	onFetchPlacesAutocomplete,
	onFetchPlace,
	onUpdateLocation,
	updateLocation,
	onResetState,
}: AddressEditProps) => {
	const { t } = useTranslation();

	const [openInput, setOpenInput] = useState(false);

	const [options, setOptions] = useState([]);
	const [searchValue, setSearchValue] = useState('');

	const debouncedSearchValue = useDebounce(searchValue, 500);

	const valeTest =
		isFullString(debouncedSearchValue) && debouncedSearchValue === debouncedSearchValue;
	const { data: placeData } = place;

	const { data: placesAutocompleteData, loading: placesAutocompleteLoading } = placesAutocomplete;

	const { data: updateLocationData, loading: updateLocationLoading } = updateLocation;

	const [defaultLocation, setDefaultLocation] = useState(null);

	const handleChangeInput = (event) => {
		setSearchValue(event.target.value);
	};

	// useEffect(())
	const [coordinates, setCoordinates] = useState({
		lat: location.latitude,
		lng: location.longitude,
	});

	useEffect(() => {
		if (!open) {
			setOptions([]);
		}
	}, [open]);

	useEffect(() => {
		if (isFullString(debouncedSearchValue || valeTest)) {
			const token = isObject(placesAutocompleteData) ? placesAutocompleteData.sessionToken : null;
			onFetchPlacesAutocomplete(debouncedSearchValue, undefined, token);
		}
	}, [debouncedSearchValue, valeTest]);

	useEffect(() => {
		if (location && !isObject(placesAutocompleteData)) {
			const tempArr = [{ name: handleHubReference(location) }];
			setDefaultLocation(tempArr[0]);
			setOptions(tempArr);
			setOpenInput(false);
		} else if (isObject(placesAutocompleteData)) {
			setOptions(
				placesAutocompleteData.predictions.map((item) => ({ name: item.description, id: item.id })),
			);
		}
	}, [location, placesAutocompleteData]);

	useEffect(() => {
		if (isObject(placeData)) {
			setCoordinates({
				lat: placeData.geometry.location.latitude,
				lng: placeData.geometry.location.longitude,
			});
		}
	}, [placeData]);

	useEffect(() => {
		if (isObject(updateLocationData)) {
			onClose();
			onResetState('updateLocation');
		}
	}, [updateLocationData]);

	const updateCoordinates = (coords) => {
		if (
			isObject(placeData) &&
			isObject(placeData.geometry) &&
			isObject(placeData.geometry.location) &&
			(placeData.geometry.location.latitude !== coords.lat ||
				placeData.geometry.location.longitude !== coords.lng)
		) {
			setCoordinates(coords);
		} else if (!isObject(placeData)) {
			setCoordinates(coords);
		}
	};

	const handlehandleSelectedInput = (event, values) => {
		if (isObject(values)) {
			onFetchPlace(values.id);
		}
		if (values) {
			setDefaultLocation({ name: values.name });
		} else {
			setDefaultLocation(null);
			setOptions([]);
		}
	};

	const patchBody = {
		...(isObject(placeData) && {
			address: {
				street: placeData?.address.street,
				postalCode: placeData?.address.postalCode,
				city: placeData?.address.city,
				countryCode: placeData?.address.country,
				...(isFullString(placeData?.address.number) && { number: placeData?.address.number }),
				...(isFullString(placeData?.address.numberAddition) && {
					numberAddition: placeData?.address.numberAddition,
				}),
			},
		}),
		latitude: coordinates.lat,
		longitude: coordinates.lng,
	};

	const handleDialogClose = () => {
		onClose();
		const tempArr = [{ name: handleHubReference(location) }];
		setDefaultLocation(tempArr[0]);
		setOptions(tempArr);
		setCoordinates({
			lat: location.latitude,
			lng: location.longitude,
		});
	};

	const handleConfirm = () => {
		onUpdateLocation(location.id, patchBody);
	};
	const newCoordinates =
		location.latitude === coordinates.lat && location.longitude === coordinates.lng;
	const locationSelected = !isObject(defaultLocation);
	const isValid = newCoordinates || locationSelected;

	return (
		<ActionDialog
			actionButtonProps={{
				text: updateLocationLoading ? <CircularProgress disableShrink size={24} /> : t('ui.update'),
				action: handleConfirm,
			}}
			handleClose={handleDialogClose}
			loading={isValid}
			open={open}
			title={t('views.organisationDetail.summary.editAddress')}
		>
			<Box>
				<Box mb={1}>
					<AutocompleteInput
						loading={placesAutocompleteLoading}
						loadingText={t('ui.loading')}
						noOptionsText={t('ui.noOprions')}
						onChange={handleChangeInput}
						onSelected={handlehandleSelectedInput}
						open={openInput}
						options={options}
						placeholder={t(
							'views.addLocation.locationDetails.locationSection.placeholder.locationAutocomplete',
						)}
						setOpen={setOpenInput}
						setOptions={setOptions}
						value={defaultLocation}
					/>
				</Box>
				<Wrapper apiKey={import.meta.env.VITE_GOOGLE_MAPS_LICENSE_KEY} render={render}>
					<InteractiveMap
						callback={updateCoordinates}
						errorMessage={'errorMessage'}
						isImage
						myLocation={coordinates}
					/>
				</Wrapper>
			</Box>
		</ActionDialog>
	);
};

const mapStateToProps = (state) => {
	return {
		place: state.details.place,
		placesAutocomplete: state.details.placesAutocomplete,
		updateLocation: state.details.updateLocation,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchPlacesAutocomplete: (searchTerm, placeType, sessionToken, globalSearch) =>
			dispatch(actions.fetchPlacesAutocomplete(searchTerm, placeType, sessionToken, globalSearch)),
		onFetchPlace: (placeId) => dispatch(actions.fetchPlace(placeId)),
		onUpdateLocation: (locationId, properties) =>
			dispatch(actions.updateLocation(locationId, properties)),
		onResetState: (state) => dispatch(actions.resetState(state)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressEdit);

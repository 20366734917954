import { useEffect, useRef, useState, useContext } from 'react';

import { useSnackbar } from 'notistack';

import { useDebounce } from '~hooks';

import {
	isEmptyString,
	isEmptyArray,
	isObject,
	isFullArray,
	isUndefined,
	isNull,
	isFullString,
	isBoolean,
	isInteger,
	isNumber,
	isFunction,
} from './utility';
import { validateInput } from './validationWithCustomMessages';

function usePrevious(value) {
	const ref = useRef(value);
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
}

function useWizardFormField(initialValue = '', initialValidationOptions = {}) {
	const [value, setValue] = useState(initialValue);
	const [isRequired, setIsRequired] = useState(!!initialValidationOptions.required);
	const [validationOptions, setValidationOptions] = useState(initialValidationOptions);
	const [isValid, setIsValid] = useState(false);
	const [validationError, setValidationError] = useState({});
	const [isTouched, setIsTouched] = useState(false);
	const [hasFocus, setHasFocus] = useState(false);
	const isChanged = initialValue !== value;

	const onBlur = (e) => {
		const onBlurValue = e.target.value.trim();
		const validatedInput = validateInput(onBlurValue, validationOptions);

		setIsTouched(true);
		setIsValid(validatedInput.valid);
		setValidationError(validatedInput.error);
		setValue(onBlurValue);
		setHasFocus(false);
	};

	const onChange = (e) => {
		const onChangeValue = e.target ? e.target.value : e;
		setIsTouched(true);
		setValue(onChangeValue);
	};

	const onFocus = () => {
		setHasFocus(true);
	};

	const setTouched = (touched) => {
		setIsTouched(touched);
	};

	const setRequired = (required) => {
		setValidationOptions({
			...validationOptions,
			required,
		});
		setIsRequired(required);
	};

	const setNewValidationOptions = (options) => {
		setValidationOptions(options);
	};

	const resetToInitialValue = () => {
		setValue(initialValue);
		setIsRequired(!!initialValidationOptions.required);
		setValidationOptions(initialValidationOptions);
		setIsValid(false);
		setValidationError({});
		setIsTouched(false);
		setHasFocus(false);
	};

	//check if value is valid when validationOptions change
	useEffect(() => {
		if (value || value === '') {
			const validatedInput = validateInput(value, validationOptions);
			setIsValid(validatedInput.valid);
			setValidationError(validatedInput.error);
		}
	}, [validationOptions, value]);

	return {
		value,
		isRequired,
		isValid,
		validationError,
		isTouched,
		isChanged,
		hasFocus,
		intValue: parseInt(value, 10),
		floatIntValue: parseFloat(value, 10),
		onChange,
		setTouched,
		setRequired,
		validationOptions,
		setNewValidationOptions,
		resetToInitialValue,
		setValue,
		bindToFormField: {
			value,
			onChange,
			onBlur,
			onFocus,
		},
	};
}

function useDebouncedWizardSave(name, value, valid, save, delay = 300, required) {
	const debouncedValue = useDebounce(value, delay);

	useEffect(() => {
		save({
			[name]: {
				value,
				valid,
				required,
			},
		});
	}, [debouncedValue, required, valid]);
}

function useDebouncedNumberSave(name, value, valid, save, delay = 300) {
	const debouncedValue = useDebounce(value, delay);
	const numberValue = parseInt(value);
	useEffect(() => {
		if (isInteger(numberValue) && debouncedValue) {
			save({ [name]: numberValue });
		} else if (isEmptyString(value)) {
			save({ [name]: '' });
		}
	}, [debouncedValue]);
}

function useDebouncedDecimalSave(name, value, save, delay = 300) {
	const debouncedValue = useDebounce(value, delay);
	const numberValue = parseFloat(value);
	useEffect(() => {
		if (isNumber(numberValue) && debouncedValue) {
			save({ [name]: numberValue });
		} else if (isEmptyString(value)) {
			save({ [name]: '' });
		}
	}, [debouncedValue]);
}

function useDebouncedBackendFieldCheck(
	value,
	check,
	checkedField,
	otherVariables = {},
	shouldCheckResponseBodyForSuccess = false,
	delay = 300,
) {
	const debouncedValue = useDebounce(value, delay);
	const [isUnique, setIsUnique] = useState(true);
	const [checkIsSuccessful, setCheckIsSuccessful] = useState(false);

	useEffect(() => {
		if (!isEmptyString(debouncedValue)) {
			check({ ...otherVariables, value: debouncedValue });
		}
	}, [debouncedValue]);

	const {
		[shouldCheckResponseBodyForSuccess ? 'data' : 'success']: checkedFieldSuccess,
		loading: checkedFieldLoading,
		error: checkedFieldError,
	} = checkedField;

	useEffect(() => {
		if (shouldCheckResponseBodyForSuccess) {
			if (isObject(checkedFieldSuccess) && !checkedFieldLoading && !checkedFieldError) {
				setIsUnique(checkedFieldSuccess.allowed);
			} else {
				setIsUnique(false);
			}
		} else {
			if (!checkedFieldLoading && !isObject(checkedFieldError)) {
				setIsUnique(true);
			} else if (!shouldCheckResponseBodyForSuccess && !checkedFieldLoading) {
				setIsUnique(false);
			}
		}

		if (
			(checkedFieldSuccess || isObject(checkedFieldSuccess)) &&
			!checkedFieldLoading &&
			!checkedFieldError
		) {
			setCheckIsSuccessful(true);
		} else if (!checkedFieldLoading && checkedFieldError) {
			setCheckIsSuccessful(false);
		}
	}, [
		checkedFieldSuccess,
		checkedFieldLoading,
		checkedFieldError,
		shouldCheckResponseBodyForSuccess,
	]);

	return {
		isUnique,
		checkIsSuccessful,
	};
}

function useBackendFieldCheck(
	value,
	check,
	checkedField,
	otherVariables = {},
	shouldCheckResponseBodyForSuccess = false,
	delay = 300,
) {
	const [initialValue] = useState(value);
	const debouncedValue = useDebounce(value, delay);
	const [isUnique, setIsUnique] = useState(true);
	const {
		[shouldCheckResponseBodyForSuccess ? 'data' : 'success']: checkedFieldSuccess,
		loading: checkedFieldLoading,
		error: checkedFieldError,
	} = checkedField;
	const isInitialValue = initialValue === debouncedValue;
	useEffect(() => {
		if (initialValue !== debouncedValue && !isEmptyString(debouncedValue)) {
			check({ ...otherVariables, value: debouncedValue });
		}
	}, [debouncedValue]);

	useEffect(() => {
		if (isInitialValue && !checkedFieldSuccess) {
			setIsUnique(true);
		} else {
			if (!checkedFieldLoading && checkedFieldSuccess) {
				setIsUnique(true);
			} else if (!checkedFieldSuccess) {
				setIsUnique(false);
			}
		}
	}, [
		checkedFieldSuccess,
		checkedFieldLoading,
		checkedFieldError,
		shouldCheckResponseBodyForSuccess,
		isInitialValue,
	]);

	return {
		isUnique,
	};
}

function useDebouncedLocalFieldCheck(value, fieldName, check, delay = 300) {
	const debouncedValue = useDebounce(value, delay);
	const [isUnique, setIsUnique] = useState(true);

	useEffect(() => {
		if (!isEmptyString(debouncedValue)) {
			setIsUnique(check(fieldName, debouncedValue));
		}
	}, [debouncedValue]);

	return isUnique;
}

/**
 * 
 * @param value 
 * @returns 
 * @deprecated Should use the `useSnackbar` hook
 */
function useError(value) {
	const { enqueueSnackbar } = useSnackbar();

	const [startAction, setStartAction] = useState(false);

	const { loading, error } = value.value;
	const actionDone =
		(isObject(value.value.data) ||
			isFullArray(value.value.data) ||
			isBoolean(value.value.data) ||
			value.value.success ||
			value.value.data) &&
		!loading &&
		!error;
	const detailedError =
		isObject(error) &&
		isObject(error.additionalData) &&
		isFullArray(error.additionalData.detailedErrorMessages) &&
		error.additionalData.detailedErrorMessages;

	useEffect(() => {
		if (startAction && actionDone) {
			enqueueSnackbar(
				`${value.message}`,
				isFullString(value.variant) ?
					value.variant === 'none' ?
						{}
					:	{ variant: value.variant }
				:	{ variant: 'success' },
			);
			setStartAction(false);
		}
	}, [startAction, actionDone]);

	useEffect(() => {
		if (isObject(error) && startAction && !loading && !isFullArray(detailedError)) {
			enqueueSnackbar(error.message, { variant: 'error' });
			setStartAction(false);
		} else if (isFullString(error) && startAction) {
			enqueueSnackbar(error, { variant: 'error' });
			setStartAction(false);
		} else if (startAction && isObject(error) && isFullArray(detailedError) && !loading) {
			enqueueSnackbar(
				isFullArray(detailedError) ?
					`${detailedError.map((detailedMessage) => detailedMessage.value).join('. ')}.`
				:	error,
				{ variant: 'error' },
			);
			setStartAction(false);
		}
	}, [startAction, error]);

	return {
		startAction,
		setStartAction,
	};
}

function useErrorAcceptedRejected(value) {
	const { enqueueSnackbar } = useSnackbar();

	const [startAction, setStartAction] = useState(false);

	const { loading, error, data } = value.value;

	const actionDone = isObject(value.value.data) && isFullArray(data.accepted) && !loading && !error;

	useEffect(() => {
		if (startAction && actionDone) {
			enqueueSnackbar(value.message.positive, { variant: 'success' });
			setStartAction(false);
		}
	}, [startAction, actionDone]);

	useEffect(() => {
		if (startAction && !loading && isObject(data) && isEmptyArray(data.accepted)) {
			enqueueSnackbar(value.message.negative, { variant: 'error' });
			setStartAction(false);
		}
	}, [startAction, error, loading]);

	return {
		startAction,
		setStartAction,
	};
}

function useCloseTimeout(data) {
	const [action, setAction] = useState(false);
	const [value, setValue] = useState(data);
	const { actionDone, loading, error } = value;

	const prevLoading = usePrevious(value.loading);
	useEffect(() => {
		if (loading !== data.loading) {
			setValue(data);
		}
	}, [data]);

	useEffect(() => {
		if (loading !== prevLoading) {
			if (prevLoading && !loading && !actionDone) {
				const errorTimer = setTimeout(() => setAction(false), value.delay ? value.delay : 30000);
				if (!isNull(error) || actionDone) {
					setAction(false);
					return () => clearTimeout(errorTimer);
				}
			}
			setAction(!value.actionDone);
		}
	}, [loading]);

	return {
		action,
		setAction,
	};
}

function useItemInstanceSelection(value = []) {
	const [selection, setSelection] = useState(value);

	const [shouldRemoveItem, setShouldRemoveItem] = useState(false);
	const [shouldRemoveInstance, setShouldRemoveInstance] = useState(false);
	const [removeId, setRemoveId] = useState();
	const [removeInstanceId, setRemoveInstanceId] = useState();

	const removeItem = (item = null, instance = null) => {
		const selectedItemIds = selection.map((selectedItem) => selectedItem.item.id);
		const itemIndex = selectedItemIds.indexOf(item.id);
		const selectedItemInstanceIds =
			selection[itemIndex].selectedInstances ?
				selection[itemIndex].selectedInstances.map(
					(selectedItemInstance) => selectedItemInstance.id,
				)
			:	[];
		if (
			(!isNull(item) && isNull(instance)) ||
			(!isNull(instance) && selectedItemInstanceIds.length === 1)
		) {
			//removing whole item from selection
			setSelection(Array(0).concat(selection.slice(0, itemIndex), selection.slice(itemIndex + 1)));
		} else {
			//remove instance
			setSelection(
				selection.map((selectedItem) => ({
					...selectedItem,
					selectedInstances:
						selectedItem.item.id === item.id ?
							isEmptyArray(selectedItem.selectedInstances) ?
								item.instances.filter((newInstance) => newInstance.id !== instance.id)
							:	Array(0).concat(
									selectedItem.selectedInstances.slice(
										0,
										selectedItemInstanceIds.indexOf(instance.id),
									),
									selectedItem.selectedInstances.slice(
										selectedItemInstanceIds.indexOf(instance.id) + 1,
									),
								)
						:	selectedItem.selectedInstances,
				})),
			);
		}
	};

	const handleRemoveItemFromSidebar = (item = null, instance = null) => {
		if (!isNull(item) && isNull(instance)) {
			setShouldRemoveItem(true);
			setRemoveId(item.id);
		} else {
			setShouldRemoveInstance(true);
			setRemoveId(item.id);
			setRemoveInstanceId(instance.id);
		}
		removeItem(item, instance);
	};

	const addItem = (item = null, instance = null) => {
		const selectedItemIds = selection.map((item) => item.item.id);
		const itemIndex = selectedItemIds.indexOf(item.id);

		if (!isNull(item) && isNull(instance)) {
			// selectAll
			if (itemIndex === -1) {
				setSelection(
					selection.concat([
						{
							item,
							selectedInstances: isFullArray(item.instances) ? item.instances : [],
						},
					]),
				);
			} else {
				setSelection(
					selection.map((selectedItem) => ({
						...selectedItem,
						selectedInstances:
							selectedItem.item.id === item.id ?
								isFullArray(item.instances) ? item.instances
								:	[]
							:	selectedItem.selectedInstances,
					})),
				);
			}
		} else {
			//instance adding
			if (selectedItemIds.indexOf(item.id) === -1) {
				setSelection(
					selection.concat([
						{
							item,
							selectedInstances: [instance],
						},
					]),
				);
			} else {
				setSelection(
					selection.map((selectedItem) => ({
						...selectedItem,
						selectedInstances:
							selectedItem.item.id === item.id ?
								selectedItem.selectedInstances.concat([instance])
							:	selectedItem.selectedInstances,
					})),
				);
			}
		}
	};

	return {
		selection,
		setSelection,
		shouldRemoveItem,
		setShouldRemoveItem,
		shouldRemoveInstance,
		setShouldRemoveInstance,
		removeId,
		removeInstanceId,
		handleRemoveItemFromSidebar,
		removeItem,
		addItem,
	};
}

function useBasicFilter(sessionKey, defaultValue = 'all') {
	const [value, setValue] = useState(
		sessionStorage.getItem(sessionKey) ? sessionStorage.getItem(sessionKey) : defaultValue,
	);
	if (value === defaultValue) {
		sessionStorage.removeItem(sessionKey);
	} else {
		sessionStorage.setItem(sessionKey, value);
	}
	return { value, setValue };
}

function usePeriodFilter(action) {
	const [dates, setDates] = useState({ startDate: null, endDate: null });

	const [focused, setFocused] = useState(undefined);

	const focusAction = (focusedInput) => {
		setFocused(focusedInput);
	};

	const datesChange = ({ startDate, endDate }) => {
		const dateIsNull = isNull(startDate) && isNull(endDate);
		setDates({
			...dates,
			...(!isNull(startDate) && { startDate }),
			...(!isNull(endDate) && { endDate }),
		});
		if (isObject(action) && isNull(startDate) && isNull(endDate)) {
			action.setSorting(() => 'id');
			action.onOrderDescending(true);
			setDates({ startDate, endDate });
		} else if (!dateIsNull && isObject(action) && !isNull(startDate)) {
			action.setSorting('startDate');
			action.onOrderDescending(false);
		} else if (isObject(action) && isNull(startDate) && !isNull(endDate)) {
			action.setSorting('endDate');
			action.onOrderDescending(false);
		}
		if (isFunction(action)) {
			action(true);
		}
	};

	return { datesChange, focused, focusAction, dates, setDates };
}

function useComplexFilter(sessionKey, secondarySessionKey) {
	const [valueName, setValueName] = useState(
		sessionStorage.getItem(sessionKey) ? sessionStorage.getItem(sessionKey) : '',
	);
	const [valueId, setValueId] = useState(
		sessionStorage.getItem(secondarySessionKey) ?
			sessionStorage.getItem(secondarySessionKey)
		:	'all',
	);
	if (valueName === '' || valueId === 'all') {
		sessionStorage.removeItem(sessionKey);
		sessionStorage.removeItem(secondarySessionKey);
	} else {
		sessionStorage.setItem(sessionKey, valueName);
		sessionStorage.setItem(secondarySessionKey, valueId);
	}
	return { valueName, setValueName, valueId, setValueId };
}

function useSearch(setPageNumber, setShouldSearch, sessionKey = undefined) {
	const [value, setValue] = useState(
		sessionStorage.getItem(sessionKey) ? sessionStorage.getItem(sessionKey) : '',
	);

	const handleSearchClick = () => {
		setPageNumber(1);
		if (!isUndefined(sessionKey)) {
			sessionStorage.setItem(sessionKey, value);
		}
		setShouldSearch((prev) => !prev);
	};

	const handleResetSearch = () => {
		setValue('');
		setPageNumber(1);
		setShouldSearch((prev) => !prev);
		if (isFullString(sessionKey) && !isUndefined(sessionKey)) {
			sessionStorage.removeItem(sessionKey);
		}
	};

	const handleKeyUp = (e) => {
		switch (e.key) {
			case 'Enter':
				handleSearchClick();
				break;
			default:
				return;
		}
	};

	const handleChange = (e) => {
		setValue(e.target.value);
	};

	const events = {
		onClick: handleSearchClick,
		onChange: (e) => handleChange(e),
		onClear: handleResetSearch,
		onKeyUp: (e) => handleKeyUp(e),
	};

	return { events, value };
}

function useSearchComponent(
	setPageNumber,
	setShouldSearch,
	sessionKey = undefined,
	shouldSearchOnDebounce = false,
) {
	const isSession = sessionStorage.getItem(sessionKey);

	const [value, setValue] = useState(isSession ? sessionStorage.getItem(sessionKey) : '');

	const debouncedSearchValue = useDebounce(value, 500);
	const [isShowingSearchResults, setIsShowingSearchResults] = useState(false);

	const handleSearchClick = () => {
		if (!isEmptyString(value)) {
			setPageNumber(1);
			setShouldSearch(true);
			setIsShowingSearchResults(true);
			if (!isUndefined(sessionKey)) {
				sessionStorage.setItem(sessionKey, value);
			}
		}
		if (isEmptyString(value) && isShowingSearchResults) {
			setPageNumber(1);
			setShouldSearch(true);
			setIsShowingSearchResults(false);
		}
	};

	const handleResetSearch = () => {
		setValue('');
		if (value === sessionStorage.getItem(sessionKey)) {
			setShouldSearch(true);
		}
		if (isFullString(sessionKey) && !isUndefined(sessionKey)) {
			sessionStorage.removeItem(sessionKey);
		}
		if (isShowingSearchResults === true) {
			setShouldSearch(true);
		}
		setIsShowingSearchResults(false);
	};

	const handleKeyUp = (e) => {
		switch (e.key) {
			case 'Enter':
				handleSearchClick();
				break;
			default:
				return;
		}
	};

	const handleChange = (e) => {
		setValue(e.target.value);
	};

	const events = {
		onClick: handleSearchClick,
		onChange: (e) => handleChange(e),
		onClear: handleResetSearch,
		onKeyUp: (e) => handleKeyUp(e),
	};

	useEffect(() => {
		if (shouldSearchOnDebounce) {
			handleSearchClick();
		}
	}, [shouldSearchOnDebounce, debouncedSearchValue]);

	return {
		value,
		events,
	};
}

function useDetailPageBackButton(location) {
	const [isBackButton, setIsBackButton] = useState(false);

	useEffect(() => {
		if (!!location.state && !!location.state.from && !location.state.showBackButton) {
			setIsBackButton(false);
		} else {
			setIsBackButton(true);
		}
	}, [location]);

	return {
		isBackButton,
	};
}

function usePaymentDetails(value) {
	const [name, setName] = useState('-');
	const [bank, setBank] = useState('-');
	const [logo, setLogo] = useState(null);

	useEffect(() => {
		if (isObject(value.bank)) {
			setBank(value.bank.iban);
		} else if (isObject(value.card)) {
			setBank(value.card.number);
		} else if (isObject(value.paypal)) {
			setBank(value.paypal.email);
		}

		if (isFullString(value.name)) {
			setName(value.name);
		}
		if (isFullString(value.logo)) {
			setLogo(value.logo);
		}
	}, [value]);

	return { name: name, bank: bank, logo: logo };
}

function usePagination(
	sessionKeyPageNumber = 'pageNumber',
	sessionKeyPageSize = 'pageSize',
	pagination = { number: 1, size: 10 },
) {
	const [pageNumber, setPageNumber] = useState(
		sessionStorage.getItem(sessionKeyPageNumber) ?
			parseInt(sessionStorage.getItem(sessionKeyPageNumber), 10)
		:	pagination.number,
	);
	const [pageSize, setPageSize] = useState(
		sessionStorage.getItem(sessionKeyPageSize) ?
			parseInt(sessionStorage.getItem(sessionKeyPageSize), 10)
		:	pagination.size,
	);
	const [fetch, setFatch] = useState(false);

	const page = { number: pageNumber, size: pageSize };

	if (pagination.number === pageNumber && pagination.size === pageSize) {
		sessionStorage.removeItem(sessionKeyPageNumber);
		sessionStorage.removeItem(sessionKeyPageSize);
	} else {
		sessionStorage.setItem(sessionKeyPageNumber, pageNumber);
		sessionStorage.setItem(sessionKeyPageSize, pageSize);
	}

	const pageNumberChange = (page) => {
		setPageNumber(page);
		setFatch(true);
	};

	const pageSizeChange = (newSize) => {
		setPageSize(newSize);
		setPageNumber(1);
		setFatch(true);
	};

	const resetPagination = () => {
		setPageNumber(pagination.number);
		setPageSize(pagination.size);
		setFatch(true);
	};

	return {
		fetch,
		setFatch,
		page,
		pageNumber,
		setPageNumber,
		pageSize,
		pageNumberChange,
		pageSizeChange,
		resetPagination,
	};
}

export {
	usePeriodFilter,
	useComplexFilter,
	useBasicFilter,
	usePrevious,
	useDebounce,
	useWizardFormField,
	useDebouncedWizardSave,
	useDebouncedNumberSave,
	useDebouncedDecimalSave,
	useDebouncedBackendFieldCheck,
	useDebouncedLocalFieldCheck,
	usePaymentDetails,
	useBackendFieldCheck,
	useItemInstanceSelection,
	useError,
	useCloseTimeout,
	useSearchComponent,
	useDetailPageBackButton,
	useErrorAcceptedRejected,
	usePagination,
	useSearch,
};

//List of allowed countries - Source: https://skopei.atlassian.net/browse/TP-3128
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CellTowerIcon from '@mui/icons-material/CellTower';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import LocationOnIcon from '@mui/icons-material/LocationOn';

export const languages = [
	{
		code: 'nl',
		language: 'dutch',
	},
	{
		code: 'de',
		language: 'german',
	},
	{
		code: 'en',
		language: 'english',
	},
];

export const gpsLocationIcons = {
	gpsFix: <GpsFixedIcon fontSize='small' />,
	gpsCopied: <ArrowDownwardIcon fontSize='small' />,
	cellTowerRequested: <CellTowerIcon fontSize='small' />,
	cellTowerCopied: <ContentCopyIcon fontSize='small' />,
	dockingLocation: <LocationOnIcon fontSize='small' />,
	none: '',
};

export const mapRestriction = {
	latLngBounds: { east: 179.9999, north: 85, south: -85, west: -179.9999 },
	strictBounds: true,
};

export const mapStyles = [
	{
		featureType: 'administrative.country',
		elementType: 'geometry.stroke',
		stylers: [
			{
				visibility: 'on',
			},
		],
	},
	{
		featureType: 'administrative.neighborhood',
		elementType: 'labels',
		stylers: [
			{
				visibility: 'simplified',
			},
		],
	},
	{
		featureType: 'landscape.natural.landcover',
		elementType: 'geometry',
		stylers: [
			{
				lightness: '19',
			},
		],
	},
	{
		featureType: 'poi',
		elementType: 'all',
		stylers: [
			{
				visibility: 'on',
			},
		],
	},
	{
		featureType: 'poi',
		elementType: 'geometry.fill',
		stylers: [
			{
				gamma: '1.00',
			},
		],
	},
	{
		featureType: 'poi',
		elementType: 'labels',
		stylers: [
			{
				visibility: 'off',
			},
		],
	},
	{
		featureType: 'poi.park',
		elementType: 'geometry',
		stylers: [
			{
				lightness: '39',
			},
		],
	},
	{
		featureType: 'road',
		elementType: 'geometry.fill',
		stylers: [
			{
				lightness: '60',
			},
		],
	},
	{
		featureType: 'road',
		elementType: 'labels.text',
		stylers: [
			{
				visibility: 'simplified',
			},
		],
	},
	{
		featureType: 'road.highway',
		elementType: 'geometry.stroke',
		stylers: [
			{
				weight: '0.60',
			},
		],
	},
	{
		featureType: 'road.highway.controlled_access',
		elementType: 'geometry.stroke',
		stylers: [
			{
				weight: '0.70',
			},
		],
	},
	{
		featureType: 'water',
		elementType: 'geometry',
		stylers: [
			{
				lightness: '29',
			},
		],
	},
];

export const categories = {
	BIKES: 1,
	OFFICES: 2,
	CARS: 3,
	TRAILERS: 4,
	PARKINGLOTS: 5,
	ASSETS: 6,
	BOATS: 7,
};

export const exportType = {
	XLSX: 'xlsx',
	CSV: 'csv',
};

export const licensePlateLength = 12;

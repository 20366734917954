import i18n from '~lib/i18n';

export const handleReservationType = (value) => {
	const [bookingTypes] = value.bookingTypes;
	const reservationType = value.reservationType;

	if (bookingTypes === 'regular' && reservationType === 'fixed') {
		return i18n.t('views.addItem.bookingType.options.fixed.title');
	} else if (bookingTypes === 'scanQr' && reservationType === 'fixed') {
		return i18n.t('views.addItem.bookingType.options.flexible.title');
	} else if (bookingTypes === 'scanNfc' && reservationType === 'flexible') {
		return i18n.t('views.addItem.bookingType.options.scanOnly.title');
	} else if (bookingTypes === 'scanQr' && reservationType === 'nonBookable') {
		return i18n.t('views.addItem.bookingType.options.nonBookable.title');
	} else {
		return 'UPDATE Usage type';
	}
};

import React, { useRef, useState } from 'react';

import { Button, Skeleton, Stack, StackProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

import FormContainerContext from '~components/dialogs/formContainerProvider';
import { FormWrapperRefProps } from '~interfaces/refProps';
import i18n from '~lib/i18n';

interface FormContainerBoxProps extends StackProps {
	loading?: boolean;
	saveLabel?: string;
}

const FormContainerBox = ({
	children,
	spacing = 2,
	my = 1,
	loading = false,
	saveLabel = i18n.t('ui.button.contained.add'),
	...stackProps
}: FormContainerBoxProps) => {
	const { t } = useTranslation();

	// Intercept the children, attach a ref, and use it to
	// fire the onSubmit
	const childRef = useRef<FormWrapperRefProps>(null);

	const [disabled, setDisabled] = useState(true);

	const handleSubmit = () => {
		// Trigger the child forms onSumbit if it is provided
		if (childRef.current?.onSubmit) {
			childRef.current.onSubmit();
		} else {
			console.warn('No onSubmit handler, did you forget it?');
		}
	};

	if (loading) {
		return <LoadingOverlay />;
	}

	return (
		<Stack {...stackProps} spacing={spacing} my={my}>
			<FormContainerContext.Provider
				value={{
					disabled: disabled,
					setDisabled: setDisabled,
				}}
			>
				{React.cloneElement(children, { ref: childRef })}
			</FormContainerContext.Provider>
			<Stack sx={{ justifyContent: 'flex-end', flexDirection: 'row', width: 1 }}>
				<Button
					size='large'
					variant='contained'
					onClick={handleSubmit}
					disabled={disabled}
					loading={loading}
					sx={{ width: 'fit-content', px: 3 }}
				>
					{saveLabel}
				</Button>
			</Stack>
		</Stack>
	);
};

const LoadingOverlay = () => (
	<Stack spacing={2} height={1}>
		<Skeleton animation='wave' variant='rounded' height={30} />
		<Skeleton animation='wave' variant='rounded' height={30} />
	</Stack>
);

export default FormContainerBox;

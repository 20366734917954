import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
} from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';

interface OrganisationInfoProps {
	className?: string;
	partner: object;
}

const OrganisationInfo = (props: OrganisationInfoProps) => {
	const { partner, className } = props;
	const { t } = useTranslation();

	const classes = useStyles();

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader title={t('views.partnerDetail.summary.cardHeaders.organisationDetails')} />
			<Divider />
			<CardContent className={classes.content}>
				<Table>
					<TableBody>
						<TableRow>
							<TableCell>{t('ui.label.name')}</TableCell>
							<TableCell>{partner.partnerOrganisation.name}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell style={{ verticalAlign: 'top' }}>{t('ui.label.description')}</TableCell>
							<TableCell>{partner.partnerOrganisation.description}</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	);
};

export default OrganisationInfo;

import { useState, useEffect, useRef } from 'react';

import { Edit as EditIcon, PhotoCamera as PhotoCameraIcon } from '@mui/icons-material';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
	CardActions,
	Typography,
	Box,
	Stack,
	IconButton,
} from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { DragAndDropImageCropper } from '~components';
import { useAuthorize } from '~features/authentication';
import { useSnackbar } from '~hooks';

import InformationEdit from './InformationEdit/InformationEdit';
import { useStyles } from './style';
import Placeholder from '../../../../../../assets/images/ic-placeholder.svg';
import { StyledButton } from '../../../../../../components';
import { handleReservationType } from '../../../../../../shared/functionality';
import { isObject, isFullArray } from '../../../../../../shared/utility';
import * as actions from '../../../../../../store/actions';

interface ItemInformationProps {
	itemData?: object;
	className?: string;
	image?: unknown[];
	onFetchItemImage?(...args: unknown[]): unknown;
	fetchItemImage?: {
		data?: unknown[];
		loading?: boolean;
		error?: object | string;
	};
	onUpdateItemImage?(...args: unknown[]): unknown;
	updateItemImage?: {
		data?: unknown[];
		loading?: boolean;
		error?: object | string;
	};
	onUpdateDetailsState?(...args: unknown[]): unknown;
	isOwnItem?: boolean;
}

const ItemInformation = (props: ItemInformationProps) => {
	const {
		className,
		itemData,

		onUpdateItemImage,
		updateItemImage,
		onUpdateDetailsState,
		isOwnItem,
	} = props;
	const { t } = useTranslation();

	const { isSuperAdmin } = useAuthorize();

	const { enqueueErrorSnackbar } = useSnackbar();

	const classes = useStyles();
	const [openEdit, setOpenEdit] = useState(false);

	const defaultImage =
		isObject(itemData) && isFullArray(itemData.imagesReference) ?
			itemData.imagesReference[0]
		:	Placeholder;
	const [previewImage, setPreviewImage] = useState(defaultImage);

	const {
		data: updateImageData,
		loading: updateImageLoading,
		error: updateImageError,
	} = updateItemImage;

	useEffect(() => {
		if (!updateImageLoading) {
			if (isFullArray(updateImageData)) {
				onUpdateDetailsState('itemDetails', { ...itemData, imagesReference: updateImageData });
			} else if (isObject(updateImageError)) {
				const initialState =
					isObject(itemData) && isFullArray(itemData.imagesReference) ?
						itemData.imagesReference[0]
					:	'';
				setPreviewImage(initialState);
			}
		}
	}, [updateItemImage]);

	const handleChange = (file: File, uri: string) => {
		setPreviewImage(uri);
		onUpdateItemImage(itemData.id, [file]);
	};

	const handleEditOpen = () => setOpenEdit(true);

	const handleEditClose = () => setOpenEdit(false);

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader title={t('views.itemDetail.summary.section.itemInfo.title')} />
			<Divider />
			<CardContent>
				<Box p={1}>
					<DragAndDropImageCropper
						src={previewImage}
						onChange={(val) => val != null && handleChange(val.file, val.uri)}
						slotProps={{
							uploader: {
								readonly: !(isOwnItem || isSuperAdmin()),
								clearable: false,
								maxFileSizeInBytes: 1024000,
								onError: (errors) => enqueueErrorSnackbar(errors[0]?.message)
							},
							imageCropper: {
								aspect: 1.5,
							},
						}}
					/>
				</Box>
				<Table>
					<TableBody>
						<TableRow>
							<TableCell>{t('ui.label.location')}</TableCell>
							<TableCell>{itemData.hubReference.name}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{t('ui.label.category')}</TableCell>
							<TableCell>{itemData.categoryReference.name}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{t('views.addItem.bookingType.title')}</TableCell>
							<TableCell>{handleReservationType(itemData)}</TableCell>
						</TableRow>
					</TableBody>
				</Table>
				<Divider />
				<Typography className={clsx(classes.body3, classes.tableCell)}>
					{t('ui.label.description')}
				</Typography>
				<Typography className={clsx(classes.tableCell, classes.adjustedTableCell)} variant='body2'>
					{itemData.description}
				</Typography>
			</CardContent>
			{isOwnItem || isSuperAdmin() ?
				<>
					<CardActions className={classes.actions}>
						<StyledButton
							className={classes.editButton}
							onClick={handleEditOpen}
							startIcon={<EditIcon />}
							variant='inline-default'
						>
							{t('ui.button.inline.edit')}
						</StyledButton>
					</CardActions>
					<InformationEdit itemData={itemData} onClose={handleEditClose} open={openEdit} />
				</>
			:	null}
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		fetchItemImage: state.details.fetchItemImage,
		updateItemImage: state.details.updateItemImage,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchItemImage: (itemId) => dispatch(actions.fetchItemImage(itemId)),
		onUpdateItemImage: (itemId, imageArray) =>
			dispatch(actions.updateItemImage(itemId, imageArray)),
		onUpdateDetailsState: (identifier, data) =>
			dispatch(actions.updateDetailsState(identifier, data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemInformation);

import { useEffect } from 'react';

import { Typography, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ActionDialog } from '../../../../components';
import * as actions from '../../../../store/actions';

interface AttentionDialogProps {
	openAttentionDialog?: boolean;
	setOpenAttentionDialog?(...args: unknown[]): unknown;
	onRemoveAttentions?(...args: unknown[]): unknown;
	onResetStateCondition?(...args: unknown[]): unknown;
	onUpdateDetailsState?(...args: unknown[]): unknown;
	removeAttentions?: {
		success?: boolean;
		loading?: boolean;
		error?: object | string;
	};
	data?: object;
}

const AttentionDialog = (props: AttentionDialogProps) => {
	const {
		openAttentionDialog,
		setOpenAttentionDialog,
		onRemoveAttentions,
		removeAttentions,
		data,
		onResetStateCondition,
		onUpdateDetailsState,
	} = props;
	const { t } = useTranslation();

	const { enqueueSnackbar } = useSnackbar();

	const {
		success: removeAttentionSuccess,
		loading: removeAttentionLoading,
		error: removeAttentionError,
	} = removeAttentions;

	useEffect(() => {
		if (removeAttentionSuccess) {
			onResetStateCondition('removeAttentions', false);
			enqueueSnackbar(t('views.bookingDetail.usageInfo.attentionDialog.success.message'), {
				variant: 'success',
			});
			onUpdateDetailsState('fetchBooking', { ...data, attentionStates: ['none'] });
			setOpenAttentionDialog(false);
		} else if (removeAttentionError) {
			onResetStateCondition('removeAttentions', false);
			enqueueSnackbar('Error', { variant: 'error' });
		}
	}, [removeAttentions]);

	const handleCloseAttentionDialog = () => {
		setOpenAttentionDialog(false);
	};

	const handleConfirm = () => {
		const bodyData = { states: data.attentionStates };
		onRemoveAttentions(data.id, bodyData);
	};

	return (
		<ActionDialog
			actionButtonProps={{
				action: handleConfirm,
				text:
					removeAttentionLoading ? <CircularProgress disableShrink size={24} /> : t('ui.confirm'),
			}}
			handleClose={handleCloseAttentionDialog}
			loading={removeAttentionLoading}
			open={openAttentionDialog}
			title={t('views.bookingDetail.usageInfo.dialog.attention.title')}
		>
			<Typography variant='body1'>
				{t('views.bookingDetail.usageInfo.dialog.attention.description')}
			</Typography>
		</ActionDialog>
	);
};

const mapStateToProps = (state) => {
	return {
		removeAttentions: state.condition.removeAttentions,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onRemoveAttentions: (bookingId, bodyData) =>
			dispatch(actions.removeAttentions(bookingId, bodyData)),
		onResetStateCondition: (state, value) => dispatch(actions.resetStateCondition(state, value)),
		onUpdateDetailsState: (state, data) => dispatch(actions.updateDetailsState(state, data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AttentionDialog);

import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { BarChart } from '~components';

import ItemStatisticsService from '../../services/itemStatisticsService';

const service = new ItemStatisticsService();

interface ItemCountBarChartProps {
	organisationId?: string;
}

const ItemCountBarChart = ({ organisationId }: ItemCountBarChartProps) => {
	const { t } = useTranslation();

	const { data, isLoading } = useSWR(
		[service.basePath, { organisationId: organisationId }],
		([_, args]) => {
			if (isLoading) {
				service.abortCurrentRequest('parameter change');
			}

			return service.getCounts(args);
		},
	);

	return (
		<BarChart
			loading={isLoading}
			xAxis={[
				{
					scaleType: 'band',
					data: data?.map((el) => t(`ui.label.${el.category}`)) ?? [],
				},
			]}
			yAxis={[
				{
					valueFormatter: (value: number) => value.toLocaleString(),
					tickMinStep: 1,
				},
			]}
			series={[
				{ data: data?.map((el) => el.activeCount) ?? [], label: t('active') },
				{ data: data?.map((el) => el.inactiveCount) ?? [], label: t('inactive') },
			]}
			margin={{
				left: 40,
				bottom: 70,
				top: 10,
				right: 10,
			}}
		/>
	);
};

export default ItemCountBarChart;

import { useEffect, useState, Fragment } from 'react';

import ApartmentIcon from '@mui/icons-material/Apartment';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Divider, Typography, Card, CardContent, Box, Avatar } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { userInfoAtom } from '~atoms';
import { getInitials } from '~utils/stringUtils';

import ActivitieCard from './ActivitieCard/ActivitieCard';
import Dialog from './Dialog/Dialog';
import SidePanelCard from './SidePanelCard/SidePanelCard';
import { useStyles } from './style';
import { Page, ItemCard, HeaderWithStatus, StyledButton, LightBox } from '../../../../components';
import { commaTimeStrings } from '../../../../shared/datetime';
import {
	isObject,
	isFullString,
	isFullArray,
	isArray,
	handleHubReference,
} from '../../../../shared/utility';
import * as actions from '../../../../store/actions';

interface TicketsDetailsProps {
	getTicket?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onUpdateTicket?(...args: unknown[]): unknown;
	onGetTicket?(...args: unknown[]): unknown;
	onGetTicketActivities?(...args: unknown[]): unknown;
	getTicketActivities?: {
		data?: unknown[];
		loading?: boolean;
		error?: object | string;
	};
	updateTicketActivities?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const TicketsDetails = (props: TicketsDetailsProps) => {
	const {
		onGetTicket,
		getTicket,
		onGetTicketActivities,
		getTicketActivities,
		updateTicketActivities,
	} = props;

	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();
	const userInfo = useAtomValue(userInfoAtom);

	const { id } = useParams();

	const classes = useStyles();

	const [dialogStatus, setDialogStatus] = useState(false);

	const { data: getTicketData, loading: getTicketLoading, error: getTicketError } = getTicket;
	const ticketReady = isObject(getTicketData) && !getTicketLoading && !getTicketError;

	const {
		data: ticketActivitiesData,
		loading: ticketActivitiesLoading,
		error: ticketActivitiesError,
	} = getTicketActivities;
	const ticketActivitiesReady =
		isArray(ticketActivitiesData) && !ticketActivitiesLoading && !ticketActivitiesError;

	const { data: updateActivitiesData, loading: updateActivitiesLoading } = updateTicketActivities;

	const [status, setStatus] = useState('');
	const [comment, setComment] = useState('');

	useEffect(() => {
		if (isObject(getTicketData) || isObject(updateActivitiesData)) {
			if (!updateActivitiesLoading && isObject(getTicketData) && getTicketData.id != null) {
				onGetTicketActivities(getTicketData.id);
			}
		}
	}, [getTicketLoading, updateActivitiesLoading]);

	useEffect(() => {
		if (
			(!ticketActivitiesLoading && !!id) ||
			(isObject(getTicketData) && id !== getTicketData.id)
		) {
			onGetTicket(id);
		}
	}, [id]);

	useEffect(() => {
		if (isObject(getTicketData)) {
			setStatus(getTicketData.status);
		}
	}, [ticketReady]);

	const statusOfTicket = [
		{ label: 'open', name: t('ui.open') },
		{ label: 'pending', name: t('ui.status.pending') },
		{ label: 'planned', name: t('ui.status.planned') },
		{ label: 'ready', name: t('ui.status.ready') },
		{ label: 'closed', name: t('ui.closed') },
	];

	const handleBackButtonChange = () => {
		if (location?.state?.ticketStatus) {
			navigate(location.state.from, {
				state: { ticketStatus: location?.state?.ticketStatus },
			});
		} else {
			navigate('/ticketing/open');
		}
	};

	const ticketStatusColor = {
		nonCritical: 'warning',
		critical: 'error',
		missing: 'disabled',
	};

	const ticketText = {
		nonCritical: t('views.tableResults.serviceTickets.typeNonCritical'),
		critical: t('views.tableResults.serviceTickets.typeCritical'),
		missing: t('views.tableResults.serviceTickets.typeMissing'),
	};

	const images = getTicketData?.imagesReference.map((image, index) => ({
		src: image,
		alt: `image${index}`,
	}));

	return isObject(getTicketData) ?
			<Page className={classes.root} title={t('views.ticketsDetails.pageTitle')}>
				<Box ml={12} pb={1}>
					<StyledButton
						className={classes.backButton}
						onClick={handleBackButtonChange}
						size='small'
						startIcon={<ArrowBackIosIcon fontSize='small' />}
						variant='inline-default'
					>
						{t('ui.button.inline.back')}
					</StyledButton>
				</Box>
				<Box display='flex'>
					<Box className={classes.card}>
						<Card>
							<CardContent className={classes.cardContent}>
								<HeaderWithStatus
									header={`${t('ui.ticket.labelCapitalize')}: ${getTicketData.id}`}
									headerSize='h4'
									status={ticketText[getTicketData.defectReference.type]}
									subheader={`${t('ui.dateCreated')}: ${commaTimeStrings(getTicketData.dateCreated)}`}
									subheaderSize='body2'
									type={ticketStatusColor[getTicketData.defectReference.type]}
								/>
								<Card className={classes.innerCard}>
									<CardContent>
										<ItemCard
											image={getTicketData.itemInstanceReference.itemReference.imagesReference?.at(
												0,
											)}
											instanceName={getTicketData.itemInstanceReference.name}
											locationIcon={<ApartmentIcon fontSize='small' />}
											locationName={handleHubReference(
												getTicketData.itemInstanceReference.hubReference,
											)}
											name={getTicketData.itemInstanceReference.itemReference.name}
										/>
										<Box alignItems='center' display='flex' pt={2}>
											<Typography variant='h6'>
												{`${t('views.ticketsDetails.reportedBy')}:`}
											</Typography>
											{getTicketData?.userReference?.name ?
												<Fragment>
													<Box pl={1}>
														{isFullString(getTicketData?.userReference?.imagesReference?.at(0)) ?
															<Avatar
																src={getTicketData?.userReference?.imagesReference?.at(0)}
																variant='circular'
															/>
														:	<Avatar variant='circular'>
																{getInitials(
																	`${getTicketData.userReference.firstName} ${getTicketData.userReference.lastName}`,
																)}
															</Avatar>
														}
													</Box>
													<Box pl={1}>
														<Typography variant='body1'>
															{getTicketData?.userReference?.name}
														</Typography>
														<Typography variant='body2'>
															{getTicketData?.userReference?.emailAddress}
														</Typography>
													</Box>
												</Fragment>
											:	<Typography className={classes.removedUser} variant='body1'>
													{t('views.tickets.ticketsDetails.label.unknownUser')}
												</Typography>
											}
										</Box>
										<Box pb={1.5} pt={1.5}>
											<Divider className={classes.divider} />
										</Box>
										<Box display='flex' flexDirection='column' pb={1.5} pt={1.5}>
											<Typography gutterBottom variant='h6'>
												{t('ui.label.description')}
											</Typography>
											<Typography variant='body2'>{getTicketData.description}</Typography>
										</Box>
										<Box pb={1.5} pt={1.5}>
											<Divider className={classes.divider} />
										</Box>
										<Typography variant='h6'>{t('ui.label.images')}</Typography>
										<Box display='flex' flexWrap='wrap'>
											{isFullArray(getTicketData.imagesReference) && (
												<LightBox classes={classes} excludeLabel={true} images={images} />
											)}
										</Box>
									</CardContent>
								</Card>
							</CardContent>
						</Card>
						<Box pt={3}>
							<Card>
								<CardContent className={classes.cardContent}>
									<Typography variant='h4'>{t('views.ticketsDetails.ticketActivity')}</Typography>
									<Box pt={2}>
										{ticketActivitiesReady && isFullArray(ticketActivitiesData) ?
											ticketActivitiesData.map((activitie) => (
												<ActivitieCard
													comment={activitie.comment}
													created={activitie.dateCreated}
													key={activitie.id}
													modified={activitie.dateModified}
													status={activitie.status}
													user={activitie.userReference}
												/>
											))
										:	<Typography variant='body2'>
												{t('views.ticketsDetails.noActivities')}
											</Typography>
										}
									</Box>
								</CardContent>
							</Card>
						</Box>
					</Box>
					{isObject(getTicketData) ?
						<SidePanelCard
							getTicketData={getTicketData}
							setDialogStatus={setDialogStatus}
							setStatus={setStatus}
							status={status}
							statusOfTicket={statusOfTicket}
						/>
					:	null}
				</Box>
				{dialogStatus ?
					<Dialog
						comment={comment}
						dialogStatus={dialogStatus}
						organisationId={userInfo.organisation.id}
						setComment={setComment}
						setDialogStatus={setDialogStatus}
						setStatus={setStatus}
						status={status}
						ticketData={getTicketData}
						ticketId={getTicketData.id}
					/>
				:	null}
			</Page>
		:	null;
};

const mapStateToProps = (state) => {
	return {
		getTicket: state.details.getTicket,
		getTicketActivities: state.list.getTicketActivities,
		updateTicketActivities: state.details.updateTicketActivities,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onGetTicket: (id) => dispatch(actions.getTicket(id)),
		onGetTicketActivities: (ticketId) => dispatch(actions.getTicketActivities(ticketId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketsDetails);

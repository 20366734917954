import { useEffect } from 'react';

import { MenuItem, Typography, ListSubheader } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useStyles } from './style';
import { FormFieldSelect, LoadingBar } from '../../../components';
import { isEmptyArray, isFullArray } from '../../../shared/utility';
import * as actions from '../../../store/actions';

interface SubscriptionSelectFieldProps {
	className?: string;
	variable?: object;
	hideHelperText?: boolean;
	helperText?: string;
	hasFirstSelectionDisabled?: boolean;
	subscriptions?: {
		data?: unknown[];
		loading?: boolean;
		error?: object | string;
	};
	onFetchSubscriptions?(...args: unknown[]): unknown;
}

const SubscriptionSelectField = (props: SubscriptionSelectFieldProps) => {
	const {
		className,
		variable,
		hideHelperText,
		helperText,

		subscriptions,
		onFetchSubscriptions,
		hasFirstSelectionDisabled,
	} = props;
	const { t } = useTranslation();
	const classes = useStyles();

	const {
		data: subscriptionsData,
		loading: subscriptionsLoading,
		error: subscriptionsError,
	} = subscriptions;
	const subscriptionsReady =
		isFullArray(subscriptionsData) && !subscriptionsLoading && !subscriptionsError;

	const organisationSubscriptions =
		subscriptionsReady ?
			subscriptionsData.filter((sub) => sub.subscriptionType === 'organisation')
		:	[];
	const supplierSubscriptions =
		subscriptionsReady ?
			subscriptionsData.filter((sub) => sub.subscriptionType === 'supplier')
		:	[];

	useEffect(() => {
		onFetchSubscriptions();
	}, []);

	return (
		<FormFieldSelect
			className={clsx(classes.selectWithLabel, className)}
			name='subscription'
			variable={variable}
			{...(!hideHelperText ?
				{
					helperText:
						helperText ? helperText : t('views.register.organisation.setSubscription.helperText'),
				}
			:	{})}
			displayEmpty
			required
		>
			<MenuItem disabled={hasFirstSelectionDisabled} value={''}>
				<em>{t('views.register.organisation.setSubscription.firstSelectOption')}</em>
			</MenuItem>
			{subscriptionsReady && !isEmptyArray(organisationSubscriptions) ?
				<ListSubheader disableSticky>{t('ui.subscriptionType.organisation')}</ListSubheader>
			:	null}
			{subscriptionsReady && !isEmptyArray(organisationSubscriptions) ?
				organisationSubscriptions.map((subscription) => (
					<MenuItem
						className={classes.menuItem}
						key={`subscription-${subscription.id}`}
						value={`${subscription.id}`}
					>
						<Typography>{`${subscription.name} - ${subscription.subscriptionType === 'supplier' && subscription.maxItemInstances === 1000 ? '*' : subscription.maxItemInstances} ${t('ui.label.items').toLowerCase()} - €${subscription.subscriptionType === 'supplier' && subscription.price.price === 0 ? '*' : subscription.price.price}/${t('ui.month').toLowerCase()}`}</Typography>
					</MenuItem>
				))
			:	null}
			{subscriptionsReady && !isEmptyArray(supplierSubscriptions) ?
				<ListSubheader disableSticky>{t('ui.subscriptionType.supplier')}</ListSubheader>
			:	null}
			{subscriptionsReady && !isEmptyArray(supplierSubscriptions) ?
				supplierSubscriptions.map((subscription) => (
					<MenuItem
						className={classes.menuItem}
						key={`subscription-${subscription.id}`}
						value={`${subscription.id}`}
					>
						<Typography>{`${subscription.name} - ${subscription.subscriptionType === 'supplier' && subscription.maxItemInstances === 1000 ? '*' : subscription.maxItemInstances} ${t('ui.label.items').toLowerCase()} - €${subscription.subscriptionType === 'supplier' && subscription.price.price === 0 ? '*' : subscription.price.price}/${t('ui.month').toLowerCase()}`}</Typography>
					</MenuItem>
				))
			:	null}
			{!subscriptionsReady ?
				Array(3).map((_, index) => (
					<MenuItem key={`loading-item-${index}`} value={''}>
						<LoadingBar />
					</MenuItem>
				))
			:	null}
		</FormFieldSelect>
	);
};

const mapStateToProps = (state) => {
	return {
		subscriptions: state.list.subscriptions,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchSubscriptions: () => dispatch(actions.fetchSubscriptions()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionSelectField);

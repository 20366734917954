import { Fragment, useEffect, useRef, useState } from 'react';

import {
	Box,
	FormControlLabel,
	Stack,
	Switch,
	ToggleButton,
	ToggleButtonGroup,
	useTheme,
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import WeekToggleButtonGroup from '~components/buttons/weekToggleButtonGroup';
import { DayOfWeekEnum } from '~enums';
import { WeeklySchedule } from '~interfaces';
import { DateRange } from '~interfaces/dateRanges';

const fullDay: DateRange<Dayjs> = {
	start: dayjs().startOf('day'),
	end: dayjs().endOf('day'),
};

const defaultWeeklySchedule: WeeklySchedule = {
	isOpenAllDay: false,
	period: {
		start: dayjs().hour(8).minute(0).second(0).toDate(),
		end: dayjs().hour(17).minute(0).second(0).toDate(),
	},
	daysOfWeek: [
		DayOfWeekEnum.Monday,
		DayOfWeekEnum.Tuesday,
		DayOfWeekEnum.Wednesday,
		DayOfWeekEnum.Thursday,
		DayOfWeekEnum.Friday,
	],
};

interface WeeklyScheduleFormProps {
	value?: WeeklySchedule;
	onChange?: (value: WeeklySchedule) => void;
	minutesStep?: number;
}

/**
 * This is a refactored copy of the ./OpeningHoursCard.js. This component has been created because
 * of the following problems/limitation.
 * - Not possible to edit the openingshours.
 * - The available time steps where too large. If made smaller, the options were too much. The steps
 * in this component should be configurable
 * - The component is not responsive
 * - The component was strongly coupled to the wizard
 * And this component should be able to replace OpeningHoursEdit.js used in this project
 *
 * This component doesn't work in an uncontrolled state!
 * https://react.dev/learn/sharing-state-between-components#controlled-and-uncontrolled-components
 * @todo Replace the other components with this component
 */
const WeeklyScheduleForm = ({
	value = defaultWeeklySchedule,
	onChange,
	minutesStep = 5,
}: WeeklyScheduleFormProps) => {
	const { t } = useTranslation();
	const theme = useTheme();

	useEffect(() => {
		// Basically return the default value
		if (value === defaultWeeklySchedule && onChange) {
			onChange(defaultWeeklySchedule);
		}
	}, []);

	/**
	 * Handle a change in the openingshour component
	 */
	const handleChange = (newValue: WeeklySchedule) => {
		onChange?.(newValue);
	};

	const handleDayOfWeekChange = (newValue: WeeklySchedule) => {
		if (onChange && newValue.daysOfWeek.length > 0) {
			onChange(newValue);
		}
	};

	return (
		<Stack
			display='flex'
			// flex='1 1 600px'
			flexDirection='column'
			boxSizing='border-box'
			height={1}
			width={1}
			spacing={1}
		>
			<Stack direction='row' spacing={2} width={1} alignItems='center'>
				<FormControlLabel
					control={
						<Switch
							checked={value.isOpenAllDay}
							onChange={(e) => handleChange({ ...value, isOpenAllDay: e.target.checked })}
						/>
					}
					label={t('ui.allDay')}
					labelPlacement='start'
					sx={{ mx: 0, px: 0 }}
				/>
				<Stack direction='row' spacing={1}>
					<Box width={120}>
						<TimePicker
							label={t('ui.label.openingAt')}
							onChange={(val) =>
								handleChange({ ...value, period: { ...value.period, start: val } })
							}
							maxTime={dayjs(value.period?.end).subtract(minutesStep, 'minute')}
							value={value.isOpenAllDay ? fullDay.start : dayjs(value.period?.start)}
							disabled={value.isOpenAllDay}
							ampm={false}
						/>
					</Box>
					<Box width={120}>
						<TimePicker
							label={t('ui.label.closingAt')}
							minTime={dayjs(value.period?.start).add(minutesStep, 'minute')}
							value={value.isOpenAllDay ? fullDay.end : dayjs(value.period?.end)}
							disabled={value.isOpenAllDay}
							onChange={(val) => handleChange({ ...value, period: { ...value.period, end: val } })}
							ampm={false}
							sx={{ width: '100' }}
						/>
					</Box>
				</Stack>
				<WeekToggleButtonGroup
					value={value.daysOfWeek}
					onChange={(e, newDays) => handleDayOfWeekChange({ ...value, daysOfWeek: newDays })}
				/>
			</Stack>
		</Stack>
	);
};

export default WeeklyScheduleForm;

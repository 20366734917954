import { RouteObject } from 'react-router-dom';

import { pagePathSegments, queryParameters } from '~constants';
import { PageLayout } from '~layouts';
import {
	NfcTagsPage,
	UserDetailsPage,
	UserGroupsPage,
	UserInvitationsPage,
	UsersPage,
} from '~pages';

const userManagementRoutes = (): RouteObject[] => [
	{
		path: `${pagePathSegments.UserManagement}2`,
		element: (
			<PageLayout
				persistedSearchParamKeys={[queryParameters.Organisation]}
				pages={[
					{ i18nKey: 'ui.category.users', path: pagePathSegments.Users },
					{ i18nKey: 'views.users.tab', path: pagePathSegments.UserInvitations },
					{ i18nKey: 'ui.category.userGroups', path: pagePathSegments.UserGroups },
					{ i18nKey: 'ui.label.nfcTags', path: pagePathSegments.NfcTags },
				]}
				slotProps={{
					header: {
						i18nTitleKey: 'views.users.title',
					}
				}}
			/>
		),
		children: [
			{
				path: pagePathSegments.Users,
				element: <UsersPage />,
			},
			{
				path: pagePathSegments.UserGroups,
				element: <UserGroupsPage />,
			},
			{
				path: pagePathSegments.UserInvitations,
				element: <UserInvitationsPage />,
			},
			{
				path: pagePathSegments.NfcTags,
				element: <NfcTagsPage />,
			},
		],
	},
	{
		path: `${pagePathSegments.UserManagement}2`,
		children: [
			{
				path: pagePathSegments.Users,
				children: [
					{
						path: ':id',
						element: <PageLayout i18nTitleKey='views.userDetails.page.title' />,
						children: [
							{
								index: true,
								element: <UserDetailsPage />,
							},
						],
					},
				],
			},
		],
	},
];

export default userManagementRoutes;

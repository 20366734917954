import path from 'path';

import { AddressResponse, BaseReferenceResponse } from '~interfaces/responses';
import { TopologyService } from '~services';

import PaymentMethodStatusEnum from '../enums/paymentMethodStatusEnum';
import PaymentMethod from '../interfaces/paymentMethod';

class PaymentMethodsService extends TopologyService {
	protected readonly path = 'paymentmethods';

	public readonly personalSubPath = 'personal';

	constructor() {
		super('v1');
	}

	async getPersonalPaymentMethod({ ...args }: { userId: string }): Promise<PaymentMethod | null> {
		const { data } = await this._client.get<PaymentMethodResponse>(
			path.join(this.path, this.personalSubPath),
			{
				params: {
					userId: args.userId,
				},
			}
		);

		if (data?.status === 'unavailable') {
			return null;
		}

		return PaymentMethodsService.fromResponse(data);
	}

	async removePaymentMethod(userId: string, data): Promise<null> {
		await this._client.delete<null, null, PaymentMethodRemovalRequest>(
			path.join(this.path, userId),
			{
				data: data,
			}
		);
		return null;
	}

	static fromResponse(data: PaymentMethodResponse): PaymentMethod {
		const { id, name, logo, creationDate, status, ...rest } = data;

		return {
			...rest,
			...PaymentMethodsService.fromBaseReferenceResponse({ id: id, name: name }),
			logoUri: logo,
			creationDate: new Date(creationDate),
			status: status as PaymentMethodStatusEnum,
		};
	}
}

interface PaymentMethodResponse extends BaseReferenceResponse {
	status: string;
	creationDate: string;
	logo?: string;
	invoiceContact?: {
		name: string;
		address: AddressResponse;
	};
	bank?: {
		bankName: string;
		bic: string;
		countryCode: string;
		iban: string;
		ownerName: string;
	};
	card?: {
		expiryMonth: string;
		expiryYear: string;
		holderName: string;
		number: string;
	};
	paypal?: {
		email: string;
		payerId: string;
		payerStatus: string;
		phone: string;
		payerResidenceCountry: string;
	};
}

interface PaymentMethodRemovalRequest {
	message?: string;
}

export default PaymentMethodsService;

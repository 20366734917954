import { useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Drawer, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useStyles } from './style';
import { SearchAutocomplete, StyledButton, SelectedItemCard } from '../../../components';
import { useError } from '../../../shared/hooks';
import {
	isEmptyObject,
	isObject,
	handleLockType,
	handleHubReference,
	isFullArray,
} from '../../../shared/utility';
import * as actions from '../../../store/actions';
import SelectedDeviceCard from '../SelectedDeviceCard';

interface LinkDevicesProps {
	open?: boolean;
	id?: number;
	onClose?(...args: unknown[]): unknown;
	selectedDevices?: object | unknown[];
	setSelectedDevices?(...args: unknown[]): unknown;
	onFetchDevices?(...args: unknown[]): unknown;
	onLinkDevices?(...args: unknown[]): unknown;
	onFetchAssignableInstances?(...args: unknown[]): unknown;
	assignableInstances?: any;
	onFetchItemImage?(...args: unknown[]): unknown;
	fetchItemImage?: any;
	onResetState?(...args: unknown[]): unknown;
	onLinkDeviceToItemGroup?(...args: unknown[]): unknown;
	onItemsSlim?(...args: unknown[]): unknown;
	devices?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	planboardItemsToAdd?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	linkDevices?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	linkDeviceToItemGroup?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	itemsSlim?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const LinkDevices = (props: LinkDevicesProps) => {
	const {
		onClose,
		open,

		selectedDevices,
		setSelectedDevices,
		onFetchDevices,
		devices,
		assignableInstances,
		onLinkDevices,
		fetchItemImage,
		linkDevices,
		onFetchItemImage,
		id,
		onFetchAssignableInstances,
		onResetState,
		onLinkDeviceToItemGroup,
		linkDeviceToItemGroup,
		onItemsSlim,
		itemsSlim,
	} = props;
	const { t } = useTranslation();

	const classes = useStyles();

	const isItemGroupLink = selectedDevices?.link === 'itemGroup';

	const { data: fetchItemImageData, loading: fetchItemImageLoading } = fetchItemImage;

	const {
		data: assignableInstancesData,
		loading: assignableInstancesLoading,
		error: assignableInstancesError,
	} = assignableInstances;
	const assignableInstancesReady =
		isObject(assignableInstancesData) && !assignableInstancesLoading && !assignableInstancesError;

	const [fullResourceList, setFullResourceList] = useState({
		data: null,
		loading: false,
		error: null,
	});
	const [selectedItem, setSelectedItem] = useState(null);
	const [fetchImage, setFetchImage] = useState(true);

	const linkDevicesMessage = useError({
		value: linkDevices,
		message: t('views.devices.linkDevices.successfullMessage'),
	});

	const linkItemGroupMessage = useError({
		value: linkDeviceToItemGroup,
		message: t('views.devices.linkDevices.successfullMessage'),
	});

	const [itemListFilter, setItemListFilter] = useState({ name: 'organisationId', value: '' });

	const devicesFilter = { name: 'isLinked', value: false };

	const extraDevicesFilter = { name: 'isAssigned', value: true };

	const sortingFilter = { name: 'includePublic', value: false };

	const [deepFilter, setDeepFilter] = useState({
		name: 'linkedDeviceId',
		value: selectedDevices?.deviceId,
	});

	const extraItemFilter = {
		name: isItemGroupLink ? 'includeShared' : 'filterAssigned',
		value: isItemGroupLink ? false : true,
	};

	useEffect(() => {
		if (id) {
			setItemListFilter({ ...itemListFilter, value: id });
		}
	}, [id]);

	useEffect(() => {
		if (isObject(selectedDevices?.deviceId)) {
			setDeepFilter((filter) => ({ ...filter, value: selectedDevices.deviceId }));
		}
	}, [selectedDevices]);

	useEffect(() => {
		if (!fetchItemImageLoading && isObject(selectedItem) && fetchImage) {
			onFetchItemImage(isItemGroupLink ? selectedItem.id : selectedItem.itemReference.id);
			setFetchImage(false);
		}
	}, [selectedItem, fetchItemImageLoading]);

	useEffect(() => {
		if (!open) {
			setSelectedDevices(null);
			setSelectedItem(null);
			onResetState('linkDevices');
			onResetState('linkDeviceToItemGroup');
			onResetState('fetchItemImage');
			setFetchImage(true);
		}
	}, [open]);

	useEffect(() => {
		if (assignableInstancesLoading) {
			setFullResourceList({ ...fullResourceList, loading: true });
		}
	}, [assignableInstancesLoading]);

	// creating a full resource list (all selectable resources when creating events)
	useEffect(() => {
		if (!assignableInstancesLoading && isObject(assignableInstancesData)) {
			const childResources = assignableInstancesData.results.map((item) => ({
				...item,
				title: `${item.itemReference.name} - ${item.name}`,
				childHub: item.hubReference,
			}));

			setFullResourceList({
				...assignableInstances,
				data: {
					hasMore: assignableInstances.data.hasMore,
					pageNumber: assignableInstances.data.pageNumber,
					pageSize: assignableInstances.data.pageSize,
					total: assignableInstances.data.total,
					results: childResources,
				},
			});
		}
	}, [assignableInstancesReady, assignableInstancesLoading]);

	const resetSelectedDevice = () => {
		setSelectedDevices(null);
		setSelectedItem(null);
	};

	const resetSelectedItem = () => {
		setSelectedItem(null);
		setFetchImage(true);
		onResetState('fetchItemImage');
	};

	const handleConfirm = () => {
		if (isItemGroupLink) {
			onLinkDeviceToItemGroup(
				selectedDevices.deviceId,
				selectedDevices.organisationReference.id,
				selectedItem.id,
			);
			linkItemGroupMessage.setStartAction(true);
			onClose();
			return;
		}
		onLinkDevices(
			selectedDevices.deviceId,
			selectedDevices.organisationReference.id,
			selectedItem.id,
		);
		onClose();
		linkDevicesMessage.setStartAction(true);
	};

	return (
		<Drawer
			anchor='right'
			classes={{ paper: classes.drawer }}
			onClose={() => onClose()}
			open={open}
			variant='temporary'
		>
			{open ?
				<>
					<Box display='flex'>
						<StyledButton
							onClick={() => onClose()}
							size='small'
							startIcon={<CloseIcon />}
							variant='inline-default'
						>
							{t('ui.button.inline.close')}
						</StyledButton>
					</Box>
					<Typography className={classes.header} variant='h2'>
						{isItemGroupLink ?
							t('views.devices.linkDevices.linkItemGroup')
						:	t('views.devices.linkDevices.linkToItem')}
					</Typography>
					<Box pb={2}>
						<Typography variant='h6'>{t('ui.label.device.name')}</Typography>
					</Box>
					<Box mb={3}>
						{isObject(selectedDevices) ?
							<SelectedDeviceCard
								deviceType={handleLockType(selectedDevices.deviceType)}
								handleClose={resetSelectedDevice}
								loading={isEmptyObject(selectedDevices)}
								name={`Hw UID: ${selectedDevices.deviceId}`}
								version={selectedDevices.firmwareVersion}
							/>
						:	<SearchAutocomplete
								dataList={devices}
								extraFilter={extraDevicesFilter}
								filter={devicesFilter}
								listType={'devices'}
								onFetchData={onFetchDevices}
								placeholder={t('ui.label.search.items')}
								popperSize='large'
								setSelected={setSelectedDevices}
							/>
						}
					</Box>
					<Box>
						<Box pb={2}>
							<Typography variant='h6'>{t('ui.label.item')}</Typography>
							<Box pt={2}>
								{isObject(selectedItem) ?
									<SelectedItemCard
										address={
											selectedItem?.hubReference && handleHubReference(selectedItem.hubReference)
										}
										handleClose={resetSelectedItem}
										image={isFullArray(fetchItemImageData) ? fetchItemImageData[0] : null}
										loading={fetchItemImageLoading}
										name={isItemGroupLink ? selectedItem.name : selectedItem?.title}
									/>
								:	<SearchAutocomplete
										dataList={isItemGroupLink ? itemsSlim : fullResourceList}
										extraFilter={extraItemFilter}
										sortingFilter={isItemGroupLink && sortingFilter}
										filter={itemListFilter}
										deepFilter={isItemGroupLink && deepFilter}
										listType={isItemGroupLink ? 'hubs' : 'planboard-items'}
										onFetchData={isItemGroupLink ? onItemsSlim : onFetchAssignableInstances}
										placeholder={t('ui.label.search.items')}
										popperSize='large'
										setSelected={setSelectedItem}
									/>
								}
							</Box>
						</Box>
					</Box>
					<StyledButton
						disabled={!selectedItem?.id || !selectedDevices?.deviceId}
						onClick={handleConfirm}
						size='large'
						variant='contained-primary'
					>
						{t('ui.confirm')}
					</StyledButton>
				</>
			:	null}
		</Drawer>
	);
};

const mapStateToProps = (state) => {
	return {
		devices: state.paged.devices,
		linkDeviceToItemGroup: state.details.linkDeviceToItemGroup,
		planboardItemsToAdd: state.planboard.planboardItemsToAdd,
		linkDevices: state.details.linkDevices,
		assignableInstances: state.paged.assignableInstances,
		fetchItemImage: state.details.fetchItemImage,
		itemsSlim: state.paged.itemsSlim,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchDevices: (page, filters) => dispatch(actions.fetchDevices(page, filters)),
		onFetchItems: (page, filters, concat) => dispatch(actions.fetchItems(page, filters, concat)),
		onLinkDevices: (deviceId, organisationId, instanceId) =>
			dispatch(actions.linkDevices(deviceId, organisationId, instanceId)),
		onFetchAssignableInstances: (page, filters, concat) =>
			dispatch(actions.fetchAssignableInstances(page, filters, concat)),
		onFetchItemImage: (id) => dispatch(actions.fetchItemImage(id)),
		onResetState: (identifier) => dispatch(actions.resetState(identifier)),
		onLinkDeviceToItemGroup: (deviceId, organisationId, itemId) =>
			dispatch(actions.linkDeviceToItemGroup(deviceId, organisationId, itemId)),
		onItemsSlim: (page, filters, concat) => dispatch(actions.itemsSlim(page, filters, concat)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkDevices);

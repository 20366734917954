import { useTranslation } from 'react-i18next';

import { ActionDialog } from '../../../../../components';

interface DownloadCsvDialogProps {
	open?: boolean;
	onClose?(...args: unknown[]): unknown;
	onClickDownload?(...args: unknown[]): unknown;
}

const DownloadCsvDialog = (props: DownloadCsvDialogProps) => {
	const { open, onClose, onClickDownload } = props;
	const { t } = useTranslation();

	const handleDownloadCsv = () => onClickDownload();

	const handleCloseDownloadCsv = () => onClose();

	return (
		<ActionDialog
			actionButtonProps={{
				action: handleDownloadCsv,
				text: t('view.itemmanagement.itemdetails.button.contained.downloadCSV'),
			}}
			handleClose={handleCloseDownloadCsv}
			loading={false}
			open={open}
			title={t('views.itemDetail.instances.dialog.downloadCSV.title')}
		>
			{t('views.itemDetail.instances.dialog.downloadCSV.description')}
		</ActionDialog>
	);
};

export default DownloadCsvDialog;

import path from 'path';

import axios from 'axios';

import { PagedResults } from '~interfaces';
import { BasePagedParameters } from '~interfaces/requests';
import { BaseReferenceResponse } from '~interfaces/responses';
import { TopologyService } from '~services';

import SsoDomain, { NewSsoDomain } from '../interfaces/ssoDomain';

class OrganisationDomainsService extends TopologyService {
	public readonly path = 'organisations';
	public readonly ssoDomainSubPath = 'ssodomains';

	constructor() {
		super('v1');
	}

	async getSsoDomains({
		page = 1,
		pageSize = 10,
		...args
	}: Required<Pick<BasePagedParameters, 'organisationId'>> & BasePagedParameters): Promise<
		PagedResults<SsoDomain>
	> {
		const { data } = await this._client.get(
			path.join(this.path, args.organisationId, 'ssodomains'),
			{
				params: {
					pageNumber: page,
					pageSize: pageSize,
				},
			},
		);

		return this.mapPagedResponse(data, OrganisationDomainsService.fromSsoDomainResponse);
	}

	async validateSsoDomain(
		organisationId: string,
		value: string,
	): Promise<{ valid: boolean; message?: string }> {
		try {
			await this._client.get(path.join(this.path, organisationId, 'ssodomains/validate'), {
				params: {
					// Why is this necessary, the other option is none. If you choose none,
					// what would the validate endpoint do? Or trying to make it future proof
					type: 'uniqueName',
					value: value,
				},
			});

			// If response.status == 200, it means validation is ok
			return {
				valid: true,
			};
		} catch (error) {
			if (
				axios.isAxiosError(error) &&
				error.status === 400 &&
				error.response?.data.key === 'EmailDomainUnavailable'
			) {
				// If response.status == 400 and specific key, already taken
				return {
					valid: false,
					message: error.response.data.message,
				};
			}

			throw error;
		}
	}

	async addSsoDomains(organisationId: string, domains: NewSsoDomain[]): Promise<null> {
		const content = domains.map((el) => ({ domain: el.domainName }));

		const response = await this._client.put(
			path.join(this.path, organisationId, 'ssodomains'),
			content,
		);

		return null;
	}

	async deleteSsoDomain(organisationId: string, domainName: string): Promise<null> {
		await this._client.delete(path.join(this.path, organisationId, 'ssodomains', domainName));

		return null;
	}

	static fromEmailDomainResponse(data: EmailDomainResponse): EmailDomain {
		return {
			domainName: data.domainName,
			userGroup: OrganisationDomainsService.fromBaseReferenceResponse(data.userGroupReference),
		};
	}

	static fromSsoDomainResponse(data: SsoDomainResponse): SsoDomain {
		return {
			domainName: data.domainName,
			organisation: OrganisationDomainsService.fromBaseReferenceResponse(data.organisation),
		};
	}
}

interface EmailDomainResponse {
	domainName: string;
	userGroupReference: BaseReferenceResponse;
}

interface SsoDomainResponse {
	domainName: string;
	organisation: BaseReferenceResponse;
}

export default OrganisationDomainsService;

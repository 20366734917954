import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import { Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useStyles } from './style';
import { AdjustedSkeleton, StyledButton } from '../../../../../components';
import { useDetailPageBackButton } from '../../../../../shared/hooks';

interface HeaderProps {
	sharingType?: string;
	setOpenItmeDrawer?(...args: unknown[]): unknown;
	customer?: object;
	loading?: boolean;
	itemDetails?: object;
}

const Header = (props: HeaderProps) => {
	const { setOpenItmeDrawer, loading, sharingType, itemDetails } = props;
	const location = useLocation();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const classes = useStyles();

	const returnButton = useDetailPageBackButton(location);

	const handleBackButtonChange = () => {
		if (location?.state?.from) {
			navigate(location.state.from);
		} else {
			navigate('/item-management/management');
		}
	};

	const handleBackButton = () => {
		if (returnButton.isBackButton || !location.state?.label) {
			return t('ui.button.inline.back');
		} else {
			return location.state.label;
		}
	};

	const handleHeader = () => {
		if (sharingType === 'internal') {
			return itemDetails?.userGroup?.name;
		} else if (sharingType === 'public') {
			return t('ui.label.public');
		} else if (itemDetails?.consumer) {
			return itemDetails.consumer.name;
		} else if (itemDetails?.consumerName) {
			return itemDetails?.consumerName;
		}
	};

	const handleOpenDrawer = () => {
		setOpenItmeDrawer(true);
	};

	return (
		<Grid
			alignItems='center'
			className={classes.headerSpacing}
			container
			justifyContent='space-between'
			spacing={3}
		>
			<Grid item>
				<StyledButton
					className={classes.backButton}
					onClick={handleBackButtonChange}
					startIcon={<KeyboardArrowLeftOutlinedIcon />}
					variant='inline-default'
				>
					{handleBackButton()}
				</StyledButton>
				<Typography component='h1' variant='h2'>
					{loading ?
						<AdjustedSkeleton animation='wave' width={200} />
					:	`${itemDetails?.item?.name} ${t('views.tableResults.items.sharedWith')} - ${handleHeader()}`
					}
				</Typography>
			</Grid>
			<Grid item>
				<StyledButton onClick={handleOpenDrawer} variant='contained-primary'>
					{'Add item'}
				</StyledButton>
			</Grid>
		</Grid>
	);
};

export default Header;

import { useState, useEffect } from 'react';

import { Box, Link, Avatar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { useDebounce } from '~hooks';
import { getInitials } from '~utils/stringUtils';

import Header from './Header';
import { useStyles } from './style';
import { Table, Label, SearchBar, Page } from '../../../../components';
import {
	isObject,
	isEmptyString,
	isEmptyArray,
	decimalAmount,
	getFinanceTableHeaders,
} from '../../../../shared/utility';
import * as actions from '../../../../store/actions';

interface OrganisationsProps {
	date?: string;
	onFetchBusinessExpenses?(...args: unknown[]): unknown;
	businessExpenses?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const Organisations = (props: OrganisationsProps) => {
	const { onFetchBusinessExpenses, businessExpenses } = props;
	const { t } = useTranslation();

	const classes = useStyles();
	const { date, title } = useParams();
	const [shouldDoInitialFetch, setShouldDoInitialFetch] = useState(true);
	const [showingInitialResults, setShowingInitialResults] = useState(true);
	const [searchValue, setSearchValue] = useState('');
	const [shouldSearch, setShouldSearch] = useState(false);
	const [isShowingSearchResults, setIsShowingSearchResults] = useState(false);

	const shouldSearchOnDebounce = false;

	const { data: businessExpensesData, loading: businessExpensesLoading } = businessExpenses;

	const filters = {
		...(!isEmptyString(searchValue) && { searchTerm: searchValue }),
	};

	useEffect(() => {
		if (shouldSearch || shouldDoInitialFetch) {
			if (shouldSearch && showingInitialResults) {
				setShowingInitialResults(false);
			}
			if (date && !businessExpensesLoading) {
				onFetchBusinessExpenses(date, filters);
			}
		}
		if (shouldSearch) {
			setShouldSearch(false);
		} else if (shouldDoInitialFetch) {
			setShouldDoInitialFetch(false);
		}
	}, [date, shouldSearch, shouldDoInitialFetch]);

	const handleSearchClick = () => {
		if (!isEmptyString(searchValue)) {
			setShouldSearch(true);
			setIsShowingSearchResults(true);
		}
		if (isEmptyString(searchValue) && isShowingSearchResults) {
			setShouldSearch(true);
			setIsShowingSearchResults(false);
		}
	};

	const handleResetSearch = () => {
		setSearchValue('');
		if (isShowingSearchResults === true) {
			setShouldSearch(true);
		}
		setIsShowingSearchResults(false);
	};

	const handleKeyUp = (e) => {
		switch (e.key) {
			case 'Enter':
				handleSearchClick();
				break;
			default:
				return;
		}
	};

	const events = {
		onClick: handleSearchClick,
		onChange: (e) => setSearchValue(e.target.value),
		onClear: handleResetSearch,
		onKeyUp: (e) => handleKeyUp(e),
	};

	const debouncedSearchValue = useDebounce(searchValue, 500);

	useEffect(() => {
		if (shouldSearchOnDebounce) {
			handleSearchClick();
		}
	}, [shouldSearchOnDebounce, debouncedSearchValue]);

	const headers = getFinanceTableHeaders(true);

	const tableHeader = [
		{ name: 'organisations', content: t('ui.category.organisations') },
		...headers,
	];

	const statusColor = {
		open: 'default',
		paid: 'success',
	};

	const statusText = {
		open: t('ui.open'),
		paid: t('ui.label.paid'),
	};

	const emptyBody =
		(
			isObject(businessExpensesData) &&
			!businessExpensesLoading &&
			isEmptyArray(businessExpensesData.billings)
		) ?
			Array(1)
				.fill(Array(tableHeader.length).fill())
				.map((arr) => arr.map(() => ({ content: '-' })))
		:	null;

	const loadingBody =
		!isObject(businessExpensesData) || businessExpensesLoading ?
			Array(3)
				.fill(Array(tableHeader.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })))
		:	null;

	const dataBody =
		isObject(businessExpensesData) && !businessExpensesLoading ?
			businessExpensesData.billings.map((expenses) => [
				{
					content: (
						<Box alignItems='center' display='flex' height='19px' width='400px'>
							{expenses.organisationReference.logo ?
								<Avatar src={expenses.organisationReference.logo} variant='square' />
							:	<Avatar variant='square'>{getInitials(expenses.organisationReference.name)}</Avatar>}
							<Box pl={1}>
								<Link
									className={classes.link}
									color='inherit'
									component={RouterLink}
									to={`/financeManagement/expenses/${date}/${title}/partner/expenses/${expenses.organisationReference.id}/${expenses.organisationReference.name}`}
								>
									{`${expenses.organisationReference.name}`}
								</Link>
							</Box>
						</Box>
					),
				},
				{ content: `${decimalAmount(expenses.amount.subtotal, expenses.amount)}` },
				{ content: `${decimalAmount(expenses.amount.discount, expenses.amount)}` },
				{
					content: `${decimalAmount(expenses.amount.subtotal - expenses.amount.discount, expenses.amount)}`,
				},
				{ content: `${decimalAmount(expenses.amount.priceVat, expenses.amount)}` },
				{ content: `${decimalAmount(expenses.amount.refund, expenses.amount)}` },
				{ content: `${decimalAmount(expenses.amount.totalPrice, expenses.amount)}` },
				{ content: `${decimalAmount(expenses.open, expenses.amount)}` },
				{
					content: <Label type={statusColor[expenses.status]}>{statusText[expenses.status]}</Label>,
				},
			])
		:	Array(3)
				.fill(Array(tableHeader.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })));

	const titleWithInformation =
		isObject(businessExpensesData) ?
			{
				header: t('views.financeHistory.organisationalBilling.header'),
			}
		:	null;

	return (
		<Page className={classes.root} title={t('ui.details')}>
			<Header title={title} />
			<Box pt={3}>
				<SearchBar
					hasSearchButton
					placeholder={t('views.usersAndOrganisations.organisations.searchPlaceholder')}
					searchEvents={events}
					searchValue={searchValue}
				/>
			</Box>
			<Table
				body={loadingBody || emptyBody || dataBody}
				header={tableHeader}
				isNotPaginate={true}
				loading={businessExpensesLoading}
				noTitle={true}
				titleWithInformation={titleWithInformation}
				withoutPages={true}
			/>
		</Page>
	);
};

const mapStateToProps = (state) => {
	return {
		businessExpenses: state.details.businessExpenses,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchBusinessExpenses: (date, filters) =>
			dispatch(actions.fetchBusinessExpenses(date, filters)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Organisations);

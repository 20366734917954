import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Typography, Box } from '@mui/material';
import clsx from 'clsx';

import { useStyles } from './style';
import { Tooltip } from '../../../components';

interface InfoLabelProps {
	className?: string;
	name?: string;
	info?: string;
	styles?: string;
	bColor?: string;
	variant?: string;
	paddingTop?: number;
	paddingBottom?: number;
	isRequired?: boolean;
}

const InfoLabel = (props: InfoLabelProps) => {
	const { className, name, info, isRequired, variant, paddingTop, paddingBottom } = props;

	const classes = useStyles();

	return (
		<Box className={clsx(classes.root, className)} pb={paddingBottom} pt={paddingTop}>
			<Typography className={classes.icon} variant={variant}>
				{name}
				{isRequired ?
					<sup>*</sup>
				:	null}
			</Typography>
			<Tooltip title={info} placement='bottom'>
				<HelpOutlineIcon fontSize='small' />
			</Tooltip>
		</Box>
	);
};

export default InfoLabel;

import { forwardRef, useEffect, useImperativeHandle } from 'react';

import { InputAdornment, Stack } from '@mui/material';
import Ajv, { JSONSchemaType } from 'ajv';
import { Controller, Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AsyncTextField } from '~components';
import { useFormContainerState } from '~components/dialogs/formContainerProvider';
import { FormWrapperRefProps } from '~interfaces/refProps';

import arraySchema from './ssoDomainArraySchema.json';
import schema from './ssoDomainSchema.json';
import SsoDomain, { NewSsoDomain } from '../../interfaces/ssoDomain';
import OrganisationDomainsService from '../../services/organisationDomainsService';

const ajv = new Ajv();

const service = new OrganisationDomainsService();

interface SsoDomainFormProps {
	organisationId: string;
	selectedValues?: NewSsoDomain[];
	onSubmit?: (value: NewSsoDomain) => void;
}

/**
 *
 * @param props
 * @returns
 */
const SsoDomainForm = forwardRef<FormWrapperRefProps, SsoDomainFormProps>(
	({ selectedValues, organisationId, onSubmit, ...props }, ref) => {
		const { t } = useTranslation();

		/**
		 * May seems like bit of an overkill to validate one field. But the
		 * principle can be request if we want to combine/chain client and server
		 * side validation.
		 * @param values
		 * @param context
		 * @returns
		 */
		const customResolver: Resolver<SsoDomain> = async (values, context) => {
			// First validate the value itself
			let isValid = ajv.validate(schema, values);
			if (!isValid) {
				return getAjvErrorsForReactHookForms();
			}

			// Validate with respect to already added values
			if (selectedValues != null && selectedValues?.length > 0) {
				const newDomains = [...selectedValues.map((el) => el.domainName), values.domainName];
				isValid = ajv.validate(arraySchema, { domainNames: newDomains });

				if (ajv.errors != null) {
					// We want to set the errors for the domainName, so overwrite it here
					const index = ajv.errors?.findIndex((el) => el.instancePath.includes('domainNames'));
					if (index >= 0) {
						ajv.errors[index].instancePath = ajv.errors[index].instancePath.replace(
							'domainNames',
							'domainName'
						);
					}
					return getAjvErrorsForReactHookForms();
				}
			}

			// Validate with the server if the domain name is unused
			if (values.domainName) {
				const res = await service.validateSsoDomain(organisationId, values.domainName);
				if (res.valid === false) {
					return {
						values: {},
						errors: {
							domainName: {
								type: 'custom',
								message: res.message,
							},
						},
					};
				}
			}

			return {
				values: values,
				errors: {},
			};
		};

		const getAjvErrorsForReactHookForms = () => {
			const errorsFormatted = ajv.errors?.reduce(
				(prev, current) => ({
					...prev,
					[current.instancePath.replace('/', '')]: {
						type: current.keyword ?? 'validation',
						message: current.message,
					},
				}),
				{}
			);

			return {
				values: {},
				errors: errorsFormatted,
			};
		};

		const { setDisabled } = useFormContainerState();
		const { control, getValues, formState } = useForm<SsoDomain>({
			// Validation will trigger on the first blur event. After that, it will trigger on every change event.
			mode: 'onTouched',
			resolver: customResolver,
		});

		useImperativeHandle(
			ref,
			() => ({
				onSubmit: () => {
					onSubmit?.(getValues());
					setDisabled?.(true);
				},
			}),
			[onSubmit]
		);

		useEffect(() => {
			setDisabled?.(!formState.isValid);
		}, [formState.isValid, setDisabled]);

		return (
			<Stack spacing={1}>
				<Controller
					name='domainName'
					control={control}
					render={({ field, fieldState }) => {
						const message =
							fieldState.error?.type === 'pattern' ? t('ui.error.message.isEmailDomain')
							: fieldState.error?.type === 'uniqueItems' ? t('views.addEmailDomains.extraValid')
							: fieldState.error?.message;

						return (
							<AsyncTextField
								{...field}
								required
								label={t('ui.label.emailDomain')}
								placeholder='domain.com'
								error={message != null}
								helperText={message}
								slotProps={{
									input: {
										startAdornment: <InputAdornment position='start'>@</InputAdornment>,
									},
								}}
							/>
						);
					}}
				/>
			</Stack>
		);
	}
);

export default SsoDomainForm;

import { useEffect, useRef, useState } from 'react';

import { Stack } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';

import { ConfirmationDialog, FormDialog } from '~components';
import { useSnackbar } from '~hooks';

import selectedOrganisationAtom from '../atoms/selectedOrganisationAtom';
import SkcUserGroupsDataGrid from '../components/dataGrids/skcUserGroupsDataGrid';
import SkcUserGroupForm from '../forms/skcUserGroupForm/skcUserGroupForm';
import { SkcUserGroupNew } from '../interfaces/skcUserGroup';
import SkcUserGroupsService from '../services/skcUserGroupsService';

// Delete me

type UserAction =
	| {
			type: 'delete' | 'edit';
			label?: string;
			id: string;
	  }
	| {
			type: 'create';
			label?: never;
			id?: never;
	  };

const service = new SkcUserGroupsService();

const SkcUserGroupsOverviewLayout = () => {
	const { t } = useTranslation();
	const { enqueueSuccessSnackbar, enqueueAxiosErrorSnackbar } = useSnackbar();
	const dataGridRef = useRef<{ mutate: () => void }>(null);

	const [userAction, setUserAction] = useState<UserAction | null>(null);

	const selectedOrganisation = useAtomValue(selectedOrganisationAtom);

	useEffect(() => {
		// Should be temporary
		if (selectedOrganisation) {
			service.organisation = selectedOrganisation;
		}
	}, [selectedOrganisation]);

	const { data: userGroupData } = useSWR(
		userAction?.type === 'edit' ? [service.basePath, userAction.id] : null,
		([_, id]) => service.getUserGroupById(id)
	);

	const { trigger: triggerCreate, isMutating: isCreateMutating } = useSWRMutation(
		service.basePath,
		(_, { arg }: { arg: SkcUserGroupNew }) => service.createCompleteUserGroup(arg),
		{
			onSuccess: () => {
				enqueueSuccessSnackbar(`${t('ui.successfullyAdded')} ${t('accessGroup').toLowerCase()}`);
				setUserAction(null);
				dataGridRef.current?.mutate();
			},
			onError: (error) => enqueueAxiosErrorSnackbar(error),
		}
	);

	const { trigger: triggerEdit, isMutating: isEditMutating } = useSWRMutation(
		userAction?.id ? [service.basePath, userAction.id] : null,
		([_, id], { arg }: { arg: SkcUserGroupNew }) =>
			service.updateCompleteUserGroup(id, arg, userGroupData),
		{
			onSuccess: () => {
				enqueueSuccessSnackbar(
					`${t('ui.success.message.updated')} ${t('accessGroup').toLowerCase()}`
				);
				setUserAction(null);
				dataGridRef.current?.mutate();
			},
			onError: (error) => enqueueAxiosErrorSnackbar(error),
		}
	);

	const { trigger: triggerDelete, isMutating: isDeleteMutating } = useSWRMutation(
		userAction?.id ? [service.basePath, userAction.id] : null,
		([_, id]) => service.deleteUserGroup(id),
		{
			onSuccess: () => {
				enqueueSuccessSnackbar(`${t('ui.successfullyDeleted')} ${t('accessGroup').toLowerCase()}`);
				setUserAction(null);
				dataGridRef.current?.mutate();
			},
			onError: (error) => enqueueAxiosErrorSnackbar(error),
		}
	);

	return (
		<Stack
			sx={{
				height: 1,
			}}
		>
			<SkcUserGroupsDataGrid
				ref={dataGridRef}
				onCreate={() => setUserAction({ type: 'create' })}
				onEdit={(value) => setUserAction({ type: 'edit', ...value })}
				onDelete={(value) => setUserAction({ type: 'delete', ...value })}
			/>
			<FormDialog
				title={t(userAction?.type === 'edit' ? 'editResource' : 'addResource', {
					resource: t('accessGroup'),
				})}
				loading={isCreateMutating || isEditMutating}
				open={userAction?.type === 'edit' || userAction?.type === 'create'}
				onClose={() => setUserAction(null)}
				fullWidth
				maxWidth='lg'
			>
				<SkcUserGroupForm
					id={userAction?.id}
					onSubmit={async (data) => {
						if (userAction?.type === 'create') {
							await triggerCreate(data);
						} else if (userAction?.type === 'edit') {
							await triggerEdit(data);
						} else {
							console.error('Useraction not supported', userAction);
						}
					}}
				/>
			</FormDialog>
			<ConfirmationDialog
				loading={isDeleteMutating}
				open={userAction?.type === 'delete'}
				subTitle={t('thisWillDeleteResourceWithName', {
					resource: t('accessGroup'),
					name: userAction?.label ?? userAction?.id,
				})}
				onConfirm={async () => await triggerDelete()}
				onClose={() => setUserAction(null)}
			/>
		</Stack>
	);
};

export default SkcUserGroupsOverviewLayout;

import { useEffect } from 'react';

import { Tabs, Tab, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';

import Header from './Header';
import { useStyles } from './style';
import Summary from './Summary';
import { Page } from '../../../../components';
import { isObject } from '../../../../shared/utility';
import * as actions from '../../../../store/actions/index';

interface TermDetailProps {
	fetchedTerm?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	updateTerm?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onFetchTerm?(...args: unknown[]): unknown;
	onUpdateDetailsState?(...args: unknown[]): unknown;
}

const TermDetail = (props: TermDetailProps) => {
	const { onFetchTerm, fetchedTerm, updateTerm, onUpdateDetailsState } = props;
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const classes = useStyles();

	const { id, tab } = useParams();

	const {
		data: fetchedTermData,
		loading: fetchedTermLoading,
		error: fetchedTermError,
	} = fetchedTerm;
	const termReady = isObject(fetchedTermData) && !fetchedTermLoading && !fetchedTermError;

	const { data: updateTermData } = updateTerm;

	const handleTabsChange = (event, value) => {
		navigate(`../${value}`, { relative: 'path', state: { ...location.state } });
	};

	useEffect(() => {
		if (!isObject(fetchedTermData) || id !== fetchedTermData.id.toString()) {
			onFetchTerm(id);
		}
	}, [id]);

	useEffect(() => {
		if (isObject(updateTermData) && updateTermData.id === fetchedTermData.id) {
			onUpdateDetailsState('fetchTerm', updateTermData);
		}
	}, [updateTermData, id]);

	const tabs = [{ value: 'summary', label: t('ui.summary') }];

	if (!tabs.find((t) => t.value === tab)) {
		return <Navigate to={'/policy-management/terms-and-conditions'} />;
	}

	return (
		<Page className={classes.root} title={t('views.termDetails.title')}>
			<Header
				loading={!termReady}
				name={isObject(fetchedTermData) ? fetchedTermData.title : '-'}
				termData={fetchedTermData}
			/>
			<Tabs
				className={classes.tabs}
				indicatorColor='primary'
				onChange={handleTabsChange}
				scrollButtons='auto'
				value={tab}
				variant='scrollable'
			>
				{tabs.map((tab) => (
					<Tab key={tab.value} label={tab.label} value={tab.value} />
				))}
			</Tabs>
			<Divider className={classes.divider} />
			<div className={classes.content}>
				{tab === 'summary' && <Summary loading={!termReady} termData={fetchedTermData} />}
			</div>
		</Page>
	);
};

const mapStateToProps = (state) => {
	return {
		fetchedTerm: state.details.fetchTerm,

		updateTerm: state.details.updateTerm,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchTerm: (id) => dispatch(actions.fetchTerm(id)),
		onUpdateDetailsState: (identifier, data) =>
			dispatch(actions.updateDetailsState(identifier, data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TermDetail);

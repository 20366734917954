import { forwardRef, FunctionComponent, ReactNode, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { formatBytes } from '~utils';

import DragAndDropImageUploader, {
	DragAndDropImageUploaderProps,
	DragAndDropImageUploaderRef,
} from './dragAndDropImageUploader';
import ImageCropper, { ImageCropperProps } from './imageCropper';
import Dialog, { DialogProps } from '../dialogs/dialog';
import { DialogContent } from '@mui/material';

interface DragAndDropImageCropperProps {
	/**
	 * Provide children components.
	 * Forwarded to the `DragAndDropImageUploader` component
	 */
	children?: ReactNode;
	src?: string;
	onChange?: (value: { file: File; uri: string } | null) => void;
	minBlockSize?: number;
	slots?: {
		emptyUploader?: FunctionComponent;
	};
	slotProps?: {
		dialog?: Partial<DialogProps>;
		uploader?: Partial<Omit<DragAndDropImageUploaderProps, 'src'>>;
		imageCropper?: Partial<Omit<ImageCropperProps, 'src'>>;
	};
}

/**
 * A component to combine the image selecting and cropping.
 *
 */
const DragAndDropImageCropper = forwardRef<
	DragAndDropImageUploaderRef,
	DragAndDropImageCropperProps
>(({ src, slots, slotProps, children, minBlockSize = 300, ...props }, ref) => {
	const { t } = useTranslation();
	const [originalImage, setOriginalImage] = useState<{ file: File; uri: string } | null>(null);
	const [crop, setCrop] = useState<{ x: number; y: number; width: number; height: number } | null>(
		null,
	);

	useEffect(() => {
		return () => reset();
	}, []);

	/**
	 * Clean up the resources and cropping.
	 */
	const reset = () => {
		setCrop(null);
		setOriginalImage(null);
	};

	const handleSave = async () => {
		if (!originalImage || !crop) {
			return;
		}

		const offscreenCanvas = new OffscreenCanvas(crop.width, crop.height);
		const ctx = offscreenCanvas.getContext('2d');

		const image = new Image();
		image.onload = async () => {
			ctx?.drawImage(image, crop.x, crop.y, crop.width, crop.height, 0, 0, crop.width, crop.height);

			const blob = await offscreenCanvas.convertToBlob({
				type: originalImage.file.type,
				// This value is randomly chose, but if we upload a jpg. It is transformed by the
				// canvas to png which raises the size.
				quality: 0.7,
			});
			const file = new File([blob], originalImage.file.name);

			if (
				slotProps?.uploader?.maxFileSizeInBytes != null &&
				file.size > slotProps?.uploader?.maxFileSizeInBytes
			) {
				console.warn(`Too large file: ${formatBytes(file.size, 4)}`);
			}

			props.onChange?.({
				file: file,
				uri: URL.createObjectURL(file),
			});
			reset();
		};
		image.src = originalImage.uri;
	};

	return (
		<>
			<DragAndDropImageUploader
				accept='image/png, image/jpeg'
				{...slotProps?.uploader}
				ref={ref}
				src={src}
				onChange={(file) => {
					if (file == null) {
						props.onChange?.(null);
						reset();
					} else {
						setOriginalImage(file);
					}
				}}
				minBlockSize={minBlockSize}
			>
				{children}
			</DragAndDropImageUploader>
			<Dialog
				title={t('cropImage')}
				variant='legacy'
				{...slotProps?.dialog}
				open={originalImage?.uri != null}
				onSave={handleSave}
				onClose={reset}
				disableDefaultActions={false}
				saveLabel={t('ui.confirm')}
			>
				<DialogContent sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
					<ImageCropper
						{...slotProps?.imageCropper}
						src={originalImage?.uri}
						onCropChange={(crop) => setCrop(crop)}
						minBlockSize={minBlockSize}
					/>
				</DialogContent>
			</Dialog>
		</>
	);
});

export default DragAndDropImageCropper;

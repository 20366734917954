import path from 'path';

import { GroupAdd as GroupAddIcon } from '@mui/icons-material';
import { Link as MuiLink, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';
import useSWR from 'swr';

import { InfoCard, InfoCardRowDef } from '~components';
import { pagePathSegments } from '~constants';
import { useAuthorize } from '~features/authentication';
import { BaseReference } from '~interfaces';

import UsersService from '../../services/usersService';

const service = new UsersService();

interface UserOrganisationInfoCardProps {
	userId: string;
}

/**
 * TODO: unfinished
 * @returns
 */
const UserOrganisationInfoCard = ({ userId, ...props }: UserOrganisationInfoCardProps) => {
	const { t } = useTranslation();
	const location = useLocation();
	const { authorizeForOrganisation } = useAuthorize();

	const { data, isLoading, error } = useSWR([service.basePath, userId], ([_, id]) =>
		service.getUserById(id)
	);

	const rows: InfoCardRowDef[] | undefined = data && [
		{
			headerName: t('ui.label.organisation'),
			value: data.organisation,
			renderCell: (value) => (
				<Stack spacing={1} direction='row' sx={{ alignItems: 'center' }}>
					{value.logo != null && <img src={value.logo} style={{ width: '2rem', height: '2rem' }} />}
					<div>{t(value.label)}</div>
				</Stack>
			),
		},
		{
			headerName: t('ui.label.userGroup'),
			value: data.userGroup,
			renderCell: (value?: BaseReference) =>
				value != null ?
					<MuiLink
						component={ReactRouterLink}
						to={`/${path.join(pagePathSegments.UserManagement, pagePathSegments.UserGroups, value.id, pagePathSegments.Summary)}`}
						state={{ from: location.pathname }}
					>
						{value.label}
					</MuiLink>
				:	undefined,
		},
	];

	return (
		<InfoCard
			title={t('organisation')}
			rows={rows ?? []}
			loading={isLoading}
			actions={
				data?.organisation.id != null && authorizeForOrganisation(data.organisation.id) ?
					[
						{
							label: t('ui.button.inline.assigntousergroup'),
							icon: <GroupAddIcon />,
							component: ReactRouterLink,
							to: `/${path.join(pagePathSegments.UserManagement, pagePathSegments.Users, pagePathSegments.Assign, pagePathSegments.UserGroup)}`,
							state: { selectedUsers: [{ ...data, name: data.label, emailAddress: data.email }] },
						},
					]
				:	undefined
			}
			error={error != null}
		/>
	);
};

export default UserOrganisationInfoCard;

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { SsoDomainsLayout } from '~features/organisations';
import PageNotFoundPage from '../other/pageNotFoundPage';

import Page from '../page';

const SsoDomainsPage = () => {
	const { t } = useTranslation();
	const { id } = useParams();

	if (!id) {
		return <PageNotFoundPage />;
	}

	return (
		<Page title={t('ssoDomains')}>
			<SsoDomainsLayout organisationId={id} />
		</Page>
	);
};

export default SsoDomainsPage;

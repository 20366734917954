import { useEffect } from 'react';

import { Card, CardHeader, CardContent, Typography, FormControlLabel, Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';
import { useWizardFormField, useDebouncedWizardSave } from '../../../../../../shared/hooks';

interface PaymentConfigurationProps {
	save?(...args: unknown[]): unknown;
	depositAmountRequired?: boolean;
	personalBillingDeposit?: number;
	allowPersonalBilling?: boolean;
	setAllowPersonalBilling?(...args: unknown[]): unknown;
}

const PaymentConfiguration = (props: PaymentConfigurationProps) => {
	const classes = useStyles();

	const {
		save,
		depositAmountRequired,
		personalBillingDeposit: savedPersonalBillingDeposit,

		allowPersonalBilling,
		setAllowPersonalBilling,
	} = props;
	const { t } = useTranslation();

	const openDepositAmount = depositAmountRequired || false;

	useEffect(() => {
		save({ depositAmountRequired: openDepositAmount });
	}, [openDepositAmount]);

	useEffect(() => {
		save({
			personalBilling: allowPersonalBilling,
		});
	}, [allowPersonalBilling]);

	const personalBillingDeposit = useWizardFormField(savedPersonalBillingDeposit, {
		required: false,
		isNumeric: true,
		minAmount: 0,
		maxAmount: 5000,
		maxLength: 4,
	});
	useDebouncedWizardSave(
		'personalBillingDeposit',
		parseInt(personalBillingDeposit.value),
		personalBillingDeposit.isValid,
		save,
		300,
		personalBillingDeposit.isRequired,
	);

	useEffect(() => {
		personalBillingDeposit.setRequired(openDepositAmount);
	}, [openDepositAmount]);

	// DEPOSIT INFORMATION BLOCK
	// const handleToggleOpenDepositAmount = () => {
	//   setOpenDepositAmount(!openDepositAmount);
	// };

	const handlePersonalBilling = () => {
		setAllowPersonalBilling(!allowPersonalBilling);
	};

	/* * * * * *
	 * RENDER  *
	 * * * * * */
	return (
		<Card className={classes.root}>
			<CardHeader
				subheader={t('views.addPolicy.policyConfiguration.category.paymentPolicies.description')}
				title={t('views.addPolicy.policyConfiguration.category.paymentPolicies.title')}
				titleTypographyProps={{
					variant: 'h3',
				}}
			/>
			<CardContent>
				<div className={classes.formGroup}>
					{/* DEPOSIT INFORMATION BLOCK */}
					{/* <FormControlLabel
            className={classes.switchLabel}
            control={
              <Switch color='primary' checked={openDepositAmount} onChange={handleToggleOpenDepositAmount} />
            }
            label={
              <div>
                <Typography variant='h5'>{t('views.addPolicy.paymentConfiguration.section.depositAmount.title')}</Typography>
                <Typography color='textSecondary'>{t('views.addPolicy.paymentConfiguration.section.depositAmount.description')}</Typography>
              </div>
            }
            labelPlacement='start'
          />
          <Collapse in={openDepositAmount}>
            <div className={classes.inputWithExtraLabel}>
              <Typography variant='h5'>{t('ui.label.depositAmount')}:</Typography>
              <FormField
                className={classes.numberInput}
                name='personalBillingDeposit'
                type='number'
                inputProps={{
                  min: 0,
                  max: 5000,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>€</InputAdornment>
                  )
                }}
                variable={personalBillingDeposit}
              />
            </div>
          </Collapse> */}
					<div className={classes.inputWithExtraLabel}>
						<FormControlLabel
							className={classes.switchLabel}
							control={
								<Switch
									checked={allowPersonalBilling}
									color='primary'
									onChange={handlePersonalBilling}
								/>
							}
							label={
								<div>
									<Typography variant='h5'>{t('ui.label.personalBilling')}</Typography>
									<Typography color='textSecondary'>
										{t('views.userGroupDetail.summary.info.personalBilling')}
									</Typography>
								</div>
							}
							labelPlacement='start'
						/>
					</div>
				</div>
			</CardContent>
		</Card>
	);
};

export default PaymentConfiguration;

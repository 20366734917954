import { SET_GLOBAL_STATE, UPDATE_REDIRECT_OBJECT, SET_WINDOW_DIMENSIONS } from './actionTypes';

/* * * * * * * * * * *
 * SET GLOBAL STATE  *
 * * * * * * * * * * */
export const updateRedirect = (pathname = null, search = null) => {
	return {
		type: UPDATE_REDIRECT_OBJECT,
		pathname: pathname,
		search: search,
	};
};

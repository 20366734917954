import { useEffect } from 'react';

import { Tabs, Tab, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import Header from './Header';
import Locations from './Locations';
import { useStyles } from './style';
import { Page } from '../../components';
import * as actions from '../../store/actions';

interface LocationsManagementProps {
	onUpdatePagedState?(...args: unknown[]): unknown;
}

const LocationsManagement = (props: LocationsManagementProps) => {
	const { onUpdatePagedState } = props;
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { tab } = useParams();

	const classes = useStyles();

	const handleTabsChange = (event, value) => {
		navigate(`../${value}`, { relative: 'path' });
	};

	useEffect(() => {
		return () => {
			onUpdatePagedState('hubs', null, false);
		};
	}, []);

	const tabs = [{ value: 'locations', label: t('ui.category.locations') }];

	const headerButtons = [{ key: 'locations' }];

	if (!tab) {
		return <Navigate to={'/location-management/locations'} />;
	}

	if (!tabs.find((t) => t.value === tab)) {
		return <Navigate to='/errors/error-404' />;
	}

	return (
		<Page className={classes.root} title={t('views.locationManagement.page.title')}>
			{headerButtons.map((item) => item.key === tab && <Header key={item.key} {...item} />)}
			<Tabs
				className={classes.tabs}
				indicatorColor='primary'
				onChange={handleTabsChange}
				scrollButtons='auto'
				value={tab}
				variant='scrollable'
			>
				{tabs.map((tab) => (
					<Tab key={tab.value} label={tab.label} value={tab.value} />
				))}
			</Tabs>
			<Divider className={classes.divider} />
			<div className={classes.content}>{tab === 'locations' && <Locations />}</div>
		</Page>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		onUpdatePagedState: (identifier, data) => dispatch(actions.updatePagedState(identifier, data)),
	};
};

export default connect(null, mapDispatchToProps)(LocationsManagement);

import { useState, useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import PhotoIcon from '@mui/icons-material/InsertPhoto';
import { IconButton, Typography } from '@mui/material';
import FileReaderInput from 'react-file-reader-input';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useStyles } from './style';
import { AlertDialog } from '../../../../components';
import { useError } from '../../../../shared/hooks';
import { isEmptyString, isArray } from '../../../../shared/utility';
import * as actions from '../../../../store/actions';

interface DirectIconUploadProps {
	category: object;
	addedCategoryIcon?: {
		data?: unknown[];
		loading?: boolean;
		error?: object | string;
	};
	//fuctions
	onAddCategoryIcon(...args: unknown[]): unknown;
	onRemoveCategoryIcon(...args: unknown[]): unknown;
	categoriesData?: unknown[];
	removedCategoryIcon?: any;
}

const DirectIconUpload = (props: DirectIconUploadProps) => {
	const {
		category,

		addedCategoryIcon,
		onAddCategoryIcon,
		onRemoveCategoryIcon,
		categoriesData,
		removedCategoryIcon,
	} = props;
	const { t } = useTranslation();
	const classes = useStyles();

	const [previewIcon, setPreviewIcon] = useState('');

	const {
		data: addedIconData,
		loading: addedIconLoading,
		error: addedIconError,
	} = addedCategoryIcon;
	const addIconDone = isArray(addedIconData) && !addedIconLoading && !addedIconError;

	const [addingIcon, setAddingIcon] = useState(false);
	const [addedIcon, setAddedIcon] = useState(false);

	const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);

	const addedCategoryIconMessage = useError({
		value: addedCategoryIcon,
		message: `${t('ui.successfully')} ${t('ui.updated')} ${t('ui.label.iconLower')}`,
	});

	const removeCategoryIconMessage = useError({
		value: removedCategoryIcon,
		message: `${t('ui.successfully')} ${t('ui.removed')} ${t('ui.label.iconLower')}`,
	});

	useEffect(() => {
		if (category.icon) {
			setPreviewIcon(category.icon);
		}
	}, [category]);

	useEffect(() => {
		if (addingIcon && !addedIcon && addIconDone) {
			setAddingIcon(false);
			setAddedIcon(true);
			setPreviewIcon(addedIconData[0]);
		}
	}, [addingIcon, addedIcon, addIconDone]);

	const handleCloseDialog = () => {
		setDeleteAlertOpen(false);
	};

	const handleConfirmDialog = () => {
		const parts = previewIcon.split('_');
		const imageId = parts[parts.length - 1].split('.')[0];
		onRemoveCategoryIcon(category.id, imageId, categoriesData);
		setPreviewIcon('');
		setDeleteAlertOpen(false);
		removeCategoryIconMessage.setStartAction(true);
	};

	return isEmptyString(previewIcon) ?
			<FileReaderInput
				accept={['image/png', 'image/svg+xml']}
				multiple={false}
				onChange={(e, results) => {
					const [progressEvent, file] = results[0];
					setPreviewIcon(progressEvent.target.result);
					onAddCategoryIcon(category.id, [file], categoriesData);
					setAddingIcon(true);
					addedCategoryIconMessage.setStartAction(true);
				}}
			>
				<div className={classes.uploadButton}>
					<PhotoIcon />
					<Typography className={classes.uploadButtonText}>{t('ui.upload')}</Typography>
				</div>
			</FileReaderInput>
		:	<>
				<div className={classes.iconPreview}>
					<div className={classes.iconContainer}>
						<img alt={'categoryIcon'} className={classes.icon} src={previewIcon} />
					</div>
					<IconButton
						className={classes.removeIconButton}
						onClick={() => {
							setDeleteAlertOpen(true);
						}}
						size='small'
					>
						<CloseIcon />
					</IconButton>
				</div>
				<AlertDialog
					dialogDescription={t('views.settings.deleteIcon.alert.title') + category.name}
					dialogTitle={t('views.settings.deleteIcon.alert.title')}
					handleClose={handleCloseDialog}
					handleConfirm={handleConfirmDialog}
					open={deleteAlertOpen}
				/>
			</>;
};

const mapStateToProps = (state) => {
	return {
		addedCategoryIcon: state.details.addedCategoryIcon,
		removedCategoryIcon: state.details.removedCategoryIcon,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onAddCategoryIcon: (categoryId, imageArray, categoriesData) =>
			dispatch(actions.addCategoryIcon(categoryId, imageArray, categoriesData)),
		onRemoveCategoryIcon: (categoryId, imageId, categoriesData) =>
			dispatch(actions.removeCategoryIcon(categoryId, imageId, categoriesData)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DirectIconUpload);

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface HeaderProps {
	className?: string;
	editing?: boolean;
}

const Header = (props: HeaderProps) => {
	const { className, editing } = props;
	const { t } = useTranslation();

	return (
		<div className={className}>
			<Typography component='h1' variant='h3'>
				{editing ? t('ui.editing') : t('views.addPolicy.page.title')}
			</Typography>
		</div>
	);
};

export default Header;

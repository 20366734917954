import { useEffect, useState } from 'react';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Avatar, Box, Card, CardContent, Divider, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';

import { getInitials } from '~utils/stringUtils';

import Activity from './Activity';
import SidePanelCard from './SidePanelCard/SidePanelCard';
import { useStyles } from './style';
import {
	Page,
	HeaderWithStatus,
	StyledButton,
	OpenEmail,
	LightBox,
	Paginate,
	AdjustedSkeleton,
} from '../../../components';
import { localizeDateTime } from '../../../shared/datetime';
import { isObject, isFullString, isEmptyArray } from '../../../shared/utility';
import * as actions from '../../../store/actions';

interface LicenseDetailsProps {
	onFetchUser?(...args: unknown[]): unknown;
	onResetState?(...args: unknown[]): unknown;
	onUserLicensesActivities?(...args: unknown[]): unknown;
	user?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onFetchPendingLicenseDetails?(...args: unknown[]): unknown;
	pendingLicenseDetails?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	userLicensesActivities?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const LicenseDetails = (props: LicenseDetailsProps) => {
	const {
		user,
		pendingLicenseDetails,
		onFetchPendingLicenseDetails,
		onFetchUser,
		onResetState,
		onUserLicensesActivities,
		userLicensesActivities,
	} = props;
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();

	const { id } = useParams();

	const classes = useStyles();

	const [pageNumber, setPageNumber] = useState(1);
	const pageSize = 10;
	const page = { size: pageSize, number: pageNumber };

	const [fetchActivities, setFetchActivities] = useState(false);

	const { data: pendingLicenseDetailsData, loading: pendingLicenseDetailsLoading } =
		pendingLicenseDetails;
	const { data: userData, loading: userLoading, error: userError } = user;
	const userReady = isObject(userData) && !userLoading && !userError;

	const { data: userLicensesActivitiesData, loading: userLicensesActivitiesLoading } =
		userLicensesActivities;

	const filters = {
		sortBy: 'created',
		orderDescending: true,
	};

	const handleBackButtonChange = () => {
		if (location?.state?.location === 'summary') {
			navigate(location.state.from, { state: { from: '/user-management/users' } });
		} else {
			navigate('/licenses/pending');
		}
	};

	const onFetchActivities = () => setFetchActivities(true);

	const handlePageChange = (page) => {
		setPageNumber(page.selected + 1);
		onFetchActivities();
	};

	useEffect(() => {
		onFetchPendingLicenseDetails(id);
		onFetchUser(id);
		return () => {
			onResetState('fetchPendingLicenseDetails');
			onResetState('user');
		};
	}, [isFullString(id)]);

	useEffect(() => {
		if ((!userLicensesActivitiesLoading && isFullString(id)) || fetchActivities) {
			onUserLicensesActivities(id, page, filters);
		}
		if (fetchActivities) {
			setFetchActivities(false);
		}
	}, [id, fetchActivities]);

	const images =
		pendingLicenseDetailsData?.images &&
		['front', 'back', 'selfie'].map((key) => ({
			src: pendingLicenseDetailsData.images[key][0],
			alt: key,
		}));

	const statusColors = {
		none: 'error',
		expired: 'error',
		blocked: 'error',
		rejected: 'error',
		onHold: 'error',
		removedImagesManually: 'warning',
		removedImagesAutomatic: 'warning',
		submitted: 'default',
		pending: 'default',
		created: 'default',
		approved: 'success',
		attemptsReset: 'disabled',
	};

	const activities = userLicensesActivitiesData?.results?.map((activity) => (
		<Activity
			key={activity.id}
			comment={activity?.rejectionMessage}
			createdDate={activity.createdDate}
			userName={activity.user?.name}
			createdBy={activity.adminUser?.name}
			type={statusColors[activity.status]}
			status={activity.status}
		/>
	));

	const loadingActivity = Array.from([1, 2, 3, 4]).map((x) => (
		<AdjustedSkeleton key={x} height={200} />
	));

	return isObject(pendingLicenseDetailsData) && userReady && !pendingLicenseDetailsLoading ?
			<Page className={classes.root} title={t('views.pendingLicenseDetails.pageTitle')}>
				<StyledButton
					className={classes.backButton}
					onClick={handleBackButtonChange}
					size='medium'
					startIcon={<ArrowBackIosIcon fontSize='small' />}
					variant='inline-default'
				>
					{t('ui.button.inline.previous')}
				</StyledButton>
				<Divider className={classes.divider} />
				<Box display='flex'>
					<Box className={classes.card}>
						<Card>
							<CardContent className={classes.cardContent}>
								<HeaderWithStatus
									header={t('views.pendingLicenseDetails.pageTitle')}
									headerSize='h4'
									subheader={`${t('ui.label.dateSubmitted')}: ${localizeDateTime(pendingLicenseDetailsData.submitDate, undefined, { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })}`}
									subheaderSize='body2'
								/>
								<Card className={classes.innerCard}>
									<CardContent>
										<Box display='flex' flexDirection='column'>
											<Typography className={classes.header} component='h1' variant='h4'>
												{t('ui.userInfo')}
											</Typography>
											<Box alignItems='center' display='flex' pb={2} pt={2}>
												<Avatar
													alt='User'
													className={classes.avatar}
													src={pendingLicenseDetailsData.userReference?.imagesReference[0]}
												>
													{getInitials(pendingLicenseDetailsData.userReference.name)}
												</Avatar>
												<div>
													<Link
														className={classes.link}
														color='inherit'
														component={RouterLink}
														to={`/user-management/users/${pendingLicenseDetailsData.userReference.id}/summary`}
														state={{ from: location.pathname }}
														variant='body1'
													>
														{pendingLicenseDetailsData.userReference.name}
													</Link>
													<Typography variant='body2'>
														<OpenEmail email={userData.emailAddress} />
													</Typography>
												</div>
											</Box>
										</Box>
										<Divider className={classes.organisationDivider} />
										<Box alignItems='center' display='flex' flexDirection='row' pb={1.5} pt={1.5}>
											<Typography variant='h6'>{t('ui.organisation')}:</Typography>
											<Box alignItems='center' display='flex' pl={1.5}>
												<Avatar className={classes.logo} src={userData.organisationReference?.logo}>
													{getInitials(userData.organisationReference.name)}
												</Avatar>
												<Typography variant='body1'>
													{userData.organisationReference.name}
												</Typography>
											</Box>
										</Box>
									</CardContent>
								</Card>
								<Box display='flex' flexDirection='column' flexWrap='wrap' pb={3} pt={2}>
									{isObject(pendingLicenseDetailsData.images) ?
										<LightBox classes={classes} images={images} />
									:	<Typography variant='h4'>{t('views.pendingLicenseDetails.noImages')}</Typography>
									}
								</Box>
							</CardContent>
						</Card>
						<Card className={classes.activityCard}>
							<CardContent className={classes.cardContent}>
								<Typography variant='h4'>
									{t('views.pendingLicenseDetails.verificationRequestActivity')}
								</Typography>
								<Box pt={2}>
									{userLicensesActivitiesLoading ? loadingActivity : activities}
									{isEmptyArray(userLicensesActivitiesData?.results) && (
										<Typography variant='body2'>
											{' '}
											{t('views.ticketsDetails.noActivities')}{' '}
										</Typography>
									)}
								</Box>
								{userLicensesActivitiesData?.total > 10 && (
									<Paginate
										forcePage={pageNumber - 1}
										onPageChange={handlePageChange}
										pageCount={userLicensesActivitiesData?.total / pageSize}
									/>
								)}
							</CardContent>
						</Card>
					</Box>
					{isObject(pendingLicenseDetailsData) && userReady ?
						<SidePanelCard
							onFetchActivities={onFetchActivities}
							licenseDetails={pendingLicenseDetailsData}
							languagePreferences={userData?.preferences?.preferredCultureInfo?.slice(
								0,
								userData.preferences.preferredCultureInfo.indexOf('-'),
							)}
						/>
					:	null}
				</Box>
			</Page>
		:	null;
};

const mapStateToProps = (state) => {
	return {
		user: state.details.user,
		pendingLicenseDetails: state.details.fetchPendingLicenseDetails,
		userLicensesActivities: state.paged.userLicensesActivities,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchPendingLicenseDetails: (requestId) =>
			dispatch(actions.fetchPendingLicenseDetails(requestId)),
		onFetchUser: (requestId) => dispatch(actions.fetchUser(requestId)),
		onResetState: (identifier) => dispatch(actions.resetState(identifier)),
		onUserLicensesActivities: (userId, page, filters) =>
			dispatch(actions.userLicensesActivities(userId, page, filters)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LicenseDetails);

import { useEffect } from 'react';

import { Tabs, Tab, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';

import Header from './Header';
import Summary from './Summary';
import { Page } from '../../../components';
import { useAccess } from '../../../shared/accessHooks';
import { isObject, isString } from '../../../shared/utility';
import * as actions from '../../../store/actions';
import TripsList from '../../Devices/Details/TripsList';
import { useStyles } from '../style';

interface BookingDetailsProps {
	onResetState?(...args: unknown[]): unknown;
	fetchBooking?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onFetchBooking?(...args: unknown[]): unknown;
	onFetchUserImage?(...args: unknown[]): unknown;
	fetchUserImage?: {
		data?: unknown[];
		loading?: boolean;
		error?: object | string;
	};
}

const BookingDetails = (props: BookingDetailsProps) => {
	const { fetchBooking, onFetchBooking, onResetState } = props;
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();

	const classes = useStyles();

	const { id, tab } = useParams();

	const { data: bookingData, loading: bookingLoading, error: bookingError } = fetchBooking;
	const bookingReady = isObject(bookingData) && !bookingLoading && !bookingError;

	const access = useAccess();

	const handleTabsChange = (event, value) => {
		navigate(`../${value}`, { relative: 'path', state: { ...location.state } });
	};

	useEffect(() => {
		if (!bookingLoading && isString(id)) {
			onFetchBooking(id);
		}
		return () => {
			onResetState('fetchBooking');
		};
	}, [id]);

	useEffect(() => {
		if (bookingData) {
			access.setItemId(bookingData.itemReference.id);
			access.setInstanceId(bookingData.itemInstance.id);
		}
	}, [bookingData]);

	const tabs = [
		{ value: 'summary', label: t('ui.summary') },
		...(bookingData?.showTrips ? [{ value: 'trips', label: t('ui.label.trips') }] : []),
	];

	if (!tab) {
		return <Navigate to={'/bookings'} />;
	}

	if (isObject(bookingData) && !tabs.find((t) => t.value === tab)) {
		return <Navigate to='/errors/error-404' />;
	}

	const tripsListProps = { id: id, isBookingTrip: true };

	return (
		<Page className={classes.root} title={t('views.bookings.page.title')}>
			<Header bookingData={bookingData} loading={!bookingReady} />
			<Tabs
				className={classes.content}
				indicatorColor='primary'
				onChange={handleTabsChange}
				scrollButtons='auto'
				value={tab}
				variant='scrollable'
			>
				{tabs.map((tab) => (
					<Tab key={tab.value} label={tab.label} value={tab.value} />
				))}
			</Tabs>
			<Divider className={classes.detailsDivider} />
			<div className={classes.content}>
				{tab === 'summary' && (
					<Summary
						access={access?.access?.includes('internal')}
						bookingData={bookingData}
						loading={bookingLoading}
					/>
				)}
				{tab === 'trips' && bookingData?.showTrips ?
					<TripsList {...tripsListProps} />
				:	null}
			</div>
		</Page>
	);
};

const mapStateToProps = (state) => {
	return {
		fetchBooking: state.details.fetchBooking,

		fetchUserImage: state.list.fetchUserImage,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchBooking: (id) => dispatch(actions.fetchBooking(id)),
		onFetchUserImage: (userId) => dispatch(actions.fetchUserImage(userId)),
		onResetState: (identifier) => dispatch(actions.resetState(identifier)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingDetails);

import {
	FormControl,
	InputLabel,
	MenuItem,
	Select as MuiSelect,
	SelectProps as MuiSelectProps,
} from '@mui/material';

import i18n from '~lib/i18n';

export type SelectProps = MuiSelectProps & {
	emptyLabel?: string;
}

/**
 * A generic wrapper around a select component
 * @param props
 * @returns
 */
const Select = ({
	size,
	value,
	children,
	emptyLabel = i18n.t('all'),
	displayEmpty,
	onChange,
	...props
}: SelectProps) => {
	const label = props.required && props.label ? `${props.label} *` : props.label;

	return (
		<FormControl fullWidth size={size} sx={props.sx}>
			<InputLabel id={props.labelId}>{label}</InputLabel>
			<MuiSelect
				{...props}
				label={label}
				value={value ?? ''}
				onChange={(e, child) => {
					// Overwrite the empty string value and force an empty value
					// as null. Reliable?
					if (e.target.value === '') {
						e.target.value = null;
					}

					onChange?.(e, child);
				}}
				displayEmpty={displayEmpty}
			>
				{displayEmpty && (
					<MenuItem value='' sx={{ fontWeight: 500 }}>
						{emptyLabel}
					</MenuItem>
				)}
				{children}
			</MuiSelect>
		</FormControl>
	);
};

export default Select;

import { useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import useSWRMutation from 'swr/mutation';

import { ConfirmationDialog, Dialog, FormDialog, FormDrawer } from '~components';
import { LinkNfcTagToUserForm } from '~features/users';
import { useSnackbar } from '~hooks';

import { NfcTag, NfcTagsDataGrid } from '..';
import CreateNfcTagForm from '../forms/createNfcTag/createNfcTagForm';
import LinkUserToNfcTagForm from '../forms/linkUserToNfcTag/linkUserToNfcTagForm';
import { NfcTagNew } from '../interfaces/nfcTag';
import NfcTagsService from '../services/nfcTagsService';

const service = new NfcTagsService();

type UserAction =
	| {
			type: 'create';
			id?: never;
	  }
	| {
			type: 'edit' | 'link' | 'unlink' | 'delete';
			id: string;
	  };

const NfcTagsOverviewLayout = () => {
	const { t } = useTranslation();
	const { enqueueSuccessSnackbar, enqueueAxiosErrorSnackbar } = useSnackbar();
	const dataGridRef = useRef<{ mutate: () => void }>(null);

	const [userAction, setUserAction] = useState<UserAction | null>(null);

	const { trigger: triggerCreate, isMutating: isCreateMutating } = useSWRMutation(
		service.basePath,
		(_, { arg }: { arg: NfcTagNew }) => service.createNfcTag(arg),
		{
			onSuccess: () => {
				enqueueSuccessSnackbar(t('created'));
				setUserAction(null);
				dataGridRef.current?.mutate();
			},
			onError: (error) => enqueueAxiosErrorSnackbar(error),
		},
	);

	const { trigger: triggerEdit, isMutating: isEditMutating } = useSWRMutation(
		userAction?.id ? [service.basePath, userAction.id] : null,
		([_, id], { arg }) => service.todo(id, arg),
		{
			onSuccess: () => {
				enqueueSuccessSnackbar(t('edited'));
				setUserAction(null);
				dataGridRef.current?.mutate();
			},
			onError: (error) => enqueueAxiosErrorSnackbar(error),
		},
	);

	const { trigger: triggerDelete, isMutating: isDeleteMutating } = useSWRMutation(
		userAction?.id ? [service.basePath, userAction.id] : null,
		([_, id]) => service.deleteNfcTag(id),
		{
			onSuccess: () => {
				enqueueSuccessSnackbar(t('deleted'));
				setUserAction(null);
				dataGridRef.current?.mutate();
			},
			onError: (error) => enqueueAxiosErrorSnackbar(error),
		},
	);

	const { trigger: triggerLink, isMutating: isLinkMutating } = useSWRMutation(
		userAction?.id ? [service.basePath, userAction.id] : null,
		([_, id]) => service.todo(id),
		{
			onSuccess: () => {
				enqueueSuccessSnackbar(t('link'));
				setUserAction(null);
				dataGridRef.current?.mutate();
			},
			onError: (error) => enqueueAxiosErrorSnackbar(error),
		},
	);

	const { trigger: triggerUnlink, isMutating: isUnlinkMutating } = useSWRMutation(
		userAction?.id ? [service.basePath, userAction.id] : null,
		([_, id]) => service.unlinkNfcTag(id),
		{
			onSuccess: () => {
				enqueueSuccessSnackbar(t('unlink'));
				setUserAction(null);
				dataGridRef.current?.mutate();
			},
			onError: (error) => enqueueAxiosErrorSnackbar(error),
		},
	);

	return (
		<>
			<NfcTagsDataGrid
				onCreate={() => setUserAction({ type: 'create' })}
				onEdit={(id) => setUserAction({ type: 'edit', id: id })}
				onDelete={(id) => setUserAction({ type: 'delete', id: id })}
				onLink={(id) => setUserAction({ type: 'link', id: id })}
				onUnlink={(id) => setUserAction({ type: 'unlink', id: id })}
			/>
			<FormDialog
				title={t(userAction?.type === 'create' ? 'addResource' : 'editResource', {
					resource: t('nfcTag'),
				})}
				open={userAction?.type === 'create' || userAction?.type === 'edit'}
				loading={isCreateMutating || isEditMutating}
				onClose={() => setUserAction(null)}
			>
				<CreateNfcTagForm onSubmit={async (data) => await triggerCreate(data)} />
			</FormDialog>
			<FormDrawer
				open={userAction?.type === 'link'}
				onSubmit={async (data) => await triggerLink(data)}
				loading={isLinkMutating}
				onClose={() => setUserAction(null)}
			>
				<LinkUserToNfcTagForm nfcId={userAction?.id} />
			</FormDrawer>
			<ConfirmationDialog
				open={userAction?.type === 'delete' || userAction?.type === 'unlink'}
				loading={isDeleteMutating || isUnlinkMutating}
				onConfirm={async () => {
					if (userAction?.type === 'delete') {
						await triggerDelete();
					} else if (userAction?.type === 'unlink') {
						await triggerUnlink();
					}
				}}
				onClose={() => setUserAction(null)}
			/>
		</>
	);
};

export default NfcTagsOverviewLayout;

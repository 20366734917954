import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';

import { DeleteOutlined as DeleteOutlinedIcon } from '@mui/icons-material';
import { Box, Button, Portal, Stack } from '@mui/material';
import { GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { DataGrid, DataGridToolbar, GridActionsCellItem } from '~components';
import { useAuthorize } from '~features/authentication';
import { MutationRefProps } from '~interfaces/refProps';

import SsoDomain from '../../interfaces/ssoDomain';
import OrganisationDomainsService from '../../services/organisationDomainsService';

const toolbarIdString = 'topology-access-rules-datagrid';

const service = new OrganisationDomainsService();

interface SsoDomainsDataGridProps {
	organisationId: string;
	onCreate?: () => void;
	onDelete?: (domainName: string) => void;
}

const SsoDomainsDataGrid = forwardRef<MutationRefProps, SsoDomainsDataGridProps>(
	({ onDelete, onCreate, ...props }, ref) => {
		const { t } = useTranslation();
		const { isSuperAdmin } = useAuthorize();

		const columns = useMemo<GridColDef<SsoDomain>[]>(
			() => [
				{
					field: 'domainName',
					headerName: t('ui.label.emailDomain'),
					flex: 1,
					valueFormatter: (value: string) => `@${value}`,
				},
				{
					field: 'actions',
					type: 'actions',
					display: 'text',
					width: 50,
					resizable: false,
					align: 'right',
					hideable: false,
					getActions: (params) => [
						<GridActionsCellItem
							key={`${params.id}-delete`}
							label={t('ui.delete')}
							icon={<DeleteOutlinedIcon color='error' />}
							color='error'
							onClick={() => onDelete?.(params.row.domainName)}
							showInMenu
						/>,
					],
				},
			],
			[],
		);

		const [fetchParameters, setFetchParameters] = useState({
			organisationId: props.organisationId,
		});
		const { data, isLoading, isValidating, error, mutate } = useSWR(
			[service.basePath, fetchParameters, service.ssoDomainSubPath],
			([_, args]) => service.getSsoDomains(args),
			{
				keepPreviousData: true,
			},
		);
		const [totalCount, setTotalCount] = useState<number>(data?.total || 0);

		useEffect(() => {
			if (data?.total != null) {
				setTotalCount(data.total);
			}
		}, [data]);
		
		useImperativeHandle(
			ref,
			() => ({
				mutate: mutate,
			}),
			[mutate],
		);

		const handlePaginationChange = (value: GridPaginationModel) => {
			setFetchParameters((prev) => ({
				...prev,
				page: value.page + 1,
				pageSize: value.pageSize,
			}));
		};

		return (
			<Stack
				spacing={2}
				sx={{
					display: 'flex',
					height: 1,
				}}
			>
				{isSuperAdmin() && (
					<Box
						id={toolbarIdString}
						sx={{
							minHeight: 48,
						}}
					/>
				)}
				<DataGrid
					title={t('ssoDomains')}
					disableUrlSync
					getRowId={(row) => row.domainName}
					loading={isLoading || isValidating}
					error={error}
					columns={columns}
					rowCount={totalCount}
					rows={data?.results ?? []}
					onPaginationModelChange={handlePaginationChange}
					initialState={{
						columns: {
							columnVisibilityModel: {
								// Only superadmins can perform actions
								actions: isSuperAdmin(),
							},
						},
					}}
					slots={{
						toolbar: isSuperAdmin() ? Toolbar : undefined,
					}}
					slotProps={{
						toolbar: {
							onCreate: onCreate,
						},
					}}
				/>
			</Stack>
		);
	},
);

const Toolbar = (props) => {
	const { t } = useTranslation();

	return (
		<Portal container={() => document.getElementById(toolbarIdString)}>
			<DataGridToolbar disableSearch disabledOrganisationFilter disableClearableOrganisation>
				<Button variant='contained' onClick={() => props.onCreate?.()}>
					{t('addResource', {
						resource: t('ssoDomains'),
						formatParams: { ignore: true },
					})}
				</Button>
			</DataGridToolbar>
		</Portal>
	);
};

export default SsoDomainsDataGrid;

import React, {
	cloneElement,
	forwardRef,
	PropsWithChildren,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from 'react';

import {
	AddPhotoAlternate as AddPhotoAlternateIcon,
	Close as CloseIcon,
	PhotoCamera as PhotoCameraIcon,
} from '@mui/icons-material';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import placeholderImageUrl from '~assets/images/placeholder.svg?url';
import { useElementSize, useFilePicker } from '~hooks';
import { formatBytes } from '~utils';

/**
 * TODO: different naming
 */
interface FileInput {
	file: File | null;
	uri: string | null;
}

export type DragAndDropImageUploaderProps = {
	title?: string;
	readonly?: boolean;
	src?: string | null;
	maxFileSizeInBytes?: number;
	accept?: string;
	/**
	 *
	 * @param file The image as file
	 * @param uri
	 * @returns
	 */
	onChange?: (image: FileInput | null) => void;
	onError?: (errors: Error[]) => void;
	clearable?: boolean;
	editable?: boolean;
	placeholderSrc?: string | null;

	minBlockSize?: number;
} & PropsWithChildren;

export interface DragAndDropImageUploaderRef {
	openFilePicker: () => void;
}

const DragAndDropImageUploader = forwardRef<
	DragAndDropImageUploaderRef,
	DragAndDropImageUploaderProps
>(
	(
		{
			maxFileSizeInBytes: maxImageSizeInBytes = 1024000,
			clearable = true,
			editable = true,
			readonly = false,
			title,
			accept = 'image/png, image/jpeg',
			placeholderSrc = placeholderImageUrl,
			minBlockSize,
			...props
		},
		ref
	) => {
		const { t } = useTranslation();

		const divRef = useRef<HTMLDivElement>();

		const filePicker = useFilePicker({
			multiple: false,
			maxSize: maxImageSizeInBytes,
			accept: accept,
		});
		const size = useElementSize(divRef);

		const [hovered, setHovered] = useState(false);

		useEffect(() => {
			if (filePicker.errors.length > 0) {
				props.onError?.(filePicker.errors.map((el) => el.error));
			}
		}, [filePicker.errors]);

		useEffect(() => {
			props.onChange?.(filePicker.files[0]);
		}, [filePicker.files]);

		useImperativeHandle(ref, () => ({
			openFilePicker: filePicker.openFilePicker,
		}));

		const OverlayContainer = ({ children }: PropsWithChildren) => (
			<Box
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					// We block all the handlers in the child components
					// We are switching between a click on the whole components
					// or the subcomponents
					pointerEvents: 'none',
				}}
			>
				{children}
			</Box>
		);

		const AddOverlay = () => (
			<OverlayContainer>
				<Stack
					color='white'
					onClick={filePicker.openFilePicker}
					spacing={1}
					sx={{
						alignItems: 'center',
					}}
				>
					{size.width < 120 && <AddPhotoAlternateIcon />}
					{size.width >= 120 && (
						<Typography variant='h5' color='white'>
							{t('ui.uploadImage')}
						</Typography>
					)}
					{size.width >= 120 && (
						<Typography variant='body2' color='white'>
							{`${t('ui.maxFileSize')}: ${formatBytes(maxImageSizeInBytes, 1)}`}
						</Typography>
					)}
				</Stack>
			</OverlayContainer>
		);

		const EditOverlay = () => (
			<OverlayContainer>
				<Stack
					color='white'
					onClick={filePicker.openFilePicker}
					sx={{
						alignItems: 'center',
					}}
				>
					<PhotoCameraIcon />
					{size.width >= 120 && (
						<Typography color='white'>{t('changeResource', { resource: t('image') })}</Typography>
					)}
					{size.width >= 120 && (
						<Typography variant='body2' color='white'>
							{`${t('ui.maxFileSize')}: ${formatBytes(maxImageSizeInBytes, 1)}`}
						</Typography>
					)}
				</Stack>
			</OverlayContainer>
		);

		const ClearOverlay = () => (
			<Stack
				color='white'
				sx={{
					position: 'absolute',
					top: 0,
					right: 0,
					justifyContent: 'flex-end',
				}}
			>
				<IconButton onClick={() => props.onChange?.(null)}>
					<CloseIcon sx={{ color: 'white' }} />
				</IconButton>
			</Stack>
		);

		const handleClick = () => {
			filePicker.openFilePicker();
		};

		const src = props.src ?? placeholderSrc;
		const children = props.children ?? (
			<img
				style={{
					maxHeight: '280px',
					width: 'auto',
					// minBlockSize: minBlockSize,
					// writingMode:
					// 	minBlockSize == null ? undefined
					// 	: dimensions && dimensions.width / dimensions.height > 1 ? 'vertical-lr'
					// 	: 'horizontal-tb',
				}}
			/>
		);

		return (
			<div
				ref={divRef}
				// {...(!readonly && {
				// 	onDrop: filePicker.dropFiles,
				// 	onDragOver: (event) => event.preventDefault(),
				// 	onMouseEnter: () => setHovered(true),
				// 	onMouseLeave: () => setHovered(false),
				// })}
				style={{
					position: 'relative',
					width: '100%',
					height: '100%',
					display: 'flex',
					justifyContent: 'center',
					...props.style,
				}}
			>
				{cloneElement(children, {
					// className: 'image-container',
					src: src,
					draggable: false,
					style: {
						...React.Children.only(children).props.style,
						...(!readonly && {
							filter: `brightness(${hovered ? '50%' : '100%'})`,
							transition: '0.3s',
							backgroundColor: src != null ? 'white' : undefined,
							cursor: 'pointer',
							minBlockSize: minBlockSize,
						}),
					},
					...(!readonly && {
						onDrop: filePicker.dropFiles,
						onDragOver: (e) => e.preventDefault(),
						onMouseEnter: () => setHovered(true),
						onMouseLeave: () => setHovered(false),
						onClick: handleClick,
					}),
				})}

				{hovered && !readonly && (
					<>
						{props.src == null && <AddOverlay />}
						{props.src != null && editable && <EditOverlay />}
						{props.src != null && clearable && <ClearOverlay />}
					</>
				)}
			</div>
		);
	}
);

export default DragAndDropImageUploader;

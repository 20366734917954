import { useEffect, useState } from 'react';

import { Box, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import ActivePeriod from './ActivePeriod';
import { useStyles } from './style';
import { Table, Label } from '../../../components';
import { isObject, decimalAmount, getFinanceTableHeaders } from '../../../shared/utility';
import * as actions from '../../../store/actions';

interface PersonalProps {
	onFetchBillings?(...args: unknown[]): unknown;
	isSuperAdmin?: boolean;
	billings?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const Personal = (props: PersonalProps) => {
	const { onFetchBillings, billings } = props;
	const { t } = useTranslation();
	const classes = useStyles();

	const [shouldDoInitialFetch, setShouldDoInitialFetch] = useState(true);
	const [showingInitialResults, setShowingInitialResults] = useState(true);

	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize, setPageSize] = useState(20);
	const page = { number: pageNumber, size: pageSize };

	const filters = {};

	const { data: billingsData, loading: billingsLoading, error: billingsError } = billings;
	const billingsReady = isObject(billingsData) && !billingsLoading && !billingsError;

	useEffect(() => {
		if (shouldDoInitialFetch) {
			if (!billingsLoading) {
				onFetchBillings(page, filters);
			}
			if (showingInitialResults) {
				setShowingInitialResults(false);
			}
		}
	}, [pageNumber, pageSize]);

	const handlePageChange = (newPage) => {
		setPageNumber(newPage);
		setShouldDoInitialFetch(true);
	};

	const handlePageSizeChange = (newSize) => {
		setPageSize(newSize);
		setPageNumber(1);
		setShouldDoInitialFetch(true);
	};

	const statusColor = {
		open: 'default',
		paid: 'success',
	};

	const statusText = {
		open: t('ui.open'),
		paid: t('ui.label.paid'),
	};

	const tableHeader = getFinanceTableHeaders();

	const tableBody =
		billingsReady && isObject(billingsData) ?
			billingsData.results.map((history) => [
				{
					content: (
						<Box className={classes.periodContent}>
							<Link
								className={classes.link}
								color='inherit'
								component={RouterLink}
								to={`/financeManagement/personal/${history.start}/${history.title}/billing`}
								variant='h6'
							>
								{`${history.title}`}
							</Link>
						</Box>
					),
				},
				{ content: `${decimalAmount(history.amount.subtotal, history.amount)}` },
				{ content: `${decimalAmount(history.amount.discount, history.amount)}` },
				{
					content: `${decimalAmount(history.amount.subtotal - history.amount.discount, history.amount)}`,
				},
				{ content: `${decimalAmount(history.amount.priceVat, history.amount)}` },
				{ content: `${decimalAmount(history.amount.refund, history.amount)}` },
				{ content: `${decimalAmount(history.amount.totalPrice, history.amount)}` },
				{ content: `${decimalAmount(history.open, history.amount)}` },
				{
					content: (
						<Box>
							<Label className={classes.status} type={statusColor[history.status]}>
								{statusText[history.status]}
							</Label>
						</Box>
					),
				},
			])
		:	Array(5)
				.fill(Array(tableHeader.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })));

	return (
		<Box>
			<ActivePeriod />
			<Box pt={4.3}>
				<Table
					body={tableBody}
					data={billingsData ? billingsData.results : []}
					handlePageChange={handlePageChange}
					header={tableHeader}
					loading={billingsLoading}
					page={pageNumber}
					rowsPerPage={pageSize}
					setRowsPerPage={handlePageSizeChange}
					title={t('views.tableResults.revenue.table.title')}
					total={billingsData ? billingsData.total : 0}
					withoutPages={true}
				/>
			</Box>
		</Box>
	);
};

const mapStateToProps = (state) => {
	return {
		billings: state.paged.billings,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchBillings: (page, filters) => dispatch(actions.fetchBillings(page, filters)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Personal);

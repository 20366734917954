import { TableCell, TableSortLabel } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { useStyles } from '../Management/style';

interface HeadersProps {
	cIndex?: number;
	cell?: object;
}

const Headers = (props: HeadersProps) => {
	const { cell, cIndex } = props;
	const { t } = useTranslation();

	const classes = useStyles();

	return (
		<TableCell
			className={clsx({
				[classes.consumerHeaders]: cIndex === 0,
				[classes.qtyHeader]: cIndex === 1,
				[classes.userGroupHeader]: cIndex === 2,
				[classes.termsHeader]: cIndex === 3,
				[classes.consumerHeaders]: cIndex === 4,
				[classes.consumerHeaders]: cIndex === 5,
				[classes.policyHeader]: cIndex === 6,
				[classes.lastUpdateHeader]: cIndex === 7,
			})}
			key={cell.name}
		>
			{cell.hasSorting ?
				<TableSortLabel>{t(cell.content)}</TableSortLabel>
			:	t(cell.content)}
		</TableCell>
	);
};

export default Headers;

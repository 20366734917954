import { useState, useEffect, useReducer } from 'react';

import EuroIcon from '@mui/icons-material/Euro';
import GavelOutlinedIcon from '@mui/icons-material/GavelOutlined';
import LocalPoliceOutlinedIcon from '@mui/icons-material/LocalPoliceOutlined';
import { Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import {
	useSharedData,
	useInternalSharingItems,
	useSharingsConsumersData,
	useConsumerSharingItems,
	usePublicSharingItems,
} from './hooks';
import { providersReducer } from './reducer';
import { useStyles } from './style';
import ProvidersTable from './TableComponents/ProvidersTable';
import { SearchBar, EmptyState } from '../../../components';
import { usePagination } from '../../../shared/hooks';
import { isFullArray, isObject, isEmptyArray, isInteger } from '../../../shared/utility';
import * as actions from '../../../store/actions';
import { usePagedCall, usePagedCallTerms, stateType } from '../hooks';
import { tableHeaders, itemsReducer, editTypes } from '../ManagementConstants';

interface ManagementProps {
	onOrganisationsSlim?(...args: unknown[]): unknown;
	onFetchUserGroups?(...args: unknown[]): unknown;
	onFetchTerms?(...args: unknown[]): unknown;
	onFetchItems?(...args: unknown[]): unknown;
	onFetchPolicies?(...args: unknown[]): unknown;
	onFetchPricingModels?(...args: unknown[]): unknown;
}

const Management = (props: ManagementProps) => {
	const {
		onOrganisationsSlim,
		onFetchUserGroups,
		onFetchPricingModels,
		onFetchTerms,
		onFetchPolicies,
		onFetchItems,
	} = props;
	const { t } = useTranslation();

	const classes = useStyles();

	const navigate = useNavigate();

	const [providers, dispatchProviders] = useReducer(providersReducer, []);

	const [selectedItems, dispatchSelectedItems] = useReducer(itemsReducer, []);

	const [order, setOrder] = useState({ order: 'asc', descending: true, orderBy: 'id' });

	const pagination = usePagination('managementPageNumber', 'managementPageSize');

	const slimFilters = {
		sortBy: 'name',
		orderDescending: false,
	};

	const slimOrganisations = usePagedCall(onOrganisationsSlim, stateType.ORGANISATIONSSLIM, {
		...slimFilters,
		filter: 'verified',
	});
	const slimItems = usePagedCall(onFetchItems, stateType.ITEMSSLIM, {
		...slimFilters,
		includePublic: false,
	});
	const slimUserGroups = usePagedCall(onFetchUserGroups, stateType.USERGROUPSSLIM, {
		...slimFilters,
	});
	const slimPricingModels = usePagedCall(onFetchPricingModels, stateType.PRICINGMODELSSLIM, {
		...slimFilters,
	});
	const slimTerms = usePagedCallTerms(onFetchTerms, stateType.TERMSSLIM, {
		sortBy: 'title',
		orderDescending: false,
	});
	const slimPolicies = usePagedCall(onFetchPolicies, stateType.POLICIESSLIM, { ...slimFilters });

	const filter = {
		...(isInteger(slimUserGroups.state.id) && { userGroupId: slimUserGroups.state.id }),
		...(isInteger(slimPricingModels.state.id) && { pricingModelId: slimPricingModels.state.id }),
		...(isInteger(slimTerms.state.id) && { termsId: slimTerms.state.id }),
		...(isInteger(slimPolicies.state.id) && { policyId: slimPolicies.state.id }),
		...(isInteger(slimItems.state.id) && { itemId: slimItems.state.id }),
	};

	const providerFilter = {
		...(slimOrganisations.state.id != null && { providerId: slimOrganisations.state.id }),
		...filter,
	};

	const sharedData = useSharedData(pagination, providerFilter);
	const sharingsConsumers = useSharingsConsumersData(filter);
	const consumerItems = useConsumerSharingItems(filter);
	const internalItems = useInternalSharingItems(filter);
	const publicItems = usePublicSharingItems(filter);

	const handleFetchList = () => sharedData?.setFetchData(true);

	const lazyFilters = [
		{
			...slimOrganisations,
			fetchList: handleFetchList,
			key: stateType.ORGANISATIONSSLIM,
			placeholder: t('ui.filter.organisations.all'),
		},
		{
			...slimItems,
			fetchList: handleFetchList,
			key: stateType.ITEMSSLIM,
			placeholder: t('views.planboard.filterbar.filters.items.all'),
		},
		{
			...slimUserGroups,
			fetchList: handleFetchList,
			key: stateType.USERGROUPSSLIM,
			placeholder: t('ui.placeholder.allUserGroups'),
		},
		{
			...slimPricingModels,
			fetchList: handleFetchList,
			key: stateType.PRICINGMODELSSLIM,
			placeholder: t('views.assets.pricing.table.title'),
		},
		{
			...slimTerms,
			fetchList: handleFetchList,
			key: stateType.TERMSSLIM,
			placeholder: t('ui.label.terms.all'),
		},
		{
			...slimPolicies,
			fetchList: handleFetchList,
			key: stateType.POLICIESSLIM,
			placeholder: t('ui.label.policies.all'),
		},
	];

	const handleClearFilters = () => {
		slimOrganisations.resetInitialState();
		slimItems.resetInitialState();
		slimUserGroups.resetInitialState();
		slimPricingModels.resetInitialState();
		slimTerms.resetInitialState();
		slimPolicies.resetInitialState();
		sharedData?.setFetchData(true);
		pagination.resetPagination();
	};

	const clearFilters = {
		clear:
			pagination.pageNumber !== 1 ||
			pagination.pageSize !== 10 ||
			slimOrganisations.state.id != null ||
			isInteger(slimUserGroups.state.id) ||
			isInteger(slimPricingModels.state.id) ||
			isInteger(slimTerms.state.id) ||
			isInteger(slimPolicies.state.id) ||
			isInteger(slimItems.state.id),
		btnText: 'ui.button.inline.clearfilters',
		action: handleClearFilters,
	};

	const handleRequestSort = (property) => {
		const isDesc = order.orderBy === property && order.order === 'desc';
		setOrder({ order: isDesc ? 'asc' : 'desc', descending: !isDesc, orderBy: property });
	};

	useEffect(() => {
		if (isObject(sharedData) && sharedData.newData) {
			dispatchProviders({ type: 'empty' });
			dispatchProviders({ type: 'add', providers: sharedData?.results });
			pagination.setFatch(false);
			sharedData.setNewData(false);
		}
	}, [sharedData, pagination]);

	useEffect(() => {
		if (sharingsConsumers.newData && isFullArray(sharingsConsumers.results)) {
			dispatchProviders({
				type: 'addConsumers',
				consumers: sharingsConsumers.results,
				id: sharingsConsumers.consumerId,
				total: sharingsConsumers.total,
			});
			sharingsConsumers.setNewData(false);
		}
	}, [sharingsConsumers]);

	useEffect(() => {
		if (consumerItems.newData && isFullArray(consumerItems.results)) {
			dispatchProviders({
				type: 'addItems',
				items: consumerItems.results,
				providerId: consumerItems.providerId,
				consumerId: consumerItems.consumerId,
				total: consumerItems.total,
			});
			consumerItems.setNewData(false);
		}
	}, [consumerItems]);

	useEffect(() => {
		if (internalItems.newData && isFullArray(internalItems.results)) {
			dispatchProviders({
				type: 'addInternalAndPublicItems',
				items: internalItems.results,
				providerId: internalItems.id,
				consumerType: 'internal',
				total: internalItems.total,
			});
			internalItems.setNewData(false);
		}
	}, [internalItems]);

	useEffect(() => {
		if (publicItems.newData && isFullArray(publicItems.results)) {
			dispatchProviders({
				type: 'addInternalAndPublicItems',
				items: publicItems.results,
				providerId: publicItems.id,
				consumerType: 'public',
				total: publicItems.total,
			});
			publicItems.setNewData(false);
		}
	}, [publicItems]);

	const handleEdit = (editType) => {
		navigate('/item-management/management/edit', {
			state: {
				selectedItems: selectedItems,
				editType: editType,
				providerId: sharingsConsumers.consumerId,
			},
		});
	};

	const editActionButtons = [
		{
			icon: <GavelOutlinedIcon sx={{ marginRight: '8px' }} />,
			text: t('views.itemManagement.management.button.edit.terms'),
			action: () => handleEdit(editTypes.TERMS),
			variant: 'inline-default',
		},
		{
			icon: <EuroIcon sx={{ marginRight: '8px' }} />,
			text: t('views.itemManagement.management.button.edit.pricing'),
			action: () => handleEdit(editTypes.PRICING),
			variant: 'inline-default',
		},
		{
			icon: <LocalPoliceOutlinedIcon sx={{ marginRight: '8px' }} />,
			text: t('views.itemManagement.management.button.edit.policy'),
			action: () => handleEdit(editTypes.POLICY),
			variant: 'inline-default',
		},
	];

	const handleLinks = (
		path,
		label,
		itemDetails,
		returnButton = 'views.itemManagement.management.table.header',
	) => (
		<Link
			className={classes.itemGroup}
			color='primary'
			component={RouterLink}
			to={path}
			state={{ from: location.pathname, label: t(returnButton), itemDetails: itemDetails }}
		>
			{label}
		</Link>
	);

	const managementHeaders = [{ name: 'name', content: 'ui.provider' }];
	//
	const createItemsData = (data) => {
		const { items: createItems, isLoadingItems, pageNumber, ...rest } = data;

		if (!isLoadingItems && isFullArray(createItems)) {
			return createItems.map((item) => ({
				canEdit: item.canEdit,
				item: item.item,
				userGroup: item?.userGroup,
				consumerName: data.consumerName,
				selected: item?.selected,
				pageNumber: pageNumber,
				results: [
					{
						content: handleLinks(
							`/item-management/item/${item.item.id}/summary/${data.providerId}/sharing/${data.type}/${
								data.type === 'internal' ? item.userGroup.id
								: data.type === 'external' ? data.consumerId
								: 'id'
							}`,
							item.item.name,
							{ ...rest, ...item },
						),
					},
					{ content: item?.totalInstances },
					{
						content:
							item?.userGroup?.name ?
								handleLinks(
									`/user-management/user-groups/${item.userGroup.id}/summary`,
									item.userGroup.name,
									{ ...rest, ...item },
								)
							:	t('views.itemManagement.management.userGroup.placeholder'),
					},
					{
						content:
							item?.terms?.name &&
							handleLinks(`/term/details/${item.terms.id}/summary`, item.terms.name, {
								...rest,
								...item,
							}),
					},
					{
						content:
							item?.pricingModel?.name &&
							handleLinks(`/pricing/${item.pricingModel.id}/summary`, item.pricingModel.name, {
								...rest,
								...item,
							}),
					},
					{
						content:
							item?.businessPricingModel?.name &&
							handleLinks(
								`/pricing/${item.businessPricingModel.id}/summary`,
								item.businessPricingModel.name,
								{ ...rest, ...item },
							),
					},
					{
						content:
							item?.policy?.name &&
							handleLinks(
								`/policy-management/item-policies/${item.policy.id}/summary`,
								item.policy.name,
								{ ...item, ...rest },
							),
					},
				],
			}));
		} else {
			return Array(5)
				.fill()
				.map(() => ({ loading: true }));
		}
	};
	//
	const createConsumersData = (data) => {
		const { consumers, providerId, isLoadingConsumers, providerName } = data;

		if (!isLoadingConsumers && isFullArray(consumers)) {
			return consumers.map((consumer) => ({
				providerId,
				id: consumer.id,
				type: consumer.type,
				itemsPage: consumer.itemsPage,
				consumerName: consumer.name,
				total: consumer.total,
				isLoadingItems: consumer.isLoadingItems,
				selecteAll:
					isFullArray(consumer?.items) ?
						consumer?.items?.every((selectedItem) =>
							selectedItem.canEdit === true ? selectedItem.selected === true : true,
						)
					:	false,
				indeterminate:
					!consumer?.items?.every((selectedItem) =>
						selectedItem.canEdit === true ? selectedItem.selected === true : true,
					) &&
					consumer?.items?.some((selectedItem) => selectedItem.canEdit && selectedItem.selected),
				isShowingItems: consumer.isShowingItems,
				results: [{ content: consumer.id ? consumer.name : t(`ui.label.${consumer.type}`) }],
				items:
					consumer.isShowingItems ?
						createItemsData({
							providerId,
							providerName,
							consumerName: consumer.name,
							consumerId: consumer.id,
							items: consumer.items,
							isLoadingItems: consumer.isLoadingItems,
							type: consumer.type,
							pageNumber: consumer.itemsPage.pageNumber,
						})
					:	[],
			}));
		} else {
			return Array(5)
				.fill()
				.map(() => ({ loading: true }));
		}
	};

	const body =
		isFullArray(providers) && !sharedData?.newData ?
			providers.map((provider) => ({
				providerId: provider.id,
				isExpanded: provider.isShowingConsumers,
				consumersPage: provider.consumersPage,
				total: provider.total,
				results: [{ content: provider.name }],
				consumers:
					provider.isShowingConsumers ?
						createConsumersData({
							isLoadingConsumers: provider.isLoadingConsumers,
							providerName: provider.name,
							providerId: provider.id,
							consumers: provider.consumers,
						})
					:	[],
			}))
		:	Array(5)
				.fill(Array(tableHeaders.length).fill())
				.map((arr) => ({ isExpanded: false, results: arr.map(() => ({ loading: true })) }));

	const handleToggle = (item, type) => dispatchProviders({ type: type, item: item });

	const selectionEvents = {
		onShowConsumers: (item) => {
			handleToggle(item, 'toggleShowingConsumers');
			dispatchSelectedItems({});
			sharingsConsumers.setConsumerId(item.providerId);
			if (!item.isExpanded) {
				sharingsConsumers.setFetchData(true);
			}
		},
		onShowConsumerItems: (item) => {
			handleToggle(item, 'toggleShowingItems');
			consumerItems.setProviderId(item.providerId);
			consumerItems.setConsumerId(item.id);
			if (!item.isShowingItems && !['internal', 'public'].includes(item.type)) {
				consumerItems.setFetchData(true);
			}
			if (item.type === 'internal') {
				internalItems.setId(item.providerId);
				if (!item.isShowingItems) {
					internalItems.setFetchData(true);
				}
			} else if (item.type === 'public') {
				publicItems.setId(item.providerId);
				if (!item.isShowingItems) {
					publicItems.setFetchData(true);
				}
			}
		},
		onSelectedAllItems: (itemsSelected) => {
			dispatchProviders({
				type: itemsSelected.selected ? 'selectAllItems' : 'removeAllItems',
				item: itemsSelected,
			});
			dispatchSelectedItems({
				type: itemsSelected.selected ? 'addAllItems' : 'removeAllItems',
				item: itemsSelected,
			});
		},
		onSelectedItem: (item) => {
			dispatchProviders({ type: 'selectItem', item: item });
			dispatchSelectedItems({
				type: item.selected ? 'addItem' : 'removeItem',
				item: { ...item, items: [item.item] },
			});
		},
	};

	const handlePagination = (type, providerData) =>
		dispatchProviders({ type: type, item: providerData });

	const onChangePageNumber = (page, providerId) => {
		handlePagination('updateConsumersPage', { providerId: providerId, pageNumber: page + 1 });
		sharingsConsumers.setPage({ ...sharingsConsumers.page, number: page + 1 });
		sharingsConsumers.setFetchData(true);
	};

	const onChangePageSize = (pageSize, providerId) => {
		handlePagination('updateConsumersPage', { providerId: providerId, pageSize: pageSize });
		sharingsConsumers.setPage({ ...sharingsConsumers.page, size: pageSize });
		sharingsConsumers.setFetchData(true);
	};

	const consumerActions = {
		changePageNumber: onChangePageNumber,
		changePageSize: onChangePageSize,
	};

	const onChangePageNumberItems = (page, provider) => {
		handlePagination('updateItemsPage', { ...provider, pageNumber: page + 1 });
		if (provider?.id) {
			consumerItems.setPage({ ...consumerItems.page, number: page + 1 });
			consumerItems.setFetchData(true);
		} else if (provider.type === 'internal') {
			internalItems.setPage({ ...internalItems.page, number: page + 1 });
			internalItems.setFetchData(true);
		} else if (provider.type === 'public') {
			publicItems.setPage({ ...publicItems.page, number: page + 1 });
			publicItems.setFetchData(true);
		}
	};

	const onChangePageSizeItems = (pageSize, provider) => {
		handlePagination('updateItemsPage', { ...provider, pageNumber: 1, pageSize: pageSize });
		if (provider?.id) {
			consumerItems.setPage({ number: 1, size: pageSize });
			consumerItems.setFetchData(true);
		} else if (provider.type === 'internal') {
			internalItems.setPage({ number: 1, size: pageSize });
			internalItems.setFetchData(true);
		} else if (provider.type === 'public') {
			publicItems.setPage({ number: 1, size: pageSize });
			publicItems.setFetchData(true);
		}
	};

	const itemsActions = {
		changePageNumber: onChangePageNumberItems,
		changePageSize: onChangePageSizeItems,
	};

	const searchBarProps = { hideSearch: true, clearFilters, lazyFilters };

	const tableProps = {
		title: t('views.itemManagement.management.table.header'),
		checkboxAligning: true,
		headers: tableHeaders,
		managementHeaders,
		order: order.order,
		orderBy: order.orderBy,
		handleSorting: handleRequestSort,
		body,
		total: sharedData?.total ? sharedData?.total : 0,

		consumerActions: consumerActions,
		itemsActions: itemsActions,
		selectionEvents: selectionEvents,
		handlePageChange: pagination.pageNumberChange,
		page: pagination.pageNumber,
		rowsPerPage: pagination.pageSize,
		setRowsPerPage: pagination.pageSizeChange,
		selection: selectedItems,
		editActionButtons,
	};

	return (
		<>
			<SearchBar {...searchBarProps} />
			{isEmptyArray(sharedData?.results) ?
				<EmptyState image={'booking'} title={t('ui.noResultsFound')} />
			:	<ProvidersTable {...tableProps} />}
		</>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		onOrganisationsSlim: (page, filters, concat) =>
			dispatch(actions.organisationsSlim(page, filters, concat)),
		onFetchUserGroups: (page, filters, concat) =>
			dispatch(actions.userGroupsSlim(page, filters, concat)),
		onFetchPricingModels: (page, filters, concat) =>
			dispatch(actions.pricingModelsSlim(page, filters, concat)),
		onFetchTerms: (page, filters, concat) => dispatch(actions.termsSlim(page, filters, concat)),
		onFetchPolicies: (page, filters, concat) =>
			dispatch(actions.policiesSlim(page, filters, concat)),
		onFetchItems: (page, filters, concat) => dispatch(actions.itemsSlim(page, filters, concat)),
	};
};

export default connect(null, mapDispatchToProps)(Management);

import { useMemo } from 'react';

import { Autocomplete, AutocompleteProps, TextField } from '@mui/material';
import { getName, getSupportedLanguages, getAlpha2Codes } from 'i18n-iso-countries';
import { useTranslation } from 'react-i18next';

interface CountryOption {
	countryCode: string;
	label: string;
}

interface CountryAutocompleteProps<T extends string>
	extends Omit<AutocompleteProps<T, false, boolean | undefined, false>, 'renderInput' | 'options'> {
	required?: boolean;
}

const CountryAutocomplete = ({
	required = false,
	...autocompleteProps
}: CountryAutocompleteProps<string>) => {
	const { i18n, t } = useTranslation();

	const supportedCountries = useMemo(() => {
		const supportedCountryCodes = Object.keys(getAlpha2Codes());

		const result = supportedCountryCodes.map((el) => ({
			countryCode: el,
			label: getName(el, i18n.resolvedLanguage, { select: 'alias' }) ?? el,
		}));
		result.sort((a, b) => {
			if (a.label.toLowerCase() < b.label.toLowerCase()) {
				return -1;
			}

			return 1;
		});
		return result;
	}, [i18n.resolvedLanguage, getSupportedLanguages]);

	return (
		<Autocomplete
			{...autocompleteProps}
			value={autocompleteProps.value ?? null}
			options={supportedCountries}
			getOptionLabel={(option) => option.label ?? getName(option, i18n.resolvedLanguage, { select: 'alias' })}
			isOptionEqualToValue={(option, value) => option.countryCode == value}
			onChange={(event, val, reason, details) => autocompleteProps.onChange?.(event, val.countryCode, reason, details)}
			renderInput={(params) => (
				<TextField
					{...params}
					required={required}
					label={t('ui.label.country')}
					slotProps={{
						htmlInput: {
							...params.inputProps,
							autoComplete: 'new-password', // disable autocomplete and autofill
						},
					}}
				/>
			)}
		/>
	);
};

export default CountryAutocomplete;

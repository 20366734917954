import { atomWithRefresh, loadable } from 'jotai/utils';

import { User, UsersService } from '~features/users';
import i18n from '~lib/i18n';

const service = new UsersService();

/**
 * An atom for the user information. On init this value is retrieved from
 * the user. After the initial call, this atom is the source of truth.
 * TODO: Do we need the following additions?
 * - localStorage: this is done currently for the currentUser, but what is
 * 	 the added value? We would only need this if we were maybe offline. But
 *   it just adds a additional 'caching' layer
 * - sync with the server: see atomWithReset
 * TODO: this triggers React.suspense. We want that for the inital fetch,
 * but not after that.
 * https://github.com/pmndrs/jotai/discussions/1102
 * https://github.com/pmndrs/jotai/discussions/1460
 */
const userInfoAtom = atomWithRefresh<Promise<User | null> | User>(async () => {
	try {
		const res = await service.getUserInformation();
		// TODO: eventually synchronisation with the localstorage here in stead of 
		// in the service
		i18n.changeLanguage(res.preferences.preferredCultureInfo);
		return res;
	} catch (_error) {
		console.warn('Unable to get user information from server');

		// TODO: determine if this is good. If the user info is not going correct, the
		// admin panel is technically not functional. But it could be "unwanted" to work
		// with outdated data
		try {
			const item = localStorage.getItem('currentUser');
			if (item != null) {
				const currentUser = JSON.parse(item);
				return UsersService.fromResponse(currentUser);
			}
		} catch (_) {
			console.warn('Unable to get parsed user from local storage');
			return null;
		}

		console.error('Unable to get user information');
		return null;
	}
});

/**
 * A user info while preventing react suspense
 */
export const loadableUserInfoAtom = loadable(userInfoAtom);

export default userInfoAtom;

import { useRef, useState } from 'react';

import { AutocompleteInputChangeReason } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useSWRInfinite from 'swr/infinite';

import { AsyncAutocomplete, AsyncAutocompleteProps } from '~components';
import { IdReference, PagedResults } from '~interfaces';
import i18n from '~lib/i18n';

import DeviceGroup from '../../interfaces/skcDeviceGroup';
import SkcSchedule from '../../interfaces/skcSchedule';
import SchedulesService from '../../services/skcSchedulesService';

const service = new SchedulesService();

interface ScheduleAutocompleteProps
	extends Omit<AsyncAutocompleteProps<SkcSchedule>, 'options' | 'label'> {
	label?: string;
	count?: number;
	organisation?: IdReference;
}

const ScheduleAutocomplete = ({
	label = i18n.t('schedule'),
	count = 10,
	organisation,
	...asyncAutocompleteProps
}: ScheduleAutocompleteProps) => {
	const { t } = useTranslation();

	const [searchQuery, setSearchQuery] = useState('');
	const [open, setOpen] = useState(false);

	const total = useRef<number>();
	const page = useRef(1);

	const getKey = (i: number, previousData: PagedResults<DeviceGroup[]>) => {
		if (!open || (previousData && !previousData.hasMore)) {
			// reached the end
			return null;
		}

		page.current = i + 1;

		return [
			service.basePath,
			{
				page: page.current,
				pageSize: count,
				organisationId: organisation?.id,
				searchQuery: searchQuery,
			},
		] as const;
	};

	const { data, isLoading, isValidating, size, setSize } = useSWRInfinite(
		getKey,
		([_, args]) => service.getSchedules(args),
		{
			keepPreviousData: true,
			revalidateFirstPage: false,
			onSuccess: (res) => (total.current = res[0].total),
		},
	);

	/**
	 * Just set the size of the pages
	 */
	const handleOverflow = () => {
		if ((size - 1) * count <= total.current) {
			setSize(size + 1);
		}
	};

	const handleInputChange = (
		e: React.SyntheticEvent<Element, Event>,
		value: string,
		reason: AutocompleteInputChangeReason,
	) => {
		setSearchQuery(value);

		asyncAutocompleteProps.onInputChange?.(e, value, reason);
	};

	return (
		<AsyncAutocomplete
			{...asyncAutocompleteProps}
			label={label}
			loading={isLoading || isValidating}
			noOptionsText={t('noResults')}
			onOpen={() => setOpen(true)}
			onClose={() => setOpen(false)}
			options={data?.flatMap((el) => el.results) ?? []}
			onInputChange={handleInputChange}
			onOverflow={handleOverflow}
		/>
	);
};

export default ScheduleAutocomplete;

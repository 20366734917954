import SearchIcon from '@mui/icons-material/Search';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';
import { Input } from '../..';
import { StyledButton } from '../../../components';
import { isFullString } from '../../../shared/utility';

interface SearchProps {
	className?: string;
	placeholder?: string;
	events?: object;
	value?: string;
	hasSearchButton?: boolean;
	hasInlineSearchButton?: boolean;
	inputType?: 'search-suggest';
	styledInput?: string;
}

const Search = (props: SearchProps) => {
	const {
		placeholder,
		events,
		value,
		hasSearchButton,
		hasInlineSearchButton,
		inputType,
		className,

		styledInput,
	} = props;
	const { t } = useTranslation();

	const classes = useStyles();

	return (
		<div className={clsx(classes.root, className)}>
			<Input
				className={isFullString(styledInput) ? styledInput : classes.searchInput}
				events={events}
				hasClearButton={true}
				hasInlineSearchButton={hasInlineSearchButton}
				icon={!inputType ? <SearchIcon /> : null}
				inputType={inputType ? inputType : null}
				placeholder={placeholder ? placeholder : t('ui.search')}
				value={value}
			/>
			{hasSearchButton ?
				<StyledButton
					className={classes.searchButton}
					onClick={events.onClick}
					size='large'
					variant='contained-tertiary'
				>
					{t('ui.button.contained.search')}
				</StyledButton>
			:	null}
		</div>
	);
};

export default Search;

import { Typography } from '@mui/material';

import { Tooltip } from '~components';

import { handleDeviceTypes } from '../../../../shared/utility';
import { useStyles } from '../style';

interface TooltipProps {
	titles?: unknown[];
}

const DeviceTooltip = (props: TooltipProps) => {
	const { titles } = props;

	const classes = useStyles();

	return (
		<>
			{titles?.length > 1 ?
				<Tooltip
					placement='bottom'
					title={titles.map((type) => (
						<Typography key={type}>{handleDeviceTypes(type)}</Typography>
					))}
				>
					<Typography className={classes.detailTitle}>{`${titles?.length} types`}</Typography>
				</Tooltip>
			:	handleDeviceTypes(titles.at(0))}
		</>
	);
};

export default DeviceTooltip;

import { Children, PropsWithChildren } from 'react';

import { Grid2 } from '@mui/material';

interface DetailsGridLayoutProps extends PropsWithChildren {}

const DetailsGridLayout = ({ children }: DetailsGridLayoutProps) => {
	return (
		<Grid2 container spacing={3}>
			{Children.map(
				children,
				(child, i) =>
					child != null && (
						<Grid2 key={i} size={{ xs: 12, md: 6, lg: 6, xl: 4 }} maxHeight={600}>
							{child}
						</Grid2>
					)
			)}
		</Grid2>
	);
};

export default DetailsGridLayout;

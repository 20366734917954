import { useState, useEffect } from 'react';

import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useMatch, useNavigate, useParams } from 'react-router-dom';

import BikesCharacteristics from './BiksCharacteristics';
import BoatCharactaristics from './BoatCharactaristics';
import CarCharacteristics from './CarCharacteristics';
import OfficeCharacteristics from './OfficeCharacteristics';
import ParkingLotsCharacteristics from './ParkingLotsCharacteristics';
import TrailersCharacteristics from './TrailersCharacteristics';
import { categories } from '../../../../../constantsOld';
import { useError } from '../../../../../shared/hooks';
import { isObject, isUndefined, isNull, isInteger, isBoolean } from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions/index';

const PREFIX = 'Characteristics';

const classes = {
	root: `${PREFIX}-root`,
	typeDetails: `${PREFIX}-typeDetails`,
};

const Root = styled('div')(() => ({
	[`& .${classes.root}`]: {},
	[`& .${classes.typeDetails}`]: {},
}));

interface CharacteristicsProps {
	selectedCategory?: object;
	itemStep?: number;
	save?(...args: unknown[]): unknown;
	cargoSpace?: boolean;
	images?: object;
	allCharacteristics?: object;
	onItemPatch?(...args: unknown[]): unknown;
	onFetchItem?(...args: unknown[]): unknown;
	onFetchCharacteristics?(...args: unknown[]): unknown;
	characteristics?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	itemPatch?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	itemDetails?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const Characteristics = (props: CharacteristicsProps) => {
	const {
		save,
		allCharacteristics,
		itemDetails,
		onFetchItem,
		onItemPatch,
		itemPatch,
		onFetchCharacteristics,
		characteristics,
	} = props;
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { data: characteristicsData } = characteristics;

	useEffect(() => {
		if (!isObject(characteristicsData)) {
			onFetchCharacteristics();
		}
	}, []);

	const [includesBoats, setIncludesBoats] = useState([]);

	const match = useMatch({
		path: '/item-management/item/:itemId/characteristics',
		end: true,
		caseSensitive: true,
	});

	const { itemId } = useParams();

	const [isEdit] = useState(isObject(match));
	const [updatingItem, setUpdatingItem] = useState(false);
	const updatingDone = isObject(itemPatch.data) && !itemPatch.loading && !itemPatch.error;
	const patchItemErrorHandling = useError({
		value: itemPatch,
		message: `${t('ui.success.message.updated')} ${isObject(itemPatch.data) ? itemPatch.data.name : '-'}`,
		variant: 'success',
	});

	const categoryId =
		isEdit && isObject(itemDetails.data) ? itemDetails.data.categoryReference.id
		: isObject(allCharacteristics) ? allCharacteristics.category
		: '';

	const getDetailsProps = () => ({
		...(isObject(allCharacteristics) && allCharacteristics),
		...(isEdit &&
			isObject(itemDetails.data) && {
				...(isInteger(categoryId) &&
					itemDetails.data.characteristics.reduce((acc, cur) => {
						const name = cur.name.charAt(0).toLowerCase() + cur.name.substring(1);
						return { ...acc, [name]: cur.value };
					}, {})),
			}),
	});

	useEffect(() => {
		if (isEdit) {
			const boats = [];
			if (categoryId === categories.BOATS && isObject(itemDetails?.data)) {
				itemDetails.data.characteristics.map((car) =>
					isBoolean(car.value) && car.value ? boats.push(car.name) : car,
				);
			}
			setIncludesBoats(boats);
		}
	}, [itemDetails]);

	useEffect(() => {
		if (isEdit && !isObject(itemDetails.data)) {
			onFetchItem(itemId);
		}
	}, [isEdit, itemDetails.data]);

	useEffect(() => {
		setDetailsProps(getDetailsProps());
	}, [itemDetails, allCharacteristics]);

	const editCallback = (data) => {
		const [key, value] = Object.entries(data)[0];

		if (!isUndefined(value) && !isNull(value)) {
			const name = key.charAt(7).toLowerCase() + key.substring(8);

			let updatedProps;
			if (name === 'propulsionType') {
				if (value === 'hybrid') {
					// eslint-disable-next-line no-unused-vars
					const { transmissionType, kmRange, ...rest } = detailsProps;
					updatedProps = rest;
				} else if (value === 'electric') {
					// eslint-disable-next-line no-unused-vars
					const { transmissionType, ...rest } = detailsProps;
					updatedProps = rest;
				} else if (value === 'fuel') {
					// eslint-disable-next-line no-unused-vars
					const { kmRange, ...rest } = detailsProps;
					updatedProps = rest;
				}
			} else {
				updatedProps = detailsProps;
			}
			if (name === 'bikePropulsionType') {
				if (value === 'noSupport') {
					// const {} = detailsProps;
				}
			}
			setDetailsProps({ ...updatedProps, [name]: value });
		}
	};

	const [detailsProps, setDetailsProps] = useState(getDetailsProps());

	useEffect(() => {
		if (updatingItem && updatingDone) {
			setUpdatingItem(false);
			navigate(`/item-management/items/${itemId}/summary`);
		}
	}, [updatingItem, updatingDone]);

	const handleEdit = (update) => {
		if (update) {
			const patchItemBody = {
				...(categoryId === categories.PARKINGLOTS && { parkingLotCharacteristics: detailsProps }),
				...(categoryId === categories.TRAILERS && { trailerCharacteristics: detailsProps }),
				...(categoryId === categories.CARS && { carCharacteristics: detailsProps }),
				...(categoryId === categories.OFFICES && { officeCharacteristics: detailsProps }),
				...(categoryId === categories.BIKES && { bikeCharacteristics: detailsProps }),
				...(categoryId === categories.BOATS && { boatCharacteristics: detailsProps }),
			};
			onItemPatch(itemId, patchItemBody);
			setUpdatingItem(true);
			patchItemErrorHandling.setStartAction(true);
		} else {
			navigate(`/item-management/items/${itemId}/summary`);
		}
	};

	const characteristicsProps = {
		className: classes.typeDetails,
		onClose: handleEdit,
		save: save,
		...detailsProps,
		isEdit: isEdit,
		isFinishingEdit: updatingItem,
		...(isEdit && { save: editCallback }),
		...(isEdit && { includesBoats: includesBoats }),
	};

	const characteristicsCard = {
		4: <TrailersCharacteristics {...characteristicsProps} />,
		3: <CarCharacteristics {...characteristicsProps} />,
		2: <OfficeCharacteristics {...characteristicsProps} />,
		1: <BikesCharacteristics {...characteristicsProps} />,
		5: <ParkingLotsCharacteristics {...characteristicsProps} />,
		7: <BoatCharactaristics {...characteristicsProps} />,
	};

	return !isObject(detailsProps) ? null : <Root>{characteristicsCard[categoryId]}</Root>;
};

const mapStateToProps = (state) => {
	return {
		itemDetails: state.details.itemDetails,
		itemPatch: state.details.itemPatch,

		characteristics: state.details.characteristics,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchItem: (id) => dispatch(actions.fetchItem(id)),
		onItemPatch: (id, properties) => dispatch(actions.itemPatch(id, properties)),
		onFetchCharacteristics: (id) => dispatch(actions.fetchCharacteristics(id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Characteristics);

import { useEffect, useState } from 'react';

import CreditCardIcon from '@mui/icons-material/CreditCard';
import FeedbackIcon from '@mui/icons-material/Feedback';
import {
	Box,
	Typography,
	List,
	ListItem,
	RadioGroup,
	FormControlLabel,
	Radio,
} from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useAuthorize } from '~features/authentication';
import { useDebounce } from '~hooks';

import { Notification, LoadingBar } from '../../../../components';
import { areDatesEqual } from '../../../../shared/datetime';
import { usePaymentDetails } from '../../../../shared/hooks';
import { isObject, isEmptyArray, isUndefined, isFullString } from '../../../../shared/utility';
import * as actions from '../../../../store/actions';
import { useStyles } from '../style';

interface UsageCardProps {
	selectedStartDate?: object;
	selectedEndDate?: object;
	selectedUser?: object;
	onFetchPaymentMethodsOfUser?(...args: unknown[]): unknown;
	bookingData?: object;
	open?: boolean;
	fetchInstanceData?: object;
	presetData?: object;
	onFetchBookingPrice?(...args: unknown[]): unknown;
	onFetchCalculatedPrice?(...args: unknown[]): unknown;
	applyingDiscount?: boolean;
	hasChangedItem?: boolean;
	bookingLoading?: boolean;
	selectedPaymentMethod?: string;
	setSelectedPaymentMethod?(...args: unknown[]): unknown;
	setDiscount?(...args: unknown[]): unknown;
	setHasChangedItem?(...args: unknown[]): unknown;
	isProvider?: boolean;
	setApplyingDiscount?(...args: unknown[]): unknown;
	fetchBookingPrice?: boolean;
	setFetchBookingPrice?(...args: unknown[]): unknown;
	selectedItem?: object;
	setPrice?(...args: unknown[]): unknown;
	discount?: number;
	nowSwitch?: boolean;
	paymentMethodsUser?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	calculatedPrice?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	bookingPrice?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	fetchItemPolicies?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const UsageCard = (props: UsageCardProps) => {
	const {
		selectedUser,
		selectedItem,
		onFetchPaymentMethodsOfUser,

		paymentMethodsUser,
		calculatedPrice,
		bookingData,
		open,
		onFetchBookingPrice,
		onFetchCalculatedPrice,
		setPrice,
		hasChangedItem,
		bookingPrice,
		selectedStartDate,
		selectedEndDate,
		fetchInstanceData,
		discount,
		applyingDiscount,
		bookingLoading,
		presetData,
		setSelectedPaymentMethod,
		selectedPaymentMethod,
		setDiscount,
		setHasChangedItem,
		isProvider,
		setApplyingDiscount,
		fetchItemPolicies,
		nowSwitch,
		fetchBookingPrice,
		setFetchBookingPrice,
	} = props;
	const { t } = useTranslation();
	const { isSuperAdmin } = useAuthorize();

	const { data: pmData, loading: pmLoading, error: pmError } = paymentMethodsUser;
	const pmReady = isObject(pmData) && !pmLoading && !pmError;

	const {
		data: calculatedPriceData,
		loading: calculatedPriceLoading,
		error: calculatedPriceError,
	} = calculatedPrice;
	const priceReady =
		isObject(calculatedPriceData) && !calculatedPriceLoading && !calculatedPriceError;

	const { data: fetchItemPoliciesData } = fetchItemPolicies;

	const {
		data: bookingPriceData,
		loading: bookingPriceLoading,
		error: bookingPriceError,
	} = bookingPrice;

	const [paymentMethods, setPaymentMethods] = useState([]);

	const classes = useStyles();

	const bankData = usePaymentDetails(pmReady && isObject(pmData.personal) && pmData.personal);

	const debouncedDiscount = useDebounce(discount, 300);

	const [paymentMethodAllowed, setPaymentMethodAllowed] = useState(true);

	useEffect(() => {
		if (isObject(selectedUser)) {
			onFetchPaymentMethodsOfUser(selectedUser.id);
		} else if (!isObject(bookingData)) {
			setPaymentMethods([]);
			setSelectedPaymentMethod('');
			setPaymentMethodAllowed(true);
		}
	}, [selectedUser, selectedItem, isProvider]);

	useEffect(() => {
		if (pmReady) {
			setPaymentMethods([
				...((isProvider || isSuperAdmin()) && pmData.personal.status === 'authorized' ?
					[
						{
							value: 'personal',
							logo: pmData.personal.logo,
							type: t('ui.label.privateUse'),
							...(isSuperAdmin() &&
								priceReady &&
								!calculatedPriceData.isFree && { details: bankData.bank }),
						},
					]
				:	[]),
				...(pmData.business.status === 'authorized' ?
					[
						{
							value: 'business',
							logo: pmData.business.logo,
							type: t('ui.label.businessUse'),
							details: pmData.business.name,
						},
					]
				:	[]),
			]);
		}
	}, [pmReady, priceReady]);

	const newBooking = !isObject(bookingData) && isObject(fetchItemPoliciesData);

	useEffect(() => {
		if (
			(isObject(fetchInstanceData) || newBooking) &&
			isObject(selectedUser) &&
			!calculatedPriceLoading &&
			isObject(selectedItem) &&
			selectedStartDate &&
			selectedEndDate &&
			open
		) {
			const isStartBigger = selectedStartDate.getTime() > selectedEndDate.getTime();
			const endDate =
				selectedStartDate.toISOString() === selectedEndDate.toISOString() || isStartBigger ?
					new Date(selectedStartDate.getTime() + 900000)
				:	selectedEndDate;
			const params = {
				userId: selectedUser.id,
				...(moment.isDate(selectedStartDate) && { periodStart: selectedStartDate.toISOString() }),
				periodEnd: endDate.toISOString(),
				itemInstanceId: selectedItem.instanceId,
				...(applyingDiscount && { discount: Number.isNaN(discount) ? 0 : discount }),
				...(isFullString(selectedPaymentMethod) && { billingType: selectedPaymentMethod }),
			};
			onFetchCalculatedPrice(selectedItem.itemId, params);
		}
	}, [
		selectedUser,
		selectedPaymentMethod,
		fetchInstanceData,
		selectedItem,
		selectedStartDate,
		selectedEndDate,
		open,
		debouncedDiscount,
		applyingDiscount,
		fetchItemPoliciesData,
	]);

	const timeNotChanged =
		(bookingData?.periodEnd && areDatesEqual(new Date(bookingData?.periodEnd), selectedEndDate)) ||
		(bookingData?.usage?.stop &&
			areDatesEqual(new Date(bookingData?.usage?.stop), selectedEndDate));

	useEffect(() => {
		if (isObject(bookingData) && fetchBookingPrice) {
			setFetchBookingPrice(false);
			onFetchBookingPrice(
				bookingData.id,
				(
					bookingData.status === 'active' ||
						nowSwitch ||
						areDatesEqual(new Date(bookingData?.periodStart), selectedStartDate)
				) ?
					null
				:	selectedStartDate,
				timeNotChanged ? null : selectedEndDate,
				applyingDiscount ?
					Number.isNaN(discount) ?
						0
					:	discount
				: timeNotChanged ? 0
				: null,
				!isUndefined(bookingData.promoCode) ? bookingData.promoCode : null,
				nowSwitch ? true : null,
				timeNotChanged ? false : null,
			);
			if (hasChangedItem) {
				setHasChangedItem(false);
			}
		}
	}, [fetchBookingPrice, bookingPriceData]);

	useEffect(() => {
		if (isObject(bookingData) && bookingData !== null && timeNotChanged) {
			onFetchBookingPrice(
				bookingData.id,
				(
					bookingData.status === 'active' ||
						nowSwitch ||
						areDatesEqual(new Date(bookingData?.periodStart), selectedStartDate)
				) ?
					null
				:	selectedStartDate,
				selectedEndDate,
				null,
				!isUndefined(bookingData.promoCode) ? bookingData.promoCode : null,
				nowSwitch ? true : null,
				timeNotChanged ? false : null,
			);
		}
	}, [bookingData, onFetchBookingPrice, timeNotChanged]);

	useEffect(() => {
		if (isObject(calculatedPriceData) && !calculatedPriceLoading && !calculatedPriceError) {
			setPrice(calculatedPriceData);
		}
	}, [calculatedPriceLoading]);

	useEffect(() => {
		if (isObject(bookingPriceData) && !bookingPriceLoading && !bookingPriceError) {
			setPrice(bookingPriceData);
		}
	}, [bookingPriceData]);

	useEffect(() => {
		if (isObject(bookingData) && !bookingLoading) {
			let priceDetails;
			if (isObject(bookingData) && isObject(bookingData.priceDetails)) {
				priceDetails = bookingData.priceDetails;
			}
			if (!isUndefined(priceDetails?.discount)) {
				setDiscount(priceDetails?.discount);
				if (priceDetails?.discount > 0) {
					setApplyingDiscount(true);
				}
			}
			setPrice(priceDetails);
		}
	}, [bookingData, bookingLoading, presetData]);

	const handleChangePaymentMethod = (e) => {
		setSelectedPaymentMethod(e.target.value);
	};

	useEffect(() => {
		if (
			isObject(bookingData) &&
			selectedUser?.id !== bookingData?.userReference?.id &&
			!paymentMethods.find((method) => method.value === bookingData?.billingType)
		) {
			setPaymentMethodAllowed(false);
			setSelectedPaymentMethod('');
		} else {
			setPaymentMethodAllowed(true);
			if (isObject(bookingData)) {
				setSelectedPaymentMethod(bookingData.billingType);
			}
		}
	}, [bookingData, selectedUser, paymentMethods]);

	return (
		<div className={classes.usageCard}>
			<Typography className={classes.usageCardHeaders} variant='h5'>
				{t('views.planboard.addBooking.usage.sectionTitle')}
			</Typography>
			{!isObject(selectedItem) ?
				<Notification
					className={classes.usageCardHeaders}
					message={t('views.planboard.addBooking.notification.noItemSelected')}
					startIcon={<FeedbackIcon />}
				/>
			: !selectedStartDate || !selectedEndDate ?
				<Notification
					className={classes.usageCardHeaders}
					message={t('views.planboard.addBooking.notification.noDatesSelected')}
					startIcon={<FeedbackIcon />}
				/>
			: !isObject(selectedUser) ?
				<Notification
					className={classes.usageCardHeaders}
					message={t('views.planboard.addBooking.notification.noUserSelected')}
					startIcon={<FeedbackIcon />}
				/>
			: pmLoading ?
				<List>
					<ListItem className={classes.loadingListItem}>
						<LoadingBar />
					</ListItem>
					<ListItem className={classes.loadingListItem}>
						<LoadingBar />
					</ListItem>
				</List>
			: isEmptyArray(paymentMethods) || !paymentMethodAllowed ?
				<Notification
					className={classes.usageCardHeaders}
					message={t('views.planboard.addBooking.notification.noPaymentMethodsAvailable')}
					startIcon={<CreditCardIcon />}
					variant='error'
				/>
			:	<RadioGroup onChange={handleChangePaymentMethod} value={selectedPaymentMethod}>
					{paymentMethods.map((method, index) => (
						<FormControlLabel
							className={classes.radioFormControlLabel}
							classes={{
								label: classes.radioLabel,
								labelPlacementStart: classes.radioLabelStart,
							}}
							control={
								<Radio
									checked={
										method.value ===
										(isObject(bookingData) ? bookingData?.billingType : selectedPaymentMethod)
									}
									color='primary'
									disabled={isObject(bookingData)}
								/>
							}
							key={`payment-method-${index}`}
							label={
								<Box alignContent='center' display='flex'>
									<img className={classes.radioImage} src={method.logo} />
									<Box display='flex' flexDirection='column' justifyContent='center'>
										<Typography variant='h6'>{method.type}</Typography>
										{method.details ?
											<Typography className={classes.labelDetails} variant='body2'>
												{method.details}
											</Typography>
										:	null}
									</Box>
								</Box>
							}
							labelPlacement='start'
							value={method.value}
						/>
					))}
				</RadioGroup>
			}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		planboardBooking: state.details.planboardBooking,
		paymentMethodsUser: state.details.paymentMethodsUser,
		calculatedPrice: state.details.calculatedPrice,
		bookingPrice: state.details.bookingPrice,
		fetchItemPolicies: state.details.fetchItemPolicies,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchPaymentMethodsOfUser: (userId) => dispatch(actions.fetchPaymentMethodsOfUser(userId)),
		onFetchCalculatedPrice: (itemId, params) =>
			dispatch(actions.fetchCalculatedPrice(itemId, params)),
		onFetchBookingPrice: (
			bookingId,
			startDate,
			endDate,
			discount,
			promoCode,
			isStartNow,
			isExtending,
		) =>
			dispatch(
				actions.fetchBookingPrice(
					bookingId,
					startDate,
					endDate,
					discount,
					promoCode,
					isStartNow,
					isExtending,
				),
			),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UsageCard);

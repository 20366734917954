import { useEffect } from 'react';

import { ButtonProps } from '@mui/material';
import { useAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';

import pageDrawerButtonsAtom from '../atoms/pageDrawerButtonsAtom';
import pageDrawerOpenAtom from '../atoms/pageDrawerOpenAtom';
import pageHeaderButtonsAtom from '../atoms/pageHeaderButtonsAtom';
import pageTitleAtom from '../atoms/pageTitleAtom';

interface PageButtonsProps {
	pageTitle?: string;
	setPageTitle: (value: string | undefined) => void;
	pageHeaderButtons: ButtonProps[];
	setPageHeaderButtons: (value: ButtonProps[]) => void;
	pageDrawerButtons: ButtonProps[];
	setPageDrawerButtons: (value: ButtonProps[]) => void;
	drawerOpen?: boolean;
	setDrawerOpen: (value: boolean) => void;
}

/**
 * A hook to set the buttons defined in the header of
 * the page layout.
 * Why use this hook instead of using the atom directly? We want
 * the buttons to be reset if a user leaves the pages the button
 * are set. By using this hook we have control and we can make sure
 * that the buttons are reset whenever the component unmounts.
 * Tried to do this using context of React. But the state wasn't
 * properly updated
 */
const usePageLayoutProperties = (): PageButtonsProps => {
	const [pageTitle, setPageTitle] = useAtom(pageTitleAtom);
	const resetPageTitle = useResetAtom(pageTitleAtom);

	const [pageHeaderButtons, setPageHeaderButtons] = useAtom(pageHeaderButtonsAtom);
	const resetPageButtons = useResetAtom(pageHeaderButtonsAtom);

	const [drawerOpen, setDrawerOpen] = useAtom(pageDrawerOpenAtom);
	const [pageDrawerButtons, setPageDrawerButtons] = useAtom(pageDrawerButtonsAtom);
	const resetPageDrawerButtons = useResetAtom(pageDrawerButtonsAtom);

	useEffect(() => {
		return () => {
			resetPageTitle();
			resetPageButtons();
			resetPageDrawerButtons();
		};
	}, [resetPageTitle, resetPageButtons, resetPageDrawerButtons]);

	return {
		pageTitle,
		setPageTitle,
		pageHeaderButtons,
		setPageHeaderButtons,
		pageDrawerButtons,
		setPageDrawerButtons,
		drawerOpen,
		setDrawerOpen
	};
};

export default usePageLayoutProperties;

import { useEffect, useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Card, CardHeader, CardContent, Divider, Typography, Box } from '@mui/material';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useStyles } from './style';
import { AlertDialog, StyledButton } from '../../../../../../components';
import { isObject } from '../../../../../../shared/utility';
import * as actions from '../../../../../../store/actions';

interface IncomingRequestActionsProps {
	className?: string;
	partner?: object;
	updatedPartnership?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onUpdatePartnershipStatus?(...args: unknown[]): unknown;
}

const IncomingRequestActions = (props: IncomingRequestActionsProps) => {
	const { className, partner, onUpdatePartnershipStatus, updatedPartnership } = props;
	const { t } = useTranslation();
	const navigate = useNavigate();

	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();

	const {
		data: updatedPartnershipData,
		loading: updatedPartnershipLoading,
		error: updatedPartnershipError,
	} = updatedPartnership;
	const updatePartnerShipStatusDone =
		isObject(updatedPartnershipData) && !updatedPartnershipLoading && !updatedPartnershipError;

	const [updatingPartnershipStatus, setUpdatingPartnershipStatus] = useState(false);

	const [openDialog, setOpenDialog] = useState(false);

	const [button, setButton] = useState(null);

	const handleAccept = (accept) => {
		setButton(accept);
		setOpenDialog(true);
	};

	const handleDecline = (reject) => {
		setButton(reject);
		setOpenDialog(true);
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
	};

	const handleConfirmDialog = () => {
		if (button === 'accept') {
			setUpdatingPartnershipStatus(true);
			onUpdatePartnershipStatus(partner.partnerOrganisation.id, button);
		}
		if (button === 'reject') {
			setUpdatingPartnershipStatus(true);
			onUpdatePartnershipStatus(partner.partnerOrganisation.id, button);
		}
	};

	// in case status has been successfully updated
	useEffect(() => {
		if (updatingPartnershipStatus && updatePartnerShipStatusDone) {
			setUpdatingPartnershipStatus(false);
			if (button === 'accept') {
				enqueueSnackbar(
					`${t('views.partnerships.messages.success.accept')} ${partner.partnerOrganisation.name}`,
					{ variant: 'success' },
				);
				navigate(`/partnerships/partners/${partner.partnerOrganisation.id}/summary`);
			} else if (button === 'reject') {
				enqueueSnackbar(
					`${t('views.partnerships.messages.success.reject')} ${partner.partnerOrganisation.name}`,
				);
				navigate('/partnerships/incoming-requests');
			}
		}
	}, [updatePartnerShipStatusDone, updatingPartnershipStatus]);

	// in case there's an error with updating the status
	useEffect(() => {
		if (updatingPartnershipStatus && !updatedPartnershipLoading && updatedPartnershipError) {
			setUpdatingPartnershipStatus(false);
			enqueueSnackbar(
				isObject(updatedPartnershipError) ?
					updatedPartnershipError.message
				:	updatedPartnershipError,
				{ variant: 'error' },
			);
		}
	}, [updatedPartnershipError, updatingPartnershipStatus, updatedPartnershipLoading]);

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader title={t('views.partnerDetail.summary.cardHeaders.incomingRequestActions')} />
			<Divider />
			<CardContent>
				<Typography gutterBottom={true} variant='h6'>
					{`${t('views.partnerDetail.summary.connectWith')}: ${partner.partnerOrganisation.name}`}{' '}
				</Typography>
				<Box display='flex'>
					<Box pr={2.5}>
						<StyledButton
							onClick={() => handleDecline('reject')}
							size='small'
							startIcon={<CloseIcon />}
							variant='inline-delete'
						>
							{t('ui.button.inline.decline')}
						</StyledButton>
					</Box>
					<StyledButton
						onClick={() => handleAccept('accept')}
						size='small'
						startIcon={<CheckIcon />}
						variant='inline-default'
					>
						{t('ui.button.inline.accept')}
					</StyledButton>
				</Box>
				<AlertDialog
					dialogDescription={
						button === 'reject' ? t('ui.dialog.partnership.decline.description')
						: button === 'accept' ?
							t('ui.dialog.partnership.accept.description')
						:	''
					}
					dialogTitle={
						button === 'reject' ? t('ui.dialog.partnership.decline.title')
						: button === 'accept' ?
							t('ui.dialog.partnership.accept.title')
						:	''
					}
					handleClose={handleCloseDialog}
					handleConfirm={handleConfirmDialog}
					open={openDialog}
				/>
			</CardContent>
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		fetchPartnerships: state.paged.fetchPartnerships,

		updatedPartnership: state.details.updatedPartnership,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onUpdatePartnershipStatus: (partnerId, state) =>
			dispatch(actions.updatePartnershipStatus(partnerId, state)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(IncomingRequestActions);

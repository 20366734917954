import { forwardRef, useEffect, useImperativeHandle } from 'react';

import { ajvResolver } from '@hookform/resolvers/ajv';
import { MenuItem } from '@mui/material';
import { JSONSchemaType } from 'ajv';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormContainer, Select, TextField } from '~components';
import { useFormContainerState } from '~components/dialogs/formContainerProvider';
import { LanguageEnum } from '~enums';
import { FormWrapperRefProps } from '~interfaces/refProps';

import schema from './removePaymentMethodSchema.json';

enum PaymentCancellationReason {
	Other = 'other',
	CancelledPaypalBillingAgreement = 'cancelledPaypalBillingAgreement',
}

interface RemovePaymentMethodData {
	language?: LanguageEnum;
	reason?: PaymentCancellationReason;
	message: string;
}

interface RemovePaymentMethodFormProps {
	value?: Partial<RemovePaymentMethodData>;
	onSubmit?: (value: RemovePaymentMethodData) => void;
}

const supportedLanguages = Object.keys(LanguageEnum);

const paymentCancellationMapping: {
	reason: PaymentCancellationReason;
	i18nTitleKey?: string;
	i18nMessageKey?: string;
}[] = [
	{
		reason: PaymentCancellationReason.Other,
		i18nTitleKey: 'views.licenses.rejectionReason.other',
	},
	{
		reason: PaymentCancellationReason.CancelledPaypalBillingAgreement,
		i18nTitleKey: 'paymentMethodDeletionReason.cancelledBillingAgreementPaypal.title',
		i18nMessageKey: 'paymentMethodDeletionReason.cancelledBillingAgreementPaypal.message',
	},
];

const RemovePaymentMethodForm = forwardRef<FormWrapperRefProps, RemovePaymentMethodFormProps>(
	({ value, onSubmit }, ref) => {
		const { t } = useTranslation();

		const { setDisabled } = useFormContainerState();

		const { getValues, control, formState, setValue } = useForm<RemovePaymentMethodData>({
			defaultValues: {
				...(() => {
					const defaultReason = PaymentCancellationReason.CancelledPaypalBillingAgreement;
					const defaultI18nMessageKey = paymentCancellationMapping.find(
						(el) => el.reason === defaultReason
					)?.i18nMessageKey;

					return {
						reason: defaultReason,
						message:
							defaultI18nMessageKey ? t(defaultI18nMessageKey, { lng: value?.language }) : '',
					};
				})(),
				...value,
			},
			mode: 'onChange',
			resolver: ajvResolver(schema as JSONSchemaType<any>),
		});

		useImperativeHandle(
			ref,
			() => ({
				onSubmit: () => onSubmit?.(getValues()),
			}),
			[onSubmit, getValues]
		);

		useEffect(() => {
			setDisabled?.(!formState.isValid);
		}, [formState.isValid, setDisabled]);

		return (
			<FormContainer>
				<Controller
					name='reason'
					control={control}
					render={({ field }) => (
						<Select
							label={t('reason')}
							value={field.value ?? ''}
							onChange={(e) => {
								field.onChange(e.target.value);

								const i18nKey = paymentCancellationMapping.find((el) => el.reason === e.target.value)?.i18nMessageKey;
								setValue('message', i18nKey ? t(i18nKey, { lng: getValues('language') }) : '', {
									shouldValidate: true,
								});
							}}
						>
							{Object.values(PaymentCancellationReason).map((el: PaymentCancellationReason) => (
								<MenuItem key={el} value={el}>
									{t(paymentCancellationMapping.find((confEl) => confEl.reason === el)?.i18nTitleKey ?? el)}
								</MenuItem>
							))}
						</Select>
					)}
				/>

				<Controller
					name='language'
					control={control}
					render={({ field }) => (
						<Select
							label={t('views.userDetails.summary.language')}
							value={field.value ?? ''}
							onChange={(e) => {
								field.onChange(e.target.value);

								const i18nKey = paymentCancellationMapping.find(
									(el) => el.reason === getValues('reason')
								)?.i18nMessageKey;
								setValue('message', i18nKey ? t(i18nKey, { lng: getValues('language') }) : '', {
									shouldValidate: true,
								});
							}}
						>
							{supportedLanguages.map((lang: string) => (
								<MenuItem
									key={lang}
									value={Object.values(LanguageEnum)[supportedLanguages.indexOf(lang)]}
								>
									{t(lang)}
								</MenuItem>
							))}
						</Select>
					)}
				/>

				<Controller
					name='message'
					control={control}
					render={({ field }) => (
						<TextField
							{...field}
							value={field.value}
							onChange={field.onChange}
							required
							label={t('views.planboard.addBooking.comment')}
							placeholder={t('ui.placeholder.description')}
							multiline
							minRows={3}
							slotProps={{
								htmlInput: {
									maxLength: schema.properties.message.maxLength,
								},
							}}
						/>
					)}
				/>
			</FormContainer>
		);
	}
);

export default RemovePaymentMethodForm;

import { Chip, ChipOwnProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

import PaymentMethodStatusEnum from '../../enums/paymentMethodStatusEnum';

interface PaymentMethodStatusChip {
	status: PaymentMethodStatusEnum; // A general status
}

const PaymentMethodStatusChip = ({ status }: PaymentMethodStatusChip) => {
	const { t } = useTranslation();

	const getStatusLabel = (): string => {
		return t(`ui.status.${status}`);
	};

	const getStatusColor = (): ChipOwnProps['color'] => {
		let color: ChipOwnProps['color'];
		switch (status) {
			case PaymentMethodStatusEnum.Unavailable:
				color = 'disabled';
				break;
			case PaymentMethodStatusEnum.Started:
			case PaymentMethodStatusEnum.Pending:
				color = 'default';
				break;
			case PaymentMethodStatusEnum.Authorized:
				color = 'success';
				break;
			case PaymentMethodStatusEnum.Rejected:
				color = 'error';
				break;
			case PaymentMethodStatusEnum.Disabled:
				color = 'warning';
				break;
			default:
				color = 'default';
				break;
		}

		return color;
	};

	return <Chip size='small' label={getStatusLabel()} color={getStatusColor()} />;
};

export default PaymentMethodStatusChip;

import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
} from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';

interface AdministrativePolicyInfoProps {
	className?: string;
	policy: object;
}

const AdministrativePolicyInfo = (props: AdministrativePolicyInfoProps) => {
	const { policy, className } = props;
	const { t } = useTranslation();

	const classes = useStyles();

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader title={t('views.policyDetail.summary.cardHeaders.administrativePolicies')} />
			<Divider />
			<CardContent className={classes.content}>
				<Table>
					<TableBody>
						<TableRow>
							<TableCell>{t('ui.label.commentRequired')}</TableCell>
							<TableCell>
								{policy.requireReservationComment ? t('ui.enabled') : t('ui.disabled')}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	);
};

export default AdministrativePolicyInfo;

import { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { isObject, isInteger, isNull, isArray } from '../../../shared/utility';
import {
	updatePagedState,
	sharings,
	internalSharingItems,
	sharingsConsumers,
	consumerSharingItems,
	publicSharingItems,
	internalAvailableSharing,
	publicAvailableSharing,
	externalAvailableSharing,
} from '../../../store/actions';
import { sharingTypes } from '../ManagementConstants/';

function useSharedData(pagination, filters) {
	const [fetchData, setFetchData] = useState(true);
	const [newData, setNewData] = useState(false);

	const sharingsData = useSelector((state) => state.paged.sharings);
	const dispatch = useDispatch();

	useEffect(() => {
		if (fetchData || pagination.fetch) {
			dispatch(sharings(pagination.page, filters));
			setNewData(true);
		}
		if (fetchData) {
			setFetchData(false);
		} else if (pagination.fetch) {
			pagination.setFatch(false);
		}
		return () => {
			if (fetchData || pagination.fetch) {
				return null;
			} else {
				dispatch(updatePagedState('sharings'));
			}
		};
	}, [fetchData, pagination.fetch]);

	if (isObject(sharingsData.data)) {
		return {
			results: sharingsData.data.results,
			fetchData,
			setFetchData,
			total: sharingsData.data.total,
			loading: sharingsData.loading,
			newData,
			setNewData,
		};
	}
}

function useSharingsConsumersData(filter) {
	const [page, setPage] = useState({ number: 1, size: 10 });
	const [fetchData, setFetchData] = useState(true);
	const [newData, setNewData] = useState(false);

	const [consumerId, setConsumerId] = useState(null);

	const sharingsConsumersData = useSelector((state) => state.paged.sharingsConsumers);
	const dispatch = useDispatch();

	useEffect(() => {
		if (consumerId != null && fetchData) {
			dispatch(sharingsConsumers(consumerId, page, filter));
			setNewData(true);
			if (fetchData) {
				setFetchData(false);
			}
		}
		return () => {
			if (fetchData) {
				return null;
			} else {
				dispatch(updatePagedState('sharingsConsumers'));
			}
		};
	}, [fetchData, consumerId]);

	return {
		results: sharingsConsumersData?.data?.results,
		total: sharingsConsumersData?.data?.total,
		loading: sharingsConsumersData?.loading,
		consumerId,
		setConsumerId,
		fetchData,
		setFetchData,
		newData,
		setNewData,
		page,
		setPage,
	};
}

function useConsumerSharingItems(filter) {
	const [page, setPage] = useState({ number: 1, size: 5 });
	const [fetchData, setFetchData] = useState(true);
	const [newData, setNewData] = useState(false);

	const [providerId, setProviderId] = useState(null);
	const [consumerId, setConsumerId] = useState(null);

	const consumerSharingItemsData = useSelector((state) => state.paged.consumerSharingItems);
	const dispatch = useDispatch();

	useEffect(() => {
		if (providerId != null && consumerId != null && fetchData) {
			dispatch(consumerSharingItems(providerId, consumerId, page, filter));
			setNewData(true);
			if (fetchData) {
				setFetchData(false);
			}
		}
		return () => {
			if (fetchData) {
				return null;
			} else {
				dispatch(updatePagedState('consumerSharingItems'));
			}
		};
	}, [fetchData, providerId, consumerId]);

	return {
		results: consumerSharingItemsData?.data?.results,
		fetchData,
		setFetchData,
		total: consumerSharingItemsData?.data?.total,
		loading: consumerSharingItemsData?.loading,
		newData,
		setNewData,
		setConsumerId,
		setProviderId,
		providerId,
		consumerId,
		page,
		setPage,
	};
}

function useInternalSharingItems(filter) {
	const [page, setPage] = useState({ number: 1, size: 5 });
	const [fetchData, setFetchData] = useState(true);
	const [newData, setNewData] = useState(false);

	const [id, setId] = useState(null);

	const internalSharingItemsData = useSelector((state) => state.paged.internalSharingItems);
	const dispatch = useDispatch();

	useEffect(() => {
		if (id != null && fetchData) {
			dispatch(internalSharingItems(id, page, filter));
			setNewData(true);
			if (fetchData) {
				setFetchData(false);
			}
		}
		return () => {
			if (fetchData) {
				return null;
			} else {
				dispatch(updatePagedState('internalSharingItems'));
			}
		};
	}, [fetchData, id]);

	return {
		results: internalSharingItemsData?.data?.results,
		total: internalSharingItemsData?.data?.total,
		loading: internalSharingItemsData?.loading,
		newData,
		setNewData,
		fetchData,
		setFetchData,
		id,
		setId,
		page,
		setPage,
	};
}

function usePublicSharingItems(filter) {
	const [page, setPage] = useState({ number: 1, size: 5 });
	const [fetchData, setFetchData] = useState(true);
	const [newData, setNewData] = useState(false);

	const [id, setId] = useState(null);

	const publicSharingItemsData = useSelector((state) => state.paged.publicSharingItems);
	const dispatch = useDispatch();

	useEffect(() => {
		if (id != null && fetchData) {
			dispatch(publicSharingItems(id, page, filter));
			setNewData(true);
			if (fetchData) {
				setFetchData(false);
			}
		}
		return () => {
			if (fetchData) {
				return null;
			} else {
				dispatch(updatePagedState('publicSharingItems'));
			}
		};
	}, [fetchData, id]);

	return {
		results: publicSharingItemsData?.data?.results,
		total: publicSharingItemsData?.data?.total,
		loading: publicSharingItemsData?.loading,
		fetchData,
		setFetchData,
		newData,
		setNewData,
		page,
		setPage,
		id,
		setId,
	};
}

function useFetchAvailableSharing(props) {
	const { providerId, consumerId, userGroupId, itemId, type } = props;

	const [fetch, setFetch] = useState(true);
	const [isUpdated, setIsUpdated] = useState(false);

	const INTERNAL = type === sharingTypes.INTERNAL;
	const PUBLIC = type === sharingTypes.PUBLIC;
	const EXTERNAL = type === sharingTypes.EXTERNAL;

	const dispatch = useDispatch();

	const availableSharing = useSelector((state) => {
		if (INTERNAL) {
			return state.list.internalAvailableSharing;
		} else if (PUBLIC) {
			return state.list.publicAvailableSharing;
		} else {
			return state.list.externalAvailableSharing;
		}
	});

	useEffect(() => {
		if (INTERNAL && fetch) {
			dispatch(internalAvailableSharing(providerId, userGroupId, itemId));
		} else if (PUBLIC && fetch) {
			dispatch(publicAvailableSharing(providerId, itemId));
		} else if (EXTERNAL && fetch) {
			dispatch(externalAvailableSharing(providerId, consumerId, itemId));
		}
		setFetch(() => false);
	}, [isObject(props) && fetch]);

	useEffect(() => {
		if (isArray(availableSharing.data)) {
			setIsUpdated(() => true);
		}
	}, [availableSharing.data]);

	return {
		loading: isNull(availableSharing.data) || availableSharing.loading,
		data: availableSharing.data,
		isUpdated,
		setIsUpdated,
	};
}

export {
	useSharedData,
	useSharingsConsumersData,
	useConsumerSharingItems,
	useInternalSharingItems,
	usePublicSharingItems,
	useFetchAvailableSharing,
};

import { useEffect, useMemo, useState } from 'react';

import { Box, Portal, Stack } from '@mui/material';
import {
	GridColDef,
	GridFilterModel,
	GridPaginationModel,
	GridRowSelectionModel,
} from '@mui/x-data-grid';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { DataGrid, DataGridToolbar } from '~components';
import { useAuthorize } from '~features/authentication';
import { Device, SkcDevicesService } from '~features/devices';
import { useUrlSearchParamsOrganisation } from '~features/organisations';
import { BaseReference } from '~interfaces';

import SelectOrganisationOverlay from './selectOrganisationOverlay';
import selectedOrganisationAtom from '../../atoms/selectedOrganisationAtom';

const toolbarIdString = 'topology-access-rules-datagrid';

const service = new SkcDevicesService();

interface AccessDevicesDataGridProps {
	onDeviceSelectionChange: (value: Device[]) => void;
}

const AccessDevicesDataGrid = ({ ...props }: AccessDevicesDataGridProps) => {
	const { t } = useTranslation();
	const { isSuperAdmin } = useAuthorize();
	const [selectedOrganisation, setSelectedOrganisation] = useUrlSearchParamsOrganisation();

	const [selectedDevices, setSelectedDevices] = useState<Device[]>([]);

	const [fetchParameters, setFetchParameters] = useState<object | null>(null);

	const columns = useMemo<GridColDef<Device>[]>(
		() => [
			{ field: 'id', headerName: 'ID', width: 80, hideable: false },
			{
				field: 'hardwareId',
				headerName: t('hardwareId'),
			},
			{
				field: 'skopeiNumber',
				headerName: t('skopeiNumber'),
				flex: 1,
			},
			{
				field: 'organisation',
				headerName: t('organisation'),
				valueGetter: () => selectedOrganisation,
				valueFormatter: (value: BaseReference) => value?.label ?? '-',
				flex: 1,
			},
		],
		[]
	);

	const { data, isLoading, isValidating, error } = useSWR(
		[service.basePath, fetchParameters],
		([_, args]) => service.getDevices(args),
		{
			keepPreviousData: true,
		}
	);
	const [totalCount, setTotalCount] = useState<number>(data?.total || 0);

	useEffect(() => {
		if (data?.total != null) {
			setTotalCount(data.total);
		}
	}, [data]);

	const handlePaginationChange = (value: GridPaginationModel) => {
		setFetchParameters((prev) => ({
			...prev,
			page: value.page + 1,
			pageSize: value.pageSize,
		}));
	};

	const handleFilterModelChange = (model: GridFilterModel) => {
		const searchQuery = model.quickFilterValues?.[0];

		const newOrganisation =
			model.items?.[0]?.field === 'organisation' ? model.items[0].value : undefined;

		setSelectedOrganisation(newOrganisation);
		setFetchParameters((prev) => ({
			...prev,
			organisationId: selectedOrganisation?.id,
			searchQuery: searchQuery,
		}));
	};

	const handleRowSelectionModelChange = (model: GridRowSelectionModel) => {
		const newSelectedDevices = [
			...selectedDevices.filter(el => model.includes(el.id)),
			...(data?.results.filter(el => model.includes(el.id)) ?? [])
		];
		setSelectedDevices(newSelectedDevices);

		props.onDeviceSelectionChange?.(newSelectedDevices);
	}

	return (
		<Stack
			spacing={2}
			sx={{
				display: 'flex',
				height: 1,
			}}
		>
			{/* TODO: admin has no use for the toolbar, but if we hide it, the datagrid renders weird */}
			<Box id={toolbarIdString} />
			<DataGrid
				checkboxSelection
				keepNonExistentRowsSelected
				disableColumnSorting
				snapshotKey='accl'
				columns={columns}
				error={error}
				loading={isLoading || isValidating}
				rows={data?.results ?? []}
				rowCount={totalCount}
				onPaginationModelChange={handlePaginationChange}
				onFilterModelChange={handleFilterModelChange}
				rowSelectionModel={selectedDevices.map(el => el.id)}
				onRowSelectionModelChange={handleRowSelectionModelChange}
				initialState={{
					filter: {
						filterModel: {
							items:
								selectedOrganisation ?
									[
										{
											field: 'organisation',
											operator: 'contains',
											value: selectedOrganisation,
										},
									]
								:	[],
						},
					},
				}}
				slots={{
					toolbar: Toolbar,
					...(selectedOrganisation == null && {
						noRowsOverlay: SelectOrganisationOverlay,
					}),
				}}
				slotProps={{
					noRowsOverlay: {
						title: t('noResourceFound', { resource: t('devices') }),
					},
					noResultsOverlay: {
						title: t('noResourceFound', { resource: t('devices') }),
					},
				}}
				sx={{ flexGrow: 1, flexBasis: 200 }}
			/>
		</Stack>
	);
};

const Toolbar = (props) => (
	<Portal container={() => document.getElementById(toolbarIdString)}>
		<DataGridToolbar disableSearch />
	</Portal>
);

export default AccessDevicesDataGrid;

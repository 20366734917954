import { useState, useEffect } from 'react';

import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

// import AddOrEditBooking from '../ActionFlows/AddOrEditBooking';
import FilterBar from './FilterBar';
import LoadingState from './LoadingState';
import { useStyles } from './style';
import TopBar from './TopBar';
import { Page, EmptyState } from '../../../components';
import { unsetTime, modifyDate } from '../../../shared/datetime';
import { useSearchComponent, useBasicFilter, useComplexFilter } from '../../../shared/hooks';
import {
	isNull,
	isObject,
	isEmptyArray,
	isEmptyString,
	isEmptyObject,
	isInteger,
} from '../../../shared/utility';
import * as actions from '../../../store/actions';
import SideDrawer from '../SideDrawer';

interface CalendarFilterContainerProps {
	children?: object;
	calendarRef?: object;
	openAddOrEditBooking?: boolean;
	presetBookingData?: object;
	shouldEditBooking?: boolean;
	date?: Date;
	setDate?(...args: unknown[]): unknown;
	pageNumber?: number;
	setPageNumber?(...args: unknown[]): unknown;
	pageSize?: number;
	shouldLazyLoadFetch?: boolean;
	setShouldLazyLoadFetch?(...args: unknown[]): unknown;
	shouldDoInitialFetch?: boolean;
	setShouldDoInitialFetch?(...args: unknown[]): unknown;
	setShouldEditBooking?(...args: unknown[]): unknown;
	setOpenAddOrEditBooking?(...args: unknown[]): unknown;
	setUpdatedBookingId?(...args: unknown[]): unknown;
	resources?: unknown[];
	setResources?(...args: unknown[]): unknown;
	onResetState?(...args: unknown[]): unknown;
	setPresetBookingData?(...args: unknown[]): unknown;
	planboardBookings?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	canceledBooking?: {
		success?: boolean;
		loading?: boolean;
		error?: object | string;
	};
	stopBooking?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	updateCooldown?: {
		success?: boolean;
		loading?: boolean;
		error?: object | string;
	};
	onFetchPlanboardBookings?(...args: unknown[]): unknown;
	onResetStateCondition?(...args: unknown[]): unknown;
}

const CalendarFilterContainer = (props: CalendarFilterContainerProps) => {
	const {
		children,
		resources,
		calendarRef,
		openAddOrEditBooking,
		setOpenAddOrEditBooking,
		presetBookingData,
		shouldEditBooking,
		setShouldEditBooking,
		date,
		setDate,
		pageNumber,
		setPageNumber,
		pageSize,
		shouldLazyLoadFetch,
		setShouldLazyLoadFetch,
		shouldDoInitialFetch,
		setShouldDoInitialFetch,
		planboardBookings,
		onFetchPlanboardBookings,
		setUpdatedBookingId,
		setResources,
		setPresetBookingData,
		canceledBooking,
		onResetStateCondition,
		stopBooking,
		onResetState,
		updateCooldown,
	} = props;
	const { t } = useTranslation();
	const classes = useStyles();

	useEffect(() => {
		if (!isNull(calendarRef.current)) {
			const calendarApi = calendarRef.current.getApi();
			calendarApi.gotoDate(date);
		}
	}, [calendarRef.current]);

	const [isShowingFilterResults, setIsShowingFilterResults] = useState(false);

	// results
	const [showingInitialResults, setShowingInitialResults] = useState(true);
	const [shouldSearch, setShouldSearch] = useState(false);
	const [shouldFilter, setShouldFilter] = useState(false);

	const { data: bookingsData, loading: bookingsLoading } = planboardBookings;

	const { success: cancelledBookingSuccess } = canceledBooking;

	const { success: updateCooldownSuccess } = updateCooldown;

	const { data: stopBookingData } = stopBooking;

	const search = useSearchComponent(setPageNumber, setShouldSearch, 'planboardSearch');

	const planboardItemFilter = useBasicFilter('planboardItemFilter');
	const planboardLocationFilter = useComplexFilter(
		'planboardLocationNameFilter',
		'planboardLocationIdFilter',
	);
	const planboardOrganisationFilter = useComplexFilter(
		'planboardOrganisationNameFilter',
		'planboardOrganisationIdFilter',
	);

	const filters = {
		...(!isEmptyString(search.value) && { searchTerm: search.value }),
		...(planboardOrganisationFilter.valueId !== 'all' && {
			organisationId: planboardOrganisationFilter.valueId,
		}),
		...(planboardItemFilter.value !== 'all' && { categoryId: planboardItemFilter.value }),
		...(planboardLocationFilter.valueId !== 'all' && { hubId: planboardLocationFilter.valueId }),
		itemFilters: 'unpublished,nonBookable',
		dateAfter: unsetTime(date).toISOString(),
		dateBefore: modifyDate(unsetTime(date), { date: '+1' }).toISOString(),
		slim: true,
	};

	/* * * * * * *
	 * GET DATA  *
	 * * * * * * */
	useEffect(() => {
		if (
			!bookingsLoading &&
			(shouldSearch ||
				shouldFilter ||
				(shouldLazyLoadFetch && bookingsData.hasMore) ||
				shouldDoInitialFetch)
		) {
			//
			if ((shouldSearch || shouldFilter) && showingInitialResults) {
				setShowingInitialResults(false);
			}
			if ((shouldSearch || shouldFilter) && !isShowingFilterResults) {
				setIsShowingFilterResults(true);
			}
			if (shouldSearch || shouldFilter) {
				setPageNumber(1);
				setResources([
					{ className: 'loading-track', id: '-1' },
					{ className: 'loading-track', id: '-2' },
					{ className: 'loading-track', id: '-3' },
				]);
			}
			if (shouldLazyLoadFetch) {
				setPageNumber(bookingsData.pageNumber + 1);
			}
			onFetchPlanboardBookings({ number: pageNumber, size: pageSize }, filters);
		}

		if (shouldSearch) {
			setShouldSearch(false);
		} else if (shouldFilter) {
			setShouldFilter(false);
		} else if (shouldLazyLoadFetch) {
			setShouldLazyLoadFetch(false);
		} else if (shouldDoInitialFetch) {
			setShouldDoInitialFetch(false);
		}
	}, [
		date,
		shouldSearch,
		shouldFilter,
		shouldDoInitialFetch,
		shouldLazyLoadFetch,
		showingInitialResults,
		pageNumber,
	]);

	useEffect(() => {
		return () => {
			setShouldDoInitialFetch(true);
			setShowingInitialResults(true);
		};
	}, [setShouldDoInitialFetch, setShowingInitialResults]);

	useEffect(() => {
		if (cancelledBookingSuccess || isObject(stopBookingData) || updateCooldownSuccess) {
			onFetchPlanboardBookings({ number: pageNumber, size: pageSize }, filters);
			onResetStateCondition('canceledBooking', false);
			onResetState('stopBooking');
			if (updateCooldownSuccess) {
				onResetStateCondition('updateCooldown', false);
			}
		}
	}, [cancelledBookingSuccess, stopBookingData, updateCooldownSuccess]);

	/* * * * * * * * *
	 * DATE METHODS  *
	 * * * * * * * * */
	const handleDateToday = () => {
		const calendarApi = calendarRef.current.getApi();

		calendarApi.today();
		setDate(calendarApi.getDate());
		setShouldFilter(true);
	};

	const handleDatePrev = () => {
		const calendarApi = calendarRef.current.getApi();

		calendarApi.prev();

		setDate(calendarApi.getDate());
		setShouldFilter(true);
	};

	const handleDateNext = () => {
		const calendarApi = calendarRef.current.getApi();

		calendarApi.next();
		setDate(calendarApi.getDate());
		setShouldFilter(true);
	};

	const handleSelectDate = (date) => {
		const calendarApi = calendarRef.current.getApi();

		calendarApi.gotoDate(date);
		setDate(calendarApi.getDate());
		setShouldFilter(true);
	};

	/* * * * * * * * * *
	 * BOOKING METHODS *
	 * * * * * * * * * */
	const handleAddBookingOpen = () => {
		setOpenAddOrEditBooking(true);
		setPresetBookingData(undefined);
	};

	const handleAddBookingClose = (shouldRefresh = false, updatedBookingId = null) => {
		setOpenAddOrEditBooking(false);
		if (shouldEditBooking) {
			setShouldEditBooking(false);
		}

		if (shouldRefresh) {
			setPageNumber(1);
			setShouldDoInitialFetch(true);
		}

		if (!isNull(updatedBookingId)) {
			setUpdatedBookingId(updatedBookingId);
		}
	};

	/* * * * * *
	 * FILTER  *
	 * * * * * */
	const handleClearFilters = () => {
		planboardOrganisationFilter.setValueId('all');
		planboardItemFilter.setValue('all');
		planboardLocationFilter.setValueId('all');
		if (isShowingFilterResults) {
			setShouldFilter(true);
		}
		setIsShowingFilterResults(false);
		setShowingInitialResults(true);
		planboardOrganisationFilter.setValueName('');
		planboardLocationFilter.setValueName('');
	};

	const handleChangeOrganisation = (item) => {
		if (item.id === planboardOrganisationFilter.valueId) {
			return;
		}
		if (isInteger(item.id)) {
			planboardOrganisationFilter.setValueName(item.name);
		} else if (isEmptyString(item.id)) {
			planboardOrganisationFilter.setValueName('');
		}
		planboardOrganisationFilter.setValueId(item.id);
		setShouldFilter(true);
	};

	const handleChangeItem = (e) => {
		if (planboardItemFilter.value !== e.target.value) {
			planboardItemFilter.setValue(e.target.value);
			setShouldFilter(true);
		}
	};

	const handleChangeLocation = (location) => {
		if (location.id === planboardLocationFilter.valueId) {
			return;
		}
		if (isInteger(location.id)) {
			planboardLocationFilter.setValueName(location.name);
		} else if (isEmptyString(location.id)) {
			planboardLocationFilter.setValueName('');
		}
		planboardLocationFilter.setValueId(location.id);
		setShouldFilter(true);
	};

	const filterEvents = {
		onChangeOrganisation: handleChangeOrganisation,
		onChangeItem: handleChangeItem,
		onClear: handleClearFilters,
		onChangeLocation: handleChangeLocation,
		onOrganisationValue: planboardOrganisationFilter.setValueName,
		organisationFilterValue: planboardOrganisationFilter.valueName,
		setLocationFilterValue: planboardLocationFilter.setValueName,
		locationFilterValue: planboardLocationFilter.valueName,
	};

	/* * * * * * * *
	 * EMPTY STATE *
	 * * * * * * * */
	const resourceListEmpty =
		isObject(bookingsData) && !bookingsLoading && isEmptyArray(bookingsData.items) ?
			showingInitialResults ?
				<EmptyState
					callToAction={'/item-management/items/add'}
					callToActionText={t('views.planboard.button.addResource')}
					className={classes.emptyState}
					image={'booking'}
					subTitle={t('views.planboard.empty.description.zeroInSystem')}
					title={t('views.planboard.empty.title')}
				/>
			:	<EmptyState
					callToAction={'/item-management/items/add'}
					callToActionText={t('views.planboard.button.addResource')}
					className={classes.emptyState}
					image={'booking'}
					subTitle={t('views.planboard.empty.description.zeroMatching')}
					title={t('views.planboard.empty.title')}
				/>
		:	null;

	return (
		<Page className={classes.root} title='Planboard'>
			<TopBar
				date={date}
				disabled={
					(isObject(bookingsData) && !bookingsLoading && isEmptyArray(bookingsData.items)) ||
					bookingsLoading
				}
				onAddBooking={handleAddBookingOpen}
				onDateChange={handleSelectDate}
				onDateNext={handleDateNext}
				onDatePrev={handleDatePrev}
				onDateToday={handleDateToday}
			/>
			<FilterBar
				filterEvents={filterEvents}
				itemValue={planboardItemFilter.value}
				organisationValue={planboardOrganisationFilter.valueId}
				searchEvents={search.events}
				searchValue={search.value}
			/>

			{resourceListEmpty ?
				resourceListEmpty
			: (
				isEmptyObject(resources) &&
				(!isObject(bookingsData) || (bookingsLoading && !shouldLazyLoadFetch))
			) ?
				<LoadingState />
			:	<Box className={classes.pageContent}>
					{children}
					<SideDrawer
						date={date}
						onClose={handleAddBookingClose}
						open={openAddOrEditBooking}
						presetData={presetBookingData}
						setPresetBookingData={setPresetBookingData}
					/>
				</Box>
			}
		</Page>
	);
};

const mapStateToProps = (state) => {
	return {
		planboardBookings: state.planboard.planboardBookings,
		addedBooking: state.details.addedBooking,
		updatedBookingDetails: state.condition.updatedBookingDetails,
		canceledBooking: state.condition.canceledBooking,
		stopBooking: state.details.stopBooking,
		updateCooldown: state.condition.updateCooldown,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchPlanboardBookings: (page, filters) =>
			dispatch(actions.fetchPlanboardBookings(page, filters)),
		onPatchUpdateBooking: (bookingId, bodyData) =>
			dispatch(actions.patchUpdateBooking(bookingId, bodyData)),
		onResetStateCondition: (identifier, value) =>
			dispatch(actions.resetStateCondition(identifier, value)),
		onResetState: (state) => dispatch(actions.resetState(state)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarFilterContainer);

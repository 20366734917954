import { useEffect } from 'react';

import { Tabs, Tab, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Navigate, useNavigate, useLocation, useParams } from 'react-router-dom';

import Header from './Header';
import { useStyles } from './style';
import Summary from './Summary';
import { Page } from '../../../../components';
import { isObject } from '../../../../shared/utility';
import * as actions from '../../../../store/actions/index';

interface LocationDetailProps {
	onResetState?(...args: unknown[]): unknown;
	hubDetails?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onFetchHub?(...args: unknown[]): unknown;
}

const LocationDetail = (props: LocationDetailProps) => {
	const { hubDetails, onFetchHub, onResetState } = props;
	const location = useLocation();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const classes = useStyles();

	const { id, tab } = useParams();

	const { data: hubData, loading: hubLoading, error: hubError } = hubDetails;
	const hubReady = isObject(hubData) && !hubLoading && !hubError;

	const handleTabsChange = (event, value) => {
		navigate(`../${value}`, { relative: 'path', state: { ...location.state } });
	};
	useEffect(() => {
		if (!hubLoading) {
			onFetchHub(id);
		}
		return () => {
			onResetState('hubDetails');
		};
	}, [id, onFetchHub]);

	const tabs = [{ value: 'summary', label: t('ui.summary') }];

	if (!tab) {
		return <Navigate to={'/location-management/locations'} />;
	}

	if (!tabs.find((t) => t.value === tab)) {
		return <Navigate to='/errors/error-404' />;
	}

	return (
		<Page className={classes.root} title={t('views.locationDetail.page.title')}>
			<Header hubData={hubData} loading={!hubReady} />
			<Tabs
				className={classes.tabs}
				indicatorColor='primary'
				onChange={handleTabsChange}
				scrollButtons='auto'
				value={tab}
				variant='scrollable'
			>
				{tabs.map((tab) => (
					<Tab key={tab.value} label={tab.label} value={tab.value} />
				))}
			</Tabs>
			<Divider className={classes.divider} />
			<div className={classes.content}>
				{tab === 'summary' && <Summary hub={hubData} loading={hubLoading} />}
			</div>
		</Page>
	);
};

const mapStateToProps = (state) => {
	return {
		hubDetails: state.details.hubDetails,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchHub: (id) => dispatch(actions.fetchHub(id)),
		onResetState: (identifier) => dispatch(actions.resetState(identifier)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationDetail);

import { useEffect } from 'react';

import { Card, CardHeader, CardContent, Typography } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useAuthorize } from '~features/authentication';

import { useStyles } from './style';
import { SearchAutocomplete, SelectedOrganisationCard, FormField } from '../../../../../components';
import { useWizardFormField, useDebouncedWizardSave } from '../../../../../shared/hooks';
import { isEmptyObject, isObject } from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';

interface AddTermsAndConditionsTitleProps {
	className?: string;
	save?(...args: unknown[]): unknown;
	editing?: boolean;
	termsAndConditionsTitle?: string;
	onFetchOrganisations?(...args: unknown[]): unknown;
	organisationsList?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	currentUser?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	fetchedTerm?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const AddTermsAndConditionsTitle = (props: AddTermsAndConditionsTitleProps) => {
	const {
		save,

		organisationsList,
		onFetchOrganisations,
		currentUser,

		termsAndConditionsTitle,
		editing,
		fetchedTerm,
	} = props;
	const { t } = useTranslation();
	const { isAdmin, isSuperAdmin } = useAuthorize();

	const classes = useStyles();

	const { data: currentUserData } = currentUser;

	const { data: fetchedTermData } = fetchedTerm;

	const selectedOrganisation = useWizardFormField({}, { required: true });

	useEffect(() => {
		if (isAdmin(true) && isObject(currentUserData)) {
			save({ selectedOrganisation: { value: currentUserData.organisationReference, valid: true } });
		} else if (editing && isObject(fetchedTermData) && isEmptyObject(selectedOrganisation.value)) {
			selectedOrganisation.setValue({ name: fetchedTermData.organisationReference.name });
			save({ selectedOrganisation: { value: fetchedTermData.organisationReference, valid: true } });
		} else {
			save({
				selectedOrganisation: {
					value: selectedOrganisation.value,
					valid: !isEmptyObject(selectedOrganisation.value),
				},
			});
		}
	}, [
		selectedOrganisation.value,
		selectedOrganisation.isValid,
		isAdmin,
		currentUserData,
		fetchedTerm,
	]);

	const resetSelectedOrganisation = () => {
		selectedOrganisation.onChange({});
	};

	const documentName = useWizardFormField(termsAndConditionsTitle, {
		maxLength: 128,
		minLength: 1,
	});
	useDebouncedWizardSave('title', documentName.value, documentName.isValid, save, 300);

	useEffect(() => {
		save({ title: documentName.value });
	}, [documentName.value]);

	return (
		<Card className={clsx(classes.root, classes.termsAndConditions)}>
			<CardHeader
				title={
					editing ?
						t('views.addTerms.addTermsAndConditionsTitle.editing')
					:	t('ui.category.termsAndConditions')
				}
				titleTypographyProps={{
					variant: 'h3',
				}}
			/>
			<CardContent>
				<form>
					{isSuperAdmin() ? (
						<>
							<div className={classes.formGroup}>
								<Typography gutterBottom={true} variant='h5'>
									{t('views.addTermsAndConditions.subheader.selectOrganisation')}
									<sup>*</sup>
								</Typography>
								{!isEmptyObject(selectedOrganisation.value) ?
									<SelectedOrganisationCard
										handleClose={resetSelectedOrganisation}
										hasCloseButton={editing}
										name={selectedOrganisation.value.name}
									/>
								:	<SearchAutocomplete
										dataList={organisationsList}
										listType={'organisations'}
										onFetchData={onFetchOrganisations}
										placeholder={t('views.addTermsAndConditions.placeholder.searchAutocomplete')}
										setSelected={selectedOrganisation.onChange}
									/>
								}
							</div>
						</>
					) : null}
					<div className={classes.formGroup}>
						<FormField label={t('ui.title')} name={'title'} variable={documentName} />
					</div>
				</form>
			</CardContent>
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		organisationsList: state.paged.organisations,

		currentUser: state.details.currentUser,

		fetchedTerm: state.details.fetchTerm,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchOrganisations: (page, filters, concat) =>
			dispatch(actions.fetchOrganisations(page, filters, concat)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTermsAndConditionsTitle);

import { Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { useLocation } from 'react-router-dom';

import Page from '../page';

/**
 * A simple page from where to login process can be started
 */
const LoginLaunchPage = () => {
	const { i18n, t } = useTranslation();
	const location = useLocation();
	const auth = useAuth();

	const handleLoginClick = () => {
		auth.signinRedirect({ url_state: location.state?.from, extraQueryParams: { culture: i18n.language } });
	};

	return (
		<Page title={t('login')}>
			<Stack spacing={1} justifyContent='center' alignItems='center'>
				<Typography variant='h3'>{t('views.login.title')}</Typography>
				<Typography variant='subtitle2' pb={2}>
					{t('views.login.description')}
				</Typography>
				<Button variant='contained' onClick={handleLoginClick}>
					{t('view.login.button.contained.login')}
				</Button>
			</Stack>
		</Page>
	);
};

export default LoginLaunchPage;

import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ScheduleDetailsLayout } from '~features/access';

import PageNotFoundPage from '../other/pageNotFoundPage';
import Page from '../page';

const AccessScheduleDetailsPage = () => {
	const { t } = useTranslation();
	const { id } = useParams();

	if (!id) {
		return <PageNotFoundPage />;
	}

	return (
		<Page title={`${t('scheduleDetails')} ${id}`}>
			<ScheduleDetailsLayout id={id} />
		</Page>
	);
};

export default AccessScheduleDetailsPage;

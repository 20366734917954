import { useEffect, useState } from 'react';

import {
	Card,
	CardContent,
	Box,
	MenuItem,
	List,
	ListItem,
	Radio,
	TextField,
	Typography,
} from '@mui/material';
import clsx from 'clsx';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { userInfoAtom } from '~atoms';
import { useAuthorize } from '~features/authentication';

import { useStyles } from './style';
import {
	InfoLabel,
	FormField,
	SelectedOrganisationCard,
	SearchAutocomplete,
} from '../../../../../components';
import { useWizardFormField } from '../../../../../shared/hooks';
import {
	isArray,
	isNull,
	isEmptyObject,
	isFullString,
} from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';
import Header from '../Header';

const calculationTypes = {
	FIXED: 'fixed',
	VARIABLE: 'flex',
	FREE: 'free',
};

interface ModelNameProps {
	className?: string;
	organisationsList?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	categories?: {
		data?: unknown[];
		loading?: boolean;
		error?: object | string;
	};
	onFetchCategories?(...args: unknown[]): unknown;
	onFetchOrganisations?(...args: unknown[]): unknown;
	save?(...args: unknown[]): unknown;
	name?: string;
	selectedName?: string;
	selectedOrganisation?: object;
	selectedCategory?: number;
	selectedPricingType?: string;
	editing?: boolean;
}

const ModelName = (props: ModelNameProps) => {
	const {
		className,
		editing,
		onFetchCategories,
		onFetchOrganisations,
		categories,
		organisationsList,
		save,
		selectedName,
		selectedOrganisation: selectOrg,
		selectedCategory,

		selectedPricingType,
	} = props;
	const { t } = useTranslation();
	const { isSuperAdmin } = useAuthorize();
	const classes = useStyles();
	const userInfo = useAtomValue(userInfoAtom);

	const { data: categoriesData, loading: categoriesLoading, error: categoriesError } = categories;
	const categoriesReady = isArray(categoriesData) && !categoriesLoading && !categoriesError;

	const name = useWizardFormField(selectedName, { required: true, maxLength: 25 });

	const selectedOrganisation = useWizardFormField({}, { required: true });

	const [pricingType, setPricingType] = useState(selectedPricingType);

	useEffect(() => {
		if (isNull(categoriesData)) {
			onFetchCategories();
		}
	}, []);

	useEffect(() => {
		save({
			name: {
				value: name.value,
			},
		});
	}, [name]);

	useEffect(() => {
		if (!isSuperAdmin()) {
			save({
				organisation: {
					name: userInfo.organisation.label,
					id: userInfo.organisation.id,
				},
			});
		} else if (!isEmptyObject(selectedOrganisation.value)) {
			save({
				organisation: { name: selectedOrganisation.value.name, id: selectedOrganisation.value.id },
			});
		} else if (editing) {
			selectedOrganisation.setValue({ ...selectOrg });
		}
	}, [selectedOrganisation]);

	useEffect(() => {
		if (isFullString(pricingType)) {
			save({ pricingType });
		}
	}, [pricingType]);

	const handleCategory = (event) => {
		const value = event.target.value;
		save({ category: value });
	};

	const handleCalculationType = (event, item) => {
		setPricingType(item);
	};

	const resetSelectedOrganisation = () => {
		selectedOrganisation.onChange({});
	};

	return (
		<>
			<Header editing={editing} />
			<Card className={clsx(classes.root, className)}>
				<CardContent>
					<form>
						<InfoLabel
							info={t('views.addPricing.modelName.info')}
							name={t('views.addPricing.modelName.infoName')}
							paddingBottom={4.5}
							paddingTop={1.5}
							variant='h4'
						/>
						<FormField
							label={t('ui.label.name')}
							maxLength={25}
							name='name'
							variable={name}
							// isExtraValid={shouldValidateName ? checkedName.isUnique : true}
							// extraValidHelperText={isSuperAdmin() && isEmptyObject(selectedOrganisation.value) ? t('views.addPolicy.error.nameAlreadyInSystem') : t('views.addPolicy.error.nameAlreadyInUse')}
						/>
						{isSuperAdmin() ?
							<Box pt={4.5}>
								<div className={classes.formGroup}>
									{!isEmptyObject(selectedOrganisation.value) ?
										<SelectedOrganisationCard
											handleClose={resetSelectedOrganisation}
											hasCloseButton={editing}
											name={selectedOrganisation.value.name}
										/>
									:	<SearchAutocomplete
											// placeholder={t('views.addTermsAndConditions.placeholder.searchAutocomplete')}
											dataList={organisationsList}
											label={t('ui.organisation')}
											listType={'organisations'}
											onFetchData={onFetchOrganisations}
											setSelected={selectedOrganisation.onChange}
										/>
									}
								</div>
							</Box>
						:	null}
						<Box pt={4.5}>
							<TextField
								InputLabelProps={{ shrink: true }}
								SelectProps={{ value: selectedCategory || '' }}
								disabled={editing}
								fullWidth
								label={t('ui.label.category')}
								onChange={handleCategory}
								select
								variant='outlined'
							>
								{categoriesReady ?
									categoriesData.map((item) => (
										<MenuItem key={item.id} value={item.id}>
											{item.name}
										</MenuItem>
									))
								:	<MenuItem />}
							</TextField>
						</Box>
						<Box pl={1} pt={4.5}>
							<Typography variant='h5'>{t('ui.addPricing.pricingModelType')}</Typography>
							<List>
								{[calculationTypes.FIXED, calculationTypes.VARIABLE, calculationTypes.FREE].map(
									(item) => (
										<ListItem
											button={true}
											className={clsx(classes.option, {
												[classes.selectedOption]: pricingType === item,
											})}
											disabled={editing}
											key={item}
											onClick={(event) => handleCalculationType(event, item)}
										>
											<Radio
												checked={pricingType === item}
												className={classes.optionRadio}
												onChange={(event) => handleCalculationType(event, item)}
												value={item}
											/>
											<div className={classes.optionDetails}>
												<Typography gutterBottom variant='h5'>
													{t(`ui.label.${item}Pricing`)}
												</Typography>
												<Typography variant='body1'>
													{t(`views.addPricing.${item}PricingDescription`)}
												</Typography>
											</div>
										</ListItem>
									),
								)}
							</List>
						</Box>
					</form>
				</CardContent>
			</Card>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		categories: state.list.categories,
		organisationsList: state.paged.organisations,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchCategories: (sortBy, orderDescending, name) =>
			dispatch(actions.fetchCategories(sortBy, orderDescending, name)),
		onFetchOrganisations: (page, filters, concat) =>
			dispatch(actions.fetchOrganisations(page, filters, concat)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModelName);

import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Divider,
	List,
	ListItem,
	ListItemText,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import SkcDeviceGroupsService from '../../services/skcDeviceGroupsService';

interface AccessRuleGeneralInfoCardProps {
	id: string;
}

const service = new SkcDeviceGroupsService();

const AccessDevicesCard = ({ id }: AccessRuleGeneralInfoCardProps) => {
	const { t } = useTranslation();

	const { data, isLoading, error } = useSWR([service.basePath, id], ([_, args]) =>
		service.getDeviceGroupById(args),
	);

	return (
		<Card sx={{ height: 1 }}>
			<CardHeader title={t('ui.label.devices')} />
			<Divider />
			<CardContent>
				{data?.devices != null && data?.devices.length > 0 ?
					<List disablePadding>
						{data.devices.map((el, i, arr) => (
							<ListItem key={i} divider={i < arr.length - 1} disablePadding>
								<ListItemText primary={el.skopeiNumber} secondary={el.hardwareId} />
							</ListItem>
						))}
					</List>
				:	<NoContentOverlay label={t('noResults')} />}
			</CardContent>
		</Card>
	);
};

const NoContentOverlay = ({ label }: { label: string }) => (
	<Box>
		<Typography variant='body2'>{label}</Typography>
	</Box>
);

export default AccessDevicesCard;

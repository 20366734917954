/**
 * It is debatable if this is a good practice, but override the default locale converting
 * to use a default locales
 * https://stackoverflow.com/questions/33658829/how-to-override-javascripts-date-object
 */

const defaultLocales = 'nl-NL';

if (import.meta.env.DEV) {
	// Remind ourselfs that we overwrite the default function. Can possibly create very annoying
	// bugs
	console.debug(`Overriding date prototype functions by settings default ${defaultLocales} locales`);
}

Date.prototype.toLocaleDateStringDefault = Date.prototype.toLocaleDateString;
Date.prototype.toLocaleDateString = function (
	locales: Intl.LocalesArgument = defaultLocales,
	options?: Intl.DateTimeFormatOptions,
): string {
	return this.toLocaleDateStringDefault(locales, options);
};

Date.prototype.toLocaleStringDefault = Date.prototype.toLocaleString;
Date.prototype.toLocaleString = function (
	locales: Intl.LocalesArgument = defaultLocales,
	options?: Intl.DateTimeFormatOptions,
): string {
	return this.toLocaleStringDefault(locales, options);
};

Date.prototype.toLocaleTimeStringDefault = Date.prototype.toLocaleTimeString;
Date.prototype.toLocaleTimeString = function (
	locales: Intl.LocalesArgument = defaultLocales,
	options?: Intl.DateTimeFormatOptions,
): string {
	return this.toLocaleTimeStringDefault(locales, options);
};

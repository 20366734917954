import { useState } from 'react';

import { Grid } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import useSWRMutation from 'swr/mutation';

import { ConfirmationDialog, FormDialog } from '~components';
import { useAuthorize } from '~features/authentication';
import { BillingCard, PaymentMethodsService, RemovePaymentMethodForm } from '~features/finances';
import { NfcGeneralInfoCard, NfcTag, NfcTagsService } from '~features/nfc';
import {
	LinkNfcTagToUserForm,
	UserGeneralInfoCard,
	UserOrganisationInfoCard,
} from '~features/users';
import { useSnackbar } from '~hooks';

import CustomerEdit from './CustomerInformation/CustomerEdit';
import LicenseDetails from './LicenseDetails';
import OtherActions from './OtherActions';
import { useStyles } from './style';
import { isFullString } from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';
import SummaryLoading from '../../../../OrganisationManagement/Details/OrganisationDetails/Summary/SummaryLoading/SummaryLoading';
import useSWR from 'swr';

type UserAction =
	| {
			type: 'linkNfc' | 'removePaymentMethod';
			id?: never;
	  }
	| {
			type: 'unlinkNfc';
			id: string;
	  };

const paymentMethodsService = new PaymentMethodsService();
const nfcTagsService = new NfcTagsService();

interface SummaryProps {
	className?: string;
	customer?: object;
	paymentMethod?: object;
	license?: object;
	loading?: boolean;
	userRoleData?: string;
	isInternalUser?: boolean;
	NFCTags?: any;
}

const Summary = (props: SummaryProps) => {
	const {
		className,
		customer,
		paymentMethod,
		loading,
		userRole,
		isInternalUser,
		license,
		NFCTags,
		onFetchUser,
	} = props;
	const classes = useStyles();
	const { i18n, t } = useTranslation();
	const { enqueueSuccessSnackbar, enqueueAxiosErrorSnackbar } = useSnackbar();
	const { isSuperAdmin } = useAuthorize();

	const [userAction, setUserAction] = useState<UserAction | null>(null);

	const gridProps = { item: true, xs: 12, md: 6, lg: 6, xl: 4, flexGrow: 1 };

	const [editUserDialogOpen, setEditUserDialogOpen] = useState(false);

	const userProps = {
		customer,
		isSuperAdmin: isSuperAdmin(),
		isInternalUser,
		NFCTags,
	};

	// Use this temporary just to mutate the nfc by id
	const nfcId =
		userProps.NFCTags && userProps.NFCTags.length > 0 ? userProps.NFCTags[0].nfcId : undefined;

	const { data: paymentMethodData, mutate: mutatePaymentMethod } = useSWR(
		[paymentMethodsService.basePath, customer?.id],
		([_, userId]) => paymentMethodsService.getPersonalPaymentMethod({ userId: userId })
	)

	const { trigger: triggerLinkNfcTag, isMutating: isLinkNfcTagMutating } = useSWRMutation(
		customer?.id ? [nfcTagsService.basePath, customer.id.toString()] : null,
		([_, userId], { arg }: { arg: NfcTag }) => {
			const { id: nfcTagId, ...rest } = arg;

			return nfcTagsService.updateNfcTag(nfcTagId, { ...rest, user: { id: userId } });
		},
		{
			onSuccess: () => {
				enqueueSuccessSnackbar(t('linked'));
				setUserAction(null);
				onFetchUser(customer.id);
			},
			onError: (error) => enqueueAxiosErrorSnackbar(error),
		}
	);

	const { trigger: triggerUnlinkNfcTag, isMutating: isUnlinkNfcTagMutating } = useSWRMutation(
		nfcTagsService.basePath,
		(_, { arg }: { arg: string }) => nfcTagsService.unlinkNfcTag(arg),
		{
			onSuccess: () => {
				enqueueSuccessSnackbar(t('views.unlinkDialog.success.message'));
				setUserAction(null);
				onFetchUser(customer.id);
			},
			onError: (error) => enqueueAxiosErrorSnackbar(error),
		}
	);

	const { trigger: triggerRemovePaymentMethod, isMutating: isRemovePaymentMethodMutating } =
		useSWRMutation(
			customer?.id ? [paymentMethodsService.basePath, customer.id.toString()] : undefined,
			([_, id], { arg }: { arg: { message: string } }) =>
				paymentMethodsService.removePaymentMethod(id, arg),
			{
				onSuccess: () => {
					enqueueSuccessSnackbar(
						t('resourceRemoved', {
							resource: t('views.bookingDetail.invoicesBiling.paymentMethod'),
						})
					);
					setUserAction(null);
					mutatePaymentMethod();
				},
				onError: (error) => enqueueAxiosErrorSnackbar(error),
			}
		);

	return (
			customer &&
				((isInternalUser ? isFullString(userRole) : true) || customer?.isAnonymous) &&
				!loading
		) ?
			<>
				<Grid className={clsx(classes.root, className)} container spacing={3} display='flex'>
					<Grid {...gridProps}>
						<UserGeneralInfoCard
							userId={customer.id?.toString()}
							onEditClick={() => setEditUserDialogOpen((prev) => !prev)}
						/>
					</Grid>
					<Grid {...gridProps}>
						<UserOrganisationInfoCard userId={customer.id?.toString()} />
					</Grid>
					{isSuperAdmin() ?
						<>
							<Grid {...gridProps}>
								<BillingCard
									value={{
										// ...paymentMethod,
										...paymentMethodData,
										address: customer.businessAddress ?? customer.address,
										companyName: customer.companyName,
									}}
									onRemovePaymentMethod={() => setUserAction({ type: 'removePaymentMethod' })}
								/>
							</Grid>
							<Grid {...gridProps}>
								<LicenseDetails {...userProps} license={license} />
							</Grid>
						</>
					:	null}
					{isSuperAdmin() || (!customer?.isAnonymous && isInternalUser) ?
						<Grid {...gridProps}>
							<OtherActions {...userProps} userRole={userRole} />
						</Grid>
					:	null}
					<Grid {...gridProps}>
						<NfcGeneralInfoCard
							id={nfcId}
							organisationId={customer.organisationReference.id.toString()}
							onLinkClick={() => setUserAction({ type: 'linkNfc' })}
							onUnlinkClick={(nfcId) => setUserAction({ type: 'unlinkNfc', id: nfcId })}
						/>
					</Grid>
				</Grid>
				<CustomerEdit
					customer={customer}
					onClose={() => setEditUserDialogOpen(false)}
					open={editUserDialogOpen}
				/>
				<FormDialog
					title={t('linkResource', { resource: t('nfcTag'), formatParams: { ignore: true } })}
					open={userAction?.type === 'linkNfc'}
					loading={isLinkNfcTagMutating}
					onClose={() => setUserAction(null)}
					saveLabel={t('ui.confirm')}
					fullWidth
					maxWidth='sm'
				>
					<LinkNfcTagToUserForm
						userId={customer.id?.toString()}
						onSubmit={async (data) => await triggerLinkNfcTag(data.nfcTag)}
					/>
				</FormDialog>

				<FormDialog
					title={t('removeResource', { resource: t('ui.label.paymentMethod') })}
					open={userAction?.type === 'removePaymentMethod'}
					loading={isRemovePaymentMethodMutating}
					onClose={() => setUserAction(null)}
					saveLabel={t('ui.confirm')}
					fullWidth
					variant='legacy'
					maxWidth='sm'
				>
					<RemovePaymentMethodForm
						value={{
							// language: i18n.resolvedLanguage,
							language: customer?.preferences.preferredCultureInfo.substring(0, 2),
						}}
						onSubmit={(value) => triggerRemovePaymentMethod({ message: value.message })}
					/>
				</FormDialog>

				<ConfirmationDialog
					open={userAction?.type === 'unlinkNfc'}
					title={t('views.usersAndOrganisations.nfcUnlink.title')}
					subTitle={`${customer.name || ''} ${t('views.usersAndOrganisations.nfcUnlink.description')}`}
					loading={isUnlinkNfcTagMutating}
					onConfirm={async () => await triggerUnlinkNfcTag(userAction?.id)}
					onClose={() => setUserAction(null)}
				/>
			</>
		:	<SummaryLoading />;
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchUser: (id) => dispatch(actions.fetchUser(id)),
	};
};

export default connect(null, mapDispatchToProps)(Summary);

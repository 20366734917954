import { useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Card, CardContent, Typography, IconButton, Avatar } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getInitials } from '~utils/stringUtils';

import { LoadingBar } from '../../../../components';
import { isObject, isNull } from '../../../../shared/utility';
import * as actions from '../../../../store/actions';
import { Search } from '../../../Actions';
import { useStyles } from '../style';

interface UserCardProps {
	open?: boolean;
	setPolicies?(...args: unknown[]): unknown;
	bookingData?: object;
	isDoubleBookingInCategoryError?: boolean;
	onUpdateListState?(...args: unknown[]): unknown;
	onFetchUserImage?(...args: unknown[]): unknown;
	currentUserData?: object;
	onResetState?(...args: unknown[]): unknown;
	fetchUnavailabilityData?: object;
	resetAvailability?(...args: unknown[]): unknown;
	selectedItem?: object;
	className?: any;
	presetData?: object;
	selectedUser?: object;
	setSelectedUser?(...args: unknown[]): unknown;
	selectType?: string;
	onSearchUser?(...args: unknown[]): unknown;
	onResetListState?(...args: unknown[]): unknown;
	fetchUserImage?: {
		data?: unknown[];
		loading?: boolean;
		error?: object | string;
	};
	searchUser?: {
		data?: unknown[];
		loading?: boolean;
		error?: object | string;
	};
}

const UserCard = (props: UserCardProps) => {
	const {
		className,
		presetData,
		selectedUser,
		setSelectedUser,
		fetchUserImage,
		onFetchUserImage,
		onUpdateListState,

		isDoubleBookingInCategoryError,
		bookingData,
		currentUserData,
		selectType,
		onSearchUser,
		onResetListState,
		fetchUnavailabilityData,
		onResetState,
		setPolicies,
		resetAvailability,
		searchUser,
		selectedItem,
	} = props;
	const { t } = useTranslation();

	const classes = useStyles();

	const { data: userImageData, loading: userImageLoading } = fetchUserImage;

	const filters = {
		requestedResources: 10,
		hideAnonymous: true,
		...(selectedItem?.instanceId && { instanceId: selectedItem?.instanceId }),
	};

	useEffect(() => {
		if ((!userImageLoading && isObject(selectedUser)) || isObject(fetchUnavailabilityData)) {
			if (selectedUser?.id) {
				onFetchUserImage(selectedUser.id);
			}
		}
	}, [selectedUser, fetchUnavailabilityData]);

	useEffect(() => {
		if (
			selectType === 'maintenance' &&
			isObject(currentUserData) &&
			!isObject(fetchUnavailabilityData)
		) {
			setSelectedUser({
				id: currentUserData.id,
				firstName: currentUserData.firstName,
				lastName: currentUserData.lastName,
				email: currentUserData.emailAddress,
				clear: false,
			});
		} else if (isObject(fetchUnavailabilityData)) {
			setSelectedUser({
				id: fetchUnavailabilityData.createdByUser.id,
				firstName: fetchUnavailabilityData.createdByUser.firstName,
				lastName: fetchUnavailabilityData.createdByUser.lastName,
				email: fetchUnavailabilityData.createdByUser.emailAddress,
				clear: false,
			});
		} else {
			setSelectedUser(null);
			onResetState('fetchItemPolicies');
		}
	}, [selectType, fetchUnavailabilityData]);

	const handleClearUser = () => {
		setSelectedUser(undefined);
		onUpdateListState('fetchUserImage', null);
		resetAvailability();
		setPolicies(null);
	};

	const searchSelect = (value) => {
		setSelectedUser({
			id: value.id,
			name: value.name,
			email: value.email,
			clear: true,
		});
	};

	const restState = () =>
		onResetListState('searchUser', { data: null, loading: false, error: null });

	const handleRenderOption = (props, option) => (
		<li {...props} key={option.key}>
			<Avatar
				alt='User'
				className={classes.avatar}
				src={option?.imagesReference && option.imagesReference[0]}
			>
				{getInitials(option.name)}
			</Avatar>
			<Box disabled={true} display='flex' flexDirection='column'>
				<Typography variant='h6'>{option.optionsName}</Typography>
				<Typography variant='body2'>{option.address}</Typography>
			</Box>
		</li>
	);

	const showUserCard =
		(presetData?.bookingId && (isObject(selectedUser) || isNull(selectedUser))) ||
		(isObject(presetData) && isObject(selectedUser)) ||
		isObject(selectedUser);

	const loading = !isObject(selectedUser);

	const searchProps = {
		renderOption: handleRenderOption,
		fetchData: onSearchUser,
		filters,

		searchSelect: searchSelect,
		data: searchUser,
		restState,
		noOptions: t('views.planboard.noOptions.user'),
	};

	return (
		<Box>
			<Typography className={classes.cardHeaders} variant='h5'>
				{selectType === 'maintenance' ? t('ui.label.unavailabilityFor') : t('ui.label.bookFor')}
			</Typography>
			{showUserCard ?
				<>
					<Card className={clsx(classes.userCard, className)}>
						<CardContent className={classes.content}>
							<Box className={classes.avatarContainer}>
								{userImageData && userImageData[0] ?
									<Avatar src={userImageData[0]} />
								:	<Avatar>
										{selectedUser ?
											getInitials(
												selectedUser.name ?
													selectedUser.name
												:	`${selectedUser.firstName} ${selectedUser.lastName}`,
											)
										:	null}
									</Avatar>
								}
							</Box>
							<Box>
								<Typography variant='h5'>
									{loading ?
										<LoadingBar />
									: selectedUser?.name ?
										selectedUser?.name
									:	`${selectedUser.firstName} ${selectedUser.lastName}`}
								</Typography>
								<Typography className={classes.body3}>
									{loading ?
										<LoadingBar />
									:	selectedUser.email}
								</Typography>
							</Box>
						</CardContent>
						{selectedUser?.clear ?
							<IconButton className={classes.closeButton} onClick={handleClearUser} size='small'>
								<CloseIcon />
							</IconButton>
						:	<div></div>}
					</Card>
					{(
						isDoubleBookingInCategoryError &&
						selectType === 'booking' &&
						!(
							isObject(bookingData) &&
							bookingData.status !== 'upcoming' &&
							bookingData.status !== 'active'
						)
					) ?
						<Typography className={clsx(classes.helperText, classes.errorText)}>
							{t('views.planboard.addBooking.error.message.doubleCategoryBooking')}
						</Typography>
					:	null}
				</>
			:	<Search {...searchProps} />}
		</Box>
	);
};

const mapStateToProps = (state) => {
	return {
		fetchUserImage: state.list.fetchUserImage,

		searchUser: state.list.searchUser,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchUserImage: (userId) => dispatch(actions.fetchUserImage(userId)),
		onUpdateListState: (state, data) => dispatch(actions.updateListState(state, data)),
		onResetState: (state) => dispatch(actions.resetState(state)),
		onSearchUser: (filters) => dispatch(actions.searchUser(filters)),
		onResetListState: (state, data) => dispatch(actions.updateListState(state, data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserCard);

import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { InfoCard, InfoCardRowDef, WeekToggleButtonGroup } from '~components';
import { WeeklySchedule } from '~interfaces';
import { time24WithoutSecondsFormatter } from '~utils/dateUtils';

import SkcSchedulesService from '../../services/skcSchedulesService';
import WeekdayEnum from '~enums/dayOfWeekEnum';

interface ScheduleGeneralInfoCardProps {
	id: string;
}

const service = new SkcSchedulesService();

const ScheduleGeneralInfoCard = ({ id }: ScheduleGeneralInfoCardProps) => {
	const { t } = useTranslation();

	const { data, isLoading, error } = useSWR([service.basePath, id], ([_, args]) =>
		service.getScheduleById(args),
	);

	const rows: InfoCardRowDef[] | undefined = data && [
		{
			headerName: 'ID',
			value: data.id,
		},
		{
			headerName: t('ui.label.description'),
			value: data.description,
		},
		{
			headerName: t('ui.label.open'),
			value: data.schedule,
			valueFormatter: (value: WeeklySchedule) =>
				value.isOpenAllDay ?
					t('ui.allDay')
				:	`${time24WithoutSecondsFormatter.format(value.period.start)} - ${time24WithoutSecondsFormatter.format(value.period.end)}`,
		},
		{
			headerName: t('schedule'),
			value: data.schedule.daysOfWeek,
			renderCell: (value: WeekdayEnum[]) => (
				<WeekToggleButtonGroup disabled size='small' value={value} />
			),
		},
	];

	return (
		<InfoCard
			title={`${t('schedule')} ${t('info').toLowerCase()}`}
			version='new'
			rows={rows ?? []}
			loading={isLoading}
			error={error != null}
		/>
	);
};

export default ScheduleGeneralInfoCard;

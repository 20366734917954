export const capitalizeFirstCharacter = (value: string) =>
	`${value.slice(0, 1).toUpperCase()}${value.slice(1)}`;

export const decapitalizeFirstCharacter = (value: string) =>
	`${value.slice(0, 1).toLowerCase()}${value.slice(1)}`;

export const getInitials = (label: string = ''): string => {
	if (label == null) {
		return '';
	}

	return label
		.replace(/\s+/, ' ')
		.split(' ')
		.slice(0, 2)
		.map((value) => value &&value[0].toUpperCase())
		.join('');
};

import { Box, Stack, Typography } from '@mui/material';

import EmptyListIcon from '~components/elements/EmptyState/assets/booking.svg?react';
import i18n from '~lib/i18n';

interface ErrorFallbackProps {
	label?: string;
}

// TODO: maybe not the good place. Should be in components?
const ErrorFallback = ({ label = i18n.t('somethingWentWrong') }: ErrorFallbackProps) => {
	return (
		<Stack
			width={1}
			height={1}
			display='flex'
			alignItems='center'
			justifyContent='center'
			direction='column'
			spacing={1}
		>
			<EmptyListIcon />
			<Typography variant='h6'>{label}</Typography>
		</Stack>
	);
};

export default ErrorFallback;

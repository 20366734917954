import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import { Grid, Typography, Hidden } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { AdjustedSkeleton, StyledButton } from '../../../../../components';
import { useDetailPageBackButton } from '../../../../../shared/hooks';
import { isUndefined } from '../../../../../shared/utility';

const PREFIX = 'Header';

const classes = {
	root: `${PREFIX}-root`,
	buttonSpacing: `${PREFIX}-buttonSpacing`,
};

const Root = styled('div')(() => ({
	[`& .${classes.root}`]: {},

	[`& .${classes.buttonSpacing}`]: {
		maxHeight: '56px',
	},
}));

export {};

interface HeaderProps {
	className?: string;
	buttonPlaceholder?: string;
	buttonDisabled?: boolean;
	action?(...args: unknown[]): unknown;
	href?: string;
	itemData?: object;
	loading?: boolean;
	isOwnItem?: boolean;
}

const Header = (props: HeaderProps) => {
	const {
		className,
		buttonPlaceholder,
		buttonDisabled,
		href,
		action,
		itemData,
		loading,
		isOwnItem,
	} = props;
	const { t } = useTranslation();

	const location = useLocation();
	const navigate = useNavigate();

	const returnButton = useDetailPageBackButton(location);

	const handleBackButtonChange = () => navigate('/item-management/items');

	const handleBackButton = () => {
		if (returnButton.isBackButton || !location.state?.label) {
			return t('ui.button.inline.back');
		} else {
			return location.state.label;
		}
	};

	return (
		<Root className={className}>
			<Grid alignItems='flex-end' container justifyContent='space-between' spacing={3}>
				<Grid item>
					<Hidden only={['xs', 'sm']}>
						<StyledButton
							className={classes.backButton}
							disabled={buttonDisabled}
							onClick={handleBackButtonChange}
							startIcon={<KeyboardArrowLeftOutlinedIcon />}
							variant='inline-default'
						>
							{handleBackButton()}
						</StyledButton>
					</Hidden>
					<Typography component='h1' variant='h2'>
						{loading ?
							<AdjustedSkeleton animation='wave' width={200} />
						:	itemData.name}
					</Typography>
				</Grid>
				<Grid className={classes.buttonSpacing} item>
					{isUndefined(buttonPlaceholder) || !isOwnItem ? null : (
						<StyledButton
							disabled={buttonDisabled}
							href={href}
							onClick={action}
							variant='contained-primary'
						>
							{buttonPlaceholder}
						</StyledButton>
					)}
				</Grid>
			</Grid>
		</Root>
	);
};

export default Header;

import { useState, useEffect } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Link, Box, Avatar, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { getInitials } from '~utils/stringUtils';

import { useStyles } from './style';
import {
	SearchBar,
	Table,
	AlertDialog,
	EmptyState,
	SubscriptionSelectField,
	FormField,
	StyledButton,
} from '../../../components';
import { useSearchComponent, useWizardFormField, usePagination } from '../../../shared/hooks';
import { isObject, isEmptyString, isEmptyArray } from '../../../shared/utility';
import * as actions from '../../../store/actions';

interface IncomingRequestsProps {
	onSetFilter(...args: unknown[]): unknown;
	onFetchOrganisations?(...args: unknown[]): unknown;
	organisationsList?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	verifyOrganisation?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onVerifyOrganisation?(...args: unknown[]): unknown;
	rejectOrganisation?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	patchedOrganisation?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onRejectOrganisation?(...args: unknown[]): unknown;
	onPatchUpdateOrganisation?(...args: unknown[]): unknown;
}

const IncomingRequests = (props: IncomingRequestsProps) => {
	const {
		onFetchOrganisations,
		onPatchUpdateOrganisation,
		patchedOrganisation,
		organisationsList,
		onVerifyOrganisation,
		verifyOrganisation,
		onRejectOrganisation,
		rejectOrganisation,
	} = props;
	const { t } = useTranslation();
	const location = useLocation();

	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();

	const [shouldSearch, setShouldSearch] = useState(false);

	const [sortingCategory, setSortingCategory] = useState('id');
	const [order, setOrder] = useState('asc');
	const [orderDescending, setOrderDescending] = useState(false);
	const [shouldSort, setShouldSort] = useState(false);

	const [filters] = useState('unverified');

	const [shouldFilter, setShouldFilter] = useState(false);

	const [openDialog, setOpenDialog] = useState(false);
	const [orgId, setOrgId] = useState({ organisation: null, action: '' });
	const subscription = useWizardFormField('', { required: true });
	const rejectionReason = useWizardFormField('', { required: true });

	const {
		data: organisationsData,
		loading: organisationsLoading,
		error: organisationsError,
	} = organisationsList;
	const organisationsReady =
		isObject(organisationsData) && !organisationsLoading && !organisationsError;

	const {
		data: verifyOrganisationData,
		loading: verifyOrganisationLoading,
		error: verifyOrganisationError,
	} = verifyOrganisation;
	const verifyOrganisationDone =
		isObject(verifyOrganisationData) && !verifyOrganisationLoading && !verifyOrganisationError;
	const [verifyingOrganisation, setVerifyingOrganisation] = useState(false);

	const {
		data: rejectOrganisationData,
		loading: rejectOrganisationLoading,
		error: rejectOrganisationError,
	} = rejectOrganisation;
	const rejectOrganisationDone =
		isObject(rejectOrganisationData) && !rejectOrganisationLoading && !rejectOrganisationError;
	const [rejectingOrganisation, setRejectingOrganisation] = useState(false);

	const [patchingOrganisation, setPatchingOrganisation] = useState(false);
	const {
		data: patchedOrgData,
		loading: patchedOrgLoading,
		error: patchedOrgError,
	} = patchedOrganisation;
	const patchOrganisationDone = isObject(patchedOrgData) && !patchedOrgLoading && !patchedOrgError;

	const [shouldDoInitialFetch, setShouldDoInitialFetch] = useState(true);
	const [showingInitialResults, setShowingInitialResults] = useState(true);

	const pagination = usePagination('pageNumberIncomingRequests', 'pageSizeIncomingRequests');

	const search = useSearchComponent(
		pagination.setPageNumber,
		setShouldSearch,
		'incomingOrganisationSearch',
	);

	/* * * * * * *
	 * GET DATA  *
	 * * * * * * */
	const orgFilters = {
		...(!isEmptyString(search.value) && { searchTerm: search.value }),
		orderDescending,
		filters: filters,
	};

	useEffect(() => {
		if (shouldSearch || pagination.fetch || shouldSort || shouldFilter || shouldDoInitialFetch) {
			if ((shouldFilter || shouldSearch || shouldSort) && showingInitialResults) {
				setShowingInitialResults(false);
			}
			onFetchOrganisations(pagination.page, orgFilters);
		}

		if (shouldSearch) {
			setShouldSearch(false);
		} else if (shouldFilter) {
			setShouldFilter(false);
		} else if (shouldSort) {
			setShouldSort(false);
		} else if (shouldDoInitialFetch) {
			setShouldDoInitialFetch(false);
		} else if (pagination.fetch) {
			pagination.setFatch(false);
		}
	}, [pagination.fetch, shouldSearch, shouldFilter, shouldSort, shouldDoInitialFetch]);

	useEffect(() => {
		return () => {
			setShouldDoInitialFetch(true);
			setShowingInitialResults(true);
		};
	}, [setShouldDoInitialFetch, setShowingInitialResults]);

	/* * * * * * * * *
	 * NOTIFICATIONS *
	 * * * * * * * * */
	useEffect(() => {
		if (patchingOrganisation && !patchedOrgLoading && !!patchedOrgError) {
			setPatchingOrganisation(false);
			enqueueSnackbar(isObject(patchedOrgError) ? patchedOrgError.message : patchedOrgError, {
				variant: 'error',
			});
		}
	}, [patchedOrgError, patchingOrganisation, patchedOrgLoading]);

	useEffect(() => {
		if (verifyingOrganisation && verifyOrganisationDone) {
			setVerifyingOrganisation(false);
			setOpenDialog(false);
			setShouldSearch(true);
			pagination.setPageNumber(1);
			enqueueSnackbar(
				`${t('views.organisations.messages.success.accept')} ${verifyOrganisationData.name}`,
				{ variant: 'success' },
			);
		}
	}, [verifyOrganisationDone, verifyingOrganisation]);

	useEffect(() => {
		if (verifyingOrganisation && !verifyOrganisationLoading && !!verifyOrganisationError) {
			setVerifyingOrganisation(false);
			setOpenDialog(false);
			enqueueSnackbar(
				isObject(verifyOrganisationError) ?
					verifyOrganisationError.message
				:	verifyOrganisationError,
				{ variant: 'error' },
			);
		}
	}, [verifyOrganisationError, verifyingOrganisation, verifyOrganisationLoading]);

	useEffect(() => {
		if (rejectingOrganisation && rejectOrganisationDone) {
			setRejectingOrganisation(false);
			setOpenDialog(false);
			setShouldSearch(true);
			pagination.setPageNumber(1);
			enqueueSnackbar(
				`${t('views.organisations.messages.success.decline')} ${rejectOrganisationData.name}`,
			);
		}
	}, [rejectOrganisationDone, rejectingOrganisation]);

	useEffect(() => {
		if (rejectingOrganisation && !!rejectOrganisationError) {
			setRejectingOrganisation(false);
			setOpenDialog(false);
			enqueueSnackbar(
				isObject(rejectOrganisationError) ?
					rejectOrganisationError.message
				:	rejectOrganisationError,
				{ variant: 'error' },
			);
		}
	}, [rejectOrganisationError, rejectingOrganisation]);

	/* * * * * *
	 * SORTING *
	 * * * * * */
	const handleRequestSort = (property) => {
		const isDesc = sortingCategory === property && order === 'desc';
		setOrder(isDesc ? 'asc' : 'desc');
		setOrderDescending(isDesc);
		setSortingCategory(property);
		setShouldSort(true);
	};

	const handleAccept = (orgData) => {
		setOpenDialog(true);
		setOrgId(orgData);
		if (orgData.organisation.subscription) {
			subscription.onChange(`${orgData.organisation.subscription.id}`);
		}
	};

	const handleDecline = (orgData) => {
		setOpenDialog(true);
		setOrgId(orgData);
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
	};

	const handleConfirmDialog = () => {
		if (orgId.action === 'decline') {
			onRejectOrganisation(orgId.organisation.id, rejectionReason.value);
			setRejectingOrganisation(true);
		}
		if (orgId.action === 'accept') {
			if (
				!(
					orgId.organisation.subscription &&
					`${orgId.organisation.subscription.id}` === subscription.value
				)
			) {
				onPatchUpdateOrganisation(orgId.organisation.id, { subscriptionId: subscription.value });
				setPatchingOrganisation(true);
			} else {
				onVerifyOrganisation(orgId.organisation.id);
				setVerifyingOrganisation(true);
			}
		}
	};

	// verify organisation if patching organisation and if this is done.
	useEffect(() => {
		if (patchingOrganisation && patchOrganisationDone) {
			setPatchingOrganisation(false);
			onVerifyOrganisation(orgId.organisation.id);
			setVerifyingOrganisation(true);
		}
	}, [patchingOrganisation, patchOrganisationDone]);

	const handleClearFilters = () => {
		search.events.onClear();
		pagination.resetPagination();
	};

	const clearFilters = {
		clear: pagination.pageNumber !== 1 || pagination.pageSize !== 10,
		btnText: 'ui.button.inline.clearfilters',
		action: handleClearFilters,
	};

	const tableHeader = [
		{ name: 'logo', className: classes.iconCell },
		{ name: 'name', content: t('ui.label.name') },
		{ name: 'actions' },
	];

	const tableBody =
		organisationsReady ?
			organisationsData.results.map((item) => [
				{
					content:
						item.logo ?
							<Avatar className={classes.avatar} src={item.logo} />
						:	<Avatar>{getInitials(item.name)}</Avatar>,
					className: classes.iconCell,
				},
				{
					content: (
						<div>
							<Link
								className={classes.itemGroup}
								color='inherit'
								component={RouterLink}
								to={`/organisations/${item.id}/summary`}
								state={{ from: location.pathname }}
							>
								{item.name}
							</Link>
						</div>
					),
				},
				{
					content: (
						<>
							<Box display='flex' flexDirection='row-reverse'>
								<Box pl={2.5}>
									<StyledButton
										onClick={() => handleAccept({ organisation: item, action: 'accept' })}
										size='small'
										startIcon={<CheckIcon />}
										variant='contained-secondary'
									>
										{t('ui.button.contained.accept')}
									</StyledButton>
								</Box>
								<StyledButton
									onClick={() => handleDecline({ organisation: item, action: 'decline' })}
									size='small'
									startIcon={<CloseIcon />}
									variant='contained-tertiary'
								>
									{t('ui.button.contained.decline')}
								</StyledButton>
							</Box>
						</>
					),
				},
			])
		:	Array(4)
				.fill(Array(3).fill())
				.map((arr) => arr.map(() => ({ loading: true })));

	/* * * * * * * *
	 * EMPTY STATE *
	 * * * * * * * */
	const incomingRequestsListEmpty =
		(
			isObject(organisationsData) &&
			!organisationsLoading &&
			isEmptyArray(organisationsData.results)
		) ?
			showingInitialResults ?
				<EmptyState
					image={'booking'}
					subTitle={t('views.organisations.incomingRequests.empty.description.zeroInSystem')}
					title={t('views.organisations.incomingRequests.empty.title')}
				/>
			:	<EmptyState
					image={'booking'}
					subTitle={t('views.organisations.incomingRequests.empty.description.zeroMatching')}
					title={t('views.organisations.incomingRequests.empty.title')}
				/>
		:	null;

	const alertDialogTitle =
		orgId.action === 'accept' ? t('views.organisations.dialog.acceptOrganisation.title')
		: orgId.action === 'decline' ? t('views.organisations.dialog.declineOrganisation.title')
		: '';
	const alertDialogContent =
		orgId.action === 'accept' ?
			<Box>
				<Typography variant='body2'>
					{t('views.organisations.dialog.acceptOrganisation.description')}
				</Typography>
				{orgId.organisation ?
					<>
						<Box alignItems='center' className={classes.organisationContainer} display='flex'>
							{orgId.organisation.logo ?
								<Avatar className={classes.avatar} src={orgId.organisation.logo} />
							:	<Avatar>{getInitials(orgId.organisation.name)}</Avatar>}
							<Typography className={classes.alertOrganisationName}>
								{orgId.organisation.name}
							</Typography>
						</Box>
						<Typography className={classes.subtitle} variant='h4'>
							{t('ui.label.subscription')}
						</Typography>
						<SubscriptionSelectField
							className={classes.subscriptionSelect}
							variable={subscription}
						/>
					</>
				:	null}
			</Box>
		:	<Box className={classes.rejectionAlertContent}>
				<Box display='flex' justifyContent='space-between' pb={1}>
					<Typography variant='h5'>{t('ui.label.comment')}*</Typography>
					<Typography className={classes.counterText}>
						{rejectionReason.value.length}/200 {t('ui.characters')}
					</Typography>
				</Box>
				<FormField
					multiline
					name='rejectionReason'
					placeholder={t('ui.placeholder.description')}
					rows={5}
					variable={rejectionReason}
				/>
			</Box>;

	return (
		<div className={classes.root}>
			<AlertDialog
				confirmDisabled={
					!isEmptyString(orgId.action) &&
					(orgId.action === 'accept' ?
						isEmptyString(subscription.value)
					:	isEmptyString(rejectionReason.value))
				}
				dialogContent={alertDialogContent}
				dialogTitle={alertDialogTitle}
				handleClose={handleCloseDialog}
				handleConfirm={handleConfirmDialog}
				loading={patchingOrganisation || verifyingOrganisation || rejectingOrganisation}
				open={openDialog}
				titleVariant='h3'
			/>
			<SearchBar
				clearFilters={clearFilters}
				hideMoreFilters={true}
				placeholder={t('views.usersAndOrganisations.organisations.searchPlaceholder')}
				searchEvents={search.events}
				searchValue={search.value}
			/>
			{incomingRequestsListEmpty ?
				incomingRequestsListEmpty
			:	<Table
					body={tableBody}
					error={isObject(organisationsError) ? organisationsError.message : null}
					handlePageChange={pagination.pageNumberChange}
					handleSorting={handleRequestSort}
					header={tableHeader}
					loading={organisationsLoading}
					order={order}
					orderBy={sortingCategory}
					page={pagination.pageNumber}
					rowsPerPage={pagination.pageSize}
					setRowsPerPage={pagination.pageSizeChange}
					title={t('ui.category.organisations')}
					total={organisationsData ? organisationsData.total : 0}
				/>
			}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		organisationsList: state.paged.organisations,
		verifyOrganisation: state.details.verifyOrganisation,
		rejectOrganisation: state.details.rejectOrganisation,
		patchedOrganisation: state.details.patchedOrganisation,
		filters: state.filters,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchOrganisations: (page, filters) => dispatch(actions.fetchOrganisations(page, filters)),
		onSetFilter: (identifier, value) => dispatch(actions.setFilter(identifier, value)),
		onVerifyOrganisation: (id) => dispatch(actions.verifyOrganisation(id)),
		onRejectOrganisation: (id, reason) => dispatch(actions.rejectOrganisation(id, reason)),
		onPatchUpdateOrganisation: (id, properties) =>
			dispatch(actions.patchUpdateOrganisation(id, properties)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(IncomingRequests);

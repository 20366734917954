import { useState, useEffect } from 'react';

import {
	Modal,
	Card,
	CardContent,
	CardActions,
	Grid,
	Typography,
	TextField,
	MenuItem,
} from '@mui/material';
import clsx from 'clsx';
import { getData } from 'country-list';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useStyles } from './style';
import { CountryAutocomplete, StyledButton } from '../../../../../../../components';
import { useError } from '../../../../../../../shared/hooks';
import {
	isEmptyArray,
	isObject,
	isFullString,
	isEmptyString,
} from '../../../../../../../shared/utility';
import { validateInput } from '../../../../../../../shared/validation';
import * as actions from '../../../../../../../store/actions';

interface CustomerEditProps {
	className?: string;
	customer?: any;
	onClose?(...args: unknown[]): unknown;
	open?: boolean;
	onUpdateUser?(...args: unknown[]): unknown;
	updateUser?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const CustomerEdit = ({
	open = false,
	onClose = () => {},
	customer,
	className,
	onUpdateUser,
	updateUser,
}: CustomerEditProps) => {
	const { t } = useTranslation();

	const classes = useStyles();
	const [formState, setFormState] = useState({
		...customer,
	});

	const updateUserMessage = useError({
		value: updateUser,
		message: `${t('ui.successfully')} ${t('ui.updated')} ${t('ui.user.label')}`,
	});

	useEffect(() => {
		if (customer.id !== formState.id) {
			setFormState({ ...customer });
		}
	}, [customer]);

	const countries = [{ code: '', name: 'Select a country' }, ...getData()];

	useEffect(() => {
		if (isEmptyArray(countries)) {
			getData();
		}
	}, [countries]);

	if (!open) {
		return null;
	}

	const handleEditUser = (event) => {
		const target = event.target;

		event.persist();
		const isAddress =
			target.name === 'street' ||
			target.name === 'number' ||
			target.name === 'additionNumber' ||
			target.name === 'postalCode' ||
			target.name === 'city';
		const updatedProp = {
			[target.name]: target.type === 'checkbox' ? target.checked : target.value,
		};

		setFormState((user) => ({
			...user,
			...(isAddress && { address: { ...user.address, ...updatedProp } }),
			...(!isAddress && updatedProp),
		}));
	};

	const fullAddressRequired =
		(isObject(formState.address) &&
			isFullString(formState.address.street) &&
			isFullString(formState.address.number) &&
			isFullString(formState.address.city) &&
			isFullString(formState.address.postalCode) &&
			isFullString(formState.address.countryCode)) ||
		(isObject(formState.address) &&
			(!isEmptyString(formState.address.street) ||
				!isEmptyString(formState.address.number) ||
				!isEmptyString(formState.address.city) ||
				!isEmptyString(formState.address.postalCode) ||
				!isEmptyString(formState.address.countryCode)));

	const userData = [
		{
			name: 'firstName',
			label: t('ui.label.firstName'),
			error: !validateInput(formState.firstName, { required: true, minLength: 3, maxLength: 25 }),
			value: formState.firstName,
			helperText: t('views.userDetail.edit'),
			required: true,
		},
		{
			name: 'lastName',
			label: t('ui.label.lastName'),
			error: !validateInput(formState.lastName, { required: true, minLength: 3, maxLength: 25 }),
			value: formState.lastName,
			helperText: t('views.userDetail.edit'),
			required: true,
		},
		{
			name: 'phoneNumber',
			label: t('ui.label.phoneNumber'),
			error: !validateInput(formState.phoneNumber || '', {
				required: !customer?.isAnonymous && true,
				minLength: 3,
				maxLength: 25,
			}),
			value: isFullString(formState.phoneNumber) ? formState.phoneNumber : '',
			helperText: t('views.userDetail.edit'),
			required: true,
		},
		{
			name: 'street',
			label: t('ui.label.street'),
			error:
				isEmptyString(isObject(formState.address) ? formState.address.street : '') &&
				fullAddressRequired,
			value: isObject(formState.address) ? formState.address.street : '',
			helperText: t('views.userDetail.addressError'),
			required: fullAddressRequired,
		},
		{
			name: 'number',
			label: t('ui.nummer'),
			error:
				isEmptyString(isObject(formState.address) ? formState.address.number : '') &&
				fullAddressRequired,
			value: isObject(formState.address) ? formState.address.number : '',
			helperText: t('views.userDetail.addressError'),
			required: fullAddressRequired,
		},
		{
			name: 'postalCode',
			label: t('ui.label.postalCode'),
			error:
				isEmptyString(isObject(formState.address) ? formState.address.postalCode : '') &&
				fullAddressRequired,
			value: isObject(formState.address) ? formState.address.postalCode : '',
			helperText: t('views.userDetail.addressError'),
			required: fullAddressRequired,
		},
		{
			name: 'city',
			label: t('ui.label.city'),
			error:
				isEmptyString(isObject(formState.address) ? formState.address.city : '') &&
				fullAddressRequired,
			value: isObject(formState.address) ? formState.address.city : '',
			helperText: t('views.userDetail.addressError'),
			required: fullAddressRequired,
		},
	];
	const countryError =
		isEmptyString(isObject(formState.address) ? formState.address.countryCode : '') &&
		fullAddressRequired;

	const handleCountry = (event) => {
		const target = event.target;
		const updatedProp = { [target.name]: target.value };

		setFormState((user) => ({
			...user,
			address: { ...user.address, ...updatedProp },
		}));
	};

	const handleEdit = () => {
		const address =
			isObject(formState.address) && isFullString(formState.address.countryCode) ?
				formState.address
			:	null;
		onUpdateUser(
			customer.id,
			formState.firstName,
			formState.lastName,
			formState.phoneNumber,
			address,
			formState.preferences,
		);
		onClose();
		updateUserMessage.setStartAction(true);
	};

	const handleCancel = () => {
		setFormState({ ...customer });
		onClose();
	};

	const valid =
		customer.isAnonymous ? true : (
			isFullString(formState.firstName) &&
			isFullString(formState.lastName) &&
			isFullString(formState.phoneNumber) &&
			(!fullAddressRequired ||
				(isObject(formState.address) &&
					isFullString(formState.address.street) &&
					isFullString(formState.address.number) &&
					isFullString(formState.address.city) &&
					isFullString(formState.address.postalCode)))
		);

	return (
		<Modal onClose={onClose} open={open}>
			<Card className={clsx(classes.root, className)}>
				<form>
					<CardContent>
						<Typography align='center' gutterBottom variant='h3'>
							{t('views.userDetails.summary.edit.user.title')}
						</Typography>
						<Grid className={classes.container} container spacing={3}>
							{userData.map((item) => (
								<Grid item key={item.name} md={6} xs={12}>
									<TextField
										FormHelperTextProps={{ style: { position: 'absolute', marginTop: '3.5rem' } }}
										InputLabelProps={{ shrink: true }}
										error={item.error}
										fullWidth
										helperText={item.error ? item.helperText : null}
										label={item.label}
										name={item.name}
										autoComplete='off'
										onChange={handleEditUser}
										required={item.required}
										value={item.value}
										variant='outlined'
									/>
								</Grid>
							))}
							<Grid item md={6} xs={12}>
								{/* <CountryAutocomplete
									required
									value={formState.address?.countryCode}
								/> */}
								<TextField
									FormHelperTextProps={{ style: { position: 'absolute', marginTop: '3.5rem' } }}
									InputLabelProps={{ shrink: true }}
									SelectProps={{
										MenuProps: { PaperProps: { style: { maxHeight: 245, maxWidth: '314px' } } },
									}}
									error={countryError}
									fullWidth
									helperText={countryError ? t('views.userDetail.addressError') : null}
									label={'Country'}
									name='countryCode'
									onChange={handleCountry}
									required={fullAddressRequired}
									select
									value={isObject(formState.address) ? formState.address.countryCode : ''}
									variant='outlined'
								>
									{countries.map((item) => (
										<MenuItem key={item.code} value={item.code}>
											{item.name}
										</MenuItem>
									))}
								</TextField>
							</Grid>
						</Grid>
					</CardContent>
					<CardActions className={classes.actions}>
						<StyledButton onClick={handleCancel} variant='contained-tertiary'>
							{t('ui.button.contained.close')}
						</StyledButton>
						<StyledButton
							className={classes.saveButton}
							disabled={!valid}
							onClick={handleEdit}
							variant='contained-primary'
						>
							{t('ui.button.contained.save')}
						</StyledButton>
					</CardActions>
				</form>
			</Card>
		</Modal>
	);
};

const mapStateToProps = (state) => {
	return {
		updateUser: state.details.updateUser,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onUpdateUser: (userId, firstName, lastName, phoneNumber, address, preferences) =>
			dispatch(actions.updateUser(userId, firstName, lastName, phoneNumber, address, preferences)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerEdit);

import { useEffect } from 'react';

import {
	Business as BusinessIcon,
	Call as CallIcon,
	Email as EmailIcon,
} from '@mui/icons-material';
import { Avatar, Box, Button, Divider, Stack, SvgIcon, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import useSWR from 'swr';

import { generalInformation } from '~constants';
import { UserRoleEnum } from '~enums';
import { useAuthorize } from '~features/authentication';
import { UsersService } from '~features/users';

const sliceIndex = 5;
const formattedPhoneNumber =
	`${generalInformation.phoneNumber.slice(0, sliceIndex)} ${generalInformation.phoneNumber.slice(sliceIndex)}`.replace(
		'+31',
		'+31(0)'
	);

const service = new UsersService();

/**
 * Provide feedback for a new user waiting for their
 * organisation to be approved
 * @returns
 */
const OrganisationVerificationPage = () => {
	const { t } = useTranslation();
	const auth = useAuth();
	const { authorize } = useAuthorize();
	const { enqueueSnackbar } = useSnackbar();

	const { data, isLoading, isValidating, error } = useSWR(auth.user && 'userRole', () =>
		service.getUserRole(auth.user?.profile.sub)
	);

	useEffect(() => {
		if (data != null) {
			determineStatus();
		}
	}, [data]);

	/**
	 * If the server role is admin, but the local is user, it is verified
	 */
	const determineStatus = () => {
		if (
			auth.user?.profile.role.toLowerCase() === UserRoleEnum.User &&
			data === UserRoleEnum.Admin
		) {
			enqueueSnackbar(t('views.verification.messages.approved'));
		}
	};

	return (
		<Stack spacing={2} justifyContent='center' alignItems='center' sx={{ maxWidth: 550 }}>
			<Avatar
				sx={{
					color: '#304FFE',
					bgcolor: '#E4EEFB',
					width: '91px',
					height: '91px',
				}}
			>
				<SvgIcon component={BusinessIcon} sx={{ fontSize: '55px' }} />
			</Avatar>
			<Typography variant='h4'>{t('views.verification.subsection.status.title')}</Typography>
			<Typography textAlign='center'>
				{t('views.verification.subsection.status.subtitle')}
			</Typography>

			<Box width={1} py={3}>
				<Divider orientation='horizontal' />
			</Box>

			<Typography variant='h4'>{t('views.verification.subsection.questions.title')}</Typography>
			<Typography textAlign='center'>
				{t('views.verification.subsection.questions.subtitle')}
			</Typography>
			<Stack px={3} spacing={3} pt={3} direction='row' justifyContent='space-between' width={1}>
				<Button
					variant='outlined'
					href={`mailto:${generalInformation.infoEmail}`}
					startIcon={<EmailIcon />}
					fullWidth
				>
					{generalInformation.infoEmail}
				</Button>
				<Button
					variant='outlined'
					href={`tel:${generalInformation.phoneNumber}`}
					startIcon={<CallIcon />}
					fullWidth
				>
					{formattedPhoneNumber}
				</Button>
			</Stack>
		</Stack>
	);
};

export default OrganisationVerificationPage;

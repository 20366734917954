import { TopologyService } from '~services';

interface UserStat {
	new: number;
	nfc: number;
	existing: number;
	total: number;
}

class UserStatisticsService extends TopologyService {
	public readonly path = 'graphs/users';

	constructor() {
		super('v1');
	}

	async getCounts({ organisationId }: { organisationId?: string }): Promise<UserStat> {
		const { data } = await this._client.get<UserStatResponse>(this.path, {
			params: {
				organisationId: organisationId,
			},
		});

		return {
			existing: data.existing,
			nfc: data.nfc,
			new: data.new,
			total: data.total,
		};
	}
}

interface UserStatResponse {
	existing: number;
	new: number;
	nfc: number;
	total: number;
}

export default UserStatisticsService;

import { MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Select, SelectProps } from '~components';
import { UserRoleEnum } from '~enums';
import i18n from '~lib/i18n';

const userRoleOptions = Object.values(UserRoleEnum);

interface UserRoleSelectProps extends Omit<SelectProps, 'value' | 'onChange'> {
	value?: UserRoleEnum;
	onChange?: (value: UserRoleEnum) => void;
}

const UserRoleSelect = ({
	value,
	onChange,
	emptyLabel = i18n.t('allRoles'),
	...props
}: UserRoleSelectProps) => {
	const { t } = useTranslation();

	return (
		<Select
			{...props}
			value={value}
			onChange={(e) => onChange?.(e.target.value as UserRoleEnum)}
			emptyLabel={emptyLabel}
		>
			{userRoleOptions.map((el: UserRoleEnum) => (
				<MenuItem key={el} value={el}>
					{t(el === UserRoleEnum.SuperAdmin ? 'superAdmin' : el)}
				</MenuItem>
			))}
		</Select>
	);
};

export default UserRoleSelect;

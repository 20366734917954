import { Address } from '~interfaces';

/**
 * Get the address as a string in format to include postal code and up
 * @param address 
 * @returns 
 */
export const toPostalCodeAndUpString = (address: Address): string => {
	return `${address.postalCode}, ${address.city} (${address.country})`;
}

/**
 * Get the street as string including housenumbers
 * @param address 
 * @returns 
 */
export const toStreetString = (address: Address): string => {
	return `${address.street}${address.number != null ? ` ${address.number}${address.numberAddition ?? ''}` : ''}`;
}

/**
 * Get the city and street as a string
 * @param address 
 * @param includeHouseNumber 
 * @returns 
 */
export const toCityStreetString = (address: Address, includeHouseNumber = true): string => {
	if (!includeHouseNumber) {
		return `${address.street}, ${address.city}`;
	}

	return `${toStreetString(address)}, ${address.city}`;
}

import { AuthProviderProps } from 'react-oidc-context';

import callbackRedirectExcludePaths from './callbackRedirectExcludePaths';
import userManager from './userManager';

const oidcConfig: AuthProviderProps = {
	userManager: userManager,
	onSigninCallback: (user) => {
		if (user?.url_state) {
			let urlStateRedirect = true;

			for (const callbackRedirectExclude of callbackRedirectExcludePaths) {
				if (user.url_state?.includes(callbackRedirectExclude)) {
					urlStateRedirect = false;
					break;
				}
			}

			location.href = urlStateRedirect ? user.url_state : window.location.origin;
			return;
		}

		location.href = window.location.origin;
	},
};

export default oidcConfig;

import { useState, useEffect, useReducer } from 'react';

import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { Typography, List, ListItem, Radio, FormControlLabel, Checkbox, Box } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { actionType, characteristicsReducer } from './reducer';
import { CountController, FormField } from '../../../../../../components';
import { useWizardFormField, useDebouncedNumberSave } from '../../../../../../shared/hooks';
import { isNumber, isObject, isInteger, isFullArray } from '../../../../../../shared/utility';
import AdditionalCharactaristics from '../AdditionalCharactaristics';
import CharacteristicsCard from '../CharacteristicsCard';
import EditButtons from '../EditButtons';
import { useStyles } from '../style';

interface BoatCharactaristicsProps {
	className?: string;
	save?(...args: unknown[]): unknown;
	numberOfDoors?: number;
	numberOfSeats?: number;
	carBodyType?: string;
	propulsionType?: string;
	fuelType?: string;
	isFinishingEdit?: boolean;
	hasNavigation?: boolean;
	hasBluetooth?: boolean;
	hasTowHook?: boolean;
	transmissionType?: string;
	kmRange?: string | number;
	itemStep?: number;
	isEdit?: boolean;
	onClose?(...args: unknown[]): unknown;
	includesBoats?: unknown[];
	engineType?: any;
	boatBodyType?: any;
	characteristics?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const BoatCharactaristics = (props: BoatCharactaristicsProps) => {
	const {
		save,

		itemStep,
		isEdit,
		numberOfSeats,
		characteristics,
		propulsionType,
		engineType,
		fuelType,
		boatBodyType,
		isFinishingEdit,
		onClose,
		kmRange,
		includesBoats,
	} = props;
	const { t } = useTranslation();

	const classes = useStyles();
	const cardProps = { itemStep, isEdit };

	const [additional, dispatchCharacteristics] = useReducer(characteristicsReducer, []);

	const [seats, setSeats] = useState(numberOfSeats);

	const [updateChar, setUpdateChar] = useState(true);

	const kmRangeValue = useWizardFormField(kmRange, {
		required: true,
		minLength: 1,
		maxLength: 5,
		isNumeric: true,
	});
	useDebouncedNumberSave('updatedKmRange', kmRangeValue.value, kmRangeValue.isValid, save);

	const {
		data: characteristicsData,
		loading: characteristicsLoading,
		error: characteristicsError,
	} = characteristics;
	const readyData =
		isObject(characteristicsData) && !characteristicsLoading && !characteristicsError;

	const engine =
		readyData ?
			characteristicsData.boats.filter((boat) => (boat.name === 'engineType' ? boat : null))
		:	null;
	const propulsion =
		readyData ?
			characteristicsData.boats.filter((boat) => (boat.name === 'propulsionType' ? boat : null))
		:	null;
	const fuel =
		readyData ?
			characteristicsData.boats.filter((boat) => (boat.name === 'fuelType' ? boat : null))
		:	null;
	const bodyType =
		readyData ?
			characteristicsData.boats.filter((boat) => (boat.name === 'boatBodyType' ? boat : null))
		:	null;

	const handleCharacteristics = (event) => {
		const target = event.target;
		dispatchCharacteristics({
			type: actionType.UPDATE,
			characteristic: { name: target.name, checked: target.checked },
		});
		save({
			[`updated${target.name.charAt(0).toUpperCase() + target.name.substring(1)}`]: target.checked,
		});
	};

	useEffect(() => {
		if (isFullArray(additional) && isFullArray(includesBoats)) {
			dispatchCharacteristics({ type: actionType.FILLUP, characteristic: includesBoats });
		}
	}, [itemStep, isEdit && updateChar]);

	useEffect(() => {
		if (isFullArray(additional) && isFullArray(includesBoats)) {
			setUpdateChar(false);
		}
	}, [additional, includesBoats]);

	useEffect(() => {
		if (isEdit) {
			setSeats(numberOfSeats);
		}
	}, [numberOfSeats]);

	useEffect(() => {
		if (isInteger(numberOfSeats)) {
			save({ updatedNumberOfSeats: seats });
		}
	}, [numberOfSeats]);

	useEffect(() => {
		if (isObject(characteristicsData)) {
			const boars = characteristicsData.boats.filter(
				(characteristic) => characteristic.type === 'boolean',
			);
			dispatchCharacteristics({});
			dispatchCharacteristics({ type: actionType.ADD, items: boars });
		}
	}, [characteristics]);

	const handlePoweredBy = (event, item) => save({ updatedPropulsionType: item.name });

	const handleFuelType = (event, item) => save({ updatedFuelType: item.name });

	const handleEngineType = (event, item) => save({ updatedEngineType: item.name });

	const handleBoatBodyType = (event, item) => save({ updatedBoatBodyType: item.name });

	const handleIncrementSeats = () => {
		const valueSeats = seats >= 100 ? 100 : seats + 1;
		save({ updatedNumberOfSeats: valueSeats });
		setSeats(valueSeats);
	};

	const handleDecrementSeats = () => {
		const valueSeats = seats === 1 ? seats : seats - 1;
		save({ updatedNumberOfSeats: valueSeats });
		setSeats(valueSeats);
	};

	const capacity = [
		{
			name: 'Seats',
			value: isNumber(numberOfSeats) ? numberOfSeats : seats,
			callIncrement: handleIncrementSeats,
			callDecrement: handleDecrementSeats,
			icon: <PeopleAltOutlinedIcon color='primary' fontSize='large' />,
		},
	];
	const enumCharacteristics = isObject(characteristicsData) && [
		{
			name: 'boatBodyType',
			value: boatBodyType,
			header: 'views.addItem.characteristicsBoat.sloop',
			enumValues: bodyType[0].enumValues,
			action: handleBoatBodyType,
		},
		{
			name: 'engineType',
			value: engineType,
			header: 'views.addItem.characteristicsBoat.engineLocation',
			enumValues: engine[0].enumValues,
			action: handleEngineType,
		},
		{
			name: 'propulsionType',
			value: propulsionType,
			header: 'views.addItem.characteristicsBoat.poweredBy',
			enumValues: propulsion[0].enumValues,
			action: handlePoweredBy,
		},
		...(propulsionType === 'fuel' ?
			[
				{
					name: 'fuelType',
					value: fuelType,
					header: 'views.addItem.characteristicsBoat.fuelType',
					enumValues: fuel[0].enumValues,
					action: handleFuelType,
				},
			]
		:	[]),
	];
	return (
		<>
			<CharacteristicsCard {...cardProps}>
				<Typography className={classes.labelSpacing} variant='h5'>
					{t('views.addItem.characteristicsBoat.capacity')}?
				</Typography>
				<List className={classes.capacityControllers}>
					{capacity.map((item) => (
						<ListItem key={item.name}>
							<CountController
								callbackDecrement={item.callDecrement}
								callbackIncrement={item.callIncrement}
								icon={item.icon}
								name={item.name}
								value={item.value}
							/>
						</ListItem>
					))}
				</List>
				{isFullArray(enumCharacteristics) &&
					enumCharacteristics.map((characteristic) => (
						<Box key={characteristic.name}>
							<Typography className={classes.labelSpacing} variant='h5'>
								{t(characteristic.header)}?
							</Typography>
							<List className={classes.listComponent}>
								{readyData &&
									characteristic.enumValues.map((item) => (
										<ListItem
											button={true}
											className={clsx(classes.option, {
												[classes.selectedOption]: characteristic.value === item.name,
											})}
											key={item.id}
											onClick={(event) => characteristic.action(event, item)}
										>
											<Radio
												checked={characteristic.value === item.name}
												className={classes.optionRadio}
												onChange={(event) => characteristic.action(event, item)}
												value={item.name}
											/>
											<div className={classes.optionDetails}>
												<Typography variant='h5'>{t(`ui.characteristics.${item.name}`)}</Typography>
											</div>
										</ListItem>
									))}
							</List>
						</Box>
					))}
				{propulsionType === 'electric' ?
					<>
						<Typography className={classes.labelSpacing} variant='h5'>
							{t('views.addItem.characteristicsCars.whatRangeOfElectricBoat')}?
						</Typography>
						<Box className={classes.listComponent}>
							<Box mb={1} width='180px'>
								<FormField
									fullWidth={true}
									hideCharacterCounter={!kmRangeValue.hasFocus}
									label={t('ui.characteristics.kmRange')}
									maxLength={5}
									name='kmRange'
									variable={kmRangeValue}
								/>
							</Box>
						</Box>
					</>
				:	null}
			</CharacteristicsCard>
			<AdditionalCharactaristics isEdit={isEdit}>
				{additional.map((item) => (
					<FormControlLabel
						control={
							<Checkbox checked={item.checked} name={item.name} onChange={handleCharacteristics} />
						}
						key={item.name}
						label={item.label}
					></FormControlLabel>
				))}
			</AdditionalCharactaristics>
			{isEdit ?
				<EditButtons isFinishingEdit={isFinishingEdit} onClose={onClose} />
			:	null}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		characteristics: state.details.characteristics,
	};
};

export default connect(mapStateToProps, null)(BoatCharactaristics);

import { Download as DownloadIcon, Edit as EditIcon } from '@mui/icons-material';
import { Box, Button, Link, Stack, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';

import { userInfoAtom } from '~atoms';
import { InfoCard, InfoCardRowDef, LoadingSwitch } from '~components';
import { useAuthorize } from '~features/authentication';
import { useDownloadFile, useSnackbar } from '~hooks';
import { DynamicLinkService } from '~services';

import Organisation from '../../interfaces/organisation';
import OrganisationsService from '../../services/organisationsService';

const dynamicLinkService = new DynamicLinkService();
const organisationService = new OrganisationsService();

interface OrganisationBillingCardProps {
	organisationId: string;
	onEditClick?: () => void;
	onChange?: () => void;
}

const OrganisationBillingCard = ({ organisationId, ...props }: OrganisationBillingCardProps) => {
	const { t } = useTranslation();
	const { isSuperAdmin, authorizeForOrganisation } = useAuthorize();
	const userInfo = useAtomValue(userInfoAtom);
	const { enqueueSuccessSnackbar, enqueueAxiosErrorSnackbar } = useSnackbar();
	const { downloadLocalFile } = useDownloadFile();

	const {
		data: organisationData,
		isLoading: isOrganisationLoading,
		error: organisationError,
	} = useSWR(
		organisationId != null ? [organisationService.basePath, organisationId] : null,
		([_, args]) => organisationService.getOrganisationById(args)
	);

	const { trigger: triggerOrganisation, isMutating: isOrganisationMutating } = useSWRMutation(
		[organisationService.basePath, organisationId],
		([_, id], { arg }: { arg: Partial<Organisation> }) =>
			organisationService.patchOrganisation(id, arg),
		{
			onSuccess: () => {
				enqueueSuccessSnackbar(
					`${t('views.organisationDetail.summary.partnershipAvailability.success')} ${organisationData.label}`
				);
				props.onChange?.();
			},
			onError: (error) => enqueueAxiosErrorSnackbar(error),
		}
	);

	const {
		data: qrData,
		isLoading: isQrLoading,
		isValidating: isQrValidating,
		mutate: mutateQr,
	} = useSWR(
		[dynamicLinkService.basePath, `organisations/${organisationId}`],
		([_, linkPath]) => dynamicLinkService.getQrImage(linkPath),
		{
			revalidateOnFocus: false,
			refreshInterval: 24 * 60 * 60 * 1000,
		}
	);

	const { trigger: triggerQr, isMutating: isQrMutating } = useSWRMutation(
		dynamicLinkService.basePath,
		(_, { arg }) => dynamicLinkService.generateLink({ target: 'portal', ...arg }),
		{
			onSuccess: () => mutateQr(),
			onError: (error) => enqueueAxiosErrorSnackbar(error),
		}
	);

	const rows: InfoCardRowDef[] | undefined = organisationData && [
		{
			headerName: t('ui.label.vatNumber'),
			value: organisationData.administration?.vatNumber,
		},
		{
			headerName: t('ui.label.enterpriseNumber'),
			value: organisationData.administration?.enterpriseNumber,
			hidden: organisationData.address?.countryCode !== 'BE',
		},
		{
			headerName: t('ui.label.cocNumber'),
			value: organisationData.administration?.chamberOfCommerceNumber,
		},
		{
			headerName: t('ui.label.ibanNumber'),
			hidden: !organisationData.isVerified,
			value: organisationData.administration?.ibanNumber,
		},
		{
			headerName: t('ui.label.email'),
			value: organisationData.invoiceContact?.emailAddress,
			renderCell: (value: string) => (value ? <Link href={`email:${value}`}>{value}</Link> : '-'),
		},
		{
			headerName: t('ui.label.name'),
			value: organisationData.invoiceContact?.name,
		},
		{
			headerName: t('ui.label.showOnDashboard.publicItem'),
			hidden: !isSuperAdmin(),
			value: organisationData.showOnDashboard,
			renderCell: (value: boolean) => (
				<LoadingSwitch
					size='small'
					checked={value}
					onChange={async (e) => {
						if (isOrganisationMutating) {
							return;
						}

						const value = { showOnDashboard: e.target.checked };
						await triggerOrganisation(value, {
							optimisticData: (current) => ({ ...current, ...value }),
						});
					}}
				/>
			),
		},
		{
			headerName: t('ui.label.availableForPartnership'),
			hidden:
				!authorizeForOrganisation(organisationId) ||
				userInfo?.organisation.subscriptions.includes('telematics'),
			value: organisationData.isPublic,
			renderCell: (value: boolean) => (
				<LoadingSwitch
					size='small'
					checked={value}
					onChange={async (e) => {
						if (isOrganisationMutating) {
							return;
						}

						const value = { isPublic: e.target.checked };
						await triggerOrganisation(value, {
							optimisticData: (current) => ({ ...current, ...value }),
						});
					}}
				/>
			),
		},
	];

	return (
		<InfoCard
			title={t('views.organisationDetail.summary.cardHeaders.billingAndAdministration')}
			rows={rows ?? []}
			loading={isOrganisationLoading}
			error={organisationError != null}
			slots={{
				contentFooter:
					isSuperAdmin() ?
						<Stack sx={{ p: 2 }}>
							<Typography sx={{ fontSize: 12 }}>QR-code</Typography>
							{qrData ?
								<Stack sx={{ alignItems: 'center' }}>
									<img alt='qrCode' src={qrData.url} width='160px' />
									<Button
										startIcon={<DownloadIcon />}
										onClick={() =>
											downloadLocalFile(qrData.url, `${organisationData.label}-qr.png`)
										}
									>
										{t('download')}
									</Button>
								</Stack>
							:	<Button
									onClick={async () => await triggerQr({ path: `organisations/${organisationId}` })}
								>
									{t('ui.generateQr')}
								</Button>
							}
						</Stack>
					:	undefined,
			}}
			actions={
				organisationData?.isVerified && authorizeForOrganisation(organisationId) ?
					[
						{
							label: t('ui.edit'),
							icon: <EditIcon />,
							onClick: props.onEditClick,
						},
					]
				:	undefined
			}
		/>
	);
};

export default OrganisationBillingCard;

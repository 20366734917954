import { forwardRef, useImperativeHandle } from 'react';

import { Edit as EditIcon } from '@mui/icons-material';
import { Avatar, Box, Link, Paper, Stack, useTheme } from '@mui/material';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';

import { DragAndDropImageCropper, InfoCard, InfoCardRowDef } from '~components';
import { useAuthorize } from '~features/authentication';
import { useSnackbar } from '~hooks';
import { MutationRefProps } from '~interfaces/refProps';
import { TopologyErrorResponse } from '~interfaces/responses';
import { getInitials } from '~utils/stringUtils';

import OrganisationsService from '../../services/organisationsService';

const avatarHeight = 92;

const service = new OrganisationsService();

interface OrganisationGeneralInformationCardProps {
	organisationId: string;
	onEdit?: (id: string) => void;
}

/**
 * A card to show organisation basic information
 * Notice: the imperative handle should be temporary, just to refresh the data
 * within this component from outside
 * @returns
 */
const OrganisationGeneralInformationCard = forwardRef<
	MutationRefProps,
	OrganisationGeneralInformationCardProps
>(({ organisationId, ...props }, ref) => {
	const { t } = useTranslation();
	const { enqueueSuccessSnackbar, enqueueErrorSnackbar, enqueueAxiosErrorSnackbar } = useSnackbar();
	const theme = useTheme();

	const { authorizeForOrganisation } = useAuthorize();

	const { data, isLoading, mutate } = useSWR([service.basePath, organisationId], ([_, id]) =>
		service.getOrganisationById(id)
	);

	const { trigger: triggerLogo } = useSWRMutation(
		[service.basePath, organisationId, service.logoSubPath],
		([_, id], { arg }: { arg: File }) => service.updateLogoImage(id, arg),
		{
			onSuccess: () => {
				enqueueSuccessSnackbar(`${t('ui.success.message.updated')} ${t('logo').toLowerCase()}`);
				mutate();
			},
			onError: (error: AxiosError<TopologyErrorResponse>) => enqueueAxiosErrorSnackbar(error, error.response?.data?.message),
		}
	);

	const { trigger: triggerBanner } = useSWRMutation(
		[service.basePath, organisationId, service.bannerSubPath],
		([_, id], { arg }: { arg: File }) => service.updateBannerImage(id, arg),
		{
			onSuccess: () => {
				enqueueSuccessSnackbar(`${t('ui.success.message.updated')} ${t('banner').toLowerCase()}`);
				mutate();
			},
			onError: (error: AxiosError<TopologyErrorResponse>) => enqueueAxiosErrorSnackbar(error, error.response?.data?.message),
		}
	);

	useImperativeHandle(
		ref,
		() => ({
			mutate: mutate,
		}),
		[mutate]
	);

	const rows: InfoCardRowDef[] | undefined = data && [
		{
			headerName: t('name'),
			value: data.label,
		},
		{
			headerName: t('ui.label.legalName'),
			value: data.legalName
		},
		{
			headerName: t('ui.label.email'),
			value: data.email,
			renderCell: (value: string) => <Link href={`mailto:${value}`}>{value}</Link>,
		},
		{
			headerName: t('ui.label.phoneNumber'),
			value: data.phoneNumber,
			renderCell: (value: string) => <Link href={`tel:${value}`}>{value}</Link>,
		},
		{
			headerName: t('ui.label.users'),
			value: data.totalUsers,
		},
		{
			headerName: t('ui.category.userGroups'),
			value: data.totalUserGroups,
		},
		{
			headerName: t('ui.label.description'),
			value: data.description,
		},
	];

	return (
		<InfoCard
			title={t('views.organisationDetail.summary.cardHeaders.general')}
			rows={rows ?? []}
			loading={isLoading}
			version='legacy'
			slots={{
				contentHeader: (
					<Box>
						<DragAndDropImageCropper
							src={data?.logoUri}
							slotProps={{
								uploader: {
									clearable: false,
									readonly: !authorizeForOrganisation(organisationId),
									maxFileSizeInBytes: 1024000,
									onError: (errors) => enqueueErrorSnackbar(errors[0]?.message),
								},
								imageCropper: {
									aspect: 1,
								},
							}}
							onChange={async (val) => val?.file != null && triggerLogo(val.file)}
						/>
					</Box>
				),
				// contentHeader2: (
				// 	<Stack sx={{ width: 1, height: 'fit-content' }}>
				// 		<Box sx={{ marginBottom: `-${avatarHeight * 0.66}px` }}>
				// 			<DragAndDropImageCropper
				// 				src={data?.bannerUri}
				// 				slotProps={{
				// 					uploader: {
				// 						readonly: !authorizeForOrganisation(organisationId),
				// 						maxFileSizeInBytes: 1024000,
				// 						clearable: false,
				// 						onError: (errors) => enqueueErrorSnackbar(errors[0]?.message),
				// 					},
				// 					imageCropper: {
				// 						aspect: 16 / 9,
				// 					},
				// 				}}
				// 				onChange={async (val) => val?.file != null && triggerBanner(val.file)}
				// 			>
				// 				<img
				// 					style={{
				// 						clipPath: 'ellipse(150% 100% at 50% 0%)',
				// 						objectFit: 'cover',
				// 						borderTopLeftRadius: '8px',
				// 						borderTopRightRadius: '8px',
				// 						width: '100%',
				// 						height: '100%',
				// 						maxHeight: '245px',
				// 					}}
				// 				/>
				// 			</DragAndDropImageCropper>
				// 		</Box>
				// 		<DragAndDropImageCropper
				// 			src={data?.logoUri}
				// 			slotProps={{
				// 				uploader: {
				// 					clearable: false,
				// 					placeholderSrc: null,
				// 					readonly: !authorizeForOrganisation(organisationId),
				// 					maxFileSizeInBytes: 1024000,
				// 					onError: (errors) => enqueueErrorSnackbar(errors[0]?.message),
				// 					style: {
				// 						width: 'fit-content',
				// 						height: 'fit-content',
				// 						position: 'relative',
				// 						left: '50%',
				// 						transform: 'translate(-50%)',
				// 					},
				// 				},
				// 				imageCropper: {
				// 					aspect: 1,
				// 				},
				// 			}}
				// 			onChange={async (val) => val?.file != null && triggerLogo(val.file)}
				// 		>
				// 			<Avatar
				// 				component={Paper}
				// 				elevation={3}
				// 				sx={{
				// 					width: avatarHeight,
				// 					height: avatarHeight,
				// 					border: 1,
				// 					borderColor: theme.palette.grey[300],
				// 				}}
				// 			>
				// 				{getInitials(data?.label)}
				// 			</Avatar>
				// 		</DragAndDropImageCropper>
				// 	</Stack>
				// ),
			}}
			actions={
				authorizeForOrganisation(organisationId) ?
					[
						{
							label: t('ui.edit'),
							icon: <EditIcon />,
							onClick: () => props.onEdit?.(organisationId),
						},
					]
				:	undefined
			}
		/>
	);
});

export default OrganisationGeneralInformationCard;

import { Box } from '@mui/material';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';

import 'moment/locale/nl';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';

interface DatePickerProps {
	start?: object;
	end?: object;
	handleDatesChange?(...args: unknown[]): unknown;
	focused?: string;
	handleFocusChange?(...args: unknown[]): unknown;
	paddingTop?: number;
	paddingBottom?: number;
	minDate?: object;
}

const DatePicker = (props: DatePickerProps) => {
	const { start, end, handleDatesChange, focused, handleFocusChange, paddingTop, paddingBottom } =
		props;
	const { i18n, t } = useTranslation();
	const classes = useStyles();

	if (i18n.language === 'nl') {
		moment.locale('nl-NL');
	} else if (i18n.language === 'en') {
		moment.locale('en-US');
	} else {
		moment.locale('de-DE');
	}

	return (
		<div className={classes.root}>
			<Box
				alignItems='center'
				display='flex'
				flexDirection='row'
				pb={paddingBottom}
				pt={paddingTop}
			>
				<DateRangePicker
					displayFormat={() => 'DD/MM/YYYY'}
					endDate={end}
					endDateId='idEndDate'
					endDatePlaceholderText={t('ui.label.endDate')}
					focusedInput={focused}
					isOutsideRange={() => false}
					onDatesChange={handleDatesChange}
					onFocusChange={handleFocusChange}
					showClearDates={true}
					showDefaultInputIcon={true}
					startDate={start}
					startDateId='idStartDate'
					startDatePlaceholderText={t('ui.label.startDate')}
					isDayBlocked={(date) => date < props.minDate}
				/>
			</Box>
		</div>
	);
};

export default DatePicker;

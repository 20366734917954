import path from 'path';

import TopologyService from './topologyService';

interface DynamicLinkNew {
	/**
	 * Path of the new url
	 */
	path: string;
	/**
	 * The platform to target
	 */
	target: 'portal' | 'adminPanel';
}

class DynamicLinkService extends TopologyService {
	public readonly path = 'dynamiclinks';

	constructor() {
		super('v2');
	}

	/**
	 * Get a dynamic link qr based on the path and target
	 * @param linkPath 
	 * @param target The qr for the platform. What to redirect to
	 * @returns 
	 */
	async getQrImage(
		linkPath: string,
		target: 'portal' | 'adminPanel' = 'portal',
	): Promise<{ url: string, file: File } | null> {
		const { data } = await this._client.get<Blob>(path.join(this.path, 'qr'), {
			responseType: 'blob',
			params: {
				path: linkPath,
				target: target,
			},
		});

		if (data && data.size > 0) {
			const file = new File([data], 'qr.png', { type: data.type });
			return {
				url: URL.createObjectURL(file),
				file: file
			};
		}

		return null;
	}

	/**
	 * Generate a new dynamic link
	 * @param data 
	 * @returns 
	 */
	async generateLink(data: DynamicLinkNew): Promise<{ url: string }> {
		const response = await this._client.post(this.path, data);

		return response.data;
	}
}

export default DynamicLinkService;

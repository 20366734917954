import { useEffect } from 'react';

import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { usePrevious } from '../../../../shared/hooks';
import { isObject } from '../../../../shared/utility';
import * as actions from '../../../../store/actions';
import AddPolicy from '../../ActionFlows/AddPolicy';

interface EditPolicyProps {
	policyDetails?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onFetchPolicy?(...args: unknown[]): unknown;
}

const EditPolicy = (props: EditPolicyProps) => {
	const { policyDetails, onFetchPolicy } = props;
	const location = useLocation();

	const { id } = useParams();

	const { data: policyDetailsData, loading: policyDetailsLoading } = policyDetails;

	useEffect(() => {
		if (isObject(policyDetailsData) && id !== policyDetailsData.id) {
			onFetchPolicy(id);
		}
	}, [id]);

	useEffect(() => {
		if (!isObject(policyDetailsData) && id) {
			onFetchPolicy(id);
		}
	}, [policyDetailsData, id]);

	return isObject(policyDetailsData) ?
			<AddPolicy
				editPolicyData={policyDetailsData}
				editRedirect={location.state.detail}
				editing={true}
			/>
		:	null;
};

const mapStateToProps = (state) => {
	return {
		policyDetails: state.details.fetchPolicy,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchPolicy: (id) => dispatch(actions.fetchPolicy(id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPolicy);

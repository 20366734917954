import { useEffect, useState, useRef } from 'react';

import { InfoOutlined as InfoOutlinedIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Paper, Typography, Link, Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import useSWRMutation from 'swr/mutation';

import { ConfirmationDialog, FormDialog } from '~components';
import { useAuthorize } from '~features/authentication';
import { CreateNfcTagForm, NfcTag, NfcTagsService } from '~features/nfc';
import { useSnackbar } from '~hooks';

import { LinkDrawer, DownloadCsvDialog } from './components';
import { useStyles } from './style';
import { Table, SearchBar, Label, LinkUnlinkButton, GenericMoreButton } from '../../../components';
import { commaTimeStrings } from '../../../shared/datetime';
import {
	useSearchComponent,
	usePagination,
	useComplexFilter,
	useBasicFilter,
	usePrevious,
} from '../../../shared/hooks';
import {
	isObject,
	isEmptyArray,
	isFullString,
	isEmptyString,
	isInteger,
	isNull,
	isBoolean,
	capitalizeFirstCharacter,
} from '../../../shared/utility';
import * as actions from '../../../store/actions';

const service = new NfcTagsService();

type UserAction = | {
	type: 'linkNfc' | 'createNfc',
	id?: never;
} | {
	type: 'unlinkNfc' | 'deleteNfc',
	id: string;
}

interface NfcCardsProps {
	organisationsList?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	fetchNfcCards?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	addNfcCsvImports?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onFetchOrganisations?(...args: unknown[]): unknown;
	onFetchNfcCards?(...args: unknown[]): unknown;
	onAddNfcCsvImports?(...args: unknown[]): unknown;
	onResetBlobState?(...args: unknown[]): unknown;
}

const NfcCards = (props: NfcCardsProps) => {
	const {
		fetchNfcCards,
		onFetchNfcCards,
		onFetchOrganisations,
		organisationsList,
		addNfcCsvImports,
		onAddNfcCsvImports,
		onResetBlobState,
	} = props;
	const { t } = useTranslation();
	const location = useLocation();
	const { isSuperAdmin } = useAuthorize();
	const { enqueueSnackbar, enqueueSuccessSnackbar, enqueueAxiosErrorSnackbar } = useSnackbar();
	const [userAction, setUserAction] = useState<UserAction | null>(null);

	const { data: fetchNfcCardsData, loading: fetchNfcCardsLoading } = fetchNfcCards;

	const isEmptyResults = isObject(fetchNfcCardsData) && isEmptyArray(fetchNfcCardsData.results);
	const isFullResults = isObject(fetchNfcCardsData) && !isEmptyArray(fetchNfcCardsData.results);

	const classes = useStyles();

	const showUnLinkedNFC = false;

	const linkRef = useRef(null);

	const [shouldFetch, setShouldFetch] = useState(true);
	const [loading, setLoading] = useState(true);

	const [order, setOrder] = useState('asc');
	const [orderDescending, setOrderDescending] = useState(true);
	const [sortingCategory, setSortingCategory] = useState('');

	const [shouldSearch, setShouldSearch] = useState(false);

	const [linkNfcData, setLinkNfcData] = useState(null);
	const {
		data: addNfcCsvImportsData,
		loading: addNfcCsvImportsLoading,
		error: addNfcCsvImportsError,
	} = addNfcCsvImports;

	// TODO Type NfcTag
	const { trigger: triggerCreateNfc, isMutating: isCreateNfcMutating } = useSWRMutation(
		service.basePath,
		(_, { arg }: { arg: NfcTag}) => service.createNfcTag(arg),
		{
			onSuccess: () => {
				// TODO: trans
				enqueueSuccessSnackbar('Succesful created');
				setUserAction(null);
				setShouldFetch(true);
			},
			onError: (error) => enqueueAxiosErrorSnackbar(error),
		}
	);

	const { trigger: triggerDeleteNfc, isMutating: isDeleteNfcMutating } = useSWRMutation(
		service.basePath,
		(_, { arg }: { arg: string }) => service.deleteNfcTag(arg),
		{
			onSuccess: () => {
				enqueueSuccessSnackbar(t('views.usersAndOrganisations.deleteNfc.successMessage'));
				setUserAction(null);
				setShouldFetch(true);
			},
			onError: (error) => enqueueAxiosErrorSnackbar(error),
		}
	);

	const { trigger: triggerUnlinkNfc, isMutating: isUnlinkNfcMutating } = useSWRMutation(
		service.basePath,
		(_, { arg }: { arg: string }) => service.unlinkNfcTag(arg),
		{
			onSuccess: () => {
				enqueueSuccessSnackbar(t('views.usersAndOrganisations.nfcUnlink.successMessage'));
				setUserAction(null);
				setShouldFetch(true);
			},
			onError: (error) => enqueueAxiosErrorSnackbar(error),
		}
	);

	const [fileReaderData, setFileReaderData] = useState(null);
	const [fileName, setFileName] = useState(null);
	const [loadingFile, setLoadingFile] = useState(false);
	const [downloadCsv, setDownloadCsv] = useState(false);
	const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);
	const usePreviousLoading = usePrevious(addNfcCsvImportsLoading);

	const pagination = usePagination('pageNumberUserGroups', 'pageSizeUserGroups');
	const nfcStatusFilter = useBasicFilter('nfcgStatusFilter', 'none');
	const showLinkedFilter = useBasicFilter('showLinkedFilter', 'none');
	const nfcOrganisationFilter = useComplexFilter(
		'nfcOrganisationNameFilter',
		'nfcOrganisationIdFilter'
	);
	const search = useSearchComponent(pagination.setPageNumber, setShouldSearch, 'nfcSearch');

	const filters = {
		...(!isEmptyString(search.value) && { searchTerm: search.value }),
		...(isFullString(sortingCategory) && { sortBy: sortingCategory }),
		...(nfcOrganisationFilter.valueId !== 'all' && {
			organisationId: nfcOrganisationFilter.valueId,
		}),
		...(nfcStatusFilter.value !== 'none' && { cardStatus: nfcStatusFilter.value }),
		...(showLinkedFilter.value !== 'none' ?
			{ isLinked: JSON.parse(showLinkedFilter.value) }
		:	null),
		orderDescending,
	};

	useEffect(() => {
		if (!fetchNfcCardsLoading && (pagination.fetch || shouldFetch || shouldSearch)) {
			onFetchNfcCards(pagination.page, filters);
			setLoading(true);
			setShouldFetch(false);
		}
		if (shouldSearch) {
			setShouldSearch(false);
		} else if (pagination.fetch) {
			pagination.setFatch(false);
		}
	}, [shouldFetch, pagination.fetch, shouldSearch, showUnLinkedNFC]);

	useEffect(() => {
		if (isObject(fetchNfcCardsData) && !fetchNfcCardsLoading) {
			setLoading(false);
		}
	}, [fetchNfcCardsData]);

	useEffect(() => {
		if (!addNfcCsvImportsLoading && !isNull(addNfcCsvImportsData) && downloadCsv) {
			const ext = addNfcCsvImportsData.type.split('/')[1];
			linkRef.current.href = URL.createObjectURL(addNfcCsvImportsData);
			linkRef.current.download = `${fileName}.${ext}`;
			linkRef.current.click();
			setDownloadCsv(false);
		}
	}, [addNfcCsvImportsData, downloadCsv]);

	useEffect(() => {
		if (
			!addNfcCsvImportsLoading &&
			isObject(addNfcCsvImportsData) &&
			usePreviousLoading &&
			!isObject(addNfcCsvImportsError)
		) {
			setLoadingFile(false);
			setDownloadDialogOpen(true);
		} else if (isObject(addNfcCsvImportsError) && usePreviousLoading) {
			setLoadingFile(false);
			enqueueSnackbar(`${addNfcCsvImportsError?.message}`, { variant: 'error' });
		} else if (isBoolean(addNfcCsvImportsData)) {
			setLoadingFile(false);
		}
	}, [addNfcCsvImportsLoading]);

	useEffect(() => {
		if (
			!addNfcCsvImportsLoading &&
			isObject(addNfcCsvImportsData) &&
			usePreviousLoading &&
			!isObject(addNfcCsvImportsError)
		) {
			setLoadingFile(false);
			enqueueSnackbar(t('views.usersAndOrganisations.nfcCards.successMessage'), {
				variant: 'success',
			});
			setShouldFetch(true);
		} else if (isObject(addNfcCsvImportsError) && usePreviousLoading) {
			setLoadingFile(false);
			enqueueSnackbar(`${addNfcCsvImportsError?.message}`, { variant: 'error' });
			onResetBlobState('addNfcCsvImports');
		} else if (isBoolean(addNfcCsvImportsData)) {
			setLoadingFile(false);
		}
	}, [addNfcCsvImportsLoading]);

	useEffect(() => {
		if (isObject(fileReaderData)) {
			onAddNfcCsvImports([fileReaderData]);
			setLoadingFile(true);
		}
	}, [fileReaderData]);

	const handleDownloadCsv = () => {
		setDownloadCsv(true);
		setDownloadDialogOpen(false);
	};

	const getNfcData = () => setShouldFetch(true);

	const handleRequestSort = (property) => {
		const isDesc = sortingCategory === property && order === 'desc';
		setOrder(isDesc ? 'asc' : 'desc');
		setOrderDescending(!isDesc);
		setSortingCategory(property);
		setShouldFetch(true);
	};

	const handleChangeOrganisationFilterSelect = (item) => {
		if (item.id === nfcOrganisationFilter.valueId) {
			return;
		}
		if (isInteger(item.id)) {
			nfcOrganisationFilter.setValueName(item.name);
		} else if (isEmptyString(item.id)) {
			nfcOrganisationFilter.setValueName('');
		}
		nfcOrganisationFilter.setValueId(item.id);
		setShouldFetch(true);
	};

	const handleFilterChange = (event, filterValue) => {
		const value = event.target.value;
		if (value !== filterValue.value) {
			filterValue.setValue(() => value);
			setShouldFetch(true);
		}
	};

	const linkStatusFilters = [true, false];

	const statusFilters = ['active', 'disabled'];

	const mainFilters = [
		...(isSuperAdmin() ?
			[
				{
					isSelectWithLazyLoading: true,
					events: {
						onChange: handleChangeOrganisationFilterSelect,
						searchHandle: nfcOrganisationFilter.setValueName,
					},
					value: nfcOrganisationFilter.valueName,
					dataList: organisationsList,
					placeholder: t('ui.filter.organisations.all'),
					onFetchData: onFetchOrganisations,
					listType: 'organisations',
					defaultListItem: {
						id: '',
						name: 'All organisations',
					},
				},
				{
					events: { onChange: (e) => handleFilterChange(e, nfcStatusFilter) },
					value: nfcStatusFilter.value,
					selectOptions: [
						{
							value: 'none',
							label: t('ui.label.status'),
						},
					].concat(
						statusFilters.map((type) => ({
							value: type,
							label: t(`ui.status.${type}`),
						}))
					),
				},
			]
		:	[]),
		{
			events: { onChange: (e) => handleFilterChange(e, showLinkedFilter) },
			value: showLinkedFilter.value,
			selectOptions: [
				{
					value: 'none',
					label: t('ui.linkStatus.label.none'),
				},
			].concat(
				linkStatusFilters.map((type) => ({
					value: `${type}`,
					label: t(`ui.linkStatus.label.${type}`),
				}))
			),
		},
	];

	const handleClearFilters = () => {
		nfcOrganisationFilter.setValueName('');
		nfcOrganisationFilter.setValueId('all');
		nfcStatusFilter.setValue('none');
		showLinkedFilter.setValue('none');
		search.events.onClear();
		pagination.resetPagination();
	};

	const clearFilters = {
		clear:
			pagination.pageNumber !== 1 ||
			pagination.pageSize !== 10 ||
			nfcOrganisationFilter.valueId !== 'all' ||
			nfcStatusFilter.value !== 'none' ||
			showLinkedFilter.value !== 'none',
		btnText: 'ui.button.inline.clearfilters',
		action: handleClearFilters,
	};

	const fileReader = {
		setFileReaderData: setFileReaderData,
		setFileName: setFileName,
		loading: loadingFile,
		setLoading: setLoadingFile,
		name: t('ui.button.contained.importNfcTags'),
	};
	const handleCloseDownloadCsv = () => setDownloadDialogOpen(false);

	const extraButtons = [
		{
			variant: 'contained-primary',
			onClick: () => setUserAction({ type: 'createNfc' }),
			text: t('views.userDetails.summary.addNfcCard'),
		},
	];

	const handleLinkUnlinkNfc = (nfcData) => {
		setLinkNfcData(nfcData);
		if (nfcData?.userReference) {
			setUserAction({ type: 'unlinkNfc', id: nfcData.nfcId });
			return;
		}
		setUserAction({ type: 'linkNfc' });
	};

	const createMenuItems = (nfc) => [
		{
			icon: <DeleteIcon color='error' />,
			text: t('ui.button.inline.delete'),
			action: () => setUserAction({ type: 'deleteNfc', id: nfc.nfcId }),
			isRed: true,
			disabled: !isSuperAdmin() && nfc.tagType !== 'none',
		},
	];

	const tableHeader = [
		...(isFullResults ?
			[
				{
					name: 'cardNumber',
					content: t('views.usersAndOrganisations.nfcCards.cardNr'),
					hasSorting: true,
				},
				{
					name: 'nfcId',
					content: t('rfidUid'),
					hasSorting: true,
				},
				{ name: 'linkedWith', content: t('views.usersAndOrganisations.nfcCards.linkedWith') },
				{ name: 'userGroup', content: t('ui.label.userGroup') },
				...(isSuperAdmin() ?
					[{ name: 'organisation', content: t('ui.organisation') }]
				:	[]),
				{
					name: 'tagType',
					content: t('views.usersAndOrganisations.nfcCards.tagType'),
				},
				{
					name: 'linkedDate',
					content: t('views.usersAndOrganisations.nfcCards.linkDate'),
					hasSorting: true,
				},
				...(isSuperAdmin() ?
					[{ name: 'status', content: t('ui.label.status') }]
				:	[]),
			]
		:	[]),
	];

	const loadingBody =
		loading ?
			Array(4)
				.fill(Array(tableHeader.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })))
		:	null;

	const emptyBody =
		!loading && isEmptyResults ?
			[
				[
					{
						content: (
							<Paper className={classes.paperCard}>
								<InfoOutlinedIcon className={classes.icon} fontSize='small' />
								<Typography className={classes.tip}>
									{`${t('views.usersAndOrganisations.nfcCards.emptyList')}`}
								</Typography>
							</Paper>
						),
					},
				],
			]
		:	null;

	const handleLinks = (path, label, returnButton = 'ui.label.nfcTags') => (
		<Link
			className={classes.itemGroup}
			color='inherit'
			component={RouterLink}
			to={path}
			state={{ from: location.pathname, label: t(returnButton) }}
		>
			{label}
		</Link>
	);

	const dataBody =
		!loading && isFullResults ?
			fetchNfcCardsData.results.map((nfc) => [
				{ content: nfc.cardNumber },
				{ content: nfc.nfcId },
				{
					content:
						nfc?.userReference &&
						handleLinks(
							`/user-management/users/${nfc?.userReference?.id}/summary`,
							nfc?.userReference?.name
						),
				},
				{ content: nfc.userGroupReference?.name },
				...(isSuperAdmin() ?
					[
						{
							content:
								nfc?.organisationReference &&
								handleLinks(
									`/organisations/${nfc?.organisationReference?.id}/summary`,
									nfc?.organisationReference?.name
								),
						},
					]
				:	[]),
				{
					content:
						nfc.tagType === 'none' ? t('ui.label.external') : capitalizeFirstCharacter(nfc.tagType),
				},
				{
					content:
						isSuperAdmin() ?
							nfc?.linkedDate && commaTimeStrings(nfc.linkedDate)
						:	<Box className={classes.linkButton}>
								{commaTimeStrings(nfc.linkedDate)}
								<Stack direction='row'>
									<LinkUnlinkButton
										link={isObject(nfc?.userReference)}
										onClick={handleLinkUnlinkNfc}
										data={nfc}
									/>
									<GenericMoreButton menuItems={createMenuItems(nfc)} />
								</Stack>
							</Box>,
					classCell: classes.linkedDate,
				},
				...(isSuperAdmin() ?
					[
						{
							content: (
								<Box className={classes.linkButton}>
									{isSuperAdmin() && (
										<Label type={nfc.status === 'active' ? 'success' : 'error'}>
											{nfc.status === 'active' ? t('ui.status.active') : t('ui.status.disabled')}
										</Label>
									)}
									<Stack direction='row'>
										<LinkUnlinkButton
											link={isObject(nfc?.userReference)}
											onClick={handleLinkUnlinkNfc}
											data={nfc}
										/>
										<GenericMoreButton menuItems={createMenuItems(nfc)} />
									</Stack>
								</Box>
							),
							classCell: classes.linkCell,
						},
					]
				:	[]),
			])
		:	null;

	return (
		<div>
			<SearchBar
				hasExtraButtons
				extraButtons={extraButtons}
				clearFilters={clearFilters}
				hasMainFilter={true}
				fileReaderData={isSuperAdmin() && fileReader}
				mainFilters={mainFilters}
				placeholder={t('views.usersAndOrganisations.nfcCards.search')}
				searchEvents={search.events}
				searchValue={search.value}
			/>
			<Table
				body={loadingBody || emptyBody || dataBody}
				data={isObject(fetchNfcCardsData) ? fetchNfcCardsData.results : []}
				handlePageChange={pagination.pageNumberChange}
				handleSorting={handleRequestSort}
				header={tableHeader}
				isNotPaginate={loading || isEmptyResults || !isFullResults}
				loading={fetchNfcCardsLoading}
				order={order}
				orderBy={sortingCategory}
				page={pagination.pageNumber}
				rowsPerPage={pagination.pageSize}
				setRowsPerPage={pagination.pageSizeChange}
				title={`${isObject(fetchNfcCardsData) ? fetchNfcCardsData.total : ''} ${t('views.usersAndOrganisations.nfcCards.listTitle')}`}
				total={fetchNfcCardsData ? fetchNfcCardsData.total : 0}
			/>
			<a ref={linkRef} style={{ display: 'none' }}>
				{' '}
			</a>
			<LinkDrawer
				getNfcData={getNfcData}
				linkNfcData={linkNfcData}
				open={userAction?.type === 'linkNfc'}
				onClose={() => setUserAction(null)}
			/>
			<DownloadCsvDialog
				open={downloadDialogOpen}
				onClose={handleCloseDownloadCsv}
				onClickDownload={handleDownloadCsv}
			/>

			<FormDialog
				title={t('views.userDetails.summary.addNfcCard')}
				open={userAction?.type === 'createNfc'}
				onClose={() => setUserAction(null)}
				fullWidth
				maxWidth='sm'
				loading={isCreateNfcMutating}
				variant='legacy'
				saveLabel={t('ui.add')}
			>
				<CreateNfcTagForm onSubmit={async (value) => triggerCreateNfc(value)} />
			</FormDialog>

			<ConfirmationDialog
				title={t('views.usersAndOrganisations.nfcUnlink.title')}
				subTitle={`${linkNfcData?.userReference?.name || ''} ${t('views.usersAndOrganisations.nfcUnlink.description')}`}
				open={userAction?.type === 'unlinkNfc'}
				loading={isUnlinkNfcMutating}
				onConfirm={async () => await triggerUnlinkNfc(userAction?.id)}
				onClose={() => setUserAction(null)}
			/>

			<ConfirmationDialog
				title={t('views.usersAndOrganisations.deleteNfc.title')}
				subTitle={`${userAction?.id} ${t('views.usersAndOrganisations.deleteNfc.description')}`}
				open={userAction?.type === 'deleteNfc'}
				loading={isDeleteNfcMutating}
				onConfirm={async () => await triggerDeleteNfc(userAction?.id)}
				onClose={() => setUserAction(null)}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		fetchNfcCards: state.paged.fetchNfcCards,
		organisationsList: state.paged.organisations,
		addNfcCsvImports: state.blob.addNfcCsvImports,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchNfcCards: (page, filters) => dispatch(actions.fetchNfcCards(page, filters)),
		onFetchOrganisations: (page, filters, concat) =>
			dispatch(actions.fetchOrganisations(page, filters, concat)),
		onAddNfcCsvImports: (file) => dispatch(actions.addNfcCsvImports(file)),
		onResetBlobState: (state) => dispatch(actions.resetBlobState(state)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(NfcCards);

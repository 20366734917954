import { forwardRef, useEffect, useImperativeHandle } from 'react';

import { ajvResolver } from '@hookform/resolvers/ajv';
import { List, ListItem, ListItemText, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormContainer } from '~components';
import { useFormContainerState } from '~components/dialogs/formContainerProvider';
import { Device } from '~features/devices';
import { OrganisationAutocomplete } from '~features/organisations';
import { FormWrapperRefProps } from '~interfaces/refProps';

import schema from './assignDevicesSchema.json';

interface NewAssignDevicesModel {
	organisationId: string;
	devices: Device[];
}

interface AssignDevicesFormProps {
	onSubmit?: (value: NewAssignDevicesModel) => void;
	value: Partial<NewAssignDevicesModel>;
}

const AssignDevicesForm = forwardRef<FormWrapperRefProps, AssignDevicesFormProps>(
	({ onSubmit, value }, ref) => {
		const { t } = useTranslation();

		const { setDisabled } = useFormContainerState();

		const { getValues, control, formState } = useForm<NewAssignDevicesModel>({
			defaultValues: value,
			mode: 'onChange',
			resolver: ajvResolver(schema as JSONSchemaType<any>),
		});

		useImperativeHandle(
			ref,
			() => ({
				onSubmit: () => onSubmit?.(getValues()),
			}),
			[onSubmit, getValues]
		);

		useEffect(() => {
			setDisabled?.(!formState.isValid);
		}, [formState.isValid, setDisabled]);

		return (
			<FormContainer>
				<Controller
					name='organisation'
					control={control}
					render={({ field }) => (
						<OrganisationAutocomplete
							required
							value={field.value}
							onChange={(_, newValue, reason) => reason !== 'clear' && field.onChange(newValue)}
						/>
					)}
				/>

				<Typography variant='h4'>{t('devices')}</Typography>
				<List>
					{value.devices?.map((el, i, arr) => (
						<ListItem key={el.id} divider={i < arr.length - 1}>
							<ListItemText primary={el.skopeiNumber} secondary={`${el.id} - ${el.hardwareId}`} />
						</ListItem>
					))}
				</List>
			</FormContainer>
		);
	}
);

export default AssignDevicesForm;

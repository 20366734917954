import path from 'path';

import { useAuth } from 'react-oidc-context';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { LoadingFallback } from '~components';
import { pagePathSegments, pagePaths } from '~constants';
import { UserRoleEnum } from '~enums';

interface PublicRouteProps {
	/**
	 * If true, set this path just for an user
	 */
	exclusiveUserRoute?: boolean;
}

/**
 * A route that is only available if a user is not logged in. Is also used if
 * the
 * @returns
 */
const PublicRoute = ({ exclusiveUserRoute = false }: PublicRouteProps) => {
	const auth = useAuth();
	const location = useLocation();

	const role = (auth.user?.profile.role as string)?.toLowerCase() as UserRoleEnum;

	if (auth.isLoading) {
		// Technically we are using authentication in the public route. Show this in dev, but
		// for the other environments this may seem inappropiate. No value for the user, only
		// confusement why it is authenticating for a public route
		return <LoadingFallback description={import.meta.env.DEV ? 'authenticating public' : undefined} />;
	} else if (!auth.isAuthenticated && exclusiveUserRoute) {
		return <Navigate to={`/${pagePaths.AuthLogin}`} replace />;
	} else if (auth.isAuthenticated && role !== UserRoleEnum.User) {
		return <Navigate to={pagePathSegments.Home} replace />;
	} else if (auth.isAuthenticated && !exclusiveUserRoute) {
		return <Navigate to={pagePathSegments.Home} replace />;
	} else if (
		!auth.isAuthenticated &&
		location.pathname.includes(path.join(pagePathSegments.Auth, pagePathSegments.Callback))
	) {
		// The auth callback page seems the weird case. We should enter if authenticated,
		// but we don't want this page to be available when protected.
		// This is just to comply with the oidc standard
		// TODO: should this be part of the public route
		return <Navigate to={pagePathSegments.Home} replace />;
	}

	return <Outlet />;
};

export default PublicRoute;

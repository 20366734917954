import { memo, useState } from 'react';

import { Typography, List, ListItem, Radio, FormControlLabel, Checkbox } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { isBoolean, isObject } from '../../../../../../shared/utility';
import AdditionalCharactaristics from '../AdditionalCharactaristics';
import CharacteristicsCard from '../CharacteristicsCard';
import EditButtons from '../EditButtons';
import { useStyles } from '../style';

interface ParkingLotsCharacteristicsProps {
	className?: string;
	itemStep?: number;
	save?(...args: unknown[]): unknown;
	axleCount?: string;
	parkingLotType?: string;
	isEdit?: boolean;
	isFinishingEdit?: boolean;
	onClose?(...args: unknown[]): unknown;
	hasTrailerCover?: boolean;
	hasChargingPoint?: boolean;
	isCovered?: boolean;
	isRoundTheClock?: boolean;
	cargoSpaceL?: number | object | string;
	cargoSpaceW?: number | object | string;
	cargoSpaceH?: number | object | string;
	maxWeightInKg?: number | object | string;
	grossWeightInKg?: number | object | string;
	characteristics?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const ParkingLotsCharacteristics = memo((props: ParkingLotsCharacteristicsProps) => {
	const {
		itemStep,
		isEdit,

		parkingLotType,
		save,
		onClose,
		isFinishingEdit,
		characteristics,
		hasChargingPoint,
		isCovered,
		isRoundTheClock,
	} = props;
	const { t } = useTranslation();

	const classes = useStyles();

	const {
		data: characteristicsData,
		loading: characteristicsLoading,
		error: characteristicsError,
	} = characteristics;
	const readyData =
		isObject(characteristicsData) && !characteristicsLoading && !characteristicsError;

	const [parkingLotsCharacteristics, setParkingLotsCharacteristics] = useState({
		hasChargingPoint: false,
		isCovered: false,
		isRoundTheClock: false,
	});

	const parkingLot =
		readyData ?
			characteristicsData.parkingLots.filter((parking) =>
				parking.name === 'parkingLotType' ? parking : null,
			)
		:	null;

	const handleParkingLotType = (event, item) => {
		save({ updatedParkingLotType: item.name });
	};

	const handleCharacteristics = (event) => {
		const target = event.target;
		setParkingLotsCharacteristics({ ...parkingLotsCharacteristics, [target.name]: target.checked });
		save({
			[`updated${target.name.charAt(0).toUpperCase() + target.name.substring(1)}`]: target.checked,
		});
	};

	const primaryCharacteristics = [
		{
			label: t('ui.characteristics.hasChargingPoint'),
			checked:
				isBoolean(hasChargingPoint) ? hasChargingPoint : (
					parkingLotsCharacteristics.hasChargingPoint
				),
			action: handleCharacteristics,
			name: 'hasChargingPoint',
		},
		{
			label: t('ui.characteristics.isCovered'),
			checked: isBoolean(isCovered) ? isCovered : parkingLotsCharacteristics.isCovered,
			action: handleCharacteristics,
			name: 'isCovered',
		},
		{
			label: t('ui.characteristics.isRoundTheClock'),
			checked:
				isBoolean(isRoundTheClock) ? isRoundTheClock : parkingLotsCharacteristics.isRoundTheClock,
			action: handleCharacteristics,
			name: 'isRoundTheClock',
		},
	];

	return (
		<>
			<CharacteristicsCard isEdit={isEdit} itemStep={itemStep}>
				<Typography className={classes.labelSpacing} variant='h5'>
					{t('views.addItem.parkingLotsCharacteristics.selectParking')}
				</Typography>
				<List className={classes.listComponent}>
					{readyData &&
						parkingLot[0].enumValues.map((item) => (
							<ListItem
								button={true}
								className={clsx(classes.option)}
								key={item.id}
								onClick={(event) => handleParkingLotType(event, item)}
							>
								<Radio
									checked={parkingLotType === item.name}
									className={classes.optionRadio}
									onChange={(event) => handleParkingLotType(event, item)}
									value={item.name}
								/>
								<div className={classes.optionDetails}>
									<Typography variant='h5'>{t(`ui.characteristics.${item.name}`)}</Typography>
								</div>
							</ListItem>
						))}
				</List>
			</CharacteristicsCard>
			<AdditionalCharactaristics isEdit={isEdit}>
				{primaryCharacteristics.map((item) => (
					<FormControlLabel
						control={<Checkbox checked={item.checked} name={item.name} onChange={item.action} />}
						key={item.name}
						label={item.label}
					></FormControlLabel>
				))}
			</AdditionalCharactaristics>
			{isEdit ?
				<EditButtons isFinishingEdit={isFinishingEdit} onClose={onClose} />
			:	null}
		</>
	);
});

const mapStateToProps = (state) => {
	return {
		characteristics: state.details.characteristics,
	};
};

export default connect(mapStateToProps)(ParkingLotsCharacteristics);

import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { Card, CardHeader, CardContent, Divider, Typography } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useStyles } from './style';
import { ButtonWithAlertDialog } from '../../../../../../components';
import { useError } from '../../../../../../shared/hooks';
import { isObject } from '../../../../../../shared/utility';
import * as actions from '../../../../../../store/actions';

interface OtherActionsProps {
	className?: string;
	onDeleteItem?(...args: unknown[]): unknown;
	itemData?: object;
	deleteItem?: {
		success?: boolean;
		loading?: boolean;
		error?: object | string;
	};
}

const OtherActions = (props: OtherActionsProps) => {
	const { className, onDeleteItem, itemData, deleteItem } = props;
	const { t } = useTranslation();

	const classes = useStyles();

	const { success: deleteSuccess, loading: deleteLoading, error: deleteError } = deleteItem;
	const deleteUserDone = deleteSuccess && !deleteLoading && !deleteError;

	const deleteItemMessage = useError(
		isObject(deleteItem) ?
			{
				value: deleteItem,
				message: `${t('ui.successfullyDeleted')} ${itemData.name}`,
			}
		:	null,
	);

	const handleDeleteItem = () => {
		onDeleteItem(itemData.id);
		deleteItemMessage.setStartAction(true);
	};

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader title={t('views.userDetails.summary.cardHeaders.otherActions')} />
			<Divider />
			<CardContent>
				<Typography className={classes.notice} variant='body2'>
					{t('views.itemDetail.summary.otherActions.text')}
				</Typography>
				<ButtonWithAlertDialog
					actionDone={deleteUserDone}
					callback={handleDeleteItem}
					className={classes.deleteButton}
					dialogDescription={`${t('ui.deleted.dialogDescription')} ${itemData.name} ${t('ui.permanently')}`}
					dialogLoading={deleteLoading && !deleteUserDone}
					dialogTitle={t('ui.delete')}
					variant='inline-delete'
				>
					<DeleteIcon className={classes.buttonIcon} />
					{t('ui.button.inline.delete')}
				</ButtonWithAlertDialog>
			</CardContent>
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		deleteItem: state.condition.deleteItem,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onDeleteItem: (id) => dispatch(actions.deleteItem(id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OtherActions);

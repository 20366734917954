import { useState, useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import PhotoIcon from '@mui/icons-material/InsertPhoto';
import { Typography, IconButton } from '@mui/material';
import FileReaderInput from 'react-file-reader-input';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useStyles } from './style';
import { AlertDialog } from '../../../../components';
import { useError } from '../../../../shared/hooks';
import { isFullString, isObject } from '../../../../shared/utility';
import * as actions from '../../../../store/actions';

interface IconsUploadProps {
	characteristic?: object;
	characteristicEnumValues?: object;
	removeCharacteristicsIcon?: any;
	removeCharacteristicsIconNested?: any;
	addIcon?: {
		data?: unknown[];
		loading?: boolean;
		error?: object | string;
	};
	addIconNested?: {
		data?: unknown[];
		loading?: boolean;
		error?: object | string;
	};
	onAddCharacteristicsIcon?(...args: unknown[]): unknown;
	onAddCharacteristicsIconNested?(...args: unknown[]): unknown;
	onRemoveCharacteristicsIcon?(...args: unknown[]): unknown;
	onRemoveCharacteristicsIconNested?(...args: unknown[]): unknown;
	updateState?(...args: unknown[]): unknown;
	characteristicsData?: {
		cars?: unknown[];
		bikes?: unknown[];
		offices?: unknown[];
	};
}

const IconsUpload = (props: IconsUploadProps) => {
	const {
		characteristic,
		characteristicEnumValues,

		onAddCharacteristicsIcon,
		onAddCharacteristicsIconNested,
		onRemoveCharacteristicsIcon,
		onRemoveCharacteristicsIconNested,
		characteristicsData,
		addIcon,
		addIconNested,
		removeCharacteristicsIcon,
		removeCharacteristicsIconNested,
	} = props;
	const { t } = useTranslation();
	const classes = useStyles();
	const [previewIcon, setPreviewIcon] = useState('');

	const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
	const [previewIconNested, setPreviewIconNested] = useState('');

	const addCharacteristicsIconMessage = useError({
		value: addIcon,
		message: `${t('ui.successfully')} ${t('ui.updated')} ${t('ui.label.iconLower')}`,
	});

	const removeCharacteristicsIconMessage = useError({
		value: removeCharacteristicsIcon,
		message: `${t('ui.successfully')} ${t('ui.removed')} ${t('ui.label.iconLower')}`,
	});

	const addCharacteristicsIconNestedMessage = useError({
		value: addIconNested,
		message: `${t('ui.successfully')} ${t('ui.updated')} ${t('ui.label.iconLower')}`,
	});

	const removeCharacteristicsIconNestedMessage = useError({
		value: removeCharacteristicsIconNested,
		message: `${t('ui.successfully')} ${t('ui.removed')} ${t('ui.label.iconLower')}`,
	});

	useEffect(() => {
		if (isObject(characteristicEnumValues)) {
			setPreviewIconNested(characteristicEnumValues.icon);
		} else if (isObject(characteristic)) {
			setPreviewIcon(characteristic.icon);
		}
	}, [characteristic, characteristicEnumValues]);

	const handleOnChange = (e, results) => {
		if (isObject(characteristic)) {
			const [progressEvent, file] = results[0];
			if (isObject(characteristicEnumValues)) {
				setPreviewIconNested(progressEvent.target.result);
				onAddCharacteristicsIconNested(
					characteristic.id,
					characteristicEnumValues.id,
					[file],
					characteristicsData,
				);
				addCharacteristicsIconNestedMessage.setStartAction(true);
			} else {
				setPreviewIcon(progressEvent.target.result);
				onAddCharacteristicsIcon(characteristic.id, [file], characteristicsData);
				addCharacteristicsIconMessage.setStartAction(true);
			}
		}
	};

	const handleCloseDialog = () => {
		setDeleteAlertOpen(false);
	};

	const handleConfirmDialog = () => {
		if (isFullString(previewIconNested)) {
			const parts = previewIconNested.split('_');
			const imageIdNested = parts[parts.length - 1].split('.')[0];
			if (imageIdNested) {
				onRemoveCharacteristicsIconNested(
					characteristic.id,
					characteristicEnumValues.id,
					imageIdNested,
					characteristicsData,
				);
				removeCharacteristicsIconNestedMessage.setStartAction(true);
			}
		} else if (previewIcon) {
			const parts = previewIcon.split('_');
			const imageId = parts[parts.length - 1].split('.')[0];
			onRemoveCharacteristicsIcon(characteristic.id, imageId, characteristicsData);
			removeCharacteristicsIconMessage.setStartAction(true);
		}
		setDeleteAlertOpen(false);
	};

	return isFullString(previewIconNested) || isFullString(previewIcon) ?
			<>
				<div className={classes.iconPreview}>
					<div className={classes.iconContainer}>
						<img
							alt='characteristics'
							className={classes.icon}
							src={previewIconNested || previewIcon}
						/>
					</div>
					<IconButton
						className={classes.removeIconButton}
						onClick={() => {
							setDeleteAlertOpen(true);
						}}
						size='small'
					>
						<CloseIcon />
					</IconButton>
				</div>
				<AlertDialog
					dialogDescription={
						characteristicEnumValues ?
							`${t(`ui.characteristics.${characteristicEnumValues.name}`)} icon`
						:	`${t(`ui.characteristics.${characteristic.name}`)} icon`
					}
					dialogTitle={t('ui.delete')}
					handleClose={handleCloseDialog}
					handleConfirm={handleConfirmDialog}
					open={deleteAlertOpen}
				/>
			</>
		:	<FileReaderInput
				accept={['image/png', 'image/svg+xml']}
				multiple={false}
				onChange={handleOnChange}
			>
				<div className={classes.uploadButton}>
					<PhotoIcon />
					<Typography className={classes.uploadButtonText}>{t('ui.upload')}</Typography>
				</div>
			</FileReaderInput>;
};

const mapStateToProps = (state) => {
	return {
		addIcon: state.details.addCharacteristicsIcon,
		addIconNested: state.details.addCharacteristicsIconNested,
		removeCharacteristicsIcon: state.details.removeCharacteristicsIcon,
		removeCharacteristicsIconNested: state.details.removeCharacteristicsIconNested,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onAddCharacteristicsIcon: (characteristicId, imageArray, characteristicsData) =>
			dispatch(actions.addCharacteristicsIcon(characteristicId, imageArray, characteristicsData)),
		onAddCharacteristicsIconNested: (
			characteristicId,
			enumValueId,
			imageArray,
			characteristicsData,
		) =>
			dispatch(
				actions.addCharacteristicsIconNested(
					characteristicId,
					enumValueId,
					imageArray,
					characteristicsData,
				),
			),
		onRemoveCharacteristicsIcon: (characteristicId, imageId, characteristicsData) =>
			dispatch(actions.removeCharacteristicsIcon(characteristicId, imageId, characteristicsData)),
		onRemoveCharacteristicsIconNested: (
			characteristicId,
			enumValue,
			imageId,
			characteristicsData,
		) =>
			dispatch(
				actions.removeCharacteristicsIconNested(
					characteristicId,
					enumValue,
					imageId,
					characteristicsData,
				),
			),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(IconsUpload);

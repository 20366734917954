import path from 'path';

import { TopologyService } from '~services';
import { decapitalizeFirstCharacter } from '~utils/stringUtils';

import LockStateEnum from '../enums/lockStateEnum';
import Heartbeat, { SharedHeartbeat } from '../interfaces/heartbeat';

class DeviceHeartbeatsService extends TopologyService {
	protected readonly path = 'devices';

	public readonly latestHeartbeatSubPath = 'heartbeats/latest';

	constructor() {
		super('v1');
	}

	/**
	 * Get a list of access rules
	 * @param page The number of the page
	 * @param pageSize The amount of results of the page
	 * @returns
	 */
	async getLatestHeartbeat(deviceId: string): Promise<SharedHeartbeat> {
		const { data } = await this._client.get<SharedHeartbeatResponse>(
			path.join(this.path, deviceId, this.latestHeartbeatSubPath)
		);

		return DeviceHeartbeatsService.fromResponse(data);
	}

	/**
	 * At the moment of writing (20250130) BMW is deprecated in Topology
	 * and also in the SDS. It seems the SDS already doesn't return a a response
	 * or just a 204 with no body.
	 * But declare this function to keep the original flow intact.
	 * Obviously over time, this function should be removed.
	 * @param deviceId
	 * @returns
	 * @deprecated All of BMW in its current form is deprecated
	 */
	async getBmwVehicleStatus(deviceId: string): Promise<null> {
		await this._client.get<null>(path.join(this.path, deviceId, 'vehiclestatus'));

		return null;
	}

	static fromResponse(data: SharedHeartbeatResponse): SharedHeartbeat {
		const {
			lastUpdated,
			ignition,
			immobiliserStateEnabled,
			doors,
			lockState,
			batteryLevel,
			batteryLevelEnum,
			batteryCharging,
			location,
			latitude,
			longitude,
			...rest
		} = data;

		return {
			...rest,
			immobiliserEnabled: immobiliserStateEnabled,
			doorsState: doors?.map((el) => ({
				...el,
				location: decapitalizeFirstCharacter(el.location),
			})),
			ignitionDisabled: ignition != null ? ignition.toLowerCase() === 'disabled' : undefined,
			dateUpdated: lastUpdated ? new Date(lastUpdated) : undefined,
			lockState: lockState != null ? (lockState as LockStateEnum) : undefined,
			deviceBatteryLevel: batteryLevel,
			deviceBatteryLevelEnum: batteryLevelEnum,
			batteryIsCharging: batteryCharging,
			location:
				location ??
				(latitude != null && longitude != null ?
					{ latitude: latitude, longitude: longitude } : undefined),
		};
	}
}

interface BaseHeartbeatResponse {
	lastUpdated: string;
	batteryLevel: number; // the device battery level
	batteryLevelEnum: string;
	deviceId: string;
	range: number;
	locationType: string;
}

type SharedHeartbeatResponse = Partial<CarHeartbeatResponse> &
	Partial<GenericLockHeartbeatResponse> &
	Partial<BikeLockHeartbeatResponse> &
	BaseHeartbeatResponse;

interface CarHeartbeatResponse extends BaseHeartbeatResponse {
	cell: object;
	location: {
		accuracy: number;
		latitude: number;
		longitude: number;
	};
	mileage: number;
	lockState: 'locked' | 'unlocked' | 'secured';
	doors: {
		location: 'Driver' | 'Passenger' | 'DriverRear' | 'PassengerRear';
		open: boolean;
	}[];
	vehicleFuelVolume?: number;
	vehicleBatteryLevel?: number;
	immobiliserStateEnabled: boolean;
	ignition: string;
	batteryCharging: boolean;
	keyDetected: boolean;
	rangeElectric?: number;
}

// Shared by doorlock, trailerlock, batterybackup, cpac
interface GenericLockHeartbeatResponse extends BaseHeartbeatResponse {
	firmwareVersion: string;
	latitude: number;
	longitude: number;
}

interface BikeLockHeartbeatResponse extends BaseHeartbeatResponse {
	firmwareVersion: string;
	temperature: number;
	state: string;
	type: string;
	cycles: number;
	borderRouterId: string;
	eventCode: number;
	communicationType: string[];
	mileage: number;
	lockState: number;
	immobiliserStateEnabled: boolean;
}

export default DeviceHeartbeatsService;

import { useEffect, useState } from 'react';

import {
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Checkbox,
	Divider,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	Box,
} from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { useStyles } from './style';
import { isArray, isEmptyArray, isFullArray, isFullString, isObject, isUndefined } from '../../../shared/utility';
import { TableEditBar } from '../../elements';
import { LoadingBar } from '../../loading';

interface CustomTableProps {
	className?: string;
	title?: string;
	total?: number;
	timePicker?: object;
	loading?: boolean;
	isNotPaginate?: boolean;
	handleSorting?(...args: unknown[]): unknown;
	handlePageChange?(...args: unknown[]): unknown;
	orderBy?: string;
	order?: string;
	header?: unknown[];
	body?: unknown[] | boolean;
	data?: unknown[];
	page?: number;
	rowsPerPage?: number;
	setRowsPerPage?(...args: unknown[]): unknown;
	hasSelectionEnabled?: boolean;
	editActionButtons?: unknown[];
	titleWithInformation?: object;
	noTitle?: boolean;
	cellStyle?: string;
	headerLabel?: string;
	checkboxAligning?: boolean;
	removeMrgin?: boolean;
}

const CustomTable = (props: CustomTableProps) => {
	const {
		cellStyle,
		className,
		title,
		total,
		header,
		body,
		data,
		handleSorting,
		orderBy,
		order,
		page,
		handlePageChange,
		rowsPerPage,
		setRowsPerPage,
		hasSelectionEnabled,
		editActionButtons,

		timePicker,
		isNotPaginate,
		titleWithInformation,
		noTitle,
		headerLabel,
		checkboxAligning,
		loading,
		removeMrgin,
	} = props;
	const { t } = useTranslation();

	const classes = useStyles();

	const [selected, setSelected] = useState([]);
	const [dataSelected, setDataSelected] = useState([]);

	const selectData = useSelectData(
		selected,
		setSelected,
		dataSelected,
		setDataSelected,
		data,
		loading,
	);

	const handleChangeRowsPerPage = (event) => setRowsPerPage(event.target.value);

	const onPageChange = (e, page) => handlePageChange(page + 1);

	const handleLabelDisplayedRows = ({ from, to, count }) =>
		`${from}-${to} ${t('ui.of')} ${count !== -1 ? count : '0'}`;

	const onSorting = (cellName) => () => handleSorting(cellName);

	const handleSelect = (rIndex, event) => () => selectData.handleSelectOne(event, rIndex);

	const actions = {
		changeRows: handleChangeRowsPerPage,
		changePage: onPageChange,
		displayedRows: handleLabelDisplayedRows,
		sorting: onSorting,
		handleSelect: handleSelect,
	};

	return (
		<div className={clsx(classes.root, className)}>
			<Card className={removeMrgin ? classes.mainCardMrgin : classes.mainCard}>
				{noTitle ?
					null
				: isFullString(title) ?
					<CardHeader title={title} />
				: timePicker ?
					<Box className={classes.timePicker} display='flex'>
						{timePicker}
					</Box>
				: isObject(titleWithInformation) ?
					<Box display='flex' justifyContent='space-between' pb={2} pl={3} pr={3} pt={2}>
						<Typography variant='h5'>{titleWithInformation.header}</Typography>
						{titleWithInformation.sideHeader ?
							<Typography variant='h6'>{`${isFullString(headerLabel) ? headerLabel : t('ui.label.total')}: ${titleWithInformation.sideHeader}`}</Typography>
						:	''}
					</Box>
				:	<Box display='flex' justifyContent='space-between' pb={2} pl={3} pr={3} pt={2}>
						<LoadingBar />
					</Box>
				}

				{noTitle ? null : <Divider />}
				<CardContent className={classes.content}>
					<PerfectScrollbar>
						<div className={classes.inner}>
							<Table>
								<TableHead>
									<TableRow hover={false}>
										{checkboxAligning ?
											<TableCell className={classes.cellSpacing}></TableCell>
										:	null}
										{hasSelectionEnabled ?
											<TableCell className={classes.cellSpacing} padding='checkbox'>
												<Checkbox
													checked={selected.length === body.length}
													color='primary'
													indeterminate={selected.length > 0 && selected.length < body.length}
													onChange={selectData.handleSelectAll}
												/>
											</TableCell>
										:	null}
										{header.map((cell) => (
											<TableCell key={cell.name}>
												{cell.hasSorting ?
													<TableSortLabel
														active={orderBy === cell.name}
														direction={order === 'asc' ? 'desc' : 'asc'}
														onClick={actions.sorting(cell.name)}
													>
														{cell.content}
													</TableSortLabel>
												:	cell.content}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{isArray(body) &&
										body?.map((row, rIndex) => (
											<TableRow
												hover={false}
												key={rIndex}
												selected={selected.indexOf(rIndex) !== -1}
											>
												{checkboxAligning ?
													<TableCell className={classes.cellSpacing}></TableCell>
												:	null}
												{hasSelectionEnabled ?
													<TableCell className={classes.cellSpacing} padding='checkbox'>
														<Checkbox
															checked={selected.indexOf(rIndex) !== -1}
															color='primary'
															onChange={actions.handleSelect(rIndex)}
															value={selected.indexOf(rIndex) !== -1}
														/>
													</TableCell>
												:	null}
												{row.map((cell, cIndex) => (
													<TableCell
														className={
															(cIndex === 0 || cell.classCell) && !isFullString(cell.classCell) ?
																null
															: isFullString(cellStyle) ?
																cellStyle
															:	cell.classCell
														}
														key={cIndex}
													>
														{cell.loading ?
															<LoadingBar />
														: cell.content ?
															cell.content
														:	<p>-</p>}
													</TableCell>
												))}
											</TableRow>
										))}
								</TableBody>
							</Table>
						</div>
					</PerfectScrollbar>
				</CardContent>
				{isNotPaginate ? null : (
					<CardActions className={classes.actions}>
						<TablePagination
							component='div'
							count={total}
							labelDisplayedRows={actions.displayedRows}
							labelRowsPerPage={t('ui.rowsPerPage')}
							onPageChange={actions.changePage}
							onRowsPerPageChange={actions.changeRows}
							page={page - 1}
							rowsPerPage={rowsPerPage}
							rowsPerPageOptions={[5, 10, 20, 40, 50]}
						/>
					</CardActions>
				)}
				<TableEditBar actionButtons={editActionButtons} selected={dataSelected} />
			</Card>
		</div>
	);
};

function useSelectData(selected, setSelected, dataSelected, setDataSelected, data, loading) {
	useEffect(() => {
		if (!isEmptyArray(dataSelected)) {
			const newSelected = data.reduce(
				(acc, item, index) => [
					...acc,
					...((
						!isUndefined(
							dataSelected.find((selectedItem) =>
								!isUndefined(selectedItem.innerId) ?
									selectedItem.innerId === item.innerId
								:	selectedItem.id === item.id,
							),
						)
					) ?
						[index]
					:	[]),
				],
				[],
			);

			setSelected(newSelected);
		}
	}, [data]);

	useEffect(() => {
		if (loading) {
			setDataSelected([]);
			setSelected([]);
		}
	}, [loading]);

	const handleSelectOne = (event, index) => {
		if (isFullArray(data)) {
			const selectedIndex = selected.indexOf(index);
			const dataSelectedIndex = dataSelected.map((item) => item.id).indexOf(data[index].id);

			const newSelected =
				selectedIndex === -1 ?
					[...selected, index]
				:	[...selected.slice(0, selectedIndex), ...selected.slice(selectedIndex + 1)];
			const newDataSelected =
				selectedIndex === -1 ?
					[...dataSelected, data[index]]
				:	[
						...dataSelected.slice(0, dataSelectedIndex),
						...dataSelected.slice(dataSelectedIndex + 1),
					];

			setSelected(newSelected);
			setDataSelected(newDataSelected);
		}
	};

	const handleSelectAll = (event) => {
		setSelected(event.target.checked ? data.map((_, index) => index) : []);
		setDataSelected(event.target.checked ? data : []);
	};
	return {
		handleSelectOne,
		handleSelectAll,
	};
}

export default CustomTable;

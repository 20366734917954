import { FETCH_BLOB_START, FETCH_BLOB_SUCCESS, FETCH_BLOB_FAIL } from './actionTypes';
import events, { Counter } from './eventServices';
import { isFullArray, isNull, isInteger, stringifyQueryParams } from '../../shared/utility';
import { blobStates } from '../states';
/* * * * * * * * * * * * * * *
 * ACTIVE ACTION TYPE METHODS *
 * * * * * * * * * * * * * *  */
const BlobStatesCounter = new Counter(blobStates);

// action type methods return current active action type that is determined by the state of the fetch requests.
// Also these methods pass data passed from user methods to Redux reducers to update states
const fetchBlobStart = (identifier) => {
	return {
		type: FETCH_BLOB_START,
		identifier: identifier,
	};
};

const fetchBlobSuccess = (identifier, data = null) => {
	BlobStatesCounter.reset(identifier);
	return {
		type: FETCH_BLOB_SUCCESS,
		identifier: identifier,
		data: data,
	};
};

const fetchBlobFail = (
	identifier,
	error = 'Error message missing. Please contact site administrator.',
) => {
	BlobStatesCounter.reset(identifier);
	return {
		type: FETCH_BLOB_FAIL,
		identifier: identifier,
		error: error,
	};
};

export const resetBlobState = (identifier) => {
	return (dispatch) => {
		dispatch(fetchBlobSuccess(identifier));
	};
};

/* * * * * * * * * * * * *
 * BILLING PERIOD METHODS *
 * * * * * * * * * * * * */
export const billingReport = (
	type = null,
	dateTime = null,
	id = null,
	organisationId = null,
	exportType = null,
) => {
	const routingType = {
		personal: 'billings',
		expenses: 'expenses',
		revenue: 'revenues',
	};

	const entityType = {
		personal: 'users',
		expenses: 'partners',
		revenue: 'partners',
	};

	const exportFor =
		organisationId != null ?
			`organisationId=${organisationId}&exportType=${exportType}`
		:	`exportType=${exportType}`;

	return async (dispatch) => {
		dispatch(fetchBlobStart('billingReport'));
		try {
			const data = await events.get(
				`finances/${routingType[type]}/${dateTime}/${entityType[type]}/${id}/bookings/exports?${exportFor}`,
				false,
				false,
				true,
			);
			dispatch(fetchBlobSuccess('billingReport', data));
		} catch (error) {
			dispatch(fetchBlobFail('billingReport', error));
		}
	};
};

export const addCSVInstances = (id = null, file = null) => {
	const bodyData = isFullArray(file) ? new FormData() : null;

	if (!isNull(bodyData)) {
		for (let i = 0; i < file.length; i++) {
			const newFile = file[i];
			bodyData.append('file', newFile);
		}
	}

	return async (dispatch) => {
		dispatch(fetchBlobStart('addCSVInstances'));
		try {
			const data = await events.post(
				`items/${id}/iteminstances/imports/csv`,
				bodyData,
				false,
				true,
				true,
			);
			dispatch(fetchBlobSuccess('addCSVInstances', data));
		} catch (error) {
			dispatch(fetchBlobFail('addCSVInstances', error));
		}
	};
};

export const addNfcCsvImports = (file = null) => {
	const bodyData = isFullArray(file) ? new FormData() : null;

	if (!isNull(bodyData)) {
		for (let i = 0; i < file.length; i++) {
			const newFile = file[i];
			bodyData.append('file', newFile);
		}
	}

	return async (dispatch) => {
		dispatch(fetchBlobStart('addNfcCsvImports'));
		try {
			const data = await events.post('nfccards/imports/csv', bodyData, false, true, true);
			dispatch(fetchBlobSuccess('addNfcCsvImports', data));
		} catch (error) {
			dispatch(fetchBlobFail('addNfcCsvImports', error));
		}
	};
};

export const removeItemInstances = (bodyData = null) => {
	return async (dispatch) => {
		dispatch(fetchBlobStart('removeItemInstances'));
		try {
			const data = await events.put('iteminstances/remove', bodyData, false, false, true);
			dispatch(fetchBlobSuccess('removeItemInstances', data));
		} catch (error) {
			dispatch(fetchBlobFail('removeItemInstances', error));
		}
	};
};

import { Chip, ChipOwnProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

import ApprovalStateEnum from '../../enums/approvalStateEnum';
import BookingStatusEnum from '../../enums/bookingStatusEnum';
import UsageStateEnum from '../../enums/usageStateEnum';

interface BookingStatusChipProps {
	status: BookingStatusEnum; // A general status
	usageState: UsageStateEnum;
	approvalState: ApprovalStateEnum;
	isNoShow: boolean;
}

const BookingStatusChip = ({ status, usageState, approvalState, isNoShow }: BookingStatusChipProps) => {
	const { t } = useTranslation();

	const getStatusLabel = (): string => {
		let translationKey: string;

		if (isNoShow === true) {
			translationKey = 'ui.status.noShow';
		} else {
			switch (status) {
				case BookingStatusEnum.Completed:
					translationKey = 'ui.status.completed';
					break;
				case BookingStatusEnum.Cancelled:
					translationKey =
						approvalState === ApprovalStateEnum.Declined ?
							'ui.status.rejected'
						:	'ui.status.cancelled';
					break;
				case BookingStatusEnum.Upcoming:
					translationKey =
						approvalState === ApprovalStateEnum.Pending ?
							'ui.status.pending'
						:	'ui.status.upcoming';
					break;
				case BookingStatusEnum.Active:
					if (usageState === UsageStateEnum.Ready) {
						translationKey = 'ui.status.readyToStart';
						break;
					} else if (usageState === UsageStateEnum.Started) {
						translationKey = 'ui.status.inUse';
						break;
					}

					translationKey = 'ui.label.active';
					break;
				case BookingStatusEnum.Overtime:
					translationKey = 'ui.status.overtime';
					break;
				default:
					translationKey = 'unknown';
					console.warn('Unknown booking status');
					break;
			}
		}

		return t(translationKey);
	};

	const getStatusColor = (): ChipOwnProps['color'] => {
		if (isNoShow) {
			return 'error';
		}

		let color: ChipOwnProps['color'];
		switch (status) {
			case BookingStatusEnum.Completed:
				color = 'primary';
				break;
			case BookingStatusEnum.Active:
				color = 'success';
				break;
			case BookingStatusEnum.Upcoming:
				color = 'info';
				break;
			case BookingStatusEnum.Overtime:
				color = 'error';
				break;
			case BookingStatusEnum.Cancelled:
				color = 'error';
				break;
			default:
				color = 'default';
				break;
		}

		return color;
	};

	return <Chip size='small' label={getStatusLabel()} color={getStatusColor()} />;
};

export default BookingStatusChip;

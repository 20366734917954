import { useState, useRef, useEffect, useCallback } from 'react';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import EditIcon from '@mui/icons-material/Edit';
import {
	Card,
	CardActions,
	CardContent,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Typography,
	Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import EditInstanceInfo from './EditInstanceInfo/EditInstanceInfo';
import { Label, StyledButton } from '../../../../../../components';
import { isObject, isNull, instanceName, instanceLabel } from '../../../../../../shared/utility';
import * as actions from '../../../../../../store/actions/index';
import { useStyles } from '../style';

interface InstanceInfoProps {
	instanceData?: object;
	isDeviceMileagePresent?: boolean;
	itemInstanceQrCode?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onFetchItemInstanceQrCode?(...args: unknown[]): unknown;
}

const InstanceInfo = (props: InstanceInfoProps) => {
	const { instanceData, itemInstanceQrCode, onFetchItemInstanceQrCode, isDeviceMileagePresent } =
		props;
	const { t } = useTranslation();
	const { data: qrCodeBlob, loading: qrCodeLoading } = itemInstanceQrCode;
	const [download, setDownload] = useState(false);
	const [openEditDialog, setOpenEditDiaog] = useState(false);

	const linkRef = useRef(null);

	const classes = useStyles();

	const isCar = isObject(instanceData.itemCar);

	const isBike = isObject(instanceData.itemBike);

	const isTrailer = isObject(instanceData.itemTrailer);

	const handleButton = (e) => {
		e.preventDefault();
		onFetchItemInstanceQrCode(instanceData.itemReference.id, instanceData.id);
		setDownload(true);
	};

	const handleEditOpen = () => {
		setOpenEditDiaog(true);
	};

	const handleEditClose = () => {
		setOpenEditDiaog(false);
	};

	useEffect(() => {
		if (!qrCodeLoading && !isNull(qrCodeBlob) && download) {
			const ext = qrCodeBlob.type.substring(qrCodeBlob.type.lastIndexOf('/') + 1);
			const instanceNameValue = instanceName(instanceData);
			linkRef.current.href = URL.createObjectURL(qrCodeBlob);
			linkRef.current.download = `${instanceNameValue}.${ext}`;
			linkRef.current.click();
			setDownload(false);
		}
	}, [qrCodeLoading, download]);

	const handleCo2 = useCallback(() => {
		if (instanceData?.itemCar?.gramsPerKilometer > 0) {
			// Convert grams to kilograms and round to 2 decimal places
			return instanceData.itemCar.gramsPerKilometer;
		} else if (instanceData?.itemCar?.gramsPerKilometer === 0) {
			// If CO2 is exactly 0
			return '0';
		} else {
			// If CO2 is less than 0 or not a valid number
			return '-';
		}
	}, [isCar, instanceData]);

	const infoData = [
		{
			label: instanceLabel(instanceData.categoryReference.type),
			content: instanceData.name,
		},
		...(isBike ?
			[{ label: 'ui.label.frameNumber', content: instanceData.itemBike.frameNumber }]
		:	[]),
		{ label: 'ui.label.id', content: instanceData.id },
		...(isTrailer ?
			[{ label: 'ui.label.vinNumber', content: instanceData.itemTrailer.vinNumber }]
		:	[]),
		...(isCar && !isDeviceMileagePresent ?
			[{ label: 'ui.label.mileage', content: instanceData.itemCar.mileageKm }]
		:	[]),
		...(isCar ?
			[{ label: 'ui.label.co2GramsPerKilometer', content: <span>{handleCo2()}</span> }]
		:	[]),
	];

	return (
		<Card className={classes.maiCard}>
			<Box display='flex' justifyContent='space-between' pb={2} pl={3} pr={3} pt={2}>
				<Typography variant='h5'>{t('views.instanceDetails.instanceInfo')}</Typography>
				<Label type={instanceData.isAvailable ? 'success' : 'error'}>
					{instanceData.isAvailable ? t('ui.status.available') : t('ui.status.unavailable')}
				</Label>
			</Box>
			<Divider />
			<CardContent className={classes.content}>
				<Table>
					<TableBody>
						{infoData.map((item, index) => (
							<TableRow key={item.label}>
								<TableCell>{index === 0 ? item.label : t(item.label)}</TableCell>
								<TableCell>{item?.content ? item.content : '-'}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
				{instanceData.qrCodeUrl ?
					<Box display='flex' flexDirection='column' p={2}>
						<Typography>{'QR-code'}</Typography>
						<Box alignSelf='center' pt={4}>
							<img alt='qrCode' className={classes.qrCode} src={instanceData.qrCodeUrl} />
						</Box>
						<Box alignSelf='center'>
							<a ref={linkRef} style={{ display: 'none' }} />
							<StyledButton
								onClick={handleButton}
								startIcon={<ArrowDownwardIcon />}
								variant='inline-default'
							>
								{t('views.instanceDetails.qrCode')}
							</StyledButton>
						</Box>
					</Box>
				:	null}
			</CardContent>
			<>
				<CardActions>
					<StyledButton onClick={handleEditOpen} startIcon={<EditIcon />} variant='inline-default'>
						{t('ui.button.inline.edit')}
					</StyledButton>
				</CardActions>
				<EditInstanceInfo
					instanceData={instanceData}
					isDeviceMileagePresent={isDeviceMileagePresent}
					onClose={handleEditClose}
					open={openEditDialog}
				/>
			</>
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		itemInstanceQrCode: state.details.itemInstanceQrCode,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchItemInstanceQrCode: (id, instanceId) =>
			dispatch(actions.fetchItemInstanceQrCode(id, instanceId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(InstanceInfo);

import { useReducer, useEffect } from 'react';

import EuroIcon from '@mui/icons-material/Euro';
import GavelOutlinedIcon from '@mui/icons-material/GavelOutlined';
import LocalPoliceOutlinedIcon from '@mui/icons-material/LocalPoliceOutlined';
import { Link } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { userInfoAtom } from '~atoms';

import ConsumerTable from './ConsumerTable';
import { consumersReducer } from './reducer';
import { SearchBar, EmptyState } from '../../../components';
import { isFullArray, isEmptyArray, isInteger } from '../../../shared/utility';
import * as actions from '../../../store/actions';
import { usePagedCall, usePagedCallTerms, stateType } from '../hooks';
import {
	useSharingsConsumersData,
	useConsumerSharingItems,
	useInternalSharingItems,
	usePublicSharingItems,
} from '../Management/hooks';
import { useStyles } from '../Management/style';
import { itemsReducer } from '../ManagementConstants';

interface ConsumersManagementProps {
	onFetchUserGroups?(...args: unknown[]): unknown;
	onFetchTerms?(...args: unknown[]): unknown;
	onFetchItems?(...args: unknown[]): unknown;
	onFetchPolicies?(...args: unknown[]): unknown;
	onFetchPricingModels?(...args: unknown[]): unknown;
}

const ConsumersManagement = (props: ConsumersManagementProps) => {
	const {
		onFetchItems,
		onFetchUserGroups,
		onFetchPricingModels,
		onFetchTerms,
		onFetchPolicies,
	} = props;
	const { t } = useTranslation();
	const userInfo = useAtomValue(userInfoAtom);

	const classes = useStyles();

	const navigate = useNavigate();
	const [consumers, dispatchConsumers] = useReducer(consumersReducer, []);
	const [selectedItems, dispatchSelectedItems] = useReducer(itemsReducer, []);

	const slimFilters = {
		sortBy: 'name',
		orderDescending: false,
	};

	const slimItems = usePagedCall(onFetchItems, stateType.ITEMSSLIM, {
		...slimFilters,
		includePublic: false,
	});
	const slimUserGroups = usePagedCall(onFetchUserGroups, stateType.USERGROUPSSLIM, {
		...slimFilters,
	});
	const slimPricingModels = usePagedCall(onFetchPricingModels, stateType.PRICINGMODELSSLIM, {
		...slimFilters,
	});
	const slimTerms = usePagedCallTerms(onFetchTerms, stateType.TERMSSLIM, {
		sortBy: 'title',
		orderDescending: false,
	});
	const slimPolicies = usePagedCall(onFetchPolicies, stateType.POLICIESSLIM, { ...slimFilters });

	const filter = {
		...(isInteger(slimUserGroups.state.id) && { userGroupId: slimUserGroups.state.id }),
		...(isInteger(slimPricingModels.state.id) && { pricingModelId: slimPricingModels.state.id }),
		...(isInteger(slimTerms.state.id) && { termsId: slimTerms.state.id }),
		...(isInteger(slimPolicies.state.id) && { policyId: slimPolicies.state.id }),
		...(isInteger(slimItems.state.id) && { itemId: slimItems.state.id }),
	};

	const sharingsConsumers = useSharingsConsumersData(filter);
	const consumerItems = useConsumerSharingItems(filter);
	const internalItems = useInternalSharingItems(filter);
	const publicItems = usePublicSharingItems(filter);

	const handleFetchList = () => sharingsConsumers.setFetchData(true);

	const lazyFilters = [
		{
			...slimItems,
			fetchList: handleFetchList,
			key: stateType.ITEMSSLIM,
			placeholder: t('views.planboard.filterbar.filters.items.all'),
		},
		{
			...slimUserGroups,
			fetchList: handleFetchList,
			key: stateType.USERGROUPSSLIM,
			placeholder: t('ui.placeholder.allUserGroups'),
		},
		{
			...slimPricingModels,
			fetchList: handleFetchList,
			key: stateType.PRICINGMODELSSLIM,
			placeholder: t('views.assets.pricing.table.title'),
		},
		{
			...slimTerms,
			fetchList: handleFetchList,
			key: stateType.TERMSSLIM,
			placeholder: t('ui.label.terms.all'),
		},
		{
			...slimPolicies,
			fetchList: handleFetchList,
			key: stateType.POLICIESSLIM,
			placeholder: t('ui.label.policies.all'),
		},
	];

	const handleClearFilters = () => {
		slimItems.resetInitialState();
		slimUserGroups.resetInitialState();
		slimPricingModels.resetInitialState();
		slimTerms.resetInitialState();
		slimPolicies.resetInitialState();
		handleFetchList();
	};

	const clearFilters = {
		clear:
			isInteger(slimUserGroups.state.id) ||
			isInteger(slimPricingModels.state.id) ||
			isInteger(slimTerms.state.id) ||
			isInteger(slimPolicies.state.id) ||
			isInteger(slimItems.state.id),
		btnText: 'ui.button.inline.clearfilters',
		action: handleClearFilters,
	};

	useEffect(() => {
		sharingsConsumers.setConsumerId(userInfo.organisation.id);
	}, []);

	useEffect(() => {
		if (sharingsConsumers.newData && isFullArray(sharingsConsumers.results)) {
			dispatchConsumers({});
			dispatchConsumers({ type: 'add', consumers: sharingsConsumers.results });
			sharingsConsumers.setNewData(false);
		}
	}, [sharingsConsumers]);

	useEffect(() => {
		if (consumerItems.newData && isFullArray(consumerItems.results)) {
			dispatchConsumers({
				type: 'addItems',
				items: consumerItems.results,
				item: { consumerId: consumerItems.consumerId, total: consumerItems.total },
			});
			consumerItems.setNewData(false);
		}
	}, [consumerItems]);

	useEffect(() => {
		if (internalItems.newData && isFullArray(internalItems.results)) {
			dispatchConsumers({
				type: 'addInternalAndPublicItems',
				items: internalItems.results,
				item: { consumerType: 'internal', total: internalItems.total },
			});
			internalItems.setNewData(false);
		}
	}, [internalItems]);

	useEffect(() => {
		if (publicItems.newData && isFullArray(publicItems.results)) {
			dispatchConsumers({
				type: 'addInternalAndPublicItems',
				items: publicItems.results,
				item: { consumerType: 'public', total: publicItems.total },
			});
			publicItems.setNewData(false);
		}
	}, [publicItems]);

	const handleToggle = (item, type) => dispatchConsumers({ type: type, item: item });

	const selectionEvents = {
		onShowItems: (item) => {
			handleToggle(item, 'toggleShowingItems');
			if (!item.isShowingItems && !['internal', 'public'].includes(item.type)) {
				consumerItems.setProviderId(userInfo.organisation.id);
				consumerItems.setConsumerId(item.consumerId);
				if (!item.isExpanded) {
					consumerItems.setFetchData(true);
				}
			} else if (item.type === 'internal') {
				internalItems.setId(userInfo.organisation.id);
				if (!item.isExpanded) {
					internalItems.setFetchData(true);
				}
			} else if (item.type === 'public') {
				publicItems.setId(userInfo.organisation.id);
				if (!item.isExpanded) {
					publicItems.setFetchData(true);
				}
			}
		},
		onSelectedAllItems: (itemsSelecte) => {
			dispatchConsumers({
				type: itemsSelecte.selecteAll ? 'selectAllItems' : 'removeAllItems',
				item: itemsSelecte,
			});
			dispatchSelectedItems({
				type: itemsSelecte.selecteAll ? 'addAllItems' : 'removeAllItems',
				item: itemsSelecte,
			});
		},
		onSelectedItem: (item) => {
			dispatchConsumers({ type: 'selectItem', item: item });
			dispatchSelectedItems({
				type: item.selected ? 'addItem' : 'removeItem',
				item: { ...item, items: [item.item] },
			});
		},
	};

	const handlePagination = (type, consumerData) =>
		dispatchConsumers({ type: type, item: consumerData });

	const onChangePageNumberItems = (page, consumer) => {
		handlePagination('updateItemsPage', { ...consumer, pageNumber: page + 1 });
		if (consumer?.id) {
			consumerItems.setPage({ ...consumerItems.page, number: page + 1 });
			consumerItems.setFetchData(true);
		} else if (consumer.type === 'internal') {
			internalItems.setPage({ ...internalItems.page, number: page + 1 });
			internalItems.setFetchData(true);
		} else if (consumer.type === 'public') {
			publicItems.setPage({ ...publicItems.page, number: page + 1 });
			publicItems.setFetchData(true);
		}
	};

	const onChangePageSizeItems = (pageSize, consumer) => {
		handlePagination('updateItemsPage', { ...consumer, pageNumber: 1, pageSize: pageSize });
		if (consumer?.consumerId) {
			consumerItems.setPage({ number: 1, size: pageSize });
			consumerItems.setFetchData(true);
		} else if (consumer.type === 'internal') {
			internalItems.setPage({ number: 1, size: pageSize });
			internalItems.setFetchData(true);
		} else if (consumer.type === 'public') {
			publicItems.setPage({ number: 1, size: pageSize });
			publicItems.setFetchData(true);
		}
	};

	const itemsActions = {
		changePageNumber: onChangePageNumberItems,
		changePageSize: onChangePageSizeItems,
	};

	const pageNumberChange = (page) => {
		sharingsConsumers.setPage({ ...sharingsConsumers.page, number: page });
		sharingsConsumers.setFetchData(true);
	};

	const pageSizeChange = (newSize) => {
		sharingsConsumers.setPage({ number: 1, size: newSize });
		sharingsConsumers.setFetchData(true);
	};

	const editActionButtons = [
		{
			icon: <GavelOutlinedIcon sx={{ marginRight: '8px' }} />,
			text: t('views.itemManagement.management.button.edit.terms'),
			action: () =>
				navigate('/item-management/management/edit', {
					state: { selectedItems: selectedItems, editType: 'terms' },
				}),
			variant: 'inline-default',
		},
		{
			icon: <EuroIcon sx={{ marginRight: '8px' }} />,
			text: t('views.itemManagement.management.button.edit.pricing'),
			action: () =>
				navigate('/item-management/management/edit', {
					state: { selectedItems: selectedItems, editType: 'pricing' },
				}),
			variant: 'inline-default',
		},
		{
			icon: <LocalPoliceOutlinedIcon sx={{ marginRight: '8px' }} />,
			text: t('views.itemManagement.management.button.edit.policy'),
			action: () =>
				navigate('/item-management/management/edit', {
					state: { selectedItems: selectedItems, editType: 'policy' },
				}),
			variant: 'inline-default',
		},
	];

	const handleLinks = (
		path,
		label,
		itemDetails,
		returnButton = 'views.itemManagement.management.table.header',
	) => (
		<Link
			className={classes.itemGroup}
			color='primary'
			component={RouterLink}
			to={path}
			state={{ from: location.pathname, label: t(returnButton), itemDetails: itemDetails }}
		>
			{label}
		</Link>
	);

	// import tableHeaders from ManagementConstants on business price activation
	const tableHeaders = [
		{ name: 'itemGroep', content: 'ui.label.item' },
		{ name: 'qty', content: 'ui.qty' },
		{ name: 'userGroup', content: 'ui.label.userGroup' },
		{ name: 'terms', content: 'views.addItem.steps.sidePanelDetails.settings.terms' },
		{ name: 'price', content: 'views.management.header.pricing' },
		{ name: 'businessPrice', content: 'ui.label.businessPrice' },
		{ name: 'policy', content: 'views.addItem.settings.subsection.policy.title' },
	];

	const createItemData = (data) => {
		const { isLoadingItems, items: itemsData, ...rest } = data;

		if (!isLoadingItems && isFullArray(itemsData)) {
			return itemsData.map((item) => ({
				canEdit: item.canEdit,
				item: item?.item,
				userGroup: item?.userGroup,
				selected: item.selected,
				consumerName: data?.consumerName,
				pageNumber: item.pageNumber,
				results: [
					{
						content: handleLinks(
							`/item-management/item/${item.item.id}/summary/${userInfo.organisation.id}/sharing/${data.type}/${
								data.type === 'internal' ? item.userGroup.id
								: data.type === 'external' ? data.consumerId
								: 'id'
							}`,
							item.item.name,
							{ ...item, ...rest },
						),
					},
					{ content: item?.totalInstances },
					{
						content:
							item?.userGroup?.name ?
								handleLinks(
									`/user-management/user-groups/${item.userGroup.id}/summary`,
									item.userGroup.name,
									{ ...item, ...rest },
								)
							:	t('views.itemManagement.management.userGroup.placeholder'),
					},
					{
						content:
							item?.terms?.name &&
							handleLinks(`/term/details/${item.terms.id}/summary`, item.terms.name, {
								...item,
								...rest,
							}),
					},
					{
						content:
							item?.pricingModel?.name &&
							handleLinks(`/pricing/${item.pricingModel.id}/summary`, item.pricingModel.name, {
								...item,
								...rest,
							}),
					},
					{
						content:
							item?.businessPricingModel?.name &&
							handleLinks(
								`/pricing/${item.businessPricingModel.id}/summary`,
								item.businessPricingModel.name,
								{ ...rest, ...item },
							),
					},
					{
						content:
							item?.policy?.name &&
							handleLinks(
								`/policy-management/item-policies/${item.policy.id}/summary`,
								item.policy.name,
								{ ...item, ...rest },
							),
					},
				],
			}));
		} else {
			return Array(5)
				.fill()
				.map(() => ({ loading: true }));
		}
	};

	const managementHeaders = [{ name: 'name', content: 'ui.label.consumers' }];

	const body =
		isFullArray(consumers) && !sharingsConsumers?.newData ?
			consumers.map((item) => ({
				consumerId: item.id,
				isExpanded: item.isShowingItems,
				itemsPage: item.itemsPage,
				total: item.total,
				type: item.type,
				consumerName: item.name,
				selecteAll:
					isFullArray(item?.items) ?
						item?.items?.every((selectedItem) =>
							selectedItem.canEdit === true ? selectedItem.selected === true : true,
						)
					:	false,
				indeterminate:
					item?.items?.some((selectedItem) => selectedItem.canEdit && selectedItem.selected) &&
					!item?.items?.every((selectedItem) =>
						selectedItem.canEdit === true ? selectedItem.selected === true : true,
					),
				results: [{ content: item?.id ? item.name : t(`ui.label.${item.type}`) }],
				items:
					item.isShowingItems ?
						createItemData({
							isLoadingItems: item.isLoadingItems,
							consumerId: item.id,
							items: item.items,
							type: item.type,
							consumerName: item.name,
							pageNumber: item.itemsPage.pageNumber,
						})
					:	[],
			}))
		:	Array(5)
				.fill(Array(managementHeaders.length).fill())
				.map((arr) => ({ isExpanded: false, results: arr.map(() => ({ loading: true })) }));

	const searchBarProps = { hideSearch: true, clearFilters, lazyFilters };

	const tableProps = {
		title: t('views.itemManagement.management.table.header'),
		managementHeaders,

		body,
		selectionEvents,
		headers: tableHeaders,
		itemsActions,
		total: sharingsConsumers?.total ? sharingsConsumers?.total : 0,
		handlePageChange: pageNumberChange,
		page: sharingsConsumers?.page?.number,
		rowsPerPage: sharingsConsumers?.page?.size,
		setRowsPerPage: pageSizeChange,
		selection: selectedItems,
		editActionButtons,
	};

	return (
		<>
			<SearchBar {...searchBarProps} />
			{isEmptyArray(sharingsConsumers?.results) ?
				<EmptyState image={'booking'} title={t('ui.noResultsFound')} />
			:	<ConsumerTable {...tableProps} />}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		policies: state.paged.policiesSlim,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchUserGroups: (page, filters, concat) =>
			dispatch(actions.userGroupsSlim(page, filters, concat)),
		onFetchPricingModels: (page, filters, concat) =>
			dispatch(actions.pricingModelsSlim(page, filters, concat)),
		onFetchTerms: (page, filters, concat) => dispatch(actions.termsSlim(page, filters, concat)),
		onFetchPolicies: (page, filters, concat) =>
			dispatch(actions.policiesSlim(page, filters, concat)),
		onFetchItems: (page, filters, concat) => dispatch(actions.itemsSlim(page, filters, concat)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ConsumersManagement);

import path from 'path';

import { AxiosResponse } from 'axios';
import { ReplacePatch } from 'json-patch';

import { PagedResults } from '~interfaces';
import { SearchPagedParameters } from '~interfaces/requests';
import { BaseReferenceResponse, IdReferenceResponse, PagedResponse } from '~interfaces/responses';
import { TopologyService } from '~services';

import Organisation from '../interfaces/organisation';

class OrganisationsService extends TopologyService {
	protected readonly path = 'organisations';

	public readonly logoSubPath = 'images';
	public readonly bannerSubPath = 'banner';
	public readonly ssoSubPath = 'sso';
	public readonly emailDomainsSubPath = 'emaildomains';

	constructor(version: 'v1' | 'v2' = 'v1') {
		super(version);
	}

	/**
	 * Get a paginated list of organisations
	 * @param page The number of the page
	 * @param pageSize The amount of results of the page
	 * @returns
	 */
	async getOrganisations({
		page = 1,
		pageSize = 10,
		...args
	}: Omit<SearchPagedParameters, 'organisation'>): Promise<PagedResults<Organisation>> {
		const { data } = await this._client.get<PagedResponse<OrganisationResponse>>(this.path, {
			params: {
				pageNumber: page,
				pageSize: pageSize,
				searchTerm: args.searchQuery,
				sortBy: 'name',
			},
		});

		return this.mapPagedResponse(data, OrganisationsService.fromResponse);
	}

	/**
	 * Get the details of a single organisation
	 * @param id
	 * @returns
	 */
	async getOrganisationById(id: string): Promise<Organisation> {
		const { data } = await this._client.get<OrganisationResponse>(path.join(this.path, id));

		return OrganisationsService.fromResponse(data);
	}

	/**
	 * Get the organisation by id and do NOT map it to our internal business logic
	 * @param id
	 * @deprecated This function is used as a transition from Redux to swr by parsing
	 * the response directly
	 * @returns
	 */
	async getOrganisationByIdUnmapped(id: string): Promise<OrganisationResponse> {
		const { data } = await this._client.get<OrganisationResponse>(path.join(this.path, id));

		return data;
	}

	async patchOrganisation(id: string, data: Partial<Organisation>): Promise<null> {
		const patches: ReplacePatch[] = Object.keys(data).map((el) => ({
			op: 'replace',
			path: el,
			value: data[el],
		}));

		const response = await this._client.patch<OrganisationResponse>(
			path.join(this.path, id),
			patches
		);

		return null;
	}

	async updateLogoImage(id: string, file: File) {
		const formData = new FormData();
		formData.append('files', file);

		const response = await this._client.put(path.join(this.path, id, this.logoSubPath), formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});

		return null;
	}

	async updateBannerImage(id: string, file: File) {
		const formData = new FormData();
		formData.append('files', file);

		const response = await this._client.put(
			path.join(this.path, id, this.bannerSubPath),
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}
		);

		return null;
	}

	async setSsoOnlyAccess(organisationId: string, value: boolean): Promise<null> {
		await this._client.put(path.join(this.path, organisationId, this.ssoSubPath), {
			value: value,
		});

		return null;
	}

	async setEmailDomainLinking(organisationId: string, value: boolean): Promise<null> {
		await this._client.put(path.join(this.path, organisationId, this.emailDomainsSubPath), {
			value: value,
		});

		return null;
	}

	static fromResponse(data: OrganisationResponse): Organisation {
		const { id, name, logo, emailAddress, banner, address, ...rest } = data;

		return {
			...rest,
			...OrganisationsService.fromBaseReferenceResponse({
				id: id,
				name: name,
			}),
			logoUri: logo,
			ssoDomainSignupOnly: rest.features.includes('ssoDomains'),
			email: emailAddress,
			bannerUri: banner,
			address:
				address != null ?
					{
						countryCode: address.countryCode,
						country: address.country,
						city: address.city,
						postalCode: address.postalCode,
						street: address.street,
						number: address.number ? Number(address.number) : undefined,
						numberAddition: address.numberAddition,
					}
				:	undefined,
		};
	}
}

interface OrganisationResponse extends BaseReferenceResponse {
	emailAddress: string;
	phoneNumber: string;
	logo?: string;
	banner?: string;
	description?: string;
	features: string[];
	address: {
		street: string;
		number?: string;
		postalCode: string;
		city: string;
		countryCode: string;
		numberAddition?: string;
	} & IdReferenceResponse;
	isPublic: boolean;
	isVerified: boolean;
	showOnDashboard: boolean;
	invoiceContact: {
		name: string;
		emailAddress: string;
	};
	administration: {
		vatNumber: string;
		chamberOfCommerceNumber: string;
		ibanNumber: string;
		enterpriseNumber?: string;
	};
}

interface OrganisationRequest {
	address: {
		street: string;
		number?: string;
		postalCode: string;
		city: string;
		countryCode: string;
		numberAddition?: string;
	};
}

export default OrganisationsService;

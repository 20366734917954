import { Box, Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useStyles } from '../../../style';

interface UserCommentProps {
	bookingData: object;
}

const UserComment = (props: UserCommentProps) => {
	const { bookingData } = props;
	const { t } = useTranslation();

	const classes = useStyles();

	return (
		<Card className={classes.card}>
			<CardHeader title={t('views.bookingDetail.summary.cardHeaders.UserComment')} />
			<Divider />
			<CardContent className={classes.cardConten}>
				<Box className={classes.commentContainer}>
					{bookingData.comment ?
						<Typography variant='body2'>{bookingData.comment}</Typography>
					:	<Typography className={classes.emptyComment} variant='body2'>
							{t('views.bookingDetail.summary.userComment.empty')}
						</Typography>
					}
				</Box>
			</CardContent>
		</Card>
	);
};

export default UserComment;

import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import { Typography, Hidden } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import { useStyles } from './style';
import { LoadingBar, StyledButton } from '../../../../../components';
import { isFullString } from '../../../../../shared/utility'; // import { useDetailPageBackButton } from '../../../shared/hooks';

interface HeaderProps {
	className?: string;
	course?: string;
	revenueData?: object;
	loading?: boolean;
	month?: string;
	route?: string;
	organisationName?: string;
	date?: string;
	title?: string;
}

const Header = (props: HeaderProps) => {
	const { className, title } = props;
	const location = useLocation();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const classes = useStyles();

	const handleBackButtonChange = () => navigate('/financeManagement/revenue');

	return (
		<div className={clsx(classes.root, className)}>
			<Typography component='h2' gutterBottom variant='overline'>
				<Hidden only={['xs', 'sm']}>
					<StyledButton
						className={classes.backButton}
						onClick={handleBackButtonChange}
						startIcon={<KeyboardArrowLeftOutlinedIcon />}
						variant='inline-default'
					>
						{t('nav.financialReports.revenue')}
					</StyledButton>
				</Hidden>
			</Typography>
			<Typography component='h1' variant='h3'>
				{!isFullString(title) ?
					<LoadingBar />
				:	title}
			</Typography>
		</div>
	);
};

export default Header;

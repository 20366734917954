import { useState } from 'react';

import { MenuItem, SelectProps } from '@mui/material';
import useSWRImmutable from 'swr/immutable';

import { AsyncSelect, Select } from '~components';

import Defect from '../../interfaces/defect';
import DefectsService from '../../services/defectsService';
import DefectSeverityChip from '../chips/defectSeverityChip';

const service = new DefectsService();

type DefectSelectProps = SelectProps & {
	itemGroupId?: string;
	categoryId?: string;
};

/**
 * WIP
 */
const DefectSelect = ({
	label = 'defect',
	itemGroupId,
	categoryId,
	value,
	onChange,
	...selectProps
}: DefectSelectProps) => {
	const [open, setOpen] = useState(false);

	const { data, isLoading } = useSWRImmutable(
		open && itemGroupId ? [service.basePath, itemGroupId] : undefined,
		([_, id]) => service.getItemGroupDefects(id),
	);

	const handleChange = (e, child: React.ReactNode) => {
		const newValue = data.find((el) => el.id === e.target.value);
		onChange?.(e, child);
	};

	return (
		<AsyncSelect
			{...selectProps}
			label={label}
			onOpen={() => setOpen(true)}
			onClose={() => setOpen(false)}
			value={value ?? ''}
			onChange={handleChange}
			loading={isLoading}
		>
			{data?.map((el) => (
				<MenuItem
					key={el.id}
					value={el.id}
					sx={{ display: 'flex', justifyContent: 'space-between' }}
				>
					{el.label}
					<DefectSeverityChip status={el.severity} />
				</MenuItem>
			))}
		</AsyncSelect>
	);
};

const DefectSelectBup = ({
	itemGroupId,
	categoryId,
	value,
	onChange,
	...selectProps
}: DefectSelectProps) => {
	const { data, isLoading } = useSWRImmutable(
		itemGroupId ? [service.basePath, itemGroupId] : undefined,
		([_, id]) => service.getItemGroupDefects(id),
	);

	if (!data || isLoading) {
		return 'loading';
	}

	const handleChange = (e, child: React.ReactNode) => {
		const newValue = data.find((el) => el.id === e.target.value);
		console.log(e, child);
		onChange?.(e, child);
	};

	return (
		<Select {...selectProps} value={value ?? ''} onChange={handleChange}>
			{data?.map((el) => (
				<MenuItem
					key={el.id}
					value={el.id}
					sx={{ display: 'flex', justifyContent: 'space-between' }}
				>
					{el.label}
					<DefectSeverityChip status={el.severity} />
				</MenuItem>
			))}
		</Select>
	);
};

export default DefectSelect;

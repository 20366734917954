import { useEffect } from 'react';

import { Tabs, Tab, Divider } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';

import { userInfoAtom } from '~atoms';
import { useAuthorize } from '~features/authentication';
import { useSnackbar } from '~hooks';

import Header from './Header';
import { useStyles } from './style';
import Summary from './Summary/Summary';
import { Page } from '../../../../components';
import { useAccess } from '../../../../shared/accessHooks';
import { isObject, isUndefined } from '../../../../shared/utility';
import * as actions from '../../../../store/actions/index';
import TripsList from '../../../Devices/Details/TripsList';

interface InstanceDetailsProps {
	fetchInstance?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onFetchInstance?(...args: unknown[]): unknown;
	onFetchDevice?(...args: unknown[]): unknown;
	fetchDevice?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onFetchVehicleStatus?(...args: unknown[]): unknown;
	vehicleStatus?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	instanceHeartbeats?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onInstanceHeartbeats?(...args: unknown[]): unknown;
	onResetState?(...args: unknown[]): unknown;
}

const InstanceDetails = (props: InstanceDetailsProps) => {
	const {
		onFetchInstance,
		fetchInstance,
		onFetchDevice,
		fetchDevice,
		onFetchVehicleStatus,
		vehicleStatus,
		onInstanceHeartbeats,
		instanceHeartbeats,

		onResetState,
	} = props;

	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const { isSuperAdmin } = useAuthorize();
	const userInfo = useAtomValue(userInfoAtom);
	const { enqueueErrorSnackbar } = useSnackbar();

	const classes = useStyles();

	const { id, instanceId, tab } = useParams();

	const { data: instanceData, loading: instanceLoading, error: instanceError } = fetchInstance;

	const { data: deviceData, loading: deviceLoading } = fetchDevice;

	const { data: vehicleStatusData, loading: vehicleLoading } = vehicleStatus;

	const { data: instanceHeartbeatsData, loading: instanceHeartbeatsLoading } = instanceHeartbeats;

	const isInternalUser =
		userInfo?.organisation.id == instanceData?.organisationReference?.id ||
		isSuperAdmin();
	const parseInstanceId = parseInt(instanceId);
	const fetchDeviceLoading =
		isObject(instanceData) &&
		!isUndefined(instanceData.deviceId) &&
		!deviceLoading &&
		parseInstanceId === instanceData.id;
	const noDevice = isObject(instanceData) && isUndefined(instanceData.deviceId);
	const statusLoading = vehicleLoading || instanceHeartbeatsLoading;
	const instanceAccess = useAccess();

	const handleTabsChange = (event, value) => {
		navigate(`../${value}`, { relative: 'path', state: { ...location.state } });
	};

	useEffect(() => {
		if (instanceError) {
			// 2025-03-14 There is a known bug where the backend gives an error when the organisation name is
			// the same as the name of the instance!
			enqueueErrorSnackbar(t('somethingWentWrong'), { preventDuplicate: true })
		}
	}, [instanceError]);

	useEffect(() => {
		if (id && instanceId && !instanceLoading) {
			onFetchInstance(id, instanceId);
			if (isObject(vehicleStatusData) || isObject(instanceHeartbeats)) {
				onResetState('vehicleStatus');
				onResetState('instanceHeartbeats');
			}
			instanceAccess.setItemId(id);
			instanceAccess.setInstanceId(instanceId);
		}
	}, [id, instanceId]);

	useEffect(() => {
		if (fetchDeviceLoading && instanceData?.deviceId) {
			if (instanceAccess?.access?.includes('internal') || isSuperAdmin()) {
				onFetchDevice(instanceData.deviceId);
			}
			if (
				instanceData.deviceType === 'bmwCar' &&
				(instanceData?.itemReference.hubReference.organisationReference.id ==
					userInfo.organisation.id ||
					isSuperAdmin())
			) {
				onFetchVehicleStatus(instanceData.deviceId);
			} else if (!instanceHeartbeatsLoading) {
				onInstanceHeartbeats(id, instanceId);
			}
		}
	}, [instanceLoading]);

	const tabs = [
		{ value: 'summary', label: t('ui.summary') },
		...(instanceData?.showTrips ? [{ value: 'trips', label: t('ui.label.trips') }] : []),
	];

	if (!tab) {
		return <Navigate to={'/item-management/items'} />;
	}

	if (isObject(instanceData) && !tabs.find((t) => t.value === tab)) {
		return <Navigate to='/errors/error-404' />;
	}

	const tripsListProps = { id: instanceId, isInstanceTrip: true };

	return (
		<Page className={classes.root} title={t('views.instanceDetails.page.title')}>
			<Header data={instanceData} loading={instanceLoading} />
			<Tabs
				className={classes.tabs}
				indicatorColor='primary'
				onChange={handleTabsChange}
				scrollButtons='auto'
				value={tab}
				variant='scrollable'
			>
				{tabs.map((tab) => (
					<Tab key={tab.value} label={tab.label} value={tab.value} />
				))}
			</Tabs>
			<Divider className={classes.divider} />
			<div className={classes.content}>
				{tab === 'summary' && (
					<Summary
						access={instanceAccess?.access?.includes('internal')}
						instanceData={{
							...instanceData,
							device: noDevice ? null : deviceData,
							deviceStatus:
								noDevice ? null
								: isObject(instanceData) && instanceData.deviceType === 'bmwCar' ? vehicleStatusData
								: instanceHeartbeatsData,
						}}
						instanceId={parseInstanceId}
						instanceLoading={instanceLoading || (noDevice && fetchDeviceLoading && statusLoading)}
						isInternalUser={isInternalUser}
						isSuperAdmin={isSuperAdmin()}
					/>
				)}
				{tab === 'trips' && instanceData?.showTrips ?
					<TripsList {...tripsListProps} />
				:	null}
			</div>
		</Page>
	);
};

const mapStateToProps = (state) => {
	return {
		fetchInstance: state.details.fetchInstance,
		fetchDevice: state.details.fetchDevice,
		vehicleStatus: state.details.vehicleStatus,
		instanceHeartbeats: state.details.instanceHeartbeats,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchInstance: (id, instanceId) => dispatch(actions.fetchInstance(id, instanceId)),
		onFetchDevice: (id) => dispatch(actions.fetchDevice(id)),
		onFetchVehicleStatus: (id) => dispatch(actions.fetchVehicleStatus(id)),
		onInstanceHeartbeats: (itemId, instanceId) =>
			dispatch(actions.instanceHeartbeats(itemId, instanceId)),
		onResetState: (state) => dispatch(actions.resetState(state)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(InstanceDetails);

import { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
	Drawer,
	Typography,
	Divider,
	TextField,
	FormControl,
	FormControlLabel,
	InputAdornment,
	Switch,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';

import { StyledButton, CustomNumberField } from '../../../../../../components';
import {
	modifyDate,
	stringifyTime,
	unsetTime,
	weekdays,
	weekend,
	weekOptions,
	timeSlots,
} from '../../../../../../shared/datetime';
import {
	isFullString,
	isFullArray,
	isEmptyArray,
	isInteger,
	ignoreKeyClick,
} from '../../../../../../shared/utility';
import { useStyles } from '../../style';

interface UpdatePerHourProps {
	className?: string;
	open?: boolean;
	onClose?(...args: unknown[]): unknown;
	periodLabel?: string;
	amountPerHour?: string | number;
	setPricingPerHour?(...args: unknown[]): unknown;
	pricingPerHourModel?: unknown[];
	pricingPerHour?: object;
	save?(...args: unknown[]): unknown;
	periodType?: string;
	createPricingModel?(...args: unknown[]): unknown;
}

const UpdatePerHour = (props: UpdatePerHourProps) => {
	const {
		open,
		onClose,
		periodLabel,
		periodType,
		pricingPerHourModel,
		pricingPerHour,
		setPricingPerHour,
		save,
		createPricingModel,
	} = props;
	const { t } = useTranslation();

	const classes = useStyles();

	const [isEdit] = useState(isInteger(pricingPerHour.dayOfWeek));
	const [selectedDays, setSelectedDays] = useState(
		weekOptions.filter((item) =>
			isEdit ? item.id === pricingPerHour.dayOfWeek : item.id === pricingPerHour.start.getDay(),
		),
	);

	const updatedModels =
		!isEdit ? pricingPerHourModel : (
			pricingPerHourModel.reduce((acc, cur) => {
				const updatedModel =
					cur.id !== pricingPerHour.id ?
						cur
					:	{ ...cur, days: cur.days.filter((day) => day.id !== pricingPerHour.dayOfWeek) };

				return [...acc, ...(isFullArray(updatedModel.days) ? [updatedModel] : [])];
			}, [])
		);

	const forceDisable =
		!pricingPerHour.allDay &&
		pricingPerHour.end.getHours() === 0 &&
		pricingPerHour.end.getMinutes() === 0;
	const disableSave =
		isEmptyArray(selectedDays) ||
		isFullArray(
			updatedModels.filter((model) => {
				const daysToAdd = model.start.getDate() !== model.end.getDate() ? 1 : 0;

				const selectedStart = modifyDate(model.start, { date: pricingPerHour.start.getDate() });
				const selectedEnd = modifyDate(model.end, {
					date: pricingPerHour.start.getDate() + daysToAdd,
				});

				const endOfDay =
					pricingPerHour.end.getHours() === 23 && pricingPerHour.end.getMinutes() === 59;
				const end = modifyDate(endOfDay ? pricingPerHour.start : pricingPerHour.end, {
					...(endOfDay && { hours: 0, minutes: 0, date: '+1' }),
				});

				return (
					isFullArray(
						model.days.filter((day) =>
							isFullArray(selectedDays.filter((selectedDay) => day.id === selectedDay.id)),
						),
					) &&
					((pricingPerHour.start.getTime() <= selectedStart.getTime() &&
						end.getTime() >= selectedEnd.getTime()) ||
						(pricingPerHour.start.getTime() >= selectedStart.getTime() &&
							end.getTime() <= selectedEnd.getTime()))
				);
			}),
		);
	const disabledTimes = updatedModels.reduce((acc, cur) => {
		const endOfDay = cur.end.getHours() === 0 && cur.end.getMinutes() === 0;
		const selectedTimes = cur.days
			.filter((day) => isFullArray(selectedDays.filter((selected) => selected.id === day.id)))
			.map(() => ({
				start: stringifyTime(cur.start),
				end: stringifyTime(modifyDate(cur.end, { ...(endOfDay && { hours: 23, minutes: 59 }) })),
			}));

		return [...acc, ...selectedTimes];
	}, []);

	const handlePriceChange = (event) => {
		const value = parseFloat(event.target.value);
		setPricingPerHour({ ...pricingPerHour, price: value });
	};

	const toggleAllDay = () => {
		const start = unsetTime(new Date());

		if (!isFullArray(disabledTimes)) {
			setPricingPerHour({
				...pricingPerHour,
				start: start,
				end: modifyDate(start, { hours: 23, minutes: 59 }),
				allDay: !pricingPerHour.allDay,
			});
		}
	};

	const getOptionLabel = (selected) => {
		return selected.name;
	};

	const handleAutocomplete = (event, values) => {
		const updatedOptions = values.reduce((accumulator, currentValue) => {
			if (currentValue.id === 7) {
				return [...weekdays, ...weekend];
			} else if (currentValue.id === 8) {
				return [...weekdays];
			} else if (currentValue.id === 9) {
				return [...weekend];
			} else {
				return [...accumulator, currentValue];
			}
		}, []);
		setSelectedDays(updatedOptions);
	};

	const handleStartTimeChange = (event, value) => {
		if (isFullString(value)) {
			const modifiedStart = modifyDate(pricingPerHour.start, {
				hours: parseInt(value.slice(0, 2), 10),
				minutes: parseInt(value.slice(3, 5), 10),
			});
			if (modifiedStart.getTime() < pricingPerHour.end.getTime()) {
				const updatedTime = {
					...pricingPerHour,
					start: modifiedStart,
					// allDay: modifiedStart.getDate() !== pricingPerHour.end.getDate()
				};

				setPricingPerHour(updatedTime);
			}
		}
	};

	const handleEndTimeChange = (event, value) => {
		if (isFullString(value)) {
			const hours = parseInt(value.slice(0, 2), 10);
			const minutes = parseInt(value.slice(3, 5), 10);
			const modifiedEnd = modifyDate(pricingPerHour.end, {
				hours: hours,
				minutes: minutes,
				...((hours > 0 || minutes > 0) && { date: pricingPerHour.start.getDate() }),
			});
			if (modifiedEnd.getTime() > pricingPerHour.start.getTime()) {
				setPricingPerHour({
					...pricingPerHour,
					end: modifiedEnd,
					// allDay: modifiedEnd.getDate() !== pricingPerHour.start.getDate()
				});
			}
		}
	};

	const handleDisabledOption = (option, isEnd = false) => {
		const totalOccurences = option === '00:00' || option === '23:59' ? 1 : 2;
		return (
			isFullArray(disabledTimes.filter((time) => time.start < option && time.end > option)) ||
			disabledTimes.filter((time) => time.start === option || time.end === option).length ===
				totalOccurences ||
			(!isEnd ?
				option >= stringifyTime(pricingPerHour.end)
			:	option <= stringifyTime(pricingPerHour.start))
		);
	};

	const handleDisabledOptionStart = (option) => handleDisabledOption(option);
	const handleDisabledOptionEnd = (option) => handleDisabledOption(option, true);

	const handlePricingClose = () => {
		onClose();
	};

	const handlePricingAdd = () => {
		const priceValue = createPricingModel(
			Date.now(),
			pricingPerHour.start,
			pricingPerHour.end,
			pricingPerHour.allDay,
			pricingPerHour.price,
			periodType,
			selectedDays,
		);

		if (isEdit) {
			priceValue.isEdit = true;
			priceValue.initialModelId = pricingPerHour.id;
			priceValue.dayOfWeek = pricingPerHour.dayOfWeek;
		}

		save({ pricePerHour: priceValue, pricePerHourRemove: null });
		onClose();
	};

	const handlePricingRemove = () => {
		save({ pricePerHourRemove: pricingPerHour, pricePerHour: null });
		onClose();
	};

	const handleKeyUp = (event) => {
		ignoreKeyClick(event, 'Enter');
	};

	const timeComponent = [
		{
			label: 'from',
			name: t('ui.from'),
			disabled: pricingPerHour.allDay,
			options: timeSlots,
			action: handleStartTimeChange,
			value: stringifyTime(pricingPerHour.start),
			disabledValue: handleDisabledOptionStart,
		},
		{
			label: 'to',
			name: t('ui.to'),
			disabled: pricingPerHour.allDay,
			options: timeSlots,
			action: handleEndTimeChange,
			value: stringifyTime(pricingPerHour.end),
			disabledValue: handleDisabledOptionEnd,
		},
	];

	return (
		<Drawer
			anchor='right'
			classes={{ paper: classes.drawer }}
			onClose={handlePricingClose}
			open={open}
			variant='temporary'
		>
			<div>
				<StyledButton
					onClick={handlePricingClose}
					startIcon={<CloseIcon />}
					variant='inline-default'
				>
					{t('ui.button.inline.close')}
				</StyledButton>
				<Typography
					className={classes.header}
					variant='h3'
				>{`${t('ui.price')} ${periodLabel.toLowerCase()}`}</Typography>
				<Divider className={classes.divider} />
				<form>
					<TextField
						InputLabelProps={{ shrink: true }}
						InputProps={{
							inputComponent: CustomNumberField,
							startAdornment: <InputAdornment position='start'>{'€'}</InputAdornment>,
						}}
						fullWidth
						label={t('ui.amount')}
						name='amountPer'
						onChange={handlePriceChange}
						onKeyPress={handleKeyUp}
						size='medium'
						value={pricingPerHour.price}
						variant='outlined'
					/>
				</form>
				<Divider className={classes.divider} />
				<Autocomplete
					ListboxProps={{ style: { maxHeight: '210px' } }}
					filterSelectedOptions={true}
					getOptionLabel={getOptionLabel}
					multiple
					onChange={handleAutocomplete}
					options={weekOptions}
					renderInput={(params) => (
						<TextField
							{...params}
							InputLabelProps={{ shrink: true }}
							fullWidth
							label='Days'
							size='medium'
							variant='outlined'
						/>
					)}
					value={selectedDays}
				/>
				<Divider className={classes.divider} />
				<div className={classes.timeSection}>
					{timeComponent.map((item) => (
						<div className={classes.saction} key={item.label}>
							<Autocomplete
								disabled={item.disabled}
								getOptionDisabled={item.disabledValue}
								onChange={item.action}
								options={item.options}
								renderInput={(params) => (
									<TextField
										{...params}
										InputLabelProps={{ shrink: true }}
										label={item.name}
										size='medium'
										variant='outlined'
									/>
								)}
								value={item.value}
							/>
						</div>
					))}
				</div>
				<div className={classes.allDaySpacing}>
					<FormControl disabled={isFullArray(disabledTimes)} variant='standard'>
						<FormControlLabel
							control={
								<Switch
									checked={pricingPerHour.allDay && !isFullArray(disabledTimes)}
									onChange={toggleAllDay}
								/>
							}
							label={
								pricingPerHour.allDay ?
									<Typography color='primary' variant='h6'>
										{t('ui.allDay')}
									</Typography>
								:	<Typography variant='h6'>{t('ui.selectAllDay')}</Typography>
							}
							labelPlacement='start'
						/>
					</FormControl>
				</div>
			</div>
			<div>
				<StyledButton
					disabled={forceDisable || disableSave}
					fullWidth
					onClick={handlePricingAdd}
					variant='contained-secondary'
				>
					{t('ui.button.contained.save')}
				</StyledButton>
				{isEdit ?
					<StyledButton
						className={classes.removeButton}
						fullWidth
						onClick={handlePricingRemove}
						variant='contained-delete'
					>
						{t('ui.button.contained.remove')}
					</StyledButton>
				:	null}
			</div>
		</Drawer>
	);
};

export default UpdatePerHour;

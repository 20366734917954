import { Box, Card, CardContent, Typography, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';
import { Label } from '../../../../components';
import { commaTimeStrings } from '../../../../shared/datetime';

interface ActivityProps {
	comment?: string;
	createdDate?: string;
	userName?: string;
	createdBy?: string;
	type?: string;
	status?: string;
}

const Activity = ({
	comment = '',
	createdDate,
	userName,
	createdBy,
	type,
	status,
}: ActivityProps) => {
	const { t } = useTranslation();

	const classes = useStyles();

	return (
		<Box mb={3}>
			<Typography
				gutterBottom={true}
				variant='body2'
			>{`${t('ui.dateCreated')} ${commaTimeStrings(createdDate)}`}</Typography>
			<Card>
				<CardContent>
					<Box className={classes.userCard}>
						<Typography variant='h6'>{userName}</Typography>
						<Typography variant='h6'>
							{t('ui.changedStatusTo')}:{' '}
							<Label type={type}>{t(`views.activities.status.${status}`)}</Label>
						</Typography>
						<Typography className={classes.superAdmin} variant='body2'>
							{createdBy}
						</Typography>
					</Box>
					<Divider className={classes.divider} />
					<Typography variant='h6'>{t('ui.label.comment')}</Typography>
					<Typography variant='body2'>{comment}</Typography>
				</CardContent>
			</Card>
		</Box>
	);
};

export default Activity;

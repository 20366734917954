import { useState, useEffect } from 'react';

import { Share as ShareIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { UnassignDevices } from './UnassignDevices';
import { Table, EmptyState } from '../../../../../components';
import { isArray, isFullArray, isNull, handleLockType } from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';

const isEmptyArray = (value) => Array.isArray(value) && value.length === 0;

interface AccessDevicesProps {
	onItemGroupDevices?(...args: unknown[]): unknown;
	onResetListState?(...args: unknown[]): unknown;
	itemGroupDevices?: {
		data?: unknown[];
		loading?: boolean;
		error?: object | string;
	};
}

const AccessDevices = (props: AccessDevicesProps) => {
	const { onItemGroupDevices, itemGroupDevices, onResetListState } = props;
	const { t } = useTranslation();

	const { id } = useParams();

	const [fetch, setFetch] = useState({
		loading: true,
		callData: true,
	});

	const [unassignDevicesData, setUnassignDevicesData] = useState(null);

	const { data: itemGroupDevicesData, loading: itemGroupDevicesLoading } = itemGroupDevices;

	useEffect(() => {
		if (!itemGroupDevicesLoading && ((fetch.callData && id) || isNull(itemGroupDevicesData))) {
			onItemGroupDevices(id);
			setFetch((val) => ({ ...val, callData: false }));
		}
		if (isArray(itemGroupDevicesData)) {
			setFetch((val) => ({ ...val, loading: false }));
		}
	}, [fetch.callData, id, itemGroupDevicesLoading, itemGroupDevicesData]);

	useEffect(() => {
		return () => {
			onResetListState('itemGroupDevices', null);
			setFetch({ loading: true, callData: true });
		};
	}, []);

	const handleFetchData = () => {
		setFetch((val) => ({ ...val, callData: true, loading: true }));
	};

	const tableHeader = [
		{ name: 'id', content: t('ui.label.deviceId') },
		{ name: 'name', content: t('ui.label.name') },
		{ name: 'type', content: t('ui.characteristics.boatBodyType') },
		{ name: '', content: '' },
	];

	const loadingState =
		fetch.loading &&
		Array(3)
			.fill(Array(4).fill())
			.map((arr) => arr.map(() => ({ loading: true })));

	const tableBody =
		!fetch.loading &&
		!isNull(itemGroupDevicesData) &&
		itemGroupDevicesData?.map((device) => [
			{ content: device.deviceId },
			{ content: device.deviceName },
			{ content: handleLockType(device.deviceType) },
			{ content: <div></div> },
		]);

	const emptyState = (
		<EmptyState
			title={t('views.accessDevices.emptyState.title')}
			subTitle={t('views.accessDevices.emptyState.subTitle')}
			image={'booking'}
		/>
	);

	const handleOpenUnassignDevices = (selectionDevices) => {
		setUnassignDevicesData({
			open: true,
			deviceIds: selectionDevices.map((device) => device.deviceId),
		});
	};

	const handleCloseUnassignDevices = () => setUnassignDevicesData(null);

	const editActionButtons = [
		{
			icon: <ShareIcon sx={{ marginRight: 1 }} color='error' p={1} />,
			text: t('views.devices.unassignDevices'),
			action: (selectionDevices) => handleOpenUnassignDevices(selectionDevices),
		},
	];

	return (
			!fetch.loading &&
				itemGroupDevicesData !== null &&
				isEmptyArray(itemGroupDevicesData) &&
				!isFullArray(itemGroupDevicesData)
		) ?
			emptyState
		:	<>
				<Table
					body={loadingState || (isArray(itemGroupDevicesData) && tableBody)}
					header={tableHeader}
					hasSelectionEnabled={true}
					data={itemGroupDevicesData}
					isNotPaginate={true}
					editActionButtons={editActionButtons}
					loading={fetch.loading}
					title={t('views.accessDevices.table.title')}
					withoutPages={true}
				/>
				<UnassignDevices
					open={unassignDevicesData?.open}
					deviceIds={unassignDevicesData?.deviceIds}
					onClose={handleCloseUnassignDevices}
					fetchData={handleFetchData}
					itmeId={id}
				/>
			</>;
};

const mapStateToProps = (state) => {
	return {
		itemGroupDevices: state.list.itemGroupDevices,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onItemGroupDevices: (itemId) => dispatch(actions.itemGroupDevices(itemId)),
		onResetListState: (state, value) => dispatch(actions.updateListState(state, value)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessDevices);

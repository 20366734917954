import { useEffect, useState } from 'react';

import CarRentalIcon from '@mui/icons-material/CarRental';
import KeyIcon from '@mui/icons-material/Key';
import { Box, Typography, LinearProgress, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { Tooltip } from '~components';

import Dashboard from '../../../../assets/icons/ic-dashboard.svg';
import BatteryIcon from '../../../../assets/icons/icons-battery.png';
import ChargerIcon from '../../../../assets/icons/icons-charger.svg';
import FuelIcon from '../../../../assets/icons/icons-fuel.svg';
import Energy from '../../../../assets/icons/icons-ic-energy.svg';
import locationRange from '../../../../assets/icons/icons-location-range.svg';
import LockedIcon from '../../../../assets/icons/icons-locked.svg';
import StatusIcons from '../../../../assets/icons/icons-status.svg';
import TemperatureIcons from '../../../../assets/icons/icons-temperature.svg';
import WindowIcon from '../../../../assets/icons/icons-window.svg';
import PowerButtonIcon from '../../../../assets/icons/power-button.svg';
import { commaTimeStrings } from '../../../../shared/datetime';
import {
	isObject,
	isFullArray,
	isInteger,
	isBoolean,
	mapBatteryLevelEnum,
} from '../../../../shared/utility';
import * as actions from '../../../../store/actions/index';
import { useStyles } from '../../style';

interface DeviceCardProps {
	openFleetDrawer: boolean;
	handleLinkDevice?(...args: unknown[]): unknown;
	selectedFleet?: object;
	onResetState?(...args: unknown[]): unknown;
	cardData?: object;
	setCardData?(...args: unknown[]): unknown;
	fetchDevice?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onFetchDevice?(...args: unknown[]): unknown;
	onFetchVehicleStatus?(...args: unknown[]): unknown;
	vehicleStatus?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	fetchDeviceHeartbeats?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onFetchDeviceHeartbeats?(...args: unknown[]): unknown;
}

const DeviceCard = (props: DeviceCardProps) => {
	const {
		vehicleStatus,
		selectedFleet,
		openFleetDrawer,
		onResetState,
		onFetchVehicleStatus,

		onFetchDeviceHeartbeats,
		fetchDeviceHeartbeats,
		cardData,
		setCardData,
		handleLinkDevice,
	} = props;
	const { t } = useTranslation();

	const { data: vehicleStatusData, loading: vehicleStatusLoading } = vehicleStatus;

	const { data: deviceHeartbeatsData, loading: fetchDeviceHeartbeatsLoading } =
		fetchDeviceHeartbeats;

	const classes = useStyles();

	const [deviceCard, setDeviceCard] = useState(null);

	const [fuel, setFuel] = useState('-');

	useEffect(() => {
		if (selectedFleet?.deviceType === 'bmwCar' && !vehicleStatusLoading) {
			onFetchVehicleStatus(selectedFleet.deviceId);
			onResetState('fetchDeviceHeartbeats');
		} else if (
			selectedFleet?.deviceId &&
			selectedFleet?.deviceType !== 'bmwCar' &&
			!fetchDeviceHeartbeatsLoading
		) {
			onFetchDeviceHeartbeats(selectedFleet.deviceId);
			onResetState('vehicleStatus');
		}
	}, [selectedFleet]);

	useEffect(() => {
		if (deviceHeartbeatsData?.vehicleFuelVolume > 0) {
			setFuel(`${deviceHeartbeatsData?.vehicleFuelVolume} liter`);
		} else if (deviceHeartbeatsData?.vehicleFuelLevel > 0) {
			setFuel(`${deviceHeartbeatsData?.vehicleFuelLevel}%`);
		}
	}, [deviceHeartbeatsData]);

	useEffect(() => {
		if (vehicleStatusData) {
			setDeviceCard(vehicleStatusData?.timestamp);
		} else if (deviceHeartbeatsData) {
			setDeviceCard(deviceHeartbeatsData?.lastUpdated);
		}
	}, [vehicleStatusData, deviceHeartbeatsData]);

	useEffect(() => {
		if (!openFleetDrawer) {
			onResetState('vehicleStatus');
			onResetState('fetchDeviceHeartbeats');
			setDeviceCard(null);
		}
	}, [openFleetDrawer]);

	useEffect(() => {
		if (!fetchDeviceHeartbeatsLoading && deviceHeartbeatsData?.firmwareVersion) {
			const description = `${t('ui.label.firmwareVersion')} ${deviceHeartbeatsData?.firmwareVersion ? deviceHeartbeatsData.firmwareVersion : '-'}`;

			setCardData({
				...cardData,
				description: description,
			});
		}
	}, [fetchDeviceHeartbeats]);

	const disabledEnabled = {
		disabled: t('ui.disabled'),
		enabled: t('ui.enabled'),
	};

	const centralLocking = {
		Locked: t('ui.label.locked'),
		Unlocked: t('ui.label.unlocked'),
		Secured: t('ui.label.lockedSecurely'),
	};

	const charging = {
		Charging: t('ui.label.device.state.charging'),
		NotCharging: t('ui.label.notcharging'),
	};

	const windowsOpen = {
		Driver: t('ui.label.driver'),
		DriverRear: t('ui.label.driverRear'),
		Passenger: t('ui.label.passenger'),
		PassengerRear: t('ui.label.passengerRear'),
		SunRoof: t('ui.label.sunRoof'),
	};

	const itemReach = [
		...(vehicleStatusData?.hvBatteryLevel ?
			[
				{
					icon: BatteryIcon,
					level: vehicleStatusData.hvBatteryLevel,
					alt: 'electric',
					label: `${vehicleStatusData.hvBatteryLevel}%`,
				},
			]
			: []),
		...(deviceHeartbeatsData?.batteryLevel || isInteger(deviceHeartbeatsData?.batteryLevel) ?
			[
				{
					icon: BatteryIcon,
					level: deviceHeartbeatsData.batteryLevel,
					enum: deviceHeartbeatsData.batteryLevelEnum,
					alt: 'battery',
					label: `${t('ui.label.primary')}: ${deviceHeartbeatsData.batteryLevel}%`,
				},
			]
			: []),
		...((
			deviceHeartbeatsData?.externalBatteryLevel ||
			isInteger(deviceHeartbeatsData?.externalBatteryLevel)
		) ?
			[
				{
					icon: BatteryIcon,
					level: deviceHeartbeatsData.externalBatteryLevel,
					alt: 'secondaryBattery',
					label: `${t('ui.label.secondary')}: ${deviceHeartbeatsData.externalBatteryLevel}%`,
				},
			]
			: []),
	];

	const statusOfWindows =
		isObject(vehicleStatusData) &&
		vehicleStatusData?.windows &&
		vehicleStatusData.windows.reduce((acc, status) => acc || status.open, false);
	const openWindows =
		isObject(vehicleStatusData) &&
		vehicleStatusData?.windows &&
		vehicleStatusData.windows.filter((window) => (window.open ? window.location : null));

	const handleWindows = () =>
		statusOfWindows ?
			<Tooltip
				title={
					openWindows?.map((item, i) => (
						<Typography key={i}>{windowsOpen[item.location]}</Typography>
					))
				}
			>
				<span>{t('ui.open')}</span>
			</Tooltip>
			: t('ui.openStatus.closed');

	const statusOfDoors =
		isObject(deviceHeartbeatsData) &&
		deviceHeartbeatsData?.doors &&
		deviceHeartbeatsData.doors.reduce((acc, status) => acc || status.open, false);
	const openDoors =
		isObject(deviceHeartbeatsData) &&
		deviceHeartbeatsData?.doors &&
		deviceHeartbeatsData.doors.filter((door) => (door.open ? door.location : null));

	const handleDoors = () =>
		statusOfDoors ?
			<Tooltip
				title={
					openDoors?.map((item, i) => (
						<Typography key={i}>{windowsOpen[item.location]}</Typography>
					))
				}
			>
				<span>{t('ui.open')}</span>
			</Tooltip>
			: t('ui.openStatus.closed');

	const icons = {
		mileage: Dashboard,
		range: locationRange,
		energy: Energy,
		fuel: FuelIcon,
		charger: ChargerIcon,
		lockState: LockedIcon,
		power: PowerButtonIcon,
		temperature: TemperatureIcons,
		window: WindowIcon,
		status: StatusIcons,
	};

	const isCar = [
		'vehicleTrackerObd',
		'vehicleTrackerPro',
		'vehicleTrackerCan',
		'vehicleTracker',
	].includes(selectedFleet?.deviceType);
	const isBoat = selectedFleet?.deviceType === 'cpacBoat';

	const firstColumnDetails = [
		...(vehicleStatusData ?
			[
				{
					label: `${vehicleStatusData?.mileage}km`,
					value: vehicleStatusData?.mileage,
					alt: 'mileage',
				},
				{ label: `${vehicleStatusData?.reach}km`, value: vehicleStatusData?.reach, alt: 'range' },
				{
					label: `${vehicleStatusData?.hvBatteryLevel}%`,
					value: vehicleStatusData.hvBatteryLevel,
					alt: 'energy',
				},
				{
					label: vehicleStatusData?.fuelLevel && `${vehicleStatusData.fuelLevel} liter`,
					value: vehicleStatusData?.fuelLevel,
					alt: 'fuel',
				},
				{
					label: charging[vehicleStatusData.charging],
					value: vehicleStatusData?.charging,
					alt: 'charger',
				},
			]
			: []),
		...(isCar && deviceHeartbeatsData ?
			[
				{
					label: `${deviceHeartbeatsData?.mileage}km`,
					value: deviceHeartbeatsData?.mileage,
					alt: 'mileage',
				},
				{
					label: `${deviceHeartbeatsData?.range}km`,
					value: deviceHeartbeatsData?.range,
					alt: 'range',
				},
				{
					label: `${deviceHeartbeatsData?.vehicleBatteryLevel}%`,
					value: deviceHeartbeatsData?.vehicleBatteryLevel,
					alt: 'energy',
				},
				{
					label: fuel,
					value: deviceHeartbeatsData?.vehicleFuelVolume || deviceHeartbeatsData?.vehicleFuelLevel,
					alt: 'fuel',
				},
				{
					label: deviceHeartbeatsData?.batteryCharging ? charging.Charging : charging.NotCharging,
					value: isBoolean(deviceHeartbeatsData?.batteryCharging),
					alt: 'charger',
				},
			]
			: []),
		...(deviceHeartbeatsData ?
			[
				...((
					selectedFleet?.deviceType === 'bikeLock' || selectedFleet?.deviceType === 'trailerLock'
				) ?
					[
						{
							label: t(`ui.label.${deviceHeartbeatsData?.lockState}`),
							value: deviceHeartbeatsData?.lockState,
							alt: 'lockState',
						},
						{
							label: t(`ui.label.device.state.${deviceHeartbeatsData?.state}`),
							value: deviceHeartbeatsData?.state,
							alt: 'status',
						},
					]
					: []),
			]
			: []),
		...(isBoat ?
			[
				{
					label: `${deviceHeartbeatsData?.mileage}km`,
					value: deviceHeartbeatsData?.mileage,
					alt: 'mileage',
				},
				{
					label: `${deviceHeartbeatsData?.range}km`,
					value: deviceHeartbeatsData?.range,
					alt: 'range',
				},
				{
					label: `${deviceHeartbeatsData?.vehicleBatteryLevel}%`,
					value: deviceHeartbeatsData?.vehicleBatteryLevel,
					alt: 'energy',
				},
				{
					label: fuel,
					value: deviceHeartbeatsData?.vehicleFuelVolume || deviceHeartbeatsData?.vehicleFuelLevel,
					alt: 'fuel',
				},
				{
					label: deviceHeartbeatsData?.batteryCharging ? charging.Charging : charging.NotCharging,
					value: isBoolean(deviceHeartbeatsData?.batteryCharging),
					alt: 'charger',
				},
			]
			: []),
	];

	const secondColumnDetails = [
		...(vehicleStatusData ?
			[
				{
					label: centralLocking[vehicleStatusData.centralLocking],
					value: vehicleStatusData?.centralLocking,
					alt: 'lockState',
				},
				{
					label: disabledEnabled[vehicleStatusData?.ignition?.toLowerCase()],
					value: vehicleStatusData?.ignition,
					alt: 'power',
				},
				{
					label: '',
					value: '',
					icon: <CarRentalIcon className={classes.vehicleTrackerCan} />,
					alt: 'immobiliser',
				},
				{
					label: '',
					value: '',
					icon: <KeyIcon className={classes.vehicleTrackerCan} />,
					alt: 'key',
				},
				{ label: handleWindows(), value: isFullArray(vehicleStatusData?.windows), alt: 'window' },
			]
			: []),
		...(isCar && deviceHeartbeatsData ?
			[
				{
					label: t(`ui.label.${deviceHeartbeatsData?.lockState}`),
					value: deviceHeartbeatsData?.lockState && deviceHeartbeatsData?.lockState !== 'none',
					alt: 'lockState',
				},
				{
					label: disabledEnabled[deviceHeartbeatsData?.ignition?.toLowerCase()],
					value: deviceHeartbeatsData?.ignition,
					alt: 'power',
				},
				{
					label:
						deviceHeartbeatsData?.immobiliserStateEnabled ?
							disabledEnabled.enabled
							: disabledEnabled.disabled,
					value: isBoolean(deviceHeartbeatsData?.immobiliserStateEnabled),
					icon: <CarRentalIcon className={classes.vehicleTrackerCan} />,
					alt: 'immobiliser',
				},
				{
					label:
						deviceHeartbeatsData?.keyDetected ? disabledEnabled.enabled : disabledEnabled.disabled,
					value: isBoolean(deviceHeartbeatsData?.keyDetected),
					icon: <KeyIcon className={classes.vehicleTrackerCan} />,
					alt: 'key',
				},
				{ label: handleDoors(), value: isFullArray(deviceHeartbeatsData?.doors), alt: 'window' },
			]
			: []),
		...(deviceHeartbeatsData ?
			[
				...((
					selectedFleet?.deviceType === 'bikeLock' ||
					selectedFleet?.deviceType === 'trailerLock' ||
					selectedFleet?.deviceType === 'doorLock'
				) ?
					[
						{
							label: `${deviceHeartbeatsData.temperature}°C`,
							value: deviceHeartbeatsData?.temperature,
							alt: 'temperature',
						},
					]
					: []),
			]
			: []),
		...(isBoat ?
			[
				{
					label: t(`ui.label.device.state.${deviceHeartbeatsData?.state}`),
					value: deviceHeartbeatsData?.state,
					alt: 'status',
				},
				{
					label: t(`ui.label.${deviceHeartbeatsData?.lockState}`),
					value: deviceHeartbeatsData?.lockState && deviceHeartbeatsData?.lockState !== 'none',
					alt: 'lockState',
				},
				{
					label: disabledEnabled[deviceHeartbeatsData?.ignition?.toLowerCase()],
					value: deviceHeartbeatsData?.ignition,
					alt: 'power',
				},
				{
					label:
						deviceHeartbeatsData?.immobiliserStateEnabled ?
							disabledEnabled.enabled
							: disabledEnabled.disabled,
					value: isBoolean(deviceHeartbeatsData?.immobiliserStateEnabled),
					icon: <CarRentalIcon className={classes.vehicleTrackerCan} />,
					alt: 'immobiliser',
				},
				{
					label: `${deviceHeartbeatsData?.temperature}°C`,
					value: deviceHeartbeatsData?.temperature,
					alt: 'temperature',
				},
			]
			: []),
	];

	const handleReach = () => {
		if (isFullArray(itemReach)) {
			return itemReach.map((item) => (
				<Box
					alignItems='center'
					display='flex'
					justifyContent='space-between'
					key={item.alt}
					pb={1}
					pt={1}
				>
					<Box alignItems='center' display='flex'>
						<Box pr={1}>
							<img alt={item.alt} height='16' src={item.icon} width='16' />
						</Box>
						<Box minWidth='110' pr={1}>
							<Typography className={classes.deviceCardItems}>
								{selectedFleet?.deviceType === 'doorLock' && item.enum ? mapBatteryLevelEnum(item.enum) : item.label}
							</Typography>
						</Box>
					</Box>
					{selectedFleet?.deviceType !== 'doorLock' && (
						<Box flexBasis='67%'>
							<LinearProgress
								color='neutral'
								value={parseInt(item.level, 10)}
								variant='determinate'
							/>
						</Box>
					)}
				</Box>
			));
		} else {
			return null;
		}
	};

	const handleDetails = () => {
		if (isObject(vehicleStatusData) || isObject(deviceHeartbeatsData)) {
			return (
				<Box display='flex'>
					{isFullArray(firstColumnDetails) ?
						<Box flexDirection='column' pr={15}>
							{firstColumnDetails.map((first) => (
								<Box alignItems='center' display='flex' key={first.alt} pb={1} pt={1}>
									<Box pr={1}>
										{<img alt={first.alt} height='16' src={icons[first.alt]} width='16' />}
									</Box>
									<Typography className={classes.deviceCardItems}>
										{first.value || isInteger(first.value) ? first.label : '-'}
									</Typography>
								</Box>
							))}
						</Box>
						: null}
					<Box flexDirection='column'>
						{secondColumnDetails.map((second) => (
							<Box alignItems='center' display='flex' key={second.alt} pb={1} pt={1}>
								<Box pr={1}>
									{second.icon ?
										second.icon
										: <img alt={second.alt} height='16' src={icons[second.alt]} width='16' />}
								</Box>
								<Typography className={classes.deviceCardItems}>
									{second.value || isInteger(second.value) ? second.label : '-'}
								</Typography>
							</Box>
						))}
					</Box>
				</Box>
			);
		} else {
			return null;
		}
	};

	const boxProps = {
		pt: 1,
		pb: 1,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
	};

	return (
		<div className={classes.deviceCard}>
			<Box {...boxProps}>
				<Typography variant='body2'>{`${t('views.ticketsDetails.lastUpdated')}: ${commaTimeStrings(deviceCard)}`}</Typography>
			</Box>

			{(
				selectedFleet?.deviceType === 'bikeLock' ||
				selectedFleet?.deviceType === 'trailerLock' ||
				selectedFleet?.deviceType === 'doorLock'
			) ?
				<>
					<Box {...boxProps}>
						<Typography variant='h6'>{t('views.fleetDrawer.itemInfo')}</Typography>
					</Box>
					{handleReach()}
					<Box {...boxProps}>{handleDetails()}</Box>
					<Divider />
				</>
				: null}
			{isCar || selectedFleet?.deviceType === 'bmwCar' ?
				<>
					<Box {...boxProps}>{handleDetails()}</Box>
					<Divider />
					<Box {...boxProps}>
						<Typography variant='h6'>{t('views.fleetDrawer.itemInfo')}</Typography>
					</Box>
					{selectedFleet?.deviceType !== 'bmwCar' && handleReach()}
				</>
				: null}
			{isBoat ?
				<Box {...boxProps}>{handleDetails()}</Box>
				: null}
			<Box {...boxProps}>
				<Typography className={classes.deviceCardItems}>
					{
						<>
							{'Hw UID: '}
							{handleLinkDevice()}
						</>
					}
				</Typography>
				<Typography className={classes.deviceCardItems}>
					{cardData?.description ? cardData?.description : '-'}
				</Typography>
			</Box>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		fetchDevice: state.details.fetchDevice,

		fetchDeviceHeartbeats: state.details.fetchDeviceHeartbeats,
		vehicleStatus: state.details.vehicleStatus,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchDevice: (id) => dispatch(actions.fetchDevice(id)),
		onFetchVehicleStatus: (id) => dispatch(actions.fetchVehicleStatus(id)),
		onFetchDeviceHeartbeats: (id) => dispatch(actions.fetchDeviceHeartbeats(id)),
		onResetState: (state) => dispatch(actions.resetState(state)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceCard);

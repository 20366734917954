import { useState } from 'react';

import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { SelectWithLazyLoading } from '../../../components';
import { isInteger, isEmptyString } from '../../../shared/utility';
import * as actions from '../../../store/actions';
import { useAuthorize } from '~features/authentication';

interface HeaderProps {
	onFetchOrganisations?(...args: unknown[]): unknown;
	dashboardOrganisationFilter?: object;
	organisationsList?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const Header = (props: HeaderProps) => {
	const { onFetchOrganisations, organisationsList, dashboardOrganisationFilter } = props;
	const { t } = useTranslation();
	const { isSuperAdmin } = useAuthorize();

	const smallCard = { item: true, xs: 6, md: 2, lg: 2, xl: 2 };

	const [filter] = useState({ name: 'filter', value: 'verified' });

	const selectOrganisation = (item) => {
		if (item.id === dashboardOrganisationFilter.valueId) {
			return;
		}
		if (isInteger(item.id)) {
			dashboardOrganisationFilter.setValueName(item.name);
		} else if (isEmptyString(item.id)) {
			dashboardOrganisationFilter.setValueName('');
		}
		dashboardOrganisationFilter.setValueId(item.id);
	};

	return (
		<Grid alignItems='center' container spacing={3}>
			<Grid item>
				<Typography component='h1' variant='h3'>
					{t('nav.category.dashboard')}
				</Typography>
			</Grid>
			{isSuperAdmin() ?
				<Grid {...smallCard}>
					<SelectWithLazyLoading
						dataList={organisationsList}
						defaultListItem={{ id: '', name: t('ui.filter.organisations.all') }}
						events={{}}
						filter={filter}
						label={t('ui.organisation')}
						onFetchData={onFetchOrganisations}
						searchHandle={dashboardOrganisationFilter.setValueName}
						setSelected={selectOrganisation}
						value={dashboardOrganisationFilter.valueName}
					/>
				</Grid>
			:	null}
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		organisationsList: state.paged.organisations,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchOrganisations: (page, filters, concat) =>
			dispatch(actions.fetchOrganisations(page, filters, concat)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

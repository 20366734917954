import {
	Card,
	CardContent,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Box,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Label, UserCard, OpenEmail } from '../../../../../components';
import { useStyles } from '../../../style';

interface UserInfoProps {
	bookingData: object;
	userData?: object;
	visibility?: object;
}

const UserInfo = (props: UserInfoProps) => {
	const { bookingData, userData } = props;
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();

	const classes = useStyles();

	const licenseStatusColors = { pending: 'default', verified: 'success', rejected: 'error' };

	const handleUserView = () => {
		navigate(`/user-management/users/${userData.id}/summary`, {
			state: { from: location.pathname, showBackButton: true },
		});
	};

	const userInfoData = [
		{ label: 'ui.label.email', content: <OpenEmail email={userData.emailAddress} /> },
		{ label: 'ui.organisation', content: userData.organisationReference.name },
		...(bookingData?.licensePlateNumber ?
			[{ label: 'ui.label.licensePlate', content: bookingData.licensePlateNumber }]
		:	[]),
		...(bookingData?.createdByUser ?
			[{ label: 'ui.label.bookingDetails.createdBy', content: bookingData.createdByUser.name }]
		:	[]),
	];

	return (
		<Card className={classes.card}>
			<Box display='flex' justifyContent='space-between' pb={2} pl={3} pr={3} pt={2}>
				<Typography variant='h5'>{t('ui.userInfo')}</Typography>
				{userData.licenseStatus && bookingData.itemInstance.categoryReference.type === 'cars' ?
					<Label
						type={licenseStatusColors[userData.licenseStatus]}
					>{`${t('ui.License')} ${userData.licenseStatus}`}</Label>
				:	null}
			</Box>
			<Divider />
			<CardContent className={classes.cardConten}>
				<UserCard
					hasButton
					onClick={handleUserView}
					user={userData}
					userImage={userData.imagesReference}
				/>
				<Table>
					<TableBody>
						{userInfoData.map((info) => (
							<TableRow key={info.label}>
								<TableCell>{t(info.label)}</TableCell>
								<TableCell>{info.content}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	);
};

export default UserInfo;

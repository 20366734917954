import { Typography, Card, CardContent, Box, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';
import { HeaderWithStatus } from '../../../../../components';
import { commaTimeStrings } from '../../../../../shared/datetime';
import { isFullString } from '../../../../../shared/utility';

interface ActivitieCardProps {
	comment?: string;
	created?: string;
	modified?: string;
	status?: string;
	user?: object;
	statusOfTicket?: unknown[];
}

const ActivitieCard = (props: ActivitieCardProps) => {
	const { comment, created, status, user } = props;
	const { t } = useTranslation();

	const statusColor = {
		open: 'error',
		pending: 'default',
		planned: 'default',
		ready: 'success',
		closed: 'disabled',
	};

	const statusText = {
		open: t('ui.open'),
		pending: t('ui.status.pending'),
		planned: t('ui.status.planned'),
		ready: t('ui.status.ready'),
		closed: t('ui.openStatus.closed'),
	};

	const classes = useStyles();
	return (
		<Box>
			<Typography variant='body2'>
				{`${t('ui.dateCreated')} ${commaTimeStrings(created)}`}
			</Typography>
			{isFullString(comment) ? null : (
				<Card className={classes.card}>
					<CardContent className={classes.cardContent}>
						<HeaderWithStatus
							header={user.organisationReference.name}
							headerSize='h6'
							subheader={user.name}
							subheaderSize='body2'
						/>
						<HeaderWithStatus
							header={`${t('ui.changedStatusTo')}: `}
							headerSize='h6'
							status={statusText[status]}
							type={statusColor[status]}
						/>
					</CardContent>
				</Card>
			)}
			{isFullString(comment) ?
				<Card className={classes.card}>
					<CardContent className={classes.commentContent}>
						<Box display='flex' justifyContent='space-between'>
							<HeaderWithStatus
								header={`${user.organisationReference.name}`}
								headerSize='h6'
								subheader={user.name}
								subheaderSize='body2'
							/>
							<HeaderWithStatus
								header={`${t('ui.changedStatusTo')}: `}
								headerSize='h6'
								status={statusText[status]}
								type={statusColor[status]}
							/>
						</Box>
						<Box pb={2.4} pt={2.5}>
							<Divider />
						</Box>
						<Typography variant='h6'>{t('views.planboard.addBooking.comment')}</Typography>
						<Box pt={1.7}>
							<Typography variant='body2'>{comment}</Typography>
						</Box>
					</CardContent>
				</Card>
			:	null}
		</Box>
	);
};

export default ActivitieCard;

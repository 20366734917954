import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { StyledButton } from '../../../components';
import { isUndefined } from '../../../shared/utility';

interface HeaderProps {
	className?: string;
	buttonPlaceholder?: string;
	action?(...args: unknown[]): unknown;
	href?: string;
}

const Header = (props: HeaderProps) => {
	const { className, buttonPlaceholder, href, action } = props;
	const { t } = useTranslation();

	return (
		<div className={className}>
			<Grid alignItems='flex-end' container justifyContent='space-between' spacing={3}>
				<Grid item>
					<Typography component='h2' gutterBottom variant='overline'></Typography>
					<Typography component='h1' variant='h3'>
						{t('views.organisation-management.page.title')}
					</Typography>
				</Grid>
				<Grid item>
					{isUndefined(buttonPlaceholder) ? null : (
						<StyledButton href={href} onClick={action} variant='contained-primary'>
							{buttonPlaceholder}
						</StyledButton>
					)}
				</Grid>
			</Grid>
		</div>
	);
};

export default Header;

import { MutableRefObject, useEffect, useState } from 'react';

/**
 * Keep track of the size of an element
 * @param ref
 * @returns
 */
const useElementSize = (ref: MutableRefObject<HTMLDivElement | undefined>) => {
	const [size, setSize] = useState({ width: 0, height: 0 });

	useEffect(() => {
		const observer = new ResizeObserver((entries) => {
			setSize({
				width: entries[0].contentRect.width,
				height: entries[0].contentRect.height,
			});
		});

		if (ref.current) {
			observer.observe(ref.current);
		}

		return () => {
			if (ref.current) {
				observer.unobserve(ref.current);
			}
		};
	}, [ref.current]);

	return size;
};

export default useElementSize;

import React, { useRef, useState } from 'react';

import { AutocompleteInputChangeReason } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import useSWRInfinite from 'swr/infinite';

import { userInfoAtom } from '~atoms';
import { AsyncAutocomplete, AsyncAutocompleteProps } from '~components';
import { PagedResults } from '~interfaces';
import i18n from '~lib/i18n';

import Organisation from '../../interfaces/organisation';
import PartnerShipsService from '../../services/partnerShipsService';

const service = new PartnerShipsService();

interface PartnerAutocompleteProps
	extends Omit<AsyncAutocompleteProps<Organisation>, 'options' | 'label'> {
	label?: string;
	count?: number;
	status?: 'accepted' | 'pending';
	includeUserOrganisation?: boolean;
}

const PartnerAutocomplete = ({
	label = i18n.t('partner'),
	count = 10,
	status,
	includeUserOrganisation = false,
	...asyncAutocompleteProps
}: PartnerAutocompleteProps) => {
	const { t } = useTranslation();
	const userInfo = useAtomValue(userInfoAtom);

	const [searchQuery, setSearchQuery] = useState('');
	const [open, setOpen] = useState(false);

	// Let's set the on the first page response. Accept that it may happen
	// if an organisation is added in the mean time
	// Small changes right..?
	const total = useRef<number>();
	const page = useRef(1);

	const getKey = (i: number, previousData: PagedResults<Organisation[]>) => {
		if (!open || (previousData && !previousData.hasMore)) {
			// Not open or reached the end
			return null;
		}

		page.current = i + 1;

		return [
			service.basePath,
			{
				page: page.current,
				pageSize: count,
				searchQuery: searchQuery || undefined,
				status: status,
			},
		] as const;
	};

	const { data, isLoading, isValidating, size, setSize } = useSWRInfinite(
		getKey,
		([_, args]) => service.getPartners(args),
		{
			keepPreviousData: true,
			revalidateFirstPage: false,
			onSuccess: (res) => (total.current = res[0].total),
		},
	);

	/**
	 * Just set the size of the pages
	 */
	const handleOverflow = () => {
		if ((size - 1) * count <= total.current) {
			setSize(size + 1);
		}
	};

	const handleInputChange = (
		e: React.SyntheticEvent<Element, Event>,
		value: string,
		reason: AutocompleteInputChangeReason,
	) => {
		setSearchQuery(value);

		asyncAutocompleteProps.onInputChange?.(e, value, reason);
	};

	const dataTmp = [
		...(includeUserOrganisation ? [userInfo?.organisation] : []),
		...(data?.flatMap((el) => el.results) ?? []),
	];

	return (
		<AsyncAutocomplete
			{...asyncAutocompleteProps}
			label={label}
			loading={isLoading || isValidating}
			noOptionsText={t('noResults')}
			onOpen={() => setOpen(true)}
			onClose={() => setOpen(false)}
			options={dataTmp}
			onInputChange={handleInputChange}
			onOverflow={handleOverflow}
		/>
	);
};

export default PartnerAutocomplete;

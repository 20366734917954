import { useEffect, useMemo, useState } from 'react';

import path from 'path';

import {
	Block as BlockIcon,
	DeleteOutlined as DeleteOutlinedIcon,
	Edit as EditIcon,
	PersonRemove as PersonRemoveIcon,
	PriorityHigh as PriorityHighIcon,
} from '@mui/icons-material';
import {
	Avatar,
	Box,
	Button,
	Link as MuiLink,
	Portal,
	Stack,
	SvgIcon,
	useTheme,
} from '@mui/material';
import {
	GridColDef,
	GridFilterModel,
	GridPaginationModel,
	GridRenderCellParams,
	GridRowParams,
	GridSortModel,
} from '@mui/x-data-grid';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Link as ReactRouterLink } from 'react-router-dom';
import useSWR from 'swr';

import { userInfoAtom } from '~atoms';
import { DataGrid, DataGridToolbar, GridActionsCellItem, Tooltip } from '~components';
import { pagePathSegments } from '~constants';
import { UserRoleEnum } from '~enums';
import { useAuthorize, UserRoleSelect } from '~features/authentication';
import { useUrlSearchParamsOrganisation } from '~features/organisations';
import { useDownloadFile } from '~hooks';
import { BaseReference, IdReference } from '~interfaces';
import { getInitials } from '~utils/stringUtils';

import User from '../../interfaces/user';
import UsersService from '../../services/usersService';

// TODO: eventually through a theme
const colors = {
	warning: {
		// bgColor: '#c98171',
		bgColor: '#ffffff',
		color: 'warning',
	},
};

const toolbarIdString = 'topology-users-toolbar';

const usersService = new UsersService();

interface UsersDataGridProps {
	enableEdit?: boolean;
	enableDelete?: boolean;
	onCreate?: (type: 'anonymous') => void;
	onInvite?: () => void;
	onEdit?: (id: string) => void;
	onDisable?: (id: string) => void;
	onDelete?: (id: string) => void;
}

const UsersDataGrid = ({ onCreate, onInvite, onEdit, onDisable, onDelete }: UsersDataGridProps) => {
	const { t } = useTranslation();
	const theme = useTheme();
	const { isSuperAdmin } = useAuthorize();
	const userInfo = useAtomValue(userInfoAtom);
	const { isLoading: isFileDownloading, downloadServerFile: downloadFile } = useDownloadFile();

	const [selectedOrganisation, setSelectedOrganisation] = useUrlSearchParamsOrganisation();
	const [fetchParameters, setFetchParameters] = useState<object | null>(null);

	const columns = useMemo<GridColDef<User>[]>(
		() => [
			{
				field: 'imageUrl',
				headerName: '',
				sortable: false,
				filterable: false,
				width: 60,
				renderCell: (params) => (
					<Box
						sx={{
							alignContent: 'center',
							height: 1,
						}}
					>
						<Avatar src={params.row.imageUrl}>
							{getInitials(`${params.row.firstName} ${params.row.lastName}`)}
						</Avatar>
					</Box>
				),
			},
			{
				field: 'label',
				headerName: t('name'),
				renderCell: (params: GridRenderCellParams<User>) => (
					<MuiLink
						component={ReactRouterLink}
						to={`/${path.join(`${pagePathSegments.UserManagement}2`, pagePathSegments.Users, params.row.id)}`}
						sx={{
							fontStyle: params.value && params.value !== ' ' ? 'inherit' : 'italic',
						}}
					>
						{params.value && params.value !== ' ' ? params.value : t('noName')}
					</MuiLink>
				),
				flex: 1,
			},
			{
				field: 'id',
				headerName: 'ID',
				renderCell: (params: GridRenderCellParams<User>) => (
					<MuiLink
						component={ReactRouterLink}
						to={`/${path.join(pagePathSegments.UserManagement, pagePathSegments.Users, params.value, pagePathSegments.Summary)}`}
					>
						{params.value}
					</MuiLink>
				),
			},
			{
				field: 'email',
				headerName: t('ui.label.email'),
				renderCell: (params: GridRenderCellParams<User>) =>
					params.value ?
						<Stack spacing={0.5} direction='row' sx={{ alignItems: 'center' }}>
							<MuiLink href={`mailto:${params.value}`}>{params.value}</MuiLink>
							{!params.row.isEmailConfirmed && (
								<Tooltip title={t('ui.emailNotVerified')}>
									<Avatar
										sx={{
											width: 16,
											height: 16,
											bgcolor: colors['warning'].bgColor,
										}}
									>
										<SvgIcon component={PriorityHighIcon} color='warning' sx={{ fontSize: 12 }} />
									</Avatar>
								</Tooltip>
							)}
						</Stack>
					:	'-',
				flex: 1,
			},
			{
				field: 'role',
				headerName: t('views.userDetails.summary.cardHeaders.userRole'),
				valueFormatter: (value: string) => t(value),
			},
			{
				field: 'organisation',
				headerName: t('organisation'),
				renderCell: (params: GridRenderCellParams<User>) => (
					<MuiLink
						component={ReactRouterLink}
						to={`/${path.join(pagePathSegments.Organisations, params.value.id, pagePathSegments.Summary)}`}
					>
						{params.value.label}
					</MuiLink>
				),
				flex: 1,
			},
			{
				field: 'cards',
				headerName: t('nfcId'),
				sortable: false,
				valueFormatter: (value?: IdReference[]) => value?.[0]?.id ?? '-',
				flex: 1,
			},
			{
				field: 'userGroup',
				headerName: t('userGroup'),
				valueFormatter: (value: BaseReference) => value.label,
				renderCell: (params: GridRenderCellParams<BaseReference>) => (
					<MuiLink
						component={ReactRouterLink}
						to={`/${path.join(pagePathSegments.UserManagement, pagePathSegments.UserGroups, params.value.id, pagePathSegments.Summary)}`}
					>
						{params.value.label}
					</MuiLink>
				),
				flex: 1,
			},
			{
				field: 'actions',
				type: 'actions',
				width: 50,
				align: 'right',
				resizable: false,
				hideable: false,
				getActions: (params: GridRowParams) => [
					<GridActionsCellItem
						showInMenu
						key={`${params.id}-edit`}
						label={t('ui.edit')}
						icon={<EditIcon />}
						onClick={() => onEdit?.(params.id.toString())}
					/>,
					<GridActionsCellItem
						key={`${params.id}-deactivate`}
						label={t('ui.deactivate')}
						icon={<BlockIcon color='error' />}
						onClick={() => onDisable?.(params.id.toString())}
						hidden={!isSuperAdmin()}
						showInMenu
						sx={{ color: theme.palette.error.main }}
					/>,
					<GridActionsCellItem
						key={`${params.id}-removeFromOrganisation`}
						label={t('removeFromOrganisation')}
						icon={<PersonRemoveIcon color='error' />}
						onClick={() => onDelete?.(params.id.toString())}
						hidden={isSuperAdmin()}
						showInMenu
						sx={{ color: theme.palette.error.main }}
					/>,
				],
			},
		],
		[]
	);

	const { data, isLoading, isValidating, error } = useSWR(
		[usersService.basePath, fetchParameters],
		([_, args]) => {
			if (isLoading || isValidating) {
				usersService.abortCurrentRequest('parameter change');
			}

			return usersService.getUsers(args);
		},
		{
			onSuccess: (res) => res?.total != null && setTotalCount(res.total),
			keepPreviousData: true,
		}
	);

	const [totalCount, setTotalCount] = useState<number>(data?.total || 0);

	const handlePaginationModelChange = (value: GridPaginationModel) => {
		setFetchParameters((prev) => ({
			...prev,
			page: value.page + 1,
			pageSize: value.pageSize,
		}));
	};

	const handleSortModelChange = (model: GridSortModel) => {
		setFetchParameters((prev) => ({
			...prev,
			sortBy: model?.[0]?.field,
			descending: model?.[0]?.sort === 'desc',
		}));
	};

	const handleFilterModelChange = (model: GridFilterModel) => {
		const organisation =
			model.items?.[0]?.field === 'organisation' ? model.items[0].value : undefined;

		setSelectedOrganisation(organisation);
		setFetchParameters((prev) => ({
			...prev,
			searchQuery: model.quickFilterValues?.[0],
			organisationId: organisation?.id,
		}));
	};

	const handleExportUsers = async () => {
		downloadFile(
			() => usersService.getUsersForExport(fetchParameters.organisation?.id ?? userInfo.organisation.id),
			'User details'
		);
	};

	return (
		<Stack
			spacing={2}
			sx={{
				height: 1,
				display: 'flex',
			}}
		>
			<Box id={toolbarIdString} />
			<DataGrid
				checkboxSelection
				columns={columns}
				error={error}
				loading={isLoading || isValidating}
				rows={data?.results ?? []}
				rowCount={totalCount}
				onPaginationModelChange={handlePaginationModelChange}
				onSortModelChange={handleSortModelChange}
				onFilterModelChange={handleFilterModelChange}
				slots={{
					toolbar: Toolbar,
				}}
				slotProps={{
					toolbar: {
						isFileDownloading: isFileDownloading,
						handleExportUsers: handleExportUsers,
						onCreate: onCreate,
						onInvite: onInvite,
						role: fetchParameters?.role,
						onRoleChange: (val: UserRoleEnum) =>
							setFetchParameters((prev) => ({ ...prev, role: val })),
					},
				}}
				initialState={{
					filter: {
						filterModel: {
							items: selectedOrganisation ? [
								{
									field: 'organisation',
									operator: 'contains',
									value: selectedOrganisation,
								}
							] : []
						}
					},
					columns: {
						columnVisibilityModel: {
							role: isSuperAdmin(),
						},
					},
					sorting: {
						sortModel: [
							{
								field: 'id',
								sort: 'desc',
							},
						],
					},
				}}
				sx={{ flexGrow: 1, flexBasis: 200 }}
			/>
		</Stack>
	);
};

const Toolbar = (props) => {
	const { t } = useTranslation();
	const { isSuperAdmin } = useAuthorize();

	return (
		<Portal container={() => document.getElementById(toolbarIdString)}>
			<DataGridToolbar
				filters={
					isSuperAdmin() ?
						<UserRoleSelect
							displayEmpty
							size='small'
							value={props.role}
							onChange={value => props.onRoleChange(value)}
						/>
					:	undefined
				}
			>
				<Button
					loading={props.isFileDownloading}
					variant='outlined'
					onClick={props.handleExportUsers}
				>
					{t('views.userDetails.summary.exportUsers')}
				</Button>
				<Button variant='outlined' onClick={() => props.onCreate?.('anonymous')}>
					{t('addAnonymousUser')}
				</Button>
				<Button variant='contained' onClick={() => props.onInvite?.()}>
					{t('ui.button.contained.inviteusers')}
				</Button>
			</DataGridToolbar>
		</Portal>
	);
};

export default UsersDataGrid;

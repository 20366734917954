import React, { ReactNode } from 'react';

import {
	CircularProgress,
	FormControl,
	InputAdornment,
	InputLabel,
	ListSubheader,
	MenuItem,
	Select as MuiSelect,
	SelectProps as MuiSelectProps,
	SelectChangeEvent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { BaseReference } from '~interfaces';
import i18n from '~lib/i18n';

interface GroupedOption<T extends BaseReference> {
	key: number;
	i: number;
	group: string;
	options: T[];
}

export type AsyncSelectProps<T extends BaseReference> = Omit<
	MuiSelectProps,
	'children' | 'onChange'
> & {
	value?: T | null;
	loading?: boolean;
	onChange?: (e: SelectChangeEvent<string>, value: T | null) => void;
	options: T[];
	clearable?: boolean;
	emptyLabel?: string;
	groupBy?: (option: T) => string;
	renderGroupHeader?: (group: GroupedOption<T>) => ReactNode;
	getOptionLabel?: (option: T) => string;
	/**
	 * A helper text on top of the list
	 */
	helperText?: string;
};

/**
 * An async select component. E.g. used for a finite amount of server defined options.
 */
const AsyncSelect = <T extends BaseReference>({
	loading = false,
	value,
	onChange,
	options,
	clearable = false,
	displayEmpty,
	emptyLabel = i18n.t('all'),
	groupBy,
	renderGroupHeader = (option) => option.group,
	getOptionLabel = (option) => option.label ?? option,
	helperText,
	...props
}: AsyncSelectProps<T>) => {
	const { t } = useTranslation();

	const label = props.required && props.label ? `${props.label} *` : props.label;

	const handleChange = (event: SelectChangeEvent<string>) => {
		if (event.target.value === '') {
			onChange?.(event, null);
		} else {
			const option = options.find((el) => el.id === event.target.value);
			onChange?.(event, option);
		}
	};

	/**
	 * Credits: Mui
	 * See: https://github.com/mui/material-ui/blob/master/packages/mui-base/src/useAutocomplete/useAutocomplete.js
	 */
	const groupedOptions = options.reduce((acc: GroupedOption<T>[], option, i) => {
		const group = groupBy?.(option) ?? 'ungrouped';
		if (acc.length > 0 && acc[acc.length - 1].group === group) {
			acc[acc.length - 1].options.push(option);
		} else {
			acc.push({
				key: i,
				i,
				group,
				options: [option],
			});
		}

		return acc;
	}, []);

	const renderGroup = (params) => {
		const elements = params.options.map((el) => (
			<MenuItem key={el.id} value={el.id}>
				{getOptionLabel(el)}
			</MenuItem>
		));

		return [<ListSubheader disableSticky>{renderGroupHeader(params)}</ListSubheader>, elements];
	};

	return (
		<FormControl sx={{ width: props.sx?.width ?? 1 }}>
			<InputLabel id={props.labelId} shrink={true}>
				{label}
			</InputLabel>
			<MuiSelect
				{...props}
				label={label}
				displayEmpty={displayEmpty}
				value={value?.id ?? ''}
				onChange={handleChange}
				renderValue={(selected) => {
					if (selected === '') {
						return emptyLabel;
					}

					// Find the label, otherwise fallback to selected to at least show something
					const selectedOption = options.find((el) => el.id == selected);
					return getOptionLabel != null && selectedOption ?
							getOptionLabel(selectedOption)
						:	(selectedOption?.label ?? selected);
				}}
				endAdornment={
					<InputAdornment
						position='end'
						sx={{ position: 'absolute', right: 35, pointerEvents: 'none' }}
					>
						{loading && <CircularProgress size={20} color='inherit' />}
					</InputAdornment>
				}
				sx={{ width: 1, ...props.sx }}
			>
				{loading ?
					<MenuItem disabled>{`${t('loading')}...`}</MenuItem>
				:	[
						helperText != null ?
							<MenuItem key='helper' disabled>
								<em>{helperText}</em>
							</MenuItem>
						:	undefined,
						clearable && displayEmpty ?
							<MenuItem key='empty' value={''} sx={{ fontWeight: 500 }}>
								{emptyLabel}
							</MenuItem>
						:	undefined,
						...(groupBy != null ?
							groupedOptions.map((el) => renderGroup(el))
						:	options.map((el) => (
								<MenuItem key={el.id} value={el.id}>
									{getOptionLabel(el)}
								</MenuItem>
							))),
					]
				}
			</MuiSelect>
		</FormControl>
	);
};

export default AsyncSelect;

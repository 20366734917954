import { useState, useEffect } from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Avatar, Paper, Link, Typography } from '@mui/material';
import { getName } from 'i18n-iso-countries';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';

import { datetimeWithoutSecondsFormatter } from '~utils/dateUtils';
import { getInitials } from '~utils/stringUtils';

import { useStyles } from './style';
import { Table, SearchBar, Label } from '../../../components';
import { localizeDateTime } from '../../../shared/datetime';
import { useSearchComponent, usePagination } from '../../../shared/hooks';
import {
	isObject,
	isEmptyArray,
	isEmptyString,
	isFullArray,
	isNull,
} from '../../../shared/utility';
import * as actions from '../../../store/actions';

interface LicensesListProps {
	onFetchLicenses?(...args: unknown[]): unknown;
	licenseType?: string;
	onUpdatePagedState?(...args: unknown[]): unknown;
	pendingLicenses?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const LicensesList = (props: LicensesListProps) => {
	const classes = useStyles();

	const { pendingLicenses, onFetchLicenses, onUpdatePagedState, licenseType } = props;
	const { i18n, t } = useTranslation();
	const location = useLocation();

	const { tab } = useParams();

	const [shouldSearch, setShouldSearch] = useState(false);

	const [order, setOrder] = useState('asc');
	const [orderDescending, setOrderDescending] = useState(false);
	const [sortByProperty, setSortByProperty] = useState('created');
	const [loading, setLoading] = useState(true);
	const [shouldSort, setShouldSort] = useState(false);
	const [status] = useState(tab);

	const pagination = usePagination(`pageNumber${licenseType}`, `pageSize${licenseType}`);

	const search = useSearchComponent(
		pagination.setPageNumber,
		setShouldSearch,
		`licensesSearch${licenseType}`,
	);

	const { data: pendingLicensesData, loading: pendingLicensesLoading } = pendingLicenses;

	const isPageEmpty = isObject(pendingLicensesData) && isEmptyArray(pendingLicensesData.results);
	const isPageFull = isObject(pendingLicensesData) && isFullArray(pendingLicensesData.results);

	const filters = {
		...(!isEmptyString(search.value) && { searchTerm: search.value }),
		...(!isEmptyString(sortByProperty) && { sortBy: sortByProperty }),
		status: status,
		orderDescending,
	};

	useEffect(() => {
		if (
			!pendingLicensesLoading &&
			(pagination.fetch ||
				shouldSearch ||
				shouldSort ||
				sortByProperty ||
				isNull(pendingLicensesData))
		) {
			onFetchLicenses(pagination.page, filters);
			setLoading(true);
		}
		if (shouldSearch) {
			setShouldSearch(false);
		} else if (shouldSort) {
			setShouldSort(false);
		} else if (pagination.fetch) {
			pagination.setFatch(false);
		}

		return () => {
			if (shouldSearch || pagination.fetch) {
				null;
			} else {
				onUpdatePagedState('licenses', null, false);
			}
		};
	}, [shouldSearch, pagination.fetch, sortByProperty, order, status]);

	useEffect(() => {
		if (!pendingLicensesLoading && (isPageEmpty || isPageFull)) {
			setLoading(false);
		} else {
			setLoading(true);
		}
	}, [pendingLicenses, status, isPageEmpty, isPageFull]);

	const handleRequestSort = (property) => {
		const isDesc = sortByProperty === property && order === 'desc';
		setOrder(isDesc ? 'asc' : 'desc');
		setOrderDescending(!isDesc);
		setSortByProperty(property);
		setShouldSort(true);
	};

	const handleClearFilters = () => {
		search.events.onClear();
		pagination.resetPagination();
	};

	const clearFilters = {
		clear: pagination.pageNumber !== 1 || pagination.pageSize !== 10,
		btnText: 'ui.button.inline.clearfilters',
		action: handleClearFilters,
	};

	const tableHeaders =
		!loading && isObject(pendingLicensesData) && isEmptyArray(pendingLicensesData.results) ?
			[]
		:	[
				{ name: 'id', content: t('views.licenses.table.requestId') },
				{ name: 'submitDate', content: t('ui.label.dateSubmitted'), hasSorting: true },
				{ name: 'name', content: t('views.licenses.table.user') },
				{ name: 'dateOfBirth', content: t('views.licenses.table.dateOfBirth') },
				{ name: 'countryCode', content: t('views.licenses.table.licenseCountry') },
				{ name: 'licenseNumber', content: t('views.licenses.table.licenseNumber') },
				{ name: 'expiryDate', content: t('views.licenses.table.expiryDate') },
				{ name: '', content: '' },
			];

	const handlePathname = (path, label) => (
		<Link
			className={classes.link}
			color='inherit'
			component={RouterLink}
			to={path}
			state={{ from: location.pathname }}
			variant='h6'
		>
			{label}
		</Link>
	);

	const loadingBody =
		loading || isNull(pendingLicensesData) ?
			Array(3)
				.fill(Array(8).fill())
				.map((arr) => arr.map(() => ({ loading: true })))
		:	null;

	const tableBody =
		!loading &&
		isFullArray(pendingLicensesData?.results) &&
		pendingLicensesData.results.map((license) => [
			{
				content: (
					<Box pl={1}>
						{handlePathname(`/licenses/request/${license.userReference.id}`, license.id)}{' '}
					</Box>
				),
			},
			{ content: datetimeWithoutSecondsFormatter.format(new Date(license.submitDate)) },
			{
				content: (
					<div className={classes.nameCell}>
						{license.imagesReference && license.imagesReference[0] ?
							<Avatar alt='User' className={classes.avatar} src={license.imagesReference[0]} />
						:	<Avatar alt='User' className={classes.avatar}>
								{getInitials(license.userReference.name)}
							</Avatar>
						}
						<div>
							<Link
								className={classes.link}
								color='inherit'
								component={RouterLink}
								to={`/user-management/users/${license.userReference.id}/summary`}
								state={{ from: location.pathname }}
								variant='h6'
							>
								{license.userReference.name}
							</Link>
						</div>
					</div>
				),
			},
			{ content: localizeDateTime(license.dateOfBirth) },
			{ content: getName(license.countryCode, i18n.resolvedLanguage, { select: 'alias' }) },
			{ content: license.licenseNumber },
			{ content: localizeDateTime(license.expiryDate) },
			{
				content: (
					<Label type={license.isOnHold || license.status === 'rejected' ? 'error' : 'default'}>
						{license.isOnHold ?
							t('ui.status.license.onHold')
						:	t(`ui.status.license.${license.status}`)}
					</Label>
				),
			},
		]);

	const emptyBody =
		isPageEmpty ?
			[
				[
					{
						content: (
							<Paper className={classes.tipCard}>
								<InfoOutlinedIcon className={classes.icon} fontSize='small' />
								<Typography className={classes.tip}>
									{t('views.licenses.table.emptyTable')}
								</Typography>
							</Paper>
						),
					},
				],
			]
		:	null;

	return (
		<>
			<SearchBar
				clearFilters={clearFilters}
				placeholder={t('views.licenses.placeholder.search')}
				searchEvents={search.events}
				searchValue={search.value}
			/>
			<Box pb={3}>
				<Table
					body={loadingBody || emptyBody || tableBody}
					cellStyle={classes.cellStyle}
					data={isObject(pendingLicensesData) ? pendingLicensesData.results : []}
					handlePageChange={pagination.pageNumberChange}
					handleSorting={handleRequestSort}
					header={tableHeaders}
					isNotPaginate={loading || isPageEmpty || !isPageFull}
					loading={pendingLicensesLoading}
					order={order}
					orderBy={sortByProperty}
					page={pagination.pageNumber}
					rowsPerPage={pagination.pageSize}
					setRowsPerPage={pagination.pageSizeChange}
					title={t(`views.licenses.table.title.${tab}`)}
					total={pendingLicensesData ? pendingLicensesData.total : 0}
				/>
			</Box>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		pendingLicenses: state.paged.licenses,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchLicenses: (page, filters) => dispatch(actions.fetchLicenses(page, filters)),
		onUpdatePagedState: (identifier, data) => dispatch(actions.updatePagedState(identifier, data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LicensesList);

import path from 'path';

import { pagePathSegments } from '~constants';

const callbackRedirectExcludePaths: string[] = [
	path.join(pagePathSegments.Auth, pagePathSegments.Callback),
	path.join(pagePathSegments.Auth, pagePathSegments.Login),
	path.join(pagePathSegments.Auth, pagePathSegments.Logout),
];

export default callbackRedirectExcludePaths
import path from 'path';

import { AppBar, Box, Toolbar, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import TopologyLogo from '~assets/images/topology_logo.svg?react';
import { pagePaths } from '~constants';

/**
 * A more generic header. E.g. used for scaffolding for example when a
 * page is loading
 */
const GenericHeader = () => {
	const { t } = useTranslation();
	const theme = useTheme();

	return (
		<AppBar elevation={2} position='static' sx={{ bgcolor: theme.palette.common.white }}>
			<Toolbar>
				<a href={`/${pagePaths.AuthLogin}`}>
					<TopologyLogo width='140px' />
				</a>

				<Box flexGrow={1} />
			</Toolbar>
		</AppBar>
	);
};

export default GenericHeader;

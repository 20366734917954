import CloseIcon from '@mui/icons-material/Close';
import { Box, Card, CardContent, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import {
	SearchAutocomplete,
} from '../../../../components';
import { isObject, handleHubReference } from '../../../../shared/utility';
import * as actions from '../../../../store/actions';
import { useStyles } from '../style';

interface DockingCardProps {
	clearSelection?(...args: unknown[]): unknown;
	onFetchDockingLocations?(...args: unknown[]): unknown;
	open?: boolean;
	docking?: object;
	setDocking?(...args: unknown[]): unknown;
	fetchDockingLocations?: any;
}

const DockingCard = (props: DockingCardProps) => {
	const {
		open,

		clearSelection,
		docking,
		setDocking,
		onFetchDockingLocations,
		fetchDockingLocations,
	} = props;
	const { t } = useTranslation();

	const classes = useStyles();

	const handleSelectOption = () => {
		if (open) {
			return (
				<SearchAutocomplete
					dataList={fetchDockingLocations}
					listType={'hubs'}
					onFetchData={onFetchDockingLocations}
					placeholder={t('ui.label.search.items')}
					popperSize='large'
					setSelected={setDocking}
				/>
			);
		}
	};

	return (
		<Box pt={3}>
			<Typography variant='h6'>
				{t('views.actions.linkDockingLocation.drawer.label.dockingLocation')}
			</Typography>
			<Box mt={1.5}>
				{isObject(docking) ?
					<Card className={classes.card}>
						<CardContent>
							<Typography variant='h5'>{docking.name}</Typography>
							<Typography variant='body2'>{handleHubReference(docking)}</Typography>
							<Typography variant='body2'>{docking?.address?.country}</Typography>
						</CardContent>
						<IconButton className={classes.closeButton} onClick={clearSelection}>
							<CloseIcon />
						</IconButton>
					</Card>
				:	handleSelectOption()}
			</Box>
		</Box>
	);
};

const mapStateToProps = (state) => {
	return {
		fetchDockingLocations: state.paged.fetchDockingLocations,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchDockingLocations: (page, filters, concat) =>
			dispatch(actions.fetchDockingLocations(page, filters, concat)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(DockingCard);

import { useEffect, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { TextField, CircularProgress } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';

import { useDebounce } from '~hooks';

import { useStyles } from './style';
import {
	isArray,
	isFullArray,
	isFullString,
	isEmptyString,
	isEmptyArray,
	handleHubReference,
} from '../../../shared/utility';

interface SearchProps {
	fetchData?: any;
	data?: any;
	filters?: object;
	renderOption?: any;
	searchSelect?(...args: unknown[]): unknown;
	restState?(...args: unknown[]): unknown;
	noOptions?: string;
}

const Search = (props: SearchProps) => {
	const { fetchData, data, filters, renderOption, searchSelect, restState, noOptions } = props;
	const { t } = useTranslation();
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [searchValue, setSearchValue] = useState('');

	const debouncedSearchValue = useDebounce(searchValue, 500);

	useEffect(() => {
		if (
			!loading &&
			!data?.loading &&
			isFullString(debouncedSearchValue) &&
			debouncedSearchValue === searchValue
		) {
			fetchData({
				...filters,
				...(debouncedSearchValue && { searchTerm: encodeURIComponent(debouncedSearchValue) }),
			});
			setLoading(true);
		}
	}, [debouncedSearchValue]);

	useEffect(() => {
		if (isFullArray(options) || (isEmptyArray(options) && isEmptyArray(data.data))) {
			setOpen(true);
			setLoading(false);
		}
	}, [options]);
	useEffect(() => {
		if (isArray(data?.data)) {
			setOptions(
				data.data.map((item) => {
					return {
						...item,
						key: item.id,
						...(item?.item ?
							{
								optionsName: `${item.item.name} - ${item.name}`,
								address: handleHubReference(item.hub),
							}
						:	{ optionsName: item.name, address: item.email }),
					};
				}),
			);
		}
	}, [data.loading]);

	useEffect(() => {
		if (isEmptyString(searchValue)) {
			setOptions(() => []);
			setLoading(() => false);
			setOpen(() => false);
			restState();
		}
	}, [isEmptyString(searchValue)]);

	const handleValue = (event) => setSearchValue(event.target.value);

	const handleGetOptionLabel = (option) => option.optionsName;

	const optionEqualToValue = (option, value) => option.key === value.key;

	const filterOptions = (options, state) => {
		const inputValue = state.inputValue.toLowerCase();
		return options.filter((option) =>
			option?.email ?
				option.name?.toLowerCase().includes(inputValue) ||
				option.email.toLowerCase().includes(inputValue)
			:	option.name.toLowerCase().includes(inputValue) ||
				option.item.name.toLowerCase().includes(inputValue),
		);
	};

	const handleBlur = () => {
		setOpen(false);
		setOptions([]);
		setSearchValue('');
	};

	const onChange = (event, values) => {
		searchSelect(values);
		setOpen(false);
	};

	return (
		<Autocomplete
			className={classes.searchIcon}
			filterOptions={filterOptions}
			getOptionLabel={handleGetOptionLabel}
			isOptionEqualToValue={optionEqualToValue}
			loading={loading}
			noOptionsText={<span>{noOptions}</span>}
			onChange={onChange}
			open={open}
			options={options}
			popupIcon={<SearchIcon />}
			renderInput={(params) => (
				<TextField
					fullWidth
					onChange={handleValue}
					{...params}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<>
								{loading ?
									<CircularProgress color='inherit' size={20} />
								:	null}
							</>
						),
					}}
					onBlur={handleBlur}
					placeholder={t('ui.placeholders.search.generic')}
				/>
			)}
			renderOption={renderOption}
		/>
	);
};

export default Search;

import { useState, useEffect } from 'react';

import { useAtomValue } from 'jotai';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { userInfoAtom } from '~atoms';

import EditCard from './EditCard';
import { Wizard } from '../../../../components';
import { isObject, isFullArray } from '../../../../shared/utility';
import * as actions from '../../../../store/actions';

interface EditManagementProps {
	onResetState?(...args: unknown[]): unknown;
	onSharingsUpdate?(...args: unknown[]): unknown;
	sharingsUpdate?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const EditManagement = (props: EditManagementProps) => {
	const { onSharingsUpdate, sharingsUpdate, onResetState } = props;
	const { t } = useTranslation();
	const userInfo = useAtomValue(userInfoAtom);

	const types = { TERMS: 'terms', PRICING: 'pricing', POLICY: 'policy' };

	const pricing = { BOTH: 'both', BUSINESS: 'business', PERSONAL: 'personal' };

	const [startedFinishWizard, setStartedFinishWizard] = useState(false);

	const [selectedPolicy, setSelectedPolicy] = useState('');

	const [businessPricing, setBusinessPricing] = useState('');

	const [pricingType, setPricingType] = useState('both');

	const { data: sharingsUpdateData, error: sharingsUpdateError } = sharingsUpdate;

	const { enqueueSnackbar } = useSnackbar();

	const navigate = useNavigate();

	const location = useLocation();

	const [organisationIdFilter] = useState({
		name: 'organisationId',
		value:
			location.state?.providerId ?
				location.state.providerId
			:	userInfo.organisation.id,
	});

	const isValidPricing =
		pricingType === pricing.BOTH ?
			isObject(businessPricing) && isObject(selectedPolicy)
		:	isObject(businessPricing) || isObject(selectedPolicy);

	const isValid =
		location.state.editType === types.PRICING ? isValidPricing : isObject(selectedPolicy);

	const bodyData = location.state?.selectedItems.map((item) => ({
		itemId: item.id,
		type: item.type,
		...(item.type === 'internal' && { userGroupId: item.userGroup?.id }),
		...(item.type === 'external' && { partnershipId: item.consumerId }),
		...(location.state.editType === types.TERMS && { termsId: selectedPolicy.id }),
		...(location.state.editType === types.PRICING &&
			(pricingType === pricing.BOTH || pricingType === pricing.PERSONAL) && {
				pricingModelId: selectedPolicy.id,
			}),
		...(location.state.editType === types.PRICING &&
			(pricingType === pricing.BOTH || pricingType === pricing.BUSINESS) && {
				businessPricingModelId: businessPricing.id,
			}),
		...(location.state.editType === types.POLICY && { policyId: selectedPolicy.id }),
	}));

	useEffect(() => {
		if (startedFinishWizard) {
			onSharingsUpdate(organisationIdFilter.value, { items: bodyData });
		}
	}, [startedFinishWizard]);

	useEffect(() => {
		if (isFullArray(sharingsUpdateData?.accepted) && !isFullArray(sharingsUpdateData?.rejected)) {
			enqueueSnackbar(t('views.edit.management.success.message'), { variant: 'success' });
			onResetState('sharingsUpdate');
			navigate('/item-management/management');
		} else if (
			isFullArray(sharingsUpdateData?.rejected) &&
			!isFullArray(sharingsUpdateData?.accepted)
		) {
			enqueueSnackbar(t('views.edit.management.rejected.message'), { variant: 'error' });
			onResetState('sharingsUpdate');
			setStartedFinishWizard(false);
		} else if (
			isFullArray(sharingsUpdateData?.accepted) &&
			isFullArray(sharingsUpdateData?.rejected)
		) {
			enqueueSnackbar(t('views.edit.management.warning.message'), { variant: 'warning' });
		} else if (sharingsUpdateError) {
			setStartedFinishWizard(false);
			onResetState('sharingsUpdate');
			enqueueSnackbar(sharingsUpdateError.message, { variant: 'error' });
		}
	}, [sharingsUpdateData, sharingsUpdateError]);

	const callback = (data) => {
		const { finishedWizard } = data;

		if (finishedWizard && !startedFinishWizard) {
			setStartedFinishWizard(true);
		}
	};

	const addSteps = [
		{
			name: t('views.addItem.steps.bookingType'),
			content: EditCard,
			valid: isValid,
			props: {
				editType: location.state.editType,
				selectedItems: location.state.selectedItems,
				selectedPolicy,
				organisationIdFilter,
				setSelectedPolicy,
				pricingType,
				setPricingType,
				businessPricing,
				setBusinessPricing,
				types,
				pricing,
			},
		},
	];

	return (
		<Wizard
			callback={callback}
			loading={startedFinishWizard}
			stepperType={'progress'}
			steps={addSteps}
			title={t('views.addItem.page.title')}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		sharingsUpdate: state.details.sharingsUpdate,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onSharingsUpdate: (providerId, bodyData) =>
			dispatch(actions.sharingsUpdate(providerId, bodyData)),
		onResetState: (state) => dispatch(actions.resetState(state)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditManagement);

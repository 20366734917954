import { Edit as EditIcon } from '@mui/icons-material';
import { Link } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { InfoCard, InfoCardRowDef } from '~components';

interface ContactPerson {
	firstName: string;
	lastName: string;
	phoneNumber: string;
	emailAddress: string;
}

interface ContactInfoCardProps {
	data?: Partial<ContactPerson>;
	onEdit?: () => void;
	enableEdit?: boolean;
}

/**
 * A card to show organisation basic information
 * Notice: the imperative handle should be temporary, just to refresh the data
 * within this component from outside
 * @returns
 */
const ContactInfoCard = ({ data, enableEdit = true, ...props }: ContactInfoCardProps) => {
	const { t } = useTranslation();

	const rows: InfoCardRowDef[] | undefined =
		data != null ?
			[
				{
					headerName: t('ui.label.name'),
					value: data,
					valueFormatter: (value) => {
						if (!value.firstName && !value.lastName) {
							return '-';
						}

						const names = [value.firstName, value.lastName].filter((el) => el != null);
						return names.join(' ');
					},
				},
				{
					headerName: t('ui.label.phoneNumber'),
					value: data.phoneNumber,
					renderCell: (value: string) => (value ? <Link href={`tel:${value}`}>{value}</Link> : '-'),
				},
				{
					headerName: t('ui.label.email'),
					value: data.emailAddress,
					renderCell: (value: string) =>
						value ? <Link href={`mailto:${value}`}>{value}</Link> : '-',
				},
			]
		:	[];

	return (
		<InfoCard
			title={t('views.organisationDetail.summary.cardHeaders.contact')}
			rows={rows ?? []}
			version='legacy'
			noResultsLabel={t('views.organisationDetail.summary.contact.missing.description')}
			actions={
				enableEdit ?
					[
						{
							label: t('ui.edit'),
							icon: <EditIcon />,
							onClick: () => props.onEdit?.(),
						},
					]
				:	undefined
			}
		/>
	);
};

export default ContactInfoCard;

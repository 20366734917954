import { useEffect } from 'react';

import { Tabs, Tab, Divider, colors } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Navigate, useNavigate, useLocation, useParams } from 'react-router-dom';

import Header from './Header';
import Summary from './Summary';
import { Page } from '../../../../components';
import { isObject } from '../../../../shared/utility';
import * as actions from '../../../../store/actions/index';
import Items from '../../../ItemManagement/Items';

const PREFIX = 'PartnerDetail';

const classes = {
	root: `${PREFIX}-root`,
	tabs: `${PREFIX}-tabs`,
	divider: `${PREFIX}-divider`,
	content: `${PREFIX}-content`,
};

const StyledPage = styled(Page)(({ theme }) => ({
	[`&.${classes.root}`]: {
		padding: theme.spacing(3),
	},

	[`& .${classes.tabs}`]: {
		marginTop: theme.spacing(3),
	},

	[`& .${classes.divider}`]: {
		backgroundColor: colors.grey[300],
	},

	[`& .${classes.content}`]: {
		marginTop: theme.spacing(3),
	},
}));

interface PartnershipDetailProps {
	partner?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onPartner?(...args: unknown[]): unknown;
}

const PartnershipDetail = (props: PartnershipDetailProps) => {
	const { onPartner, partner } = props;
	const location = useLocation();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { id, tab } = useParams();

	const { data: partnerData, loading: partnerLoading, error: partnerError } = partner;
	const partnerReady = isObject(partnerData) && !partnerLoading && !partnerError;

	const handleTabsChange = (event, value) => {
		navigate(`../${value}`, { relative: 'path', state: { ...location.state } });
	};

	useEffect(() => {
		if (!partnerLoading) {
			onPartner(id);
		}
	}, [id]);

	const tabs = [
		{
			value: 'summary',
			label: t('ui.summary'),
		},
		...(isObject(partnerData) && !partnerLoading && partnerData.status === 'pending' ?
			[]
		:	[
				{
					value: 'itemsSharedBy',
					label: `${t('views.tableResults.items.sharedBy')} ${isObject(partnerData) && partnerData.partnerOrganisation.name}`,
				},
				{
					value: 'itemsSharedWith',
					label: `${t('views.tableResults.items.sharedWith')} ${isObject(partnerData) && partnerData.partnerOrganisation.name}`,
				},
			]),
	];

	if (!tab) {
		return <Navigate to={'/partnerships/partners'} />;
	}

	if (!tabs.find((t) => t.value === tab)) {
		return <Navigate to='/errors/error-404' />;
	}

	return (
		<StyledPage className={classes.root} title={t('views.partnerDetail.page.title')}>
			<Header loading={!partnerReady} partner={partnerData} />
			<Tabs
				className={classes.tabs}
				indicatorColor='primary'
				onChange={handleTabsChange}
				scrollButtons='auto'
				value={tab}
				variant='scrollable'
			>
				{tabs.map((tab) => (
					<Tab key={tab.value} label={tab.label} value={tab.value} />
				))}
			</Tabs>
			<Divider className={classes.divider} />
			<div className={classes.content}>
				{tab === 'summary' && <Summary loading={partnerLoading} partner={partnerData} />}
				{tab === 'itemsSharedBy' && (
					<Items
						itemUse='partnershipSharedBy'
						itemsSharedBy={true}
						partnerId={parseInt(id, 10)}
						type='consumer'
					/>
				)}
				{tab === 'itemsSharedWith' && (
					<Items
						itemSharedWithMe={true}
						itemUse='partnershipSharedWithy'
						partnerId={parseInt(id, 10)}
						type='supplier'
					/>
				)}
			</div>
		</StyledPage>
	);
};

const mapStateToProps = (state) => {
	return {
		partner: state.details.partner,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onPartner: (id) => dispatch(actions.partner(id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnershipDetail);

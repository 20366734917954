import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { IconButton, Card, CardContent, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { Tooltip } from '~components';

import ReservationsIcon from '../../../../assets/icons/reservations-24-px@2x.webp';
import { LoadingBar } from '../../../../components';
import { useStyles } from '../../style';

interface ReservationsProps {
	monthSwitcher?: object;
	dashboardBookingsOverview?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const Reservations = (props: ReservationsProps) => {
	const classes = useStyles();
	const { monthSwitcher, dashboardBookingsOverview } = props;
	const { t } = useTranslation();

	const { data: dashboardBookingsOverviewData, loading: dashboardBookingsOverviewLoading } =
		dashboardBookingsOverview;

	const style = { width: 10 };

	const handleReservations = () =>
		dashboardBookingsOverviewData?.bookings ? dashboardBookingsOverviewData?.bookings : 0;

	const handleUniqueUsers = () =>
		dashboardBookingsOverviewData?.uniqueUsers ? dashboardBookingsOverviewData?.uniqueUsers : 0;

	return (
		<Card className={classes.card}>
			<CardContent
				sx={{
					padding: '24px 0px 24px 16px',
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'space-between',
				}}
			>
				<Box display='flex' width='175px'>
					<Box alignSelf='flex-start' className={classes.tooltipContainer}>
						<Tooltip title={t('views.dashboard.reservation.tooltip')} placement='bottom'>
							<img alt={'calendar-icon'} src={ReservationsIcon} width='25' height='25' />
						</Tooltip>
					</Box>
					<Box display='flex' flexDirection='column'>
						<Box display='flex'>
							<Typography
								sx={{ marginRight: 1 }}
							>{`${t('nav.bookings.reservations')}:`}</Typography>
							<Typography>
								{dashboardBookingsOverviewLoading ?
									<LoadingBar style={style} />
								:	handleReservations()}
							</Typography>
						</Box>
						<Box display='flex' sx={{ alignItems: 'center', whiteSpace: 'nowrap' }}>
							<Typography
								sx={{ marginRight: 1 }}
							>{`${t('views.dashboard.label.uniqueUsers')}:`}</Typography>
							<Typography>
								{dashboardBookingsOverviewLoading ?
									<LoadingBar />
								:	handleUniqueUsers()}
							</Typography>
						</Box>
					</Box>
				</Box>
				<Box alignItems='center' display='flex' ml={3}>
					<IconButton
						color='primary'
						disableRipple={true}
						onClick={monthSwitcher.prevMonth}
						size='small'
					>
						<NavigateBeforeIcon />
					</IconButton>
					<Typography sx={{ whiteSpace: 'nowrap' }}>
						{monthSwitcher.currentMonth.toLocaleString('default', {
							month: 'long',
							year: 'numeric',
						})}
					</Typography>
					<IconButton color='primary' onClick={monthSwitcher.nextMonth} size='small'>
						<NavigateNextIcon />
					</IconButton>
				</Box>
			</CardContent>
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		dashboardBookingsOverview: state.details.dashboardBookingsOverview,
	};
};

export default connect(mapStateToProps, null)(Reservations);

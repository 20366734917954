import { useMemo, useState } from 'react';

import path from 'path';

import {
	DeleteOutlined as DeleteOutlinedIcon,
	Edit as EditIcon,
	Link as LinkIcon,
	LinkOff as LinkOffIcon,
} from '@mui/icons-material';
import { Box, Button, Chip, MenuItem, Link as MuiLink, Portal, Stack } from '@mui/material';
import {
	GridColDef,
	GridFilterModel,
	GridPaginationModel,
	GridRenderCellParams,
	GridSortModel,
} from '@mui/x-data-grid';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Link as ReactRouterLink } from 'react-router-dom';
import useSWR from 'swr';

import { userInfoAtom } from '~atoms';
import { DataGrid, DataGridToolbar, GridActionsCellItem, Select } from '~components';
import { pagePathSegments } from '~constants';
import { useAuthorize } from '~features/authentication';
import { datetimeWithoutSecondsFormatter } from '~utils/dateUtils';

import NfcTag from '../../interfaces/nfcTag';
import NfcTagsService from '../../services/nfcTagsService';

const toolbarIdString = 'topology-nfc-tags-toolbar';

const service = new NfcTagsService();

interface NfcsDataGridProps {
	enableEdit?: boolean;
	enableLinking?: boolean;
	enableDelete?: boolean;
	enableCreate?: boolean;
	onEdit?: (id: string) => void;
	onCreate?: () => void;
	onLink?: (id: string) => void;
	onUnlink?: (id: string) => void;
	onDelete?: (id: string) => void;
}

const NfcsDataGrid = ({
	enableEdit = false,
	enableLinking = true,
	enableCreate = false,
	enableDelete = false,
	onCreate,
	onEdit,
	onLink,
	onUnlink,
	onDelete,
}: NfcsDataGridProps) => {
	const { t } = useTranslation();
	const { isSuperAdmin } = useAuthorize();
	const userInfo = useAtomValue(userInfoAtom);

	const [fetchParameters, setFetchParameters] = useState<object | null>({
		organisationId: !isSuperAdmin() ? userInfo.organisation.id : null,
	});

	const columns = useMemo<GridColDef<NfcTag>[]>(
		() => [
			{
				field: 'tagNumber',
				headerName: t('cardNumber'),
				flex: 1,
			},
			{
				field: 'id',
				headerName: t('rfidUid'),
				valueFormatter: (value: string) => value.toUpperCase(),
				flex: 1,
			},
			{
				field: 'user',
				headerName: t('linkedWith'),
				renderCell: (params: GridRenderCellParams<NfcTag>) =>
					!params.value ? '-' : (
						<MuiLink
							component={ReactRouterLink}
							to={`/${path.join(pagePathSegments.UserManagement, pagePathSegments.Users, params.value.id, pagePathSegments.Summary)}`}
						>
							{params.value.label}
						</MuiLink>
					),
				flex: 1,
			},
			{
				field: 'userGroup',
				headerName: t('userGroup'),
				renderCell: (params: GridRenderCellParams<NfcTag>) =>
					!params.value ? '-' : (
						<MuiLink
							component={ReactRouterLink}
							to={`/${path.join(pagePathSegments.UserManagement, pagePathSegments.UserGroups, params.value.id, pagePathSegments.Summary)}`}
						>
							{params.value.label}
						</MuiLink>
					),
				flex: 1,
			},
			{
				field: 'organisation',
				headerName: t('organisation'),
				renderCell: (params: GridRenderCellParams<NfcTag>) =>
					!params.value ? '-' : (
						<MuiLink
							component={ReactRouterLink}
							to={`/${path.join(pagePathSegments.Organisations, params.value.id, pagePathSegments.Summary)}`}
						>
							{params.value.label}
						</MuiLink>
					),
				flex: 1,
			},
			{
				field: 'dateLinked',
				headerName: t('linkDate'),
				valueFormatter: (value: Date) => datetimeWithoutSecondsFormatter.format(value),
				flex: 1,
			},
			{
				field: 'tagType',
				headerName: t('type'),
				flex: 1,
			},
			{
				field: 'status',
				headerName: t('status'),
				renderCell: (params: GridRenderCellParams<NfcTag>) => (
					<Chip label={params.value} color='success' sx={{ fontSize: '12px' }} />
				),
			},
			{
				field: 'actions',
				type: 'actions',
				width: 150,
				resizable: false,
				hideable: false,
				align: 'right',
				getActions: (params) => [
					<GridActionsCellItem
						key={`${params.id}-link`}
						{...(!params.row.user ?
							{
								icon: <LinkIcon />,
								label: t('link'),
								onClick: () => onLink?.(params.id.toString()),
							}
						:	{
								icon: <LinkOffIcon />,
								label: t('unlink'),
								onClick: () => onUnlink?.(params.id.toString()),
							})}
					/>,
					...(enableEdit ?
						[
							<GridActionsCellItem
								key={`${params.id}-edit`}
								icon={<EditIcon />}
								label={t('edit')}
								showInMenu
								onClick={() => onEdit?.(params.id.toString())}
							/>,
						]
					:	[]),
					<GridActionsCellItem
						key={`${params.id}-delete`}
						icon={<DeleteOutlinedIcon color='error' />}
						label={t('delete')}
						showInMenu
						disabled={!isSuperAdmin() && params.row.tagType !== 'external'}
						onClick={() => onDelete?.(params.id.toString())}
					/>,
				],
			},
		],
		[]
	);

	const { data, isLoading, isValidating, error } = useSWR(
		fetchParameters ? [service.basePath, fetchParameters] : null,
		([_, args]) => {
			if (isLoading || isValidating) {
				service.abortCurrentRequest('Parameter change');
			}

			return service.getNfcTags(args);
		},
		{
			onSuccess: (res) => res?.total != null && setTotalCount(res.total),
			keepPreviousData: true,
		}
	);

	const [totalCount, setTotalCount] = useState<number>(data?.total || 0);

	const handlePaginationModelChange = (model: GridPaginationModel) => {
		setFetchParameters((prev) => ({
			...prev,
			page: model.page + 1,
			pageSize: model.pageSize,
		}));
	};

	const handleSortModelChange = (model: GridSortModel) => {
		setFetchParameters((prev) => ({
			...prev,
			sortBy: model?.[0]?.field,
			descending: model?.[0].sort ? model[0].sort === 'desc' : undefined,
		}));
	};

	const handleFilterModelChange = (model: GridFilterModel) => {
		const organisation =
			model.items?.[0]?.field === 'organisation' ? model.items[0].value : undefined;

		setFetchParameters((prev) => ({
			...prev,
			searchQuery: model.quickFilterValues?.[0],
			organisationId: organisation?.id,
		}));
	};

	return (
		<Stack
			spacing={2}
			sx={{
				height: 1,
			}}
		>
			<Box id={toolbarIdString} />
			<DataGrid
				snapshotKey='nfcTags'
				columns={columns}
				error={error}
				loading={isLoading || isValidating}
				rows={data?.results ?? []}
				rowCount={totalCount}
				onPaginationModelChange={handlePaginationModelChange}
				onSortModelChange={handleSortModelChange}
				onFilterModelChange={handleFilterModelChange}
				slots={{
					toolbar: Toolbar,
				}}
				slotProps={{
					toolbar: {
						onCreate: onCreate,
						status: fetchParameters.status,
						onStatusChange: (val) => setFetchParameters((prev) => ({ ...prev, status: val })),
						linkStatus: fetchParameters.linkStatus,
						onLinkStatusChange: (val) =>
							setFetchParameters((prev) => ({ ...prev, linkStatus: val })),
					},
				}}
				initialState={{
					sorting: {
						sortModel: [
							{
								field: 'id',
								sort: 'desc',
							},
						],
					},
				}}
				sx={{ flexGrow: 1, flexBasis: 200 }}
			/>
		</Stack>
	);
};

const Toolbar = (props) => {
	const { t } = useTranslation();
	const { isSuperAdmin } = useAuthorize();

	return (
		<Portal container={() => document.getElementById(toolbarIdString)}>
			<DataGridToolbar
				filters={[
					...(isSuperAdmin() ?
						[
							<Select
								key='status'
								displayEmpty
								size='small'
								value={props.status}
								onChange={(e) => props.onStatusChange?.(e.target.value)}
							>
								{['active', 'disabled'].map((el) => (
									<MenuItem key={el} value={el}>
										{el}
									</MenuItem>
								))}
							</Select>,
						]
					:	[]),
					<Select
						key='linkStatus'
						displayEmpty
						size='small'
						value={props.linkStatus}
						onChange={(e) => props.onLinkStatusChange?.(e.target.value)}
					>
						{['linked', 'unlinked'].map((el) => (
							<MenuItem key={el} value={el}>
								{el}
							</MenuItem>
						))}
					</Select>,
				]}
			>
				<Button variant='contained' onClick={() => props.onCreate?.()}>
					{t('addResource', { resource: t('nfcTag') })}
				</Button>
			</DataGridToolbar>
		</Portal>
	);
};

export default NfcsDataGrid;

import {
	Box,
	Card,
	CardHeader,
	CardContent,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
} from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';
import { EmptyState, OpenEmail, OpenPhoneNumber } from '../../../../../../components';
import { isFullString, isObject } from '../../../../../../shared/utility';

interface ContactInfoProps {
	className?: string;
	partner: object;
}

const ContactInfo = (props: ContactInfoProps) => {
	const { partner, className } = props;
	const { t } = useTranslation();

	const classes = useStyles();

	const contactPerson =
		isObject(partner.partnerOrganisation) && isObject(partner.partnerOrganisation.contactPerson) ?
			partner.partnerOrganisation.contactPerson
		:	null;

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader title={t('views.partnerDetail.summary.cardHeaders.contact')} />
			<Divider />
			<CardContent className={classes.content}>
				{contactPerson ?
					<Table>
						<TableBody>
							<TableRow>
								<TableCell>{t('ui.label.name')}</TableCell>
								<TableCell>
									{(
										isObject(contactPerson) &&
										isFullString(contactPerson.firstName) &&
										isFullString(contactPerson.lastName)
									) ?
										`${contactPerson.firstName} ${contactPerson.lastName}`
									:	'-'}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>{t('ui.label.email')}</TableCell>
								<TableCell>
									{isObject(contactPerson) && isFullString(contactPerson.emailAddress) ?
										<OpenEmail aemail={contactPerson.emailAddress} />
									:	'-'}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>{t('ui.label.phoneNumber')}</TableCell>
								<TableCell>
									{isObject(contactPerson) && isFullString(contactPerson.phoneNumber) ?
										<OpenPhoneNumber number={contactPerson.phoneNumber} />
									:	'-'}
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				:	<Box padding={2}>
						<EmptyState
							image={'booking'}
							subTitle={t('views.partnerDetail.summary.contact.missing.subtitle')}
							title={t('views.partnerDetail.summary.contact.missing.title')}
						/>
					</Box>
				}
			</CardContent>
		</Card>
	);
};

export default ContactInfo;

import { useState, useEffect } from 'react';

import path from 'path';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { pagePathSegments } from '~constants';

import ModelName from './ModelName';
import PerDay from './PerDay';
import PerHour from './PerHour';
import PerMinute from './PerMinute';
import PerWeek from './PerWeek';
import PricingUnits from './PricingUnits';
import Summary from './Summary';
import { Wizard } from '../../../../components';
import { modifyDate } from '../../../../shared/datetime';
import { useError } from '../../../../shared/hooks';
import {
	isObject,
	isFullArray,
	isEmptyArray,
	isNumber,
	isBoolean,
	isFullString,
	isNull,
	isEmptyObject,
} from '../../../../shared/utility';
import * as actions from '../../../../store/actions';
import palette from '../../../../theme/palette';

const eventColors = {
	fixed: palette.primary.main,
	free: palette.info.light,
};

const minuteMilliseconds = 60 * 1000; // seconds * milliseconds
const hourMilliseconds = minuteMilliseconds * 60;
const dayMilliseconds = hourMilliseconds * 24;
const weekMilliseconds = dayMilliseconds * 7;

interface AddPricingProps {
	onAddPricingModels?(...args: unknown[]): unknown;
	onUpdatePricingModel?(...args: unknown[]): unknown;
	editing?: boolean;
	activeStartStep?: number;
	pricingModelId?: number;
	name?: string;
	organisation?: object;
	category?: object;
	pricingType?: string;
	vatPercentage?: number;
	fixedPricingModel?: unknown[];
	pricingPerMinuteModel?: unknown[];
	pricingPerHourModel?: unknown[];
	pricingPerDayModel?: unknown[];
	pricingPerWeekModel?: unknown[];
	updatedPricingModel?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	addPricingModels?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	pricingUsage?: object | boolean;
	editRedirect?: boolean;
}

const AddPricing = (props: AddPricingProps) => {
	const {
		onAddPricingModels,
		onUpdatePricingModel,
		editing,
		activeStartStep,
		pricingModelId,
		name,
		organisation,
		category,
		vatPercentage,
		pricingType,
		fixedPricingModel: currentFixedPricingModel,
		pricingPerMinuteModel: currentPricingPerMinuteModel,
		pricingPerHourModel: currentPricingPerHourModel,
		pricingPerDayModel: currentPricingPerDayModel,
		pricingPerWeekModel: currentPricingPerWeekModel,
		updatedPricingModel,
		addPricingModels,
		pricingUsage,
		editRedirect,
	} = props;
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [selectedName, setSelectedName] = useState(editing ? name : '');

	const [selectedOrganisation, setSelectedOrganisation] = useState(editing ? organisation : null);
	const [selectedCategory, setSelectedCategory] = useState(editing ? category.id : null);
	const [selectedPricingType, setSelectedPricingType] = useState(
		editing && isFullString(pricingType) ? pricingType : null,
	);

	const [perMinuteValue, setPerMinuteValue] = useState(
		editing && !isEmptyArray(currentPricingPerMinuteModel) ?
			{ value: true, name: 'minute', label: t('ui.label.perMinute') }
		:	null,
	);
	const [pricingPerMinuteModel, setPricingPerMinuteModel] = useState(
		editing ? currentPricingPerMinuteModel : [],
	);

	const [perHourValue, setPerHourValue] = useState(
		editing && !isEmptyArray(currentPricingPerHourModel) ?
			{ value: true, name: 'hour', label: t('ui.label.perHour') }
		:	null,
	);
	const [pricingPerHourModel, setPricingPerHourModel] = useState(
		editing ? currentPricingPerHourModel : [],
	);

	const [perDayValue, setPerDayValue] = useState(
		editing && !isEmptyArray(currentPricingPerDayModel) ?
			{ value: true, name: 'day', label: t('ui.label.perDay') }
		:	null,
	);
	const [pricingPerDayModel, setPricingPerDayModel] = useState(
		editing ? currentPricingPerDayModel : [],
	);

	const [perWeekValue, setPerWeekValue] = useState(
		editing && !isEmptyArray(currentPricingPerWeekModel) ?
			{ value: true, name: 'week', label: t('ui.label.perWeek') }
		:	null,
	);
	const [pricingPerWeekModel, setPricingPerWeekModel] = useState(
		editing ? currentPricingPerWeekModel : [],
	);

	const [fixedPriceModel, setFixedPriceModel] = useState(
		editing && !isEmptyArray(currentFixedPricingModel) ? currentFixedPricingModel : [],
	);

	const [fixedPricingPerHourModel, setFixedPricingPerHourModel] = useState(
		editing && isFullArray(fixedPriceModel) ?
			fixedPriceModel.find((pricingPeriod) => pricingPeriod.periodType === 'hour')
		:	{ periodType: 'hour' },
	);
	const [fixedHourValue, setFixedHourValue] = useState(
		editing && isNumber(fixedPricingPerHourModel.price) ? fixedPricingPerHourModel.price : 0,
	);

	const [fixedPricingPerDayModel, setFixedPricingPerDayModel] = useState(
		editing && isFullArray(fixedPriceModel) ?
			fixedPriceModel.find((pricingPeriod) => pricingPeriod.periodType === 'day')
		:	{ periodType: 'day' },
	);
	const [fixedDayValue, setFixedDayValue] = useState(
		editing && isNumber(fixedPricingPerDayModel.price) ? fixedPricingPerDayModel.price : 0,
	);

	const [fixedPricingPerWeekModel, setFixedPricingPerWeekModel] = useState(
		editing && isFullArray(fixedPriceModel) ?
			fixedPriceModel.find((pricingPeriod) => pricingPeriod.periodType === 'week')
		:	{ periodType: 'week' },
	);
	const [fixedWeekValue, setFixedWeekValue] = useState(
		editing && isNumber(fixedPricingPerWeekModel.price) ? fixedPricingPerWeekModel.price : 0,
	);

	const [validFixedPricing, setValidFixedPricing] = useState(false);

	const [freeKilometers, setFreeKilometers] = useState(
		editing && isNumber(pricingUsage.freeUnits) ? pricingUsage.freeUnits.toString() : '',
	);
	const [priceFreeKilometers, setPriceFreeKilometers] = useState(
		editing && isNumber(pricingUsage.price) ? pricingUsage.price.toFixed(2).toString() : '',
	);
	const [openFreeKilometers, setOpenFreeKilometers] = useState(
		editing && isObject(pricingUsage) ? true : false,
	);

	const [selectedVATPercentage, setSelectedVATPercentage] = useState(editing ? vatPercentage : '');

	const [kilometerPrice, setKilometerPrice] = useState();

	const [startedFinishWizard, setStartedFinishWizard] = useState(false);

	const {
		data: updatedPricingModelData,
		loading: updatedPricingModelLoading,
		error: updatedPricingModelError,
	} = updatedPricingModel;
	const updatePricingModelDone =
		isObject(updatedPricingModelData) && !updatedPricingModelLoading && !updatedPricingModelError;

	const { data: addPricingData, error: addPricingError } = addPricingModels;

	const updatedPricingModelMessage = useError({
		value: updatedPricingModel,
		message: `${t('views.editPricingModel.messages.success')} ${isObject(updatedPricingModelData) && updatedPricingModelData.name}`,
	});

	const addPricingModelMessage = useError({
		value: addPricingModels,
		message: `${t('ui.successfullyAdded')} ${isObject(addPricingData) && addPricingData.name}`,
	});

	//redirect when updating a pricing model is done
	useEffect(() => {
		if (editing && updatePricingModelDone && startedFinishWizard) {
			if (editRedirect) {
				navigate(`/pricing/${pricingModelId}/summary`);
			} else {
				navigate('/policy-management/pricing');
			}
		} else if (editing && startedFinishWizard && !!updatedPricingModelError) {
			setStartedFinishWizard(false);
		}
	}, [editing, updatePricingModelDone, startedFinishWizard, updatedPricingModelError]);

	useEffect(() => {
		if (!isNull(addPricingError)) {
			setStartedFinishWizard(false);
		}
	}, [addPricingError]);

	const callback = (data) => {
		const {
			name,
			organisation,
			category,
			pricingType,
			pricePerMinute,
			pricePerHour,
			pricePerDay,
			pricePerWeek,
			fixedPricePerHour,
			fixedPricePerDay,
			fixedPricePerWeek,
			fixedPricingValidation,
			pricePerMinuteRemove,
			pricePerHourRemove,
			pricePerDayRemove,
			minute,
			hour,
			day,
			week,
			vatPercentage,
			isOpenFreeKMs,
			freeKMs,
			priceFreeKMs,
			openFreeKMs,
			finishedWizard,
		} = data;

		//Add details
		if (isObject(name)) {
			setSelectedName(name.value);
		}
		if (isObject(organisation)) {
			setSelectedOrganisation(organisation);
		}
		if (isNumber(category)) {
			setSelectedCategory(category);
		}
		if (isFullString(pricingType)) {
			setSelectedPricingType(pricingType);
		}

		if (isNumber(vatPercentage)) {
			setSelectedVATPercentage(vatPercentage);
		}

		if (isBoolean(isOpenFreeKMs)) {
			setOpenFreeKilometers(isOpenFreeKMs);
		}

		//Remove models
		if (isObject(pricePerMinuteRemove) && isFullArray(pricingPerMinuteModel)) {
			const editedModel = pricingPerMinuteModel.reduce((acc, cur) => {
				const model =
					cur.id !== pricePerMinuteRemove.id ?
						cur
					:	{ ...cur, days: cur.days.filter((d) => d.id !== pricePerMinuteRemove.dayOfWeek) };
				return [...acc, ...(isFullArray(model.days) ? [model] : [])];
			}, []);

			setPricingPerMinuteModel(editedModel);
		}
		if (isObject(pricePerHourRemove) && isFullArray(pricingPerHourModel)) {
			const editedModel = pricingPerHourModel.reduce((acc, cur) => {
				const model =
					cur.id !== pricePerHourRemove.id ?
						cur
					:	{ ...cur, days: cur.days.filter((d) => d.id !== pricePerHourRemove.dayOfWeek) };
				return [...acc, ...(isFullArray(model.days) ? [model] : [])];
			}, []);

			setPricingPerHourModel(editedModel);
		}
		if (isObject(pricePerDayRemove) && isFullArray(pricingPerDayModel)) {
			const editedModel = pricingPerDayModel.reduce((acc, cur) => {
				const model =
					cur.id !== pricePerDayRemove.id ?
						cur
					:	{ ...cur, days: cur.days.filter((d) => d.id !== pricePerDayRemove.dayOfWeek) };
				return [...acc, ...(isFullArray(model.days) ? [model] : [])];
			}, []);
			setPricingPerDayModel(editedModel);
		}

		//Add models
		if (isObject(pricePerMinute) && isFullArray(pricePerMinute.days)) {
			const { isEdit, initialModelId, dayOfWeek, ...perMinute } = pricePerMinute;
			const editedModel =
				!isEdit ? pricingPerMinuteModel : (
					pricingPerMinuteModel.reduce((acc, cur) => {
						const model =
							cur.id !== initialModelId ?
								cur
							:	{ ...cur, days: cur.days.filter((d) => d.id !== dayOfWeek) };
						return [...acc, ...(isFullArray(model.days) ? [model] : [])];
					}, [])
				);
			const newValue = isEmptyArray(editedModel.filter((model) => model.id === perMinute.id));

			if (newValue || isEdit) {
				const startOfDay = perMinute.start.getHours() === 0 && perMinute.start.getMinutes() === 0;
				const endOfDay = perMinute.end.getHours() === 23 && perMinute.end.getMinutes() === 59;
				const pricePerMinuteUpdated = {
					...perMinute,
					...(endOfDay && {
						allDay: startOfDay && endOfDay,
						end: modifyDate(perMinute.start, { hours: 0, minutes: 0, date: '+1' }),
					}),
				};
				const updatedModel = editedModel.map((model) =>
					model.id === perMinute.id ? pricePerMinuteUpdated : model,
				);

				setPricingPerMinuteModel([...updatedModel, ...(newValue ? [pricePerMinuteUpdated] : [])]);
			}
		}
		if (isObject(pricePerHour) && isFullArray(pricePerHour.days)) {
			const { isEdit, initialModelId, dayOfWeek, ...perHour } = pricePerHour;
			const editedModel =
				!isEdit ? pricingPerHourModel : (
					pricingPerHourModel.reduce((acc, cur) => {
						const model =
							cur.id !== initialModelId ?
								cur
							:	{ ...cur, days: cur.days.filter((d) => d.id !== dayOfWeek) };
						return [...acc, ...(isFullArray(model.days) ? [model] : [])];
					}, [])
				);
			const newValue = isEmptyArray(editedModel.filter((model) => model.id === perHour.id));

			if (newValue || isEdit) {
				const startOfDay = perHour.start.getHours() === 0 && perHour.start.getMinutes() === 0;
				const endOfDay = perHour.end.getHours() === 23 && perHour.end.getMinutes() === 59;
				const pricePerHourUpdated = {
					...perHour,
					...(endOfDay && {
						allDay: startOfDay && endOfDay,
						end: modifyDate(perHour.start, { hours: 0, minutes: 0, date: '+1' }),
					}),
				};
				const updatedModel = editedModel.map((model) =>
					model.id === perHour.id ? pricePerHourUpdated : model,
				);

				setPricingPerHourModel([...updatedModel, ...(newValue ? [pricePerHourUpdated] : [])]);
			}
		}
		if (isObject(pricePerDay) && isFullArray(pricePerDay.days)) {
			const editedModel =
				!pricePerDay.isEdit ? pricingPerDayModel : (
					pricingPerDayModel.reduce((acc, cur) => {
						const model =
							cur.id !== pricePerDay.initialModelId ?
								cur
							:	{ ...cur, days: cur.days.filter((d) => d.id !== pricePerDay.dayOfWeek) };
						return [...acc, ...(isFullArray(model.days) ? [model] : [])];
					}, [])
				);

			const newValue = isEmptyArray(editedModel.filter((model) => model.id === pricePerDay.id));
			if (newValue || pricePerDay.isEdit) {
				const updatedModel = editedModel.map((model) =>
					model.id === pricePerDay.id ? pricePerDay : model,
				);

				setPricingPerDayModel([...updatedModel, ...(newValue ? [pricePerDay] : [])]);
			}
		}
		if (isObject(pricePerWeek)) {
			setPricingPerWeekModel([pricePerWeek]);
		}

		//Add unit steps
		if (isObject(minute)) {
			setPerMinuteValue(minute);
		}
		if (isObject(hour)) {
			setPerHourValue(hour);
		}
		if (isObject(day)) {
			setPerDayValue(day);
		}
		if (isObject(week)) {
			setPerWeekValue(week);
		}

		//Add fixed pricing
		if (isNumber(fixedPricePerHour)) {
			setFixedHourValue(fixedPricePerHour);
			setFixedPricingPerHourModel({
				...fixedPricingPerHourModel,
				price: fixedPricePerHour,
			});
		}
		if (isNumber(fixedPricePerDay)) {
			setFixedDayValue(fixedPricePerDay);
			setFixedPricingPerDayModel({
				...fixedPricingPerDayModel,
				price: fixedPricePerDay,
			});
		}
		if (isNumber(fixedPricePerWeek)) {
			setFixedWeekValue(fixedPricePerWeek);
			setFixedPricingPerWeekModel({
				...fixedPricingPerWeekModel,
				price: fixedPricePerWeek,
			});
		}
		setFixedPriceModel([
			{ ...fixedPricingPerHourModel },
			{ ...fixedPricingPerDayModel },
			{ ...fixedPricingPerWeekModel },
		]);

		if (isBoolean(fixedPricingValidation)) {
			setValidFixedPricing(fixedPricingValidation);
		}

		if (isFullString(freeKMs)) {
			setFreeKilometers(freeKMs);
		}
		if (isFullString(priceFreeKMs)) {
			setPriceFreeKilometers(priceFreeKMs);
		}
		if (isBoolean(openFreeKMs)) {
			setKilometerPrice(openFreeKMs);
		}
		if (finishedWizard && !startedFinishWizard) {
			handleAddPricingModels();
		}
	};

	const createPricingModel = (
		id,
		start,
		end,
		allDay,
		price = 0.0,
		periodType = null,
		days = null,
	) => ({
		id,
		start,
		end,
		allDay,
		price,
		periodType,
		days,
	});

	const hasMinuteStep = isObject(perMinuteValue) && perMinuteValue.value;
	const hasHourStep = isObject(perHourValue) && perHourValue.value;
	const hasDayStep = isObject(perDayValue) && perDayValue.value;
	const hasWeekStep = isObject(perWeekValue) && perWeekValue.value;

	const freePricingModel = [
		{ periodType: 'minute', price: 0 },
		{ periodType: 'hour', price: 0 },
		{ periodType: 'day', price: 0 },
		{ periodType: 'week', price: 0 },
	];
	const freeVAT = '21';

	const handleAddPricingModels = () => {
		const pricingModels = [
			...(hasMinuteStep ? pricingPerMinuteModel : []),
			...(hasHourStep ? pricingPerHourModel : []),
			...(hasDayStep ?
				pricingPerDayModel.map((hour) => {
					// eslint-disable-next-line no-unused-vars
					const { start, end, ...updatedModel } = hour;
					return updatedModel;
				})
			:	[]),
			...(hasWeekStep ? pricingPerWeekModel : []),
		];

		const newPricingModel =
			selectedPricingType === 'flex' ?
				pricingModels.map((pricing) => {
					// eslint-disable-next-line no-unused-vars
					const { allDay, id, ...updatedModel } = pricing;

					return {
						...updatedModel,
						days: updatedModel.days.map((day) => day.value).join(', '),
					};
				})
			:	[...fixedPriceModel];

		if (editing) {
			// pricingModelId, name, organisationId, categoryId, pricingPeriods, usageCost
			onUpdatePricingModel(
				pricingModelId,
				selectedName,
				selectedCategory,
				selectedVATPercentage,
				newPricingModel,
				kilometerPrice ?
					{ price: parseFloat(priceFreeKilometers), freeUnit: parseInt(freeKilometers, 10) }
				:	null,
			);
			setStartedFinishWizard(true);
			updatedPricingModelMessage.setStartAction(true);
		} else {
			onAddPricingModels(
				selectedName,
				selectedOrganisation.id,
				selectedCategory,
				selectedPricingType === 'free' ? 'flex' : selectedPricingType,
				selectedPricingType === 'free' ? freeVAT : selectedVATPercentage,
				selectedPricingType === 'free' ? freePricingModel : newPricingModel,
				kilometerPrice ?
					{ price: parseFloat(priceFreeKilometers), freeUnit: parseInt(freeKilometers, 10) }
				:	null,
			);
			setStartedFinishWizard(true);
			addPricingModelMessage.setStartAction(true);
			setTimeout(
				() =>
					navigate(`/${path.join(pagePathSegments.PolicyManagement, pagePathSegments.Pricing)}`),
				500,
			);
		}
	};

	const steps = [
		{
			name: t('ui.category.pricingModels'),
			content: ModelName,
			valid:
				isFullString(selectedName) &&
				!isEmptyObject(selectedOrganisation) &&
				isNumber(selectedCategory) &&
				isFullString(selectedPricingType),
			props: { editing, selectedName, selectedOrganisation, selectedCategory, selectedPricingType },
		},
		...(selectedPricingType === 'free' ?
			[]
		:	[
				{
					name: t('ui.addPricing.pricingUnits'),
					content: PricingUnits,
					valid:
						isNumber(selectedVATPercentage) &&
						(hasMinuteStep || hasHourStep || hasDayStep || hasWeekStep || validFixedPricing),
					props: {
						editing,
						perMinuteValue,
						perHourValue,
						perDayValue,
						perWeekValue,
						selectedVATPercentage,
						selectedPricingType,
						fixedHourValue,
						fixedDayValue,
						fixedWeekValue,
					},
				},
				...(hasMinuteStep && selectedPricingType === 'flex' ?
					[
						{
							name: t('ui.addPricing.perMinute'),
							content: PerMinute,
							// eslint-disable-next-line no-unused-vars
							valid:
								isFullArray(pricingPerMinuteModel) &&
								pricingPerMinuteModel.reduce(
									(acc, cur) =>
										acc +
										cur.days.reduce(
											(accdays, curdays) =>
												accdays +
												(modifyDate(cur.end, {
													date: 1 + (cur.end.getDate() - cur.start.getDate()),
												}).getTime() -
													modifyDate(cur.start, { date: 1 }).getTime()),
											0,
										),
									0,
								) === weekMilliseconds,
							props: {
								editing,
								perMinuteValue,
								selectedCategory,
								pricingPerMinuteModel,
								eventColors,
								createPricingModel,
							},
						},
					]
				:	[]),
				...(hasHourStep && selectedPricingType === 'flex' ?
					[
						{
							name: t('ui.addPricing.perHour'),
							content: PerHour,
							// eslint-disable-next-line no-unused-vars
							valid:
								isFullArray(pricingPerHourModel) &&
								pricingPerHourModel.reduce(
									(acc, cur) =>
										acc +
										cur.days.reduce(
											(accdays, curdays) =>
												accdays +
												(modifyDate(cur.end, {
													date: 1 + (cur.end.getDate() - cur.start.getDate()),
												}).getTime() -
													modifyDate(cur.start, { date: 1 }).getTime()),
											0,
										),
									0,
								) === weekMilliseconds,
							props: {
								editing,
								perHourValue,
								selectedCategory,
								pricingPerHourModel,
								eventColors,
								createPricingModel,
							},
						},
					]
				:	[]),
				...(hasDayStep && selectedPricingType === 'flex' ?
					[
						{
							name: t('ui.addPricing.perDay'),
							content: PerDay,
							valid:
								isFullArray(pricingPerDayModel) &&
								pricingPerDayModel.reduce((acc, cur) => acc + cur.days.length, 0) === 7,
							props: {
								editing,
								perDayValue,
								selectedCategory,
								pricingPerDayModel,
								eventColors,
								createPricingModel,
							},
						},
					]
				:	[]),
				...(hasWeekStep && selectedPricingType === 'flex' ?
					[
						{
							name: 'Per week',
							content: PerWeek,
							valid: isFullArray(pricingPerWeekModel),
							props: {
								editing,
								perWeekValue,
								selectedCategory,
								pricingPerWeekModel,
								eventColors,
								createPricingModel,
							},
						},
					]
				:	[]),
				{
					name: t('ui.summary'),
					content: Summary,
					valid: true,
					props: {
						editing,
						selectedName,
						selectedCategory,
						data: [
							hasMinuteStep ? pricingPerMinuteModel : [],
							hasHourStep ? pricingPerHourModel : [],
							hasDayStep ? pricingPerDayModel : [],
							hasWeekStep ? pricingPerWeekModel : [],
						],
						fixedPriceModel,
						selectedPricingType,
						perMinuteValue,
						perHourValue,
						perDayValue,
						perWeekValue,
						freeKilometers,
						priceFreeKilometers,
						openFreeKilometers,
						setOpenFreeKilometers,
						setFreeKilometers,
						setPriceFreeKilometers,
					},
				},
			]),
	];

	return (
		<Wizard
			activeStartStep={activeStartStep || 0}
			callback={callback}
			loading={startedFinishWizard}
			stepperType={'progress'}
			steps={steps}
			title={t('ui.pricing')}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		addPricingModels: state.details.addPricingModels,
		updatedPricingModel: state.details.updatedPricingModel,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onResetState: (identifier) => dispatch(actions.resetState(identifier)),
		onAddPricingModels: (
			name,
			organisationId,
			categoryId,
			pricingType,
			vatPercentage,
			pricingPeriods,
			usageCost,
		) =>
			dispatch(
				actions.addPricingModels(
					name,
					organisationId,
					categoryId,
					pricingType,
					vatPercentage,
					pricingPeriods,
					usageCost,
				),
			),
		onUpdatePricingModel: (
			pricingModelId,
			name,
			categoryId,
			vatPercentage,
			pricingPeriods,
			usageCost,
		) =>
			dispatch(
				actions.updatePricingModel(
					pricingModelId,
					name,
					categoryId,
					vatPercentage,
					pricingPeriods,
					usageCost,
				),
			),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPricing);

import { Children, PropsWithChildren, useMemo, useState } from 'react';

import { Refresh as RefreshIcon } from '@mui/icons-material';
import { AutocompleteChangeReason, Button, Grid2 } from '@mui/material';
import {
	GridFilterModel,
	gridFilterModelSelector,
	GridToolbarContainer,
	useGridApiContext,
	useGridSelector,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

import AsyncTextField from '~components/textFields/asyncTextField';
import { useAuthorize } from '~features/authentication';
import { OrganisationAutocomplete } from '~features/organisations';
import { BaseReference } from '~interfaces';
import i18n from '~lib/i18n';

interface DataGridToolbarProps extends PropsWithChildren {
	disableSearch?: boolean;
	disabledOrganisationFilter?: boolean;
	disableClearableOrganisation?: boolean;
	searchPlaceholder?: string;
	filters?: JSX.Element;
}

const DataGridToolbar = ({
	searchPlaceholder = i18n.t('ui.placeholders.search.generic'),
	filters,
	disableSearch = false,
	disableClearableOrganisation = false,
	disabledOrganisationFilter = false,
	children,
}: DataGridToolbarProps) => {
	const { t } = useTranslation();
	const { isSuperAdmin } = useAuthorize();
	const apiRef = useGridApiContext();

	const [inputSearchValue, setInputSearchValue] = useState<string>('');

	const filterModel = useGridSelector(apiRef, gridFilterModelSelector);

	const organisation = useMemo(() => {
		return filterModel.items?.[0]?.field === 'organisation' ? filterModel.items[0].value : undefined;
	}, [filterModel.items]);

	const handleOrganisationFilterChange = (
		newValue: BaseReference | null,
		reason: AutocompleteChangeReason
	) => {
		if (disableClearableOrganisation && reason === 'clear') {
			return;
		}

		const newFilterModel: GridFilterModel = {
			...filterModel,
			items:
				newValue != null ?
					[
						{
							field: 'organisation',
							operator: 'contains',
							value: newValue,
						},
					]
				:	[],
		};
		apiRef.current.setFilterModel(newFilterModel);
	};

	const handleQuickFilterChange = (e) => {
		const newFilterModel: GridFilterModel = {
			...filterModel,
			quickFilterValues: e.target.value ? [e.target.value] : [],
		};

		apiRef.current.setFilterModel(newFilterModel);
	};

	const handleClearAllFilters = () => {
		const newFilterModel: GridFilterModel = {
			...filterModel,
			quickFilterValues: [],
			...(!disableClearableOrganisation && {
				items: [],
			}),
		};

		setInputSearchValue('');
		apiRef.current.setFilterModel(newFilterModel);
	};

	const canClearAllFilters = () =>
		(isSuperAdmin() &&
			filterModel.items?.[0]?.field === 'organisation' &&
			!disableClearableOrganisation) ||
		filterModel.quickFilterValues?.[0]?.length > 0;

	return (
		<GridToolbarContainer>
			<Grid2 container gap={1} direction='row-reverse' sx={{ width: 1 }}>
				<Grid2
					container
					size={{ xs: 12, sm: 'auto' }}
					direction='row'
					gap='inherit'
					sx={{ alignItems: 'center' }}
				>
					{Children.map(children, (child) => (
						<Grid2>{child}</Grid2>
					))}
				</Grid2>
				<Grid2 container size={{ xs: 12, sm: 12, md: 'grow' }} direction='row' gap='inherit'>
					{!disableSearch && (
						<Grid2>
							<AsyncTextField
								manual
								size='small'
								placeholder={searchPlaceholder}
								inputValue={inputSearchValue}
								onInputChange={(e) => setInputSearchValue(e.target.value)}
								onChange={handleQuickFilterChange}
								sx={{ width: 220 }}
							/>
						</Grid2>
					)}
					{isSuperAdmin() && !disabledOrganisationFilter && (
						<Grid2>
							<OrganisationAutocomplete
								size='small'
								value={organisation}
								onChange={(_, value, reason) => handleOrganisationFilterChange(value, reason)}
								sx={{ width: 220 }}
							/>
						</Grid2>
					)}

					{filters != null &&
						Children.map(filters, (child, i) => (
							<Grid2 key={i} size='grow' sx={{ minWidth: 150, maxWidth: 250 }}>
								{child}
							</Grid2>
						))}

					{canClearAllFilters() && (
						<Grid2 sx={{ display: 'flex', alignItems: 'center' }}>
							<Button startIcon={<RefreshIcon />} onClick={handleClearAllFilters}>
								{t('ui.button.inline.clearfilters')}
							</Button>
						</Grid2>
					)}
				</Grid2>
			</Grid2>
		</GridToolbarContainer>
	);
};

export default DataGridToolbar;

import { useState } from 'react';

import { Card, CardContent, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useAuthorize } from '~features/authentication';

import DateTimeCard from './DateTimeCard';
import {
	Tip,
	FormField,
	FormFieldLabel,
	SelectWithLazyLoading,
	InfoLabel,
} from '../../../../../components';
import { useWizardFormField, useDebouncedWizardSave } from '../../../../../shared/hooks';
import { isInteger, isEmptyString } from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';
import { useStyles } from '../style';

interface SettingsProps {
	save?(...args: unknown[]): unknown;
	contractType?: string;
	contractDetails?: object;
	onFetchAllIteminstances?(...args: unknown[]): unknown;
	onFetchTerms?(...args: unknown[]): unknown;
	onFetchUsers?(...args: unknown[]): unknown;
	onFetchPartnerships?(...args: unknown[]): unknown;
	organisationFilter?: number | string;
	allIteminstances?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	termsList?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	users?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	fetchPartnerships?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	selectedUser?: string;
	setSelectedUser?(...args: unknown[]): unknown;
	userFilter?: string;
	setUserFilter?(...args: unknown[]): unknown;
	selectedIteminstances?: any;
	setSelectedIteminstances?(...args: unknown[]): unknown;
	iteminstancesFilter?: string;
	setIteminstancesFilter?(...args: unknown[]): unknown;
	selectedTerms?: string;
	setSelectedTerms?(...args: unknown[]): unknown;
	termsFilter?: string;
	setTermsFilter?(...args: unknown[]): unknown;
	selectedPartner?: string;
	setSelectedPartner?(...args: unknown[]): unknown;
	partnerFilter?: string;
	setPartnerFilter?(...args: unknown[]): unknown;
	selectedStartDate?: any;
	selectedEndDate?: any;
}

const Settings = (props: SettingsProps) => {
	const {
		save,
		contractType,

		contractDetails,
		onFetchAllIteminstances,
		allIteminstances,
		onFetchTerms,
		termsList,
		onFetchUsers,
		users,
		organisationFilter,

		onFetchPartnerships,
		fetchPartnerships,
		selectedUser,
		setSelectedUser,
		userFilter,
		setUserFilter,
		selectedIteminstances,
		setSelectedIteminstances,
		iteminstancesFilter,
		setIteminstancesFilter,
		selectedTerms,
		setSelectedTerms,
		termsFilter,
		setTermsFilter,
		selectedPartner,
		setSelectedPartner,
		partnerFilter,
		setPartnerFilter,
		selectedStartDate,
		selectedEndDate,
	} = props;

	const { t } = useTranslation();
	const { isSuperAdmin } = useAuthorize();

	const name = useWizardFormField(contractDetails.name || '', {
		required: true,
		minLength: 3,
		maxLength: 25,
	});
	useDebouncedWizardSave('updatedName', name.value, name.isValid, save, 400, true);

	const documentContent = useWizardFormField(contractDetails.description || '', {
		maxLength: 500,
		minLength: 3,
	});
	useDebouncedWizardSave(
		'updatedDescription',
		documentContent.value,
		documentContent.isValid,
		save,
		400,
		false,
	);

	const classes = useStyles();

	const [organisationIdFilter] = useState({
		name: 'organisationId',
		value: organisationFilter,
	});

	const [extraFilter] = useState({
		name: 'status',
		value: 'accepted',
	});

	const onChangeUser = (user) => {
		if (user.id === userFilter) {
			return;
		}
		if (isInteger(user.id)) {
			setSelectedUser(user.name);
		} else if (isEmptyString(user.id)) {
			setSelectedUser('');
		}
		setUserFilter(user.id);
	};

	const onChangeIteminstances = (instances) => {
		if (instances.id === iteminstancesFilter) {
			return;
		}
		if (isInteger(instances.id)) {
			setSelectedIteminstances(`${instances.itemReference.name} - ${instances.name}`);
		} else if (isEmptyString(instances.id)) {
			setSelectedIteminstances('');
		}
		setIteminstancesFilter(instances.id);
	};

	const onChangeTerms = (term) => {
		if (term.id === termsFilter) {
			return;
		}
		if (isInteger(term.id)) {
			setSelectedTerms(term.title);
		} else if (isEmptyString(term.id)) {
			setSelectedTerms('');
		}
		setTermsFilter(term.id);
	};

	const onChangePartner = (partner) => {
		if (partner?.partnerOrganisation?.id === partnerFilter) {
			return;
		}
		if (partner?.partnerOrganisation?.id != null) {
			setSelectedPartner(partner.partnerOrganisation.name);
		} else if (isEmptyString(partner?.id)) {
			setSelectedPartner('');
		}
		setPartnerFilter(isEmptyString(partner?.id) ? '' : partner?.partnerOrganisation.id);
	};

	const settingsData = [
		{
			listType: 'allIteminstances',
			list: allIteminstances,
			name: 'views.planboard.filterbar.filters.items.all',
			fetchData: onFetchAllIteminstances,
			placeholder: 'views.planboard.filterbar.search.placeholder',
			searchHandle: setSelectedIteminstances,
			setSelected: onChangeIteminstances,
			value: selectedIteminstances,
			isVisible: true,
			info: 'views.contract.add.settings.itemInfo',
			label: 'ui.label.items',
		},
		{
			listType: 'users',
			list: users,
			name: 'views.usersAndOrganisations.users.table.title',
			fetchData: onFetchUsers,
			placeholder: 'ui.placeholders.search.user',
			searchHandle: setSelectedUser,
			setSelected: onChangeUser,
			value: selectedUser,
			isVisible: contractType === 'internal',
			info: 'views.contract.add.settings.userInfo',
			label: 'ui.label.users',
		},
		{
			listType: 'partners',
			list: fetchPartnerships,
			name: 'views.Partnerships.Requests.allPartners',
			fetchData: onFetchPartnerships,
			placeholder: 'ui.label.partner.search',
			searchHandle: setSelectedPartner,
			setSelected: onChangePartner,
			value: selectedPartner,
			isVisible: contractType === 'external',
			info: 'views.contract.add.settings.partnerInfo',
			label: 'views.assignItemsToPartner.blockHeader.selectPartners',
		},
		{
			listType: 'terms',
			list: termsList,
			name: 'ui.label.terms.all',
			fetchData: onFetchTerms,
			placeholder: 'ui.placeholders.search.terms',
			searchHandle: setSelectedTerms,
			setSelected: onChangeTerms,
			value: selectedTerms,
			isVisible: true,
			info: 'views.contract.add.settings.termsInfo',
			label: 'views.addItem.settings.subsection.terms.title',
		},
	];

	const dateTimeProps = {
		selectedStartDate,
		selectedEndDate,
		save,
	};

	return (
		<Card>
			<CardContent className={classes.mainCard}>
				<Box display='flex' flexDirection='column' pb={3}>
					<Box pb={0.5}>
						<Typography variant='h3'>{t('views.contract.add.settings.header')}</Typography>
					</Box>
					<Typography variant='body2'>
						{t('views.contract.add.selectContract.description')}
					</Typography>
				</Box>
				<div className={classes.itemSpacing}>
					<FormFieldLabel
						className={classes.inputLabelSpacing}
						info={t('views.contract.add.settings.contract.nameDescription')}
						label={t('views.contract.add.settings.contract.name')}
						required
						variant='h4'
					/>
					<FormField
						extraValidHelperText={t('views.addItem.itemDetails.itemName')}
						hideCharacterCounter={!name.hasFocus}
						label={t('ui.label.name')}
						maxLength={25}
						name='name'
						required={true}
						variable={name}
					/>
					<Tip
						arrowDirection='top'
						className={classes.tip}
						message={t('views.contract.add.settings.contract.tip')}
					/>
				</div>
				<div className={classes.itemSpacing}>
					<Box pb={1}>
						<Box display='flex' justifyContent='space-between'>
							<Typography variant='h5'>
								{t('views.contract.add.settings.contract.description')}
							</Typography>
							<Typography className={classes.counterText}>
								{documentContent.value.length}/500 {t('ui.characters')}
							</Typography>
						</Box>
						<Typography>{t('views.contract.add.settings.contract.descriptionDetails')}</Typography>
					</Box>
					<FormField
						hideCharacterCounter
						maxLength={500}
						multiline
						name={'description'}
						placeholder={t('views.addItem.itemDetails.subsection.description.placeholder')}
						required
						rows={4}
						variable={documentContent}
					/>
				</div>
				{settingsData.map((item) =>
					item.isVisible ?
						<div className={classes.itemSpacing} key={item.listType}>
							<InfoLabel
								info={t(item.info)}
								isRequired
								name={t(item.label)}
								paddingBottom={3}
								paddingTop={3}
								variant={'h4'}
							/>
							<SelectWithLazyLoading
								className={classes.filterInput}
								dataList={item.list}
								defaultListItem={{ id: '', name: t(item.name) }}
								extraFilter={item.listType === 'partners' ? extraFilter : null}
								filter={isSuperAdmin() ? organisationIdFilter : null}
								listType={item.listType}
								onFetchData={item.fetchData}
								placeholder={t(item.placeholder)}
								searchHandle={item.searchHandle}
								setSelected={item.setSelected}
								value={item.value}
							/>
						</div>
					:	null,
				)}
				<div className={classes.itemSpacing}>
					<DateTimeCard {...dateTimeProps} />
				</div>
			</CardContent>
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		termsList: state.paged.terms,
		allIteminstances: state.paged.allIteminstances,
		users: state.paged.users,

		fetchPartnerships: state.paged.fetchPartnerships,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchAllIteminstances: (page, filters, concat) =>
			dispatch(actions.fetchAllIteminstances(page, filters, concat)),
		onFetchTerms: (page, filters, concat) => dispatch(actions.fetchTerms(page, filters, concat)),
		onFetchUsers: (page, filters, concat) => dispatch(actions.fetchUsers(page, filters, concat)),
		onFetchPartnerships: (page, filters, concat) =>
			dispatch(actions.fetchPartnerships(page, filters, concat)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);

import { useRef, createRef, useEffect, useState } from 'react';

import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import { Paper, List, ListItem, ListItemButton, Box, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { LoadingBar, StyledButton } from '../../../components';
import { isInteger, handleLockType, isFullArray } from '../../../shared/utility';
import { useMainContext } from '../FleetManagement';
import { useStyles } from '../style';

interface ItemContainerProps {
	data?: unknown[];
	loading?: boolean;
	prevLoading?: boolean;
}

const ItemContainer = (props: ItemContainerProps) => {
	const { data, loading } = props;
	const { t } = useTranslation();
	const classes = useStyles();
	const scrollRefs = useRef([]);
	const {
		selectedIndex,
		resetOptions,
		fleetList,
		showFilteredList,
		listHight,
		resetActiveStates,
		setActiveStates,
	} = useMainContext();

	const [fleetData, setFleetData] = useState([]);

	useEffect(() => {
		if (!loading) {
			setFleetData(showFilteredList ? fleetList : data);
		}
	}, [showFilteredList, fleetList, data, loading]);

	useEffect(() => {
		if (isFullArray(fleetData)) {
			scrollRefs.current = fleetData.map((_, i) => scrollRefs.current[i] ?? createRef());
		}
	}, [fleetData]);

	const handleClickItem = (event, index, fleet, showFiltered = undefined) => {
		resetOptions({ lat: fleet.latitude, lng: fleet.longitude });
		setActiveStates({ index: index, fleet: fleet, showFiltered: showFiltered });
	};

	const scrollSmoothHandler = (index) => {
		scrollRefs.current[index]?.current?.scrollIntoView({ behavior: 'smooth' });
	};

	useEffect(() => {
		if (selectedIndex && isFullArray(fleetData)) {
			scrollSmoothHandler(selectedIndex);
		}
	}, [selectedIndex, fleetData]);

	const handleBackButton = () => {
		resetActiveStates();
	};

	return (
		<Paper
			elevation={8}
			style={{ height: `${isInteger(listHight) && listHight}.px`, width: 350, borderRadius: 0 }}
		>
			<List className={classes.list}>
				{showFilteredList && (
					<>
						<Box pb={0.5}>
							<StyledButton
								onClick={handleBackButton}
								startIcon={<KeyboardArrowLeftOutlinedIcon />}
							>
								{t('ui.back')}
							</StyledButton>
						</Box>
						<Divider />
					</>
				)}
				{loading ?
					<Box sx={{ padding: '8px 16px' }}>
						<ListItem>
							<LoadingBar />
						</ListItem>
						<ListItem>
							<LoadingBar />
						</ListItem>
						<ListItem>
							<LoadingBar />
						</ListItem>
					</Box>
				: isFullArray(fleetData) ?
					fleetData.map((fleet, index) => {
						const itemName = fleet?.itemName ? fleet.itemName : handleLockType(fleet.deviceType);
						const instanceName = fleet?.instanceName ? fleet.instanceName : fleet.deviceId;
						return (
							<ListItem
								className={classes.listItem}
								key={`${fleet.instanceId}-${fleet.deviceId}`}
								ref={scrollRefs.current[index]}
							>
								<ListItemButton
									onClick={(event) =>
										handleClickItem(event, index, fleet, showFilteredList ? true : false)
									}
									selected={selectedIndex === index}
									style={
										selectedIndex === index ?
											{
												transitionDuration: '0s !important',
												borderLeft: '1px solid #016dff',
												color: '#016dff',
												backgroundColor: 'transparent',
											}
										:	null
									}
								>
									{`${itemName} - ${instanceName}`}
								</ListItemButton>
							</ListItem>
						);
					})
				:	<ListItem>
						<Typography sx={{ padding: '8px 16px' }}>
							{t('views.assets.items.empty.title')}
						</Typography>
					</ListItem>
				}
			</List>
		</Paper>
	);
};

export default ItemContainer;

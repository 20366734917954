import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { AccessRuleDetailsLayout } from '~features/access';

import PageNotFoundPage from '../other/pageNotFoundPage';
import Page from '../page';

const AccessRuleDetailsPage = () => {
	const { t } = useTranslation();
	const { id } = useParams();

	if (!id) {
		return <PageNotFoundPage />;
	}

	return (
		<Page title={`${t('views.addTemplate.templateDetails.title')} ${id}`}>
			<AccessRuleDetailsLayout id={id} />
		</Page>
	);
};

export default AccessRuleDetailsPage;

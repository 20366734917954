import { useState, useEffect } from 'react';

import { useAtomValue } from 'jotai';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { userInfoAtom } from '~atoms';

import ContactPerson from './ContactPerson';
import InvoiceDetails from './InvoiceDetails';
import OrganisationDetails from './OrganisationDetails';
import { useStyles } from './style';
import { Wizard } from '../../../components';
import {
	isUndefined,
	isFullString,
	isObject,
	isFullArray,
	isEmptyArray,
} from '../../../shared/utility';
import * as actions from '../../../store/actions';

interface AddCompanyProps {
	addedOrganisation?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onAddOrganisation?(...args: unknown[]): unknown;
}

const AddCompany = (props: AddCompanyProps) => {
	const { addedOrganisation, onAddOrganisation, } = props;
	const { t } = useTranslation();
	const navigate = useNavigate();
	const userInfo = useAtomValue(userInfoAtom);

	const { enqueueSnackbar } = useSnackbar();

	const classes = useStyles();

	const [name, setName] = useState();
	const [legalName, setLegalName] = useState('');
	const [isValidName, setIsValidName] = useState(false);
	const [description, setDescription] = useState();
	const [isValidDescription, setIsValidDescription] = useState(false);
	const [images, setImages] = useState([]);
	const [bannerImages, setBannerImages] = useState([]);
	const [organisationEmail, setOrganisationEmail] = useState();
	const [isValidOrganisationEmail, setIsValidOrganisationEmail] = useState(false);
	const [organisationPhoneNumber, setOrganisationPhoneNumber] = useState();
	const [isValidOrganisationPhoneNumber, setIsValidOrganisationPhoneNumber] = useState(false);
	const [address, setAddress] = useState({
		street: '',
		number: '',
		numberAddition: '',
		postalCode: '',
		city: '',
		country: '',
	});
	const [isValidStreet, setIsValidStreet] = useState(false);
	const [isValidPostalCode, setIsValidPostalCode] = useState(false);
	const [isValidHouseNumber, setIsValidHouseNumber] = useState(false);
	const [isValidAddressAddition, setIsValidAddressAddition] = useState(false);
	const [isValidCity, setIsValidCity] = useState(false);
	const [isValidCountry, setIsValidCountry] = useState(false);

	const [oiChecksReady, setOIChecksReady] = useState(false);

	const [vatNumber, setVatNumber] = useState();
	const [isValidVatNumber, setIsValidVatNumber] = useState(false);
	const [enterpriseNumber, setEnterpriseNumber] = useState();
	const [isValidEnterpriseNumber, setIsValidEnterpriseNumber] = useState(false);
	const [chamberOfCommerceNumber, setChamberOfCommerceNumber] = useState();
	const [isValidCocNumber, setIsValidCocNumber] = useState(false);
	const [ibanNumber, setIbanNumber] = useState();
	const [isValidIbanNumber, setIsValidIbanNumber] = useState(false);
	const [isTermsChecked, setIsTermsChecked] = useState(false);

	const [billingChecksReady, setBillingChecksReady] = useState(false);

	const organisationDetailsValid =
		oiChecksReady &&
		billingChecksReady &&
		isValidName &&
		(isFullString(description) ? isValidDescription : true) &&
		!isEmptyArray(images) &&
		isValidOrganisationEmail &&
		isValidOrganisationPhoneNumber &&
		isValidStreet &&
		isValidPostalCode &&
		isValidHouseNumber &&
		(isFullString(address.numberAddition) ? isValidAddressAddition : true) &&
		isValidCity &&
		isValidCountry &&
		(isFullString(vatNumber) ? isValidVatNumber : true) &&
		(isFullString(chamberOfCommerceNumber) ? isValidCocNumber : true) &&
		(isFullString(enterpriseNumber) ? isValidEnterpriseNumber : true) &&
		(isFullString(ibanNumber) ? isValidIbanNumber : true);

	//contact person
	const [firstName, setFirstName] = useState();
	const [isValidFirstName, setIsValidFirstName] = useState(false);
	const [lastName, setLastName] = useState();
	const [isValidLastName, setIsValidLastName] = useState(false);
	const [contactPhoneNumber, setContactPhoneNumber] = useState();
	const [isValidContactPhoneNumber, setIsValidContactPhoneNumber] = useState(false);
	const [contactEmail, setContactEmail] = useState();
	const [isValidContactEmail, setIsValidContactEmail] = useState(false);

	useEffect(() => {
		setFirstName(userInfo.firstName);
		setLastName(userInfo.lastName);
		setContactPhoneNumber(userInfo.phoneNumber);
		setContactEmail(userInfo.email);
	}, [userInfo]);

	const contactPersonValid =
		isValidFirstName && isValidLastName && isValidContactPhoneNumber && isValidContactEmail;

	//invoice
	const [invoiceName, setInvoiceName] = useState();
	const [isValidInvoiceName, setIsValidInvoiceName] = useState(false);
	const [invoiceEmail, setInvoiceEmail] = useState();
	const [isValidInvoiceEmail, setIsValidInvoiceEmail] = useState(false);
	const [chosenSubscription, setChosenSubscription] = useState();
	const [isValidSubscription, setIsValidSubscription] = useState(false);

	const invoiceDetailsValid =
		isValidInvoiceName && isValidInvoiceEmail && isValidSubscription && isTermsChecked;

	const [startedFinishWizard, setStartedFinishWizard] = useState(false);

	const [shouldSubmitOrganisation, setShouldSubmitOrganisation] = useState(false);
	const [submittingOrganisation, setSubmittingOrganisation] = useState(false);
	const [submittedOrganisation, setSubmittedOrganisation] = useState(false);

	const {
		data: addedOrganisationData,
		loading: addedOrganisationLoading,
		error: addedOrganisationError,
	} = addedOrganisation;
	const addOrganisationDone =
		isObject(addedOrganisationData) && !addedOrganisationLoading && !addedOrganisationError;

	const handleAddOrganisation = () => {
		onAddOrganisation(
			name,
			isFullString(description) ? description : null,
			organisationPhoneNumber,
			organisationEmail,
			{
				...address,
			},
			(
				!isFullString(vatNumber) &&
					!isFullString(enterpriseNumber) &&
					!isFullString(chamberOfCommerceNumber) &&
					!isFullString(ibanNumber)
			) ?
				null
			:	{
					...(isFullString(vatNumber) && { vatNumber }),
					...(isFullString(enterpriseNumber) && { enterpriseNumber }),
					...(isFullString(chamberOfCommerceNumber) && { chamberOfCommerceNumber }),
					...(isFullString(ibanNumber) && { ibanNumber }),
				},
			{
				firstName,
				lastName,
				email: contactEmail,
				phoneNumber: contactPhoneNumber,
			},
			{
				name: invoiceName,
				email: invoiceEmail,
				subscriptionId: chosenSubscription,
			},
			isFullArray(images) ? images : null,
			bannerImages,
			legalName,
		);
	};

	useEffect(() => {
		if (shouldSubmitOrganisation && !submittingOrganisation && !submittedOrganisation) {
			setShouldSubmitOrganisation(false);
			setSubmittingOrganisation(true);
			handleAddOrganisation();
		}
	}, [shouldSubmitOrganisation, submittingOrganisation, submittedOrganisation]);

	useEffect(() => {
		if (submittingOrganisation && addOrganisationDone) {
			setSubmittingOrganisation(false);
			setSubmittedOrganisation(true);
		}
	});

	// reset on error
	useEffect(() => {
		if (addedOrganisationError) {
			enqueueSnackbar(
				isObject(addedOrganisationError) ? addedOrganisationError.message : addedOrganisationError,
				{ variant: 'error' },
			);
			setShouldSubmitOrganisation(false);
			setSubmittingOrganisation(false);
			setSubmittedOrganisation(false);
			setStartedFinishWizard(false);
		}
	}, [addedOrganisationError]);

	// redirect on when organisation has been added successfully
	useEffect(() => {
		if (submittedOrganisation) {
			//success message
			enqueueSnackbar(
				`${t('views.register.organisation.message.success')} ${addedOrganisationData.name}`,
				{ variant: 'success' },
			);
			navigate('/register/verification-status');
		}
	}, [submittedOrganisation]);

	const callback = (data) => {
		const {
			organisationName,
			legalName: organisationLegalName,
			organisationDescription,
			logoImage,
			bannerImage,
			companyEmail: oEmailToSave,
			companyPhoneNumber: oPhoneNumberToSave,
			street,
			postalCode,
			houseNumber,
			addressAddition,
			city,
			country,
			organisationInfoChecksReady,
			organisationVatNumber,
			enterpriseNumber: enterpriseNumberToSave,
			cocNumber,
			organisationIbanNumber,
			isCheckedTermsAndConditions,
			billingInfoChecksReady,
			firstName: firstNameToSave,
			lastName: lastNameToSave,
			contactPhoneNumber: cPhoneNumberToSave,
			contactEmail: cEmailToSave,
			invoiceName: iNameToSave,
			invoiceEmail: iEmailToSave,
			subscription,
			finishedWizard,
		} = data;

		if (!isUndefined(organisationName)) {
			setName(organisationName.value);
			setIsValidName(organisationName.valid);
		}

		if (!isUndefined(organisationLegalName)) {
			setLegalName(organisationLegalName.value);
		}

		if (!isUndefined(organisationDescription)) {
			setDescription(organisationDescription.value);
			setIsValidDescription(organisationDescription.valid);
		}

		if (!isUndefined(logoImage)) {
			setImages(logoImage);
		}

		if (!isUndefined(bannerImage)) {
			setBannerImages(bannerImage);
		}

		if (!isUndefined(oEmailToSave)) {
			setOrganisationEmail(oEmailToSave.value);
			setIsValidOrganisationEmail(oEmailToSave.valid);
		}

		if (!isUndefined(oPhoneNumberToSave)) {
			setOrganisationPhoneNumber(oPhoneNumberToSave.value);
			setIsValidOrganisationPhoneNumber(oPhoneNumberToSave.valid);
		}

		if (
			!isUndefined(street) &&
			!isUndefined(postalCode) &&
			!isUndefined(houseNumber) &&
			!isUndefined(addressAddition) &&
			!isUndefined(city) &&
			!isUndefined(country)
		) {
			setAddress({
				...address,
				street: street.value,
				postalCode: postalCode.value,
				number: houseNumber.value,
				numberAddition: addressAddition.value,
				city: city.value,
				country: country.value,
			});
			setIsValidStreet(street.valid);
			setIsValidPostalCode(postalCode.valid);
			setIsValidHouseNumber(houseNumber.valid);
			setIsValidAddressAddition(addressAddition.valid);
			setIsValidCity(city.valid);
			setIsValidCountry(country.valid);
		}

		if (!isUndefined(organisationInfoChecksReady)) {
			setOIChecksReady(organisationInfoChecksReady);
		}

		if (!isUndefined(organisationVatNumber)) {
			setVatNumber(organisationVatNumber.value);
			setIsValidVatNumber(organisationVatNumber.valid);
		}

		if (!isUndefined(enterpriseNumberToSave)) {
			setEnterpriseNumber(enterpriseNumberToSave.value);
			setIsValidEnterpriseNumber(enterpriseNumberToSave.valid);
		}

		if (!isUndefined(cocNumber)) {
			setChamberOfCommerceNumber(cocNumber.value);
			setIsValidCocNumber(cocNumber.valid);
		}

		if (!isUndefined(organisationIbanNumber)) {
			setIbanNumber(organisationIbanNumber.value);
			setIsValidIbanNumber(organisationIbanNumber.valid);
		}

		if (!isUndefined(isCheckedTermsAndConditions)) {
			setIsTermsChecked(isCheckedTermsAndConditions);
		}

		if (!isUndefined(billingInfoChecksReady)) {
			setBillingChecksReady(billingInfoChecksReady);
		}

		if (!isUndefined(firstNameToSave)) {
			setFirstName(firstNameToSave.value);
			setIsValidFirstName(firstNameToSave.valid);
		}

		if (!isUndefined(lastNameToSave)) {
			setLastName(lastNameToSave.value);
			setIsValidLastName(lastNameToSave.valid);
		}

		if (!isUndefined(cPhoneNumberToSave)) {
			setContactPhoneNumber(cPhoneNumberToSave.value);
			setIsValidContactPhoneNumber(cPhoneNumberToSave.valid);
		}

		if (!isUndefined(cEmailToSave)) {
			setContactEmail(cEmailToSave.value);
			setIsValidContactEmail(cEmailToSave.valid);
		}

		if (!isUndefined(iNameToSave)) {
			setInvoiceName(iNameToSave.value);
			setIsValidInvoiceName(iNameToSave.valid);
		}

		if (!isUndefined(iEmailToSave)) {
			setInvoiceEmail(iEmailToSave.value);
			setIsValidInvoiceEmail(iEmailToSave.valid);
		}

		if (!isUndefined(subscription)) {
			setChosenSubscription(subscription.value);
			setIsValidSubscription(subscription.valid);
		}

		if (finishedWizard && !startedFinishWizard) {
			setStartedFinishWizard(true);
			setShouldSubmitOrganisation(true);
		}
	};

	const addOrganisationSteps = [
		{
			name: t('views.register.organisation.steps.organisationDetails'),
			content: OrganisationDetails,
			valid: organisationDetailsValid,
			props: {
				name,
				legalName,
				description,
				images,
				bannerImages,
				organisationEmail,
				organisationPhoneNumber,
				address,
				vatNumber,
				enterpriseNumber,
				chamberOfCommerceNumber,
				ibanNumber,
			},
		},
		{
			name: 'Contact person',
			content: ContactPerson,
			valid: contactPersonValid,
			props: { firstName, lastName, contactPhoneNumber, contactEmail },
		},
		{
			name: 'Invoice details',
			content: InvoiceDetails,
			valid: invoiceDetailsValid,
			props: { invoiceName, invoiceEmail, chosenSubscription, isTermsChecked },
		},
	];

	// TODO: uncomment this once there are actually FAQs
	// const sidepanel = (
	//   <SidepanelContainer sticky={true} className={classes.sidebar}>
	//     <SidePanel title={t('views.addOrganisation.sidepanel.title')}>
	//       <Accordion>
	//         <AccordionSummary
	//           expandIcon={<ExpandMoreIcon />}
	//           aria-controls="panel1a-content"
	//           id="panel1a-header"
	//         >
	//           <Typography>How can I do this?</Typography>
	//         </AccordionSummary>
	//         <AccordionDetails>
	//           <Typography>
	//             Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
	//             sit amet blandit leo lobortis eget.
	//           </Typography>
	//         </AccordionDetails>
	//       </Accordion>
	//       <Accordion>
	//         <AccordionSummary
	//           expandIcon={<ExpandMoreIcon />}
	//           aria-controls="panel1a-content"
	//           id="panel1a-header"
	//         >
	//           <Typography>How can I do this?</Typography>
	//         </AccordionSummary>
	//         <AccordionDetails>
	//           <Typography>
	//             Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
	//             sit amet blandit leo lobortis eget.
	//           </Typography>
	//         </AccordionDetails>
	//       </Accordion>
	//     </SidePanel>
	//   </SidepanelContainer>
	// );

	return (
		<Wizard
			callback={callback}
			className={classes.root}
			loading={startedFinishWizard}
			shouldNotShowNavHeader
			stepperType='progress'
			steps={addOrganisationSteps}
			title={t('views.register.organisation.page.title')}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		addedOrganisation: state.details.addedOrganisation,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onAddOrganisation: (
			name,
			description,
			phoneNumber,
			email,
			address,
			billing,
			contact,
			invoice,
			images,
			bannerImages,
			legalName,
		) =>
			dispatch(
				actions.addOrganisation(
					name,
					description,
					phoneNumber,
					email,
					address,
					billing,
					contact,
					invoice,
					images,
					bannerImages,
					legalName,
				),
			),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCompany);

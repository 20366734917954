import { forwardRef, useEffect, useImperativeHandle } from 'react';

import { ajvResolver } from '@hookform/resolvers/ajv';
import { Stack } from '@mui/material';
import { JSONSchemaType } from 'ajv';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CountryAutocomplete, FormContainer, TextField } from '~components';
import { useFormContainerState } from '~components/dialogs/formContainerProvider';
import { Address } from '~interfaces';
import { FormWrapperRefProps } from '~interfaces/refProps';

import schema from './addressSchema.json';

interface AddressFormProps {
	defaultValue: Partial<Address>;
	onSubmit?: (value: Address) => void;
}

const AddressForm = forwardRef<FormWrapperRefProps, AddressFormProps>(
	({ defaultValue, onSubmit }, ref) => {
		const { t } = useTranslation();

		const { setDisabled } = useFormContainerState();

		const { control, getValues, formState, reset } = useForm<Address>({
			defaultValues: {
				country: null,
				city: '',
				countryCode: '',
				street: '',
				postalCode: '',
				...defaultValue
			},
			mode: 'onTouched',
			resolver: ajvResolver(schema as JSONSchemaType<any>),
		});

		useImperativeHandle(
			ref,
			() => ({
				onSubmit: () => onSubmit?.(getValues()),
			}),
			[onSubmit, getValues]
		);

		useEffect(() => {
			setDisabled?.(!(formState.isValid && formState.isDirty));
		}, [formState.isValid, formState.isDirty, setDisabled]);

		const getErrorText = (error: FieldError | undefined): string | undefined => {
			if (error?.type === 'minLength') {
				return t('ui.error.message.minLength');
			}

			return error?.message;
		}

		return (
			<FormContainer>
				<Controller
					name='street'
					control={control}
					render={({ field, fieldState }) => 
						<TextField
							{...field}
							required
							label={t('ui.label.street')}
							error={fieldState.error != null}
							helperText={getErrorText(fieldState.error)}
							slotProps={{
								htmlInput: {
									maxLength: schema.properties.street.maxLength,
								},
							}}
						/>
					}
				/>
				<Controller
					name='postalCode'
					control={control}
					render={({ field, fieldState }) => (
						<TextField
							{...field}
							required
							error={fieldState.error != null}
							helperText={getErrorText(fieldState.error)}
							label={t('ui.label.postalCode')}
							slotProps={{
								htmlInput: {
									maxLength: schema.properties.postalCode.maxLength,
								},
							}}
						/>
					)}
				/>
				<Stack spacing={1} direction='row' sx={{ justifyContent: 'space-between' }}>
					<Controller
						name='number'
						control={control}
						render={({ field, fieldState }) => (
							<TextField
								{...field}
								required
								error={fieldState.error != null}
								helperText={getErrorText(fieldState.error)}
								label={t('ui.nummer')}
								slotProps={{
									htmlInput: {
										maxLength: schema.properties.number.maxLength,
									},
								}}
								sx={{ flexGrow: 1 }}
							/>
						)}
					/>
					<Controller
						name='numberAddition'
						control={control}
						render={({ field, fieldState }) => (
							<TextField
								{...field}
								error={fieldState.error != null}
								helperText={getErrorText(fieldState.error)}
								label={t('ui.label.addition')}
								slotProps={{
									htmlInput: {
										maxLength: schema.properties.numberAddition.maxLength,
									},
								}}
								sx={{ flexGrow: 1 }}
							/>
						)}
					/>
				</Stack>
				<Controller
					name='city'
					control={control}
					render={({ field, fieldState }) => (
						<TextField
							required
							{...field}
							error={fieldState.error != null}
							helperText={getErrorText(fieldState.error)}
							label={t('ui.label.city')}
							slotProps={{
								htmlInput: {
									maxLength: schema.properties.city.maxLength,
								},
							}}
						/>
					)}
				/>

				<Controller
					name='countryCode'
					control={control}
					render={({ field }) => (
						<CountryAutocomplete
							required
							disableClearable
							value={field.value}
							onChange={(_, newValue) => field.onChange(newValue)}
						/>
					)}
				/>
			</FormContainer>
		);
	}
);

export default AddressForm;

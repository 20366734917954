import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	buttonIcon: {
		marginRight: theme.spacing(1),
	},
	detailTitle: {
		textDecoration: 'underline',
		...theme.typography.body3,
	},
	tableLink: {
		...theme.typography.link1,
	},
	tableText: {
		...theme.typography.body3,
	},
	instanceContent: {
		display: 'flex',
	},
	notAvailable: {
		color: theme.palette.error.main,
	},
}));

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
	},
	mainActions: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		marginTop: theme.spacing(3),
	},
	notice: {
		marginTop: theme.spacing(1),
	},
	deleteButton: {
		marginTop: theme.spacing(1),
	},
	roleCard: {
		padding: 16,
	},
	roleCardText: {
		...theme.typography.link1,
	},
	emailVerification: {
		margin: theme.spacing(1, 0),
	},
}));

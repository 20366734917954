import { Box, Button, ButtonGroup, Collapse, Divider, Stack } from '@mui/material';
import { useAtomValue } from 'jotai';
import { Outlet } from 'react-router-dom';

import pageDrawerButtonsAtom from './atoms/pageDrawerButtonsAtom';
import pageDrawerOpenAtom from './atoms/pageDrawerOpenAtom';
import PageHeader, { Page, PageHeaderProps } from './headers/pageHeader';
import PageNavBar from './navBar/pageNavBar';

interface PageLayoutProps {
	i18nTitleKey?: string;
	pages?: Page[];
	/**
	 * A list of search parameters to persist within the layout on a navigate
	 */
	persistedSearchParamKeys?: string[];
	slotProps?: {
		header?: PageHeaderProps;
	};
}

/**
 * A general page layout
 */
const PageLayout = ({
	i18nTitleKey,
	pages,
	persistedSearchParamKeys = [],
	...props
}: PageLayoutProps) => {
	const paddingX = { xs: 1, md: 3 };
	const paddingY = 2;

	const pageDrawerOpen = useAtomValue(pageDrawerOpenAtom);
	const pageDrawerButtons = useAtomValue(pageDrawerButtonsAtom);

	return (
		<Stack direction='column' spacing={3} sx={{ width: 1, height: 1 }}>
			<Box sx={{ px: paddingX, pt: paddingY }}>
				<PageHeader {...props.slotProps?.header} />
				<Box width={1}>
					{pages != null && pages.length > 0 && (
						<PageNavBar pages={pages} persistedSearchParamKeys={persistedSearchParamKeys} />
					)}
				</Box>
				<Divider orientation='horizontal' sx={{ m: 0, width: 1 }} />
			</Box>

			<Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
				<Box sx={{ flexGrow: 1, px: paddingX, pb: paddingY }}>
					<Outlet />
				</Box>

				<Collapse in={pageDrawerOpen}>
					<Divider orientation='horizontal' sx={{ width: 1 }} />
					<Box sx={{ display: 'flex', justifyContent: 'center', width: 1, p: 2 }}>
						<ButtonGroup>
							{pageDrawerButtons.map((el, i) =>
								<Button key={i} {...el} variant='text' />
							)}
						</ButtonGroup>
					</Box>
				</Collapse>
			</Box>
		</Stack>
	);
};

export default PageLayout;

import path from 'path';

import { PagedResults } from '~interfaces';
import { GeneralPagedParameters } from '~interfaces/requests';
import { AddressResponse, BaseReferenceResponse, PagedResponse } from '~interfaces/responses';
import { TopologyService } from '~services';

import Hub from '../interfaces/hub';

class HubsService extends TopologyService {
	public readonly path = 'hubs';

	constructor() {
		super('v1');
	}

	async getHubs({
		page = 1,
		pageSize = 10,
		...args
	}: GeneralPagedParameters<'id'>): Promise<PagedResults<Hub>> {
		const { data } = await this._client.get<PagedResponse<HubResponse>>(this.path, {
			params: {
				pageNumber: page,
				pageSize: pageSize,
				organisationId: args.organisationId,
			},
		});

		return this.mapPagedResponse(data, HubsService.fromResponse);
	}

	async getHub(id: string): Promise<Hub> {
		const { data } = await this._client.get<HubResponse>(path.join(this.path, id));

		return HubsService.fromResponse(data);
	}

	static fromResponse(data: HubResponse): Hub {
		const { id, name, latitude, longitude, organisationReference, address } = data;

		return {
			...HubsService.fromBaseReferenceResponse({
				id: id,
				name: name,
			}),
			organisation: HubsService.fromBaseReferenceResponse(organisationReference),
			location: {
				latitude: latitude,
				longitude: longitude,
			},
			address: {
				country: address.country,
				city: address.city,
				postalCode: address.postalCode,
				street: address.street,
				number: address.number ? Number(address.number) : undefined,
				numberAddition: address.numberAddition,
			}
		};
	}
}

interface HubResponse extends BaseReferenceResponse {
	latitude: number;
	longitude: number;
	address: AddressResponse;
	organisationReference: BaseReferenceResponse;
}

export default HubsService;

import { useEffect, useState } from 'react';

import { Card, CardContent, Box, MenuItem, Typography, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { DragAndDropImageCropper } from '~components';
import { useDebounce, useSnackbar } from '~hooks';

import { Tip, FormField, FormFieldLabel } from '../../../../../components';
import { useWizardFormField, useDebouncedWizardSave } from '../../../../../shared/hooks';
import {
	isArray,
	isObject,
	isEmptyString,
	isUndefined,
	isFullArray,
} from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';
import ItemHeader from '../ItemHeader';
import { useStyles } from '../stepsStyles';

const descriptionMaxLength = 1000;

interface ItemDetailsProps {
	className?: string;
	itemStep?: number;
	save?(...args: unknown[]): unknown;
	onFetchCategories?(...args: unknown[]): unknown;
	selectedDescription?: string;
	selectedCategory?: number;
	selectedOrganisation?: object;
	itemImages?: unknown[];
	categories?: {
		data?: unknown[];
		loading?: boolean;
		error?: object | string;
	};
	selectedName?: string;
	onValidateItemName?(...args: unknown[]): unknown;
	validateItemName?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const ItemDetails = (props: ItemDetailsProps) => {
	const {
		save,
		selectedCategory: savedCategory,
		itemImages,
		onFetchCategories,
		categories,
		selectedOrganisation: selectedOrg,
		itemStep,
		selectedName,
		selectedDescription,
		onValidateItemName,
		validateItemName,
	} = props;
	const { t } = useTranslation();
	const { enqueueErrorSnackbar } = useSnackbar();

	const classes = useStyles();

	const [selectedCategory, setSelectedCategory] = useState('');

	const name = useWizardFormField(selectedName || '', { required: true, maxLength: 25 });
	useDebouncedWizardSave('name', name.value, name.isValid, save, 400);
	const debouncedNameValue = useDebounce(name.value, 300);
	const [isUniqueItemName, setIsUniqueItemName] = useState(true);

	const documentContent = useWizardFormField(selectedDescription, {
		maxLength: descriptionMaxLength,
		minLength: 3,
	});
	useDebouncedWizardSave('name', name.value, name.isValid, save, 400);

	const { data: categoriesData, loading: categoriesLoading, error: categoriesError } = categories;
	const categoriesReady = isArray(categoriesData) && !categoriesLoading && !categoriesError;

	const {
		data: validateItemNameData,
		loading: validateItemNameLoading,
		error: validateItemNameError,
	} = validateItemName;

	useEffect(() => {
		if (!categoriesLoading && !isFullArray(categoriesData)) {
			onFetchCategories();
		}
	}, [categories]);

	useEffect(() => {
		if (!isEmptyString(name.value) && isObject(selectedOrg)) {
			onValidateItemName(debouncedNameValue, selectedOrg.id);
		}
	}, [debouncedNameValue, selectedOrg]);

	useEffect(() => {
		if (isObject(validateItemNameData)) {
			setIsUniqueItemName(validateItemNameData.allowed);
		}
	}, [validateItemNameData, validateItemNameLoading, validateItemNameError]);

	useEffect(() => {
		if (isObject(validateItemNameData)) {
			save({ validItemName: validateItemNameData.allowed });
		}
	}, [validateItemNameData]);

	useEffect(() => {
		if (!isUndefined(savedCategory)) {
			setSelectedCategory(savedCategory);
		}
	}, [savedCategory]);

	const handleCategories = (event) => {
		event.preventDefault();
		const value = event.target.value;
		setSelectedCategory(value);
		save({ updatedCategory: value });
		const categoryIdsArray = categoriesData.map((category) => category.id);
		const index = categoryIdsArray.indexOf(value);
		save({ updatedCategoryObject: categoriesData[index] });
	};

	const handleUploadProfileImage = (image) => {
		if (itemStep === 1) {
			console.log(image);
			save({ updatedItemImages: image });
		}
	};

	useEffect(() => {
		save({
			content: {
				value: documentContent.value,
				valid: documentContent.isValid,
			},
		});
	}, [documentContent.value, documentContent.isValid]);

	console.log(selectedOrg)

	return (
		<Card>
			<CardContent className={classes.mainCard}>
				<ItemHeader
					header={t('views.addItem.steps.itemDetails')}
					padding={3}
					subheader={t('views.addItem.itemDetails.subheader.text')}
					variant='h3'
					variantText='body2'
				/>
				<form>
					<div className={classes.itemSpacing}>
						<FormFieldLabel
							className={classes.inputLabelSpacing}
							info={t('views.addItem.bookingType.categoryInfo')}
							label={t('views.addItem.chooseItemCategory')}
							variant='h4'
						/>
						<TextField
							InputLabelProps={{
								shrink: true,
							}}
							SelectProps={{ value: selectedCategory || '' }}
							fullWidth={true}
							label={t('ui.label.category')}
							onChange={handleCategories}
							placeholder='category'
							required
							select
							size='medium'
							variant='outlined'
						>
							{categoriesReady ?
								categoriesData.map((tStatus) => (
									<MenuItem key={tStatus.id} value={tStatus.id}>
										{tStatus.name}
									</MenuItem>
								))
							:	[]}
						</TextField>
					</div>
					<div className={classes.itemSpacing}>
						<FormFieldLabel
							className={classes.inputLabelSpacing}
							info={t('views.addItem.bookingType.nameInfo')}
							label={t('views.addItem.itemGroupName')}
							variant='h4'
						/>
						<FormField
							extraValidHelperText={t('views.addItem.itemDetails.itemName')}
							hideCharacterCounter={!name.hasFocus}
							isExtraValid={isUniqueItemName}
							label={t('ui.label.name')}
							maxLength={25}
							name='name'
							required
							variable={name}
						/>
						<Tip
							arrowDirection='top'
							className={classes.tip}
							message={t('views.addItem.itemDetails.subsection.name.tip')}
						/>
					</div>
					<div className={classes.itemSpacing}>
						<Box pb={1}>
							<Box display='flex' justifyContent='space-between'>
								<Typography variant='h5'>
									{t('views.addItem.itemDetails.subsection.description.title')}
								</Typography>
								<Typography className={classes.counterText}>
									{documentContent.value.length}/{descriptionMaxLength} {t('ui.characters')}
								</Typography>
							</Box>
							<Typography>
								{t('views.addItem.itemDetails.subsection.description.subtitle')}
							</Typography>
						</Box>
						<FormField
							hideCharacterCounter
							maxLength={descriptionMaxLength}
							multiline
							name={'description'}
							placeholder={t('views.addItem.itemDetails.subsection.description.placeholder')}
							required
							rows={4}
							variable={documentContent}
						/>
					</div>

					<div className={classes.itemSpacing}>
						<Box pb={1}>
							<Typography variant='h5'>
								{t('views.addItem.itemDetails.subsection.image.title')}
								<sup>*</sup>
							</Typography>
						</Box>

						<DragAndDropImageCropper
							src={itemImages != null ? itemImages[0]?.uri : null}
							slotProps={{
								dialog: {
									title: t('views.addItem.setItemImage'),
									subTitle: t('views.addItem.imageCroper.description'),
								},
								uploader: {
									maxFileSizeInBytes: 1024000,
									accept: 'image/png, image/jpeg',
									onError: (errors) => enqueueErrorSnackbar(errors[0]?.message),
								},
								imageCropper: {
									aspect: 1.5,
								},
							}}
							onChange={(value) =>
								handleUploadProfileImage(
									value ?
										[
											{
												uri: value.uri,
												name: value.file.name,
												file: value.file,
											},
										]
									:	[],
								)
							}
						/>
						<Tip
							arrowDirection='top'
							className={classes.tip}
							message={t('views.addItem.itemDetails.subsection.image.tip')}
						/>
					</div>
				</form>
			</CardContent>
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		categories: state.list.categories,
		validateItemName: state.details.validateItemName,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchCategories: (sortBy, orderDescending, name) =>
			dispatch(actions.fetchCategories(sortBy, orderDescending, name)),
		onValidateItemName: (name, id) => dispatch(actions.validateItemName(name, id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemDetails);

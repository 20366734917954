import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useStyles } from './style';
import { Table, Label } from '../../../../../../components';
import { isObject, decimalAmount, getFinanceTableHeaders } from '../../../../../../shared/utility';

interface BillingPeriodProps {
	data?: object;
	id?: string;
	date?: string;
	onFetchBusinessRevenue?(...args: unknown[]): unknown;
	loading?: boolean;
}

const BillingPeriod = (props: BillingPeriodProps) => {
	const classes = useStyles();
	const { data, loading } = props;
	const { t } = useTranslation();

	const tableHeader = getFinanceTableHeaders();

	const statusColor = {
		open: 'default',
		paid: 'success',
	};

	const statusText = {
		open: t('ui.open'),
		paid: t('ui.label.paid'),
	};

	const tableBody =
		isObject(data) && !loading ?
			[
				[
					{
						content: (
							<Box width='22.628014366341713vw'>
								<Typography className={classes.link}>{data.title}</Typography>
							</Box>
						),
					},
					{ content: decimalAmount(data.amount.subtotal, data.amount) },
					{ content: decimalAmount(data.amount.discount, data.amount) },
					{ content: decimalAmount(data.amount.subtotal - data.amount.discount, data.amount) },
					{ content: decimalAmount(data.amount.priceVat, data.amount) },
					{ content: decimalAmount(data.amount.refund, data.amount) },
					{ content: decimalAmount(data.amount.totalPrice, data.amount) },
					{ content: decimalAmount(data.open, data.amount) },
					{
						content: (
							<Box>
								<Label type={statusColor[data.status]}>{statusText[data.status]}</Label>
							</Box>
						),
					},
				],
			]
		:	Array(1)
				.fill(Array(tableHeader.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })));

	const titleWithInformation = {
		header: t('ui.label.personalBilling'),
	};

	return (
		<Table
			body={tableBody}
			cellStyle={classes.cellStyle}
			header={tableHeader}
			isNotPaginate={true}
			loading={loading}
			titleWithInformation={titleWithInformation}
			withoutPages={true}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		businessRevenue: state.details.businessRevenue,
	};
};

export default connect(mapStateToProps, null)(BillingPeriod);

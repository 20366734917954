import { useEffect, useState } from 'react';

import { Box, Typography, Divider } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { TextDialog } from '../../../../components';
import { useErrorAcceptedRejected } from '../../../../shared/hooks';
import { isObject, isEmptyObject, isFullArray, instanceName } from '../../../../shared/utility';
import * as actions from '../../../../store/actions';

interface ShareDialogProps {
	itemInstance?: object;
	onPatchInstance?(...args: unknown[]): unknown;
	setUpdatedInstanceIndex?(...args: unknown[]): unknown;
	openPublicInstanceDialog?: boolean;
	openUnshareDialog?: boolean;
	openMassShareDialog?: boolean;
	onResetState?(...args: unknown[]): unknown;
	openMassUnshareDialog?: boolean;
	resetPublickShareDialog?(...args: unknown[]): unknown;
	updateInstance?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	loading?: boolean;
	massSelection?: unknown[];
	onUpdatePublicShare?(...args: unknown[]): unknown;
	updatePublicShare?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const ShareDialog = (props: ShareDialogProps) => {
	const {
		itemInstance,
		setUpdatedInstanceIndex,
		onPatchInstance,

		updateInstance,
		openPublicInstanceDialog,
		openUnshareDialog,
		openMassShareDialog,
		openMassUnshareDialog,
		massSelection,
		onUpdatePublicShare,
		updatePublicShare,
		resetPublickShareDialog,
		onResetState,
	} = props;
	const { t } = useTranslation();

	const { enqueueSnackbar } = useSnackbar();

	const [loading, setLoading] = useState(false);
	const selectedInstances =
		isObject(itemInstance) &&
		`${isObject(itemInstance) && itemInstance.itemReference.name}, ${instanceName(itemInstance)}`;

	const massSelectionInstances =
		isFullArray(massSelection) &&
		massSelection.map((item) => ({
			name: item.item.name,
			instances: item.selectedInstances.map((instance) => instanceName(instance)),
		}));

	const publicItemsSharingDialog = [
		{
			name: 'share',
			open: openPublicInstanceDialog,
			title: t('views.tableResults.textDialog.publicItems.title'),
			description: t('views.tableResults.textDialog.publicItems.description'),
			instances: selectedInstances,
		},
		{
			name: 'unshare',
			open: openUnshareDialog,
			title: t('views.tableResults.shareDialog.unshare'),
			description: t('views.tableResults.shareDialog.description'),
			instances: selectedInstances,
		},
		{
			name: 'massShare',
			open: openMassShareDialog,
			title: t('views.tableResults.textDialog.publicItems.title'),
			description: t('views.tableResults.textDialog.publicItems.description'),
			instances:
				isFullArray(massSelectionInstances) &&
				massSelectionInstances.map((item) => `${item.name}, ${item.instances.join(', ')} `),
		},
		{
			name: 'massUnshare',
			open: openMassUnshareDialog,
			title: t('views.tableResults.shareDialog.unshare'),
			description: t('views.tableResults.shareDialog.description'),
			instances:
				isFullArray(massSelectionInstances) &&
				massSelectionInstances.map((item) => `${item.name}, ${item.instances.join(', ')} `),
		},
	];

	const publicShareMessage = useErrorAcceptedRejected({
		value: updatePublicShare,
		message: {
			positive: `${t('views.tableResults.errorMessage')}`,
			negative: t('views.items.shareDialog.negative'),
		},
	});

	const massShareBody =
		isFullArray(massSelection) &&
		massSelection.map((item) => ({
			itemId: item.item.id,
			instanceIds: item.selectedInstances.map((instances) => instances.id),
		}));

	useEffect(() => {
		if (updateInstance.loading || updatePublicShare.loading) {
			setLoading(true);
		} else if (
			(!updateInstance.loading && isObject(updateInstance.data)) ||
			(!updatePublicShare.loading && isObject(updatePublicShare.data))
		) {
			setLoading(false);
		}
	}, [updateInstance.loading, updatePublicShare.loading]);

	useEffect(() => {
		if (isObject(updateInstance?.data)) {
			resetPublickShareDialog();
			enqueueSnackbar(t('views.tableResults.errorMessage'), { variant: 'success' });
			onResetState('patchInstance');
		} else if (isObject(updateInstance?.error)) {
			enqueueSnackbar(t('views.items.shareDialog.negative'), { variant: 'error' });
			resetPublickShareDialog();
			onResetState('patchInstance');
		}
	}, [updateInstance]);

	const handlePublicInstance = () => {
		resetPublickShareDialog();
	};

	const handleConfirmPublicInstance = () => {
		if (openPublicInstanceDialog || openUnshareDialog) {
			const isPublicInstance = !itemInstance.isPublic;
			const body = { isPublic: isPublicInstance };
			if (isObject(itemInstance) && !isEmptyObject(body)) {
				onPatchInstance(itemInstance.itemReference.id, itemInstance.id, body);
				setUpdatedInstanceIndex(itemInstance.id);
			}
		} else if (openMassShareDialog) {
			onUpdatePublicShare(true, massShareBody);
			setUpdatedInstanceIndex(true);
		} else if (openMassUnshareDialog) {
			onUpdatePublicShare(false, massShareBody);
			setUpdatedInstanceIndex(true);
		}
		publicShareMessage.setStartAction(true);
	};

	return (
		<>
			{publicItemsSharingDialog.map((item) => (
				<TextDialog
					content={
						<Box>
							<Box pb={2}>
								<Typography variant='body2'>{item.description}</Typography>
							</Box>
							<Box pb={2} pt={2}>
								<Typography variant='body1'>{item.instances}</Typography>
							</Box>
							<Divider />
						</Box>
					}
					disabled={false}
					handleClose={handlePublicInstance}
					handleConfirm={handleConfirmPublicInstance}
					isLoading={true}
					isText={true}
					key={item.name}
					loading={loading}
					open={item.open}
					title={item.title}
				/>
			))}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		updateInstance: state.details.patchInstance,
		updatePublicShare: state.details.updatePublicShare,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onPatchInstance: (itemId, instanceId, properties) =>
			dispatch(actions.patchInstance(itemId, instanceId, properties)),
		onUpdatePublicShare: (isPublic, items) => dispatch(actions.updatePublicShare(isPublic, items)),
		onResetState: (identifier) => dispatch(actions.resetState(identifier)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareDialog);

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {},
	content: {
		padding: 0,
	},
	inner: {
		minWidth: 700,
	},
	nameCell: {
		display: 'flex',
		alignItems: 'center',
	},
	avatar: {
		height: 42,
		width: 42,
		marginRight: theme.spacing(1),
	},
	actions: {
		padding: theme.spacing(1),
		justifyContent: 'flex-end',
	},
	buttonIcon: {
		marginRight: theme.spacing(0.5),
	},
	itemGroup: {
		...theme.typography.link1,
	},
	cellStyle: {
		whiteSpace: 'nowrap',
	},
	tooltipText: {
		...theme.typography.body3,
	},
	outerTooltipText: {
		textDecoration: 'underline',
		...theme.typography.body3,
	},
}));

import { useRef, useState } from 'react';

import { Grid } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import useSWR, { mutate as mutateGlobal } from 'swr';
import useSWRMutation from 'swr/mutation';

import { AddressCard, AddressForm, ContactInfoCard, FormDialog } from '~components';
import { useAuthorize } from '~features/authentication';
import {
	Organisation,
	OrganisationBillingCard,
	OrganisationDevicesCard,
	OrganisationGeneralInformationCard,
	OrganisationsService,
} from '~features/organisations';
import { useSnackbar } from '~hooks';
import { MutationRefProps } from '~interfaces/refProps';

import EditBillingAndAdminInfo from './BillingAndAdminInfo/EditBillingAndAdminInfo';
import EditContactInfo from './ContactInfo/EditContactInfo';
import DevicesDialog from './Devices/DevicesDialog';
import EditGeneralInfo from './GeneralInfo/EditGeneralInfo';
import OtherActions from './OtherActions';
import { useStyles } from './style';
import Subscription from './Subscription';
import SummaryLoading from './SummaryLoading';

const service = new OrganisationsService();

type UserAction = | {
	type: 'editGeneral';
	id: string;
} | {
	type: 'editContactInfo' | 'editAddress' | 'editBilling' | 'editDevices';
	id?: never;
};

interface SummaryProps {
	organisationId: string;
	className?: string;
	verified?: boolean;
}

const Summary = ({ className, organisationId, ...props }: SummaryProps) => {
	const { t } = useTranslation();
	const { isSuperAdmin, authorizeForOrganisation } = useAuthorize();
	const { enqueueSuccessSnackbar, enqueueAxiosErrorSnackbar } = useSnackbar();

	const [userAction, setUserAction] = useState<UserAction | null>(null);

	const generalInformationCardRef = useRef<MutationRefProps>();

	const { data: organisation, isLoading, mutate } = useSWR(
		organisationId ? [service.basePath, organisationId, 'unmapped'] : null,
		([_, arg]) => service.getOrganisationByIdUnmapped(arg)
	)

	const { trigger, isMutating } = useSWRMutation(
		organisationId ? [service.basePath, organisationId] : null,
		([_, id], { arg }: { arg: Partial<Organisation>}) => service.patchOrganisation(id, arg),
		{
			onSuccess: () => {
				enqueueSuccessSnackbar(`${t('ui.successfully')} ${t('ui.updated')} ${organisation.name}`);
				setUserAction(null);
				mutate();
			},
			onError: (error) => enqueueAxiosErrorSnackbar(error),
		}
	);
	
	const classes = useStyles();

	if (isLoading || !organisation) {
		return <SummaryLoading />;
	}

	const gridProps = { item: true, xs: 12, md: 6, lg: 6, xl: 4 };

	return (
		<>
			<Grid className={clsx(classes.root, className)} container spacing={3}>
				<Grid {...gridProps}>
					<OrganisationGeneralInformationCard
						ref={generalInformationCardRef}
						organisationId={organisationId}
						onEdit={(id) => setUserAction({ type: 'editGeneral', id: id })}
					/>
				</Grid>
				<Grid {...gridProps}>
					<ContactInfoCard
						data={organisation.contactPerson}
						enableEdit={organisation.isVerified && authorizeForOrganisation(organisation.id?.toString())}
						onEdit={() => setUserAction({ type: 'editContactInfo' })}
					/>
				</Grid>
				<Grid {...gridProps}>
					<OrganisationBillingCard
						organisationId={organisation.id.toString()}
						onEditClick={() => setUserAction({ type: 'editBilling' })}
						onChange={mutate}
					/>
				</Grid>
				<Grid {...gridProps}>
					<AddressCard
						enableEdit={authorizeForOrganisation(organisationId)}
						value={organisation.address}
						onEdit={() => setUserAction({ type: 'editAddress' })}
					/>
				</Grid>
				{authorizeForOrganisation(organisationId) &&
					<>
						<Grid {...gridProps}>
							<Subscription organisation={organisation} onChange={mutate} />
						</Grid>
						<Grid {...gridProps}>
							<OrganisationDevicesCard
								organisationId={organisationId}
								onEdit={() => setUserAction({ type: 'editDevices' })}
							/>
						</Grid>
					</>
				}
				<Grid {...gridProps}>
					{isSuperAdmin() ?
						<OtherActions organisation={organisation} />
					:	null}
				</Grid>
			</Grid>

			<EditGeneralInfo
				onClose={(updated) => {
					setUserAction(null);
					if (updated) {
						generalInformationCardRef.current?.mutate();
					}
				}}
				open={userAction?.type === 'editGeneral'}
				organisation={organisation}
			/>

			<EditBillingAndAdminInfo
				onClose={(updated) => {
					setUserAction(null);
					if (updated) {
						mutate();
						generalInformationCardRef.current?.mutate();
					}
				}}
				open={userAction?.type === 'editBilling'}
				organisation={organisation}
			/>

			<EditContactInfo
				onClose={(updated) => {
					setUserAction(null);
					if (updated) {
						mutate();
						generalInformationCardRef.current?.mutate();
					}
				}}
				open={userAction?.type === 'editContactInfo'}
				organisation={organisation}
			/>

			<DevicesDialog
				organisationId={organisationId}
				open={userAction?.type === 'editDevices'}
				hasDevicesAccess={organisation?.features.includes('devices')}
				handleClose={(updated) => {
					setUserAction(null);
					if (updated) {
						mutateGlobal([service.basePath, organisationId])
						mutate();
						generalInformationCardRef.current?.mutate();
					}
				}}
			/>

			<FormDialog
				title={t('views.organisationDetail.summary.editAddress')}
				open={userAction?.type === 'editAddress'}
				loading={isMutating}
				onClose={() => setUserAction(null)}
				fullWidth
				maxWidth='sm'
			>
				<AddressForm
					defaultValue={{
						...organisation.address,
						addition: organisation.address?.numberAddition,
						country: organisation.address?.countryCode,
					}}
					onSubmit={async (data) => await trigger({ address: data })}
				/>
			</FormDialog>
		</>
	);
};

export default Summary;

import {
	ChangeEvent,
	DragEvent,
	forwardRef,
	MouseEvent,
	PropsWithChildren,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from 'react';

import { Close as CloseIcon, FileUpload as FileUploadIcon } from '@mui/icons-material';
import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useFilePicker } from '~hooks';
import i18n from '~lib/i18n';
import { formatBytes } from '~utils';

/**
 * TODO: this is still to focused on image uploading. Rebuild to fit
 * general files
 */
interface FileInput {
	file: File | null;
	uri: string | null;
}

export type DragAndDropFilesUploaderProps = {
	title?: string;
	readonly?: boolean;
	maxImageSizeInBytes?: number;
	accept?: string;
	multiple?: boolean; // allow selecting multiple files
	/**
	 *
	 * @param file The image as file
	 * @param uri
	 * @returns
	 */
	onChange?: (images: FileInput[]) => void;
	onError?: (errors: Error[]) => void;
	clearable?: boolean;
	editable?: boolean;
} & PropsWithChildren;

export interface DragAndDropFilesUploaderRef {
	openFilePicker: () => void;
}

/**
 * Work in progress!!!
 * 
 */
const DragAndDropFilesUploader = forwardRef<
	DragAndDropFilesUploaderRef,
	DragAndDropFilesUploaderProps
>(
	(
		{
			children,
			maxImageSizeInBytes = 1024000,
			clearable = true,
			editable = true,
			multiple = false,
			readonly = false,
			title,
			...props
		},
		ref,
	) => {
		const { t } = useTranslation();

		const filePicker = useFilePicker();
		const [hovered, setHovered] = useState(false);

		useEffect(() => {
			props.onChange?.(filePicker.files);
		}, [filePicker.files]);

		useImperativeHandle(ref, () => ({
			openFilePicker: filePicker.openFilePicker,
		}));

		const OverlayContainer = ({ children }: PropsWithChildren) => (
			<Box
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					// We block all the handlers in the child components
					// We are switching between a click on the whole components
					// or the subcomponents
					pointerEvents: 'none',
				}}
			>
				{children}
			</Box>
		);

		const AddOverlay = () => (
			<OverlayContainer>
				<Stack
					color='white'
					onClick={filePicker.openFilePicker}
					spacing={1}
					sx={{
						alignItems: 'center',
					}}
				>
					<FileUploadIcon />
					<Typography variant='h5' color='white'>
						{t('upload file')}
					</Typography>
					<Typography variant='body2' color='white'>
						{`${t('ui.maxFileSize')}: ${formatBytes(maxImageSizeInBytes, 1)}`}
					</Typography>
				</Stack>
			</OverlayContainer>
		);

		if (readonly) {
			return (
				<>Readonly</>
			);
		}

		const handleClick = (e) => {
			if (props.src != null) {
				return;
			}

			e.preventDefault();
			filePicker.openFilePicker();
		};

		return (
			<div
				onClick={handleClick}
				onDrop={filePicker.dropFiles}
				onDragOver={(event) => event.preventDefault()}
				onMouseOver={() => setHovered(true)}
				onMouseLeave={() => setHovered(false)}
			>
				{props.src != null ?
					<Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center', width: 1 }}>
						{editable && (
							<Stack direction='row' sx={{ position: 'absolute', top: 0, right: 0 }}>
								<IconButton onClick={filePicker.openFilePicker}>
									<FileUploadIcon />
								</IconButton>
								{clearable && (
									<IconButton onClick={() => props.onChange?.([])}>
										<CloseIcon />
									</IconButton>
								)}
							</Stack>
						)}
					</Box>
				:	<DragAndDropUploaderBox
						title={title}
						maxImageSizeInBytes={maxImageSizeInBytes}
						error={filePicker.errors[0]}
					/>
				}
			</div>
		);
	},
);

interface DragAndDropFilesUploaderBox {
	title?: string;
	maxImageSizeInBytes: number;
	error?: Error | null;
}

const DragAndDropUploaderBox = ({
	maxImageSizeInBytes = 1024000,
	title = i18n.t('ui.uploadImage'),
	...props
}: DragAndDropFilesUploaderBox) => {
	const { t } = useTranslation();
	const theme = useTheme();

	return (
		<Stack
			direction='column'
			spacing={2}
			sx={{
				p: 3,
				alignItems: 'center',
				border: `1px dashed ${theme.palette.grey[200]}`,
				'&:hover': {
					borderColor: theme.palette.grey[400],
					cursor: 'pointer',
				},
			}}
		>
			<FileUploadIcon fontSize='large' sx={{ color: theme.palette.grey[400] }} />
			<Typography variant='h5'>{title}</Typography>
			<Typography variant='body2' color={props.error != null ? 'error' : undefined}>
				{`${t('ui.maxFileSize')}: ${formatBytes(maxImageSizeInBytes, 1)}`}
			</Typography>
			{props.error && (
				<Typography variant='body2' color={props.error != null ? 'error' : undefined}>
					{props.error.message}
				</Typography>
			)}
		</Stack>
	);
};

export default DragAndDropFilesUploader;

import { Box, Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useStyles } from '../style';

interface CommentProps {
	className?: string;
	contractData: object;
}

const Comment = (props: CommentProps) => {
	const { contractData } = props;
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<Card>
			<CardHeader title={t('ui.label.comment')} />
			<Divider />
			<CardContent className={classes.content}>
				<Box className={classes.commentContainer}>
					{contractData.comment ?
						<Typography variant='body2'>{contractData.comment}</Typography>
					:	<Typography className={classes.emptyComment} variant='body2'>
							{t('views.bookingDetail.summary.userComment.empty')}
						</Typography>
					}
				</Box>
			</CardContent>
		</Card>
	);
};

export default Comment;

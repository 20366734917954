import { useEffect, useState, useCallback, Fragment } from 'react';

import { Box, Drawer, Typography, Divider, CircularProgress, Button } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { userInfoAtom } from '~atoms';
import { useAuthorize } from '~features/authentication';

import BookingType from './DrawerElements/BookingType';
import ButtonCard from './DrawerElements/ButtonCard';
import CommentCard from './DrawerElements/CommentCard';
import DateTimeCard from './DrawerElements/DateTimeCard';
import Dialogs from './DrawerElements/Dialogs';
import Discount from './DrawerElements/Discount';
import ItemCard from './DrawerElements/ItemCard';
import LicensePlate from './DrawerElements/LicensePlate';
import Price from './DrawerElements/Price';
import UsageCard from './DrawerElements/UsageCard';
import UserCard from './DrawerElements/UserCard';
import { useStyles } from './style';
import { LoadingBar, StyledButton } from '../../../components';
import { licensePlateLength } from '../../../constantsOld';
import {
	getDateRoundedToDuration,
	differentDates,
	duration,
	durationToMinutes,
	areDatesEqual,
} from '../../../shared/datetime';
import { useWizardFormField } from '../../../shared/hooks';
import {
	isUndefined,
	isObject,
	isNumber,
	isNull,
	isEmptyString,
	isFullString,
	castMinutesToTimeString,
	isBoolean,
} from '../../../shared/utility';
import * as actions from '../../../store/actions';
import CancelUnabailabilitysDialog from '../../Actions/Dialogs/CancelUnabailabilitysDialog/CancelUnabailabilitysDialog';
import StopUnabailabilitysDialog from '../../Actions/Dialogs/StopUnabailabilitysDialog/StopUnabailabilitysDialog';

interface SideDrawerProps {
	open?: boolean;
	onUpdateCooldown?(...args: unknown[]): unknown;
	onClose?(...args: unknown[]): unknown;
	presetData?: object;
	setPresetBookingData?(...args: unknown[]): unknown;
	onPlanboardBooking?(...args: unknown[]): unknown;
	onResetState?(...args: unknown[]): unknown;
	onAddPlanboardBooking?(...args: unknown[]): unknown;
	onPatchUpdateBooking?(...args: unknown[]): unknown;
	onResetStateCondition?(...args: unknown[]): unknown;
	onUpdateListState?(...args: unknown[]): unknown;
	isUnavailability?: boolean;
	onAddUnavailability?(...args: unknown[]): unknown;
	date?: object;
	onUpdatePagedState?(...args: unknown[]): unknown;
	onUpdateDiscount?(...args: unknown[]): unknown;
	onUpdateDetailsState?(...args: unknown[]): unknown;
	isDetails?: boolean;
	onUpdateUnavailability?(...args: unknown[]): unknown;
	updateUnavailability?: any;
	calculatedPrice?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	planboardBooking?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	fetchInstance?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	addedPlanboardBooking?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	updatedBookingDetails?: {
		success?: boolean;
		loading?: boolean;
		error?: object | string;
	};
	itemInstanceAvailability?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	bookingAvailability?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	addUnavailability?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	fetchUnavailability?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	maintenanceAvailability?: {
		data?: object | boolean;
		loading?: boolean;
		error?: object | string;
	};
	cancelUnavailabilities?: {
		data?: object | boolean;
		loading?: boolean;
		error?: object | string;
	};
	stopUnavailability?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	validatedBookingCategory?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	validatedEditedBookingCategory?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	validateUnavailability?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	updateDiscount?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	fetchBooking?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	bookingPrice?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	updateCooldown?: {
		success?: boolean;
		loading?: boolean;
		error?: object | string;
	};
}

const SideDrawer = (props: SideDrawerProps) => {
	const {
		open,
		onClose,

		presetData,
		setPresetBookingData,
		planboardBooking,
		onResetState,

		fetchInstance,
		addedPlanboardBooking,
		updatedBookingDetails,
		onAddPlanboardBooking,
		onPatchUpdateBooking,
		onResetStateCondition,
		onUpdateListState,
		itemInstanceAvailability,
		bookingAvailability,
		isUnavailability,
		onAddUnavailability,
		addUnavailability,
		isDetails,
		onUpdateUnavailability,
		updateUnavailability,
		fetchUnavailability,
		date,
		maintenanceAvailability,
		onUpdatePagedState,
		cancelUnavailabilities,
		stopUnavailability: stopMaintenance,
		calculatedPrice,
		validatedBookingCategory,
		validatedEditedBookingCategory,
		validateUnavailability,
		onUpdateDiscount,
		updateDiscount,
		fetchBooking,
		onUpdateDetailsState,
		bookingPrice,
		onUpdateCooldown,
		updateCooldown,
	} = props;
	const { t } = useTranslation();
	const { authorizeForOrganisation, isSuperAdmin } = useAuthorize();
	const userInfo = useAtomValue(userInfoAtom);

	const classes = useStyles();

	const { enqueueSnackbar } = useSnackbar();

	const { data: bookingData, loading: bookingLoading } = planboardBooking;

	const { data: fetchInstanceData } = fetchInstance;

	const {
		data: addedBookingData,
		loading: addedBookingLoading,
		error: addedBookingError,
	} = addedPlanboardBooking;

	const { data: addUnavailabilityData, loading: addUnavailabilityLoading } = addUnavailability;

	const { data: updateUnavailabilityData } = updateUnavailability;

	const { data: unavailabilityData } = fetchUnavailability;

	const {
		success: updatedBookingSuccess,
		loading: updatedBookingLoading,
		error: updatedBookingError,
	} = updatedBookingDetails;

	const { loading: instanceAvailabilityLoading, error: instanceAvailabilityError } =
		itemInstanceAvailability;

	const { loading: maintenanceAvailabilityLoading, error: maintenanceAvailabilityError } =
		maintenanceAvailability;

	const { data: validateUnavailabilityData } = validateUnavailability;

	const { error: calculatedPriceError } = calculatedPrice;

	const { error: bookingAvailabilityError } = bookingAvailability;

	const { data: cancelUnavailabilityData } = cancelUnavailabilities;

	const { data: stopUnavailabilityData } = stopMaintenance;

	const { data: categoryCheckData } = validatedBookingCategory;

	const { data: bookingCategoryCheckData } = validatedEditedBookingCategory;

	const { data: updateDiscountData, loading: updateDiscountLoading } = updateDiscount;

	const { data: fetchBookingData } = fetchBooking;

	const { error: bookingPriceError } = bookingPrice;

	const { success: updateCooldownSuccess, loading: updateCooldownLoading } = updateCooldown;

	const [stopCarBooking, setStopCarBooking] = useState(false);
	const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
	const [stopUnavailability, setStopUnavailability] = useState(false);
	const [openCancelUnavailability, setOpenCancelUnavailability] = useState(false);

	const [selectType, setSelectType] = useState(() => ['booking']);

	const [selectedItem, setSelectedItem] = useState(null);

	const [selectedUser, setSelectedUser] = useState(null);

	const [currentError, setCurrentError] = useState(null);

	const [selectedStartDate, setSelectedStartDate] = useState(null);
	const [selectedEndDate, setSelectedEndDate] = useState(null);

	const [isExternalAdminError, setIsExternalAdminError] = useState(false);

	const [hasChangedDatesFromPresetData, setHasChangedDatesFromPresetData] = useState(false);

	const [hasChangedPresetData, setHasChangedPresetData] = useState(false);

	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');

	const [comment, setComment] = useState('');
	const [commentLength, setCommentLength] = useState(0);
	const [commentRequired, setCommentRequired] = useState(false);

	const [discount, setDiscount] = useState(0);
	const [applyingDiscount, setApplyingDiscount] = useState(false);
	const [showDiscount, setShowDiscount] = useState(true);

	const [hasChangedItem, setHasChangedItem] = useState(false);

	const [policies, setPolicies] = useState(null);

	const [price, setPrice] = useState(null);

	const [availableForMaintenance, setAvailableForMaintenance] = useState(true);

	const [nowSwitch, setNowSwitch] = useState(false);

	const [fetchBookingPrice, setFetchBookingPrice] = useState(false);

	const licensePlate = useWizardFormField('', {
		required: policies?.requiredParkingLicensePlateNumber === 'required',
		maxLength: licensePlateLength,
	});

	const discountChanged =
		isObject(bookingData) &&
		isObject(bookingData.priceDetails) &&
		(isNumber(bookingData.priceDetails.discount) ?
			bookingData.priceDetails.discount !== discount ||
			(bookingData.priceDetails.discount > 0 && !applyingDiscount)
		:	discount > 0);

	const isProvider = userInfo.organisation.id == selectedItem?.organisationReference.id;

	const discountAllowed = isObject(bookingData) && bookingData?.isAllowedDiscount;

	const availability =
		isObject(instanceAvailabilityError) ||
		isObject(maintenanceAvailabilityError) ||
		isObject(bookingAvailabilityError);

	const isRequiredLicensePlateNumber =
		policies?.requiredParkingLicensePlateNumber === 'required' && isEmptyString(licensePlate.value);

	const handleBookingCategory = useCallback(() => {
		let isAllowed = false;
		if (isObject(categoryCheckData) || isObject(bookingCategoryCheckData)) {
			isAllowed = !(bookingCategoryCheckData?.allowed || categoryCheckData?.allowed);
		}
		return isAllowed;
	}, [categoryCheckData, bookingCategoryCheckData]);

	const isDoubleBookingInCategoryError = handleBookingCategory();

	const durationErrors = useCallback(() => {
		if (
			selectType === 'booking' &&
			durationToMinutes(duration(selectedStartDate, selectedEndDate)) <
				policies?.minBookingDurationMinutes
		) {
			return `${t('views.planboard.addBooking.error.message.durationTooShort')} ${castMinutesToTimeString(policies.minBookingDurationMinutes, false)}`;
		} else if (
			selectType === 'booking' &&
			durationToMinutes(duration(selectedStartDate, selectedEndDate)) >
				policies?.maxBookingDurationMinutes
		) {
			return `${t('views.planboard.addBooking.error.message.durationTooLong')} ${castMinutesToTimeString(policies.maxBookingDurationMinutes, false)}`;
		} else if (isObject(calculatedPriceError)) {
			return calculatedPriceError.message;
		} else if (isObject(instanceAvailabilityError)) {
			return instanceAvailabilityError.message;
		} else if (isObject(bookingAvailabilityError)) {
			return bookingAvailabilityError.message;
		} else if (isExternalAdminError) {
			return t('views.planboard.addBooking.error.message.requiresHostConfirmation');
		} else if (isObject(maintenanceAvailabilityError)) {
			return maintenanceAvailabilityError.message;
		} else if (
			!validateUnavailabilityData?.allowed &&
			presetData?.unavailabilityId &&
			isBoolean(validateUnavailabilityData?.allowed)
		) {
			return t('views.planboard.addBooking.error.message.slot.unavailable');
		} else if (isObject(bookingPriceError)) {
			return bookingPriceError?.additionalData?.detailedErrorMessages ?
					bookingPriceError?.additionalData?.detailedErrorMessages.at(0).value
				:	bookingPriceError.message;
		}
	}, [
		validateUnavailabilityData,
		maintenanceAvailabilityError,
		policies,
		selectedStartDate,
		selectedEndDate,
		calculatedPriceError,
		instanceAvailabilityError,
		bookingAvailabilityError,
		bookingPriceError,
	]);

	const resetAvailability = () => {
		const resetItems = [
			'fetchItemPolicies',
			'calculatedPrice',
			'bookingAvailability',
			'itemInstanceAvailability',
			'validatedBookingCategory',
			'validatedEditedBookingCategory',
		];
		resetItems.forEach((item) => onResetState(item));
		setPolicies(null);
		setIsExternalAdminError(false);
	};

	const handleResetCooldown = () => onUpdateCooldown(bookingData?.id);

	useEffect(() => {
		if (updateCooldownSuccess) {
			enqueueSnackbar(t('views.planboard.addBooking.resetCooldown'), { variant: 'success' });
		}
	}, [updateCooldownSuccess]);

	const isMaintenanceDisabled =
		isNull(selectedItem) ||
		isNull(selectedStartDate) ||
		isNull(selectedEndDate) ||
		availability ||
		!availableForMaintenance ||
		(!presetData?.unavailabilityId && (!selectedStartDate || !selectedEndDate || availability));
	const isButtonDisabled =
		isNull(selectedItem) ||
		isNull(selectedUser) ||
		isUndefined(selectedUser) ||
		(isObject(bookingData) && !hasChangedPresetData) ||
		!selectedEndDate ||
		isDoubleBookingInCategoryError ||
		isFullString(durationErrors) ||
		isEmptyString(selectedPaymentMethod) ||
		isRequiredLicensePlateNumber ||
		(commentRequired && isEmptyString(comment)) ||
		isExternalAdminError;

	useEffect(() => {
		if (isUnavailability || presetData?.isUnavailability) {
			setSelectType('maintenance');
		}
	}, [isUnavailability, presetData]);

	useEffect(() => {
		if (isObject(bookingData)) {
			licensePlate.setValue(bookingData.licensePlateNumber);
		}
	}, [bookingData]);

	useEffect(() => {
		if (isObject(price)) {
			setShowDiscount(price.price !== 0);
		} else {
			setShowDiscount(true);
		}
	}, [price]);

	const handleResetStates = () => {
		setPresetBookingData(null);
		onResetState('planboardBooking');
		onResetState('fetchInstance');
		setSelectedItem(null);
		setSelectedUser(null);
		setSelectedStartDate(null);
		setSelectedEndDate(null);
	};

	useEffect(() => {
		if (!open) {
			resetAvailability();
			setStopCarBooking(false);
			setCancelDialogOpen(false);
			handleResetStates();
			setHasChangedPresetData(false);
			setDiscount(0);
			setApplyingDiscount(false);
			setSelectType('booking');
			setComment('');
			setPrice(null);
			setPolicies(null);
			onResetState('updateDiscount');
			onResetState('bookingPrice');
			onResetState('addUnavailability');
			onResetState('addedPlanboardBooking');
			onResetState('maintenanceAvailability');
			onResetStateCondition('updatedBookingDetails', false);
			setCurrentError(null);
			onUpdateListState('fetchUserImage', null);
			setAvailableForMaintenance(true);
			setNowSwitch(false);
			setIsExternalAdminError(false);
			if (!isDetails) {
				onResetState('fetchUnavailability');
			}
			onUpdatePagedState('planboardItems', null, false);
		}
	}, [open]);

	useEffect(() => {
		if (isObject(bookingData) && isObject(selectedItem) && selectedStartDate && selectedEndDate) {
			const commentHasChanged = bookingData?.comment && bookingData?.comment !== comment;
			const differentStart = differentDates(
				selectedStartDate,
				getDateRoundedToDuration(new Date(bookingData.periodStart), 'PT15M')
			);
			const differentEnd = differentDates(
				selectedEndDate,
				getDateRoundedToDuration(new Date(bookingData.periodEnd), 'PT15M')
			);
			const licensePlateHasChanged = bookingData?.licensePlateNumber !== licensePlate.value;
			setHasChangedPresetData(
				discountChanged ||
					(isObject(selectedItem) && selectedItem.instanceId !== bookingData.itemInstance.id) ||
					(isObject(selectedUser) && selectedUser.id !== bookingData.userReference.id) ||
					differentStart ||
					differentEnd ||
					selectedPaymentMethod !== bookingData.billingType ||
					commentHasChanged ||
					licensePlateHasChanged
			);
			setHasChangedDatesFromPresetData(differentStart || differentEnd);
		}
	}, [
		bookingData,
		selectedItem,
		selectedUser,
		selectedStartDate,
		selectedEndDate,
		selectedPaymentMethod,
		comment,
		discountChanged,
		licensePlate,
	]);

	useEffect(() => {
		if (isObject(addedBookingData) || updatedBookingSuccess || isObject(updateDiscountData)) {
			if (isObject(bookingData)) {
				onClose(true, presetData?.bookingId);
				if (isObject(updateDiscountData) && isObject(fetchBooking)) {
					onUpdateDetailsState('fetchBooking', {
						...fetchBookingData,
						price: updateDiscountData.price,
					});
				}
			} else {
				onClose(true);
			}
		} else if (
			isObject(addUnavailabilityData) ||
			isObject(updateUnavailabilityData) ||
			isObject(cancelUnavailabilityData) ||
			isObject(stopUnavailabilityData)
		) {
			onClose(true);
		}
	}, [
		addedBookingData,
		updatedBookingSuccess,
		updateDiscountData,
		addUnavailabilityData,
		updateUnavailabilityData,
		cancelUnavailabilityData,
		stopUnavailabilityData,
	]);

	useEffect(() => {
		if (!updatedBookingLoading && !addedBookingLoading && addedBookingError) {
			enqueueSnackbar(addedBookingError.message, { variant: 'error' });
		} else if (!addedBookingLoading && !updatedBookingLoading && updatedBookingError) {
			enqueueSnackbar(updatedBookingError.message, { variant: 'error' });
		}
	}, [addedBookingLoading, addedBookingError, updatedBookingLoading, updatedBookingError]);

	const handleClickBookOrEdit = () => {
		if (isObject(bookingData)) {
			const updatedDiscount =
				applyingDiscount ? parseFloat(Number.isNaN(discount) ? 0 : discount, 10) : 0;
			if (['completed'].includes(bookingData?.status)) {
				onUpdateDiscount(presetData?.bookingId, updatedDiscount);
			} else {
				onPatchUpdateBooking(presetData?.bookingId, {
					...(bookingData.id !== selectedItem.instanceId && {
						itemInstanceId: selectedItem.instanceId,
					}),
					...(bookingData.userReference.id !== selectedUser.id && { userId: selectedUser.id }),
					...((
						!areDatesEqual(new Date(bookingData.periodStart), selectedStartDate) &&
						!['active', 'overtime'].includes(bookingData?.status)
					) ?
						nowSwitch ? { isStartNow: true }
						:	{ ['periodStart']: selectedStartDate }
					:	null),
					...(!areDatesEqual(new Date(bookingData?.periodEnd), selectedEndDate) && {
						periodEnd: selectedEndDate,
					}),
					...(bookingData.billingType !== selectedPaymentMethod && {
						billingType: selectedPaymentMethod,
					}),
					...(bookingData.comment !== comment && { comment }),
					...(bookingData.priceDetails.discount !== updatedDiscount && {
						discount: updatedDiscount,
					}),
				});
			}
		} else if (!isObject(bookingData) && selectType === 'booking') {
			onAddPlanboardBooking({
				categoryId: selectedItem.categoryId,
				itemInstanceId: parseInt(selectedItem.instanceId, 10),
				userId: selectedUser.id,
				...(!nowSwitch && { periodStart: selectedStartDate }),
				periodEnd: selectedEndDate,
				billingType: selectedPaymentMethod,
				...(isFullString(comment) && { comment: comment }),
				discount: applyingDiscount ? parseFloat(Number.isNaN(discount) ? 0 : discount, 10) : null,
				licensePlateNumber: isFullString(licensePlate.value) ? licensePlate.value : null,
				promoCode: null,
				ignoreMaintenance: true,
			});
		} else if (selectType === 'maintenance' && !presetData?.unavailabilityId) {
			onAddUnavailability({
				itemId: parseInt(selectedItem.itemId, 10),
				itemInstanceId: parseInt(selectedItem.instanceId, 10),
				...(!nowSwitch && { periodStart: selectedStartDate }),
				periodEnd: selectedEndDate,
				...(isFullString(comment) && { adminNote: comment }),
			});
		} else if (presetData?.unavailabilityId) {
			onUpdateUnavailability(presetData.unavailabilityId, {
				...(unavailabilityData?.status !== 'active' && { periodStart: selectedStartDate }),
				periodEnd: selectedEndDate,
				adminNote: comment,
			});
		}
	};

	const handleCloseDrawer = () => onClose();
	const handleOpenCancelBooking = () => setCancelDialogOpen(true);
	const handleOpenStopCarBooking = () => setStopCarBooking(true);
	const cancelUnavailability = () => setOpenCancelUnavailability(true);
	const handleStopUnavailability = () => setStopUnavailability(true);

	const handleType = (event, type) => {
		if (type?.length) {
			setSelectType(type);
		}
		if (type === 'booking') {
			onResetState('calculatedPrice');
			setPrice(null);
			setSelectedUser(null);
			onResetState('maintenanceAvailability');
		}
	};

	const mainProps = {
		open,

		presetData,
		selectedUser,
		setSelectedUser,
		selectedItem,
		setSelectedItem,

		fetchInstanceData,
		isUnavailability,
	};

	const selectTypeProps = { selectType, onChange: handleType };

	const buttonProps = {
		...mainProps,
		handleOpenStopCarBooking,
		handleCloseDrawer,
		handleOpenCancelBooking,
		bookingData,
		unavailabilityData,
		cancelUnavailability,
		stopUnavailability: handleStopUnavailability,
		onResetCooldown: handleResetCooldown,
		resetLoading: updateCooldownLoading,
		isAllowedCooldownReset: bookingData?.bookingPolicies?.cooldownBufferMinutes > 0,
	};

	const itemCardProps = {
		...mainProps,
		setPresetBookingData,
		setHasChangedItem,
		resetAvailability,
		selectType,
		setFetchBookingPrice,
	};

	const userProps = {
		...mainProps,
		isDoubleBookingInCategoryError,
		bookingData,
		selectType,
		setPolicies,
		resetAvailability,
	};

	const dateTimeProps = {
		...mainProps,
		selectedStartDate,
		selectedEndDate,
		setSelectedPaymentMethod,
		setSelectedStartDate,
		setSelectedEndDate,
		setComment,
		setCommentLength,
		setPolicies,
		instanceAvailabilityLoading,
		maintenanceAvailabilityLoading,
		availableForMaintenance,
		setFetchBookingPrice,
		setAvailableForMaintenance,
		selectType,
		nowSwitch,
		setNowSwitch,
		isProvider,
		isExternalAdminError,
		setIsExternalAdminError,
		date,
		durationErrors,
	};

	const usageProps = {
		...mainProps,
		bookingData,
		hasChangedDatesFromPresetData,
		setPrice,
		selectedStartDate,
		selectedEndDate,
		discount,
		applyingDiscount,
		nowSwitch,
		hasChangedItem,
		bookingLoading,
		setSelectedPaymentMethod,
		selectedPaymentMethod,
		setDiscount,
		setHasChangedItem,
		isProvider,
		setApplyingDiscount,
		fetchBookingPrice,
		setFetchBookingPrice,
	};

	const commentProps = {
		...mainProps,
		bookingData,
		policies,
		setComment,
		comment,
		commentLength,
		setCommentLength,
		commentRequired,
		setCommentRequired,
		unavailabilityData,
	};

	const licensePlateProps = {
		open,

		licensePlate,
		disabled: isObject(bookingData) && bookingData.status !== 'upcoming',
	};

	const discountProps = {
		applyingDiscount,
		discount,
		price,
		setApplyingDiscount,
		setDiscount,
		disabled: !discountAllowed,
		setFetchBookingPrice,
	};

	const priceProps = {
		applyingDiscount,
		className: classes.priceContainer,
		discount,
		onClose,
		price,
		promoCode: isObject(bookingData) ? bookingData.promoCode : undefined,
		bookingPrice: isObject(bookingData) ? bookingData.price : undefined,
		bookingStatus: bookingData?.status,
	};

	const dialogProps = {
		...mainProps,
		onClose,
		bookingData,
		cancelDialogOpen,
		setCancelDialogOpen,
		stopCarBooking,
		setStopCarBooking,
	};

	const cancelUnavailabilityProps = {
		openCancelUnavailability,
		setOpenCancelUnavailability,
		unavailabilityId: unavailabilityData?.id,
	};

	const stopUnabailabilitysProps = {
		end: unavailabilityData?.end,
		open: stopUnavailability,
		data: unavailabilityData,
		setStopUnavailability,
		start: unavailabilityData?.start,
	};

	const buttonAddOrSaveProps = {
		disabled: selectType === 'booking' ? isButtonDisabled : isMaintenanceDisabled,
		fullWidth: true,
		onClick: handleClickBookOrEdit,
		size: 'large',
		variant: 'contained-primary',
	};

	const handleTitle = () => {
		const handleTitleLabel = (label) => (
			<Typography className={classes.drawerHeader} variant='h4'>
				{label}
			</Typography>
		);
		if (presetData?.bookingId) {
			return handleTitleLabel(`ID: ${presetData.bookingId}`);
		} else if (presetData?.unavailabilityId) {
			return handleTitleLabel(`${t('ui.label.unavailability')} ID: ${presetData.unavailabilityId}`);
		} else if (presetData?.activSelection) {
			return <BookingType {...selectTypeProps} />;
		} else if (isUnavailability && !presetData?.unavailabilityId) {
			return handleTitleLabel(t('ui.label.unavailability'));
		} else if ((!isUnavailability && isObject(presetData)) || isUndefined(presetData)) {
			return handleTitleLabel(t('views.planboard.addBooking.newTitle'));
		} else {
			return <LoadingBar />;
		}
	};

	const handleSubmittingBooking = () => {
		if (
			updatedBookingLoading ||
			addedBookingLoading ||
			addUnavailabilityLoading ||
			updateDiscountLoading
		) {
			return <CircularProgress className={classes.progress} disableShrink size={25} />;
		} else {
			if (selectType === 'booking') {
				if (presetData?.bookingId) {
					return t('ui.button.contained.save');
				} else {
					return t('view.planboard.addoreditbooking.button.contained.booknow');
				}
			} else {
				return t('ui.button.scheduledMaintenances');
			}
		}
	};

	const showLicensePlate =
		isObject(policies) && policies?.requiredParkingLicensePlateNumber !== 'notApplicable';

	const showCommentCard =
		isFullString(bookingData?.comment) ||
		bookingData?.status === 'upcoming' ||
		!isObject(bookingData);

	const showButton =
		(isObject(bookingData) &&
			!['upcoming', 'active', 'overtime'].includes(bookingData.status) &&
			!discountAllowed) ||
		(isObject(unavailabilityData) && unavailabilityData.status === 'completed');

	return (
		<Fragment>
			<Drawer
				anchor='right'
				classes={{ paper: classes.drawer }}
				onClose={() => handleCloseDrawer()}
				open={open}
				variant='temporary'
			>
				<form>
					<ButtonCard {...buttonProps} />
					{handleTitle()}
					<ItemCard {...itemCardProps} />
					<DateTimeCard {...dateTimeProps} />
					<UserCard {...userProps} />
					{selectType === 'booking' ?
						<UsageCard {...usageProps} />
					:	null}
					{showCommentCard ?
						<CommentCard {...commentProps} />
					:	null}
					{showLicensePlate && selectType === 'booking' ?
						<LicensePlate {...licensePlateProps} />
					:	null}
					{(
						authorizeForOrganisation(selectedItem?.organisationReference.id) &&
						showDiscount &&
						selectType === 'booking'
					) ?
						<>
							<Divider />
							<Discount {...discountProps} />
						</>
					:	null}
					{selectType === 'booking' ?
						<Price {...priceProps} />
					:	null}
				</form>
				{showButton ? null : (
					<Box alignContent='flex-end' mb={1} mt={1}>
						<Divider className={classes.divider} />
						<StyledButton {...buttonAddOrSaveProps}> {handleSubmittingBooking()} </StyledButton>
					</Box>
				)}
			</Drawer>
			<Dialogs {...dialogProps} />
			{openCancelUnavailability ?
				<CancelUnabailabilitysDialog {...cancelUnavailabilityProps} />
			:	null}
			{stopUnavailability ?
				<StopUnabailabilitysDialog {...stopUnabailabilitysProps} />
			:	null}
		</Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		planboardBooking: state.details.planboardBooking,

		fetchInstance: state.details.fetchInstance,
		addedPlanboardBooking: state.details.addedPlanboardBooking,
		updatedBookingDetails: state.condition.updatedBookingDetails,
		itemInstanceAvailability: state.details.itemInstanceAvailability,
		bookingAvailability: state.details.bookingAvailability,
		addUnavailability: state.details.addUnavailability,
		updateUnavailability: state.details.updateUnavailability,
		fetchUnavailability: state.details.fetchUnavailability,
		maintenanceAvailability: state.details.maintenanceAvailability,
		cancelUnavailabilities: state.details.cancelUnavailabilities,
		stopUnavailability: state.details.stopUnavailability,
		calculatedPrice: state.details.calculatedPrice,
		validatedBookingCategory: state.details.validatedBookingCategory,
		validatedEditedBookingCategory: state.details.validatedEditedBookingCategory,
		validateUnavailability: state.details.validateUnavailability,
		updateDiscount: state.details.updateDiscount,
		fetchBooking: state.details.fetchBooking,
		bookingPrice: state.details.bookingPrice,
		updateCooldown: state.condition.updateCooldown,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onResetState: (state) => dispatch(actions.resetState(state)),
		onAddPlanboardBooking: (bodyData) => dispatch(actions.addPlanboardBooking(bodyData)),
		onPatchUpdateBooking: (bookingId, bodyData) =>
			dispatch(actions.patchUpdateBooking(bookingId, bodyData)),
		onResetStateCondition: (state, value) => dispatch(actions.resetStateCondition(state, value)),
		onUpdateListState: (state, data) => dispatch(actions.updateListState(state, data)),
		onAddUnavailability: (bodyData) => dispatch(actions.addUnavailability(bodyData)),
		onUpdateUnavailability: (unavailabilityId, bodyData) =>
			dispatch(actions.updateUnavailability(unavailabilityId, bodyData)),
		onUpdatePagedState: (identifier, data) => dispatch(actions.updatePagedState(identifier, data)),
		onUpdateDiscount: (bookingId, discount) =>
			dispatch(actions.updateDiscount(bookingId, discount)),
		onUpdateDetailsState: (identifier, data) =>
			dispatch(actions.updateDetailsState(identifier, data)),
		onUpdateCooldown: (bookingId) => dispatch(actions.updateCooldown(bookingId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SideDrawer);

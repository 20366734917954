import { useMemo } from 'react';

import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { BarChart, ChartsLegendWithValues } from '~components';
import { DateRange } from '~interfaces/dateRanges';
import { getRenderedTextSize } from '~utils';

import TripStatisticsService from '../../services/tripStatisticsService';

const service = new TripStatisticsService();

interface ItemCountBarChartProps {
	organisationId?: string;
	period: DateRange;
	itemId?: string;
}

const TripDistancesBarChart = ({ organisationId, period, itemId }: ItemCountBarChartProps) => {
	const { t } = useTranslation();
	const theme = useTheme();

	const { data, isLoading } = useSWR(
		[service.basePath, { organisationId: organisationId, period: period, itemId: itemId }],
		([_, args]) => {
			if (isLoading) {
				service.abortCurrentRequest('parameter change');
			}

			return service.getCounts(args);
		},
	);

	const xAxisLabelWidth = useMemo(() => {
		if (!data) {
			return 0;
		}

		const maxValue = Math.max(...Object.values(data));
		return getRenderedTextSize(
			`${maxValue.toLocaleString()}km`,
			'12px',
			theme.typography.fontFamily,
		).width;
	}, [data]);

	const valueFormatter = (value: number | null) => `${value?.toLocaleString() ?? '-'}km`;

	return (
		<BarChart
			loading={isLoading}
			xAxis={[
				{
					scaleType: 'band',
					data: [t('ui.label.carDistance')],
					valueFormatter: (value, context) => (context.location !== 'tick' ? value : ''),
				},
			]}
			yAxis={[
				{
					min: 0,
					// max: ((data?.distanceWithBooking ?? 0 + (data?.distanceWithoutBooking ?? 0)) <= 0) ? 5 : undefined,
					valueFormatter: (value: number) => `${value.toLocaleString()}km`,
					// Set the yaxis to a minimal fixed layout
				},
			]}
			series={[
				{
					label: t('withBooking'),
					data: data != null ? [data.distanceWithBooking] : [],
					valueFormatter: valueFormatter,
				},
				{
					label: t('withoutBooking'),
					data: data != null ? [data.distanceWithoutBooking] : [],
					valueFormatter: valueFormatter,
				},
			]}
			slots={{
				legend: ChartsLegendWithValues,
			}}
			slotProps={{
				legend: {
					type: 'bar',
					values: data != null ? [data.distanceWithBooking, data.distanceWithoutBooking] : [],
				},
			}}
			// Calculate the space needed, default offset (30 for km) times the amount of characters
			// Notice the dot before every 3rd character
			margin={{
				left: xAxisLabelWidth + 15,
			}}
		/>
	);
};

export default TripDistancesBarChart;

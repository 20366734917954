import CloseIcon from '@mui/icons-material/Close';
import EastIcon from '@mui/icons-material/East';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { StyledButton } from '../../../../../components';

interface HeaderCardProps {
	onClick?(...args: unknown[]): unknown;
	itemName?: string;
	sharedWith?: string;
}

const HeaderCard = (props: HeaderCardProps) => {
	const { onClick, itemName, sharedWith } = props;
	const { t } = useTranslation();

	return (
		<Box rowGap={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
			<StyledButton onClick={onClick} size='small' startIcon={<CloseIcon />}>
				{t('ui.button.inline.close')}
			</StyledButton>
			<Typography variant='h3'>{t('views.sharing.drawer.header')}</Typography>
			<Box gap={2} sx={{ display: 'flex' }}>
				<Typography variant='h5'>{itemName} </Typography>
				<EastIcon fontSize='small' />
				<Typography variant='h5'>{sharedWith} </Typography>
			</Box>
		</Box>
	);
};

export default HeaderCard;

import { useState } from 'react';

import { AxiosResponse } from 'axios';

const useDownloadFile = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();

	const downloadLocalFile = (url: string, fileName: string) => {
		setLoading(true);

		try {
			const linkEl = document.createElement('a');
			linkEl.href = url;
			linkEl.download = fileName;
			document.body.appendChild(linkEl);
			linkEl.click();
			document.body.removeChild(linkEl);
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	};

	const downloadServerFile = async (fetcher: () => Promise<AxiosResponse>, fileName: string) => {
		setLoading(true);

		try {
			const response = await fetcher();
			const url = URL.createObjectURL(response);
			const linkEl = document.createElement('a');
			linkEl.href = url;
			linkEl.download = fileName;
			document.body.appendChild(linkEl);
			linkEl.click();
			document.body.removeChild(linkEl);
			URL.revokeObjectURL(url);
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	};

	return {
		isLoading: loading,
		error: error,
		downloadLocalFile,
		downloadServerFile,
	};
};

export default useDownloadFile;

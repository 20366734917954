import path from 'path';

import { Navigate, RouteObject } from 'react-router-dom';

import { pagePathSegments, queryParameters } from '~constants';
import { PageLayout } from '~layouts';
import {
	AccessLogsPage,
	AccessRuleDetailsPage,
	AccessRulesPage,
	AccessScheduleDetailsPage,
	AccessSchedulesPage,
	SkcDeviceGroupDetailsPage,
	SkcDeviceGroupsPage,
	SkcUserGroupDetailsPage,
	SkcUserGroupsPage,
} from '~pages';

const isHigherThanAcceptance =
	import.meta.env.VITE_APP_ENV === 'accept' || import.meta.env.VITE_APP_ENV === 'production';

const skcAccessRoutes = (): RouteObject[] => [
	{
		path: pagePathSegments.Access,
		element: (
			<PageLayout
				pages={[
					{ i18nKey: 'rules', path: pagePathSegments.Rules },
					{ i18nKey: 'deviceGroups', path: pagePathSegments.DeviceGroups },
					{ i18nKey: 'accessGroups', path: pagePathSegments.AccessGroups },
					{ i18nKey: 'schedules', path: pagePathSegments.Schedules },
					{ i18nKey: 'logs', path: pagePathSegments.Logs },
				]}
				persistedSearchParamKeys={[
					queryParameters.Organisation,
					queryParameters.PageSize,
				]}
				slotProps={{
					header: {
						i18nTitleKey: 'nav.category.accessManagement'
					}
				}}
			/>
		),
		children: [
			{
				index: true,
				element: <Navigate to={pagePathSegments.Rules} />,
			},
			{
				path: pagePathSegments.Rules,
				element: <AccessRulesPage />,
			},
			{
				path: pagePathSegments.Logs,
				element: <AccessLogsPage />,
			},
			{
				path: pagePathSegments.DeviceGroups,
				element: <SkcDeviceGroupsPage />,
			},
			{
				path: pagePathSegments.AccessGroups,
				element: <SkcUserGroupsPage />,
			},
			{
				path: pagePathSegments.Schedules,
				element: <AccessSchedulesPage />,
			}
		],
	},
	{
		path: pagePathSegments.Access,
		children: [
			{
				path: path.join(pagePathSegments.Rules, ':id'),
				element: <PageLayout slotProps={{ header: { i18nTitleKey: 'views.addTemplate.templateDetails.title' }}} />,
				children: [
					{
						index: true,
						element: <AccessRuleDetailsPage />,
					},
				],
			},
			{
				path: path.join(pagePathSegments.AccessGroups, ':id'),
				element: <PageLayout slotProps={{ header: { i18nTitleKey: 'accessGroupDetails' }}} />,
				children: [
					{
						index: true,
						element: <SkcUserGroupDetailsPage />,
					},
				],
			},
			{
				path: path.join(pagePathSegments.DeviceGroups, ':id'),
				element: <PageLayout slotProps={{ header: { i18nTitleKey: 'deviceGroupDetails' }}} />,
				children: [
					{
						index: true,
						element: <SkcDeviceGroupDetailsPage />,
					},
				],
			},
			{
				path: path.join(pagePathSegments.Schedules, ':id'),
				element: <PageLayout slotProps={{ header: { i18nTitleKey: 'scheduleDetails' }}} />,
				children: [
					{
						index: true,
						element: <AccessScheduleDetailsPage />,
					},
				],
			},
		],
	},
];

export default skcAccessRoutes;

import { useEffect } from 'react';

import { Tabs, Tab, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';

import Header from './Header';
import { useStyles } from './style';
import Summary from './Summary';
import { Page } from '../../../../components';
import { isObject } from '../../../../shared/utility';
import * as actions from '../../../../store/actions/index';

interface UnavailabilityDetailsProps {
	onFetchUnavailability?(...args: unknown[]): unknown;
	onUpdateDetailsState?(...args: unknown[]): unknown;
	fetchUnavailability?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	cancelUnavailabilities?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	updateUnavailability?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const UnavailabilityDetails = (props: UnavailabilityDetailsProps) => {
	const {
		match,
		onFetchUnavailability,
		fetchUnavailability,
		onUpdateDetailsState,
		cancelUnavailabilities,
		updateUnavailability,
	} = props;
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const classes = useStyles();

	const { id, tab } = useParams();

	const {
		data: fetchUnavailabilityData,
		loading: fetchUnavailabilityLoading,
		error: fetchUnavailabilityError,
	} = fetchUnavailability;
	const fetchUnavailabilityReady =
		isObject(fetchUnavailabilityData) && !fetchUnavailabilityLoading && !fetchUnavailabilityError;

	const { data: cancelUnavailabilityData } = cancelUnavailabilities;

	const { data: updateUnavailabilityData } = updateUnavailability;

	const summaryProps = {
		fetchUnavailabilityData,
		fetchUnavailabilityLoading,
	};
	const handleTabsChange = (event, value) => {
		navigate(`../${value}`, { relative: 'path', state: { ...location.state } });
	};

	useEffect(() => {
		if (!fetchUnavailabilityLoading) {
			onFetchUnavailability(id);
		}
	}, [id, onFetchUnavailability]);

	useEffect(() => {
		if (isObject(cancelUnavailabilityData)) {
			onUpdateDetailsState('fetchUnavailability', {
				...fetchUnavailabilityData,
				status: 'cancelled',
			});
		} else if (isObject(updateUnavailabilityData)) {
			onUpdateDetailsState('fetchUnavailability', {
				...fetchUnavailabilityData,
				start: updateUnavailabilityData.start,
				end: updateUnavailabilityData.end,
			});
		}
	}, [cancelUnavailabilityData, updateUnavailabilityData]);

	const tabs = [{ value: 'summary', label: t('ui.summary') }];

	if (!tab) {
		return <Navigate to={'/ticketing/unavailability'} />;
	}

	if (!tabs.find((t) => t.value === tab)) {
		return <Navigate to='/errors/error-404' />;
	}

	return (
		<Page className={classes.root} title={t('ui.label.details')}>
			<Header
				fetchUnavailabilityData={fetchUnavailabilityData}
				loading={!fetchUnavailabilityReady}
			/>
			<Tabs
				className={classes.tabs}
				indicatorColor='primary'
				onChange={handleTabsChange}
				scrollButtons='auto'
				value={tab}
				variant='scrollable'
			>
				{tabs.map((unavailabilityTab) => (
					<Tab
						key={unavailabilityTab.value}
						label={unavailabilityTab.label}
						value={unavailabilityTab.value}
					/>
				))}
			</Tabs>
			<Divider className={classes.divider} />
			<div className={classes.content}>{tab === 'summary' && <Summary {...summaryProps} />}</div>
		</Page>
	);
};

const mapStateToProps = (state) => {
	return {
		fetchUnavailability: state.details.fetchUnavailability,
		cancelUnavailabilities: state.details.cancelUnavailabilities,
		updateUnavailability: state.details.updateUnavailability,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchUnavailability: (unavailabilityId) =>
			dispatch(actions.fetchUnavailability(unavailabilityId)),
		onUpdateDetailsState: (identifier, data) =>
			dispatch(actions.updateDetailsState(identifier, data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UnavailabilityDetails);

import { useTranslation } from 'react-i18next';

import { BookingStatisticsLayout } from '~features/statistics';

import Page from '../page';

const BookingStatisticsPage = () => {
	const { t } = useTranslation();

	return (
		<Page title={`${t('data')} ${t('ui.category.bookings')}`}>
			<BookingStatisticsLayout />
		</Page>
	);
};

export default BookingStatisticsPage;

import path from 'path';

import { Business as BusinessIcon } from '@mui/icons-material';
import { Avatar, Button, Stack, SvgIcon, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { pagePathSegments, pagePaths } from '~constants';
import Page from '~pages/page';

/**
 * The page to show when someone is authorized but an user.
 * To be able to use the admin panel, you need to be in an
 * organisation
 * @returns
 */
const UserLandingPage = () => {
	const { t } = useTranslation();

	return (
		<Page title={t('login')}>
			<Stack spacing={3} justifyContent='center' alignItems='center' maxWidth={540} p={3}>
				<Avatar
					sx={{
						color: '#304FFE',
						bgcolor: '#E4EEFB',
						width: '91px',
						height: '91px',
					}}
				>
					<SvgIcon component={BusinessIcon} sx={{ fontSize: '55px' }} />
				</Avatar>
				<Typography variant='h4'>{t('views.login.user.title')}</Typography>
				<Typography>{t('views.login.user.subtitle')}</Typography>
				<Typography textAlign='center'>{t('views.login.user.signOutOrContinue')}</Typography>
				<Stack px={3} spacing={3} pt={3} direction='row' justifyContent='space-between' width={1}>
					<Button
						variant='outlined'
						component={Link}
						to={`/${pagePaths.AuthLogout}`}
						sx={{ width: 1 }}
					>
						{t('ui.button.contained.signout')}
					</Button>
					<Button
						variant='contained'
						component={Link}
						to={`/${path.join(pagePathSegments.Register)}/${pagePathSegments.Organisation}`}
						fullWidth
					>
						{t('ui.button.contained.continue')}
					</Button>
				</Stack>
			</Stack>
		</Page>
	);
};

export default UserLandingPage;

import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { BarChart, ChartsLegendWithValues } from '~components';
import { DateRange } from '~interfaces/dateRanges';

import BillingPeriodStatisticsService from '../../services/billingPeriodStatisticsService';

const service = new BillingPeriodStatisticsService();

interface BillingPeriodCountBarChartProps {
	organisationId?: string;
	period: DateRange;
}

const BillingPeriodCountBarChart = ({
	organisationId,
	period,
}: BillingPeriodCountBarChartProps) => {
	const { t } = useTranslation();

	const { data, isLoading } = useSWR(
		[service.basePath, { organisationId: organisationId, period: period }],
		([_, args]) => {
			if (isLoading) {
				service.abortCurrentRequest('parameter change');
			}

			return service.getBillingPeriodCounts(args);
		},
	);

	return (
		<BarChart
			loading={isLoading}
			xAxis={[
				{
					scaleType: 'band',
					data: ['payments'],
					valueFormatter: (value, context) => (context.location !== 'tick' ? value : ''),
				},
			]}
			yAxis={[
				{
					min: 0,
					tickMinStep: 1,
					valueFormatter: (value: number) => value.toLocaleString(),
					domainLimit: (min, max) => ({
						min: min,
						max: max <= 0 ? 2 : max,
					}),
				},
			]}
			series={[
				{
					label: t('ui.status.open'),
					data: data != null ? [data.open] : [],
				},
				{
					label: t('ui.status.closed'),
					data: data != null ? [data.closed] : [],
				},
				{
					label: t('ui.status.revoked'),
					data: data != null ? [data.revoked] : [],
				},
			]}
			slots={{
				legend: ChartsLegendWithValues,
			}}
			slotProps={{
				legend: {
					type: 'bar',
					values: data != null ? [data.open, data.closed, data.revoked] : [],
					direction: 'row',
					hidden: false,
				},
			}}
			margin={{
				left: 30,
			}}
		/>
	);
};

export default BillingPeriodCountBarChart;

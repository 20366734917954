import { RouteObject } from 'react-router-dom';

import { pagePathSegments } from '~constants';
import { ProtectedRoute, PublicRoute } from '~features/authentication';
import { AuthenticationLayout } from '~layouts';
import { default as AuthLayout } from '~layouts/Auth';
import i18n from '~lib/i18n';
import {
	LoginLaunchPage,
	LoginCallbackPage,
	LogoutPage,
	RegisterLaunchPage,
	UserLandingPage,
	OrganisationVerificationPage,
	ConfirmEmailPage,
	PasswordResetPage,
} from '~pages';

import AddCompany from '../../views/Register/AddCompany';
import AdminRedirect from '../../views/Register/AdminRedirect';

const authRoutes: RouteObject[] = [
	{
		element: <AuthenticationLayout />,
		children: [
			{
				path: pagePathSegments.Auth,
				children: [
					{
						element: <PublicRoute />,
						children: [
							{
								path: pagePathSegments.Login,
								element: <LoginLaunchPage />,
							},
							{
								path: pagePathSegments.Callback,
								element: <LoginCallbackPage />,
							},
						],
					},
					{
						element: <PublicRoute exclusiveUserRoute />,
						children: [
							{
								path: pagePathSegments.UserRedirect,
								element: <UserLandingPage />,
							},
						],
					},
					{
						path: pagePathSegments.Logout,
						element: <LogoutPage />,
					},
				],
			},
			{
				path: pagePathSegments.Email,
				children: [
					{
						path: pagePathSegments.Confirm,
						element: <ConfirmEmailPage />,
					},
				],
			},
			{
				path: pagePathSegments.Password,
				children: [
					{
						path: pagePathSegments.Reset,
						element: <PasswordResetPage />,
					},
				],
			},
			{
				path: pagePathSegments.Register,
				children: [
					{
						element: <PublicRoute />,
						children: [
							{
								path: pagePathSegments.Start,
								element: <RegisterLaunchPage />,
							},
						],
					},
					{
						element: <PublicRoute exclusiveUserRoute />,
						children: [
							// TODO: looks weird with the new layout
							// {
							// 	path: pagePathSegments.Organisation,
							// 	element: <AddCompany />,
							// },
							{
								path: pagePathSegments.VerificationStatus,
								element: <OrganisationVerificationPage />,
							},
						],
					},
					{
						element: <ProtectedRoute />,
						children: [
							{
								path: pagePathSegments.AdminRedirect,
								element: <AdminRedirect />,
							},
						],
					},
				],
			},
		],
	},
	{
		// element: <AuthenticationLayout disablePaper />,
		element: <AuthLayout />,
		children: [
			{
				element: <PublicRoute exclusiveUserRoute />,
				children: [
					{
						path: `${pagePathSegments.Register}/${pagePathSegments.Organisation}`,
						element: <AddCompany />,
					},
				],
			},
		],
	},
];

export default authRoutes;

import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Link,
} from '@mui/material';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { userInfoAtom } from '~atoms';
import { useAuthorize } from '~features/authentication';

import { commaTimeStrings } from '../../../../../shared/datetime';
import { useStyles } from '../style';

interface AdditionalInfoProps {
	className?: string;
	contractData?: object;
}

const AdditionalInfo = ({ contractData, ...props }: AdditionalInfoProps) => {
	const { t } = useTranslation();
	const location = useLocation();
	const { isSuperAdmin } = useAuthorize();
	const userInfo = useAtomValue(userInfoAtom);

	const isInternalUser =
		userInfo?.organisation?.id == contractData?.itemInstance.hub.organisation.id;

	const assignedUserInterna =
		userInfo?.organisation?.id == contractData?.userOrganisation?.id;

	const classes = useStyles();

	const isInternalContracts = contractData.type === 'internal';

	const handleLinks = (path, label, returnButton = 'ui.label.contracts') => (
		<Link
			className={classes.itemGroup}
			color='primary'
			component={RouterLink}
			to={path}
			state={{
				from: location.pathname,
				label: t(returnButton),
			}}
		>
			{label}
		</Link>
	);

	const data = [
		{ label: 'ui.dateCreated', value: commaTimeStrings(contractData.creationDate) },
		{
			label: 'views.contract.details.createBy',
			value:
				contractData?.createdByUser && (isInternalContracts || isInternalUser || isSuperAdmin()) ?
					handleLinks(
						`/user-management/users/${contractData.createdByUser.id}/summary`,
						contractData.createdByUser.name,
					)
				:	contractData?.createdByUser?.name,
		},
		{
			label: 'views.contract.details.dateUserAssigned',
			value: contractData.userAssignedDate && commaTimeStrings(contractData.userAssignedDate),
		},
		{
			label: 'views.contract.details.assignedBy',
			value:
				(
					contractData?.assignedByUser &&
					(isInternalContracts || isSuperAdmin() || assignedUserInterna)
				) ?
					handleLinks(
						`/user-management/users/${contractData.assignedByUser.id}/summary`,
						contractData.assignedByUser.name,
					)
				:	contractData?.assignedByUser?.name,
		},
		...(contractData?.terminatedDate ?
			[
				{
					label: 'views.contract.details.dateTerminated',
					value: contractData.terminatedDate && commaTimeStrings(contractData.terminatedDate),
				},
			]
		:	[]),
		...(contractData?.terminatedByUser ?
			[
				{
					label: 'views.contract.details.terminatedBy',
					value:
						isInternalContracts || isSuperAdmin() || assignedUserInterna ?
							handleLinks(
								`/user-management/users/${contractData?.terminatedByUser.id}/summary`,
								contractData.terminatedByUser.name,
							)
						:	contractData.terminatedByUser.name,
				},
			]
		:	[]),
		...(contractData?.intendedTerminatedDate ?
			[
				{
					label: 'views.contract.details.intendedTerminatedDate',
					value: commaTimeStrings(contractData.intendedTerminatedDate),
				},
			]
		:	[]),
	];

	return (
		<Card className={classes.cardRoot}>
			<CardHeader title={t('views.contract.details.additionalInfo')} />
			<Divider />
			<CardContent className={classes.content}>
				<Table>
					<TableBody>
						{data.map((contract) => (
							<TableRow key={contract.label}>
								<TableCell>{t(contract.label)}</TableCell>
								<TableCell>{contract?.value ? contract.value : '-'}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	);
};

export default AdditionalInfo;

import { Grid } from '@mui/material';
import clsx from 'clsx';

import { DeviceStatusCard } from '~features/devices';

import DeviceAllocation from './DeviceAllocation';
import DeviceInfo from './DeviceInfo';
import { useStyles } from './style';
import { isObject } from '../../../../shared/utility';
import SummaryLoading from '../../../OrganisationManagement/Details/OrganisationDetails/Summary/SummaryLoading/SummaryLoading';

interface SummaryProps {
	className?: string;
	deviceData?: object;
	loading?: boolean;
	id?: string;
}

const Summary = (props: SummaryProps) => {
	const { className, deviceData, loading, id } = props;

	const classes = useStyles();

	const gridProps = { item: true, xs: 12, md: 6, lg: 6, xl: 4 };

	return isObject(deviceData) && !loading ?
			<Grid className={clsx(classes.root, className)} container spacing={3}>
				<Grid {...gridProps}>
					<DeviceInfo deviceData={deviceData} />
				</Grid>
				<Grid {...gridProps}>
					<DeviceStatusCard deviceId={id} deviceType={deviceData.deviceType} />
				</Grid>
				{deviceData?.organisationReference && (
					<Grid {...gridProps}>
						<DeviceAllocation deviceData={deviceData} />
					</Grid>
				)}
			</Grid>
		:	<SummaryLoading />;
};

export default Summary;

import { useEffect, useState } from 'react';

import { OidcClient, OidcClientSettings } from 'oidc-client-ts';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ErrorFallback, LoadingFallback } from '~components';
import { pagePaths } from '~constants';
import { userManager } from '~features/authentication';

import Page from '../page';

/**
 * TODO: finish this when backen properly encodes
 * See: ./src/view/password/reset
 * @returns
 */
const PasswordResetPage = () => {
	const { t } = useTranslation();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const [error, setError] = useState(false);

	const redirect = async () => {
		const token = searchParams.get('token');
		// The email arrives here unencoded. This does mean we need to correct for the
		// + being seen as a space.
		// The backend eventually needs to provide a properly encoded email
		const email = searchParams.get('email')?.replace(' ', '+');
		if (token == null || email == null) {
			console.error('Not enough info for password reset');
			setError(true);
			return;
		}

		const culture = searchParams.get('culture');
		const settings: OidcClientSettings = {
			...userManager.settings,
			metadata: {
				...userManager.settings.metadata,
				authorization_endpoint: new URL(
					'connect/updatepassword',
					import.meta.env.VITE_IDENTITY_AUTHORITY
				).href,
			},
			extraQueryParams: {
				email: email,
				token: token,
				...(culture != null && {
					culture: culture,
				}),
			},
		};
		const client = new OidcClient(settings);

		try {
			const res = await client.createSigninRequest(settings);

			console.debug(res.url);
			window.location.href = res.url;
		} catch (error) {
			console.error(error);
			setError(true);
			navigate(`/${pagePaths.AuthLogin}`);
		}
	};

	useEffect(() => {
		redirect();
	}, []);

	return (
		<Page title='Reset password'>
			{!error ? (
				<LoadingFallback title='Resetting password' />
			) : (
				<ErrorFallback label={t('somethingWentWrong')} />
			)}
		</Page>
	);
};

export default PasswordResetPage;

import RefreshIcon from '@mui/icons-material/Refresh';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import DownloadButton from './DownloadButton';
import ExtraButtons from './ExtraButtons';
import FileReader from './FileReader/FileReader';
import MainFilter from './MainFilter';
import { useStyles } from './style';
import Switch from './Switch';
import TimePicker from './TimePicker';
import { Search, StyledButton } from '../../../components';
import { isFullArray, isObject } from '../../../shared/utility';
import { LazyInput } from '../../elements';

const withWidth = () => (WrappedComponent) => (props) => <WrappedComponent {...props} width='xs' />;

interface SearchBarProps {
	placeholder?: string;
	className?: string;
	searchEvents?: object;
	searchValue?: string;
	hideSearch?: boolean;
	hasMainFilter?: boolean;
	mainFilters?: unknown[];
	hideMoreFilters?: boolean;
	hasExtraButtons?: boolean;
	extraButtons?: unknown[];
	onFilter?(...args: unknown[]): unknown;
	width?: string;
	periodFilter?: object;
	extraSwitch?: unknown[];
	fileReaderData?: object | boolean;
	downloadData?: object;
	clearFilters?: object;
	timePickerData?: object;
	lazyFilters?: unknown[];
}

const SearchBar = (props: SearchBarProps) => {
	const {
		placeholder,
		hideSearch,
		hasMainFilter,
		mainFilters,
		hasExtraButtons,
		extraButtons,
		searchEvents,
		searchValue,
		periodFilter,
		extraSwitch,
		fileReaderData,
		downloadData,
		timePickerData,
		clearFilters,

		lazyFilters,
	} = props;
	const { t } = useTranslation();

	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<Box className={classes.searchFilterContainer}>
				{hideSearch ? null : (
					<Search
						className={classes.search}
						events={searchEvents}
						hasInlineSearchButton
						inputType='search-suggest'
						placeholder={placeholder}
						value={searchValue}
					/>
				)}
				{periodFilter}
				{isObject(timePickerData) && <TimePicker {...timePickerData} />}
				{hasMainFilter ?
					mainFilters.map((mainFilter, index) => (
						<MainFilter
							className={classes.mainFilter}
							dataList={mainFilter.dataList}
							defaultListItem={mainFilter.defaultListItem}
							events={mainFilter.events}
							isSearchAutocomplete={mainFilter.isSearchAutocomplete}
							isSelectWithLazyLoading={mainFilter.isSelectWithLazyLoading}
							key={index}
							listType={mainFilter.listType}
							onFetchData={mainFilter.onFetchData}
							placeholder={mainFilter.placeholder}
							selectOptions={mainFilter.selectOptions}
							value={mainFilter.value}
						/>
					))
				:	null}
				{isFullArray(lazyFilters) &&
					lazyFilters.map((item) => <LazyInput key={item.key} stateType={item.key} {...item} />)}
				{isFullArray(extraSwitch) ?
					<Switch extraSwitch={extraSwitch} />
				:	null}
				{isObject(clearFilters) && clearFilters.clear ?
					<StyledButton
						onClick={clearFilters.action}
						size='medium'
						startIcon={<RefreshIcon />}
						variant='inline-default'
					>
						{t(clearFilters.btnText)}
					</StyledButton>
				:	null}
			</Box>
			{hasExtraButtons ?
				<Box className={classes.extraButtonsContainer}>
					{isObject(fileReaderData) && <FileReader {...fileReaderData} />}
					{isObject(downloadData) && <DownloadButton downloadData={downloadData} />}
					{isFullArray(extraButtons) && <ExtraButtons buttons={extraButtons} />}
				</Box>
			:	null}
		</Box>
	);
};

export default withWidth()(SearchBar);

import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

interface PageProps {
	children?: React.ReactNode;
	title?: string;
}

/**
 * Use /pages/page from now on
 * @param props
 * @returns
 * @deprecated
 */
const Page = (props: PageProps) => {
	const { title, children, ...rest } = props;
	const { t } = useTranslation();

	return (
		<div {...rest}>
			<Helmet>
				<title>{title}</title>
			</Helmet>
			{children}
		</div>
	);
};

export default Page;

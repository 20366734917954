import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Divider,
	List,
	ListItem,
	ListItemText,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import i18n from '~lib/i18n';

import SkcUserGroupsService from '../../services/skcUserGroupsService';

interface AccessRuleGeneralInfoCardProps {
	id: string;
}

const service = new SkcUserGroupsService();

const AccessUsersCard = ({ id }: AccessRuleGeneralInfoCardProps) => {
	const { t } = useTranslation();

	const { data, isLoading, error } = useSWR([service.basePath, id], ([_, id]) =>
		service.getUserGroupById(id),
	);

	return (
		<Card sx={{ height: 1, display: 'flex', flexDirection: 'column' }}>
			<CardHeader title={t('ui.label.users')} />
			<Divider />
			<CardContent sx={{ overflow: 'auto', flexGrow: 1 }}>
				{data?.participants.users && data.participants.users.length > 0 ?
					<List disablePadding>
						{data?.participants.users?.map((el, i, arr) => (
							<ListItem key={i} divider={i < arr.length - 1} disablePadding>
								<ListItemText primary={el.label} secondary={el.id} />
							</ListItem>
						))}
					</List>
				:	<NoContentOverlay />}
			</CardContent>
		</Card>
	);
};

const NoContentOverlay = ({ label = i18n.t('noResults') }: { label?: string }) => (
	<Box>
		<Typography variant='body2'>{label}</Typography>
	</Box>
);

export default AccessUsersCard;

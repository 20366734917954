import { useState, memo } from 'react';

import { Typography, List, ListItem, Radio, FormControlLabel, Checkbox, Box } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { FormField } from '../../../../../../components';
import { useWizardFormField, useDebouncedNumberSave } from '../../../../../../shared/hooks';
import { isBoolean, isObject } from '../../../../../../shared/utility';
import AdditionalCharactaristics from '../AdditionalCharactaristics';
import CharacteristicsCard from '../CharacteristicsCard';
import EditButtons from '../EditButtons';
import { useStyles } from '../style';

interface OfficeCharacteristicsProps {
	className?: string;
	itemStep?: number;
	save?(...args: unknown[]): unknown;
	isEdit?: boolean;
	isFinishingEdit?: boolean;
	onClose?(...args: unknown[]): unknown;
	workplaceType?: string;
	numberOfPeople?: string | number;
	hasProjector?: boolean;
	hasWhiteboard?: boolean;
	hasSpeakers?: boolean;
	hasCoffeeTea?: boolean;
	hasMonitor?: boolean;
	hasWiredInternet?: boolean;
	hasWiFi?: boolean;
	hasAppleTV?: boolean;
	hasMicrophone?: boolean;
	isCateringPossible?: boolean;
	hasHostAvailable?: boolean;
	hasIncludingLunch?: boolean;
	hasIncludingDrinks?: boolean;
	hasIncludingFlatAndSparklingWater?: boolean;
	hasIncludingPaperAndPencils?: boolean;
	hasFreeCopying?: boolean;
	hasTechnicalServicesAvailable?: boolean;
	hasConferencePhone?: boolean;
	characteristics?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

// function valuetext(value) {
//   return `${value}`;
// }

const OfficeCharacteristics = memo((props: OfficeCharacteristicsProps) => {
	const {
		itemStep,
		save,
		isEdit,
		isFinishingEdit,
		onClose,
		numberOfPeople,
		workplaceType,
		hasProjector,
		hasWhiteboard,
		hasSpeakers,
		hasCoffeeTea,
		hasMonitor,
		hasWiredInternet,
		hasWiFi,
		hasAppleTV,
		hasMicrophone,
		isCateringPossible,
		hasHostAvailable,
		hasIncludingLunch,
		hasIncludingDrinks,
		hasIncludingFlatAndSparklingWater,
		hasIncludingPaperAndPencils,
		hasFreeCopying,
		hasTechnicalServicesAvailable,
		hasConferencePhone,
		characteristics,
	} = props;
	const { t } = useTranslation();

	const classes = useStyles();

	const numberOfPeopleValue = useWizardFormField(numberOfPeople, {
		required: true,
		minLength: 1,
		maxLength: 5,
		isNumeric: true,
	});
	useDebouncedNumberSave(
		'updatedNumberOfPeople',
		numberOfPeopleValue.value,
		numberOfPeopleValue.isValid,
		save,
	);

	const {
		data: characteristicsData,
		loading: characteristicsLoading,
		error: characteristicsError,
	} = characteristics;
	const readyData =
		isObject(characteristicsData) && !characteristicsLoading && !characteristicsError;
	const workplace =
		readyData ?
			characteristicsData.offices.filter((office) =>
				office.name === 'workplaceType' ? office : null,
			)
		:	null;

	const handleTypeOptions = (event, item) => {
		save({ updatedWorkplaceType: item.name });
	};

	const [officeCharacteristics, setOfficeCharacteristics] = useState({
		hasProjector: false,
		hasWhiteboard: false,
		hasSpeakers: false,
		hasCoffeeTea: false,
		hasMonitor: false,
		hasWiredInternet: false,
		hasWiFi: false,
		hasAppleTV: false,
		hasMicrophone: false,
		isCateringPossible: false,
		hasHostAvailable: false,
		hasIncludingLunch: false,
		hasIncludingDrinks: false,
		hasIncludingFlatAndSparklingWater: false,
		hasIncludingPaperAndPencils: false,
		hasFreeCopying: false,
		hasTechnicalServicesAvailable: false,
		hasConferencePhone: false,
	});

	const handleCharacteristics = (event) => {
		const target = event.target;
		setOfficeCharacteristics({ ...officeCharacteristics, [target.name]: target.checked });
		save({
			[`updated${target.name.charAt(0).toUpperCase() + target.name.substring(1)}`]: target.checked,
		});
	};

	const primaryCharacteristics = [
		{
			label: t('ui.characteristics.hasProjector'),
			checked: isBoolean(hasProjector) ? hasProjector : officeCharacteristics.hasProjector,
			action: handleCharacteristics,
			name: 'hasProjector',
		},
		{
			label: t('ui.characteristics.hasWhiteboard'),
			checked: isBoolean(hasWhiteboard) ? hasWhiteboard : officeCharacteristics.hasWhiteboard,
			action: handleCharacteristics,
			name: 'hasWhiteboard',
		},
		{
			label: t('ui.characteristics.hasSpeakers'),
			checked: isBoolean(hasSpeakers) ? hasSpeakers : officeCharacteristics.hasSpeakers,
			action: handleCharacteristics,
			name: 'hasSpeakers',
		},
		{
			label: t('ui.characteristics.hasCoffeeTea'),
			checked: isBoolean(hasCoffeeTea) ? hasCoffeeTea : officeCharacteristics.hasCoffeeTea,
			action: handleCharacteristics,
			name: 'hasCoffeeTea',
		},
		{
			label: t('ui.characteristics.hasMonitor'),
			checked: isBoolean(hasMonitor) ? hasMonitor : officeCharacteristics.hasMonitor,
			action: handleCharacteristics,
			name: 'hasMonitor',
		},
		{
			label: t('ui.characteristics.hasWiredInternet'),
			checked:
				isBoolean(hasWiredInternet) ? hasWiredInternet : officeCharacteristics.hasWiredInternet,
			action: handleCharacteristics,
			name: 'hasWiredInternet',
		},
		{
			label: t('ui.characteristics.hasWiFi'),
			checked: isBoolean(hasWiFi) ? hasWiFi : officeCharacteristics.hasWiFi,
			action: handleCharacteristics,
			name: 'hasWiFi',
		},
		{
			label: t('ui.characteristics.hasAppleTV'),
			checked: isBoolean(hasAppleTV) ? hasAppleTV : officeCharacteristics.hasAppleTV,
			action: handleCharacteristics,
			name: 'hasAppleTV',
		},
		{
			label: t('ui.characteristics.hasMicrophone'),
			checked: isBoolean(hasMicrophone) ? hasMicrophone : officeCharacteristics.hasMicrophone,
			action: handleCharacteristics,
			name: 'hasMicrophone',
		},
	];

	const characteristicsSecondary = [
		{
			label: t('ui.characteristics.isCateringPossible'),
			checked:
				isBoolean(isCateringPossible) ? isCateringPossible : (
					officeCharacteristics.isCateringPossible
				),
			action: handleCharacteristics,
			name: 'isCateringPossible',
		},
		{
			label: t('ui.characteristics.hasHostAvailable'),
			checked:
				isBoolean(hasHostAvailable) ? hasHostAvailable : officeCharacteristics.hasHostAvailable,
			action: handleCharacteristics,
			name: 'hasHostAvailable',
		},
		{
			label: t('ui.characteristics.hasIncludingLunch'),
			checked:
				isBoolean(hasIncludingLunch) ? hasIncludingLunch : officeCharacteristics.hasIncludingLunch,
			action: handleCharacteristics,
			name: 'hasIncludingLunch',
		},
		{
			label: t('ui.characteristics.hasIncludingDrinks'),
			checked:
				isBoolean(hasIncludingDrinks) ? hasIncludingDrinks : (
					officeCharacteristics.hasIncludingDrinks
				),
			action: handleCharacteristics,
			name: 'hasIncludingDrinks',
		},
		{
			label: t('ui.characteristics.hasIncludingFlatAndSparklingWater'),
			checked:
				isBoolean(hasIncludingFlatAndSparklingWater) ?
					hasIncludingFlatAndSparklingWater
				:	officeCharacteristics.hasIncludingFlatAndSparklingWater,
			action: handleCharacteristics,
			name: 'hasIncludingFlatAndSparklingWater',
		},
		{
			label: t('ui.characteristics.hasIncludingPaperAndPencils'),
			checked:
				isBoolean(hasIncludingPaperAndPencils) ?
					hasIncludingPaperAndPencils
				:	officeCharacteristics.hasIncludingPaperAndPencils,
			action: handleCharacteristics,
			name: 'hasIncludingPaperAndPencils',
		},
		{
			label: t('ui.characteristics.hasFreeCopying'),
			checked: isBoolean(hasFreeCopying) ? hasFreeCopying : officeCharacteristics.hasFreeCopying,
			action: handleCharacteristics,
			name: 'hasFreeCopying',
		},
		{
			label: t('ui.characteristics.hasTechnicalServicesAvailable'),
			checked:
				isBoolean(hasTechnicalServicesAvailable) ?
					hasTechnicalServicesAvailable
				:	officeCharacteristics.hasTechnicalServicesAvailable,
			action: handleCharacteristics,
			name: 'hasTechnicalServicesAvailable',
		},
		{
			label: t('ui.characteristics.hasConferencePhone'),
			checked:
				isBoolean(hasConferencePhone) ? hasConferencePhone : (
					officeCharacteristics.hasConferencePhone
				),
			action: handleCharacteristics,
			name: 'hasConferencePhone',
		},
	];

	return (
		<>
			<CharacteristicsCard isEdit={isEdit} itemStep={itemStep}>
				<Typography className={classes.capacitySpacing} variant='h5'>
					{t('views.addItem.characteristicsOffice.whatOfficeCapacity')}?
				</Typography>
				<Box className={classes.listComponent}>
					<Box mb={2} width='180px'>
						<FormField
							fullWidth={true}
							hideCharacterCounter={!numberOfPeopleValue.hasFocus}
							label={t('ui.characteristics.numberOfPeople')}
							maxLength={5}
							name='numberOfPeople'
							required
							variable={numberOfPeopleValue}
						/>
					</Box>
				</Box>
				<Typography className={classes.capacitySpacing} variant='h5'>
					{t('views.addItem.characteristicsOffice.whatTypeOfOffice')}?
				</Typography>
				<List className={classes.listComponent}>
					{readyData &&
						workplace[0].enumValues.map((office) => (
							<ListItem
								button={true}
								className={clsx(classes.option, {
									[classes.selectedOption]: workplaceType === office.name,
								})}
								key={office.id}
								onClick={(event) => handleTypeOptions(event, office)}
							>
								<Radio
									checked={workplaceType === office.name}
									className={classes.optionRadio}
									onChange={(event) => handleTypeOptions(event, office)}
									value={office.name}
								/>
								<div className={classes.optionDetails}>
									<Typography className={classes.oneLine} variant='h5'>
										{t(`ui.characteristics.${office.name}`)}
									</Typography>
								</div>
							</ListItem>
						))}
				</List>
			</CharacteristicsCard>
			<AdditionalCharactaristics
				additionalChildren={characteristicsSecondary.map((item) => (
					<FormControlLabel
						control={<Checkbox checked={item.checked} name={item.name} onChange={item.action} />}
						key={item.name}
						label={item.label}
					></FormControlLabel>
				))}
				isEdit={isEdit}
			>
				{primaryCharacteristics.map((item) => (
					<FormControlLabel
						control={<Checkbox checked={item.checked} name={item.name} onChange={item.action} />}
						key={item.name}
						label={item.label}
					></FormControlLabel>
				))}
			</AdditionalCharactaristics>
			{isEdit ?
				<EditButtons isFinishingEdit={isFinishingEdit} onClose={onClose} />
			:	null}
		</>
	);
});

const mapStateToProps = (state) => {
	return {
		characteristics: state.details.characteristics,
	};
};

export default connect(mapStateToProps)(OfficeCharacteristics);

export const conditionStates = [
	'deleteUser',
	'disableUser',
	'updateUserRole',
	'removeUserFromOrganisation',
	'deleteHub',
	'updateHubDetails',
	'deleteOrganisation',
	'disableOrganisation',
	'deleteItem',
	'deleteItemInstance',
	'updatedBookingDetails',
	'canceledBooking',
	'checkedOrganisationNameField',
	'checkedOrganisationVATField',
	'checkedOrganisationCOCField',
	'checkedOrganisationIBANField',
	'checkedOrganisationEnterpriseField',
	'validatedRoomNumber',
	'validatedBikeLockId',
	'validatedBikeNumber',
	'validatedFrameNumber',
	'validatedLicensePlate',
	'validatedCarLockId',
	'validatedVinNumber',
	'deletedPricingModel',
	'deleteTerm',
	'deletePolicy',
	'deletedUserGroup',
	'movedUserToDefaultGroup',
	'deleteInvitations',
	'payBillingPeriod',
	'deleteEmailDomain',
	'deviceAccess',
	'unlinkDevices',
	'approveUserLicense',
	'rejectUserLicense',
	'updateVehicleStatus',
	'confirmBooking',
	'rejectBooking',
	'refundBooking',
	'addedNfcTag',
	'deletedNfcTag',
	'editEmailDomain',
	'addEmailDomains',
	'updateEmailDomainLinking',
	'validatedParkingNumber',
	'validatedAssetNumber',
	'validateEmailDomain',
	'validatedTrailerNumber',
	'validatedTrailerVinNumber',
	'updatedItemInstance',
	'deleteTankCards',
	'deleteKeyTag',
	'assignUserContract',
	'createAnonymousUser',
	'deleteContract',
	'extendContract',
	'patchTrip',
	'businessUsage',
	'nfcServiceFlag',
	'removeNfcServiceFlag',
	'validateBoatNumber',
	'resetLicensesAttempts',
	'instanceRestriction',
	'bookingMileage',
	'removeAttentions',
	'hardDeleteUser',
	'verifyEmail',
	'updateCooldown',
	'unlinkNfc',
	'deleteNfc',
	'unlinkItemGroup',
	'unlinkAllItemGroup',
	'removeAccessDevices',
];

export const detailsStates = [
	'addedUser',
	'user',
	'currentUser',
	'updateUser',
	'userPatch',
	'exportUsers',
	'addedOrganisation',
	'addedTicket',
	'addedTicketOld',
	'addHubDetails',
	'placesAutocomplete',
	'place',
	'addUserGroup',
	'validateItemName',
	'addItem',
	'addedUserImage',
	'addItemImages',
	'addHubImage',
	'addedTermsAndConditions',
	'fetchTerm',
	'updateTerm',
	'organisation',
	'verifyOrganisation',
	'rejectOrganisation',
	'hubDetails',
	'userGroup',
	'itemDetails',
	'planboardBookings',
	'addedPlanboardBooking',
	'addInstance',
	'addedCategoryIcon',
	'removedCategoryIcon',
	'addPricingModels',
	'fetchItemImage',
	'addedPolicy',
	'updatePolicy',
	'addCharacteristicsIcon',
	'addCharacteristicsIconNested',
	'removeCharacteristicsIcon',
	'removeCharacteristicsIconNested',
	'fetchPrice',
	'itemPatch',
	'fetchPolicy',
	'characteristics',
	'updateItemImage',
	'userRole',
	'currentUserOrganisationStatus',
	'addedPartnership',
	'partner',
	'updatedPartnership',
	'category',
	'updateOrganisationImage',
	'updatedPricingModel',
	'validatedUserGroupName',
	'validatedPolicyName',
	'assignedItemsToUserGroup',
	'unassignOrCancelItem',
	'assignedItemsToPartner',
	'invitedUsers',
	'emailValidations',
	'updateOrganisation',
	'updatedUserGroup',
	'personalPaymentMethodUser',
	'businessPaymentMethodUser',
	'fetchBooking',
	'itemPrice',
	'bookingPrice',
	'paymentMethodsUser',
	'planboardItemDetails',
	'assignedUsersToUserGroup',
	'fetchInstance',
	'stopBooking',
	'userLicense',
	'startBooking',
	'getTicket',
	'updateTicket',
	'validatedEditedBookingCategory',
	'validatedBookingCategory',
	'updatedInstanceAvailability',
	'itemInstanceQrCode',
	'planboardBooking',
	'updateTicketActivities',
	'calculatedPrice',
	'currentRevenue',
	'personalRevenue',
	'businessRevenue',
	'updatedBookingNote',
	'itemInstanceAvailability',
	'bookingAvailability',
	'currentPersonalBillingPeriod',
	'currentExpenses',
	'businessExpenses',
	'personalBillingPeriodByDate',
	'usersPersonalBillingPeriodByDate',
	'updateInstance',
	'patchedOrganisation',
	'updatePublicShare',
	'emailDomain',
	'linkDevices',
	'assignDevices',
	'unassignDevices',
	'fetchDevice',
	'fetchPendingLicenseDetails',
	'vehicleStatus',
	'updateInstanceLocation',
	'updateInstancesLocation',
	'updatedBookingLocation',
	'fetchDeviceHeartbeats',
	'fetchDeviceLocation',
	'NFCTags',
	'addInstances',
	'allQRCodes',
	'exportBooking',
	'deviceUnlockCode',
	'updateNFCTags',
	'updateLocationImage',
	'updateLocation',
	'fetchUserImage',
	'fetchItemPolicies',
	'addUnavailability',
	'fetchUnavailability',
	'cancelUnavailabilities',
	'updateUnavailability',
	'stopUnavailability',
	'validateUnavailability',
	'linkDockingLocation',
	'unlinkDockingLocation',
	'trip',
	'tripsInsights',
	'itemInstancesTrip',
	'bookingTrip',
	'patchInstance',
	'exportBookingTrip',
	'exportInstanceTrip',
	'exportDeviceTrip',
	'exportBookingTripDetails',
	'exportInstanceTripDetails',
	'exportDeviceTripDetails',
	'addTankCard',
	'addKeyTag',
	'addContract',
	'contract',
	'terminateContract',
	'rejectContract',
	'dashboardItemsOverview',
	'dashboardBookingsOverview',
	'dashboardStatus',
	'dashboardTicketsStatus',
	'userLicensesHold',
	'sharingsUpdate',
	'stopSharingExternalItems',
	'maintenanceAvailability',
	'refundPrice',
	'instanceHeartbeats',
	'internalItem',
	'externalItem',
	'publicItem',
	'businessPricing',
	'itemSharings',
	'updateDiscount',
	'resetBookingMileage',
	'linkNfc',
	'addNfc',
	'linkDeviceToItemGroup',
	'updateDeviceName',
	'exportUsers'
];

export const listStates = [
	'users',
	'organisations',
	'categories',
	'userRoles',
	'itemInstances',
	'itemInstancesList',
	'userGroupItemInstances',
	'fetchUserImage',
	'fetchLocationImage',
	'ticketDefects',
	'countries',
	'getTicketActivities',
	'subscriptions',
	'devicesTypesFilter',
	'devicesFirmWarVersionsFilter',
	'devicesUnlockTypesFilter',
	'bookingTypes',
	'fetchHardwareversions',
	'fetchFleetsInstances',
	'fetchFleetsInstancesList',
	'fetchFleetsInstancesListSearch',
	'latestActivity',
	'tripCoordinates',
	'instanceTripCoordinates',
	'bookingTripCoordinates',
	'dashboardActions',
	'internalSharingInstances',
	'publicSharingInstances',
	'externalSharingInstances',
	'externalAvailableSharing',
	'internalAvailableSharing',
	'publicAvailableSharing',
	'instanceAccess',
	'searchInstance',
	'searchUser',
	'itemGroupDevices',
];

export const pagedStates = [
	'users',
	'organisations',
	'hubs',
	'userGroups',
	'items',
	'sharedItems',
	'terms',
	'itemInstances',
	'assignedItems',
	'pricingModels',
	'policies',
	'bookings',
	'itemActivities',
	'fetchPartnerships',
	'availablePartnerships',
	'assignedUsers',
	'invitations',
	'fetchTickets',
	'revenues',
	'expenses',
	'billings',
	'revenueBookings',
	'expenseBookings',
	'userBillingBookings',
	'devices',
	'allIteminstances',
	'licenses',
	'fetchLinkedDevices',
	'fetchHeartbeats',
	'emailDomains',
	'assignableInstances',
	'fetchNfcCards',
	'fetchUnavailabilities',
	'fetchDockingLocations',
	'fetchUnrelatedHubs',
	'fetchDevicesOrganisations',
	'deviceTrips',
	'itemInstanceTrips',
	'bookingTrips',
	'contracts',
	'dashboardBookings',
	'dashboardTickets',
	'dashboardUnavailabilities',
	'sharings',
	'internalSharingItems',
	'publicSharingItems',
	'sharingsConsumers',
	'consumerSharingItems',
	'organisationsSlim',
	'pricingModelsSlim',
	'policiesSlim',
	'termsSlim',
	'itemsSlim',
	'userGroupsSlim',
	'planboardItems',
	'userLicensesActivities',
];

export const planboardStates = ['planboardBookings', 'planboardItemsToAdd'];

export const blobStates = [
	'billingReport',
	'addCSVInstances',
	'removeItemInstances',
	'addNfcCsvImports',
	'dynamicLinkQr',
];

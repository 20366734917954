import { Stack, Typography } from '@mui/material';

import EmptyListIcon from '~components/elements/EmptyState/assets/booking.svg?react';
import i18n from '~lib/i18n';

import Page from '../page';

interface PageNotFoundPageProps {
	title?: string;
}

const PageNotFoundPage = ({ title = i18n.t('notFound') }: PageNotFoundPageProps) => {
	return (
		<Page title={title}>
			<Stack
				width={1}
				height={1}
				display='flex'
				alignItems='center'
				justifyContent='center'
				direction='column'
				spacing={1}
			>
				<EmptyListIcon />
				<Typography variant='h6'>{title}</Typography>
			</Stack>
		</Page>
	);
};

export default PageNotFoundPage;

import {
	Card,
	CardContent,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Typography,
	Box,
	Link,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';

import { commaTimeStrings } from '../../../../../../shared/datetime';
import { handleDeviceTypes, handleLockType } from '../../../../../../shared/utility';
import { useStyles } from '../style';

interface DeviceInfoProps {
	instanceData?: object;
	itemInstanceQrCode?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onFetchItemInstanceQrCode?(...args: unknown[]): unknown;
}

const DeviceInfo = (props: DeviceInfoProps) => {
	const { instanceData } = props;
	const location = useLocation();
	const { t } = useTranslation();

	const classes = useStyles();

	const handleUID = () => (
		<Link
			component={RouterLink}
			to={`/devices/${instanceData.deviceId}/summary`}
			state={{ from: location.pathname }}
		>
			{instanceData.deviceId}
		</Link>
	);
	const deviceInfoData = [
		{ label: 'ui.label.dateLinked', content: commaTimeStrings(instanceData.device.dateLinked) },
		{ label: 'ui.label.HwUID', content: handleUID() },
		{
			label: 'ui.label.deviceType',
			content: handleLockType(instanceData.device.deviceType),
		},
		{ label: 'ui.label.version', content: instanceData.device.hardwareVersion },
		{
			label: 'ui.label.communication',
			content: instanceData.device.communicationType
				.map((type) => handleDeviceTypes(type))
				.join(', '),
		},
		{
			label: 'views.organisationManagement.devices.unlockType',
			content: instanceData.device.identificationType
				.map((type) => handleDeviceTypes(type))
				.join(', '),
		},
	];
	return (
		<Card className={classes.maiCard}>
			<Box display='flex' justifyContent='space-between' pb={2} pl={3} pr={3} pt={2}>
				<Typography variant='h5'>{t('ui.label.deviceInfo')}</Typography>
			</Box>
			<Divider />
			<CardContent className={classes.content}>
				<Table>
					<TableBody>
						{instanceData.device ?
							<>
								{deviceInfoData.map((item, index) => (
									<TableRow key={item.label}>
										<TableCell className={index === 5 ? classes.bottomCell : ''}>
											{index === 3 ? `HW ${t(item.label)}` : t(item.label)}
										</TableCell>
										<TableCell className={index === 5 ? classes.bottomCell : ''}>
											{item?.content ? item.content : '-'}
										</TableCell>
									</TableRow>
								))}
							</>
						:	null}
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		itemInstanceQrCode: state.details.itemInstanceQrCode,
	};
};

export default connect(mapStateToProps, null)(DeviceInfo);

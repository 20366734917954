import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import {
	AccessTime as AccessTimeIcon,
	AssignmentTurnedInOutlined as AssignmentTurnedInOutlinedIcon,
	Block as BlockIcon,
	ErrorOutline as ErrorOutlineIcon,
	Visibility as VisibilityIcon,
} from '@mui/icons-material';
import { Box, Card, Typography } from '@mui/material';
import clsx from 'clsx';
import { useAtomValue } from 'jotai';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { userInfoAtom } from '~atoms';
import { Tooltip } from '~components';
import HighLightedSparkLineChart from '~components/charts/highlightedSparkLineChart';
import { useAuthorize } from '~features/authentication';

import { useStyles } from './style';
import { LoadingBar } from '../../../components';
import { isEarlierThan, sameDates, modifyDate } from '../../../shared/datetime';
import { isObject, handleHubReference } from '../../../shared/utility';

interface FullCalendarResourceTimelineProps {
	className?: string;
	chartData?: object;
	resourceArray?: unknown[];
	eventArray?: unknown[];
	events?: object;
	calendarRef?: object;
	refreshing?: boolean;
}

const FullCalendarResourceTimeline = (props: FullCalendarResourceTimelineProps) => {
	const {
		chartData,
		resourceArray,
		eventArray,
		events,
		calendarRef,
	} = props;
	const { t } = useTranslation();
	const classes = useStyles();
	const navigate = useNavigate();
	const { isSuperAdmin } = useAuthorize();
	const userInfo = useAtomValue(userInfoAtom);

	/* * * * * * * * * *
	 * UTILITY METHODS *
	 * * * * * * * * * */
	const getDateTimeString = (dateTime, stringifyTimeOnly = false) => {
		const date = new Date(dateTime);
		const monthsArray = [
			t('ui.months.january'),
			t('ui.months.february'),
			t('ui.months.march'),
			t('ui.months.april'),
			t('ui.months.may'),
			t('ui.months.june'),
			t('ui.months.july'),
			t('ui.months.august'),
			t('ui.months.september'),
			t('ui.months.october'),
			t('ui.months.november'),
			t('ui.months.december'),
		];

		const dateString = `${date.getDate()} ${monthsArray[date.getMonth()]} ${date.getFullYear()}, ${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}`;
		const timeString = `${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}`;
		return stringifyTimeOnly ? timeString : dateString;
	};

	/* * * * * * * * * * * * * *
	 * RESOURCE RENDER METHOD  *
	 * * * * * * * * * * * * * */
	const renderResource = (info) => {
		const resource = info.resource?._resource?.extendedProps;

		const resourceClassName = resource?.className;
		const childOrSingleTrack =
			resourceClassName === 'child-track' || resourceClassName === 'single-track';

		const resourceAddress =
			info.resource?._resource?.extendedProps?.hubReference ?
				`${handleHubReference(info.resource?._resource?.extendedProps?.hubReference)}`
			:	null;
		const itemPathname =
			resource.className === 'child-track' ?
				`/item-management/${info.resource._resource.parentId}/instance/${info.resource._resource.id.split('-')[1]}/summary`
			:	`/item-management/items/${info.resource._resource.id}/summary`;

		const resourceNeedsAttention = resourceClassName === 'parent-track' &&
			resource?.needsAttention && (
				<Tooltip
					header={t('views.planboard.info.attentionRequired.title')}
					title={t('views.planboard.info.attentionRequired.description')}
					placement='bottom'
				>
					<ErrorOutlineIcon className={classes.warningIcon} size='small' />
				</Tooltip>
			);

		const resourceUnavailable = childOrSingleTrack &&
			resource?.availabilityState === 'isUnavailable' && (
				<Tooltip
					header={t('views.planboard.info.attentionUnavailable.title')}
					title={t('views.planboard.info.attentionUnavailable.description')}
					placement='bottom'
				>
					<BlockIcon className={classes.warningIcon} size='small' />
				</Tooltip>
			);

		const resourceOvertime = childOrSingleTrack &&
			resource?.availabilityState === 'hasOvertimeBooking' && (
				<Tooltip
					header={t('views.planboard.info.attentionOvertime.title')}
					title={t('views.planboard.info.attentionOvertime.description')}
					placement='bottom'
				>
					<AccessTimeIcon className={classes.warningIcon} size='small' />
				</Tooltip>
			);

		return (
			<>
				{resourceClassName === 'loading-track' ?
					<span className={'loading-track'}>
						<LoadingBar />
					</span>
				:	<>
						<div className={classes.resourceText}>
							<Typography
								className={classes.tableLink}
								onClick={() => navigate(itemPathname, { state: { from: '/planboard' } })}
							>
								{info.fieldValue}
							</Typography>
							{resourceAddress && <Typography variant={'body2'}>{resourceAddress}</Typography>}
						</div>
						<div>
							{resourceNeedsAttention}
							{resourceUnavailable}
							{resourceOvertime}
						</div>
					</>
				}
			</>
		);
	};

	/* * * * * * * * * * * *
	 * EVENT RENDER METHOD *
	 * * * * * * * * * * * */
	const eventRender = (eventObject) => {
		const eventToRender = eventArray.find(
			(event) => `${event.id}` === eventObject.event._def.publicId,
		);

		const bookingPeriod =
			sameDates(new Date(eventToRender?.start), new Date(eventToRender?.end), false, true) ?
				getDateTimeString(eventToRender?.start, true).concat(
					' - ',
					getDateTimeString(eventToRender?.end, true),
				)
			:	getDateTimeString(eventToRender?.start).concat(' - ', getDateTimeString(eventToRender?.end));
		const displayTimeAndDetail =
			eventToRender?.title !== 'cooldown' &&
			eventToRender?.access === 'full' &&
			eventToRender?.status !== 'overtime';
		const isUnavailability = eventToRender?.title === 'unavailability';

		return (
			<div
				className={clsx({
					[classes.overlap]: true,
					[eventToRender?.className]: !!eventToRender?.className,
					[classes.unclickable]: eventToRender?.isBeingUpdated,
				})}
			>
				{displayTimeAndDetail && (
					<>
						<Tooltip
							header={t('views.planboard.info.bookingDetails.title')}
							title={`${bookingPeriod}, ${eventToRender.title}`}
							placement='bottom'
						>
							<Box className={'infoPopupHoverSpan'} />
						</Tooltip>
						<div className='fc-event-time'> {bookingPeriod} </div>
						<div className='fc-event-title-container'>
							<div className='fc-event-title fc-sticky'> {eventToRender?.title} </div>
						</div>
					</>
				)}
				{eventToRender?.title === 'cooldown' && (
					<div>
						<Tooltip
							header={`${eventToRender.cooldownBufferMinutes} 
                                ${t('views.planboard.info.bufferPeriod.titlee')}`}
							title={t('views.planboard.info.bufferPeriod.description')}
							placement='bottom'
						>
							<Box className={'infoPopupHoverSpan'} />
						</Tooltip>
					</div>
				)}
				{eventToRender?.access !== 'full' && !isUnavailability && (
					<>
						{eventToRender?.status === 'overtime' ?
							<Tooltip
								header={t('views.planboard.info.obscuredOvertimeBooking.titlee')}
								title={t('views.planboard.info.obscuredOvertimeBooking.description')}
								placement='bottom'
							>
								<Box className={'infoPopupHoverSpan'} />
							</Tooltip>
						:	<Tooltip
								header={t('views.planboard.info.obscuredBooking.title')}
								title={t('views.planboard.info.obscuredBooking.description')}
								placement='bottom'
							>
								<Box className={'infoPopupHoverSpan'} />
							</Tooltip>
						}
						<div className='fc-event-time'> {bookingPeriod}</div>
						<VisibilityIcon className={classes.bookingIcon} />
					</>
				)}
				{isUnavailability && (
					<>
						<div className='fc-event-time'> {bookingPeriod}</div>
						<Box alignItems='center' display='flex'>
							<Tooltip
								header={t('views.planboard.info.unavailability.title')}
								title={`${bookingPeriod}${eventObject.event._def?.extendedProps?.adminNote ? ',' : ''} ${eventObject.event._def?.extendedProps?.adminNote ? eventObject.event._def?.extendedProps?.adminNote : ''}`}
								placement='bottom'
							>
								<Box className={'infoPopupHoverSpan'} />
							</Tooltip>
							<AssignmentTurnedInOutlinedIcon className={classes.bookingIcon} />
							<Box className='fc-event-title-container' color='#15263E' pl={1}>
								{
									<Typography className={classes.unavailability}>
										{eventObject?.event?._def.publicId}
									</Typography>
								}
							</Box>
						</Box>
					</>
				)}
			</div>
		);
	};

	/* * * * * * * * * * * * * * * *
	 * AVAILABILITY GRAPH METHODS  *
	 * * * * * * * * * * * * * * * */
	const parentTrackContent = (dataObject) => {
		const chartObjectId = dataObject?.resource?._resource?.id;
		const isParentTrack =
			dataObject?.resource?._resource?.extendedProps?.className === 'parent-track';

		if (
			!!chartObjectId &&
			isParentTrack &&
			isObject(chartData) &&
			isObject(chartData[chartObjectId])
		) {
			return (
				<Box sx={{ width: 1, height: 60, display: 'flex', alignItems: 'flex-end' }}>
					<HighLightedSparkLineChart data={chartData[chartObjectId].bookingData} max={chartData[chartObjectId].bookingTotal} />
				</Box>
			);
		}
	};

	return (
		<Card className={classes.root}>
			<FullCalendar
				displayEventEnd
				displayEventTime
				editable
				eventAllow={(dropInfo, draggedEvent) => {
					return (
						dropInfo.resource.id.split('-')[0] === draggedEvent._def.resourceIds[0].split('-')[0] &&
						!isEarlierThan(dropInfo.start) &&
						!(
							!isSuperAdmin() && //Require external admins to make bookings that require confirmation at least 15 minutes in advance
							dropInfo.resource._resource.extendedProps.hubReference?.organisationReference?.id != null &&
							userInfo?.organisation.id !=
								dropInfo.resource._resource.extendedProps.hubReference?.organisationReference?.id &&
							dropInfo.resource._resource.extendedProps.policies?.requireHostConfirmation &&
							isEarlierThan(dropInfo.start, modifyDate(new Date(), { minutes: '+15' }))
						) &&
						(!dropInfo.resource._resource.extendedProps.availabilityState ||
							dropInfo.resource._resource.extendedProps.availabilityState === 'available' ||
							dropInfo.resource._resource.extendedProps.availabilityState === 'hasOvertimeBooking')
					);
				}}
				eventClassNames={classes.events}
				eventClick={events.clickEvent}
				eventContent={eventRender}
				eventDrop={events.dropEvent}
				eventOverlap={(stillEvent, movingEvent) => {
					return stillEvent.id.split('-')[1] === movingEvent.id;
				}}
				eventResizableFromStart={true}
				eventResize={events.resizeEvent}
				eventTimeFormat={{ hour: '2-digit', minute: '2-digit', meridiem: false, hour12: false }}
				events={eventArray}
				headerToolbar={false}
				height={'100%'}
				initialView='resourceTimeline'
				locale='en-GB'
				nowIndicator={true}
				plugins={[resourceTimelinePlugin, interactionPlugin]}
				ref={calendarRef}
				resourceAreaHeaderContent={' '}
				resourceAreaWidth={'25%'}
				resourceLabelContent={renderResource}
				resourceLaneContent={parentTrackContent}
				resources={resourceArray}
				resourcesInitiallyExpanded={false}
				schedulerLicenseKey={import.meta.env.VITE_FULLCALENDAR_LICENSE_KEY}
				scrollTime={moment().add(-2, 'hours').format('HH:mm:ss')}
				select={events.select}
				selectAllow={(selectInfo) => {
					return (
						(!selectInfo.resource.extendedProps.className ||
							selectInfo.resource.extendedProps.className !== 'parent-track') &&
						!isEarlierThan(selectInfo.start) &&
						(!selectInfo.resource._resource.extendedProps.availabilityState ||
							selectInfo.resource._resource.extendedProps.availabilityState === 'available' ||
							selectInfo.resource._resource.extendedProps.availabilityState ===
								'hasOvertimeBooking')
					);
				}}
				selectable
				slotEventOverlap={true}
				slotLabelClassNames={classes.slotLabel}
				slotLabelFormat={{ hour: '2-digit', minute: '2-digit', meridiem: false, hour12: false }}
				slotLabelInterval={'00:30'}
				slotMinWidth={30}
			/>
		</Card>
	);
};

export default FullCalendarResourceTimeline;

import { Suspense } from 'react';

import { Box, Paper, useMediaQuery, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { LoadingFallback } from '~components';

import AuthenticationHeader from './headers/authenticationHeader';

interface AuthenticationLayoutProps {
	disablePaper?: boolean;
}

const AuthenticationLayout = ({ disablePaper = false }: AuthenticationLayoutProps) => {
	const theme = useTheme();
	const largerThanMd = useMediaQuery(theme.breakpoints.up('md'));

	const content = (
		<Suspense
			fallback={
				<LoadingFallback description={import.meta.env.DEV ? 'loading auth layout' : undefined} />
			}
		>
			<Outlet />
		</Suspense>
	);

	return (
		<Box display='flex' flexDirection='column' width={1} height={1}>
			<AuthenticationHeader />
			<Box display='flex' height={1} flexGrow={1} p={2} alignItems='center' justifyContent='center'>
				{largerThanMd && !disablePaper ?
					<Paper elevation={1} sx={{ p: 3 }}>
						{content}
					</Paper>
				:	content}
			</Box>
		</Box>
	);
};

export default AuthenticationLayout;

import { Skeleton, Stack, StackProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface FormContainerProps extends StackProps {
	loading?: boolean;
}

const FormContainer = ({
	children,
	spacing = 2,
	my = 1,
	loading = false,
	...stackProps
}: FormContainerProps) => {
	const { t } = useTranslation();

	if (loading) {
		return <LoadingOverlay />;
	}

	return (
		<Stack {...stackProps} spacing={spacing} my={my}>
			{children}
		</Stack>
	);
};

const LoadingOverlay = () => (
	<Stack spacing={2} height={1}>
		<Skeleton animation='wave' variant='rounded' height={30} />
		<Skeleton animation='wave' variant='rounded' height={30} />
	</Stack>
);

export default FormContainer;

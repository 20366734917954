import { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react';

import { ajvResolver } from '@hookform/resolvers/ajv';
import { Avatar, ListItem, ListItemAvatar, ListItemText, Paper } from '@mui/material';
import { JSONSchemaType } from 'ajv';
import { Controller, useForm } from 'react-hook-form';

import { FormContainer } from '~components';
import { useFormContainerState } from '~components/dialogs/formContainerProvider';
import { NfcAutocomplete, NfcTag } from '~features/nfc';
import { IdReference } from '~interfaces';
import { FormWrapperRefProps } from '~interfaces/refProps';
import { getInitials } from '~utils/stringUtils';

import schema from './linkNfcTagToUserSchema.json';
import useUserData from '../../hooks/useUserData';

interface LinkNfcTagToUserData {
	user: IdReference;
	nfcTag: NfcTag;
}

interface LinkNfcTagToUserFormProps {
	onSubmit?: (value: LinkNfcTagToUserData) => void;
	userId: string;
}

const LinkNfcTagToUserForm = forwardRef<FormWrapperRefProps, LinkNfcTagToUserFormProps>(
	({ userId, onSubmit }, ref) => {
		const { data, isLoading, error } = useUserData(userId);

		const { setDisabled } = useFormContainerState();
		const { getValues, control, formState, reset } = useForm<LinkNfcTagToUserData>({
			defaultValues: useMemo(() => ({ user: data }), [data]),
			mode: 'onChange',
			resolver: ajvResolver(schema as JSONSchemaType<any>),
		});

		useEffect(() => {
			if (data) {
				// Used to reset the useform, otherwise the page won't properly reload
				reset({ user: data });
			}
		}, [data]);

		useImperativeHandle(
			ref,
			() => ({
				onSubmit: () => onSubmit?.(getValues()),
			}),
			[onSubmit],
		);

		useEffect(() => {
			setDisabled?.(!formState.isValid);
		}, [formState.isValid, setDisabled]);

		return (
			<FormContainer loading={isLoading}>
				<Paper>
					<ListItem>
						<ListItemAvatar>
							<Avatar
								src={data.imageUrl}
							>
								{getInitials(`${data?.firstName} ${data?.lastName}`)}
							</Avatar>
						</ListItemAvatar>
						<ListItemText primary={data.label} secondary={data.email} />
					</ListItem>
				</Paper>
				<Controller
					name='nfcTag'
					control={control}
					render={({ field }) => (
						<NfcAutocomplete
							{...field}
							organisation={data.organisation}
							enableInfiniteScroll
							onChange={(_, newValue) => field.onChange(newValue)}
							linkedFilter={false}
						/>
					)}
				/>
			</FormContainer>
		);
	},
);
LinkNfcTagToUserForm.displayName = 'LinkNfcTagToUserForm';

export default LinkNfcTagToUserForm;

import { Drawer as MuiDrawer, DrawerProps } from '@mui/material';

const Drawer = ({
	anchor = 'right',
	...props
}: DrawerProps) => {
	return (
		<MuiDrawer
			{...props}
			anchor={anchor}
			slotProps={{
				paper: {
					sx: {
						width: 450,
						maxWidth: '100%',
						padding: 2
					}
				}
			}}
			sx={{
				padding: 1
			}}
		/>
	)
}

export default Drawer;

import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import { Typography, Hidden, Box } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { useStyles } from './style';
import { AdjustedSkeleton, StyledButton } from '../../../../../../components';
import DownloadButton from '../../../../../../components/elements/SearchBar/DownloadButton';
import { useDetailPageBackButton } from '../../../../../../shared/hooks';
import { isObject, isUndefined } from '../../../../../../shared/utility';
import * as actions from '../../../../../../store/actions/index';

interface HeaderProps {
	className?: string;
	loading?: boolean;
	onResetState?(...args: unknown[]): unknown;
	downloadData?: object;
	tripId?: string;
}

const Header = (props: HeaderProps) => {
	const { className, tripId, loading, onResetState, downloadData } = props;
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();

	const classes = useStyles();

	const returnButton = useDetailPageBackButton(location);

	const handleBackButtonChange = () => {
		if (isUndefined(location.state)) {
			navigate('/dashboard');
		} else {
			if (isUndefined(location?.state?.from)) {
				navigate('/dashboard');
			} else {
				navigate(location.state.from);
				onResetState('trip');
				onResetState('tripCoordinates');
			}
		}
	};

	return (
		<div className={clsx(classes.root, className)}>
			<Typography component='h2' gutterBottom variant='overline'>
				<Hidden only={['xs', 'sm']}>
					<StyledButton
						className={classes.backButton}
						onClick={handleBackButtonChange}
						startIcon={<KeyboardArrowLeftOutlinedIcon />}
						variant='inline-default'
					>
						{returnButton.isBackButton ? t('ui.back') : t('ui.label.trips')}
					</StyledButton>
				</Hidden>
			</Typography>
			<Box display='flex' justifyContent='space-between'>
				<Typography component='h1' variant='h3'>
					{loading ?
						<AdjustedSkeleton animation='wave' width={200} />
					:	`${t('ui.label.header.trip')} ${tripId}`}
				</Typography>
				{isObject(downloadData) ?
					<DownloadButton downloadData={downloadData} />
				:	null}
			</Box>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		onResetState: (identifier) => dispatch(actions.resetState(identifier)),
	};
};

export default connect(null, mapDispatchToProps)(Header);

import {
	GridActionsCellItem as MuiGridActionsCellItem,
	GridActionsCellItemProps,
} from '@mui/x-data-grid';

import { Tooltip } from '~components';

/**
 * Just the basic MUI GridActionsCellItem wrapped in a tooltip
 * @param props
 * @returns
 */
const GridActionsCellItem = (props: GridActionsCellItemProps) => {
	if (props.hidden) {
		return undefined;
	}

	return (
		<Tooltip
			title={props.showInMenu ? '' : props.label}
			placement='left'
			disableInteractive
			enterDelay={1200}
			// enterTouchDelay={1200}
		>
			<MuiGridActionsCellItem color='inherit' {...props} />
		</Tooltip>
	);
};

export default GridActionsCellItem;

import { useEffect, useState } from 'react';

import {
	Link,
	TablePagination,
	TableSortLabel,
	CardContent,
	CardActions,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';

import { EmptyTable, LoadingBar, Label } from '../../../../components';
import { commaTimeStrings } from '../../../../shared/datetime';
import {
	isObject,
	isEmptyArray,
	isUndefined,
	isFullArray,
	isFullString,
} from '../../../../shared/utility';
import * as actions from '../../../../store/actions';
import { useStyles } from '../../style';

interface MaintenanceListProps {
	status?: string;
	onDashboardUnavailabilities?(...args: unknown[]): unknown;
	dashboardOrganisationFilter?: object;
	dashboardUnavailabilities?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const MaintenanceList = (props: MaintenanceListProps) => {
	const {
		status,
		onDashboardUnavailabilities,

		dashboardUnavailabilities,
		dashboardOrganisationFilter,
	} = props;
	const { t } = useTranslation();
	const location = useLocation();

	const { data: dashboardUnavailabilitiesData, loading: dashboardUnavailabilitiesLoading } =
		dashboardUnavailabilities;
	const isPageEmpty =
		isObject(dashboardUnavailabilitiesData) &&
		(isEmptyArray(dashboardUnavailabilitiesData.results) ||
			isUndefined(dashboardUnavailabilitiesData.results));
	const isPageFull =
		isObject(dashboardUnavailabilitiesData) && isFullArray(dashboardUnavailabilitiesData.results);

	const [page, setPage] = useState({ number: 1, size: 5 });
	const [order, setOrder] = useState({ order: 'asc', descending: true, orderBy: 'id' });

	const [loading, setLoading] = useState(true);

	const classes = useStyles();

	const filters = {
		// state: status,
		orderDescending: order.descending,
		sortBy: order.orderBy,
		...(isFullString(dashboardOrganisationFilter.valueName) && {
			organisationId: dashboardOrganisationFilter.valueId,
		}),
	};

	useEffect(() => {
		onDashboardUnavailabilities(page, filters);
		setLoading(false);
		return () => {
			setLoading(true);
		};
	}, [order, page, dashboardOrganisationFilter.valueId]);

	const handlePageChange = (e, pageNumber) => setPage({ ...page, number: pageNumber + 1 });

	const handleLabelDisplayedRows = ({ from, to, count }) =>
		`${from}-${to} ${t('ui.of')} ${count !== -1 ? count : '0'}`;

	const handleSort = (property) => () => {
		const isDesc = order.orderBy === property && order.order === 'desc';
		setOrder({ order: isDesc ? 'asc' : 'desc', descending: !isDesc, orderBy: property });
	};

	const actions = {
		pageChange: handlePageChange,
		displayedRows: handleLabelDisplayedRows,
		sorting: handleSort,
	};

	const tableHeaders =
		isPageEmpty ?
			[]
		:	[
				{ name: 'id', content: 'ui.label.id', hasSorting: true },
				{ name: 'start', content: 'views.ItemDetail.activities.start', hasSorting: true },
				{ name: 'end', content: 'views.ItemDetail.activities.end', hasSorting: true },
				{ name: 'itemName', content: 'ui.label.itemGroup' },
				{ name: 'item', content: 'ui.label.item' },
				{ name: 'status', content: 'ui.label.status' },
			];

	const unavailabilitieStatus = {
		upcoming: 'default',
		cancelled: 'error',
		active: 'success',
		completed: 'disabled',
	};

	const loadingBody =
		dashboardUnavailabilitiesLoading || loading ?
			Array(5)
				.fill(Array(tableHeaders?.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })))
		:	null;

	const emptyBody = !(dashboardUnavailabilitiesLoading && loading) &&
		isPageEmpty && [
			[
				{
					content: <EmptyTable label={`views.dashboard.bookings.emptyResults.${status}`} />,
				},
			],
		];

	const fullBody =
		!dashboardUnavailabilitiesLoading && isPageFull ?
			dashboardUnavailabilitiesData.results.map((unavailability, i) => [
				{
					content: (
						<Link
							key={i}
							className={classes.itemGroup}
							color='primary'
							component={RouterLink}
							to={`/unavailability/${unavailability.id}/summary`}
							state={{ from: location.pathname, label: t('nav.category.dashboard'), status: status }}
						>
							{unavailability.id}
						</Link>
					)
				},
				{ content: commaTimeStrings(unavailability.start) },
				{ content: unavailability?.end && commaTimeStrings(unavailability.end) },
				{ content: unavailability.item.name },
				{ content: unavailability.instance.name },
				{
					content: (
						<Label type={unavailabilitieStatus[unavailability.status]}>
							{t(`ui.status.${unavailability.status}`)}
						</Label>
					),
				},
			])
		:	[];

	const body = loadingBody || emptyBody || fullBody;

	return (
		<>
			<CardContent className={classes.content}>
				<PerfectScrollbar>
					<div className={classes.inner}>
						<Table>
							<TableHead>
								<TableRow hover={false}>
									{tableHeaders.map((cell) => (
										<TableCell key={cell.name}>
											{cell.hasSorting ?
												<TableSortLabel
													active={order.orderBy === cell.name}
													direction={order.order === 'asc' ? 'desc' : 'asc'}
													onClick={actions.sorting(cell.name)}
												>
													{t(cell.content)}
												</TableSortLabel>
											:	t(cell.content)}
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{body.map((row, rIndex) => (
									<TableRow hover={false} key={rIndex}>
										{row.map((cell, cIndex) => (
											<TableCell key={cIndex}>
												{cell.loading ?
													<LoadingBar />
												: cell.content ?
													cell.content
												:	<p>-</p>}
											</TableCell>
										))}
									</TableRow>
								))}
							</TableBody>
						</Table>
					</div>
				</PerfectScrollbar>
			</CardContent>
			<CardActions className={classes.actions}>
				<TablePagination
					component='div'
					count={dashboardUnavailabilitiesData ? dashboardUnavailabilitiesData.total : 0}
					labelDisplayedRows={actions.displayedRows}
					onPageChange={actions.pageChange}
					page={page.number - 1}
					rowsPerPage={5}
					rowsPerPageOptions={[-1]}
				/>
			</CardActions>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		dashboardUnavailabilities: state.paged.dashboardUnavailabilities,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onDashboardUnavailabilities: (page, filters, concat) =>
			dispatch(actions.dashboardUnavailabilities(page, filters, concat)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceList);

import { useEffect, useState } from 'react';

import { Drawer, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import HeaderCard from './HeaderCard';
import ItmesList from './ItmesList';
import { StyledButton } from '../../../../../components';
import { isObject } from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';
import { sharingTypes } from '../../../ManagementConstants';
import { useStyles } from '../../../style';

interface AddItemDrawerProps {
	openItmeDrawer?: boolean;
	setOpenItmeDrawer?(...args: unknown[]): unknown;
	item?: object;
	type?: string;
	consumerName?: string;
	userGroup?: object;
	providerId?: number;
	consumerId?: number;
	onUpdateListState?(...args: unknown[]): unknown;
	onResetState?(...args: unknown[]): unknown;
	onUpdatePublicShare?(...args: unknown[]): unknown;
	onAssignItemsToUserGroup?(...args: unknown[]): unknown;
	onAssignItemsToPartner?(...args: unknown[]): unknown;
	assignedItemsToUserGroup?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	updatePublicShare?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	assignedItemsToPartner?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const AddItemDrawer = (props: AddItemDrawerProps) => {
	const {
		openItmeDrawer,
		setOpenItmeDrawer,

		item,
		type,
		consumerName,
		userGroup,
		providerId,
		consumerId,
		onUpdateListState,
		onAssignItemsToUserGroup,
		assignedItemsToUserGroup,
		onResetState,
		onUpdatePublicShare,
		updatePublicShare,
		assignedItemsToPartner,
		onAssignItemsToPartner,
	} = props;
	const { t } = useTranslation();

	const classes = useStyles();

	const { enqueueSnackbar } = useSnackbar();

	const [disabled, setDisabled] = useState(true);
	const [selectedItems, setSelectedItems] = useState([]);

	const {
		data: assignedItemsToUserGroupData,
		loading: assignedItemsLoading,
		error: assignedItemsToUserGroupError,
	} = assignedItemsToUserGroup;
	const {
		data: updatePublicShareData,
		loading: updatePublicShareLoading,
		error: updatePublicShareError,
	} = updatePublicShare;
	const {
		data: assignedItemsToPartnerData,
		loading: assignedItemsToPartnerLoading,
		error: assignedItemsToPartnerError,
	} = assignedItemsToPartner;

	const handleCloseDrawer = () => {
		setOpenItmeDrawer(false);
		onUpdateListState('internalAvailableSharing', null);
		onUpdateListState('publicAvailableSharing', null);
		onUpdateListState('externalAvailableSharing', null);
	};

	const sharedWith = {
		internal: userGroup?.name,
		public: t(`ui.label.${type}`),
		external: consumerName,
	};

	const loading = assignedItemsLoading || updatePublicShareLoading || assignedItemsToPartnerLoading;

	const handleSuccess = (state, message, variant = 'success', closeDrawer = true) => {
		onResetState(state);
		enqueueSnackbar(message, { variant: variant });
		if (closeDrawer) {
			handleCloseDrawer();
		}
	};

	useEffect(() => {
		if (openItmeDrawer) {
			if (isObject(assignedItemsToUserGroupData)) {
				handleSuccess(
					'assignedItemsToUserGroup',
					`${t('views.assignItemsToUserGroup.messages.success.accepted.partOne')} ${assignedItemsToUserGroupData.accepted.length} ${t('views.assignItemsToUserGroup.messages.success.accepted.partTwo')} ${assignedItemsToUserGroupData.name}`,
				);
			} else if (isObject(updatePublicShareData)) {
				handleSuccess('updatePublicShare', t('views.tableResults.errorMessage'));
			} else if (isObject(assignedItemsToPartnerData)) {
				handleSuccess('assignedItemsToPartner', t('views.tableResults.errorMessage'));
			} else if (assignedItemsToUserGroupError) {
				handleSuccess(
					'assignedItemsToUserGroup',
					`${t('views.assignItemsToUserGroup.messages.success.rejected')} ${assignedItemsToUserGroupData.name}`,
					'error',
					false,
				);
			} else if (updatePublicShareError) {
				handleSuccess(
					'updatePublicShare',
					`${t('views.items.shareDialog.negative')} ${updatePublicShareData.name}`,
					'error',
					false,
				);
			} else if (assignedItemsToPartnerError) {
				handleSuccess(
					'assignedItemsToPartner',
					`${t('views.items.shareDialog.negative')} ${assignedItemsToPartnerData?.name}`,
					'error',
					false,
				);
			}
		}
	}, [assignedItemsToUserGroup, updatePublicShare, assignedItemsToPartner]);

	const headerProps = {
		onClick: handleCloseDrawer,
		itemName: item?.name,
		sharedWith: sharedWith[type],
	};

	const listProps = {
		providerId,
		type,
		consumerId,
		itemId: item?.id,
		userGroupId: userGroup?.id,
		setDisabled,
		openItmeDrawer,
		setSelectedItems,
	};

	const onClickShare = () => {
		if (type === sharingTypes.INTERNAL) {
			onAssignItemsToUserGroup(userGroup.id, {
				items: [{ itemId: item.id, instanceIds: selectedItems.map((item) => item.id) }],
			});
		} else if (type === sharingTypes.PUBLIC) {
			onUpdatePublicShare(true, [
				{ itemId: item.id, instanceIds: selectedItems.map((item) => item.id) },
			]);
		} else if (type === sharingTypes.EXTERNAL) {
			onAssignItemsToPartner(consumerId, {
				items: [{ itemId: item.id, instanceIds: selectedItems.map((item) => item.id) }],
			});
		}
	};

	return (
		<Drawer
			anchor='right'
			classes={{ paper: classes.drawer }}
			onClose={() => handleCloseDrawer()}
			open={openItmeDrawer}
			variant='temporary'
		>
			<div>
				<HeaderCard {...headerProps} />
				<ItmesList {...listProps} />
			</div>
			<StyledButton
				disabled={disabled || loading}
				onClick={onClickShare}
				variant='contained-primary'
			>
				{loading ?
					<CircularProgress size={23} />
				:	t('views.sharing.drawer.button.share')}
			</StyledButton>
		</Drawer>
	);
};

const mapStateToProps = (state) => {
	return {
		assignedItemsToUserGroup: state.details.assignedItemsToUserGroup,
		updatePublicShare: state.details.updatePublicShare,
		assignedItemsToPartner: state.details.assignedItemsToPartner,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onUpdateListState: (identifier, data) => dispatch(actions.updateListState(identifier, data)),
		onAssignItemsToUserGroup: (userGroupId, bodyData) =>
			dispatch(actions.assignItemsToUserGroup(userGroupId, bodyData)),
		onUpdatePublicShare: (isPublic, items) => dispatch(actions.updatePublicShare(isPublic, items)),
		onAssignItemsToPartner: (partnerId, bodyData) =>
			dispatch(actions.assignItemsToPartner(partnerId, bodyData)),
		onResetState: (state) => dispatch(actions.resetState(state)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddItemDrawer);

import { useState, useEffect } from 'react';

import { Box, Typography, CircularProgress, Divider, Switch } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useStyles } from './style';
import { ActionDialog, FormField, ReactDatePicker } from '../../../../components';
import {
	modifyDate,
	getDateRoundedToDuration,
	isEarlierThan,
	unsetTime,
	sameDates,
} from '../../../../shared/datetime';
import { useError } from '../../../../shared/hooks';
import { isObject, isFunction } from '../../../../shared/utility';
import * as actions from '../../../../store/actions';
import palette from '../../../../theme/palette';

const SwitchButton = withStyles({
	switchBase: {
		color: '#586b85',
		'&$checked': {
			color: palette.button.primary,
		},
		'&$checked + $track': {
			backgroundColor: palette.button.primary,
		},
	},
	checked: {},
	track: {},
})(Switch);

interface StopBookingDialogProps {
	open?: boolean;
	kilometres?: object;
	setOpenStopBooking?(...args: unknown[]): unknown;
	selectedEndDate?: object;
	onStopBooking?(...args: unknown[]): unknown;
	setSelectedEndDate?(...args: unknown[]): unknown;
	onResetState?(...args: unknown[]): unknown;
	data?: object;
	start?: any;
	end?: any;
	itemInstance?: any;
	onClose?(...args: unknown[]): unknown;
	stopBooking?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const StopBookingDialog = (props: StopBookingDialogProps) => {
	const {
		open,
		kilometres,
		setOpenStopBooking,
		onStopBooking,
		stopBooking,
		data,
		onResetState,
		start,
		end,
		itemInstance,
		onClose,
	} = props;
	const { t } = useTranslation();

	const classes = useStyles();
	const time = new Date();

	const [switchTimeSelect, setSwitchTimeSelect] = useState(true);
	const [selectedEndDate, setSelectedEndDate] = useState(null);

	const [currentTime, setCurrentTime] = useState(null);

	const { data: stopBookingData, loading: stopBookingLoading } = stopBooking;

	const stopBookingMessage = useError({
		value: stopBooking,
		message: t('ui.alertDialog.description.stopMessage'),
	});

	useEffect(() => {
		if (isObject(data)) {
			setSelectedEndDate(time);
		}
	}, [data]);

	useEffect(() => {
		if (isObject(stopBookingData)) {
			setOpenStopBooking(false);
			setSwitchTimeSelect(false);
			onResetState('stopBooking');
			if (isFunction(onClose)) {
				onClose();
			}
		}
	}, [stopBookingData]);

	useEffect(() => {
		if (sameDates(new Date(start), selectedEndDate, false, true)) {
			setCurrentTime(modifyDate(getDateRoundedToDuration(new Date(start), 'PT15M', true)));
		} else {
			setCurrentTime(unsetTime(time, { hours: 23, minutes: 45 }));
		}
	}, [selectedEndDate]);

	const updateEndDate = (date) => {
		setSelectedEndDate(date);
	};
	const handleConfirmDialog = () => {
		onStopBooking(data.id, kilometres.value, selectedEndDate);
		stopBookingMessage.setStartAction(true);
		kilometres.resetToInitialValue();
	};

	const handleCloseDialog = () => {
		setOpenStopBooking(false);
		setSelectedEndDate(new Date(end));
		setSwitchTimeSelect(false);
	};

	const handleSwitch = () => {
		setSwitchTimeSelect(!switchTimeSelect);
		if (!switchTimeSelect) {
			setSelectedEndDate(time);
		}
	};

	return (
		<ActionDialog
			actionButtonProps={{
				action: handleConfirmDialog,
				text: stopBookingLoading ? <CircularProgress disableShrink size={24} /> : t('ui.confirm'),
			}}
			handleClose={handleCloseDialog}
			loading={
				(
					itemInstance.categoryReference === 'car' &&
					!['bmwCar', 'vehicleTrackerCan'].includes(itemInstance.deviceType)
				) ?
					!kilometres.isValid && !['bmwCar', 'vehicleTrackerCan'].includes(itemInstance.deviceType)
				:	false
			}
			noDivider={true}
			open={open}
			title={t('view.bookings.bookingDetails.button.inline.stop')}
			variant='contained-secondary'
		>
			<Box className={classes.sideCard}>
				<Box>
					<Typography>{t('ui.alertDialog.description.stopActivBooking')}</Typography>
				</Box>
				<div className={classes.switch}>
					<Typography>{t('views.actions.stopBooking.currentTime')}</Typography>
					<SwitchButton checked={!switchTimeSelect} onChange={handleSwitch} />
				</div>
				<Divider className={classes.divider} />
				{switchTimeSelect ?
					<Box mt={4}>
						<ReactDatePicker
							containerClassName={classes.dateTimePicker}
							label={t('views.planboard.addBooking.placeholder.endDate')}
							maxTime={modifyDate(time, { hours: 23, minutes: 45 })}
							minDate={new Date(start)}
							minTime={currentTime}
							onChange={(date) =>
								updateEndDate(
									(
										sameDates(new Date(start), date, false, true) &&
											isEarlierThan(date, new Date(start))
									) ?
										modifyDate(getDateRoundedToDuration(new Date(start), 'PT15M', true))
									:	date,
								)
							}
							selected={selectedEndDate}
							showTimeSelect
							wrapperClassName={classes.fullWidth}
						/>
					</Box>
				:	null}
				{(
					itemInstance.categoryReference.type === 'cars' &&
					!['bmwCar', 'vehicleTrackerCan'].includes(itemInstance.deviceType)
				) ?
					<Box pt={3}>
						<FormField
							fullWidth={true}
							hideCharacterCounter={!kilometres.hasFocus}
							label={t('ui.label.mileage')}
							maxLength={10}
							name='kmRange'
							variable={kilometres}
						/>
					</Box>
				:	null}
			</Box>
		</ActionDialog>
	);
};

const mapStateToProps = (state) => {
	return {
		stopBooking: state.details.stopBooking,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onStopBooking: (id, kilometres, stopTime) =>
			dispatch(actions.stopBooking(id, kilometres, stopTime)),
		onResetState: (identifier) => dispatch(actions.resetState(identifier)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(StopBookingDialog);

import { useEffect, useMemo } from 'react';

import { AxisValueFormatterContext } from '@mui/x-charts/internals';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { ChartsLegendWithValues, LineChart } from '~components';
import { ItemCategoryEnum, PeriodicityEnum } from '~enums';
import { DateRange } from '~interfaces/dateRanges';
import {
	generateDateArray,
	longMonthAndYearFormatter,
	shortMonthAndYearFormatter,
} from '~utils/dateUtils';

import CustomAxisTooltip from './customAxisTooltip';
import BookingStatisticsService from '../../services/bookingStatisticsService';

const service = new BookingStatisticsService();

interface BookingsLineChartProps {
	organisationId?: string;
	period: DateRange<Dayjs>;
	itemId?: string;
	hubId?: string;
	categoryId?: string;
	partnerId?: string;
	periodicity?: PeriodicityEnum;
	showAdditionalMonths?: number;
}

const BookingsLineChart = ({
	period = {
		start: dayjs().subtract(1, 'month'),
		end: dayjs(),
	},
	showAdditionalMonths = 2,
	...props
}: BookingsLineChartProps) => {
	const { t } = useTranslation();

	const totalPeriod = useMemo<DateRange<Dayjs>>(
		() => ({
			start: period.start.subtract(showAdditionalMonths, 'month'),
			end: period.end,
		}),
		[period, showAdditionalMonths],
	);

	const fetchParameters = useMemo(
		() => ({
			...props,
			period: totalPeriod,
			periodicity:
				props.periodicity === PeriodicityEnum.Year ? PeriodicityEnum.Month : PeriodicityEnum.Day,
		}),
		[props, totalPeriod],
	);

	const summationFetchParameters = useMemo(
		() => ({
			...props,
			period: period,
			// Notice! For the summation we purposely request a periodicity of a year.
			// In this case we are only interested in the total values and not the data
			// points
			periodicity: PeriodicityEnum.Year,
		}),
		[props, period],
	);

	const { data, isLoading, isValidating } = useSWR(
		[service.basePath, fetchParameters],
		([_, args]) => service.getCounts(args),
	);

	const enableSummationCount =
		props.periodicity === PeriodicityEnum.Month && showAdditionalMonths > 0;
	const {
		data: summationData,
		isLoading: isSummationLoading,
		isValidating: isSummationValidating,
	} = useSWR(
		enableSummationCount ? [service.basePath, summationFetchParameters] : null,
		([_, args]) => service.getCounts(args),
	);

	// TODO: we should be able to cancel when the fetch parameters are changing and
	// the previous request is still active.
	// But because we use the same endpoint here, we only cancel of the calls.
	// useEffect(() => {
	// 	if (isSummationLoading || isLoading || isSummationValidating || isValidating) {
	// 		service.abortCurrentRequest('parameters change');
	// 	}
	// }, [props, totalPeriod, props.periodicity]);

	/**
	 * Generate an array of dates, so we don't have any missing gaps
	 * within the charts.
	 */
	const dates = useMemo(
		() =>
			generateDateArray(
				totalPeriod,
				props.periodicity === PeriodicityEnum.Month ? PeriodicityEnum.Day : PeriodicityEnum.Month,
			),
		[totalPeriod, props.periodicity],
	);

	/**
	 * We may have missing data where the value is 0. So fill
	 * in the missing data points where the value.
	 * So e.g. use this as the data results
	 */
	const results = useMemo(
		() =>
			dates?.map(
				(el) =>
					data?.results.find((result) =>
						dayjs(result.timestamp).isSame(
							el,
							props.periodicity === PeriodicityEnum.Month ? 'day' : 'month',
						),
					) ?? {
						timestamp: el,
						data: [],
					},
			),
		[dates, data],
	);

	const xAxisValueFormatter = (value: Date, context: AxisValueFormatterContext) => {
		if (context.location !== 'tick' && props.periodicity === PeriodicityEnum.Month) {
			return value.toLocaleDateString();
		} else if (context.location !== 'tick') {
			return longMonthAndYearFormatter.format(value);
		}

		return shortMonthAndYearFormatter.format(value);
	};

	/**
	 * Accumulate all datapoints within an array of objects
	 * @param data
	 * @param key
	 * @returns
	 */
	const sumDataFromArray = (data: any[], key: string): number =>
		data.reduce((total: number, el) => (total += el[key]), 0);

	return (
		<LineChart
			loading={isLoading}
			xAxis={[
				{
					min: totalPeriod.start.toDate(),
					// max: period.end,
					max: totalPeriod.end
						.startOf(props.periodicity === PeriodicityEnum.Year ? 'month' : 'day')
						.toDate(),
					scaleType: 'time',
					data: dates,
					valueFormatter: xAxisValueFormatter,
					tickNumber: 3,
				},
			]}
			yAxis={[
				{
					min: 0,
					tickMinStep: 1,
					// domainLimit: (min, max) => ({
					// 	min: min,
					// 	max: max <= 0 ? 2 : max
					// }),
				},
			]}
			series={[
				{
					id: 'bookingSeries',
					label: t('totalResources', { resource: t('ui.category.bookings') }),
					data: results.map((result) => sumDataFromArray(result.data, 'bookingCount')),
					showMark: false,
				},
				{
					id: 'uniqueUsersSeries',
					label: t('uniqueUsers'),
					data: results.map((result) => sumDataFromArray(result.data, 'uniqueUsers') ?? 0),
					showMark: false,
				},
			]}
			slots={{
				axisContent: CustomAxisTooltip,
				legend: ChartsLegendWithValues,
			}}
			slotProps={{
				legend: {
					values:
						enableSummationCount ?
							[summationData?.total, summationData?.uniqueUsers]
						:	[data?.total, data?.uniqueUsers],
					hidden: true,
				},
				axisContent: {
					subSeries: Object.values(ItemCategoryEnum).map((itemCategory) => ({
						parentSeriesId: 'bookingSeries',
						label: t(`ui.label.${itemCategory}`),
						data: results.map(
							(result) =>
								result.data.find((el) => el.categoryType === itemCategory)?.bookingCount ?? 0,
						),
					})),
				},
			}}
		/>
	);
};

export default BookingsLineChart;

import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet } from 'react-router-dom';
import useSWR from 'swr';

import { LoadingFallback } from '~components';
import { pagePaths } from '~constants';
import { UserRoleEnum } from '~enums';
import { OrganisationVerificationService } from '~features/organisations';
import { GenericLayout } from '~layouts';

import useProtectedRoute from '../../hooks/useProtectedRoute';

const service = new OrganisationVerificationService();

interface ProtectedRouteProps {
	/**
	 * Roles allowed in the protected route. Admin as default
	 * For now give a warning if the user is added. Shouldn't be
	 */
	roles?: (UserRoleEnum.Admin | UserRoleEnum.SuperAdmin)[];
	/**
	 * If true, only allow exactly the role defined. If false, the
	 * natural hiearchy will be followed. For example, superadmin can
	 * do everything an admin can do
	 */
	exclusive?: boolean;
}

/**
 * A route that is only available when a user is logged in
 * @returns
 */
const ProtectedRoute = ({
	roles = [UserRoleEnum.Admin],
	exclusive = false,
}: ProtectedRouteProps) => {
	const { t } = useTranslation();
	const { isAuthenticated, isAuthenticatedUser, isAuthorized, isLoading, isRenewingToken } = useProtectedRoute(
		roles,
		exclusive
	);

	if (isLoading) {
		return (
			<GenericLayout>
				<LoadingFallback
					description={`${t('authenticating')}${import.meta.env.DEV ? ' - protected' : ''}`}
				/>
			</GenericLayout>
		);
	} else if (isRenewingToken) {
		return (
			<GenericLayout>
				<LoadingFallback description='Trying to sign you in' />
			</GenericLayout>
		);
	} else if (!isAuthenticated) {
		return <Navigate to={`/${pagePaths.AuthLogin}`} state={{ from: location.pathname }} replace />;
	} else if (isAuthenticatedUser) {
		return <UserRoute />
	} else if (!isAuthorized) {
		return import.meta.env.DEV ?
				<Box>Unauthorized</Box>
			:	<Navigate to={`/${pagePaths.AuthLogin}`} />;
	}

	return <Outlet />;
};

const UserRoute = () => {
	const { data, isLoading, isValidating } = useSWR('verifyOrganisation', () =>
		service.getOrganisationVerificationStatus()
	);

	if (isLoading || isValidating) {
		return <LoadingFallback />;
	} else if (data?.verified === false) {
		return <Navigate to={`/${pagePaths.RegisterVerificationStatus}`} />;
	}

	// TODO: enum
	return <Navigate to={`/${pagePaths.AuthUserRedirect}`} />;
};

export default ProtectedRoute;

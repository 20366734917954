import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { InfoCard, InfoCardRowDef } from '~components';

import SkcUserGroupsService from '../../services/skcUserGroupsService';

interface AccessRuleGeneralInfoCardProps {
	id: string;
}

const service = new SkcUserGroupsService();

const AccessUserGroupGeneralInfoCard = ({ id }: AccessRuleGeneralInfoCardProps) => {
	const { t } = useTranslation();

	const { data, isLoading, error } = useSWR([service.basePath, id], ([_, args]) =>
		service.getUserGroupById(args),
	);

	const rows: InfoCardRowDef[] | undefined = data && [
		{
			headerName: 'ID',
			value: data.id,
		},
		{
			headerName: t('ui.label.description'),
			value: data.description,
		},
	];

	return (
		<InfoCard
			title={`${t('accessGroup')} ${t('info').toLowerCase()}`}
			version='new'
			rows={rows ?? []}
			loading={isLoading}
			error={error != null}
		/>
	);
};

export default AccessUserGroupGeneralInfoCard;

import { useTranslation } from 'react-i18next';

import { UserLicenseStatisticsLayout } from '~features/statistics';

import Page from '../page';

const UserLicenseStatisticsPage = () => {
	const { t } = useTranslation();

	return (
		<Page title={`${t('data')} - ${t('nav.category.licenses')}`}>
			<UserLicenseStatisticsLayout />
		</Page>
	);
};

export default UserLicenseStatisticsPage;

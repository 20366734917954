import { useEffect, useState } from 'react';

import path from 'path';

import {
	Input as InputIcon,
	Menu as MenuIcon,
	MenuOpen as MenuOpenIcon,
	ScienceOutlined as ScienceOutlinedIcon,
} from '@mui/icons-material';
import { AppBar, Box, Button, IconButton, Stack, Toolbar, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import TopologyLogo from '~assets/images/topology_logo.svg?react';
import { LanguageSelect } from '~components';
import { pagePathSegments, pagePaths } from '~constants';

interface MainHeaderProps {
	menuOpen: boolean;
	onMenuOpenChange?: (value: boolean) => void;
}

const MainHeader = ({ menuOpen = false, ...props }: MainHeaderProps) => {
	const { t } = useTranslation();
	const theme = useTheme();

	const [open, setOpen] = useState(menuOpen);

	useEffect(() => {
		setOpen(menuOpen);
	}, [menuOpen]);

	const handleMenuChange = () => {
		const newValue = !open;
		setOpen(newValue);

		if (props.onMenuOpenChange) {
			props.onMenuOpenChange(newValue);
		}
	};

	return (
		// TODO: zindex is randomly chosen. Better value?
		<AppBar
			elevation={2}
			position='static'
			sx={{ zIndex: 600, bgcolor: theme.palette.common.white }}
		>
			<Toolbar>
				<Link to={pagePathSegments.Home}>
					<TopologyLogo width='140px' />
				</Link>

				<Box flexGrow={1} />

				<Stack direction='row' spacing={1}>
					{(import.meta.env.DEV || import.meta.env.VITE_APP_ENV === 'develop') && (
						<LanguageSelect size='small' variant='standard' />
					)}
					{import.meta.env.DEV && (
						<IconButton component={Link} to={pagePathSegments.Sandbox}>
							<ScienceOutlinedIcon />
						</IconButton>
					)}
					<Button component={Link} to={`/${pagePaths.AuthLogout}`} startIcon={<InputIcon />}>
						{t('ui.button.inline.signout')}
					</Button>
				</Stack>
				<IconButton
					color='primary'
					onClick={handleMenuChange}
					sx={{ display: { md: 'block', lg: 'none' } }}
				>
					{open ?
						<MenuOpenIcon />
					:	<MenuIcon />}
				</IconButton>
			</Toolbar>
		</AppBar>
	);
};

export default MainHeader;

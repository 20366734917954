import { useEffect } from 'react';

import { Tabs, Tab, Divider, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';

import { useAuthorize } from '~features/authentication';

import DevicesList from './DevicesList';
import DockingLocations from './DockingLocations';
import Header from './Header';
import { useStyles } from './style';
import { Page } from '../../components';
import { isFullArray, isObject } from '../../shared/utility';
import * as actions from '../../store/actions';

interface DevicesProps {
	onFetchDevicesTypes?(...args: unknown[]): unknown;
	onFetchDevicesFirmWarVersions?(...args: unknown[]): unknown;
	onFetchDevicesUnlockTypes?(...args: unknown[]): unknown;
	devicesTypesFilter?: {
		data?: unknown[];
		loading?: boolean;
		error?: object | string;
	};
	devicesFirmWarVersionsFilter?: {
		data?: unknown[];
		loading?: boolean;
		error?: object | string;
	};
	devicesUnlockTypesFilter?: {
		data?: unknown[];
		loading?: boolean;
		error?: object | string;
	};
	deviceUnlockCode?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onResetState?(...args: unknown[]): unknown;
}

const Devices = (props: DevicesProps) => {
	const {
		onFetchDevicesTypes,
		onFetchDevicesFirmWarVersions,
		onFetchDevicesUnlockTypes,
		devicesTypesFilter,
		devicesFirmWarVersionsFilter,
		devicesUnlockTypesFilter,
		onResetState,
		deviceUnlockCode,
	} = props;
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();
	const { isSuperAdmin } = useAuthorize();

	const { tab } = useParams();

	const classes = useStyles();

	const { data: typesFilterData, loading: typesFilterLoading } = devicesTypesFilter;

	const { data: firmWarVersionsFilterData, loading: firmWarVersionsFilterLoading } =
		devicesFirmWarVersionsFilter;

	const { data: unlockTypesFilterData, loading: unlockTypesFilterLoading } =
		devicesUnlockTypesFilter;

	const { data: deviceUnlockCodeData } = deviceUnlockCode;

	useEffect(() => {
		if (!isFullArray(typesFilterData) && !typesFilterLoading) {
			onFetchDevicesTypes(isSuperAdmin() ? null : 'assign');
		}
		if (!isFullArray(firmWarVersionsFilterData) && !firmWarVersionsFilterLoading) {
			onFetchDevicesFirmWarVersions();
		}
		if (!isFullArray(unlockTypesFilterData) && !unlockTypesFilterLoading) {
			onFetchDevicesUnlockTypes();
		}
	}, [typesFilterData, firmWarVersionsFilterData, unlockTypesFilterData]);

	useEffect(() => {
		if (tab === 'assigned' || tab === 'unassigned') {
			onResetState('vehicleStatus');
		}
	}, [tab]);

	useEffect(() => {
		if (location.pathname === '/devices/assigned' && isObject(deviceUnlockCodeData)) {
			onResetState('deviceUnlockCode');
		}
	}, [location]);

	const handleTabsChange = (event, value) => {
		navigate(`../${value}`, { relative: 'path' });
	};

	const tabs = [
		{ value: 'assigned', label: 'views.devices.assignedDevices' },
		{ value: 'unassigned', label: 'views.devices.unassignedDevices' },
		...(isSuperAdmin() ? [{ value: 'dockingLocations', label: 'ui.label.dockingLocations' }] : []),
	];

	if (!tab) {
		return <Navigate to={'/dashboard'} />;
	}

	if (!tabs.find((t) => t.value === tab)) {
		return <Navigate to='/errors/error-404' />;
	}

	const headerButtons = [
		{ key: 'assigned', buttons: [] },
		{ key: 'unassigned', buttons: [] },
		{ key: 'dockingLocations', buttons: [] },
	];

	return (
		<Page className={classes.root} title={t('nav.category.devices')}>
			{headerButtons.map((item) => item.key === tab && <Header key={item.key} {...item} />)}
			{isSuperAdmin() ?
				<Tabs
					className={classes.tabs}
					indicatorColor='primary'
					onChange={handleTabsChange}
					scrollButtons='auto'
					value={tab}
					variant='scrollable'
				>
					{tabs.map((deviceTab) => (
						<Tab key={deviceTab.value} label={t(deviceTab.label)} value={deviceTab.value} />
					))}
				</Tabs>
			:	<Box className={classes.tabs}></Box>}
			<Divider className={classes.divider} />
			<div className={classes.content}>
				{tab === 'assigned' && <DevicesList assigned />}
				{tab === 'unassigned' && <DevicesList unassigned />}
				{tab === 'dockingLocations' && <DockingLocations />}
			</div>
		</Page>
	);
};

const mapStateToProps = (state) => {
	return {
		devicesTypesFilter: state.list.devicesTypesFilter,
		devicesFirmWarVersionsFilter: state.list.devicesFirmWarVersionsFilter,
		devicesUnlockTypesFilter: state.list.devicesUnlockTypesFilter,
		deviceUnlockCode: state.details.deviceUnlockCode,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchDevicesTypes: (filters) => dispatch(actions.fetchDevicesTypes(filters)),
		onFetchDevicesFirmWarVersions: () => dispatch(actions.fetchDevicesFirmWarVersions()),
		onFetchDevicesUnlockTypes: () => dispatch(actions.fetchDevicesUnlockTypes()),
		onResetState: (identifier) => dispatch(actions.resetState(identifier)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Devices);

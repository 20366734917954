import { useEffect, useMemo, useState } from 'react';

import path from 'path';

import { Refresh as RefreshIcon } from '@mui/icons-material';
import { Box, IconButton, Link as MuiLink, Portal, Stack } from '@mui/material';
import {
	GridCallbackDetails,
	GridColDef,
	GridFilterModel,
	GridPaginationModel,
} from '@mui/x-data-grid';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Link as ReactRouterLink } from 'react-router-dom';
import useSWR from 'swr';

import { DataGrid, DataGridToolbar } from '~components';
import { pagePathSegments } from '~constants';
import { useAuthorize } from '~features/authentication';
import { useUrlSearchParamsOrganisation } from '~features/organisations';
import { BaseReference, PagedResults } from '~interfaces';

import SelectOrganisationOverlay from './selectOrganisationOverlay';
import selectedOrganisationAtom from '../../atoms/selectedOrganisationAtom';
import AccessLog from '../../interfaces/accessLog';
import SkcAccessLogsService from '../../services/skcAccessLogsService';
import AccessEventChip from '../chips/accessEventChip';

const toolbarIdString = 'topology-access-rules-datagrid';

const service = new SkcAccessLogsService();

interface AccessLogsDataGridProps {}

const AccessLogsDataGrid = ({ ...props }: AccessLogsDataGridProps) => {
	const { t } = useTranslation();
	const { isSuperAdmin } = useAuthorize();
	// const [selectedOrganisation, setSelectedOrganisation] = useUrlSearchParamsOrganisation();
	const [selectedOrganisation, setSelectedOrganisation] = useAtom(selectedOrganisationAtom);

	const [fetchParameters, setFetchParameters] = useState<object | null>(null);

	const columns = useMemo<GridColDef<AccessLog>[]>(
		() => [
			{ field: 'id', headerName: 'ID', width: 80, hideable: false },
			{
				field: 'timestamp',
				headerName: t('timestamp'),
				valueFormatter: (value: Date) => value.toLocaleString(),
				flex: 1,
				type: 'dateTime',
			},
			{
				field: 'device',
				headerName: t('device'),
				valueFormatter: (value?: BaseReference) => value?.label ?? value?.id ?? '-',
				flex: 1,
			},
			{
				field: 'nfcTag',
				headerName: t('ui.label.anonymousUserNFCTag'),
				valueFormatter: (value: BaseReference) => value?.label ?? '-',
				flex: 1,
			},
			{
				field: 'rule',
				headerName: t('rule'),
				renderCell: (params) =>
					params.value ?
						<MuiLink
							component={ReactRouterLink}
							to={`/${path.join(pagePathSegments.Access, pagePathSegments.Rules, params.value.id)}`}
							state={{ from: location.pathname }}
						>
							{params.value.label}
						</MuiLink>
					:	'-',
				flex: 1,
			},
			{
				field: 'organisation',
				headerName: t('organisation'),
				valueGetter: () => selectedOrganisation,
				valueFormatter: (value: BaseReference) => value?.label ?? '-',
				flex: 1,
			},
			{
				field: 'eventType',
				headerName: t('ui.label.event'),
				renderCell: (params) => <AccessEventChip status={params.value} />,
				flex: 1,
			},
		],
		[],
	);

	const { data: accessLogsData, isLoading, isValidating, error, mutate } = useSWR(
		fetchParameters?.organisationId != null ? [service.basePath, fetchParameters] : null,
		([_, args]) => service.getAccessLogs(args),
		{
			keepPreviousData: true,
		},
	);
	// Notice: this should be temporary so long as you can't see access data
	// of multiple different organisations
	const data: PagedResults<AccessLog> | undefined =
		accessLogsData != null && selectedOrganisation == null && isSuperAdmin() ?
			{
				...accessLogsData,
				total: 0,
				results: [],
			}
		:	accessLogsData;

	const [totalCount, setTotalCount] = useState<number>(data?.total || 0);

	useEffect(() => {
		if (data?.total != null) {
			setTotalCount(data.total);
		}
	}, [data]);

	const handlePaginationChange = (value: GridPaginationModel, details: GridCallbackDetails) => {
		if (details.reason === 'setPaginationModel' && (isLoading || isValidating)) {
			service.abortCurrentRequest('pagination change');
		}

		setFetchParameters((prev) => ({
			...prev,
			page: value.page + 1,
			pageSize: value.pageSize,
		}));
	};

	const handleFilterModelChange = (model: GridFilterModel) => {
		const searchQuery = model.quickFilterValues?.[0];
		
		const newOrganisation =
			model.items?.[0]?.field === 'organisation' ?
				model.items[0].value
			:	undefined;

		setSelectedOrganisation(newOrganisation);
		setFetchParameters((prev) => ({
			...prev,
			organisationId: newOrganisation?.id,
			searchQuery: searchQuery,
		}));
	};

	return (
		<Stack
			spacing={2}
			sx={{
				display: 'flex',
				height: 1,
			}}
		>
			{/* TODO: admin has no use for the toolbar, but if we hide it, the datagrid renders weird */}
			<Box id={toolbarIdString} />
			<DataGrid
				disableColumnSorting
				snapshotKey='accl'
				columns={columns}
				error={error}
				loading={isLoading || isValidating}
				rows={data?.results ?? []}
				rowCount={totalCount}
				onPaginationModelChange={handlePaginationChange}
				onFilterModelChange={handleFilterModelChange}
				initialState={{
					filter: {
						filterModel: {
							items:
								selectedOrganisation ?
									[
										{
											field: 'organisation',
											operator: 'contains',
											value: selectedOrganisation,
										},
									]
								:	[],
						},
					},
				}}
				slots={{
					toolbar: Toolbar,
					...(selectedOrganisation == null && {
						noRowsOverlay: SelectOrganisationOverlay,
					}),
				}}
				slotProps={{
					toolbar: {
						onRefresh: mutate,
					},
					noRowsOverlay: {
						title: t('noResourceFound', { resource: t('logs') }),
					},
					noResultsOverlay: {
						title: t('noResourceFound', { resource: t('logs') }),
					},
				}}
				sx={{ flexGrow: 1, flexBasis: 200 }}
			/>
		</Stack>
	);
};

const Toolbar = (props) => (
	<Portal container={() => document.getElementById(toolbarIdString)}>
		<DataGridToolbar disableSearch>
			<IconButton onClick={props.onRefresh}>
				<RefreshIcon />
			</IconButton>
		</DataGridToolbar>
	</Portal>
);

export default AccessLogsDataGrid;

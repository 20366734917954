import { useCallback, useEffect, useState } from 'react';

import { useAuth } from 'react-oidc-context';

import { UserRoleEnum } from '~enums';

import { authorize } from '../utils/authorize';

const useProtectedRoute = (roles: UserRoleEnum[], exclusive = false) => {
	const auth = useAuth();

	const [hasTriedSignin, setHasTriedSignin] = useState(false);

	const [isRenewingToken, setIsRenewingToken] = useState(false);
	const canRenewToken =
		!isRenewingToken &&
		!auth.activeNavigator &&
		!hasTriedSignin &&
		!auth.isAuthenticated &&
		auth.user != null;

	const role = (auth.user?.profile.role as string)?.toLowerCase() as UserRoleEnum;

	const signinSilent = useCallback(async () => {
		setIsRenewingToken(true);
		// https://github.com/authts/react-oidc-context?tab=readme-ov-file#automatic-sign-in
		try {
			await auth.signinSilent();
		} catch (error) {
			console.error(error);
		} finally {
			setHasTriedSignin(true);
			setIsRenewingToken(false);
		}
	}, [auth, setHasTriedSignin]);

	useEffect(() => {
		if (canRenewToken) {
			signinSilent();
		}
	}, [canRenewToken, signinSilent]);

	return {
		isLoading: auth.isLoading,
		isRenewingToken: isRenewingToken || canRenewToken,
		isAuthenticated: auth.isAuthenticated,
		isAuthenticatedUser: auth.isAuthenticated && role === UserRoleEnum.User,
		isAuthorized: auth.isAuthenticated && authorize(role, roles, exclusive),
	};
};

export default useProtectedRoute;

import { useState, useEffect } from 'react';

import { Card, CardContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { InfoLabel, Tip } from '../../../../../components';
import { unsetTime, weekdays, weekend } from '../../../../../shared/datetime';
import { isFullArray } from '../../../../../shared/utility';
import CalendarDetails from '../CalendarDetails';
import Header from '../Header';

const PREFIX = 'PerWeek';

const classes = {
	root: `${PREFIX}-root`,
	modelLabel: `${PREFIX}-modelLabel`,
	calendarComponents: `${PREFIX}-calendarComponents`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
	[`& .${classes.root}`]: {},

	[`& .${classes.modelLabel}`]: {
		margin: theme.spacing(4.5, 0),
		boxSizing: 'border-box',
	},

	[`& .${classes.calendarComponents}`]: {
		'& .fc-scroller.fc-time-grid-container': {
			display: 'none',
		},
		'& .fc-divider.fc-widget-header': {
			display: 'none',
		},
		'& .fc-axis': {
			height: '100%',
		},
		'& .fc-event': {
			borderStyle: 'none',
			margin: '0px 4px',
			padding: '0',
			height: '100%',
		},
		'& .fc-row': {
			display: 'flex',
		},
		'& .fc-content-skeleton': {
			padding: '0 !important',
		},
		'& .fc-content': {
			height: '85%',
			margin: '5px 4px',
			borderRadius: '3px',
			padding: '10px 0 10px 0',
		},
	},
}));

function getWeekDay(addition = 0) {
	const currentDate = unsetTime(new Date());
	const day = currentDate.getDay();
	const diff = currentDate.getDate() + addition - day;
	return new Date(currentDate.setDate(diff));
}

const start = getWeekDay();
const end = getWeekDay(7);

interface PerWeekProps {
	className?: string;
	perWeekValue?: object;
	save?(...args: unknown[]): unknown;
	editing?: boolean;
	pricingPerWeek?: unknown[];
	pricingPerWeekModel?: unknown[];
	eventColors?: object;
	createPricingModel?(...args: unknown[]): unknown;
}

const PerWeek = (props: PerWeekProps) => {
	const {
		className,
		perWeekValue,
		pricingPerWeekModel,
		save,
		editing,
		eventColors,
		createPricingModel,
	} = props;
	const { t } = useTranslation();

	const initialPrice = isFullArray(pricingPerWeekModel) ? pricingPerWeekModel[0].price : undefined;
	const [pricingPerWeek, setPricingPerWeek] = useState(
		createPricingModel(Date.now(), undefined, undefined, true, initialPrice, perWeekValue.name, [
			...weekdays,
			...weekend,
		]),
	);

	const eventsArray = [
		{
			id: 0,
			title: `${
				pricingPerWeek.price === 0 ? t('ui.free')
				: pricingPerWeek.price > 0 ? `€${pricingPerWeek.price}`
				: t('ui.free')
			}/week`,
			start: start,
			end: end,
			defaultAllDayEventDuration: { days: 1 },
			color:
				pricingPerWeek.price === 0 ? eventColors.free
				: pricingPerWeek.price > 0.1 ? eventColors.fixed
				: eventColors.free,
		},
	];

	useEffect(() => {
		const priceValue = createPricingModel(
			Date.now(),
			undefined,
			undefined,
			true,
			pricingPerWeek.price,
			perWeekValue.name,
			pricingPerWeek.days,
		);

		save({ pricePerWeek: priceValue });
	}, [pricingPerWeek]);

	return (
		<Root>
			<Header editing={editing} />
			<Card className={clsx(classes.root, className)}>
				<CardContent>
					<InfoLabel
						info={t('views.addPricing.perWeek.infoPer')}
						name={t('views.addPricing.perWeek.infoName')}
						paddingBottom={4.5}
						paddingTop={1.5}
						variant='h4'
					/>
					<Tip message={t('views.addPricing.perWeek.tip')} />
					<CalendarDetails
						allDayText={t('ui.allDay')}
						className={classes.calendarComponents}
						constraint={{}}
						eventArray={eventsArray}
						events={{}}
						perWeekValue={perWeekValue}
						periodLabel={perWeekValue.label}
						pricingPerWeek={pricingPerWeek}
						setPricingPerWeek={setPricingPerWeek}
					/>
				</CardContent>
			</Card>
		</Root>
	);
};

export default PerWeek;

import { useState, useEffect } from 'react';

import DriveEtaOutlinedIcon from '@mui/icons-material/DriveEtaOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { Typography, List, ListItem, Radio, FormControlLabel, Checkbox, Box } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { CountController, FormField } from '../../../../../../components';
import { useWizardFormField, useDebouncedNumberSave } from '../../../../../../shared/hooks';
import { isInteger, isBoolean, isNumber, isObject } from '../../../../../../shared/utility';
import AdditionalCharactaristics from '../AdditionalCharactaristics';
import CharacteristicsCard from '../CharacteristicsCard';
import EditButtons from '../EditButtons';
import { useStyles } from '../style';

interface CarCharacteristicsProps {
	className?: string;
	save?(...args: unknown[]): unknown;
	numberOfDoors?: number;
	numberOfSeats?: number;
	carBodyType?: string;
	propulsionType?: string;
	hasAirconditioning?: boolean;
	hasNavigation?: boolean;
	hasBluetooth?: boolean;
	hasTowHook?: boolean;
	transmissionType?: string;
	kmRange?: string | number;
	cargoSpaceL?: unknown[] | number;
	cargoSpaceW?: unknown[] | number;
	cargoSpaceH?: unknown[] | number;
	cargoSpace?: boolean;
	itemStep?: number;
	edit?: boolean;
	isEdit?: boolean;
	isFinishingEdit?: boolean;
	onClose?(...args: unknown[]): unknown;
	characteristics?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const CarCharacteristics = (props: CarCharacteristicsProps) => {
	const {
		save,

		itemStep,
		isEdit,
		isFinishingEdit,
		numberOfSeats,
		propulsionType,
		transmissionType,
		kmRange,
		carBodyType,
		hasAirconditioning,
		hasNavigation,
		hasBluetooth,
		numberOfDoors,
		hasTowHook,
		cargoSpaceL,
		cargoSpaceW,
		cargoSpaceH,
		onClose,
		characteristics,
	} = props;
	const { t } = useTranslation();
	const classes = useStyles();

	const [seats, setSeats] = useState(numberOfSeats);
	const [doors, setDoors] = useState(numberOfDoors);

	const kmRangeValue = useWizardFormField(kmRange, {
		required: true,
		minLength: 1,
		maxLength: 5,
		isNumeric: true,
	});
	useDebouncedNumberSave('updatedKmRange', kmRangeValue.value, kmRangeValue.isValid, save);

	const [valueLength] = useState(cargoSpaceL);
	const [valueWidth] = useState(cargoSpaceW);
	const [valueHeight] = useState(cargoSpaceH);

	const {
		data: characteristicsData,
		loading: characteristicsLoading,
		error: characteristicsError,
	} = characteristics;
	const readyData =
		isObject(characteristicsData) && !characteristicsLoading && !characteristicsError;

	const types =
		readyData ?
			characteristicsData.cars.filter((car) => (car.name === 'carBodyType' ? car : null))
		:	null;
	const transmission =
		readyData ?
			characteristicsData.cars.filter((car) => (car.name === 'transmissionType' ? car : null))
		:	null;
	const propulsion =
		readyData ?
			characteristicsData.cars.filter((car) => (car.name === 'propulsionType' ? car : null))
		:	null;

	useEffect(() => {
		if (isEdit) {
			setSeats(numberOfSeats);
		}
	}, [numberOfSeats]);
	useEffect(() => {
		if (isEdit) {
			setDoors(numberOfDoors);
		}
	}, [numberOfDoors]);

	useEffect(() => {
		save({ updatedCargoSpaceL: valueLength });
	}, [valueLength]);

	useEffect(() => {
		save({ updatedCargoSpaceW: valueWidth });
	}, [valueWidth]);

	useEffect(() => {
		save({ updatedCargoSpaceH: valueHeight });
	}, [valueHeight]);

	const [carCharacteristics, setCarCharacteristics] = useState({
		hasBluetooth: false,
		hasAirconditioning: false,
		hasNavigation: false,
		hasTowHook: false,
	});

	const handleTypeOptions = (event, item) => {
		save({ updatedCarBodyType: item.name });
	};

	const handlePoweredBy = (event, item) => {
		save({ updatedPropulsionType: item.name });
	};

	useEffect(() => {
		if (propulsionType !== 'fuel') {
			save({ updatedTransmissionType: null });
		} else if (propulsionType === 'fuel') {
			save({ updatedTransmissionType: transmissionType });
		}
	}, [propulsionType]);

	const handleTransmission = (event, item) => {
		save({ updatedTransmissionType: item.name });
	};

	const handleIncrementDoors = () => {
		const updatedDoors = doors >= 20 ? 20 : doors + 1;
		save({ updatedNumberOfDoors: updatedDoors });
		setDoors(updatedDoors);
	};

	const handleDecrementDoors = () => {
		const updatedDoors = doors === 1 ? doors : doors - 1;
		save({ updatedNumberOfDoors: updatedDoors });
		setDoors(updatedDoors);
	};

	useEffect(() => {
		if (isInteger(numberOfSeats)) {
			save({ updatedNumberOfSeats: seats });
		}
	}, [numberOfSeats]);

	useEffect(() => {
		if (isInteger(numberOfDoors)) {
			save({ updatedNumberOfDoors: doors });
		}
	}, [numberOfDoors]);

	const handleIncrementSeats = () => {
		const valueSeats = seats >= 100 ? 100 : seats + 1;
		save({ updatedNumberOfSeats: valueSeats });
		setSeats(valueSeats);
	};

	const handleDecrementSeats = () => {
		const valueSeats = seats === 1 ? seats : seats - 1;
		save({ updatedNumberOfSeats: valueSeats });
		setSeats(valueSeats);
	};

	const handleCharacteristics = (event) => {
		const target = event.target;
		setCarCharacteristics({ ...carCharacteristics, [target.name]: target.checked });
		save({
			[`updated${target.name.charAt(0).toUpperCase() + target.name.substring(1)}`]: target.checked,
		});
	};

	useEffect(() => {
		if (propulsionType !== 'electric') {
			save({ updatedKmRange: '' });
		}
	}, [propulsionType]);

	const capacity = [
		{
			name: 'Seats',
			value: isNumber(numberOfSeats) ? numberOfSeats : seats,
			callIncrement: handleIncrementSeats,
			callDecrement: handleDecrementSeats,
			icon: <PeopleAltOutlinedIcon color='primary' fontSize='large' />,
		},
		{
			name: 'Doors',
			value: isNumber(numberOfDoors) ? numberOfDoors : doors,
			callIncrement: handleIncrementDoors,
			callDecrement: handleDecrementDoors,
			icon: <DriveEtaOutlinedIcon color='primary' fontSize='large' />,
		},
	];

	const characteristicsSecondary = [
		{
			label: t('ui.characteristics.hasTowHook'),
			checked: isBoolean(hasTowHook) ? hasTowHook : carCharacteristics.hasTowHook,
			action: handleCharacteristics,
			name: 'hasTowHook',
		},
		{
			label: t('ui.characteristics.hasBluetooth'),
			checked: isBoolean(hasBluetooth) ? hasBluetooth : carCharacteristics.hasBluetooth,
			action: handleCharacteristics,
			name: 'hasBluetooth',
		},
		{
			label: t('ui.characteristics.hasAirconditioning'),
			checked:
				isBoolean(hasAirconditioning) ? hasAirconditioning : carCharacteristics.hasAirconditioning,
			action: handleCharacteristics,
			name: 'hasAirconditioning',
		},
		{
			label: t('ui.characteristics.hasNavigation'),
			checked: isBoolean(hasNavigation) ? hasNavigation : carCharacteristics.hasNavigation,
			action: handleCharacteristics,
			name: 'hasNavigation',
		},
	];

	return (
		<>
			<CharacteristicsCard isEdit={isEdit} itemStep={itemStep}>
				<Typography className={classes.labelSpacing} variant='h5'>
					{t('views.addItem.characteristicsCars.capacity')}?
				</Typography>
				<List className={classes.capacityControllers}>
					{capacity.map((item) => (
						<ListItem key={item.name}>
							<CountController
								callbackDecrement={item.callDecrement}
								callbackIncrement={item.callIncrement}
								icon={item.icon}
								name={item.name}
								value={item.value}
							/>
						</ListItem>
					))}
				</List>
				<Typography className={classes.labelSpacing} variant='h5'>
					{t('views.addItem.characteristicsCars.typeOfCar')}?
				</Typography>
				<List className={classes.listComponent}>
					{readyData &&
						types[0].enumValues.map((item) => (
							<ListItem
								button={true}
								className={clsx(classes.option, {
									[classes.selectedOption]: carBodyType === item.name,
								})}
								key={item.id}
								onClick={(event) => handleTypeOptions(event, item)}
							>
								<Radio
									checked={carBodyType === item.name}
									className={classes.optionRadio}
									onChange={(event) => handleTypeOptions(event, item)}
									value={item.name}
								/>
								<div className={classes.optionDetails}>
									<Typography variant='h5'>{t(`ui.characteristics.${item.name}`)}</Typography>
								</div>
							</ListItem>
						))}
				</List>
				<Typography className={classes.labelSpacing} variant='h5'>
					{t('views.addItem.characteristicsCars.carPoweredBy')}?
				</Typography>
				<List className={classes.listComponent}>
					{readyData &&
						propulsion[0].enumValues.map((item) => (
							<ListItem
								button={true}
								className={clsx(classes.option, {
									[classes.selectedOption]: propulsionType === item.name,
								})}
								key={item.id}
								onClick={(event) => handlePoweredBy(event, item)}
							>
								<Radio
									checked={propulsionType === item.name}
									className={classes.optionRadio}
									onChange={(event) => handlePoweredBy(event, item)}
									value={item.name}
								/>
								<div className={classes.optionDetails}>
									<Typography variant='h5'>{t(`ui.characteristics.${item.name}`)}</Typography>
								</div>
							</ListItem>
						))}
				</List>
				{propulsionType === 'fuel' ?
					<>
						<Typography className={classes.labelSpacing} variant='h5'>
							{t('views.addItem.characteristicsCars.whatTransmission')}?
						</Typography>
						<List className={classes.listComponent}>
							{readyData &&
								transmission[0].enumValues.map((item) => (
									<ListItem
										button={true}
										className={clsx(classes.option, {
											[classes.selectedOption]: transmissionType === item.name,
										})}
										key={item.id}
										onClick={(event) => handleTransmission(event, item)}
									>
										<Radio
											checked={transmissionType === item.name}
											className={classes.optionRadio}
											onChange={(event) => handleTransmission(event, item)}
											value={item.name}
										/>
										<div className={classes.optionDetails}>
											<Typography variant='h5'>{t(`ui.characteristics.${item.name}`)}</Typography>
										</div>
									</ListItem>
								))}
						</List>
					</>
				:	null}
				{propulsionType === 'electric' ?
					<>
						<Typography className={classes.labelSpacing} variant='h5'>
							{t('views.addItem.characteristicsCars.whatRangeOfElectricCar')}
						</Typography>
						<Box className={classes.listComponent}>
							<Box mb={1} width='180px'>
								<FormField
									fullWidth={true}
									hideCharacterCounter={!kmRangeValue.hasFocus}
									label={t('ui.characteristics.kmRange')}
									maxLength={5}
									name='kmRange'
									variable={kmRangeValue}
								/>
							</Box>
						</Box>
					</>
				:	null}
			</CharacteristicsCard>
			<AdditionalCharactaristics isEdit={isEdit}>
				{characteristicsSecondary.map((item) => (
					<FormControlLabel
						control={<Checkbox checked={item.checked} name={item.name} onChange={item.action} />}
						key={item.name}
						label={item.label}
					></FormControlLabel>
				))}
			</AdditionalCharactaristics>
			{isEdit ?
				<EditButtons isFinishingEdit={isFinishingEdit} onClose={onClose} />
			:	null}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		characteristics: state.details.characteristics,
	};
};

export default connect(mapStateToProps)(CarCharacteristics);

import { CreditCardOff as CreditCardOffIcon, Edit as EditIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { InfoCard, InfoCardRowDef } from '~components';
import { useAuthorize } from '~features/authentication';
import { toStreetString } from '~utils/addressUtils';

import PaymentMethodStatusEnum from '../../enums/paymentMethodStatusEnum';
import PaymentMethod from '../../interfaces/paymentMethod';
import PaymentMethodStatusChip from '../chips/paymentMethodStatusChip';

interface BillingCardProps {
	value?: PaymentMethod;
	enableEdit?: boolean;
	onEditClick?: (value?: PaymentMethod) => void;
	onRemovePaymentMethod?: (value: string) => void;
}

const BillingCard = ({ value, enableEdit = false, ...props }: BillingCardProps) => {
	const { t } = useTranslation();
	const { isSuperAdmin } = useAuthorize();

	const [searchParams] = useSearchParams();

	const isDebugHidden = searchParams.get('debug') == '1';

	const rows: InfoCardRowDef[] =
		value != null ?
			[
				{
					headerName: t('ui.label.paymentMethod'),
					value: value.label,
				},
				{
					headerName: t(value.card != null ? 'ui.label.cardDetails' : 'ui.label.bankDetails'),
					// value: value.bank != null ? value.bank.iban : value.card != null ? value.card.number : value.paypal != null ? value.paypal.email : '-',
					value: (() => {
						if (value.status !== PaymentMethodStatusEnum.Authorized) {
							return undefined;
						} else if (value.bank != null) {
							return value.bank.iban;
						} else if (value.card != null) {
							return value.card.number;
						} else if (value.paypal != null) {
							return value.paypal.email;
						}
					})(),
				},
				// 2025-03-14 Hidden until apps are ready
				{
					headerName: t('companyName'),
					value: value.companyName,
					hidden: !isDebugHidden,
				},
				{
					headerName: t('ui.label.address'),
					value: value.address ? toStreetString(value.address) : undefined,
					hidden: !isDebugHidden,
				},
				{
					headerName: t('ui.label.postalCode'),
					value: value.address?.postalCode,
					hidden: !isDebugHidden,
				},
				{
					headerName: t('ui.label.city'),
					value: value.address?.city,
					hidden: !isDebugHidden,
				},
				{
					headerName: t('ui.label.country'),
					value: value.address?.country,
					hidden: !isDebugHidden,
				},
			]
		:	[];

	return (
		<InfoCard
			title={t('views.userDetails.summary.cardHeaders.billing')}
			statusComponent={
				<PaymentMethodStatusChip status={value?.status ?? PaymentMethodStatusEnum.Unavailable} />
			}
			rows={rows}
			actions={[
				...(
					enableEdit ?
						[
							{
								label: t('ui.edit'),
								icon: <EditIcon />,
								onClick: () => props.onEditClick?.(value),
							},
						]
					:	[]),
				...(isSuperAdmin() && !(value?.status == null || value?.status === PaymentMethodStatusEnum.Unavailable) ? [
					{
						label: t('removeResource', { resource: t('ui.label.paymentMethod') }),
						icon: <CreditCardOffIcon />,
						onClick: () => props.onRemovePaymentMethod?.(value)
					},
				] : [])
			]}
		/>
	);
};

export default BillingCard;

import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, IconButton, CircularProgress } from '@mui/material';

import { Tooltip } from '~components';

import VerificationIcon from '../../../../../assets/icons/label-filled-icon-success.svg';
import WarningIcon from '../../../../../assets/icons/label-filled-icon-warning@3x.png';
import { Label } from '../../../../../components';
import { commaTimeStrings } from '../../../../../shared/datetime';
import { useStyles } from '../../../style';

interface AttentionProps {
	mileage?: number;
	attention?: boolean;
	info?: string;
	time?: string;
	editUser?: string;
	showIcon?: boolean;
	onClick?(...args: unknown[]): unknown;
	loading?: boolean;
}

const Attention = (props: AttentionProps) => {
	const { mileage, attention, time, info, editUser, showIcon, onClick, loading } = props;

	const classes = useStyles();

	const isLoading = attention && loading;

	return (
		<Box className={classes.attentionCard}>
			{mileage != null ? mileage : '-'}
			{attention &&
				<Tooltip title={info}>
					<img alt='warning-icon' src={WarningIcon} width='16' height='16' />
				</Tooltip>
			}
			{(editUser && showIcon) ?
				<Tooltip title={editUser}>
					<img alt='verification-icon' src={VerificationIcon} width='16' height='16' />
				</Tooltip>
			:	null}
			{attention && time ?
				<Label className={classes.attention}>{commaTimeStrings(time)}</Label>
			:	null}
			{!loading && attention ?
				<IconButton disabled={loading} onClick={onClick}>
					<RefreshIcon />
				</IconButton>
			:	null}
			{isLoading && <CircularProgress className={classes.circularLoading} size={24} color='info' />}
		</Box>
	);
};

export default Attention;

import { useState, useEffect } from 'react';

import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { Card, CardHeader, CardContent, Divider, Typography } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useStyles } from './style';
import { ButtonWithAlertDialog, InfoDialog } from '../../../../../../components';
import { useError } from '../../../../../../shared/hooks';
import * as actions from '../../../../../../store/actions';

interface OtherActionsProps {
	className?: string;
	userGroup?: object;
	onDeleteUserGroup?(...args: unknown[]): unknown;
	deletedUserGroup?: {
		success?: boolean;
		loading?: boolean;
		error?: object | string;
	};
}

const OtherActions = (props: OtherActionsProps) => {
	const { className, onDeleteUserGroup, userGroup, deletedUserGroup } = props;
	const { t } = useTranslation();
	const navigate = useNavigate();
	const classes = useStyles();

	const { success: deleteSuccess, loading: deleteLoading, error: deleteError } = deletedUserGroup;
	const deleteUserGroupDone = deleteSuccess && !deleteLoading && !deleteError;
	const [deletingUserGroup, setDeletingUserGroup] = useState(false);

	const [infoDialogOpen, setInfoDialogOpen] = useState(false);

	useEffect(() => {
		if (deletingUserGroup && deleteUserGroupDone) {
			setDeletingUserGroup(false);
			navigate('/user-management/user-groups');
		}
	}, [deletingUserGroup, deleteUserGroupDone]);

	/* * * * * * * * *
	 * NOTIFICATIONS *
	 * * * * * * * * */
	const deleteErrorHanding = useError({
		value: deletedUserGroup,
		message: `${t('views.userGroupDetail.messages.success.delete')} ${userGroup.name}`,
		variant: 'none',
	});

	const handleDeleteUserGroup = () => {
		onDeleteUserGroup(userGroup.id);
		deleteErrorHanding.setStartAction(true);
		setDeletingUserGroup(true);
	};

	const handleClickDelete = (handleOpen) => {
		if (userGroup.isDefault) {
			setInfoDialogOpen(true);
		} else {
			handleOpen();
		}
	};

	const handleCloseInfoDialog = () => {
		setInfoDialogOpen(false);
	};

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader title={t('ui.otherActions')} />
			<Divider />
			<CardContent>
				<Typography variant='body2'>{t('views.userGroupDetail.summary.deleteInfo')}</Typography>
				<ButtonWithAlertDialog
					actionDone={deleteUserGroupDone}
					callback={handleDeleteUserGroup}
					className={classes.deleteButton}
					dialogDescription={
						userGroup.totalUsers > 0 ?
							t('views.userGroupDetail.summary.deleteDialog.description.hasUsers')
						:	t('views.userGroupDetail.summary.deleteDialog.description.hasNoUsers')
					}
					dialogLoading={deleteLoading && !deleteUserGroupDone}
					dialogTitle={t('views.userGroupDetail.summary.deleteDialog.title')}
					error={deleteError}
					onClick={handleClickDelete}
					startIcon={<DeleteIcon />}
					variant='inline-delete'
				>
					{t('ui.button.inline.delete')}
				</ButtonWithAlertDialog>
				<InfoDialog
					dialogTitle={t('views.users.userGroups.deleteNotPossibleInfo.title')}
					onClose={handleCloseInfoDialog}
					open={infoDialogOpen}
				>
					<Typography>{t('views.users.userGroups.deleteNotPossibleInfo.description')}</Typography>
				</InfoDialog>
			</CardContent>
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		deletedUserGroup: state.condition.deletedUserGroup,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onDeleteUserGroup: (id) => dispatch(actions.deleteUserGroup(id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OtherActions);

import { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import LocationDetails from './LocationDetails';
import { useStyles } from './style';
import { Wizard } from '../../../../components';
import { localToUTCTime } from '../../../../shared/datetime';
import { usePrevious, useError } from '../../../../shared/hooks';
import {
	isObject,
	isUndefined,
	isFullArray,
	isEmptyObject,
	isFullString,
	isString,
} from '../../../../shared/utility';
import * as actions from '../../../../store/actions/index';

interface AddLocationProps {
	onAddHub?(...args: unknown[]): unknown;
	addHubDetails?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const AddLocation = (props: AddLocationProps) => {
	const classes = useStyles();
	const { onAddHub, addHubDetails } = props;
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [name, setName] = useState('');
	const [validName, setValidName] = useState(false);
	const [selectedOrganisation, setSelectedOrganisation] = useState({});
	const [searchValue, setSearchValue] = useState('');
	const [validLocation, setValidLocation] = useState(false);
	const [openingHours, setOpeningHours] = useState(null);

	const {
		data: addHubDetailsData,
		loading: addHubDetailsLoading,
		error: addHubDetailsError,
	} = addHubDetails;
	const addHubDone = isObject(addHubDetailsData) && !addHubDetailsLoading && !addHubDetailsError;

	const [imageArray, setImageArray] = useState([]);

	// checks to add images at the right time
	const [shouldSubmitHub, setShouldSubmitHub] = useState(false);
	const [submittingHub, setSubmittingHub] = useState(false);
	const [submittedHub, setSubmittedHub] = useState(false);

	const [startedFinishWizard, setStartedFinishWizard] = useState(false);
	const prevHubLoading = usePrevious(addHubDetailsLoading);

	const errorHandleAdd = useError(
		isObject(addHubDetails) ?
			{
				value: addHubDetails,
				message: `${t('views.addLocation.errorHandling')} ${isFullString(name) && name}`,
			}
		:	null,
	);

	/* * * * * * * * * * *
	 * Location METHODS  *
	 * * * * * * * * * * */
	useEffect(() => {
		if (shouldSubmitHub && !submittingHub && !submittedHub) {
			setSubmittingHub(true);
			setShouldSubmitHub(false);
		}
	}, [shouldSubmitHub, submittingHub, submittedHub]);

	useEffect(() => {
		if (submittingHub && addHubDone) {
			setSubmittingHub(false);
			setSubmittedHub(true);
		}
	}, [submittingHub, addHubDone]);

	/* * * * * * * * * * * * * *
	 * Location IMAGE METHODS  *
	 * * * * * * * * * * * * * */
	useEffect(() => {
		if (prevHubLoading && addHubDone) {
			navigate('/location-management/locations');
		}
	}, [addHubDetails]);

	useEffect(() => {
		if (isObject(addHubDetailsError)) {
			setStartedFinishWizard(false);
			errorHandleAdd.setStartAction(true);
		}
	}, [addHubDetailsError]);

	/* * * * * * *
	 * CALLBACK  *
	 * * * * * * */
	const callback = (data) => {
		const { name, organisation, weekdays, hubImages, finishedWizard } = data;

		if (!isUndefined(name)) {
			setName(name.value);
			setValidName(validName);
		}

		if (isString(data.searchValue)) {
			setSearchValue(data.searchValue);
		}

		if (isFullArray(weekdays)) {
			setOpeningHours(weekdays);
		}

		if (!isUndefined(organisation)) {
			setSelectedOrganisation(organisation.value);
		}

		setImageArray(hubImages);

		setValidLocation(
			isFullArray(hubImages) &&
				name.valid &&
				!isEmptyObject(organisation.value) &&
				isObject(data.placeData),
		);
		if (finishedWizard && validLocation && !startedFinishWizard) {
			const newOpenHours = weekdays.map((weekday) => ({
				dayOfWeek: weekday.value,
				openStatus:
					weekday.openAllDay ? 'openAllDay'
					: weekday.isOpen ? 'open'
					: 'closed',
				...(!weekday.openAllDay &&
					weekday.isOpen && {
						openTime: localToUTCTime(weekday.open).toISOString(),
						closeTime: localToUTCTime(weekday.close).toISOString(),
					}),
			}));
			setShouldSubmitHub(true);
			onAddHub(
				selectedOrganisation.id,
				data.placeData.geometry.location.latitude,
				data.placeData.geometry.location.longitude,
				data.name.value,
				data.placeData.address,
				newOpenHours,
				data.description,
				data.hubImages,
			);
			errorHandleAdd.setStartAction(true);
			setStartedFinishWizard(true);
		}
	};

	const addLocationSteps = [
		{
			name: t('ui.label.location'),
			content: LocationDetails,
			valid: validLocation,
			props: {
				className: classes.openingCard,
				images: imageArray,
				name,
				selectedOrganisation,
				openingHours,
				searchValue,
			},
		},
	];

	return (
		<Wizard
			callback={callback}
			finishButtonLabel={t('view.locationmanagement.locations.button.contained.addlocation')}
			loading={startedFinishWizard}
			stepperType='steps'
			steps={addLocationSteps}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		addHubDetails: state.details.addHubDetails,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onAddHub: (
			organisationId,
			latitude,
			longitude,
			address,
			name,
			newOpenHours,
			description,
			images,
		) =>
			dispatch(
				actions.addHub(
					organisationId,
					latitude,
					longitude,
					address,
					name,
					newOpenHours,
					description,
					images,
				),
			),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddLocation);

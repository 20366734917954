import { useEffect, useState } from 'react';

import { Add as AddIcon, Close as CloseIcon } from '@mui/icons-material';
import { Box, Grid, MenuItem, Select, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { SingleTransferList, TransferListItemModel } from '~components';

const rowsPerPageOptions = [5, 10, 20, 40, 50];

interface TransferListProps {
	// Left configuration
	leftTitle: string;
	leftItems: TransferListItemModel[];
	leftItemsCount?: number;
	leftPage?: number;
	onLeftPageChange?: (value: number) => void;
	leftLoading?: boolean;
	// Right configuration
	rightTitle: string;
	rightItems: TransferListItemModel[];
	rightItemsCount?: number;
	rightPage?: number;
	onRightPageChange?: (value: number) => void;
	rightLoading?: boolean;
	// General configuration
	onChange: (value: {
		leftItems: TransferListItemModel[];
		rightItems: TransferListItemModel[];
	}) => void;
	rowsPerPage?: number;
	onRowsPerPageChange?: (value: number) => void;
}

const TransferList = ({
	leftTitle,
	leftItems,
	leftItemsCount,
	leftPage = 1,
	onLeftPageChange,
	leftLoading = false,
	rightTitle,
	rightItems,
	rightItemsCount,
	rightPage = 1,
	onRightPageChange,
	rightLoading = false,
	rowsPerPage = 10,
	onChange,
	onRowsPerPageChange,
}: TransferListProps) => {
	const { t } = useTranslation();

	const [rowsPerPageComp, setRowsPerPageComp] = useState(rowsPerPage);

	useEffect(() => {
		if (onRowsPerPageChange) {
			onRowsPerPageChange(rowsPerPageComp);
		}
	}, [rowsPerPageComp]);

	const handleLeftToRightTransfer = (value) => {
		onChange({
			leftItems: value.remained,
			rightItems: [...value.transferred, ...rightItems],
		});
	};

	const handleRightToLeftTransfer = (value) => {
		if (onChange) {
			onChange({
				leftItems: [...leftItems, ...value.transferred],
				rightItems: value.remained,
			});
		}
	};

	const handleRowsPerPageChange = (e) => {
		setRowsPerPageComp(parseInt(e.target.value, 10));
	};

	const handleLeftPageChange = (value: number) => {
		if (onLeftPageChange) {
			onLeftPageChange(value);
		}
	};

	const handleRightPageChange = (value: number) => {
		if (onRightPageChange) {
			onRightPageChange(value);
		}
	};

	return (
		<>
			<Grid container gap={2}>
				<Grid item xs>
					<SingleTransferList
						pagination='server'
						title={leftTitle}
						transferIcon={<AddIcon />}
						items={leftItems}
						itemsCount={leftItemsCount}
						loading={leftLoading}
						page={leftPage}
						onPageChange={handleLeftPageChange}
						onTransfer={handleLeftToRightTransfer}
						rowsPerPage={rowsPerPage}
					/>
				</Grid>
				<Grid item xs>
					<SingleTransferList
						title={rightTitle}
						transferIcon={<CloseIcon />}
						items={rightItems}
						loading={rightLoading}
						page={rightPage}
						onPageChange={handleRightPageChange}
						onTransfer={handleRightToLeftTransfer}
						rowsPerPage={rowsPerPage}
					/>
				</Grid>
			</Grid>
			<Stack direction='row' justifyContent='flex-end' alignItems='center'>
				<Typography>{t('ui.rowsPerPage')}</Typography>
				<Select
					value={rowsPerPage}
					onChange={handleRowsPerPageChange}
					sx={{ '.MuiOutlinedInput-notchedOutline': { borderStyle: 'none' } }}
				>
					{rowsPerPageOptions.map((el) => (
						<MenuItem key={el} value={el}>
							{el}
						</MenuItem>
					))}
				</Select>
			</Stack>
		</>
	);
};

export default TransferList;

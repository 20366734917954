import countries from 'i18n-iso-countries';
import isoCountriesDe from 'i18n-iso-countries/langs/de.json';
import isoCountriesEn from 'i18n-iso-countries/langs/en.json';
import isoCountriesNl from 'i18n-iso-countries/langs/nl.json';
import i18n from 'i18next';
import LanguageDetector, { DetectorOptions } from 'i18next-browser-languagedetector';
import HttpBackend, { HttpBackendOptions } from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { LanguageEnum } from '~enums';

countries.registerLocale(isoCountriesDe);
countries.registerLocale(isoCountriesEn);
countries.registerLocale(isoCountriesNl);

const supportedLanguages = Object.values(LanguageEnum);

i18n
	.use(initReactI18next)
	.use(HttpBackend)
	.use(LanguageDetector)
	.init<HttpBackendOptions | DetectorOptions>({
		backend: {
			loadPath: '/locales/{{lng}}/{{ns}}.json',
			// Adding these query params should prevent the app to get
			// translations from the cache on a update. Use the build number
			// over the package version.
			// TODO: try to use a hash for unique values on whatever update?
			...(import.meta.env.VITE_BUILD_NUMBER != null && {
				queryStringParams: {
					v: import.meta.env.VITE_BUILD_NUMBER
				}
			})
		},
		fallbackLng: 'nl',
		supportedLngs: ['en', 'nl', 'de'],
		debug: import.meta.env.NODE_ENV === 'development',
		ns: ['general'],
		defaultNS: 'general',
		keySeparator: '.',
	});

/**
 * Notice, the ignore option is a custom option (not supported from the i18next)
 * It is meant to have a formatter for 99% of the cases, but able to ignore for
 * special cases. For example when interpolating
 */
i18n.services.formatter?.add('lowercase', (value: string, _, options) => {
	return options.formatParams?.ignore ? value : value.toLocaleLowerCase();
});

// Forces the languages to be loaded. This is done because some components
// require translations outside of the current selected languages.
// Example is for superadmins when rejecting user licenses and selecting
// a predefined response message.
i18n.loadLanguages(supportedLanguages, (error) => error != null && console.error(error));

export default i18n;

import { useState, useEffect } from 'react';

import { Card, CardContent, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useAuthorize } from '~features/authentication';

import {
	InfoLabel,
	SearchAutocomplete,
	BasicSelectedCard,
	SelectWithLazyLoading,
} from '../../../../../components';
import { isEmptyObject, isObject } from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';
import ItemHeader from '../ItemHeader';
import { useStyles } from '../stepsStyles';

interface SettingsCardProps {
	className?: string;
	selectedOrganisationId?: number;
	selectedCategory?: number;
	selectedTermsAndConditions?: object;
	selectedServiceOrganisation?: object;
	reservationType?: string;
	selectedBusinessPricing?: object;
	termsList?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	pricingList?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	save?(...args: unknown[]): unknown;
	selectedPricingModel?: object;
	policiesList?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	partnersList?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	hubsList?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	selectPolicyModel?: object;
	organisationChanged?: boolean;
	categoryChanged?: boolean;
	onFetchPolicies?(...args: unknown[]): unknown;
	onFetchTerms?(...args: unknown[]): unknown;
	onFetchPricingModels?(...args: unknown[]): unknown;
	onFetchPartners?(...args: unknown[]): unknown;
	setOrganisationChanged?(...args: unknown[]): unknown;
	setCategoryChanged?(...args: unknown[]): unknown;
	selectedOrganisation?: object;
	onFetchHubs?(...args: unknown[]): unknown;
	selectHub?: object;
}

const SettingsCard = (props: SettingsCardProps) => {
	const {
		save,
		selectedCategory,
		termsList,
		pricingList,
		policiesList,
		partnersList,
		onFetchHubs,
		onFetchPolicies,
		onFetchPricingModels,
		onFetchTerms,
		onFetchPartners,
		selectPolicyModel,
		selectedPricingModel,
		selectedTermsAndConditions,
		selectedServiceOrganisation,
		organisationChanged,
		setOrganisationChanged,
		categoryChanged,
		setCategoryChanged,

		selectedOrganisation,
		selectedOrganisationId,
		hubsList,
		selectHub: selectedHub,
		reservationType,
		selectedBusinessPricing,
	} = props;
	const { t } = useTranslation();
	const { isSuperAdmin } = useAuthorize();

	const classes = useStyles();

	const [terms, setTerms] = useState(selectedTermsAndConditions.title || '');
	const [pricing, setPricing] = useState(selectedPricingModel.name || '');
	const [businessPricing, setBusinessPricing] = useState(selectedBusinessPricing.name || '');
	const [policy, setPolicy] = useState(selectPolicyModel.name || '');
	const [hub, setHub] = useState(selectedHub?.name || '');
	const [selectedServiceProvider, setSelectedServiceProvider] = useState({});

	useEffect(() => {
		if (organisationChanged) {
			save({ updatedPolicyModel: {}, updatedTerms: {}, updatedPricingModel: {}, updatedHub: {} });
			setTerms('');
			setPricing('');
			setBusinessPricing('');
			setPolicy('');
			setHub('');
			setSelectedServiceProvider({});
			setOrganisationChanged(false);
		}
	}, [organisationChanged]);

	useEffect(() => {
		if (categoryChanged) {
			save({ updatedPricingModel: {}, updatedHub: {} });
			setPricing('');
			setBusinessPricing('');
			setHub('');
			setCategoryChanged(false);
		}
	}, [categoryChanged]);

	const resetSelectedServiceProvider = () => {
		setSelectedServiceProvider({});
		save({ updatedServiceProvider: {} });
	};

	useEffect(() => {
		if (isObject(selectedServiceProvider.partnerOrganisation)) {
			save({ updatedServiceProvider: selectedServiceProvider });
		}
	}, [selectedServiceProvider]);

	const [organisationIdFilter] = useState({
		name: 'organisationId',
		value: selectedOrganisationId,
	});
	const [partnerIdFilter] = useState({ name: 'status', value: 'accepted' });
	const [categoryIdFilter] = useState({ name: 'categoryId', value: selectedCategory });

	const onSelectedTerms = (value) => {
		setTerms(value.title);
		save({ updatedTerms: value });
	};

	const onSelectedPricing = (value) => {
		save({ updatedPricingModel: value });
		setPricing(value.name);
	};

	const onSelectedBusinessPricing = (value) => {
		save({ updatedBusinessPricing: value });
		setBusinessPricing(value.name);
	};

	const onSelectedPolicy = (value) => {
		setPolicy(value.name);
		save({ updatedPolicyModel: value });
	};

	const onSelectedHub = (value) => {
		setHub(value.name);
		save({ updatedHub: value });
	};

	const settings = [
		{
			lable: 'terms',
			listType: 'terms',
			list: termsList,
			onFetch: onFetchTerms,
			selected: onSelectedTerms,
			value: terms,
			searchHandle: setTerms,
		},
		...(reservationType !== 'nonBookable' ?
			[
				{
					lable: 'pricing',
					listType: 'organisations',
					list: pricingList,
					onFetch: onFetchPricingModels,
					selected: onSelectedPricing,
					value: pricing,
					searchHandle: setPricing,
				},
				{
					lable: 'businessPricing',
					listType: 'organisations',
					list: pricingList,
					onFetch: onFetchPricingModels,
					selected: onSelectedBusinessPricing,
					value: businessPricing,
					searchHandle: setBusinessPricing,
				},
				{
					lable: 'policy',
					listType: 'organisations',
					list: policiesList,
					onFetch: onFetchPolicies,
					selected: onSelectedPolicy,
					value: policy,
					searchHandle: setPolicy,
				},
			]
		:	[]),
	];

	return (
		<>
			<Card className={classes.itemSpacing}>
				<CardContent className={classes.mainCard}>
					<ItemHeader
						header={t('views.addItem.settingsCard.header')}
						subheader={t('views.addItem.settingsCard.subheader')}
						variant='h3'
						variantText='body2'
					/>
					<form>
						{settings.map((setting) => (
							<Box key={setting.lable}>
								<InfoLabel
									info={t(`views.addItem.settings.subsection.${setting.lable}.info`)}
									isRequired
									name={t(`views.addItem.settings.subsection.${setting.lable}.title`)}
									paddingBottom={3}
									paddingTop={3}
									variant={'h4'}
								/>
								<SelectWithLazyLoading
									dataList={setting.list}
									events={{ filter: { name: 'organisationId', value: selectedOrganisationId } }}
									filter={setting.lable === 'pricing' ? categoryIdFilter : null}
									listType={setting.listType}
									onFetchData={setting.onFetch}
									placeholder={t(`ui.placeholders.search.${setting.lable}`)}
									searchHandle={setting.searchHandle}
									setSelected={setting.selected}
									value={setting.value}
								/>
							</Box>
						))}
						<InfoLabel
							info={t('views.addItem.settings.subsection.serviceProvider.info')}
							name={t('views.addItem.settings.subsection.serviceProvider.title')}
							paddingBottom={3}
							paddingTop={3}
							variant={'h4'}
						/>
						{(
							!isSuperAdmin() &&
							(!isEmptyObject(selectedServiceOrganisation) ||
								!isEmptyObject(selectedServiceProvider))
						) ?
							<>
								{!isEmptyObject(selectedServiceProvider) ?
									<BasicSelectedCard
										handleClose={resetSelectedServiceProvider}
										hasCloseButton={true}
										name={selectedServiceProvider.partnerOrganisation.name}
									/>
								:	null}
								{(
									!isEmptyObject(selectedServiceOrganisation) &&
									isEmptyObject(selectedServiceProvider)
								) ?
									<BasicSelectedCard
										handleClose={resetSelectedServiceProvider}
										hasCloseButton={true}
										name={selectedServiceOrganisation.name}
									/>
								:	null}
							</>
						: isSuperAdmin() && !isEmptyObject(selectedOrganisation) ?
							<BasicSelectedCard hasCloseButton={false} name={selectedOrganisation.name} />
						:	<SearchAutocomplete
								dataList={partnersList}
								filter={partnerIdFilter}
								listType='partnerOrganisation'
								onFetchData={onFetchPartners}
								placeholder={t('ui.placeholders.search.serviceProvider')}
								setSelected={setSelectedServiceProvider}
							/>
						}
					</form>
				</CardContent>
			</Card>
			<Card>
				<CardContent className={classes.mainCard}>
					<Box display='flex' flexDirection='column' pb={4.5}>
						<Typography gutterBottom={true} variant='h3'>
							{t('views.addItem.settingsCard.locationCard.header')}
						</Typography>
						<Typography variant='body2'>
							{t('views.addItem.settingsCard.locationCard.subheader')}
						</Typography>
					</Box>
					<SelectWithLazyLoading
						dataList={hubsList}
						events={{ filter: { name: 'filters', value: 'none' } }}
						extraFilter={categoryIdFilter}
						filter={organisationIdFilter}
						listType={'hubs'}
						onFetchData={onFetchHubs}
						placeholder={t('views.addItem.settingsCard.locationCard.searchLocation')}
						searchHandle={setHub}
						setSelected={onSelectedHub}
						value={hub}
					/>
				</CardContent>
			</Card>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		policiesList: state.paged.policies,
		termsList: state.paged.terms,
		pricingList: state.paged.pricingModels,
		partnersList: state.paged.fetchPartnerships,

		hubsList: state.paged.hubs,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchPolicies: (page, filters, concat) =>
			dispatch(actions.fetchPolicies(page, filters, concat)),
		onFetchTerms: (page, filters, concat) => dispatch(actions.fetchTerms(page, filters, concat)),
		onFetchPricingModels: (page, filters, concat) =>
			dispatch(actions.fetchPricingModels(page, filters, concat)),
		onFetchPartners: (page, filters, concat) =>
			dispatch(actions.fetchPartnerships(page, filters, concat)),
		onFetchHubs: (page, filters, concat) => dispatch(actions.fetchHubs(page, filters, concat)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsCard);

import { configureStore } from '@reduxjs/toolkit';

import reducer from './reducers/index';

const store = configureStore({
	reducer,
	devTools: import.meta.env.NODE_ENV !== 'production',
});

export default store; 

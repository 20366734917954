import { Edit as EditIcon } from '@mui/icons-material';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
	CardActions,
} from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useStyles } from './style';
import { Label, StyledButton } from '../../../../../../components';

interface SettingsProps {
	className?: string;
	userGroup: object;
}

const Settings = (props: SettingsProps) => {
	const { userGroup, className } = props;
	const { t } = useTranslation();
	const navigate = useNavigate();

	const classes = useStyles();

	const handleEditOpen = () => {
		navigate(`/user-management/user-groups/${userGroup.id}/settings/edit`);
	};

	const tableRows = [
		{
			label: t('ui.label.organisationalBilling'),
			content: (
				<Label type={userGroup.allowOrganisationalBilling ? 'success' : 'error'}>
					{userGroup.allowOrganisationalBilling ? t('ui.enabled') : t('ui.disabled')}
				</Label>
			),
		},
		// {
		//   label: t('ui.label.personalBilling'),
		//   content:
		//     <Label type={userGroup.allowPersonalBilling ? 'success' : 'error'}>
		//       {userGroup.allowPersonalBilling ? t('ui.enabled') : t('ui.disabled')}
		//     </Label>
		// }
	];

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader title={t('ui.cardHeaders.settings')} />
			<Divider />
			<CardContent className={classes.content}>
				<Table>
					<TableBody>
						{tableRows.map((tableRow, index) => (
							<TableRow className={classes.tableRow} key={`table-row-${index}`}>
								<TableCell>{tableRow.label}</TableCell>
								<TableCell>{tableRow.content}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</CardContent>
			<CardActions className={classes.actions}>
				<StyledButton onClick={handleEditOpen} startIcon={<EditIcon />} variant='inline-default'>
					{t('ui.button.inline.edit')}
				</StyledButton>
			</CardActions>
		</Card>
	);
};

export default Settings;

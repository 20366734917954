import { UserManager, WebStorageStateStore } from 'oidc-client-ts';

import { pagePaths } from '~constants';

/**
 * A user manager useable within and outside react
 * See: https://github.com/authts/react-oidc-context/issues/313#issuecomment-1087804261
 */
const userManager = new UserManager({
	authority: import.meta.env.VITE_IDENTITY_AUTHORITY,
	client_id: import.meta.env.VITE_IDENTITY_CLIENT_ID,
	automaticSilentRenew: true,
	response_type: 'code',
	scope: 'openid profile email skopei_api offline_access',
	redirect_uri: new URL(pagePaths.AuthCallback, window.origin).href,
	post_logout_redirect_uri: new URL(pagePaths.AuthLogin, window.origin).href,
	silent_redirect_uri: new URL(pagePaths.AuthSilent, window.origin).href,
	userStore: new WebStorageStateStore({ store: localStorage }),
	loadUserInfo: true
});

export default userManager;

import { useAtomValue } from 'jotai';
import { useAuth } from 'react-oidc-context';

import { userInfoAtom } from '~atoms';
import { UserRoleEnum } from '~enums';

import { authorize } from '../utils/authorize';

/**
 * A hook to easily determine if authorized or not within Topology
 * @returns
 */
const useAuthorize = () => {
	const auth = useAuth();
	const userInfo = useAtomValue(userInfoAtom);

	const role = (auth.user?.profile.role as string)?.toLowerCase() as UserRoleEnum;

	const isSuperAdmin = () => {
		return role === UserRoleEnum.SuperAdmin;
	};

	/**
	 *
	 * @param exclusive A boolean to set if the user must be exactly an admin
	 * If false, it will check an admin or higher
	 * @returns
	 */
	const isAdmin = (exclusive = false) => {
		if (exclusive) {
			return role === UserRoleEnum.Admin;
		}

		return [UserRoleEnum.SuperAdmin, UserRoleEnum.Admin].includes(role);
	};

	/**
	 *
	 * @param roles The roles that have access. Based on a hierarchy
	 * SuperAdmin > Admin > User
	 * @param exclusive A boolean to set if the user must be exactly an admin
	 * If false, it will check an admin or higher
	 */
	const authorizeInternal = (roles: UserRoleEnum[], exclusive = false) => 
		authorize(role, roles, exclusive)
	;

	/**
	 * TODO: Eventually include partners here?
	 * @param organisationId The organisation id on which to authorize
	 * @returns True if you are authorized
	 */
	const authorizeForOrganisation = (organisationId?: string | number) =>
		userInfo?.organisation.id == organisationId || isSuperAdmin();

	return {
		isAdmin,
		isSuperAdmin,
		authorize: authorizeInternal,
		authorizeForOrganisation,
	};
};

export default useAuthorize;

import { ChangeEvent, DragEvent, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatBytes } from '~utils';

interface FileInputError {
	index: number;
	error: Error;
}

interface FileInput {
	file: File;
	uri: string;
}

interface useFilePickerProps {
	accept?: string;
	/**
	 * Allow selecting multiple files
	 */
	multiple?: boolean;
	/**
	 * The maximum size in bytes
	 */
	maxSize?: number;

	onChange?: (files: FileInput[]) => void;

	onError?: (errors: FileInputError[]) => void;
}

const useFilePicker = ({
	maxSize = 50 * 1024 * 1024,
	multiple = false,
	onChange,
	onError,
	...props
}: useFilePickerProps = {}) => {
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);
	const [files, setFiles] = useState<FileInput[]>([]);
	const [errors, setErrors] = useState<FileInputError[]>([]);

	const clear = useCallback(() => {
		setFiles([]);
		setErrors([]);

		onChange?.([]);
	}, []);

	const openFilePicker = useCallback(() => {
		const inputElement = document.createElement('input');

		// For iOS safari
		inputElement.style.display = 'none';
		document.body.appendChild(inputElement);

		inputElement.multiple = multiple;
		inputElement.type = 'file';
		if (props.accept) {
			inputElement.accept = props.accept;
		}

		inputElement.addEventListener('change', (e) => {
			setLoading(true);

			handleSelectFiles(e);

			document.body.removeChild(inputElement);

			setLoading(false);
		});

		inputElement.click();
	}, [props]);

	const handleSelectFiles = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.files == null) {
			console.warn('No input files selected');
			return;
		}

		clear();

		const inputFiles = Array.from(e.target.files);
		handleFiles(inputFiles);
	};

	const handleDrop = (event: DragEvent<HTMLDivElement>) => {
		event.preventDefault();

		const dropFiles = Array.from(event.dataTransfer.files);
		handleFiles(dropFiles);
	};

	const handleFiles = (fileInputs: File[]) => {
		const newErrors: FileInputError[] = [];
		fileInputs.forEach((file, i) => {
			if (file.size > maxSize) {
				newErrors.push({
					index: i,
					error: new Error(
						t('fileSizeTooLargeWithValue', {
							value: formatBytes(maxSize, 1),
						})
					),
				});
			}
		});

		if (newErrors.length > 0) {
			console.warn(newErrors);
			setErrors(newErrors);
			return;
		}

		const newFiles = fileInputs.map((file) => ({
			file: file,
			uri: URL.createObjectURL(file),
		}));
		onChange?.(newFiles);
		setFiles(newFiles);
	};

	return {
		loading,
		files,
		errors,
		clear,
		openFilePicker,
		dropFiles: handleDrop,
	};
};

export default useFilePicker;

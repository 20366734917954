import { Close as CloseIcon } from '@mui/icons-material';
import { Card, Typography, Avatar, IconButton, Box } from '@mui/material';

import { getInitials } from '~utils/stringUtils';

import { useStyles } from './style';

interface UserCardProps {
	name?: string;
	email?: string;
	imagesReference?: string;
	onClick?(...args: unknown[]): unknown;
}

const UserCard = (props: UserCardProps) => {
	const { name, email, imagesReference, onClick } = props;
	const classes = useStyles();

	return (
		<Card className={classes.userCard}>
			<Avatar className={classes.avatar} alt='User' src={imagesReference}>
				{getInitials(name)}
			</Avatar>
			<Box className={classes.labelCard}>
				<Typography variant='h6'>{name}</Typography>
				<Typography className={classes.emailLabel}>{email}</Typography>
			</Box>
			<IconButton onClick={onClick} className={classes.closeButton}>
				<CloseIcon />
			</IconButton>
		</Card>
	);
};

export default UserCard;

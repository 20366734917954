import React, { createElement, useEffect, useRef, useState } from 'react';

import {
	Add as AddIcon,
	InsertDriveFileOutlined as InsertDriveFileOutlinedIcon,
	MoreVert as MoreVertIcon,
	NavigateBefore as NavigateBeforeIcon,
} from '@mui/icons-material';
import {
	Autocomplete,
	Avatar,
	Box,
	Button,
	CircularProgress,
	DialogActions,
	DialogContent,
	MenuItem,
	Stack,
	Switch,
	TextField,
	Typography,
} from '@mui/material';
import dayjs from 'dayjs';

import { Item, ItemCategorySelect } from '~features/items';
import {
	ActionDialog,
	AddressCard,
	AddressForm,
	AsyncTextField,
	ConfirmationDialog,
	Dialog,
	DragAndDropFilesUploader,
	DragAndDropImageCropper,
	ImageCropper,
	LanguageSelect,
	NumberInput,
	Select,
	SwitchButton,
	Tooltip,
} from '~components';
import {
	AddSsoDomainsLayout,
	OrganisationsService,
	SsoDomainsDataGrid,
	SsoDomainsLayout,
} from '~features/organisations';
import useSWR from 'swr';
import SsoDomainForm from '~features/organisations/forms/ssoDomain/ssoDomainForm';
import { useTranslation } from 'react-i18next';
import { LanguageEnum } from '~enums';
import OrganisationGeneralInformationCard from '~features/organisations/components/cards/organisationGeneralInformationCard';
import useFilePicker from '~hooks/useFilePicker';
import DragAndDropImageUploader from '~components/croppers/dragAndDropImageUploader';
import DeviceStatusCard from '~features/devices/components/cards/deviceStatusCard';
import { formatBytes } from '~utils';
import { getInitials } from '~utils/stringUtils';
import LoadingFallback from '~components/fallbacks/loadingFallback';
import { LineChart, SparkLineChart } from '@mui/x-charts';
import HighLightedSparkLineChart from '~components/charts/highlightedSparkLineChart';
import CountryAutocomplete from '~components/autocompletes/countryAutocomplete';
import { ErrorFallback } from '~components';
import RedirectError from '../../views/ErrorPages';
import { UserAutocomplete } from '~features/users';
import LoadingSwitch from '~components/switches/loadingSwitch';
import BillingCard from '~features/finances/components/cards/billingCard';
import NumberField from '~components/numberFields/numberField';
import AccessDevicesDataGrid from '~features/access/components/dataGrids/accessDevicesDatagrid';
import SubscriptionSelect from '~features/organisations/components/selects/subscriptionSelect';
import RemovePaymentMethodForm from '~features/finances/forms/removePaymentMethod/removePaymentMethodForm';
import { UserRoleSelect } from '~features/authentication';
import MenuButton from '~components/buttons/menuButton';

const service = new OrganisationsService();

const bla = ['henk', 'annie', 'bert'];

/**
 * This page is meant as a sandbox for a develop to develop or
 * test a component outside of the normal flow
 */
const SandboxPage = () => {
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);
	const [dateVal, setDateVal] = useState<Dayjs | null>(dayjs());
	const [val, setVal] = useState(null);
	const [textVal, setTextVal] = useState<string | null>(null);

	const [numVal, setNumVal] = useState(0);
	const [boolVal, setBoolVal] = useState(false);

	const [orignalImageUri, setOriginalImageUri] = useState<string | null>(null);
	const [imageUri, setImageUri] = useState<string | null>(
		'https://www.boels.com/api/medias/cards-boels-vestiging.jpg?context=bWFzdGVyfHJvb3R8MjM2Nzk1fGltYWdlL2pwZWd8YURCakwyZ3paQzg1TlRrd01Ua3lPRFkxTXpFd0wyTmhjbVJ6TFdKdlpXeHpMWFpsYzNScFoybHVaeTVxY0djfGViNmU2OGUyNWNmYzZmOGNmYzMxMjQzMDhlNzVlM2JjYjUzOTY5YmJkMTBmOGIxMzUwMTU1MGUxZTZiYzc2MTI'
	);

	const [lng, setLng] = useState<LanguageEnum>(LanguageEnum.English);

	const filePicker = useFilePicker({
		accept: 'image/png, image/jpeg',
	});

	const handleClick = (value: boolean) => {
		setLoading(true);
		setTimeout(() => {
			setBoolVal(value);
			setLoading(false);
		}, 1000);
	};

	return (
		<Stack spacing={1}>
			{/* <SubscriptionSelect
				value={val}
				onChange={(_, v) => setVal(v)}
			/> */}

			<RemovePaymentMethodForm />

			<UserRoleSelect value={val} onChange={(v) => setVal(v)} displayEmpty />

			<Tooltip
				open={false}
				title={bla.map((el) => (
					<Typography key={el}>{el}</Typography>
				))}
			>
				<div style={{ width: 70 }}>Hoi</div>
			</Tooltip>

			<Box>
				<MenuButton
					startIcon={<InsertDriveFileOutlinedIcon />}
					options={[
						{ label: 'joi', icon: <AddIcon /> },
						{ label: 'sfsfs ', icon: <NavigateBeforeIcon /> },
					]}
				>
					Joe
				</MenuButton>
			</Box>

			{/* <ConfirmationDialog
				title={t('views.actions.cancelUnabailabilitys.title')}
				subTitle={t('views.actions.cancelUnabailabilitys.description')}
				variant='alternative'
				open={!boolVal}
				onConfirm={() => setBoolVal(prev => !prev)}
			/>
			<ActionDialog
				title={t('views.actions.cancelUnabailabilitys.title')}
				actionButtonProps={{
					action: () => setBoolVal(prev => !prev),
					text: 'whatever'
				}}
				open={boolVal}
			>
				{t('views.actions.cancelUnabailabilitys.description')}
			</ActionDialog> */}
		</Stack>
	);
};

export default SandboxPage;

import { useState, useEffect, useRef } from 'react';

import { Edit as EditIcon, PhotoCamera as PhotoCameraIcon } from '@mui/icons-material';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Box,
	Typography,
	CardActions,
	Stack,
	IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { DragAndDropImageCropper } from '~components';
import { useAuthorize } from '~features/authentication';
import { useSnackbar } from '~hooks';

import LocationEdit from './LocationEdit';
import { useStyles } from './style';
import { StyledButton } from '../../../../../../components';
import { isFullArray } from '../../../../../../shared/utility';
import * as actions from '../../../../../../store/actions';

interface LocationInfoProps {
	className?: string;
	hub?: object;
	onResetStateCondition?(...args: unknown[]): unknown;
	onUpdateLocationImage?(...args: unknown[]): unknown;
	onUpdateDetailsState?(...args: unknown[]): unknown;
	updateLocationImage?: {
		data?: unknown[];
		loading?: boolean;
		error?: object | string;
	};
	updateHubDetails?: {
		success?: boolean;
		loading?: boolean;
		error?: object | string;
	};
}

const LocationInfo = (props: LocationInfoProps) => {
	const {
		hub,

		updateHubDetails,
		onResetStateCondition,
		onUpdateLocationImage,
		updateLocationImage,
		onUpdateDetailsState,
	} = props;
	const { t } = useTranslation();
	const { authorizeForOrganisation } = useAuthorize();

	const { success: updateLocationSuccess, loading: updateLocationLoading } = updateHubDetails;

	const {
		data: updateImageData,
		loading: updateImageLoading,
		error: updateImageError,
	} = updateLocationImage;

	const { enqueueSnackbar, enqueueErrorSnackbar } = useSnackbar();

	const classes = useStyles();

	const [openEdit, setOpenEdit] = useState(false);

	useEffect(() => {
		if (!updateImageLoading) {
			if (isFullArray(updateImageData)) {
				onUpdateDetailsState('hubDetails', { ...hub, imagesReference: updateImageData });
			}
		}
	}, [updateLocationImage]);

	useEffect(() => {
		if (updateLocationSuccess) {
			enqueueSnackbar(t('views.locationDetail.locationInfo.edit.success.message'), {
				variant: 'success',
			});
			onResetStateCondition('updateHubDetails', false);
		}
	}, [updateLocationLoading]);

	const handleChange = (file, uri) => {
		onUpdateLocationImage(hub.id, [file]);
	};

	const handleEditOpen = () => {
		setOpenEdit(true);
	};

	const handleEditClose = () => {
		setOpenEdit(false);
	};

	return (
		<Card className={classes.root}>
			<CardHeader title={t('views.locationDetail.locationInfo.locationInfo')} />
			<Divider />
			<CardContent className={classes.content}>
				<Box>
					<DragAndDropImageCropper
						src={hub.imagesReference?.[0]}
						onChange={(val) => val != null && handleChange(val.file, val.uri)}
						slotProps={{
							uploader: {
								clearable: false,
								readonly: !authorizeForOrganisation(hub?.organisationReference.id),
								maxFileSizeInBytes: 1024000,
								onError: (errors) => enqueueErrorSnackbar(errors[0]?.message)
							},
							imageCropper: {
								aspect: 1.5,
							},
						}}
					/>
				</Box>

				<Table>
					<TableBody>
						<TableRow>
							<TableCell>{t('views.locationDetail.locationInfo.locationName')}</TableCell>
							<TableCell>{hub.name}</TableCell>
						</TableRow>
					</TableBody>
				</Table>
				<Box display='flex' flexDirection='column' pl={1.5} pt={1.5}>
					<Typography color='primary' gutterBottom={true} variant='body2'>
						{t('views.locationDetail.locationInfo.locationDescription')}
					</Typography>
					<Box pt={1}>
						<Typography variant='body2'>{hub.description ? hub.description : '-'}</Typography>
					</Box>
				</Box>
			</CardContent>
			<CardActions className={classes.actions}>
				<StyledButton onClick={handleEditOpen} startIcon={<EditIcon />} variant='inline-default'>
					{t('ui.button.inline.edit')}
				</StyledButton>
				{openEdit ?
					<LocationEdit location={hub} onClose={handleEditClose} open={openEdit} />
				:	null}
			</CardActions>
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		updateHubDetails: state.condition.updateHubDetails,
		updateLocationImage: state.details.updateLocationImage,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onResetStateCondition: (state, value) => dispatch(actions.resetStateCondition(state, value)),
		onUpdateLocationImage: (locationId, imageArray) =>
			dispatch(actions.updateLocationImage(locationId, imageArray)),
		onUpdateDetailsState: (identifier, data) =>
			dispatch(actions.updateDetailsState(identifier, data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationInfo);

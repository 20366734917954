import { useState, useEffect, useMemo } from 'react';

import MoneyIcon from '@mui/icons-material/Money';
import {
	Box,
	Card,
	CardActions,
	CardHeader,
	CardContent,
	CircularProgress,
	Divider,
	Switch,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Typography,
} from '@mui/material';
import { AxiosError } from 'axios';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import useSWRMutation from 'swr/mutation';

import { ConfirmationDialog } from '~components';
import { useAuthorize } from '~features/authentication';
import { Organisation, OrganisationsService } from '~features/organisations';
import { useSnackbar } from '~hooks';
import { TopologyErrorResponse } from '~interfaces/responses';

import { useStyles } from './style';
import {
	SubscriptionSelectField,
	AlertDialog,
	StyledButton,
	Label,
} from '../../../../../../components';
import { useWizardFormField } from '../../../../../../shared/hooks';
import { isObject, isArray, isEmptyObject } from '../../../../../../shared/utility';
import * as actions from '../../../../../../store/actions';

type UserAction = {
	type: 'changeSubscription';
	subscription: object; // TODO: eventually through id + label combi
};

const service = new OrganisationsService();

interface SubscriptionProps {
	className?: string;
	organisation: object;
	patchedOrganisation?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	subscriptions?: {
		data?: unknown[];
		loading?: boolean;
		error?: object | string;
	};
	publicItems?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onFetchOrganisation?(...args: unknown[]): unknown;
	onFetchPublicItems?(...args: unknown[]): unknown;
	/**
	 * Event when some data has been changed
	 */
	onChange?: () => void;
}

const Subscription = ({ onChange, ...props }: SubscriptionProps) => {
	const {
		organisation,
		className,

		patchedOrganisation,
		subscriptions,
		onFetchOrganisation,

		onFetchPublicItems,
		publicItems,
	} = props;
	const { t } = useTranslation();
	const { isSuperAdmin } = useAuthorize();
	const [userAction, setUserAction] = useState<UserAction | null>(null);

	const classes = useStyles();
	const { enqueueSnackbar, enqueueAxiosErrorSnackbar, enqueueSuccessSnackbar } = useSnackbar();
	const orgSubscriptions =
		isObject(organisation) && isObject(organisation.subscription) && organisation.subscription;
	const isSupplierSubscription =
		orgSubscriptions && orgSubscriptions.subscriptionType === 'supplier';

	const { data: subsData, loading: subsLoading, error: subsError } = subscriptions;
	const subscriptionsReady = isArray(subsData) && !subsLoading && !subsError;

	const {
		data: patchOrgData,
		loading: patchOrgLoading,
		error: patchOrgError,
	} = patchedOrganisation;
	const patchOrganisationDone = isObject(patchOrgData) && !patchOrgLoading && !patchOrgError;

	const {
		data: publicItemsData,
		loading: publicItemsLoading,
		error: publicItemsError,
	} = publicItems;
	const publicItemsReady = isObject(publicItemsData) && !publicItemsLoading && !publicItemsError;

	const subscription = useWizardFormField(
		isObject(orgSubscriptions) ? `${orgSubscriptions.id}` : '',
		{ required: true }
	);
	const [chosenSubscription, setChosenSubscription] = useState({});
	const [openDialog, setOpenDialog] = useState(false);
	const [updatingOrganisation, setUpdatingOrganisation] = useState(false);

	const canNotChangeSubscription =
		isSupplierSubscription &&
		(chosenSubscription.subscriptionType === 'organisation' || userAction?.subscription.subscriptionType === 'organisation') &&
		publicItemsReady &&
		publicItemsData.results.length > 0;

	const { trigger: triggerPatchOrganisation, isMutating: isPatchOrganisationMutating } =
		useSWRMutation(
			[service.basePath, organisation.id.toString()],
			([_, id], { arg }: { arg: Partial<Organisation> }) => service.patchOrganisation(id, arg),
			{
				onSuccess: () => {
					enqueueSuccessSnackbar(
						`${t('views.organisationDetail.summary.subscription.message.success')} ${organisation.name}`
					);
					setUserAction(null);
					onChange?.();
					setOpenDialog(false);
				},
				onError: (error: AxiosError) =>
					enqueueAxiosErrorSnackbar(error, error.response?.data?.message),
			}
		);

	const { trigger: triggerSsoOnly, isMutating: isSsoOnlyMutating } = useSWRMutation(
		[service.basePath, organisation.id.toString(), service.ssoSubPath],
		([_, id], { arg }: { arg: boolean }) => service.setSsoOnlyAccess(id, arg),
		{
			onSuccess: () => {
				enqueueSuccessSnackbar(t('ssoSignupChangeSuccess'));
				setUpdatingOrganisation(false);
				onFetchOrganisation(organisation.id);
				onChange?.();
			},
			onError: (error: AxiosError<TopologyErrorResponse>) => {
				enqueueAxiosErrorSnackbar(error, error.response?.data?.message);
			},
		}
	);

	const { trigger: triggerEmailDomainLinking, isMutating: isEmailDomainLinkingMutating } =
		useSWRMutation(
			[service.basePath, organisation.id.toString(), service.emailDomainsSubPath],
			([_, id], { arg }: { arg: boolean }) => service.setEmailDomainLinking(id, arg),
			{
				onSuccess: () => {
					enqueueSuccessSnackbar(
						t('views.organisationDetail.summary.updatedEmailDomainLinking.success')
					);
					setUpdatingOrganisation(false);
					onFetchOrganisation(organisation.id);
					onChange?.();
				},
				onError: (error: AxiosError) => {
					enqueueAxiosErrorSnackbar(error, error.response?.data?.message);
				},
			}
		);

	useEffect(() => {
		onFetchPublicItems(organisation.id);
	}, []);

	useEffect(() => {
		if (updatingOrganisation && patchOrganisationDone) {
			setUpdatingOrganisation(false);
			setOpenDialog(false);
			onFetchOrganisation(organisation.id);
			enqueueSnackbar(
				`${t('views.organisationDetail.summary.subscription.message.success')} ${organisation.name}`,
				{ variant: 'success' }
			);
			onChange?.();
		}
	}, [updatingOrganisation, patchOrganisationDone]);

	useEffect(() => {
		if (updatingOrganisation && !patchOrgLoading && !!patchOrgError) {
			setUpdatingOrganisation(false);
			setOpenDialog(false);
			enqueueSnackbar(isObject(patchOrgError) ? patchOrgError.message : patchOrgError, {
				variant: 'error',
			});
		}
	});

	const handleChangeSubscription = (e) => {
		if (subscriptionsReady) {
			const subIndexArray = subscriptions.data.map((sub) => `${sub.id}`);
			const subIndex = subIndexArray.indexOf(e.target.value);
			setChosenSubscription(subsData[subIndex]);
			setOpenDialog(true);
			// setUserAction({ type: 'changeSubscription', subscription: subsData[subIndex] })
		}
	};

	const handleClickUpdateSubscription = () => {
		window.open('https://www.topology.nl/contact');
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
	};

	const handleConfirmDialog = () => {
		triggerPatchOrganisation({ subscriptionId: chosenSubscription.id });
		setUpdatingOrganisation(true);
	};

	const alertDialogContent =
		subscriptionsReady && !isEmptyObject(chosenSubscription) ?
			(
				isSupplierSubscription &&
				chosenSubscription.subscriptionType === 'organisation' &&
				publicItemsReady &&
				publicItemsData.results.length > 0
			) ?
				<Box>
					<Typography variant='body2'>
						{t('views.organisationDetail.summary.alertDialog.subscription.description.warning')}
					</Typography>
				</Box>
			:	<Box>
					<Typography variant='body2'>
						{`${t('views.organisationDetail.summary.alertDialog.subscription.description.detail.partOne')} ${orgSubscriptions ? `${orgSubscriptions.name} (${t(`ui.subscriptionType.${orgSubscriptions.subscriptionType}`)})` : t('ui.none')} ${t('views.organisationDetail.summary.alertDialog.subscription.description.detail.partTwo')} ${chosenSubscription.name} (${t(`ui.subscriptionType.${chosenSubscription.subscriptionType}`)}).`}
					</Typography>
					<Typography variant='body2'>
						{t('views.organisationDetail.summary.alertDialog.subscription.description.general')}
					</Typography>
				</Box>
		:	null;

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader title={t('views.organisationDetail.summary.cardHeaders.subscription')} />
			<Divider />
			<CardContent className={classes.content}>
				{isSuperAdmin() ?
					<SubscriptionSelectField
						className={classes.subscriptionSelect}
						disabled={!publicItemsReady}
						hasFirstSelectionDisabled
						helperText={t('views.organisationDetail.summary.helperText.subscription')}
						variable={{
							...subscription,
							bindToFormField: {
								...subscription.bindToFormField,
								onChange: handleChangeSubscription,
							},
						}}
					/>
				:	null}
				<Table>
					<TableBody>
						{!isSuperAdmin() ?
							<>
								<TableRow>
									<TableCell>{t('ui.label.subscription')}</TableCell>
									<TableCell>
										{orgSubscriptions ?
											orgSubscriptions.name
										:	t('views.organisationDetail.summary.noSubscriptionSelected')}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>{t('ui.label.price')}</TableCell>
									<TableCell>
										{orgSubscriptions ?
											`€${orgSubscriptions.price.price}/${t('ui.month').toLowerCase()}`
										:	'-'}
									</TableCell>
								</TableRow>
							</>
						:	null}
						<TableRow>
							<TableCell>{t('ui.label.itemLimit')}</TableCell>
							<TableCell>
								{orgSubscriptions && orgSubscriptions.maxItemInstances !== 999999999 ?
									(
										orgSubscriptions.subscriptionType === 'supplier' &&
										orgSubscriptions.maxItemInstances === 1000
									) ?
										'*'
									:	orgSubscriptions.maxItemInstances
								:	'-'}
							</TableCell>
						</TableRow>
						<TableRow className={classes.tableRow}>
							<TableCell>{t('ui.label.userLimit')}</TableCell>
							<TableCell>{'-'}</TableCell>
						</TableRow>

						<TableRow className={classes.tableRow}>
							<TableCell>
								{t('views.organisationDetail.summary.subscription.emailDomainPairing')}
							</TableCell>
							<TableCell>
								{isEmailDomainLinkingMutating ?
									<CircularProgress color='inherit' disableShrink size={24} />
								: isSuperAdmin() ?
									<Switch
										checked={organisation?.features.includes('emailDomains')}
										color='primary'
										onChange={async () =>
											await triggerEmailDomainLinking(
												!organisation?.features.includes('emailDomains')
											)
										}
										size='small'
									/>
								:	<Label
										type={organisation?.features.includes('emailDomains') ? 'success' : 'error'}
									>
										{t(
											organisation?.features.includes('emailDomains') ?
												'ui.enabled'
											:	'ui.status.disabled'
										)}
									</Label>
								}
							</TableCell>
						</TableRow>

						<TableRow className={classes.tableRow}>
							<TableCell>{t('signupOnlyViaSso')}</TableCell>
							<TableCell>
								{isSsoOnlyMutating ?
									<CircularProgress color='inherit' disableShrink size={24} />
								: isSuperAdmin() ?
									<Switch
										checked={organisation?.features.includes('ssoDomains')}
										color='primary'
										onChange={async () =>
											await triggerSsoOnly(!organisation?.features.includes('ssoDomains'))
										}
										size='small'
									/>
								:	<Label type={organisation?.features.includes('ssoDomains') ? 'success' : 'error'}>
										{t(
											organisation?.features.includes('ssoDomains') ?
												'ui.enabled'
											:	'ui.status.disabled'
										)}
									</Label>
								}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</CardContent>
			{!isSuperAdmin() ?
				<CardActions>
					<StyledButton
						onClick={handleClickUpdateSubscription}
						startIcon={<MoneyIcon />}
						variant='inline-default'
					>
						{t('view.organisationmanagement.organisationDetails.button.inline.updatesubscription')}
					</StyledButton>
				</CardActions>
			:	null}
			<AlertDialog
				confirmDisabled={
					isSupplierSubscription &&
					chosenSubscription.subscriptionType === 'organisation' &&
					publicItemsReady &&
					publicItemsData.results.length > 0
				}
				dialogContent={alertDialogContent}
				dialogTitle={t('views.organisationDetail.summary.alertDialog.subscription.title')}
				handleClose={handleCloseDialog}
				handleConfirm={handleConfirmDialog}
				open={openDialog}
			/>

			<ConfirmationDialog
				title={t('views.organisationDetail.summary.alertDialog.subscription.title')}
				subTitle={
					canNotChangeSubscription ?
						t('views.organisationDetail.summary.alertDialog.subscription.description.warning')
					:	`${t('views.organisationDetail.summary.alertDialog.subscription.description.detail.partOne')} ${orgSubscriptions ? `${orgSubscriptions.name} (${t(`ui.subscriptionType.${orgSubscriptions.subscriptionType}`)})` : t('ui.none')} ${t('views.organisationDetail.summary.alertDialog.subscription.description.detail.partTwo')} ${userAction?.subscription.name} (${t(`ui.subscriptionType.${userAction?.subscription.subscriptionType}`)}). ${t('views.organisationDetail.summary.alertDialog.subscription.description.general')}`
				}
				open={userAction?.type === 'changeSubscription'}
				disableConfirm={canNotChangeSubscription}
				loading={isPatchOrganisationMutating}
				onClose={() => setUserAction(null)}
				onConfirm={async () => await triggerPatchOrganisation({ subscriptionId: chosenSubscription.id })}
			/>
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		subscriptions: state.list.subscriptions,
		patchedOrganisation: state.details.patchedOrganisation,

		publicItems: state.paged.items,
		updateEmailDomainLinking: state.condition.updateEmailDomainLinking,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchOrganisation: (id) => dispatch(actions.fetchOrganisation(id)),
		onFetchPublicItems: (organisationId) =>
			dispatch(
				actions.fetchItems({ number: 1, size: 20 }, { itemAccess: 'public', organisationId })
			)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscription);

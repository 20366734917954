import { useEffect } from 'react';

import { useAuth } from 'react-oidc-context';

import { LoadingFallback } from '~components';
import i18n from '~lib/i18n';

import Page from '../page';

interface LogoutPageProps {
	title?: string;
	description?: string;
}

const LogoutPage = ({ title = i18n.t('signingOut'), description }: LogoutPageProps) => {
	const auth = useAuth();

	useEffect(() => {
		signout();
	}, []);

	const signout = async () => {
		localStorage.removeItem('currentUser');
		sessionStorage.removeItem('currentUser');
		await auth.signoutRedirect({ id_token_hint: auth.user?.id_token });
	};

	// TODO: translate
	return (
		<Page title={title}>
			<LoadingFallback title={title} description={description} />
		</Page>
	);
};

export default LogoutPage;

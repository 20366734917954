import { useTranslation } from 'react-i18next';

import Header from './Header';
import { useStyles } from './style';
import Summary from './Summary';
import { Page } from '../../components';
import { useComplexFilter } from '../../shared/hooks';

const Dashboard = (props) => {
	const { t } = useTranslation();

	const classes = useStyles();

	const dashboardOrganisationFilter = useComplexFilter(
		'dashboardOrganisationNameFilter',
		'dashboardOrganisationIdFilter',
	);
	const dashboardProps = { dashboardOrganisationFilter };

	return (
		<Page className={classes.root} title={t('nav.category.dashboard')}>
			<Header {...dashboardProps} />
			<Summary {...dashboardProps} />
		</Page>
	);
};

export default Dashboard;

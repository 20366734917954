import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Link,
	Table,
	TableBody,
	TableRow,
	TableCell,
} from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';

import { useStyles } from './style';
import { commaTimeStrings } from '../../../../../shared/datetime';
import { isUndefined } from '../../../../../shared/utility';

interface DeviceAllocationProps {
	className?: string;
	deviceData?: object;
	onFetchDeviceLocation?(...args: unknown[]): unknown;
	fetchDeviceLocation?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const DeviceAllocation = (props: DeviceAllocationProps) => {
	const { className, deviceData } = props;
	const { t } = useTranslation();
	const location = useLocation();

	const classes = useStyles();

	const handleLink = (label, path) => (
		<Link component={RouterLink} to={path} state={{ from: location.pathname }}>
			{label}
		</Link>
	);

	const allocation = [
		...(!isUndefined(deviceData.organisationReference) ?
			[{ label: 'ui.label.assignedTo', value: deviceData.organisationReference.name }]
		:	[]),
		{
			label: 'ui.label.dateAssigned',
			value: deviceData?.dateAssigned && commaTimeStrings(deviceData.dateAssigned),
		},
		...(deviceData?.itemReference ?
			[
				{
					label: 'ui.label.linkedItemGroup',
					value: handleLink(
						deviceData.itemReference.name,
						`/item-management/items/${deviceData.itemReference.id}/summary`,
					),
				},
			]
		:	[]),
		...(deviceData?.itemInstanceReference ?
			[
				{
					label: 'ui.label.linkedItem',
					value: handleLink(
						deviceData.itemInstanceReference.name,
						`/item-management/${deviceData.itemReference.id}/instance/${deviceData.itemInstanceReference.id}/summary`,
					),
				},
			]
		:	[]),
		...(deviceData?.dateLinked ?
			[
				{
					label: 'ui.label.dateLinked',
					value: deviceData?.dateLinked && commaTimeStrings(deviceData.dateLinked),
				},
			]
		:	[]),
	];

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader title={t('view.devices.details.deviceAllocation')} />
			<Divider />
			<CardContent className={classes.content}>
				<Table className={classes.table}>
					<TableBody>
						{allocation.map((device) => (
							<TableRow key={device.label}>
								<TableCell>{t(device.label)}</TableCell>
								<TableCell>{device?.value ? device.value : '-'}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	);
};

export default DeviceAllocation;

import { useTranslation } from 'react-i18next';

import { useStyles } from './style';
import { isFullString } from '../../../shared/utility';

interface OpenEmailProps {
	email?: string;
	subject?: string;
	body?: string;
}

const OpenEmail = (props: OpenEmailProps) => {
	const { email, subject, body } = props;
	const { t } = useTranslation();
	const classes = useStyles();
	const providedValue = `?subject=${encodeURIComponent(subject) || ''}`;
	const notProvided = `?subject=${encodeURIComponent(t('components.elements.openEmail.subject')) || ''}`;
	const providedBody = `&body=${encodeURIComponent(body) || ''}`;
	const notProvidedBody = `&body=${encodeURIComponent(t('components.elements.openEmail.body')) || ''}`;
	return (
		<a
			className={classes.styledLink}
			href={`mailto:${email}${isFullString(subject) ? providedValue : notProvided}${isFullString(body) ? providedBody : notProvidedBody}`}
		>
			{email}
		</a>
	);
};

export default OpenEmail;

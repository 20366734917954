import React, { ReactNode, useMemo, useState } from 'react';

import { Error as ErrorIcon, MoreVert as MoreVertIcon } from '@mui/icons-material';
import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardContentProps,
	CardHeader,
	CardHeaderProps,
	CardProps,
	Divider,
	Grid2,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	MenuItemProps,
	Skeleton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Typography,
	useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '~components';
import i18n from '~lib/i18n';

type InfoCardProps = {
	title: string;
	/**
	 * TODO: better naming. An additional component next to the actions
	 * E.g. for a status
	 */
	statusComponent?: JSX.Element;
	/**
	 * Define a list of actions connected to this card. We want to achieve
	 * the same functionality as the actions in the mui datagrid. Therefore
	 * this interface.
	 * TODO: determine if we want to remove this dependency
	 */
	actions?: ({
		showInMenu?: boolean;
		label: string;
		icon: ReactNode;
	} & React.ComponentPropsWithRef)[];
	rows: InfoCardRowDef[];
	/**
	 * The amount of columns in the card
	 */
	columns?: number;
	loading?: boolean;
	/**
	 * Use the table layout, more in line with the classic
	 * way of Topology
	 */
	version?: 'legacy' | 'new';
	slots?: Partial<{
		contentHeader: JSX.Element;
		contentFooter: JSX.Element;
	}>;
	slotProps?: Partial<{
		cardHeader: CardHeaderProps;
		cardContent: CardContentProps;
	}>;
	error?: boolean;
	noResultsLabel?: string;
} & CardProps;

export interface InfoCardRowDef<T = any> {
	value: T;
	headerName: string;
	/**
	 * By default we render a string in the cell. Use a custom
	 * ReactNode component instead
	 * @param value
	 * @returns
	 */
	renderCell?: (value: T) => React.ReactNode;
	/**
	 * Convert a value before displaying it.
	 * @param value
	 * @returns
	 */
	valueFormatter?: (value: T) => string;
	/**
	 * Determine how much columns this rows spans
	 */
	columns?: number;
	/**
	 * Hide the row
	 */
	hidden?: boolean;
}

/**
 * A card for information used throughout the application
 * Inspiration taken from the mui datagrid https://mui.com/x/react-data-grid/
 * @returns
 */
const InfoCard = ({
	title,
	rows,
	columns = 2,
	statusComponent,
	actions,
	loading = false,
	error,
	slots,
	slotProps,
	noResultsLabel = i18n.t('noResults'),
	version = 'legacy',
	...cardProps
}: InfoCardProps) => {
	const theme = useTheme();

	const visibleRows = useMemo(() => rows.filter(el => el.hidden !== true), [rows])

	const [anchorActionsMenu, setAnchorActionsMenu] = useState<HTMLElement | null>(null);

	const handleActionsMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorActionsMenu(event.currentTarget);
	};

	const handleActionsMenuClose = () => {
		setAnchorActionsMenu(null);
	};

	/**
	 * The "classic" Topology layout
	 * @returns
	 */
	const TableLayout = () => (
		<Table>
			<TableBody>
				{visibleRows.map((row, i) => (
					<TableRow key={i}>
						<TableCell sx={{ color: version === 'new' ? 'gray' : undefined, fontSize: 12 }}>
							{row.headerName}
						</TableCell>
						<TableCell sx={{ fontSize: 12 }}>
							{row.renderCell ?
								row.renderCell(row.value)
							: row.valueFormatter ?
								row.valueFormatter(row.value)
							:	(row.value ?? '-')}
						</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	);

	/**
	 * A new layout with respect to the classic Topology one
	 * @returns 
	 */
	const GridListLayout = () => (
		<Grid2 container>
			{visibleRows.map((row, i) => (
				<Grid2 key={i} size={12 / (row.columns ?? columns)} sx={{ py: 1 }}>
					<Box fontFamily={theme.typography.fontFamily} fontSize={'12px'}>
						<Typography variant='body2'>{row.headerName}</Typography>
						{row.renderCell ?
							row.renderCell(row.value)
						: row.valueFormatter ?
							row.valueFormatter(row.value)
						:	<Typography fontSize='inherit'>{row.value ?? '-'}</Typography>}
					</Box>
				</Grid2>
			))}
		</Grid2>
	);

	return (
		<Card {...cardProps} sx={{ height: 1, display: 'flex', flexDirection: 'column' }}>
			<CardHeader
				title={title}
				{...slotProps?.cardHeader}
				action={
					// The 4px is correcting for the action negative margin
					<Stack sx={{ height: 1, marginTop: '4px' }}>
						<Box sx={{ display: 'flex', height: 1, alignItems: 'center' }}>
							{statusComponent}
						</Box>
						{version === 'new' &&
							actions
								?.filter((el) => !el.showInMenu)
								.map((el, i) => (
									<Tooltip key={i} title={el.label}>
										<IconButton {...el}>{el.icon}</IconButton>
									</Tooltip>
								))}
						{actions?.find((el) => el.showInMenu) && (
							<IconButton onClick={handleActionsMenuClick}>
								<MoreVertIcon />
							</IconButton>
						)}
					</Stack>
				}
				slotProps={{
					action: {
						sx: {
							height: 1
						}
					}
				}}
				sx={{ height: '52px', py: 1 }}
			/>
			<Divider />
			<CardContent {...slotProps?.cardContent} sx={{ pt: '12px', flexGrow: 1 }}>
				{loading ?
					<LoadingOverlay />
				: error ?
					<ErrorOverlay />
				: visibleRows.length <= 0 ?
					<NoContentOverlay label={noResultsLabel} />
				:	<>
						{slots?.contentHeader}
						{version === 'legacy' ?
							<TableLayout />
						:	<GridListLayout />}
						{slots?.contentFooter}
					</>
				}
			</CardContent>
			<Menu
				anchorEl={anchorActionsMenu}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				open={Boolean(anchorActionsMenu)}
				onClick={handleActionsMenuClose}
				onClose={handleActionsMenuClose}
			>
				{actions
					?.filter((el) => el.showInMenu)
					.map(({ showInMenu, ...el }, i) => (
						<MenuItem {...el} key={i}>
							<ListItemIcon>{el.icon}</ListItemIcon>
							<ListItemText>{el.label}</ListItemText>
						</MenuItem>
					))}
			</Menu>
			{version === 'legacy' && (
				<CardActions>
					{actions?.map(({ showInMenu, ...el }, i) => (
						<Button {...el} key={i} startIcon={el.icon}>
							{el.label}
						</Button>
					))}
				</CardActions>
			)}
		</Card>
	);
};

const NoContentOverlay = ({ label }: { label: string }) => (
	<Box>
		<Typography variant='body2'>{label}</Typography>
	</Box>
);

const LoadingOverlay = () => (
	<Table>
		<TableBody>
			{[...Array(4).keys()].map((row) => (
				<TableRow key={row} sx={{ display: 'flex', flexGrow: 1 }}>
					<TableCell sx={{ flexGrow: 1 }}>
						<Skeleton />
					</TableCell>
					<TableCell sx={{ flexGrow: 1 }}>
						<Skeleton />
					</TableCell>
				</TableRow>
			))}
		</TableBody>
	</Table>
);

const ErrorOverlay = () => {
	const { t } = useTranslation();

	return (
		<Box display='flex' height={1} justifyContent='center' alignItems='center'>
			<Stack direction='row' alignItems='center' spacing={1}>
				<ErrorIcon color='error' />
				<Typography>{t('somethingWentWrong')}</Typography>
			</Stack>
		</Box>
	);
};

export default InfoCard;

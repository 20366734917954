import clsx from 'clsx';

import { useStyles } from './style';

const baseUrl = 'https://maps.googleapis.com/maps/api/staticmap';

interface StaticMapProps {
	className?: string;
	isImage?: boolean;
	lat: number;
	lng: number;
}

const StaticMap = (props: StaticMapProps) => {
	const { lat, lng, className, isImage } = props;
	const classes = useStyles();
	const markerColor = '304ffe';

	if (!isImage) {
		return null;
	}

	return (
		<div className={clsx(classes.root, className)}>
			<img
				alt={''}
				className={classes.map}
				src={`${baseUrl}?center=${lat},${lng}&zoom=15&size=600x259&style=feature:poi%7Cvisibility:off&maptype=roadmap&markers=size:small%7Ccolor:0x${markerColor}%7C${lat},${lng}&key=${import.meta.env.VITE_GOOGLE_MAPS_LICENSE_KEY}`}
			/>
		</div>
	);
};

export default StaticMap;

import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import { Typography, Hidden } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useStyles } from './style';
import { AdjustedSkeleton, StyledButton } from '../../../../../components';
import { useDetailPageBackButton } from '../../../../../shared/hooks';

interface HeaderProps {
	className?: string;
	hubData?: object;
	loading?: boolean;
}

const Header = (props: HeaderProps) => {
	const { className, hubData, loading } = props;
	const location = useLocation();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const classes = useStyles();

	const returnButton = useDetailPageBackButton(location);

	const handleBackButtonChange = () => navigate('/location-management/locations');

	return (
		<div className={clsx(classes.root, className)}>
			<Typography component='h2' gutterBottom variant='overline'>
				<Hidden only={['xs', 'sm']}>
					<StyledButton
						className={classes.backButton}
						onClick={handleBackButtonChange}
						startIcon={<KeyboardArrowLeftOutlinedIcon />}
						variant='inline-default'
					>
						{returnButton.isBackButton ? t('ui.back') : t('ui.category.locations')}
					</StyledButton>
				</Hidden>
			</Typography>
			<Typography component='h1' variant='h3'>
				{loading ?
					<AdjustedSkeleton animation='wave' width={200} />
				:	hubData.name}
			</Typography>
		</div>
	);
};

export default Header;

import BillingInfo from './BillingInfo';
import ContactInfo from './ContactInfo';
import Header from './Header';
import OrganisationInfo from './OrganisationInfo';
import { useStyles } from './style';

interface OrganisationDetailsProps {
	save?(...args: unknown[]): unknown;
	name?: string;
	legalName?: string;
	description?: string;
	images?: unknown[];
	organisationEmail?: string;
	organisationPhoneNumber?: string;
	address?: object;
	vatNumber?: string;
	enterpriseNumber?: string;
	chamberOfCommerceNumber?: string;
	ibanNumber?: string;
}

const OrganisationDetails = (props: OrganisationDetailsProps) => {
	const classes = useStyles();

	const {
		save,
		name,
		legalName,
		description,
		images,
		bannerImages,
		organisationEmail,
		organisationPhoneNumber,
		address,
		vatNumber,
		enterpriseNumber,
		chamberOfCommerceNumber,
		ibanNumber,
	} = props;

	const organisationInfoProps = {
		name,
		legalName,
		description,
		images,
		bannerImages,
	};

	const contactInfoProps = {
		organisationEmail,
		organisationPhoneNumber,
		address,
	};

	const billingInfoProps = {
		vatNumber,
		enterpriseNumber,
		chamberOfCommerceNumber,
		ibanNumber,
		country: address.country,
	};

	return (
		<>
			<Header />
			<OrganisationInfo className={classes.wizardSection} save={save} {...organisationInfoProps} />
			<ContactInfo className={classes.wizardSection} save={save} {...contactInfoProps} />
			<BillingInfo className={classes.wizardSection} save={save} {...billingInfoProps} />
		</>
	);
};

export default OrganisationDetails;

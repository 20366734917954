import { useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import {
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
} from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { EditDevice } from './EditDevice';
import { useStyles } from './style';
import { StyledButton } from '../../../../../components';
import { handleLockType, handleDeviceTypes } from '../../../../../shared/utility';

interface DeviceInfoProps {
	className?: string;
	deviceData?: object;
}

const DeviceInfo = (props: DeviceInfoProps) => {
	const { className, deviceData } = props;
	const { t } = useTranslation();

	const classes = useStyles();

	const [openEditDevice, setOpenEditDevice] = useState(false);

	const handleOpenEditDevice = () => setOpenEditDevice(true);

	const handleCloseEditDevice = () => setOpenEditDevice(false);

	const info = [
		{ label: 'ui.dateCreated', value: '' },
		{ label: 'ui.label.HwUID', value: deviceData.deviceId },
		{ label: 'ui.label.deviceName', value: deviceData.deviceName },
		{ label: 'ui.label.deviceType', value: handleLockType(deviceData.deviceType) },
		{ label: 'ui.label.hwVersion', value: deviceData.hardwareVersion },
		{
			label: 'ui.label.communication',
			value: deviceData.communicationType.map((type) => handleDeviceTypes(type)).join(', '),
		},
		{
			label: 'views.organisationManagement.devices.unlockType',
			value: deviceData.identificationType.map((item) => handleDeviceTypes(item)).join(', '),
		},
	];

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader title={t('ui.label.deviceInfo')} />
			<Divider />
			<CardContent className={classes.content}>
				<Table className={classes.table}>
					<TableBody>
						{info.map((device) => (
							<TableRow key={device.label}>
								<TableCell>{t(device.label)}</TableCell>
								<TableCell>{device.value}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</CardContent>
			<CardActions className={classes.actions}>
				<StyledButton
					onClick={handleOpenEditDevice}
					startIcon={<EditIcon />}
					variant='inline-default'
				>
					{t('ui.button.inline.edit')}
				</StyledButton>
			</CardActions>
			<EditDevice
				open={openEditDevice}
				onClose={handleCloseEditDevice}
				name={deviceData.deviceName}
				deviceId={deviceData.deviceId}
			/>
		</Card>
	);
};

export default DeviceInfo;

import { ReactNode } from 'react';

import {
	Tooltip as MuiTooltip,
	TooltipProps as MuiTooltipProps,
	Stack,
	Typography,
	useTheme,
} from '@mui/material';

const boxShadow = '0 1px 24px 0 rgba(0, 0, 0, 0.15)';

interface TooltipProps extends MuiTooltipProps {
	/**
	 * Add a header
	 */
	header?: ReactNode;
}

/**
 * A wrapper for the Tooltip to apply some custom
 * configurations
 */
const Tooltip = ({
	placement = 'top',
	arrow = true,
	title,
	header,
	...tooltipProps
}: TooltipProps) => {
	const theme = useTheme();

	return (
		<MuiTooltip
			{...tooltipProps}
			title={
				header != null ?
					<Stack spacing={0.5}>
						{typeof header === 'string' ?
							<Typography fontWeight={700}>{header}</Typography>
						:	header}
						{typeof title === 'string' ?
							<Typography variant='body1'>{title}</Typography>
						:	title}
					</Stack>
				: typeof title === 'string' ?
					<Typography variant='body1'>{title}</Typography>
				:	title
			}
			arrow={arrow}
			placement={placement}
			slotProps={{
				arrow: {
					sx: {
						color: theme.palette.common.white,
						boxShadow: boxShadow,
					},
				},
				tooltip: {
					sx: {
						p: 1,
						color: theme.palette.common.black,
						bgcolor: theme.palette.common.white,
						boxShadow: boxShadow,
					},
				},
			}}
		/>
	);
};

export default Tooltip;

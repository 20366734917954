import { Link as LinkIcon, LinkOff as LinkOffIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { InfoCard, InfoCardRowDef } from '~components';
import { useAuthorize } from '~features/authentication';
import { datetimeWithoutSecondsFormatter } from '~utils/dateUtils';

import NfcTagsService from '../../services/nfcTagsService';

const service = new NfcTagsService();

interface NfcGeneralInfoCardProps {
	id?: string;
	organisationId?: string;
	onLinkClick?: () => void;
	onUnlinkClick?: (nfcId: string) => void;
}

const NfcGeneralInfoCard = ({
	id,
	organisationId,
	onLinkClick,
	onUnlinkClick,
}: NfcGeneralInfoCardProps) => {
	const { t } = useTranslation();
	const { authorizeForOrganisation } = useAuthorize();

	const { data, isLoading, error } = useSWR(id != null && [service.basePath, id], ([_, id]) =>
		service.getNfcTagById(id)
	);

	const rows: InfoCardRowDef[] | undefined = data && [
		{
			headerName: t('views.userDetails.summary.nfcCardNumber'),
			value: data.tagNumber,
		},
		{
			headerName: t('views.userDetails.summary.nfcid'),
			value: data.id,
		},
		{
			headerName: t('views.userDetails.summary.nfcCardLinked'),
			value: data.dateLinked,
			valueFormatter: (value: Date) => datetimeWithoutSecondsFormatter.format(value),
		},
	];

	const isLinked = data?.user != null;

	return (
		<InfoCard
			title={t('ui.label.nfcTags')}
			rows={rows ?? []}
			actions={
				authorizeForOrganisation(data?.organisation?.id ?? organisationId) ?
					[
						...(isLinked ?
							[
								{
									label: t('unlink'),
									icon: <LinkOffIcon />,
									showInMenu: false,
									onClick: () => id && onUnlinkClick?.(id),
								},
							]
						:	[
								{
									label: t('link'),
									icon: <LinkIcon />,
									showInMenu: false,
									onClick: () => onLinkClick?.(),
								},
							]),
					]
				:	undefined
			}
			error={error != null}
			loading={isLoading}
			noResultsLabel={t('noNfcLinked')}
		/>
	);
};

export default NfcGeneralInfoCard;

import { useEffect, useState } from 'react';

import { Box, Link } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { userInfoAtom } from '~atoms';

import { useStyles } from './style';
import { Table, Label } from '../../../../components';
import { isObject, decimalAmount, getFinanceTableHeaders } from '../../../../shared/utility';
import * as actions from '../../../../store/actions';

interface ActiveFinanceProps {
	financeType?: string;
	date?: string;
	onFetchCurrentRevenue?(...args: unknown[]): unknown;
	isSuperAdmin?: boolean;
	currentRevenue?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const ActiveFinance = (props: ActiveFinanceProps) => {
	const { onFetchCurrentRevenue, currentRevenue, isSuperAdmin } = props;
	const { t } = useTranslation();
	const userInfo = useAtomValue(userInfoAtom);

	const classes = useStyles();

	const [shouldDoInitialFetch, setShouldDoInitialFetch] = useState(true);

	const { data: currentRevenueData, loading: currentRevenueLoading } = currentRevenue;

	useEffect(() => {
		if (shouldDoInitialFetch && !currentRevenueLoading) {
			onFetchCurrentRevenue();
			setShouldDoInitialFetch(false);
		}
	}, [currentRevenueLoading]);

	const tableHeader = getFinanceTableHeaders();

	const statusColor = { open: 'default', paid: 'success' };

	const statusText = { open: t('ui.open'), paid: t('ui.label.paid') };

	const handlePathName = () => {
		const path = `/financeManagement/revenue/${currentRevenueData.start}/${currentRevenueData.title}/`;
		if (isSuperAdmin) {
			return `${path}organisations/revenue`;
		} else {
			return `${path}partner/${userInfo.organisation.id}/${userInfo.organisation.label}`;
		}
	};

	const tableBody =
		isObject(currentRevenueData) ?
			[
				[
					{
						content: (
							<Box className={classes.cell}>
								<Link
									className={classes.link}
									color='inherit'
									component={RouterLink}
									to={handlePathName()}
									variant='h6'
								>
									{currentRevenueData.title}
								</Link>
							</Box>
						),
					},
					{
						content: decimalAmount(currentRevenueData.amount.subtotal, currentRevenueData.amount),
					},
					{
						content: decimalAmount(currentRevenueData.amount.discount, currentRevenueData.amount),
					},
					{
						content: decimalAmount(
							currentRevenueData.amount.subtotal - currentRevenueData.amount.discount,
							currentRevenueData.amount,
						),
					},
					{
						content: decimalAmount(currentRevenueData.amount.priceVat, currentRevenueData.amount),
					},
					{ content: decimalAmount(currentRevenueData.amount.refund, currentRevenueData.amount) },
					{
						content: decimalAmount(currentRevenueData.amount.totalPrice, currentRevenueData.amount),
					},
					{ content: decimalAmount(currentRevenueData.open, currentRevenueData.amount) },
					{
						content: (
							<Box className={classes.status}>
								<Label className={classes.status} type={statusColor[currentRevenueData.status]}>
									{statusText[currentRevenueData.status]}
								</Label>
							</Box>
						),
					},
				],
			]
		:	Array(2)
				.fill(Array(tableHeader.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })));

	return (
		<div className={classes.root}>
			<Table
				body={tableBody}
				cellStyle={classes.cellStyle}
				header={tableHeader}
				isNotPaginate={true}
				loading={currentRevenueLoading}
				title={t('ui.label.active')}
				withoutPages={true}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		currentRevenue: state.details.currentRevenue,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchCurrentRevenue: () => dispatch(actions.fetchCurrentRevenue()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveFinance);

import { Edit as EditIcon } from '@mui/icons-material';
import countries from 'i18n-iso-countries';
import { useTranslation } from 'react-i18next';

import { InfoCard, InfoCardRowDef } from '~components';
import { Address } from '~interfaces';
import { toStreetString } from '~utils/addressUtils';

interface AddressCardProps {
	isLoading?: boolean;
	enableEdit?: boolean;
	value?: Address;
	onEdit?: () => void;
}

/**
 * A card to show organisation basic information
 * Notice: the imperative handle should be temporary, just to refresh the data
 * within this component from outside
 * @returns
 */
const AddressCard = ({
	value: addressValue,
	isLoading = false,
	enableEdit = false,
	...props
}: AddressCardProps) => {
	const { i18n, t } = useTranslation();

	const rows: InfoCardRowDef[] | undefined =
	addressValue != null ?
			[
				{
					headerName: t('ui.label.address'),
					value: addressValue,
					valueFormatter: (value?: Address) => (value ? toStreetString(value) : '-'),
				},
				{
					headerName: t('ui.label.postalCode'),
					value: addressValue.postalCode,
					valueFormatter: (value: string) => value ?? '-',
				},
				{
					headerName: t('ui.label.city'),
					value: addressValue.city,
					valueFormatter: (value: string) => value ?? '-',
				},
				{
					headerName: t('ui.label.country'),
					value:
						addressValue.countryCode ? countries.getName(addressValue.countryCode, i18n.resolvedLanguage, { select: 'alias' }) : '-',
				},
			]
		:	[];

	return (
		<InfoCard
			title={t('ui.label.address')}
			rows={rows}
			loading={isLoading}
			version='legacy'
			noResultsLabel={t('noAddress')}
			actions={
				enableEdit ?
					[
						{
							label: t('ui.edit'),
							icon: <EditIcon />,
							onClick: () => props.onEdit?.(),
						},
					]
				:	undefined
			}
		/>
	);
};

export default AddressCard;

import { useEffect, useMemo, useRef } from 'react';

import {
	Box,
	CircularProgress,
	Switch as MuiSwitch,
	SwitchProps as MuiSwitchProps,
} from '@mui/material';

interface LoadingSwitchProps extends MuiSwitchProps {
	loading?: boolean;
}

/**
 * A switch with an loading stat
 * @param param0
 * @returns
 */
const LoadingSwitch = ({ loading = false, ...props }: LoadingSwitchProps) => {
	if (loading) {
		return (
			<Box
				sx={{
					// width: size.current?.width,
					height: props.size === 'medium' ? 38 : 24,
					display: 'flex',
					alignItems: 'center',
					// justifyContent: 'center',
				}}
			>
				<CircularProgress color='inherit' disableShrink size={props.size === 'medium' ? 19 : 15} />
			</Box>
		);
	}

	return <MuiSwitch {...props} />;
};

export default LoadingSwitch;

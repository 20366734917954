import { useEffect, useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import { Link, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { Tooltip } from '~components';
import { useAuthorize } from '~features/authentication';

import { useStyles } from './style';
import TripEdit from './TripEdit';
import VerificationIcon from '../../../../assets/icons/label-filled-icon-success.svg';
import WarningIcon from '../../../../assets/icons/label-filled-icon-warning@3x.png';
import { Table, SearchBar, EmptyTable, GenericMoreButton } from '../../../../components';
import { exportType } from '../../../../constantsOld';
import { commaTimeStrings } from '../../../../shared/datetime';
import { useExport } from '../../../../shared/downloadHooks';
import { useSearchComponent, usePagination } from '../../../../shared/hooks';
import {
	isObject,
	isUndefined,
	isFullArray,
	isEmptyArray,
	isEmptyString,
	isFullString,
	isNull,
} from '../../../../shared/utility';
import * as actions from '../../../../store/actions';

interface TripsListProps {
	isInstanceTrip?: boolean;
	onDeviceTrips?(...args: unknown[]): unknown;
	onItemInstanceTrips?(...args: unknown[]): unknown;
	id?: string;
	onBookingTrips?(...args: unknown[]): unknown;
	onExportBookingTrip?(...args: unknown[]): unknown;
	onExportInstanceTrip?(...args: unknown[]): unknown;
	onExportDeviceTrip?(...args: unknown[]): unknown;
	onFetchUsers?(...args: unknown[]): unknown;
	onPatchTrip?(...args: unknown[]): unknown;
	onFetchUser?(...args: unknown[]): unknown;
	isBookingTrip?: boolean;
	deviceTrips?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	itemInstanceTrips?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	bookingTrips?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	exportDeviceTrip?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	exportInstanceTrip?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	exportBookingTrip?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	usersList?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const TripsList = (props: TripsListProps) => {
	const {
		onDeviceTrips,
		deviceTrips,

		id,
		isInstanceTrip,
		onItemInstanceTrips,
		itemInstanceTrips,
		isBookingTrip,
		onBookingTrips,
		bookingTrips,
		exportBookingTrip,
		exportInstanceTrip,
		exportDeviceTrip,
		onExportBookingTrip,
		onExportInstanceTrip,
		onExportDeviceTrip,
		onFetchUser,
	} = props;
	const { t } = useTranslation();
	const location = useLocation();
	const { isSuperAdmin } = useAuthorize();

	const handleTrips = () => {
		if (isInstanceTrip) {
			return itemInstanceTrips;
		}
		if (isBookingTrip) {
			return bookingTrips;
		}
		if (!(isInstanceTrip && isBookingTrip)) {
			return deviceTrips;
		}
	};

	const handleExportData = () => {
		if (isInstanceTrip) {
			return exportInstanceTrip;
		}
		if (isBookingTrip) {
			return exportBookingTrip;
		}
		if (!(isInstanceTrip && isBookingTrip)) {
			return exportDeviceTrip;
		}
	};

	const pathname =
		isInstanceTrip ? 'item-management'
		: isBookingTrip ? 'booking'
		: 'devices';

	const { loading: exportDataLoading } = handleExportData();

	const exportTrips = useExport({
		label: t('views.devices.trip.table.title.tripDetails'),
		exportData: handleExportData(),
	});

	const { data: tripsData, loading: tripsLoading } = handleTrips();

	const classes = useStyles();

	const isPageEmpty =
		isObject(tripsData) && (isEmptyArray(tripsData.results) || isUndefined(tripsData.results));
	const isPageFull = isObject(tripsData) && isFullArray(tripsData.results);

	const [openDialog, setOpenDialog] = useState(false);
	const [tripEditData, setTripEditData] = useState(null);

	const [loading, setLoading] = useState(true);

	const [order, setOrder] = useState('asc');
	const [sortByProperty, setSortByProperty] = useState('');
	const [orderDescending, setOrderDescending] = useState(true);

	const [shouldSort, setShouldSort] = useState(false);
	const [shouldSearch, setShouldSearch] = useState(false);

	const [dateTime, setDateTime] = useState({ startDate: null, endDate: null });

	const pagination = usePagination('pageNumberTrip', 'pageSizeTrip');

	const search = useSearchComponent(pagination.setPageNumber, setShouldSearch);

	const filters = {
		orderDescending,
		...(!isEmptyString(sortByProperty) && { sortBy: sortByProperty }),
		...(!isEmptyString(search.value) && { searchTerm: search.value }),
		...(dateTime?.startDate && { dateAfter: dateTime.startDate }),
		...(dateTime?.endDate && { dateBefore: dateTime.endDate }),
	};

	const editTripProps = {
		openDialog,
		setOpenDialog,
		tripEditData,
		setTripEditData,
		setShouldSort,
	};

	useEffect(() => {
		if (!tripsLoading && !isNull(tripsData)) {
			setLoading(false);
		} else {
			setLoading(true);
		}
		return () => {
			setLoading(true);
		};
	}, [tripsLoading]);

	useEffect(() => {
		if ((!tripsLoading && id && loading) || pagination.fetch || shouldSearch || shouldSort) {
			if (isInstanceTrip) {
				onItemInstanceTrips(id, pagination.page, filters);
			} else if (isBookingTrip) {
				onBookingTrips(id, pagination.page, filters);
			} else {
				onDeviceTrips(id, pagination.page, filters);
			}
		}
		if (shouldSearch) {
			setShouldSearch(false);
		} else if (shouldSort) {
			setShouldSort(false);
		} else if (pagination.fetch) {
			pagination.setFatch(false);
		}
	}, [id, pagination.fetch, loading, shouldSearch, order, dateTime, shouldSort]);

	const handleExport = (type) => {
		const filter = { ...filters, exportType: type };

		if (isBookingTrip) {
			onExportBookingTrip(id, filter);
		} else if (isInstanceTrip) {
			onExportInstanceTrip(id, filter);
		} else {
			onExportDeviceTrip(id, filter);
		}
		exportTrips.setExportType(type);
		exportTrips.setDownload(true);
	};

	const downloadData = {
		disabled: exportDataLoading,
		text: t('ui.label.export.trips'),
		action: () => [
			{
				icon: <InsertDriveFileOutlinedIcon />,
				text: t('ui.button.export.xlsx'),
				action: () => handleExport(exportType.XLSX),
			},
			{
				icon: <InsertDriveFileOutlinedIcon />,
				text: t('ui.button.export.csv'),
				action: () => handleExport(exportType.CSV),
			},
		],
	};

	const handleChangeStart = (event) => {
		const start = event.target.value;
		if (isFullString(start)) {
			setDateTime({ ...dateTime, startDate: new Date(start).toISOString() });
		} else {
			setDateTime({ ...dateTime, startDate: null });
		}
		setShouldSort(true);
	};

	const handleChangeEnd = (event) => {
		const end = event.target.value;
		if (isFullString(end)) {
			setDateTime({ ...dateTime, endDate: new Date(end).toISOString() });
		} else {
			setDateTime({ ...dateTime, endDate: null });
		}
		setShouldSort(true);
	};

	const handleClearFilters = () => {
		search.events.onClear();
		pagination.resetPagination();
	};

	const clearFilters = {
		clear: pagination.pageNumber !== 1 || pagination.pageSize !== 10,
		btnText: 'ui.button.inline.clearfilters',
		action: handleClearFilters,
	};

	const handleRequestSort = (property) => {
		const isDesc = sortByProperty === property && order === 'desc';
		setOrder(isDesc ? 'asc' : 'desc');
		setOrderDescending(!isDesc);
		setSortByProperty(property);
		setShouldSort(true);
	};

	const timePickerData = {
		onChangeStart: handleChangeStart,
		onChangeEnd: handleChangeEnd,
	};

	const editTripUser = (trip) => {
		setOpenDialog(true);
		setTripEditData(trip);
		if (trip?.userId) {
			onFetchUser(trip.userId);
		}
	};

	const createMenuItems = (data) => [
		{
			icon: <EditIcon />,
			text: t('ui.button.inline.edit'),
			action: () => editTripUser(data),
		},
	];

	const tripEditStatus = {
		pending: <PendingOutlinedIcon fontSize='small' />,
		accepted: (
			<img
				alt='VerificationIcon'
				className={classes.icon}
				height='16'
				src={VerificationIcon}
				width='16'
			/>
		),
		rejected: (
			<img alt='warning-icon' className={classes.icon} height='16' src={WarningIcon} width='16' />
		),
		none: '',
	};

	const handleTripsIcons = (trip) =>
		trip.requestState !== 'none' ?
			<Tooltip title={t(`views.trip.icon.${trip.requestState}`)}>
				{tripEditStatus[trip.requestState]}
			</Tooltip>
		:	<Box></Box>;

	const handleLinks = (path, trip) => (
		<Link
			className={classes.itemGroup}
			color='primary'
			component={RouterLink}
			to={path}
			state={{ from: location.pathname, backPathname: location.state?.from }}
		>
			{trip.sequenceNumber}
		</Link>
	);

	const handleToolTip = (trip) => {
		const currentValue = trip.distancePercentage;
		const businessPercent = currentValue;
		const privatePercent = 100.0 - businessPercent;
		const getUpdatedDistance = (distance, percent) => distance * (percent / 100);
		const privateDistance = getUpdatedDistance(trip?.distance, privatePercent);
		const businessDistance = getUpdatedDistance(trip?.distance, businessPercent);

		return (
			<Tooltip
				title={
					<>
						<Typography>
							{`${t('views.devices.trip.table.private')} - ${privateDistance.toFixed(2)}km`}
						</Typography>
						<Typography>
							{`${t(trip.usageType === 'commute' ? 'ui.label.distanceCommute' : 'views.devices.trip.table.business')} - ${businessDistance.toFixed(2)}km`}
						</Typography>
					</>
				}
			>
				<Typography className={classes.detailTitle}>
					{t(`ui.label.${trip.usageType}.${trip.isMixed}`)}
				</Typography>
			</Tooltip>
		);
	};

	const handleCo2Trip = (co2) => {
		if (co2 > 0) {
			// Convert grams to kilograms and round to 2 decimal places
			return `${(Math.round(co2) / 1000).toFixed(2)}kg`;
		} else if (co2 === 0) {
			// If CO2 is exactly 0
			return '0 kg';
		} else {
			// If CO2 is less than 0 or not a valid number
			return '';
		}
	};

	const tableHeaders =
		!loading && isPageEmpty ?
			[]
		:	[
				{ name: 'id', content: 'ID', hasSorting: true },
				{ name: 'startTime', content: t('ui.label.startTime') },
				{ name: 'endTime', content: t('ui.label.endTime') },
				{ name: 'locationStart', content: t('ui.label.locationStart') },
				{ name: 'locationEnd', content: t('ui.label.locationEnd') },
				{ name: 'carDistance', content: t('ui.label.carDistance') },
				{ name: 'privateDistance', content: t('ui.label.privateDistance') },
				{ name: 'co2', content: t('ui.header.co2') },
				{ name: 'maxSpeed', content: t('ui.label.maxSpeed') },
				{ name: 'avgSpeed', content: t('ui.label.avgSpeed') },
				{ name: 'instanceName', content: t('views.ItemDetail.activities.instance') },
				{ name: 'userName', content: t('ui.label.user'), hasSorting: true },
				{ name: 'booking', content: t('ui.label.booking') },
				{ name: 'usage', content: t('views.planboard.addBooking.usage.sectionTitle') },
				{ name: '', content: '' },
			];

	const loadingBody =
		loading ?
			Array(3)
				.fill(Array(tableHeaders?.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })))
		:	null;

	const emptyBody =
		!loading && isPageEmpty ?
			[[{ content: <EmptyTable label='views.devices.trips.emptyResults' /> }]]
		:	null;

	const tableBody =
		!loading && isPageFull ?
			tripsData.results.map((trip) => [
				{ content: handleLinks(`/${pathname}/${id}/trips/${trip.id}/summary`, trip) },
				{ content: commaTimeStrings(trip.start) },
				{ content: trip.end != null && commaTimeStrings(trip.end) },
				{ content: trip?.startAddress },
				{ content: trip?.endAddress },
				{ content: trip?.distance != null && `${trip.distance}km` },
				{ content: trip?.personalDistance && `${trip.personalDistance}km` },
				// Round up or down to 2 decimals
				{ content: handleCo2Trip(trip?.co2) },
				{ content: trip.maxSpeed != null && `${trip.maxSpeed}km/h` },
				{ content: trip.avgSpeed != null && `${trip.avgSpeed}km/h` },
				{ content: trip.itemInstanceName },
				{ content: trip?.userFullName },
				{ content: trip?.bookingId },
				{ content: trip?.usageType && handleToolTip(trip) },
				{
					content: (
						<Box display='flex' justifyContent='space-between'>
							{handleTripsIcons(trip)} <GenericMoreButton menuItems={createMenuItems(trip)} />
						</Box>
					),
				},
			])
		:	[];

	return (
		<>
			<SearchBar
				clearFilters={clearFilters}
				downloadData={isFullArray(tripsData?.results) ? downloadData : null}
				hasExtraButtons={true}
				placeholder={t('views.devices.heartbeatsList.searchHeartbeats')}
				searchEvents={search.events}
				searchValue={search.value}
				timePickerData={timePickerData}
			/>
			<Table
				body={loadingBody || emptyBody || tableBody}
				cellStyle={classes.cellStyle}
				data={isPageFull ? tripsData.results : []}
				handlePageChange={pagination.pageNumberChange}
				handleSorting={handleRequestSort}
				header={tableHeaders}
				isNotPaginate={loading || isPageEmpty || !isPageFull}
				loading={tripsLoading}
				order={order}
				orderBy={sortByProperty}
				page={pagination.pageNumber}
				rowsPerPage={pagination.pageSize}
				setRowsPerPage={pagination.pageSizeChange}
				title={t('views.devices.trips.table.title')}
				total={tripsData ? tripsData.total : 0}
			/>
			{openDialog ?
				<TripEdit {...editTripProps} />
			:	null}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		deviceTrips: state.paged.deviceTrips,
		itemInstanceTrips: state.paged.itemInstanceTrips,

		bookingTrips: state.paged.bookingTrips,
		exportDeviceTrip: state.details.exportDeviceTrip,
		exportInstanceTrip: state.details.exportInstanceTrip,
		exportBookingTrip: state.details.exportBookingTrip,
		usersList: state.paged.users,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onDeviceTrips: (deviceId, page, filters) =>
			dispatch(actions.deviceTrips(deviceId, page, filters)),
		onItemInstanceTrips: (instanceId, page, filters) =>
			dispatch(actions.itemInstanceTrips(instanceId, page, filters)),
		onBookingTrips: (bookingId, page, filters) =>
			dispatch(actions.bookingTrips(bookingId, page, filters)),
		onExportDeviceTrip: (deviceId, filters) =>
			dispatch(actions.exportDeviceTrip(deviceId, filters)),
		onExportInstanceTrip: (instanceId, filters) =>
			dispatch(actions.exportInstanceTrip(instanceId, filters)),
		onExportBookingTrip: (bookingId, filters) =>
			dispatch(actions.exportBookingTrip(bookingId, filters)),
		onFetchUser: (id) => dispatch(actions.fetchUser(id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TripsList);

import { useMemo } from 'react';

import {
	areaElementClasses,
	AreaPlot,
	lineElementClasses,
	LinePlot,
	markElementClasses,
	MarkPlot,
	ResponsiveChartContainer,
	useDrawingArea,
} from '@mui/x-charts';

interface HighLightedSparkLineChart {
	data: number[];
	max?: number;
}

/**
 * A linespark chart with hightlighted points.
 * E.g. used for the planboard
 * @param param0 
 * @returns 
 */
const HighLightedSparkLineChart = ({ data, ...props }: HighLightedSparkLineChart) => {
	const labels = useMemo(() => Array.from({ length: data.length }, (_, i) => i), [data]);

	return (
		<ResponsiveChartContainer
			series={[{ type: 'line', data: data, area: true }]}
			xAxis={[{ scaleType: 'linear', data: labels }]}
			yAxis={[
				{
					domainLimit: (min, max) => ({
						min: min,
						max: props.max ?? max,
					}),
				},
			]}
			disableAxisListener
			sx={{
				[`& .${lineElementClasses.root}`]: {
					stroke: 'black',
					strokeWidth: 1,
				},
				[`& .${markElementClasses.root}`]: {
					stroke: 'black',
					scale: 0.9,
					fill: 'white',
					strokeWidth: 1,
				},
				[`& .${areaElementClasses.root}`]: {
					fill: 'url(#sparklinegradient)',
				},
			}}
			margin={{
				top: 8,
				left: 0,
				bottom: 8,
				right: 0,
			}}
		>
			<AreaPlot />
			<LinePlot />
			<MarkPlot />
			<VerticalGradient
				id='sparklinegradient'
				color1='rgb(227, 227, 242)'
				// color1='rgba(216, 216, 244, 0.9)'
				color2='#ffffff'
			/>
		</ResponsiveChartContainer>
	);
};

const VerticalGradient = ({
	color1,
	color2,
	id,
}: {
	color1: string;
	color2: string;
	id: string;
}) => {
	const { top, height, bottom } = useDrawingArea();
	const svgHeight = top + bottom + height;

	return (
		<defs>
			<linearGradient
				id={id}
				x1='0'
				x2='0'
				y1='0'
				y2={`${svgHeight}px`}
				gradientUnits='userSpaceOnUse'
			>
				<stop offset='0%' stopColor={color1} />
				<stop offset='100%' stopColor={color2} />
			</linearGradient>
		</defs>
	);
};

export default HighLightedSparkLineChart;

import { UserRoleEnum } from '~enums';

/**
 * @param The role on which to authorize
 * @param roles The roles that have access. Based on a hierarchy
 * SuperAdmin > Admin > User
 * @param exclusive A boolean to set if the user must be exactly an admin
 * If false, it will check an admin or higher
 */
export const authorize = (role: UserRoleEnum, roles: UserRoleEnum[], exclusive = false) => {
	if (exclusive) {
		return roles.includes(role);
	}

	if (role === UserRoleEnum.SuperAdmin) {
		// Superadmin can do everything
		return true;
	} else if (roles.includes(UserRoleEnum.User) && role === UserRoleEnum.Admin) {
		return true;
	}

	return roles.includes(role);
};
import { useState, useEffect } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import { Typography, Link, Avatar, Box } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { getInitials } from '~utils/stringUtils';

import { useStyles } from './style';
import {
	SearchBar,
	EmptyState,
	AlertDialog,
	InfoDialog,
	Table,
	GenericMoreButton,
} from '../../../components';
import { useError, useSearchComponent, usePagination } from '../../../shared/hooks';
import { isEmptyString, isEmptyArray, isObject } from '../../../shared/utility';
import * as actions from '../../../store/actions';

interface OrganisationsProps {
	organisationsList?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	deleteOrganisation?: {
		success?: boolean;
		loading?: boolean;
		error?: object | string;
	};
	className?: string;
	//functions
	onFetchOrganisations(...args: unknown[]): unknown;
	onDeleteOrganisation(...args: unknown[]): unknown;
	onSearchOrganisations?(...args: unknown[]): unknown;
}

const Organisations = (props: OrganisationsProps) => {
	const classes = useStyles();
	const {
		className,
		onFetchOrganisations,
		onDeleteOrganisation,
		deleteOrganisation,

		organisationsList,
	} = props;
	const { t } = useTranslation();
	const location = useLocation();

	const [shouldDoInitialFetch, setShouldDoInitialFetch] = useState(true);
	const [showingInitialResults, setShowingInitialResults] = useState(true);
	const [organisationName, setOrganisationName] = useState(null);
	const [shouldSearch, setShouldSearch] = useState(false);
	const [filters] = useState('verified');

	const {
		data: organisationsData,
		loading: organisationsLoading,
		error: organisationsError,
	} = organisationsList;
	const organisationsReady =
		isObject(organisationsData) && !organisationsLoading && !organisationsError;

	const {
		success: deleteOrganisationSucces,
		loading: deleteOrganisationPending,
		error: deleteOrganisationError,
	} = deleteOrganisation;
	const deleteOrganisationDone =
		deleteOrganisationSucces && !deleteOrganisationPending && !deleteOrganisationError;

	const [organisationToDelete, setOrganisationToDelete] = useState(null);

	const [dialogOpen, setDialogOpen] = useState(false);

	const [infoDialogOpen, setInfoDialogOpen] = useState(false);

	const deleteError = useError(
		deleteOrganisation ?
			{
				value: deleteOrganisation,
				message: `${t('ui.successfullyDeleted')} ${organisationName}`,
			}
		:	null,
	);

	const [sortingCategory, setSortingCategory] = useState('name');
	const [order, setOrder] = useState('asc');
	const [orderDescending, setOrderDescending] = useState(false);
	const [shouldSort, setShouldSort] = useState(false);

	const pagination = usePagination('pageNumber', 'pageSize');

	const search = useSearchComponent(
		pagination.setPageNumber,
		setShouldSearch,
		'organisationSearch',
	);

	const organisationFilters = {
		...(!isEmptyString(search.value) && { searchTerm: search.value }),
		...(!isEmptyString(sortingCategory) && { sortBy: sortingCategory }),
		orderDescending,
		filters: filters,
	};

	useEffect(() => {
		if (
			!organisationsLoading &&
			(pagination.fetch || shouldSearch || shouldSort || shouldDoInitialFetch)
		) {
			if ((shouldSearch || shouldSort) && showingInitialResults) {
				setShowingInitialResults(false);
			}
			onFetchOrganisations(pagination.page, organisationFilters);
		}

		if (shouldSearch) {
			setShouldSearch(false);
		} else if (shouldSort) {
			setShouldSort(false);
		} else if (shouldDoInitialFetch) {
			setShouldDoInitialFetch(false);
		} else if (pagination.fetch) {
			pagination.setFatch(false);
		}
	}, [
		pagination.fetch,
		shouldSearch,
		shouldSort,
		shouldDoInitialFetch,
		order,
		sortingCategory,
		showingInitialResults,
	]);

	useEffect(() => {
		return () => {
			setShouldDoInitialFetch(true);
			setShowingInitialResults(true);
		};
	}, [setShouldDoInitialFetch, setShowingInitialResults]);

	/* * * * * *
	 * SORTING *
	 * * * * * */
	const handleRequestSort = (property) => {
		const isDesc = sortingCategory === property && order === 'desc';
		setOrder(isDesc ? 'asc' : 'desc');
		setOrderDescending(isDesc);
		setSortingCategory(property);
		setShouldSort(true);
	};

	/* * * * * * * * *
	 * TABLE ACTIONS *
	 * * * * * * * * */
	const handleClickDelete = (organisation) => {
		if (organisation.totalUsers > 0) {
			setInfoDialogOpen(true);
		} else {
			setOrganisationToDelete(organisation);
			setDialogOpen(true);
		}
		setOrganisationName(organisation.name);
	};

	const handleCloseDialog = () => (dialogOpen ? setDialogOpen(false) : setInfoDialogOpen(false));

	const handleConfirmDialog = () => {
		onDeleteOrganisation(organisationToDelete.id);
		deleteError.setStartAction(true);
		setDialogOpen(false);
	};

	const createMenuItems = (organisation) => [
		{
			icon: <DeleteIcon />,
			text: t('ui.button.inline.delete'),
			action: () => handleClickDelete(organisation),
			isRed: true,
		},
	];

	const handleClearFilters = () => {
		search.events.onClear();
		pagination.resetPagination();
	};

	const clearFilters = {
		clear: pagination.pageNumber !== 1 || pagination.pageSize !== 10,
		btnText: 'ui.button.inline.clearfilters',
		action: handleClearFilters,
	};

	const tableHeader = [
		{ name: 'logo', className: classes.iconCell },
		{ name: 'name', content: t('ui.label.name'), hasSorting: true },
		{ name: 'users', content: t('ui.label.users') },
		{ name: 'user-groups', content: t('ui.label.userGroups') },
		{ name: 'actions', content: t('ui.label.actions'), className: classes.actionCell },
	];

	const tableBody =
		organisationsReady ?
			organisationsData.results.map((item) => [
				{
					content:
						item.logo ?
							<Avatar className={classes.avatar} src={item.logo} />
						:	<Avatar>{getInitials(item.name)}</Avatar>,
					className: classes.iconCell,
				},
				{
					content: (
						<div>
							<Link
								className={classes.itemGroup}
								color='inherit'
								component={RouterLink}
								to={`/organisations/${item.id}/summary`}
								state={{ from: location.pathname }}
							>
								{item.name}
							</Link>
						</div>
					),
				},
				{ content: item.totalUsers },
				{ content: item.totalUserGroups },
				{
					content: (
						<Box display='flex' justifyContent='space-between'>
							<span>{''}</span>
							<GenericMoreButton menuItems={createMenuItems(item)} />
						</Box>
					),
					className: classes.actionCell,
				},
			])
		:	Array(4)
				.fill(Array(5).fill())
				.map((arr) => arr.map(() => ({ loading: true })));

	/* * * * * * * *
	 * EMPTY STATE *
	 * * * * * * * */
	const organisationsListEmpty =
		(
			isObject(organisationsData) &&
			!organisationsLoading &&
			isEmptyArray(organisationsData.results)
		) ?
			showingInitialResults ?
				<EmptyState
					image={'booking'}
					subTitle={t('views.usersAndOrganisations.organisations.empty.description.zeroInSystem')}
					title={t('views.usersAndOrganisations.organisations.empty.title')}
				/>
			:	<EmptyState
					image={'booking'}
					subTitle={t('views.usersAndOrganisations.organisations.empty.description.zeroMatching')}
					title={t('views.usersAndOrganisations.organisations.empty.title')}
				/>
		:	null;

	return (
		<div className={clsx(classes.root, className)}>
			<SearchBar
				clearFilters={clearFilters}
				placeholder={t('views.usersAndOrganisations.organisations.searchPlaceholder')}
				searchEvents={search.events}
				searchValue={search.value}
			/>
			{organisationsListEmpty ?
				organisationsListEmpty
			:	<Table
					body={tableBody}
					handlePageChange={pagination.pageNumberChange}
					handleSorting={handleRequestSort}
					header={tableHeader}
					loading={organisationsLoading}
					order={order}
					orderBy={sortingCategory}
					page={pagination.pageNumber}
					rowsPerPage={pagination.pageSize}
					setRowsPerPage={pagination.pageSizeChange}
					title={t('ui.category.organisations')}
					total={organisationsData ? organisationsData.total : 0}
				/>
			}
			{dialogOpen ?
				<AlertDialog
					dialogDescription={`${t('views.usersAndOrganisations.organisations.deleteAlert.description')} ${organisationToDelete ? organisationToDelete.name : '-'}?`}
					dialogTitle={t('views.usersAndOrganisations.organisations.deleteAlert.title')}
					handleClose={handleCloseDialog}
					handleConfirm={handleConfirmDialog}
					loading={deleteOrganisationPending && !deleteOrganisationDone}
					open={dialogOpen}
					// error={deleteOrganisationPending}
				/>
			:	null}
			{infoDialogOpen ?
				<InfoDialog
					dialogTitle={t('views.usersAndOrganisations.organisations.deleteNotPossibleInfo.title')}
					onClose={handleCloseDialog}
					open={infoDialogOpen}
				>
					<Typography>
						{t('views.usersAndOrganisations.organisations.deleteNotPossibleInfo.description')}
					</Typography>
				</InfoDialog>
			:	null}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		organisationsList: state.paged.organisations,
		deleteOrganisation: state.condition.deleteOrganisation,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchOrganisations: (page, filters) => dispatch(actions.fetchOrganisations(page, filters)),
		onDeleteOrganisation: (id) => dispatch(actions.deleteOrganisation(id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Organisations);

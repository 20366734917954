/**
 * Get the dimensions of a hypothecially rendered piece of text.
 * E.g. used when rendered text in a svg where text cannot be aligned with
 * respect to eachother.
 * @param label
 * @param fontFamily
 * @param fontSize
 * @returns
 */
export const getRenderedTextSize = (label: string, fontFamily: string, fontSize: string) => {
	const el = document.createElement('span');
	document.body.appendChild(el);

	el.style.fontFamily = fontFamily;
	el.style.fontSize = fontSize;
	el.innerHTML = label;

	const bbox = el.getBoundingClientRect();
	document.body.removeChild(el);

	return {
		width: bbox.width,
		height: bbox.height,
	};
};

/**
 * Function to format bytes to a representable byte size string
 * @param bytes 
 * @param decimals 
 * @param type The type to use https://en.wikipedia.org/wiki/Kilobyte
 * @returns 
 */
export const formatBytes = (bytes: number, decimals = 2, type: 'decimal' | 'binary' = 'decimal'): string => {
	if (bytes <= 0) {
		return '0B';
	}
	
	const k = type === 'binary' ? 1024 : 1000;
	const dm = decimals < 0 ? 0 : decimals;

	const i = Math.floor(Math.log(bytes) / Math.log(k));
	
	const sizesDecimal = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	const sizesBinary = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

	return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm)).toLocaleString()} ${type === 'binary' ? sizesBinary[i] : sizesDecimal[i]}` 
}

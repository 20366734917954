import { useState, useEffect } from 'react';

import {
	Card,
	CardHeader,
	CardContent,
	Collapse,
	Divider,
	FormControlLabel,
	Switch,
	TextField,
	Typography,
	Autocomplete,
	Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';

interface BookingConfigurationProps {
	className?: string;
	save(...args: unknown[]): unknown;
	requireHostConfirmation?: boolean;
	minimumBookingDuration?: string;
	minimumBookingDurationRequired?: boolean;
	maximumBookingDuration?: string;
	maximumBookingDurationRequired?: boolean;
	hasBufferPeriod?: boolean;
	daysBeforeUse?: number;
	hoursBeforeUse?: number;
	minBeforeUse?: number;
	daysAfterUse?: number;
	hoursAfterUse?: number;
	minAfterUse?: number;
	hasCancellationPeriod?: boolean;
	cancelDay?: number;
	cancelHour?: number;
	cancelMin?: number;
	allowExtending?: boolean;
	requiredLicenseStatus?: string;
	editing?: boolean;
	editPolicyData?: object;
	hasGracePeriod?: boolean;
	openHoursRestrictionType?: string;
	hasMinDuration?: boolean;
	minDurationDay?: any;
	minDurationHour?: any;
	minDurationMin?: any;
	maxDurationDay?: any;
	maxDurationHour?: any;
	maxDurationMin?: any;
	hasMaxDuration?: boolean;
	requireOvertimeValidation?: any;
	requiredParkingLicensePlateNumber?: any;
	daysGracePeriod?: any;
	hoursGracePeriod?: any;
	minGracePeriod?: any;
	requireDutchLicense?: boolean;
	hasMinDurationBeforeStart?: boolean;
	hasMaxDurationBeforeStart?: boolean;
	minDurationBeforeStartDay?: number;
	minDurationBeforeStartHour?: number;
	minDurationBeforeStartMin?: number;
	maxDurationBeforeStartDay?: number;
	maxDurationBeforeStartHour?: number;
	maxDurationBeforeStartMin?: number;
	minimumAge?: number;
}

const BookingConfiguration = (props: BookingConfigurationProps) => {
	const {
		save,
		openHoursRestrictionType,
		requireOvertimeValidation: savedRequireOvertimeValidation,
		requireHostConfirmation: savedRequireHostConfirmation,
		hasBufferPeriod,
		hasGracePeriod,
		hasCancellationPeriod,
		cancelDay,
		cancelHour,
		cancelMin,
		allowExtending: savedAllowExtending,
		requiredLicenseStatus,

		daysAfterUse,
		hoursAfterUse,
		minAfterUse,
		requiredParkingLicensePlateNumber,
		daysGracePeriod,
		hoursGracePeriod,
		minGracePeriod,
		hasMinDuration,
		minDurationDay,
		minDurationHour,
		minDurationMin,
		maxDurationDay,
		maxDurationHour,
		maxDurationMin,
		hasMaxDuration,
		requireDutchLicense: savedRequireDutchLicense,
		hasMinDurationBeforeStart,
		hasMaxDurationBeforeStart,
		minDurationBeforeStartDay,
		minDurationBeforeStartHour,
		minDurationBeforeStartMin,
		maxDurationBeforeStartDay,
		maxDurationBeforeStartHour,
		maxDurationBeforeStartMin,
		minimumAge: savedMinimumAge,
	} = props;
	const { t } = useTranslation();

	const classes = useStyles();

	// Host Confirmation
	const [requireHostConfirmation, setRequireHostConfirmation] = useState(
		savedRequireHostConfirmation || false,
	);

	const [requireOvertimeValidation, setRequireOvertimeValidation] = useState(
		savedRequireOvertimeValidation || false,
	);

	useEffect(() => {
		save({ updatedHostConfirmation: requireHostConfirmation });
	}, [requireHostConfirmation]);

	useEffect(() => {
		save({ updatedOvertimeValidation: requireOvertimeValidation });
	}, [requireOvertimeValidation]);

	// Duration
	const [openMinDuration, setOpenMinDuration] = useState(hasMinDuration);

	useEffect(() => {
		save({ updatedMinDuration: openMinDuration });
	}, [openMinDuration]);

	const [openMaxDuration, setOpenMaxDuration] = useState(hasMaxDuration);

	useEffect(() => {
		save({ updatedMaxDuration: openMaxDuration });
	}, [openMaxDuration]);

	const [openBufferPeriod, setOpenBufferPeriod] = useState(hasBufferPeriod);

	useEffect(() => {
		save({ hasBufferPeriod: openBufferPeriod });
	}, [openBufferPeriod]);

	// Booking Upfront
	const [openMinDurationBeforeStart, setOpenMinDurationBeforeStart] =
		useState(hasMinDurationBeforeStart);

	useEffect(() => {
		save({ updatedMinDurationBeforeStart: openMinDurationBeforeStart });
	}, [openMinDurationBeforeStart]);

	const [openMaxDurationBeforeStart, setOpenMaxDurationBeforeStart] =
		useState(hasMaxDurationBeforeStart);

	useEffect(() => {
		save({ updatedMaxDurationBeforeStart: openMaxDurationBeforeStart });
	}, [openMaxDurationBeforeStart]);

	const [openGracePeriod, setOpenGracePeriod] = useState(hasGracePeriod);

	useEffect(() => {
		save({ hasGracePeriod: openGracePeriod });
	}, [openGracePeriod]);

	const bookingHours = {
		NONE: 'none',
		CREATE: 'create',
		USAGE: 'usage',
	};

	const [openHoursRestriction, setOpenHoursRestriction] = useState(openHoursRestrictionType);
	const [openHoursCreate, setOpenHoursCreate] = useState(
		openHoursRestrictionType !== bookingHours.NONE,
	);
	const [openHoursUsage, setOpenHoursUsage] = useState(
		openHoursRestrictionType === bookingHours.USAGE,
	);

	useEffect(() => {
		if (openHoursCreate && openHoursUsage) {
			setOpenHoursRestriction(bookingHours.USAGE);
		} else if (openHoursCreate && !openHoursUsage) {
			setOpenHoursRestriction(bookingHours.CREATE);
		} else {
			setOpenHoursRestriction(bookingHours.NONE);
		}
		save({
			updatedOpenHoursRestrictionType: openHoursRestriction,
		});
	}, [openHoursRestriction, openHoursCreate, openHoursUsage]);

	const maxDaysAllowedBeforeStart = 62;
	const maxDaysAllowed = 7;
	const maxDaysAllowedBookingDuratio = 31;
	const hoursInDay = 24;
	const minutesInHours = 60;

	const days = Array.from(Array(maxDaysAllowed).keys()).map(String);
	const maxDaysBookingDuration = Array.from(Array(maxDaysAllowedBookingDuratio).keys()).map(String);
	const maxDaysSlotsBeforeStart = Array.from(Array(maxDaysAllowedBeforeStart).keys()).map(String);
	const hoursSlots = Array.from(Array(hoursInDay).keys()).map(String);

	const minSlot = ['0', '15', '30', '45'];

	const graceSlots = Array.from(Array(minutesInHours).keys()).map((x) => `${x}`);

	// Min Booking Duration
	const handleMinBookingDurationDay = (event, value) =>
		save({ updatedMinDurationDay: parseInt(value) });

	const handleMinBookingDurationHours = (event, value) =>
		save({ updatedMinDurationHour: parseInt(value) });

	const handleMinBookingDurationMin = (event, value) =>
		save({ updatedMinDurationMin: parseInt(value) });

	const minBookingDuration = [
		{
			label: 'days',
			name: t('ui.label.days'),
			value: minDurationDay,
			options: days,
			action: handleMinBookingDurationDay,
			className: classes.dayInputAfter,
			defaultValue: '0',
		},
		{
			label: 'hours',
			name: t('ui.label.hours'),
			value: minDurationHour,
			options: hoursSlots,
			action: handleMinBookingDurationHours,
			className: classes.hourInputAfter,
			defaultValue: '0',
		},
		{
			label: 'min',
			name: t('ui.label.min'),
			value: minDurationMin,
			options: graceSlots,
			action: handleMinBookingDurationMin,
			className: classes.minInputAfter,
			defaultValue: '00',
		},
	];

	// Max Booking Duration
	const handleMaxBookingDurationDay = (event, value) =>
		save({ updatedMaxDurationDay: parseInt(value) });

	const handleMaxBookingDurationHours = (event, value) =>
		save({ updatedMaxDurationHour: parseInt(value) });

	const handleMaxBookingDurationMin = (event, value) =>
		save({ updatedMaxDurationMin: parseInt(value) });

	const maxBookingDuration = [
		{
			label: 'days',
			name: t('ui.label.days'),
			value: maxDurationDay,
			options: maxDaysBookingDuration,
			action: handleMaxBookingDurationDay,
			className: classes.dayInputAfter,
			defaultValue: '0',
		},
		{
			label: 'hours',
			name: t('ui.label.hours'),
			value: maxDurationHour,
			options: hoursSlots,
			action: handleMaxBookingDurationHours,
			className: classes.hourInputAfter,
			defaultValue: '0',
		},
		{
			label: 'min',
			name: t('ui.label.min'),
			value: maxDurationMin,
			options: graceSlots,
			action: handleMaxBookingDurationMin,
			className: classes.minInputAfter,
			defaultValue: '00',
		},
	];

	// Min Duration Before Start
	const handleMinDurationBeforeStartDay = (event, value) =>
		save({ updatedMinDurationBeforeStartDay: parseInt(value) });

	const handleMinDurationBeforeStartHours = (event, value) =>
		save({ updatedMinDurationBeforeStartHour: parseInt(value) });

	const handleMinDurationBeforeStartMin = (event, value) =>
		save({ updatedMinDurationBeforeStartMin: parseInt(value) });

	const minDurationBeforeStart = [
		{
			label: 'days',
			name: t('ui.label.days'),
			value: minDurationBeforeStartDay,
			options: maxDaysSlotsBeforeStart,
			action: handleMinDurationBeforeStartDay,
			className: classes.dayInputAfter,
			defaultValue: '0',
		},
		{
			label: 'hours',
			name: t('ui.label.hours'),
			value: minDurationBeforeStartHour,
			options: hoursSlots,
			action: handleMinDurationBeforeStartHours,
			className: classes.hourInputAfter,
			defaultValue: '0',
		},
		{
			label: 'min',
			name: t('ui.label.min'),
			value: minDurationBeforeStartMin,
			options: graceSlots,
			action: handleMinDurationBeforeStartMin,
			className: classes.minInputAfter,
			defaultValue: '00',
		},
	];

	// Max Duration Before Start
	const handleMaxDurationBeforeStartDay = (event, value) =>
		save({ updatedMaxDurationBeforeStartDay: parseInt(value) });

	const handleMaxDurationBeforeStartHours = (event, value) =>
		save({ updatedMaxDurationBeforeStartHour: parseInt(value) });

	const handleMaxDurationBeforeStartMin = (event, value) =>
		save({ updatedMaxDurationBeforeStartMin: parseInt(value) });

	const maxDurationBeforeStart = [
		{
			label: 'days',
			name: t('ui.label.days'),
			value: maxDurationBeforeStartDay,
			options: maxDaysSlotsBeforeStart,
			action: handleMaxDurationBeforeStartDay,
			className: classes.dayInputAfter,
			defaultValue: '0',
		},
		{
			label: 'hours',
			name: t('ui.label.hours'),
			value: maxDurationBeforeStartHour,
			options: hoursSlots,
			action: handleMaxDurationBeforeStartHours,
			className: classes.hourInputAfter,
			defaultValue: '0',
		},
		{
			label: 'min',
			name: t('ui.label.min'),
			value: maxDurationBeforeStartMin,
			options: graceSlots,
			action: handleMaxDurationBeforeStartMin,
			className: classes.minInputAfter,
			defaultValue: '00',
		},
	];

	// After Use
	const handleDayAfterUse = (event, value) => save({ daysAfterUse: parseInt(value) });

	const handleHourAfterUse = (event, value) => save({ hoursAfterUse: parseInt(value) });

	const handleMinAfterUse = (event, value) => save({ minAfterUse: parseInt(value) });

	const afterUseTime = [
		{
			label: 'days',
			name: t('ui.label.days'),
			value: daysAfterUse,
			options: days,
			action: handleDayAfterUse,
			className: classes.dayInputAfter,
			defaultValue: '0',
		},
		{
			label: 'hours',
			name: t('ui.label.hours'),
			value: hoursAfterUse,
			options: hoursSlots,
			action: handleHourAfterUse,
			className: classes.hourInputAfter,
			defaultValue: '0',
		},
		{
			label: 'min',
			name: t('ui.label.min'),
			value: minAfterUse,
			options: minSlot,
			action: handleMinAfterUse,
			className: classes.minInputAfter,
			defaultValue: '00',
		},
	];

	// Grace Period
	const handleDayGracePeriod = (event, value) => save({ daysGracePeriod: parseInt(value) });

	const handleHourGracePeriod = (event, value) => save({ hoursGracePeriod: parseInt(value) });

	const handleMinGracePeriod = (event, value) => save({ minGracePeriod: parseInt(value) });

	const gracePeriod = [
		{
			label: 'days',
			name: t('ui.label.days'),
			value: daysGracePeriod,
			options: days,
			action: handleDayGracePeriod,
			className: classes.dayInputAfter,
			defaultValue: '0',
		},
		{
			label: 'hours',
			name: t('ui.label.hours'),
			value: hoursGracePeriod,
			options: hoursSlots,
			action: handleHourGracePeriod,
			className: classes.hourInputAfter,
			defaultValue: '0',
		},
		{
			label: 'min',
			name: t('ui.label.min'),
			value: minGracePeriod,
			options: graceSlots,
			action: handleMinGracePeriod,
			className: classes.minInputAfter,
			defaultValue: '00',
		},
	];
	// Cancellation
	const [openCancellationPeriod, setOpenCancellationPeriod] = useState(hasCancellationPeriod);

	useEffect(() => {
		save({
			hasCancellationPeriod: openCancellationPeriod,
		});
	}, [openCancellationPeriod]);

	const handleDayChange = (event, value) => {
		save({ cancelDays: parseInt(value) });
	};

	const handleHourChange = (event, value) => {
		save({ cancelHours: parseInt(value) });
	};

	const handleMinChange = (event, value) => {
		save({ cancelMins: parseInt(value) });
	};

	const cancelTime = [
		{
			label: 'days',
			name: t('ui.label.days'),
			value: cancelDay,
			option: days,
			action: handleDayChange,
			className: classes.dayInputCancel,
			defaultValue: '0',
		},
		{
			label: 'hours',
			name: t('ui.label.hours'),
			value: cancelHour,
			option: hoursSlots,
			action: handleHourChange,
			className: classes.hourInputCancel,
			defaultValue: '0',
		},
		{
			label: 'min',
			name: t('ui.label.min'),
			value: cancelMin,
			option: minSlot,
			action: handleMinChange,
			className: classes.minInputCancel,
			defaultValue: '00',
		},
	];

	// Extension
	const [allowExtending, setAllowExtending] = useState(savedAllowExtending);

	useEffect(() => {
		save({ allowExtending });
	}, [allowExtending]);

	// Dutch license
	const [requireDutchLicense, setRequireDutchLicense] = useState(savedRequireDutchLicense);

	useEffect(() => {
		if (requireDutchLicense && requiredLicenseStatus === licenseStatus.NOT_APPLICABLE) {
			return setRequireDutchLicense(false);
		}
		save({ requireDutchLicense });
	}, [requireDutchLicense, requiredLicenseStatus]);

	// Verification
	const licenseStatus = {
		NOT_APPLICABLE: 'notApplicable',
		VALIDATION: 'validation', // Automatically verified by RDW
		VERIFICATION: 'verification', //Manual verification by super admin, use now verify later is enabled
	};
	const [requireLicenseStatus, setRequireLicenseStatus] = useState(requiredLicenseStatus);
	const [requireLicenseVerification, setRequireLicenseVerification] = useState(
		requiredLicenseStatus !== licenseStatus.NOT_APPLICABLE,
	);
	const [useNowVerifyLater, setUseNowVerifyLater] = useState(
		requiredLicenseStatus === licenseStatus.VALIDATION,
	);

	// Min age
	const [minimumAgeEnabled, setMinimumAgeEnabled] = useState(savedMinimumAge != undefined);
	const [minimumAge, setMinimumAge] = useState<number>(savedMinimumAge ?? 0);

	useEffect(() => {
		save({ minimumAge: minimumAgeEnabled && requireLicenseVerification ? minimumAge : null });
	}, [minimumAgeEnabled, minimumAge, requireLicenseVerification]);

	useEffect(() => {
		if (requireLicenseVerification && useNowVerifyLater) {
			setRequireLicenseStatus(licenseStatus.VALIDATION);
		} else if (requireLicenseVerification && !useNowVerifyLater) {
			setRequireLicenseStatus(licenseStatus.VERIFICATION);
		} else {
			setRequireLicenseStatus(licenseStatus.NOT_APPLICABLE);
		}
		save({
			requiredLicenseStatus: requireLicenseStatus,
		});
	}, [requireLicenseStatus, requireLicenseVerification, useNowVerifyLater]);

	const parkingLicensePlate = {
		notApplicable: 'notApplicable',
		optional: 'optional',
		required: 'required',
	};

	const [requireParkingLicensePlateNumber, setRequireParkingLicensePlateNumber] = useState(
		requiredParkingLicensePlateNumber,
	);
	const [parkingLicensePlateOptional, setParkingLicensePlateOptional] = useState(
		requiredParkingLicensePlateNumber !== parkingLicensePlate.notApplicable,
	);
	const [requiredParkingLicensePlate, setRequiredParkingLicensePlate] = useState(
		requiredParkingLicensePlateNumber === parkingLicensePlate.required,
	);

	useEffect(() => {
		if (parkingLicensePlateOptional && requiredParkingLicensePlate) {
			setRequireParkingLicensePlateNumber(parkingLicensePlate.required);
		} else if (parkingLicensePlateOptional && !requiredParkingLicensePlate) {
			setRequireParkingLicensePlateNumber(parkingLicensePlate.optional);
		} else {
			setRequireParkingLicensePlateNumber(parkingLicensePlate.notApplicable);
		}
		save({ requiredParkingLicensePlateNumber: requireParkingLicensePlateNumber });
	}, [requireParkingLicensePlateNumber, parkingLicensePlateOptional, requiredParkingLicensePlate]);

	return (
		<Card className={classes.root}>
			<CardHeader
				subheader={t('views.addPolicy.policyConfiguration.category.bookingPolicies.description')}
				title={t('views.addPolicy.policyConfiguration.category.bookingPolicies.title')}
				titleTypographyProps={{ variant: 'h3' }}
			/>
			<CardContent>
				<div className={classes.formGroup}>
					<SwitchWithLabel
						value={requireHostConfirmation}
						onChange={(checked) => setRequireHostConfirmation(checked)}
						primary={t('views.addPolicy.bookingConfiguration.section.hostConfirmation.title')}
						secondary={t(
							'views.addPolicy.bookingConfiguration.section.hostConfirmation.description',
						)}
					/>

					<Typography className={classes.subheader} variant='h5'>
						{t('ui.label.bookingHours')}
					</Typography>
					<SwitchWithLabel
						value={openHoursCreate}
						onChange={(checked) => setOpenHoursCreate(checked)}
						primary={t('views.addPolicy.bookingConfiguration.section.bookingHours.subheader')}
						secondary={t('views.addPolicy.bookingConfiguration.section.bookingHours.description')}
					/>
					<Collapse in={openHoursCreate}>
						<Card className={classes.licenseVerification} variant='outlined'>
							<CardContent>
								<SwitchWithLabel
									value={openHoursUsage}
									onChange={(checked) => setOpenHoursUsage(checked)}
									primary={t('views.addPolicy.bookingConfiguration.bookingHours.title')}
									secondary={t('views.addPolicy.bookingConfiguration.bookingHours.description')}
								/>
							</CardContent>
						</Card>
					</Collapse>
				</div>
				<Divider className={classes.divider} />

				<Typography className={classes.subheader} variant='h4'>
					{t('views.addPolicy.bookingConfiguration.subcategory.duration')}
				</Typography>
				<div className={classes.formGroup}>
					<SwitchWithLabel
						value={openMinDuration}
						onChange={(checked) => setOpenMinDuration(checked)}
						primary={t('views.addPolicy.bookingConfiguration.section.minimumTime.title')}
						secondary={t('views.addPolicy.bookingConfiguration.section.minimumTime.description')}
					/>
					<Collapse in={openMinDuration}>
						<div className={classes.inputCooldown}>
							<Typography className={classes.label} variant='h5'>
								{t('ui.label.minDuration')}:
							</Typography>
							{minBookingDuration.map((item) => (
								<Autocomplete
									className={item.className}
									key={item.label}
									onChange={item.action}
									options={item.options}
									renderInput={(params) => (
										<TextField
											{...params}
											InputLabelProps={{ shrink: false }}
											label={item.name}
											size='medium'
											variant='outlined'
										/>
									)}
									value={`${item.value || item.defaultValue}`}
								/>
							))}
						</div>
					</Collapse>
				</div>
				<div className={classes.formGroup}>
					<SwitchWithLabel
						value={openMaxDuration}
						onChange={(checked) => setOpenMaxDuration(checked)}
						primary={t('views.addPolicy.bookingConfiguration.section.maximumTime.title')}
						secondary={t('views.addPolicy.bookingConfiguration.section.maximumTime.description')}
					/>
					<Collapse in={openMaxDuration}>
						<div className={classes.inputCooldown}>
							<Typography className={classes.label} variant='h5'>
								{t('ui.label.maxDuration')}:
							</Typography>
							{maxBookingDuration.map((item) => (
								<Autocomplete
									className={item.className}
									key={item.label}
									onChange={item.action}
									options={item.options}
									renderInput={(params) => (
										<TextField
											{...params}
											InputLabelProps={{ shrink: false }}
											label={item.name}
											size='medium'
											variant='outlined'
										/>
									)}
									value={`${item.value || item.defaultValue}`}
								/>
							))}
						</div>
					</Collapse>
				</div>
				<Divider className={classes.divider} />

				<Typography className={classes.subheader} variant='h4'>
					{t('views.addPolicy.bookingConfiguration.section.header.inUse')}
				</Typography>
				<div className={classes.formGroup}>
					<SwitchWithLabel
						value={requireOvertimeValidation}
						onChange={(checked) => setRequireOvertimeValidation(checked)}
						primary={t('views.addPolicy.bookingConfiguration.section.title')}
						secondary={t('views.addPolicy.bookingConfiguration.section.description')}
					/>
				</div>
				<Divider className={classes.divider} />

				<Typography className={classes.subheader} variant='h4'>
					{t('views.addPolicy.bookingConfiguration.section.header.bookingUpfront')}
				</Typography>
				<div className={classes.formGroup}>
					<SwitchWithLabel
						value={openMaxDurationBeforeStart}
						onChange={(checked) => setOpenMaxDurationBeforeStart(checked)}
						primary={t('views.addPolicy.minDurationBeforeStart.section.title')}
					/>
					<Collapse in={openMaxDurationBeforeStart}>
						<div className={classes.inputCooldown}>
							<Typography className={classes.label} variant='h5'>
								{t('ui.label.futureLimit')}:
							</Typography>
							{maxDurationBeforeStart.map((item) => (
								<Autocomplete
									className={item.className}
									key={item.label}
									onChange={item.action}
									options={item.options}
									renderInput={(params) => (
										<TextField
											{...params}
											InputLabelProps={{ shrink: false }}
											label={item.name}
											size='medium'
											variant='outlined'
										/>
									)}
									value={`${item.value || item.defaultValue}`}
								/>
							))}
						</div>
					</Collapse>
				</div>
				<Divider className={classes.divider} />

				<Typography className={classes.subheader} variant='h4'>
					{t('views.addPolicy.bookingConfiguration.subcategory.bufferPeriod')}
				</Typography>
				<div className={classes.formGroup}>
					<SwitchWithLabel
						value={openBufferPeriod}
						onChange={(checked) => setOpenBufferPeriod(checked)}
						primary={t('views.addPolicy.bookingConfiguration.section.bufferPeriod.title')}
						secondary={t('views.addPolicy.bookingConfiguration.section.bufferPeriod.description')}
					/>
					<Collapse in={openBufferPeriod}>
						<div className={classes.inputCooldown}>
							<Typography className={classes.label} variant='h5'>
								{t('ui.label.afterUse')}:
							</Typography>
							{afterUseTime.map((item) => (
								<Autocomplete
									className={item.className}
									key={item.label}
									onChange={item.action}
									options={item.options}
									renderInput={(params) => (
										<TextField
											{...params}
											InputLabelProps={{ shrink: false }}
											label={item.name}
											size='medium'
											variant='outlined'
										/>
									)}
									value={`${item.value || item.defaultValue}`}
								/>
							))}
						</div>
					</Collapse>
				</div>
				<Divider className={classes.divider} />

				<Typography className={classes.subheader} variant='h4'>
					{t('views.addPolicy.bookingConfiguration.section.graceiPariod.title')}
				</Typography>
				<div className={classes.formGroup}>
					<SwitchWithLabel
						value={openGracePeriod}
						onChange={(checked) => setOpenGracePeriod(checked)}
						primary={t('views.addPolicy.bookingConfiguration.section.graceiPariod.subcategory')}
						secondary={t('views.addPolicy.bookingConfiguration.section.graceiPariod.description')}
					/>
					<Collapse in={openGracePeriod}>
						<div className={classes.inputCooldown}>
							<Typography className={classes.label} variant='h5'>
								{t('ui.label.graceiPariod.pariod')}:
							</Typography>
							{gracePeriod.map((item) => (
								<Autocomplete
									className={item.className}
									key={item.label}
									onChange={item.action}
									options={item.options}
									renderInput={(params) => (
										<TextField
											{...params}
											InputLabelProps={{ shrink: false }}
											label={item.name}
											size='medium'
											variant='outlined'
										/>
									)}
									value={`${item.value || item.defaultValue}`}
								/>
							))}
						</div>
					</Collapse>
				</div>
				<Divider className={classes.divider} />

				<Typography className={classes.subheader} variant='h4'>
					{t('views.addPolicy.bookingConfiguration.subcategory.cancellation')}
				</Typography>
				<div className={classes.formGroup}>
					<SwitchWithLabel
						value={openCancellationPeriod}
						onChange={(checked) => setOpenCancellationPeriod(checked)}
						primary={t('views.addPolicy.bookingConfiguration.section.cancellationPeriod.title')}
						secondary={t(
							'views.addPolicy.bookingConfiguration.section.cancellationPeriod.description',
						)}
					/>
					<Collapse in={openCancellationPeriod}>
						<div className={classes.inputCancellation}>
							<Typography variant='h5'>{t('ui.label.cancelUpUntilTimePartOne')}</Typography>
							{cancelTime.map((item) => (
								<Autocomplete
									className={item.className}
									key={item.label}
									onChange={item.action}
									options={item.option}
									renderInput={(params) => (
										<TextField
											{...params}
											InputLabelProps={{ shrink: false }}
											label={item.name}
											size='medium'
											variant='outlined'
										/>
									)}
									value={`${item.value || item.defaultValue}`}
								/>
							))}
							<Typography className={classes.endLabel} variant='h5'>
								{t('ui.label.cancelUpUntilTimePartTwo')}
							</Typography>
						</div>
					</Collapse>
				</div>
				<Divider className={classes.divider} />

				<Typography className={classes.subheader} variant='h4'>
					{t('views.addPolicy.bookingConfiguration.subcategory.extension')}
				</Typography>
				<div className={classes.formGroup}>
					<SwitchWithLabel
						value={allowExtending}
						onChange={(checked) => setAllowExtending(checked)}
						primary={t('views.addPolicy.bookingConfiguration.section.allowExtension.title')}
						secondary={t('views.addPolicy.bookingConfiguration.section.allowExtension.description')}
					/>
				</div>
				<Divider className={classes.divider} />

				<Typography className={classes.subheader} variant='h4'>
					{t('ui.drivingLicense')}
				</Typography>
				<div className={classes.formGroup}>
					<SwitchWithLabel
						value={requireLicenseVerification}
						onChange={(checked) => setRequireLicenseVerification(checked)}
						primary={t('views.addPolicy.bookingConfiguration.section.requireVerification.title')}
						secondary={t(
							'views.addPolicy.bookingConfiguration.section.requireVerification.description',
						)}
					/>
					<Collapse in={requireLicenseVerification}>
						<Card className={classes.licenseVerification} variant='outlined'>
							<CardContent>
								<SwitchWithLabel
									value={useNowVerifyLater}
									onChange={(checked) => setUseNowVerifyLater(checked)}
									primary={t('views.addPolicy.bookingConfiguration.useNowVerifyLater.title')}
									secondary={t(
										'views.addPolicy.bookingConfiguration.useNowVerifyLater.description',
									)}
								/>
							</CardContent>
						</Card>
						<Card className={classes.licenseVerification} variant='outlined'>
							<CardContent>
								<SwitchWithLabel
									value={requireDutchLicense}
									onChange={(checked) => setRequireDutchLicense(checked)}
									primary={t('views.addPolicy.bookingConfiguration.section.dutchLicense.title')}
									secondary={t(
										'views.addPolicy.bookingConfiguration.section.dutchLicense.description',
									)}
								/>
							</CardContent>
						</Card>
						<Card className={classes.licenseVerification} variant='outlined'>
							<CardContent>
								<SwitchWithLabel
									value={minimumAgeEnabled}
									onChange={(checked) => setMinimumAgeEnabled(checked)}
									primary={t('minimumAgePolicyTitle')}
									secondary={t('minimumAgePolicySubtitle')}
								/>
								<Collapse in={minimumAgeEnabled}>
									<div className={classes.inputCooldown}>
										<Typography variant='h5'>{`${t('minimumAge')}:`}</Typography>
										<Autocomplete
											className={classes.dayInputAfter}
											onChange={(e, value) => setMinimumAge(value)}
											options={[...Array(100).keys()].map((el) => el.toString())}
											renderInput={(params) => (
												<TextField
													{...params}
													InputLabelProps={{ shrink: false }}
													label='years'
													size='medium'
													variant='outlined'
												/>
											)}
											value={minimumAge?.toString()}
										/>
									</div>
								</Collapse>
							</CardContent>
						</Card>
					</Collapse>
				</div>
				<Divider className={classes.divider} />
				<Typography className={classes.subheader} variant='h4'>
					{''}
				</Typography>
				<div className={classes.formGroup}>
					<SwitchWithLabel
						value={parkingLicensePlateOptional}
						onChange={(checked) => setParkingLicensePlateOptional(checked)}
						primary={t('ui.label.licensePlate')}
						secondary={t('views.addPolicy.bookingConfiguration.licensePlate.description')}
					/>
					<Collapse in={parkingLicensePlateOptional}>
						<Card className={classes.licenseVerification} variant='outlined'>
							<CardContent>
								<SwitchWithLabel
									value={requiredParkingLicensePlate}
									onChange={(checked) => setRequiredParkingLicensePlate(checked)}
									primary={t('views.addPolicy.bookingConfiguration.licensePlate.required.title')}
									secondary={t(
										'views.addPolicy.bookingConfiguration.licensePlate.required.description',
									)}
								/>
							</CardContent>
						</Card>
					</Collapse>
				</div>
			</CardContent>
		</Card>
	);
};

interface SwitchWithLabelProps {
	value: boolean;
	onChange?: (value: boolean) => void;
	primary: string;
	secondary?: string;
}

/**
 * Some attempt to generalize the switches with formcontrollabels
 * Before it was all copy paste
 */
const SwitchWithLabel = (props: SwitchWithLabelProps) => {
	const classes = useStyles();

	return (
		<FormControlLabel
			className={classes.switchLabel}
			control={
				<Switch
					checked={props.value}
					color='primary'
					onChange={(e) => props.onChange?.(e.target.checked)}
				/>
			}
			label={
				<div>
					<Typography variant='h5'>{props.primary}</Typography>
					{props.secondary && <Typography color='textSecondary'>{props.secondary}</Typography>}
				</div>
			}
			labelPlacement='start'
		/>
	);
};

export default BookingConfiguration;

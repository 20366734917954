import { useState, useEffect, useRef, useReducer } from 'react';

import BlockIcon from '@mui/icons-material/Block';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { Box, CircularProgress, Drawer, Link, Stack, Typography } from '@mui/material';
import clsx from 'clsx';
import { useAtomValue } from 'jotai';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { userInfoAtom } from '~atoms';
import { Tooltip } from '~components';
import { useAuthorize } from '~features/authentication';

import { reducer, initialState, availabilityTypes } from './availabilityState';
import { useStyles } from './style';
import WarningIcon from '../../../../../assets/icons/label-filled-icon-warning@3x.png';
import {
	SearchBar,
	EmptyState,
	Table,
	GenericMoreButton,
	Label,
	ActionDialog,
	SelectWithLazyLoading,
	StyledButton
} from '../../../../../components';
import { usePrevious, useError, useErrorAcceptedRejected } from '../../../../../shared/hooks';
import {
	isObject,
	isEmptyArray,
	isUndefined,
	instanceName,
	instanceLabel,
	isFullString,
	handleHubReference,
	isEmptyObject,
	isFullArray,
	isNull,
	isBoolean,
} from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';

interface ItemInstancesProps {
	id?: string;
	itemData?: object;
	categoryType?: string;
	onResetStateCondition?(...args: unknown[]): unknown;
	instances?: any;
	onInstanceRestriction?(...args: unknown[]): unknown;
	allQRCodes?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	organisation?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	hubsList?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	itemInstances?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	deleteItemInstance?: {
		success?: boolean;
		loading?: boolean;
		error?: object | string;
	};
	updatedInstanceAvailability?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	itemDetails?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	updateInstancesLocation?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	addCSVInstances?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	removeItemInstances?: {
		data?: object | boolean;
		loading?: boolean;
		error?: object | string;
	};
	deviceUnlockCode?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	instanceRestriction?: {
		success?: boolean;
		loading?: boolean;
		error?: object | string;
	};
	onDeleteItemInstance?(...args: unknown[]): unknown;
	onFetchAllQrCodes?(...args: unknown[]): unknown;
	onFetchHubs?(...args: unknown[]): unknown;
	onFetchItemInstances?(...args: unknown[]): unknown;
	onSetFilter?(...args: unknown[]): unknown;
	onUpdateInstanceAvailability?(...args: unknown[]): unknown;
	setShowAddInstance?(...args: unknown[]): unknown;
	onUpdateInstancesLocation?(...args: unknown[]): unknown;
	onAddCSVInstances?(...args: unknown[]): unknown;
	onRemoveItemInstances?(...args: unknown[]): unknown;
	onResetBlobState?(...args: unknown[]): unknown;
	onResetState?(...args: unknown[]): unknown;
}

const ItemInstances = (props: ItemInstancesProps) => {
	const {
		itemData,
		itemInstances,
		id,
		categoryType,
		onFetchItemInstances,
		deleteItemInstance,
		onDeleteItemInstance,
		setShowAddInstance,
		onUpdateInstanceAvailability,
		updatedInstanceAvailability,
		instances,
		itemDetails,
		onFetchHubs,
		hubsList,
		onUpdateInstancesLocation,
		updateInstancesLocation,

		onFetchAllQrCodes,
		allQRCodes,
		onAddCSVInstances,
		addCSVInstances,
		onRemoveItemInstances,
		removeItemInstances,
		onResetBlobState,
		onResetState,
		deviceUnlockCode,
		instanceRestriction,
		onResetStateCondition,
		onInstanceRestriction,
	} = props;
	const { t } = useTranslation();
	const { isSuperAdmin } = useAuthorize();
	const userInfo = useAtomValue(userInfoAtom);

	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const { data: itemInstancesData, loading: itemInstancesLoading } = itemInstances;

	const { data: deviceUnlockCodeData } = deviceUnlockCode;

	const {
		data: updatedInstanceData,
		loading: updatedInstanceLoading,
		error: updatedInstanceError,
	} = updatedInstanceAvailability;
	const updatedInstanceReady =
		isObject(updatedInstanceData) && !updatedInstanceLoading && !updatedInstanceError;

	const {
		data: removeItemInstancesData,
		loading: removeItemInstancesLoading,
		error: removeItemInstancesRrror,
	} = removeItemInstances;
	const isFailedDelete =
		isObject(removeItemInstancesData) && !removeItemInstancesLoading && !removeItemInstancesRrror;

	const { loading: updateInstancesLocationLoading } = updateInstancesLocation;

	const { data: itemDetailsData, loading: itemDetailsLoading } = itemDetails;
	const organisationId =
		isObject(itemDetailsData) &&
		isObject(itemDetailsData.hubReference) &&
		itemDetailsData.hubReference.organisationReference.id;

	const loading = (itemDetailsLoading && itemInstancesLoading) || removeItemInstancesLoading;
	const isPersonalItem =
		isObject(itemDetailsData) &&
		itemDetailsData.hubReference.organisationReference.id ==
			userInfo.organisation.id;

	const { success: deleteSucces, loading: deletePending, error: deleteError } = deleteItemInstance;
	const deleteDone = deleteSucces && !deletePending && !deleteError;

	const {
		success: instanceRestrictionSuccess,
		loading: instanceRestrictionLoading,
		error: instanceRestrictionError,
	} = instanceRestriction;

	const previousDeleteLoading = usePrevious(deletePending);

	const [availability, dispatchAvailability] = useReducer(reducer, initialState);

	const [showingInitialResults, setShowingInitialResults] = useState(true);
	const linkRef = useRef(null);
	const blobRef = useRef(null);
	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const page = { number: pageNumber, size: pageSize };
	// Dialog
	const [openDialog, setOpenDialog] = useState(false);
	const [deletedInstanceIndex, setDeletedInstanceIndex] = useState(null);
	const [openDeleteInstancesDialog, setOpenDeleteInstancesDialog] = useState(false);

	const [updatedInstanceIndex, setUpdatedInstanceIndex] = useState(null);

	//Location Change drawer
	const [selectInstancesHub, setSelectInstancesHub] = useState(
		isObject(itemData) && isObject(itemData.hubReference) ? itemData.hubReference : { name: '' },
	);
	const [openAssignLocationDrawer, setOpenAssignLocationDrawer] = useState(false);
	const [selectedItemInstances, setSelectedItemInstances] = useState(null);

	//Download All QR Codes
	const { data: allQRCodesData, loading: allQRCodesLoading, error: allQRCodesError } = allQRCodes;
	const allQRCodesReady = !allQRCodesLoading && !allQRCodesError && !isNull(allQRCodesData);
	const [download, setDownload] = useState(false);
	const [downloadCSVInstances, setDownloadCSVInstances] = useState(false);
	const [downloadCSVDialog, setDownloadCSVDialog] = useState(false);

	const {
		data: addCSVInstancesData,
		loading: addCSVInstancesLoading,
		error: addCSVInstancesError,
	} = addCSVInstances;

	const [fileReaderData, setFileReaderData] = useState(null);
	const [fileName, setFileName] = useState(null);
	const [loadingFile, setLoadingFile] = useState(false);
	const usePreviousLoading = usePrevious(addCSVInstancesLoading);
	const [blobDownlod, setBlobDownlod] = useState(false);

	const [organisationIdFilter, setOrganisationIdFilter] = useState({
		name: 'organisationId',
		value: organisationId,
	});

	const [sortingFilter] = useState({ name: 'filters', value: 'none' });

	const assignLocation = useError({
		value: itemInstances,
		message: t('views.itemsDetails.assignLocation.success'),
	});

	const removeItemInstancesMessage = useErrorAcceptedRejected({
		value: removeItemInstances,
		message: { positive: t('views.addItem.message.success.addItemInstance'), negative: 'Error' },
	});

	const handlePageSizeChange = (newSize) => {
		setPageSize(newSize);
		setPageNumber(1);
	};

	const handlePageChange = (number) => {
		setPageNumber(number);
	};

	useEffect(() => {
		if (isObject(deviceUnlockCodeData)) {
			onResetState('deviceUnlockCode');
		}
	}, [deviceUnlockCodeData]);

	useEffect(() => {
		if (isBoolean(removeItemInstancesData)) {
			setOpenDeleteInstancesDialog(false);
			onResetBlobState('removeItemInstances');
			enqueueSnackbar(`${t('ui.successfullyDeleted')} ${''}`, { variant: 'success' });
		}
	}, [removeItemInstancesData]);

	useEffect(() => {
		if (instanceRestrictionSuccess) {
			onFetchItemInstances(id, page);
			dispatchAvailability({ type: 'reset' });
			enqueueSnackbar(t('views.itemManagement.items.availability.message'), { variant: 'success' });
			onResetStateCondition('instanceRestriction', false);
		} else if (instanceRestrictionError) {
			enqueueSnackbar(t(instanceRestrictionError?.message), { variant: 'error' });
			onResetStateCondition('instanceRestriction', false);
		}
	}, [instanceRestriction]);

	useEffect(() => {
		if (!addCSVInstancesLoading && !isNull(addCSVInstancesData) && downloadCSVInstances) {
			const ext = addCSVInstancesData.type.split('/')[1];
			const instanceName = fileName;
			linkRef.current.href = URL.createObjectURL(addCSVInstancesData);
			linkRef.current.download = `${instanceName}.${ext}`;
			linkRef.current.click();
			setDownloadCSVInstances(false);
		}
	}, [addCSVInstancesData, downloadCSVInstances]);

	useEffect(() => {
		if (!removeItemInstancesLoading && !isNull(removeItemInstancesData) && blobDownlod) {
			const ext = removeItemInstancesData.type.split('/')[1];
			blobRef.current.href = URL.createObjectURL(removeItemInstancesData);
			blobRef.current.download = `${itemData.name}.${ext}`;
			blobRef.current.click();
			setBlobDownlod(false);
			setOpenDeleteInstancesDialog(false);
			onResetBlobState('removeItemInstances');
		}
	}, [removeItemInstancesData, blobDownlod]);

	useEffect(() => {
		if (isObject(fileReaderData)) {
			onAddCSVInstances(id, [fileReaderData]);
			setLoadingFile(true);
		}
	}, [fileReaderData]);

	useEffect(() => {
		if (
			!addCSVInstancesLoading &&
			isObject(addCSVInstancesData) &&
			usePreviousLoading &&
			!isObject(addCSVInstancesError)
		) {
			setLoadingFile(false);
			setDownloadCSVDialog(true);
		} else if (isObject(addCSVInstancesError) && usePreviousLoading) {
			setLoadingFile(false);
			enqueueSnackbar(`${addCSVInstancesError?.message}`, { variant: 'error' });
		} else if (isBoolean(addCSVInstancesData)) {
			setLoadingFile(false);
		}
	}, [addCSVInstancesLoading]);

	useEffect(() => {
		if (organisationId != null ) {
			setOrganisationIdFilter({ name: 'organisationId', value: organisationId });
		}
	}, [organisationId]);

	useEffect(() => {
		if (isBoolean(addCSVInstancesData)) {
			enqueueSnackbar(t('views.itemDetail.instances.successfullyAddedCSV'), { variant: 'success' });
			onResetBlobState('addCSVInstances');
		}
	}, [addCSVInstancesData, addCSVInstancesLoading]);

	useEffect(() => {
		if (previousDeleteLoading && !deletePending) {
			if (isObject(deleteError)) {
				enqueueSnackbar(deleteError.message, { variant: 'error' });
			} else {
				enqueueSnackbar(`${t('ui.successfullyDeleted')} ${'the instance'}`, { variant: 'success' });
			}
		}
	}, [deletePending]);

	useEffect(() => {
		if (deleteSucces && deletedInstanceIndex) {
			onFetchItemInstances(id, page);
			setDeletedInstanceIndex(null);
		}
	}, [deleteDone, deletedInstanceIndex]);

	useEffect(() => {
		if (
			(!updatedInstanceLoading && updatedInstanceReady && updatedInstanceIndex) ||
			(!addCSVInstancesLoading && (isObject(addCSVInstancesData) || isBoolean(addCSVInstancesData)))
		) {
			onFetchItemInstances(id, page);
			setUpdatedInstanceIndex(null);
			dispatchAvailability({ type: 'reset' });
			setOpenDeleteInstancesDialog(false);
		}
	}, [updatedInstanceReady, updatedInstanceIndex, addCSVInstancesLoading]);

	/* * * * * * *
	 * GET DATA  *
	 * * * * * * */
	useEffect(() => {
		if (
			(instances && id && !itemInstancesLoading) ||
			updateInstancesLocationLoading ||
			(!removeItemInstancesLoading && isBoolean(removeItemInstancesData))
		) {
			onFetchItemInstances(id, page);
		}
	}, [id, pageNumber, pageSize, updateInstancesLocationLoading, removeItemInstancesLoading]);

	useEffect(() => {
		return () => {
			setShowingInitialResults(true);
		};
	}, [setShowingInitialResults]);

	// Item delete

	const deleteInstance = (data) => {
		dispatchAvailability({ type: 'updateInstance', payload: data.id });
		setOpenDialog(true);
	};

	const createMenuItems = (data) => [
		{
			icon: <DeleteIcon />,
			text: t('ui.button.inline.delete'),
			action: () => deleteInstance(data),
		},
		...(data.isAvailable ?
			[
				{
					icon: <BlockIcon />,
					text: t('ui.button.inline.makeunavailable'),
					isRed: true,
					action: () => handleClickAvailability(data, availabilityTypes.UNAVAILABLE),
				},
			]
		:	[]),
		...(!data.isAvailable || data.restrictAvailable ?
			[
				{
					icon: <CheckCircleOutlinedIcon />,
					text: t('ui.button.inline.makeavailable'),
					action: () =>
						handleClickAvailability(
							data,
							!data.isAvailable && data.restrictAvailable && isSuperAdmin() ?
								availabilityTypes.BOOKABLE
							:	availabilityTypes.AVAILABLE,
						),
					disabled: !isSuperAdmin() && data.restrictAvailable,
				},
			]
		:	[]),
		...(isSuperAdmin() && !data.restrictAvailable ?
			[
				{
					icon: <ErrorOutlineOutlinedIcon />,
					text: t('ui.button.unbookable'),
					isRed: true,
					action: () => handleClickAvailability(data, availabilityTypes.UNBOOKABLE),
				},
			]
		:	[]),
	];

	const handleClickAvailability = (data, type) => {
		dispatchAvailability({
			type: 'updateAvailability',
			payload: {
				openDialog: true,
				itemId: data.itemReference.id,
				instanceId: data.id,
				type: type,
			},
		});
	};

	const handleConfirmDialog = () => {
		if (availability.instanceId) {
			onDeleteItemInstance(id, availability.instanceId);
			setDeletedInstanceIndex(availability.instanceId);
		}
		setOpenDialog(false);
	};

	const body = {
		items: [
			{
				itemId: parseInt(id),
				instanceIds:
					isFullArray(selectedItemInstances) && selectedItemInstances.map((item) => item.id),
			},
		],
	};

	const handleConfirmdeleteInstances = () => {
		if (isFailedDelete) {
			setBlobDownlod(true);
		} else {
			onRemoveItemInstances(body);
			removeItemInstancesMessage.setStartAction(true);
		}
	};

	const handleConfirmChangeAvailability = () => {
		setUpdatedInstanceIndex(availability.instanceId);
		if (availability.type === availabilityTypes.UNAVAILABLE) {
			onUpdateInstanceAvailability(availability.itemId, availability.instanceId, true);
		} else if (availability.type === availabilityTypes.AVAILABLE) {
			onUpdateInstanceAvailability(availability.itemId, availability.instanceId, false);
		} else if (availability.type === availabilityTypes.UNBOOKABLE) {
			onInstanceRestriction(availability.itemId, availability.instanceId, false);
		} else if (availability.type === availabilityTypes.BOOKABLE) {
			onInstanceRestriction(availability.itemId, availability.instanceId, true);
		}
	};

	const handleCancelActionDialog = () => {
		if (availability.openDialog) {
			dispatchAvailability({ type: 'reset' });
		} else if (downloadCSVDialog) {
			setDownloadCSVDialog(false);
		} else if (openDialog) {
			setOpenDialog(false);
		} else if (openDeleteInstancesDialog) {
			setOpenDeleteInstancesDialog(false);
			onResetBlobState('removeItemInstances');
		}
	};

	const handleCSVDownload = () => {
		setDownloadCSVInstances(true);
		setDownloadCSVDialog(false);
	};

	/* * * * * * * * * *
	 * Assign Location *
	 * * * * * * * * * */
	const handleAssignLocation = () => {
		const item = { itemId: id, instanceIds: selectedItemInstances.map((id) => id.id) };
		setOpenAssignLocationDrawer(false);
		assignLocation.setStartAction(true);
		onUpdateInstancesLocation(selectInstancesHub.id, [item]);
		setSelectInstancesHub({ name: '' });
	};

	const handleCloseAssignLocationDrawer = () => {
		setOpenAssignLocationDrawer(false);
		setSelectInstancesHub({ name: '' });
	};

	const fileReader = {
		setFileReaderData: setFileReaderData,
		setFileName: setFileName,
		loading: loadingFile,
		setLoading: setLoadingFile,
		name: t('view.itemmanagement.itemdetails.button.contained.importCSV'),
	};

	const editActionButtons = [
		{
			icon: <LocationOnOutlinedIcon />,
			text: t('ui.assignToLocation'),
			action: (selectionInstances) => {
				setOpenAssignLocationDrawer(true);
				setSelectedItemInstances(selectionInstances);
			},
		},
		{
			icon: <DeleteIcon />,
			text: t('ui.delete'),
			isRed: true,
			action: (selectionInstances) => {
				setOpenDeleteInstancesDialog(true);
				setSelectedItemInstances(selectionInstances);
			},
		},
	];

	const handleDownloadQRCodes = (e) => {
		e.preventDefault();
		onFetchAllQrCodes(id);
		setDownload(true);
	};

	useEffect(() => {
		if (allQRCodesReady && download) {
			const ext = allQRCodesData.type.substring(allQRCodesData.type.lastIndexOf('/') + 1);
			const link = document.createElement('a');
			link.href = URL.createObjectURL(allQRCodesData);
			link.download = `${itemData.name}.${ext}`;
			link.click();
			setDownload(false);
		}
	}, [allQRCodesLoading, download]);

	/* * * * * * * * *
	 * TABLE DETAILS *
	 * * * * * * * * */
	const tableHeaders = [
		...(isFullString(categoryType) ? [{ name: 'name', content: instanceLabel(categoryType) }] : []),
		{ name: 'location', content: t('ui.label.location') },
		{ name: 'deviceId', content: t('ui.label.deviceId') },
		...(categoryType === 'bikes' ?
			[{ name: 'frameNumber', content: t('ui.label.frameNumber') }]
		:	[]),
		...(categoryType === 'cars' || categoryType === 'boats' ?
			[
				{ name: 'brand', content: t('ui.label.brand') },
				{ name: 'model', content: t('ui.label.model') },
			]
		:	[]),
		...(categoryType === 'cars' ? [{ name: 'vinNumber', content: t('ui.label.vinNumber') }] : []),
		...(categoryType === 'trailers' ?
			[{ name: 'vinNumber', content: t('ui.label.vinNumber') }]
		:	[]),
		{ name: 'status', content: t('ui.label.status') },
		{ name: '', content: '' },
	];

	const loadingState = Array(4)
		.fill(Array(tableHeaders.length).fill())
		.map((arr) => arr.map(() => ({ loading: true })));

	const loadingBody =
		!isObject(itemInstancesData) || loading || updateInstancesLocationLoading ?
			Array(4)
				.fill(Array(tableHeaders.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })))
		:	null;

	const dataBody =
		isObject(itemInstancesData) && !loading ?
			itemInstancesData.results.map((item) => [
				{
					content: (
						<Stack spacing={1} direction='row' sx={{ alignItems: 'center' }}>
							<Link
								className={clsx({
									[classes.itemGroup]: true,
									[classes.notAvailable]:
										item.isAvailable !== undefined ?
											!item.isAvailable || item.restrictAvailable
										:	false,
								})}
								color='inherit'
								component={RouterLink}
								to={`/item-management/${item.itemReference.id}/instance/${item.id}/summary`}
								state={{
									from: `/item-management/items/${item.itemReference.id}/itemInstances`,
									showBackButton: true,
								}}
							>
								{instanceName(item)}
							</Link>
							{item.restrictAvailable &&
								<Tooltip title={t('ui.tooltip.description')}>
									<img alt='warning icon' src={WarningIcon} width='16' height='16' />
								</Tooltip>
							}
						</Stack>
					),
				},
				{ content: handleHubReference(item.hubReference) },
				{ content: !isUndefined(item.deviceId) ? item.deviceId : '-' },
				...(categoryType === 'bikes' && item.itemBike ?
					[{ content: item.itemBike.frameNumber }]
				:	[]),
				...(categoryType === 'cars' && item.itemCar ?
					[
						{ content: item.itemCar.brand },
						{ content: item.itemCar.model },
						{ content: item.itemCar.vinNumber },
					]
				:	[]),
				...(categoryType === 'boats' ?
					[{ content: item?.itemBoat?.brand }, { content: item?.itemBoat?.model }]
				:	[]),
				...(categoryType === 'trailers' && item.itemTrailer ?
					[{ content: item.itemTrailer.vinNumber }]
				:	[]),
				{
					content: (
						<Label type={item.isAvailable ? 'success' : 'error'}>
							{item.isAvailable ? t('ui.status.available') : t('ui.status.unavailable')}
						</Label>
					),
				},
				{
					content:
						isPersonalItem || isSuperAdmin() ?
							<div className={classes.buttonSpacing}>
								<GenericMoreButton menuItems={createMenuItems(item)} />
							</div>
						:	[],
				},
			])
		:	loadingState;

	const extraButtons = [
		...(isFullArray(itemInstancesData?.results) ?
			[
				{
					text:
						allQRCodesLoading ?
							<CircularProgress color='info' disableShrink size={24} />
						:	<>
								<QrCodeIcon sx={{ marginRight: '4px' }} />
								{t('views.itemInstances.downloadAllQRCodes')}
							</>,
					onClick: (e) => handleDownloadQRCodes(e),
					variant: 'contained-tertiary',
				},
			]
		:	[]),
		...(isPersonalItem || isSuperAdmin() ?
			[
				{
					text:
						isNull(categoryType) ?
							<CircularProgress disableShrink size={24} />
						:	t(`view.itemmanagement.itemdetails.button.contained.add${categoryType}`),
					onClick: () => setShowAddInstance(true),
					variant: 'contained-primary',
				},
			]
		:	[]),
	];

	const emptyStateProps = {
		callToActionText: t(`view.itemmanagement.itemdetails.button.contained.add${categoryType}`),
		handleAction: () => setShowAddInstance(true),
		image: 'booking',
		title: t('views.itemDetail.instances.table.empty.title'),
	};
	const itemsInstancesListEmpty =
		isObject(itemInstancesData) && !loading && isEmptyArray(itemInstancesData.results) ?
			showingInitialResults ?
				<EmptyState
					{...emptyStateProps}
					subTitle={t('views.itemDetail.instances.table.empty.description.zeroInSystem')}
				/>
			:	<EmptyState
					{...emptyStateProps}
					subTitle={t('views.itemDetail.instances.table.empty.description.zeroMatching')}
				/>
		:	null;

	const actionDialog = [
		{
			name: availability.type,
			loading: updatedInstanceLoading || instanceRestrictionLoading,
			open: availability.openDialog,
			action: handleConfirmChangeAvailability,
			title: `views.itemDetail.instances.dialog.${availability.type}.title`,
			text: `views.itemDetail.instances.dialog.${availability.type}.confirm`,
			description: `views.itemDetail.instances.dialog.${availability.type}.description`,
		},
		{
			name: 'downloadCSV',
			open: downloadCSVDialog,
			action: handleCSVDownload,
			text: 'view.itemmanagement.itemdetails.button.contained.downloadCSV',
			loading: false,
			title: 'views.itemDetail.instances.dialog.downloadCSV.title',
			description: 'views.itemDetail.instances.dialog.downloadCSV.description',
		},
		{
			name: 'deleteInstance',
			open: openDialog,
			action: handleConfirmDialog,
			text: 'ui.confirm',
			loading: deletePending && !deleteDone,
			title: 'ui.delete',
			description: 'views.itemDetail.instances.dialog.deleteInstance.description',
		},
		{
			name: 'deleteInstances',
			open: openDeleteInstancesDialog,
			action: handleConfirmdeleteInstances,
			text:
				isFailedDelete ?
					'view.itemmanagement.itemdetails.button.contained.downloadCSV'
				:	'ui.confirm',
			loading: isFailedDelete ? blobDownlod : removeItemInstancesLoading,
			title: 'ui.delete',
			description:
				isFailedDelete ?
					'views.itemDetail.instances.dialog.bulkDeleteCSV.description'
				:	'views.itemDetail.instances.dialog.bulkDeleteInstance.description',
		},
	];

	/* * * * * *
	 * RENDER  *
	 * * * * * */
	return (
		<div className={classes.root}>
			<SearchBar
				extraButtons={extraButtons}
				fileReaderData={(isSuperAdmin() || isPersonalItem) && fileReader}
				hasExtraButtons
				hideSearch
			/>
			{itemsInstancesListEmpty || updateInstancesLocationLoading ?
				itemsInstancesListEmpty || updateInstancesLocationLoading
			:	<Table
					body={loadingBody || dataBody}
					cellStyle={classes.cellStyle}
					data={isObject(itemInstancesData) ? itemInstancesData.results : []}
					editActionButtons={editActionButtons}
					handlePageChange={handlePageChange}
					hasSelectionEnabled={(isPersonalItem || isSuperAdmin()) && !loading}
					header={tableHeaders}
					loading={loading}
					page={pageNumber}
					rowsPerPage={pageSize}
					setRowsPerPage={handlePageSizeChange}
					title={t('views.itemDetail.instances.table.title')}
					total={itemInstancesData ? itemInstancesData.total : 0}
				/>
			}
			{actionDialog.map((dialog) =>
				dialog.open ?
					<ActionDialog
						actionButtonProps={{
							text: dialog.loading ? <CircularProgress disableShrink size={24} /> : t(dialog.text),
							action: dialog.action,
						}}
						handleClose={handleCancelActionDialog}
						key={dialog.name}
						open={dialog.open}
						title={t(dialog.title)}
					>
						<Typography color={isFailedDelete ? 'error' : null} variant='body1'>
							{t(dialog.description)}
						</Typography>
					</ActionDialog>
				:	null,
			)}
			<Drawer
				anchor='right'
				classes={{ paper: classes.drawer }}
				onClose={handleCloseAssignLocationDrawer}
				open={openAssignLocationDrawer}
				variant='temporary'
			>
				<Box display='flex'>
					<StyledButton
						onClick={handleCloseAssignLocationDrawer}
						size='small'
						startIcon={<CloseIcon />}
						variant='inline-default'
					>
						{t('ui.button.inline.close')}
					</StyledButton>
				</Box>
				<>
					<Typography className={classes.header} variant='h2'>
						{t('ui.assignToLocation')}
					</Typography>
					<div className={classes.formGroup}>
						<Typography gutterBottom={true} variant='h6'>
							{t('ui.label.location')}
						</Typography>
						<SelectWithLazyLoading
							className={classes.filterInput}
							dataList={hubsList}
							extraFilter={sortingFilter}
							filter={organisationIdFilter}
							listType={'organisations'}
							onFetchData={onFetchHubs}
							placeholder={t('ui.filter.locations.all')}
							setSelected={setSelectInstancesHub}
							sortingFilter={sortingFilter}
							value={selectInstancesHub.name}
						/>
					</div>
					<Box mb={3} mt={3}>
						<Typography variant='h6'>{t('ui.label.items')}</Typography>
						<Box className={classes.itemInstancesBox} mt={2} p={1}>
							{isFullArray(selectedItemInstances) &&
								selectedItemInstances.map((itemInstance) => (
									<Typography key={itemInstance.id} variant='h6'>
										{/* Need a better way, assumes category types are plurals */}
										{`${t(`ui.${categoryType.slice(0, -1)}`)}: ${instanceName(itemInstance)}`}
									</Typography>
								))}
						</Box>
					</Box>
					<StyledButton
						disabled={isEmptyObject(selectedItemInstances)}
						onClick={handleAssignLocation}
						size='large'
						variant='contained-primary'
					>
						{t('ui.confirm')}
					</StyledButton>
				</>
			</Drawer>
			<a ref={linkRef} style={{ display: 'none' }} />
			<a ref={blobRef} style={{ display: 'none' }} />
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		allQRCodes: state.details.allQRCodes,
		hubsList: state.paged.hubs,
		itemInstances: state.paged.itemInstances,
		deleteItemInstance: state.condition.deleteItemInstance,
		updatedInstanceAvailability: state.details.updatedInstanceAvailability,
		instanceRestriction: state.condition.instanceRestriction,
		filters: state.filters,

		itemDetails: state.details.itemDetails,
		organisation: state.details.organisation,
		updateInstancesLocation: state.details.updateInstancesLocation,

		addCSVInstances: state.blob.addCSVInstances,
		removeItemInstances: state.blob.removeItemInstances,
		deviceUnlockCode: state.details.deviceUnlockCode,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchAllQrCodes: (itemId) => dispatch(actions.fetchAllQRCodes(itemId)),
		onFetchHubs: (page, filters, concat) => dispatch(actions.fetchHubs(page, filters, concat)),
		onFetchItemInstances: (id, page, filters) =>
			dispatch(actions.fetchItemInstances(id, page, filters)),
		onDeleteItemInstance: (itemId, instanceId) =>
			dispatch(actions.deleteItemInstance(itemId, instanceId)),
		onUpdateInstanceAvailability: (itemId, instanceId, isAvailable) =>
			dispatch(actions.updateInstanceAvailability(itemId, instanceId, isAvailable)),
		onInstanceRestriction: (itemId, instanceId, isAvailable) =>
			dispatch(actions.instanceRestriction(itemId, instanceId, isAvailable)),
		onUpdateInstancesLocation: (itemId, innstances) =>
			dispatch(actions.updateInstancesLocation(itemId, innstances)),
		onAddCSVInstances: (itemId, file) => dispatch(actions.addCSVInstances(itemId, file)),
		onRemoveItemInstances: (body) => dispatch(actions.removeItemInstances(body)),
		onResetBlobState: (state) => dispatch(actions.resetBlobState(state)),
		onResetState: (state) => dispatch(actions.resetState(state)),
		onResetStateCondition: (state, value) => dispatch(actions.resetStateCondition(state, value)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemInstances);

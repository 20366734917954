import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useStyles } from './style';

interface HeaderProps {
	className?: string;
}

const Header = (props: HeaderProps) => {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<div>
			<Grid
				alignItems='flex-end'
				className={classes.headerSpacing}
				container
				justifyContent='space-between'
				spacing={3}
			>
				<Grid item>
					<Typography component='h1' variant='h3'>
						{t('ui.label.contracts')}
					</Typography>
				</Grid>
			</Grid>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		deleteContract: state.condition.deleteContract,
	};
};

export default connect(mapStateToProps, null)(Header);

import { useEffect, useMemo, useState } from 'react';

import path from 'path';

import {
	GridCallbackDetails,
	GridColDef,
	GridPaginationModel,
	GridSortModel,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { DataGrid } from '~components';
import { Hub } from '~features/hub';
import { BaseReference } from '~interfaces';
import { DateRange } from '~interfaces/dateRanges';
import { toCityStreetString } from '~utils/addressUtils';

import FinanceStatisticsService from '../../services/financeStatisticsService';

interface FinanceStat {
	timestamp: Date;
	revenue: number; // in euros?
	item: BaseReference;
	hub: BaseReference;
	bookingCount: number;
}

const service = new FinanceStatisticsService();

interface ItemRevenueStatisticsDataGridProps {
	organisationId?: string;
	period: DateRange;
	categoryId?: string;
	itemId?: string;
	hubId?: string;
}

/**
 *
 * @param props
 * @returns
 * Notice if you change the props to a spread syntax, will create infinite rerender
 */
const ItemRevenueStatisticsDataGrid = (props: ItemRevenueStatisticsDataGridProps) => {
	const { t } = useTranslation();

	const columns = useMemo<GridColDef<FinanceStat>[]>(
		() => [
			{
				field: 'item',
				headerName: t('ui.label.item'),
				flex: 1,
				// valueGetter: (_, row) => row?.label,
				valueGetter: (_, row) => `${row?.label ?? ''} - ${row.itemGroup.label}`,
			},
			{
				field: 'hub',
				headerName: t('ui.label.location'),
				flex: 1,
				valueFormatter: (value: Hub) => value?.address ? toCityStreetString(value.address) : '-',
			},
			{
				field: 'revenue',
				headerName: t('total'),
				flex: 1,
				valueFormatter: (value: number) =>
					`€${value.toLocaleString(undefined, { minimumFractionDigits: 2 })}`,
			},
			{
				field: 'bookingCount',
				headerName: t('ui.category.bookings'),
				flex: 1,
			},
			{
				field: 'average',
				headerName: t('averageRevenuePerBooking'),
				flex: 1,
				valueGetter: (_, row) => row.revenue / row.bookingCount,
				valueFormatter: (value: number) =>
					`€${value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
			},
		],
		[t],
	);

	const [fetchParameters, setFetchParameters] = useState(props);
	const { data, error, isLoading, isValidating } = useSWR(
		[path.join(service.basePath, 'list'), fetchParameters],
		([_, args]) => {
			if (isLoading) {
				service.abortCurrentRequest('Parameters change');
			}

			return service.getItemRevenues(args);
		},
	);
	const [totalCount, setTotalCount] = useState<number>(data?.total || 0);

	useEffect(() => {
		if (data?.total != null) {
			setTotalCount(data.total);
		}
	}, [data]);

	useEffect(() => {
		setFetchParameters((prev) => ({
			...prev,
			...props,
		}));
	}, [props]);

	const handlePaginationModelChange = (
		value: GridPaginationModel,
		details: GridCallbackDetails,
	) => {
		if (details.reason === 'setPaginationModel' && (isLoading || isValidating)) {
			service.abortCurrentRequest('pagination change');
		}

		setFetchParameters((prev) => ({
			...prev,
			page: value.page + 1,
			pageSize: value.pageSize,
		}));
	};

	const handleSortModelChange = (model: GridSortModel) => {
		setFetchParameters((prev) => ({
			...prev,
			sortBy: model ? model[0]?.field : undefined,
			descending: model ? model[0]?.sort === 'desc' : undefined,
		}));
	};

	return (
		<DataGrid
			error={error}
			loading={isLoading || isValidating}
			columns={columns}
			rows={data?.results ?? []}
			rowCount={totalCount}
			onPaginationModelChange={handlePaginationModelChange}
			onSortModelChange={handleSortModelChange}
			initialState={{
				pagination: {
					paginationModel: {
						pageSize: 5,
					},
				},
			}}
			sx={{ flexGrow: 1, flexBasis: 200 }}
		/>
	);
};

export default ItemRevenueStatisticsDataGrid;

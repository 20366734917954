import { useState, useRef, useEffect, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import CalendarFilterContainer from './CalendarFilterContainer';
import { useStyles } from './CalendarFilterContainer/style';
import FullCalendar from './FullCalendar';
import { chartTimes } from './utility';
import {
	timeWithinEventPeriod,
	sameDates,
	isEarlierThan,
	isLaterThan,
	modifyDate,
} from '../../shared/datetime';
import { useError } from '../../shared/hooks';
import {
	isObject,
	isFullArray,
	isEmptyArray,
	isUndefined,
	isNull,
	isInteger,
	isArray,
} from '../../shared/utility';
import * as actions from '../../store/actions';

const getExtension = (extension) => extension.split('-').pop();

const itemInstanceResourceIdRegexp = new RegExp(/^\d{1,}-\d{1,}$/);

interface FullCalendarPlanboardProps {
	planboardBookings?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	updatedBookingDetails?: {
		success?: boolean;
		loading?: boolean;
		error?: object | string;
	};
	// functions
	onPatchUpdateBooking(...args: unknown[]): unknown;
}

const FullCalendarPlanboard = (props: FullCalendarPlanboardProps) => {
	const classes = useStyles();

	const { planboardBookings, onPatchUpdateBooking, updatedBookingDetails } = props;
	const { t } = useTranslation();

	// calendar
	const calendarRef = useRef(null);
	const [date, setDate] = useState(new Date());
	const getFirstElement = (id) => id.split('-').shift();
	// add booking
	const [openAddOrEditBooking, setOpenAddOrEditBooking] = useState(false);
	const [presetBookingData, setPresetBookingData] = useState(null);
	const [shouldEditBooking, setShouldEditBooking] = useState(false);

	const { data: bookingsData, loading: bookingsLoading, error: bookingsError } = planboardBookings;
	const bookingsReady = isObject(bookingsData) && !bookingsLoading && !bookingsError;
	const [resources, setResources] = useState([]);
	const [resourcesLoading, setResourcesLoading] = useState(true);
	const [chartData, setChartData] = useState(null);

	const [bookings, setBookings] = useState([]);
	const [updatedBookingId, setUpdatedBookingId] = useState(null);

	const [pageNumber, setPageNumber] = useState(1);
	const pageSize = 20;

	useEffect(() => {
		if (!bookingsLoading && isObject(bookingsData)) {
			const unavailabilities =
				isFullArray(bookingsData.items) && isArray(bookingsData.unavailabilities) ?
					bookingsData.unavailabilities.map((unavailability) => {
						const children = bookingsData.items.find(
							(item) => item.id === unavailability.resourceId,
						);
						return {
							...unavailability,
							id: unavailability.id,
							className: classes.unavailability,
							title: 'unavailability',
							unavailability: true,
							editable: false,
							startEditable: false,
							durationEditable: false,
							resourceEditable: false,
							resourceId:
								children?.numberOfChildren === 1 ?
									`${unavailability.itemInstance.itemReference.id}`
								:	`${unavailability.itemInstance.itemReference.id}-${unavailability.itemInstance.id}`,
						};
					})
				:	[];
			// `

			const updatedBookings = bookingsData.bookings.map((booking) => {
				const itemGroup = bookingsData.items.find(
					(el) => el.id === booking.resourceId.split('-')[0],
				);

				return {
					...booking,
					...(isEarlierThan(new Date(booking.periodEnd)) && {
						editable: false,
						startEditable: false,
						durationEditable: false,
						resourceEditable: false,
					}),
					...(itemGroup.children.length === 1 && {
						resourceId: itemGroup.id,
					}),
					...(isEarlierThan(new Date(booking.periodStart)) &&
						isLaterThan(new Date(booking.periodEnd)) && {
							editable: false,
							startEditable: false,
							durationEditable: false,
							resizableFromStart: false,
							resourceEditable: false,
						}),
					...(booking.access !== 'full' && {
						className: classes.obscuredBooking,
						editable: false,
						startEditable: false,
						durationEditable: false,
						resourceEditable: false,
					}),
					...(booking.status === 'overtime' && { className: classes.overtimeBooking }),
					...(booking.access !== 'full' &&
						booking.status === 'overtime' && { className: classes.obscuredOvertimeBooking }),
					start: booking.periodStart,
					end: booking.usageState === 'stopped' ? booking.usage.stop : booking.periodEnd,
					...booking.items,
				};
			});

			const bufferPeriods = bookingsData.bookings.map((booking) => {
				const item = bookingsData.items.find(
					(bItem) => `${bItem.id}` === booking.resourceId.split('-')[0],
				);
				return (
						isUndefined(item) ||
							isUndefined(booking.bookingPolicies) ||
							(booking.bookingPolicies &&
								isUndefined(booking.bookingPolicies.cooldownBufferMinutes)) ||
							(booking.bookingPolicies && booking?.bookingPolicies?.cooldownBufferMinutes === 0)
					) ?
						{}
					:	{
							...booking,
							id: `cooldown-${booking.id}`,
							className: classes.bufferPeriod,
							...(booking.access !== 'full' && { className: classes.obscuredBufferPeriod }),
							...(booking.status === 'overtime' && { className: classes.overtimeBufferPeriod }),
							...(booking.access !== 'full' &&
								booking.status === 'overtime' && {
									className: classes.obscuredOvertimeBufferPeriod,
								}),
							editable: false,
							startEditable: false,
							durationEditable: false,
							resourceEditable: false,
							title: 'cooldown',
							access: 'full',
							start:
								booking.usageState === 'stopped' ?
									modifyDate(new Date(booking.usage.stop), { seconds: '+45' }).toISOString()
								:	booking.periodEnd,
							end:
								booking.usageState === 'stopped' ?
									modifyDate(new Date(booking.usage.stop), {
										minutes: `+${booking.bookingPolicies.cooldownBufferMinutes}`,
									}).toISOString()
								:	modifyDate(new Date(booking.periodEnd), {
										minutes: `+${booking.bookingPolicies.cooldownBufferMinutes}`,
									}).toISOString(),
							cooldownBufferMinutes: bookings?.bookingPolicies?.cooldownBufferMinutes,
							policies: booking.bookingPolicies,
						};
			});

			setBookings(updatedBookings.concat(bufferPeriods, unavailabilities));
			setResources(
				bookingsData.items.map((item) => ({
					...item,
					...(item.children.length > 1 && {
						children: item.children.map((child) => ({
							...child,
							className: 'child-track',
						})),
					}),
					...(item?.numberOfChildren === 1 && {
						className: 'single-track',
						availabilityState: item.children[0].availabilityState,
						children: [],
					}),
					...(item?.numberOfChildren > 1 && { className: 'parent-track' }),
				})),
			);
			setResourcesLoading(false);
		} else {
			setResourcesLoading(true);
		}
	}, [bookingsReady, bookingsData]);

	//add buffer period events
	useEffect(() => {
		if (!isNull(updatedBookingId)) {
			setUpdatedBookingId(null);
			const updatedBookings = bookings.map((booking) => ({
				...booking,
				...(booking.id === updatedBookingId && {
					isBeingUpdated: true,
					editable: false,
					startEditable: false,
					durationEditable: false,
					resourceEditable: false,
				}),
			}));
			setBookings(updatedBookings);
		}
	}, [updatedBookingId]);

	useEffect(() => {
		if (resourcesLoading) {
			setResources(
				resources.concat([
					{
						className: 'loading-track',
						id: '-1',
					},
					{
						className: 'loading-track',
						id: '-2',
					},
					{
						className: 'loading-track',
						id: '-3',
					},
				]),
			);
		}
	}, [resourcesLoading]);

	const [fullResourceList, setFullResourceList] = useState([]);

	// creating a full resource list (all selectable resources when creating events)
	useEffect(() => {
		if (bookingsReady) {
			const items = bookingsData.items.map((item) => ({
				...item,
				...(item?.numberOfChildren > 1 && { className: 'parent-track' }),
				mainItemId: item.id,
			}));

			const mainResources = items.filter((resource) => {
				return !(resource.className && resource.className === 'parent-track');
			});

			const parentTracks = items.filter((resource) => {
				return resource.className && resource.className === 'parent-track';
			});

			let childResources = [];

			for (let i = 0; i < parentTracks.length; i++) {
				const children = parentTracks[i].children.map((child) => ({
					id: child.id,
					title: parentTracks[i].title + ' - ' + child.title,
					hubReference: parentTracks[i].hubReference,
					categoryId: parentTracks[i].categoryId,
					itemInstanceId: child.id.split('-')[1],
					mainItemId: parentTracks[i].id,
				}));

				childResources = childResources.concat(children);
			}

			const newFullResourceList = mainResources.concat(childResources);

			setFullResourceList(newFullResourceList);
		}
	}, [bookingsReady, bookingsData]);

	const loading = (isObject(bookingsData) ? bookingsLoading : !bookingsReady) || resourcesLoading;

	//update booking
	const [updatingBooking, setUpdatingBooking] = useState(false);

	const {
		success: updatedBookingSuccess,
		loading: updatedBookingLoading,
		error: updatedBookingError,
	} = updatedBookingDetails;
	const updateBookingDone = updatedBookingSuccess && !updatedBookingLoading && !updatedBookingError;

	const updateErrorHandling = useError({
		value: updatedBookingDetails,
		message: t('views.planboard.message.success.updatedBooking'),
	});

	useEffect(() => {
		if (updatingBooking && updateBookingDone) {
			setUpdatingBooking(false);
			setPageNumber(1);
			setShouldDoInitialFetch(true);
		}
	}, [updatingBooking, updateBookingDone]);

	useEffect(() => {
		if (updatingBooking && !!updatedBookingError) {
			setUpdatingBooking(false);
			setPageNumber(1);
			setShouldDoInitialFetch(true);
			setBookings(bookings.concat([{}]));
		}
	}, [updatingBooking, updatedBookingError, bookings]);

	// results
	const [shouldDoInitialFetch, setShouldDoInitialFetch] = useState(true);
	const [shouldLazyLoadFetch, setShouldLazyLoadFetch] = useState(false);

	//calculate used items per moment at half hour intervals
	useEffect(() => {
		if (!isEmptyArray(resources)) {
			const parentArray = resources.filter((resource) => resource.className === 'parent-track');

			for (let i = 0; i < parentArray.length; i++) {
				const bookings = bookingsData.bookings.filter((event) => {
					const idPartToMatch = event.resourceId.toString().slice(0, parentArray[i].id.length + 1);
					return idPartToMatch === parentArray[i].id + '-';
				});

				const unavailabilities = bookingsData.unavailabilities
					.filter((event) => {
						const idPartToMatch = `${event.resourceId}-`;
						return idPartToMatch === parentArray[i].id + '-';
					})
					.map((item) => ({ ...item, periodStart: item.start, periodEnd: item.end }));

				const childEvents = [].concat(unavailabilities, bookings);

				childEvents.sort((a, b) => {
					return a.resourceId.split('-')[1] - b.resourceId.split('-')[1];
				});

				const dataArray = [];

				for (let j = 0; j < chartTimes.length; j++) {
					dataArray.push(0);
					for (let k = 0; k < childEvents.length; k++) {
						const startDate = new Date(childEvents[k].periodStart);
						const sameTrackSameTimeAsTimeToCheck =
							k >= 1 &&
							childEvents[k].resourceId === childEvents[k - 1].resourceId &&
							sameDates(startDate, new Date(childEvents[k - 1].periodEnd), true) &&
							startDate.getHours() === chartTimes[j].hours &&
							startDate.getMinutes() === chartTimes[j].minutes;
						if (
							!sameTrackSameTimeAsTimeToCheck &&
							timeWithinEventPeriod(
								chartTimes[j],
								date,
								new Date(childEvents[k].periodStart),
								new Date(childEvents[k].periodEnd),
							)
						) {
							dataArray[j] += 1;
						}
					}
				}
				setChartData((prevChartData) => ({
					...prevChartData,
					[parentArray[i].id]: {
						bookingTotal: parentArray[i].children.length,
						bookingData: dataArray,
					},
				}));
			}
		}
	}, [resources, date]);

	/* * * * * * * * * * * * * *
	 * LAZY LOADING LISTITEMS  *
	 * * * * * * * * * * * * * */
	const handleLazyLoading = () => {
		setShouldLazyLoadFetch(true);
	};

	const resourceScroller = document.querySelector('.fc-scroller.fc-scroller-liquid-absolute');

	const lazyLoadMore = useCallback(() => {
		if (!loading) {
			if (
				resourceScroller?.scrollTop &&
				Math.round(resourceScroller.scrollTop + resourceScroller.clientHeight) >=
					resourceScroller.children[0].scrollHeight &&
				!loading &&
				bookingsReady &&
				isObject(bookingsData) &&
				bookingsData.hasMore
			) {
				handleLazyLoading();
			}
		}
	}, [loading, bookingsReady, bookingsData]);

	useEffect(() => {
		if (bookingsReady && bookingsData.hasMore && resourceScroller) {
			resourceScroller.addEventListener('scroll', lazyLoadMore);
		}

		return () => {
			if (shouldLazyLoadFetch) {
				resourceScroller.removeEventListener('scroll', lazyLoadMore);
			}
		};
	}, [bookingsReady, bookingsData, lazyLoadMore]);

	/* * * * * * * * * * *
	 * CALENDAR METHODS  *
	 * * * * * * * * * * */
	const handleSelect = (selectionInfo) => {
		setPresetBookingData({
			start: selectionInfo.start,
			end: selectionInfo.end,
			itemSelected: true,
			activSelection: true,
			instanceId:
				isInteger(selectionInfo?.resource?._resource?.extendedProps?.itemInstanceId) ?
					selectionInfo.resource._resource.extendedProps.itemInstanceId
				:	Math.round(getExtension(selectionInfo.resource._resource.id)),
			itemId:
				selectionInfo.resource._resource?.parentId ?
					Number(selectionInfo.resource._resource.parentId)
				:	Number(selectionInfo.resource._resource.id),
		});
		setOpenAddOrEditBooking(true);
	};

	const handleResizeEvent = (info) => {
		const updateBookingBody = {
			periodStart: info.event.start,
			periodEnd: info.event.end,
		};
		handleEditBooking(info.event.id, updateBookingBody);
	};

	const handleDropEvent = (info) => {
		const updateBookingBody = {
			periodStart: info.event.start,
			periodEnd: info.event.end,
			...(info.event._def.resourceIds[0].split('-').length > 1 && {
				itemInstanceId: info.event._def.resourceIds[0].split('-')[1],
			}),
		};
		handleEditBooking(info.event.id, updateBookingBody);
	};

	const handleClickEvent = (info) => {
		const extendedProps = info.event._def.extendedProps;
		if (
			!extendedProps.isBeingUpdated &&
			extendedProps.access !== 'none' &&
			!extendedProps.unavailability
		) {
			setPresetBookingData({
				bookingId: Number(info.event._def.publicId),
				itemId: Number(getFirstElement(info.event._def.resourceIds[0])),
				itemSelected: true,
			});
			setOpenAddOrEditBooking(true);
		} else if (extendedProps.unavailability) {
			setPresetBookingData({
				unavailabilityId: Number(info.event._def.publicId),
				itemId: Number(getFirstElement(info.event._def.resourceIds[0])),
				itemSelected: true,
				isUnavailability: true,
			});
			setOpenAddOrEditBooking(true);
		}
	};

	const fullCalendarEvents = {
		select: handleSelect,
		resizeEvent: handleResizeEvent,
		dropEvent: handleDropEvent,
		clickEvent: handleClickEvent,
	};

	const handleEditBooking = (bookingId, updateBookingBody) => {
		onPatchUpdateBooking(bookingId, updateBookingBody);
		updateErrorHandling.setStartAction(true);
		setUpdatingBooking(true);
	};

	const CalendarFilterContainerProps = {
		calendarRef,
		openAddOrEditBooking,
		setOpenAddOrEditBooking,
		presetBookingData,
		shouldEditBooking,
		setShouldEditBooking,
		date,
		setDate,
		pageNumber,
		setPageNumber,
		pageSize,
		shouldLazyLoadFetch,
		setShouldLazyLoadFetch,
		shouldDoInitialFetch,
		setShouldDoInitialFetch,
		resources,
		setResources,
		fullResourceList,
		setUpdatedBookingId,
		setPresetBookingData,
	};

	/* * * * * *
	 * RENDER  *
	 * * * * * */
	return (
		<CalendarFilterContainer {...CalendarFilterContainerProps}>
			<FullCalendar
				calendarRef={calendarRef}
				chartData={chartData}
				eventArray={bookings}
				events={fullCalendarEvents}
				resourceArray={resources}
			/>
		</CalendarFilterContainer>
	);
};

const mapStateToProps = (state) => {
	return {
		planboardBookings: state.planboard.planboardBookings,
		updatedBookingDetails: state.condition.updatedBookingDetails,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onPatchUpdateBooking: (bookingId, bodyData) =>
			dispatch(actions.patchUpdateBooking(bookingId, bodyData)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(FullCalendarPlanboard);

import { Card, CardContent } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { InfoLabel } from '../../../../../../components';
import { useStyles } from '../style';

interface CharacteristicsCardProps {
	itemStep?: number;
	isEdit?: boolean;
	children?: unknown[];
}

const CharacteristicsCard = (props: CharacteristicsCardProps) => {
	const { isEdit, children } = props;
	const { t } = useTranslation();

	const classes = useStyles();

	return (
		<Card className={isEdit ? classes.cardTop : null}>
			<CardContent className={classes.cardCharacteristics}>
				<InfoLabel
					info={t('views.itemManagement.characteristicsDescription')}
					name={
						isEdit ? t('views.itemManagement.characteristicsEdit') : t('ui.characteristics.title')
					}
					paddingTop={2}
					variant='h4'
				/>
				{children}
			</CardContent>
		</Card>
	);
};

export default CharacteristicsCard;

import path from 'path';

import { UserRoleEnum } from '~enums';
import { PagedResults } from '~interfaces';
import { GeneralPagedParameters } from '~interfaces/requests';
import { AddressResponse, BaseReferenceResponse, PagedResponse } from '~interfaces/responses';
import { TopologyService } from '~services';

import User from '../interfaces/user';

/**
 * A service to get "business logic" users. E.g. user data from
 * the Core service
 */
class UsersService extends TopologyService {
	protected readonly path = 'users';

	public readonly exportsSubPath = 'exports';

	constructor() {
		super('v1');
	}

	/**
	 * Get a paginated list of users
	 * @param page The number of the page
	 * @param pageSize The amount of results of the page
	 * @returns
	 */
	async getUsers({
		page = 1,
		pageSize = 10,
		descending = true,
		sortBy = 'id',
		...args
	}: GeneralPagedParameters<'id' | 'label' | 'email' | 'organisation' | 'userGroup'> & {
		excludeAnonymous?: boolean;
		userGroupId?: string;
		role?: UserRoleEnum
	}): Promise<PagedResults<User>> {
		const sortByMapped = sortBy === 'label' ? 'fullName' : sortBy;
		const { data } = await this._client.get<PagedResponse<UserResponse>>(this.path, {
			params: {
				pageNumber: page,
				pageSize: pageSize,
				organisationId: args.organisationId,
				userGroupId: args.userGroupId,
				sortBy: sortByMapped,
				orderDescending: descending,
				hideAnonymous: args.excludeAnonymous,
				role: args.role,
				...(args.searchQuery && { searchTerm: args.searchQuery }),
			},
		});

		return this.mapPagedResponse(data, UsersService.fromResponse);
	}

	/**
	 * Get the role of the specified user
	 * @param id
	 * @returns
	 */
	async getUserRole(id: string): Promise<UserRoleEnum> {
		const { data } = await this._client.get<string>(path.join('identityusers', id, 'role'));

		return data.toLowerCase() as UserRoleEnum;
	}

	/**
	 * Get information about the user that is logged in.
	 * TODO: writing to localstorage should be done outside this function
	 * @returns
	 */
	async getUserInformation(): Promise<User> {
		const { data } = await this._client.get<UserResponse>(path.join(this.path, 'me'));

		localStorage.setItem('currentUser', JSON.stringify(data));

		return UsersService.fromResponse(data);
	}

	async getUsersForExport(organisationId?: string) {
		const { data } = await this._client.get(path.join(this.path, this.exportsSubPath), {
			responseType: 'blob',
			params: {
				organisationId: organisationId,
			},
		});

		return data;
	}

	/**
	 * Get details about a specific user
	 * @param id
	 * @returns
	 */
	async getUserById(userId: string): Promise<User> {
		const { data } = await this._client.get<UserResponse>(path.join(this.path, userId));

		return UsersService.fromResponse(data);
	}

	/**
	 * Delete a user
	 * @param id
	 * @returns
	 */
	async deleteUser(id: string, hard = false): Promise<null> {
		const { data } = await this._client.delete<null>(path.join(this.path, id), {
			params: {
				hardDelete: hard,
			},
		});

		return data;
	}

	async removeUserFromOrganisation(userId: string): Promise<null> {
		await this._client.put(path.join(this.path, userId, 'remove'));

		return null;
	}

	/**
	 * Get a paginated list of users
	 * @param page The number of the page
	 * @param pageSize The amount of results of the page
	 * @returns
	 */
	async getUsersUnmapped({
		page = 1,
		pageSize = 10,
		descending = true,
		sortBy = 'id',
		...args
	}: GeneralPagedParameters<'id' | 'label' | 'email' | 'organisation' | 'userGroup'> & {
		excludeAnonymous?: boolean;
		userGroupId?: string;
		role?: UserRoleEnum
	}): Promise<PagedResults<UserResponse>> {
		const sortByMapped = sortBy === 'label' ? 'fullName' : sortBy;
		const { data } = await this._client.get<PagedResponse<UserResponse>>(this.path, {
			params: {
				pageNumber: page,
				pageSize: pageSize,
				organisationId: args.organisationId,
				userGroupId: args.userGroupId,
				sortBy: sortByMapped,
				orderDescending: descending,
				hideAnonymous: args.excludeAnonymous,
				role: args.role,
				...(args.searchQuery && { searchTerm: args.searchQuery }),
			},
		});

		return data;
	}

	/**
	 * 
	 * @param userId 
	 * @returns 
	 * @deprecated This in only introduced to transition from Redux to the service layer
	 * approach. Once ready, use `getUserById`
	 */
	async getUserByIdUnmapped(userId: string): Promise<UserResponse> {
		const { data } = await this._client.get<UserResponse>(path.join(this.path, userId));

		return data;
	}

	static fromResponse(data: UserResponse): User {
		const {
			id,
			name,
			emailAddress,
			userGroupReference,
			organisationReference,
			dateOfBirth,
			imagesReference,
			address,
			cards,
			validationStatus,
			role,
			...rest
		} = data;

		return {
			...rest,
			...UsersService.fromBaseReferenceResponse({
				id: id,
				name: name,
			}),
			email: emailAddress,
			userGroup: UsersService.fromBaseReferenceResponse(userGroupReference),
			organisation: {
				...UsersService.fromBaseReferenceResponse(organisationReference),
				logo: organisationReference.logo,
				features: organisationReference.features,
				subscriptions: organisationReference.subscriptionModule
			},
			address: address != null ? {
				countryCode: address.countryCode,
				country: address.country,
				city: address.city,
				postalCode: address.postalCode,
				street: address.street,
				number: address.number ? Number(address.number) : undefined,
				numberAddition: address.numberAddition,
			} : undefined,
			imageUrl: imagesReference && imagesReference.length > 0 ? imagesReference[0] : undefined,
			dateOfBirth: dateOfBirth != null ? new Date(dateOfBirth) : undefined,
			cards: cards.map((el) => ({ id: el.nfcId })),
			hasUserLicense: validationStatus !== 'notApplicable',
			role: role as UserRoleEnum,
		};
	}
}

interface UserResponse extends BaseReferenceResponse {
	// TODO: should this be nullable?
	// The backend returns null only if you request a user
	// that is not part of your organisation but booked
	// one of your items
	userGroupReference?: BaseReferenceResponse;
	organisationReference: {
		features: string[];
		logo: string;
		subscriptionModule: string[];
	} & BaseReferenceResponse;
	emailAddress: string;
	firstName: string;
	lastName: string;
	role: string;
	cards: {
		nfcId: string;
		cardType: string;
	}[];
	preferences: {
		preferredCultureInfo: string;
	};
	imagesReference?: string[];
	phoneNumber: string;
	isEmailConfirmed: boolean;
	isAnonymous: boolean;
	licenseStatus: string;
	validationStatus: string;
	address?: AddressResponse;
	dateOfBirth?: string;
}

export default UsersService;

import { useMemo } from 'react';

import { Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useMatches, useSearchParams } from 'react-router-dom';

import { UserRoleEnum } from '~enums';
import { useAuthorize } from '~features/authentication';

interface Page {
	i18nKey: string;
	path: string;
	// Otherwise defaults to admin?
	roles?: UserRoleEnum[];
}

interface PageNavBarProps {
	pages: Page[];
	persistedSearchParamKeys?: string[]
}

const PageNavBar = ({ pages, persistedSearchParamKeys = [] }: PageNavBarProps) => {
	const { t } = useTranslation();

	const matches = useMatches();
	const [searchParams] = useSearchParams();
	const { authorize } = useAuthorize();

	/**
	 * Split the last path entry from the path and keep the parent path.
	 * We use the useMatches for this, because if keeps a predictable
	 * trailing slash we can split on
	 */
	const pathElements = matches[matches.length - 1].pathname.split('/').reverse();
	const lastPath = pathElements.find((el) => el !== '');

	const persistedSearchParams = useMemo<URLSearchParams>(() => {
		const newSearchParams = new URLSearchParams();

		for (const entry of searchParams.entries()) {
			if (persistedSearchParamKeys.includes(entry[0])) {
				newSearchParams.append(entry[0], entry[1]);
			}
		}

		return newSearchParams;
	}, [searchParams, persistedSearchParamKeys]);

	return (
		<Tabs value={lastPath}>
			{pages
				.filter((el) => !el.roles || authorize(el.roles))
				.map((page) => (
					<Tab
						key={page.path}
						component={Link}
						value={page.path}
						to={{
							pathname: page.path,
							search: persistedSearchParams.toString(),
						}}
						label={t(page.i18nKey)}
						relative='path'
					/>
				))}
		</Tabs>
	);
};

export default PageNavBar;
